.gauteng-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  .gauteng-title {
    color: #333;
    font-size: 1.8em;
    margin-bottom: 15px;
  }
  
  .gauteng-content {
    display: flex;
    gap: 10px;
  }
  
  .kzn-sidebar {
    width: 30%;
    padding-right: 10px;
  }
  
  .kzn-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .kzn-list-item {
    margin-bottom: 8px;
  }
  
  .kzn-button {
    background: none;
    border: none;
    text-align: left;
    padding: 5px;
    cursor: pointer;
    color: #ffffff;
    font-size: 1em;
    transition: color 0.3s;
  }
  
  /* .kzn-button:hover {
    color: #0077cc;
  } */
  
  .western-cape-details {
    width: 70%;
    padding-left: 10px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .western-cape-info p {
    margin: 5px 0;
    color: #333;
    font-size: 1em;
  }
  
  .eastern-cape-role {
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 5px;
    display: inline-block;
  }
  
  /* Example styles for roles */
  /* .status.admin {
    color: #d9534f;
  } */
  
  .status.moderator {
    color: #f0ad4e; /* Orange for moderator */
  }
  
  .status.user {
    color: #5bc0de; /* Blue for user */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .gauteng-content {
      flex-direction: column;
    }
  
    .kzn-sidebar,
    .western-cape-details {
      width: 100%;
      padding: 0;
    }
  }
  