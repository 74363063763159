/* .ReferralProgramCard-module__cardInner1,
.ReferralProgramCard-module__cardInner3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start; Align items to the left
    text-align: left; /* Ensure text alignment is to the left
    padding-left: 20px; /* Optional: Add some padding to move the text away from the image
    width: 300px; /* Set the width for the card content
}

.ReferralProgramCard-module__cardTitle {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left; /* Align the title text to the left
    margin-bottom: 10px; /* Add some space between the title and subtitle
    width: 90%; /* Ensure title takes the full width
}

.ReferralProgramCard-module__cardSubTitle {
    font-size: 1rem;
    text-align: left; /* Align the subtitle text to the left
    margin-bottom: 20px; /* Add some space below the subtitle
    width: 80%; /* Ensure subtitle takes the full width
}

.Button-module__btnInnerContent {
    text-align: left; /* Ensure button text is aligned left
    width: 100%; /* Ensure button takes the full width
}

.Button-module__primary {
    width: 100%; /* Make the button fill the available width
    display: flex;
    justify-content: flex-start; /* Align button text to the left
}


.ThreeStepsSection-module__threeStepsDesktop {
    display: flex;
    justify-content: space-between;
    overflow: hidden; /* Hide overflow by default
}

@media (max-width: 768px) { 
    /* For mobile screens
    .ThreeStepsSection-module__threeStepsDesktop {
        display: flex;
        overflow-x: auto; /* Enable horizontal scroll
        white-space: nowrap; /* Prevent items from wrapping to the next line
        scroll-behavior: smooth; /* Add smooth scrolling
        -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS
    }
    
    .StepCard-module__stepCardOuter {
        min-width: 80%; /* Adjust the width of each step card
        flex-shrink: 0; /* Prevent shrinking
        margin-right: 10px; /* Add spacing between step cards
    }

    .StepCard-module__stepCardInnerDesktop {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .StepCard-module__title, .StepCard-module__subTitle {
        text-align: center; /* Ensure text is centered on mobile
    }
}

.StepCard-module__stepCardInner,
.StepCard-module__stepCardOuter {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding: 7px;

} */
.Ripple-module__container {
    -webkit-tap-highlight-color: transparent;
    bottom: 0;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
  }

  .Ripple-module__container .Ripple-module__rippleSpan {
    -webkit-animation-name: Ripple-module__ripple !important;
    animation-name: Ripple-module__ripple !important;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 100% !important;
    position: absolute !important;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  .Ripple-module__container .Ripple-module__rippleSpan.Ripple-module__darkBlack {
    background-color: rgba(0, 0, 0, .2)
  }
  .Button-module__btn2 {
    background: linear-gradient(45deg, #e53863, #ff5779 50%, #e53863);
    background-position: 100% 100%;
  }

  .Button-module__btn3 {
   display: none;
 
  }

  .RoomTypeSection-module__viewMoreBtn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #f05252;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 0 16px 12px;
    text-align: left
  }

  .RoomTypeSection-module__viewMoreBtn.RoomTypeSection-module__desktop {
    color: #1f2a37;
    background-color: #f9fafb;
    padding: 0
  }
  .button-container {
    display: flex;
    flex-direction: row; /* Ensures buttons are side by side */
    gap: 10px; /* Space between the buttons */
}

.Button-module__btn2,
.Button-module__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1; /* Ensures buttons share available width equally */
    max-height: 40px;
}


  @media only screen and (max-width: 768px) {
    .TenancyCard-module__bookBtnDesktop .Ripple-module__container {
        display: none !important;
    }
    .Button-module__btn2.Button-module__primary {
        display: none !important;
     }
     .RoomTypeSection-module__viewMoreBtn{
        display: none !important;
     }
     .Button-module__btn3 {
        display: block;
        background: linear-gradient(45deg, #e53863, #ff5779 50%, #e53863);
        background-position: 100% 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 8px;
        justify-content: center;
      }
}
