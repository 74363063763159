/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  Unique Slider container (Apartdsadsdas) 
  .Apartdsadsdas {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    width: 100%;
    margin: auto;
    gap: 10px;
}*/

/* Unique Slider items 
.Apartdsadsdas-item {
    scroll-snap-align: start;
    flex: 0 0 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
*/
/* Images inside the unique slider
.Apartdsadsdas-item img {
    max-width: 100%;
    height: auto;
}

/* Unique fdsdsfdsdsv buttons (dsdss) 
.fdsdsfdsdsv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px auto;
}

.dsdss {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

/* Hide buttons on mobile
@media only screen and (max-width: 768px) {
    .fdsdsfdsdsv {
        display: none;
    }
}
body {
    font-family: Arial, sans-serif;
    
    text-align: center; /* Align content to the left
}

/* On mobile, change to 1 column 
@media (max-width: 768px) {
    .building-grid {
        grid-template-columns: 1fr; /* 1 column for mobile 
        width: 100%;
    }
    .room-card {
        max-width: 300px;
        height: 500px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        position: relative;
        color: #ffffff;
        background-color: #142c44; /* Dark blue background for the card 
        margin-bottom: 20px;
    }

    body {
        font-family: Arial, sans-serif;
        text-align: center;
        max-width: 500px;
    }
}

.room-card {
    width: 400px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    color: #ffffff;
    background-color: #142c44; /* Dark blue background for the card 
    margin-bottom: 20px;
}

.room-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important   ; 
    object-fit: cover;
    z-index: 0;
    opacity: 0.9; /* Add some transparency to the image 
}

.room-card-content {
    padding: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(51, 51, 51, 0.9) 3%, rgba(51, 51, 51, 0.7) 20%, rgba(51, 51, 51, 0.1) 120%);
    z-index: 1;
    color: #ffffff; /* White text on the blue gradient background
}

.room-title {
    margin: 0;
    font-size: 24px;
    text-align: left;
    color: #ffffff; /* Bright blue for the room title
}

.room-type {
    color: #cce7ff; /* Light blue for the room type
    font-size: 22px;
    text-align: left;
    margin: 5px 0 15px 0;
}

.b-type {
    color: #cce7ff; /* Light blue for the room type
    font-size: 17px;
    text-align: left;
    margin: 5px 0 15px 0;
}

.plan-options {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
}

.plan-option {
    background-color: transparent;
    color: #ffffff; /* Bright blue text
    border: 2px solid #ffffff; /* Bright blue border
    font-weight: 700;
    padding: 8px 7px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    width: auto;
    display: inline-block;
}

.plan-option:hover {
    background-color: rgba(51, 51, 51, 0.2);
}

.view-link {
    color: #cce7ff; /* Light blue for the link
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin-bottom: 15px;
    text-align: left;
}

.room-details {
    display: flex;
    justify-content: space-between;
    background-color: #4d4d4d00; /* Dark blue background for details
    padding: 10px;
    color: #ffffff; /* White text
    border-radius: 5px;
}

.detail-item {
    text-align: left; 
    /* Align text to the left within details
}

.detail-item p {
    margin: 0;width: 150%;
    color: #d3d3d3; /* Light blue for the detail labels
}

.detail-item span {
    display: block;
    font-weight: bold;
    margin-top: 5px;
    color: #a6a6a6; /* Bright blue for the detail values
}

.disabled-button {
    background-color: #d3d3d3; /* Light gray background when disabled
    color: #a6a6a6; /* Gray text when disabled
    cursor: not-allowed; /* Change cursor to 'not-allowed'
    border: 2px solid #a6a6a6; /* Gray border when disabled
}

button:disabled {
    opacity: 0.6; /* Slightly dim the button when disabled
}*/

#filterPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .filterContent {
    background: #fff;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
  }
  
  .mainFilterButton {
    display: none; /* Hide on desktop */
  }
  
  @media (max-width: 768px) {
    .filterSection-module__filterListDesktop {
      display: none; /* Hide filter list on mobile */
    }
    #filterPopup {
        display: flex;
      }
    .mainFilterButton {
      display: inline-block; /* Show filter button on mobile */
    }
    .filterSection-module__container filter> *:not(.mainFilterButton) {
        display: none;
      }
    .filterSection-module__filterListDesktop > *:not(.mainFilterButton) {
        display: none;
      }
  }
  