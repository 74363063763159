.search-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px; /* Add some padding for small screens */
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 10px 20px;
  width: 100%;
}

.search-input {
  flex: 1;
  background: transparent;
  padding: 10px;
  font-size: 16px;
  outline: none;
  border: none;
}

.search-button {
  background: none;
  border: none;
  border-radius: 17px;
  background-color: #ff2b32;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button span {
  background-color:'black'; 
  padding:'10px';
   border-radius:'30px'
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 10px;
  z-index: 10;
}

.country-list {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.country-button {
  background: #f0f0f0;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.country-button.active {
  background-color: #ff5a5f;
  color: #fff;
}

.recently-searched, .top-cities, .top-universities {
  margin-top: 20px;
}

.recently-searched h4,
.top-cities h4,
.top-universities h4 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #555;
}

.city-list, .university-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.city-list p, .university-list p {
  background: #f0f0f0;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.city-list p:hover, .university-list p:hover {
  background-color: #ff5a5f;
  color: #fff;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .search-input-wrapper {
      padding: 8px 15px; /* Adjust padding for smaller screens */
      border-radius: 20px; /* Slightly smaller border radius for mobile */
  }

  .search-input {
      font-size: 14px; /* Slightly smaller font size for mobile */
  }

  .search-button {
      font-size: 18px;
      padding: 8px; /* Adjust padding for a smaller button on mobile */
  }

  .search-dropdown {
      padding: 15px;
  }

  .country-button {
      padding: 6px 12px; /* Adjust button padding for mobile */
      font-size: 12px; /* Smaller font size for mobile */
  }

  .recently-searched h4,
  .top-cities h4,
  .top-universities h4 {
      font-size: 12px; /* Smaller font size for mobile headings */
  }

  .city-list p, .university-list p {
      padding: 4px 8px; /* Adjust padding for smaller screen sizes */
      font-size: 12px; /* Smaller font size for mobile items */
  }
}

/* For very small screens, e.g., max-width: 480px */
@media (max-width: 480px) {
  .search-input-wrapper {
      padding: 6px 10px;
      border-radius: 15px;
  }

  .search-input {
      font-size: 12px; /* Reduce font size for very small screens */
  }

  .search-button {
      font-size: 16px;
      padding: 6px; /* Smaller button for very small screens */
  }

  .search-dropdown {
      padding: 10px;
  }

  .country-button {
      padding: 4px 8px;
      font-size: 10px;
  }

  .recently-searched h4,
  .top-cities h4,
  .top-universities h4 {
      font-size: 10px; /* Further reduce font size for headings */
  }

  .city-list p, .university-list p {
      padding: 3px 6px;
      font-size: 10px; /* Smaller font for very small items */
  }
}
