/* Modern and Clean CSS Design */

.husky-container {
    margin: 20px auto;
    max-width: 1000px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.husky-title {
    font-size: 24px;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 10px;
}

.husky-subtitle {
    font-size: 18px;
    color: #7f8c8d;
    text-align: center;
    margin-bottom: 30px;
}

.husky-search {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.husky-search input {
    padding: 12px;
    width: 300px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 16px;
}

.husky-button-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.husky-button-group button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.husky-button-group button:hover {
    background-color: #2980b9;
}

.husky-error {
    color: #e74c3c;
    text-align: center;
}

.husky-building {
    margin-bottom: 40px;
}

.husky-building h3 {
    font-size: 20px;
    color: #34495e;
    margin-bottom: 10px;
}

.husky-building p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #16a085;
}

.husky-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 14px;
}

.husky-table th,
.husky-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ecf0f1;
}

.husky-table th {
    background-color: #3498db;
    color: white;
    text-transform: uppercase;
}

.husky-table td {
    color: #7f8c8d;
}

.husky-table .date,
.husky-table .amount {
    text-align: right;
}

.husky-footer {
    margin-top: 40px;
    font-size: 14px;
    color: #7f8c8d;
    text-align: center;
}

.husky-footer p {
    line-height: 1.6;
}

.month-year-header {
    background-color: #f0f0f0; /* Light grey background */
    font-weight: bold; /* Bold text */
    font-size: 1.2em; /* Larger text */
    text-align: center; /* Center text */
    padding: 10px 0; /* Padding for better spacing */
    border-top: 2px solid #ccc; /* Top border for visual separation */
    border-bottom: 2px solid #ccc; /* Bottom border for visual separation */
    width: 100%; /* Ensure it covers the full width */
    color: #333; /* Dark text for better readability */}

/* Additional styles to ensure space between months */
.month-year-header + tr {
    border-top: 2px solid #ccc; /* Top border for the first row of a new month */
}