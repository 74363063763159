
#inner-shadow-companion {
  font-size: 100%;
}

#inner-shadow-companion {
  font-family: circular, -apple-system, BlinkMacSystemFont, Segoe UI,
    sans-serif;
  color: var(--lns-color-body);

  font-size: var(--lns-fontSize-medium);
  line-height: var(--lns-lineHeight-medium);
  ;
  font-feature-settings: 'ss08' on;
}

#inner-shadow-companion *,
#inner-shadow-companion *:before,
#inner-shadow-companion *:after {
  box-sizing: border-box;
}

#inner-shadow-companion * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: calc(0.6px - 0.05em);
}


#inner-shadow-companion,
.theme-light,
[data-lens-theme="light"] {
  --lns-color-primary: var(--lns-themeLight-color-primary);
  --lns-color-primaryHover: var(--lns-themeLight-color-primaryHover);
  --lns-color-primaryActive: var(--lns-themeLight-color-primaryActive);
  --lns-color-body: var(--lns-themeLight-color-body);
  --lns-color-bodyDimmed: var(--lns-themeLight-color-bodyDimmed);
  --lns-color-background: var(--lns-themeLight-color-background);
  --lns-color-backgroundHover: var(--lns-themeLight-color-backgroundHover);
  --lns-color-backgroundActive: var(--lns-themeLight-color-backgroundActive);
  --lns-color-backgroundSecondary: var(--lns-themeLight-color-backgroundSecondary);
  --lns-color-backgroundSecondary2: var(--lns-themeLight-color-backgroundSecondary2);
  --lns-color-overlay: var(--lns-themeLight-color-overlay);
  --lns-color-border: var(--lns-themeLight-color-border);
  --lns-color-focusRing: var(--lns-themeLight-color-focusRing);
  --lns-color-record: var(--lns-themeLight-color-record);
  --lns-color-recordHover: var(--lns-themeLight-color-recordHover);
  --lns-color-recordActive: var(--lns-themeLight-color-recordActive);
  --lns-color-info: var(--lns-themeLight-color-info);
  --lns-color-success: var(--lns-themeLight-color-success);
  --lns-color-warning: var(--lns-themeLight-color-warning);
  --lns-color-danger: var(--lns-themeLight-color-danger);
  --lns-color-dangerHover: var(--lns-themeLight-color-dangerHover);
  --lns-color-dangerActive: var(--lns-themeLight-color-dangerActive);
  --lns-color-backdrop: var(--lns-themeLight-color-backdrop);
  --lns-color-backdropDark: var(--lns-themeLight-color-backdropDark);
  --lns-color-backdropTwilight: var(--lns-themeLight-color-backdropTwilight);
  --lns-color-disabledContent: var(--lns-themeLight-color-disabledContent);
  --lns-color-highlight: var(--lns-themeLight-color-highlight);
  --lns-color-disabledBackground: var(--lns-themeLight-color-disabledBackground);
  --lns-color-formFieldBorder: var(--lns-themeLight-color-formFieldBorder);
  --lns-color-formFieldBackground: var(--lns-themeLight-color-formFieldBackground);
  --lns-color-buttonBorder: var(--lns-themeLight-color-buttonBorder);
  --lns-color-upgrade: var(--lns-themeLight-color-upgrade);
  --lns-color-upgradeHover: var(--lns-themeLight-color-upgradeHover);
  --lns-color-upgradeActive: var(--lns-themeLight-color-upgradeActive);
  --lns-color-tabBackground: var(--lns-themeLight-color-tabBackground);
  --lns-color-discoveryBackground: var(--lns-themeLight-color-discoveryBackground);
  --lns-color-discoveryLightBackground: var(--lns-themeLight-color-discoveryLightBackground);
  --lns-color-discoveryTitle: var(--lns-themeLight-color-discoveryTitle);
  --lns-color-discoveryHighlight: var(--lns-themeLight-color-discoveryHighlight);
}

.theme-dark,
[data-lens-theme="dark"] {
  --lns-color-primary: var(--lns-themeDark-color-primary);
  --lns-color-primaryHover: var(--lns-themeDark-color-primaryHover);
  --lns-color-primaryActive: var(--lns-themeDark-color-primaryActive);
  --lns-color-body: var(--lns-themeDark-color-body);
  --lns-color-bodyDimmed: var(--lns-themeDark-color-bodyDimmed);
  --lns-color-background: var(--lns-themeDark-color-background);
  --lns-color-backgroundHover: var(--lns-themeDark-color-backgroundHover);
  --lns-color-backgroundActive: var(--lns-themeDark-color-backgroundActive);
  --lns-color-backgroundSecondary: var(--lns-themeDark-color-backgroundSecondary);
  --lns-color-backgroundSecondary2: var(--lns-themeDark-color-backgroundSecondary2);
  --lns-color-overlay: var(--lns-themeDark-color-overlay);
  --lns-color-border: var(--lns-themeDark-color-border);
  --lns-color-focusRing: var(--lns-themeDark-color-focusRing);
  --lns-color-record: var(--lns-themeDark-color-record);
  --lns-color-recordHover: var(--lns-themeDark-color-recordHover);
  --lns-color-recordActive: var(--lns-themeDark-color-recordActive);
  --lns-color-info: var(--lns-themeDark-color-info);
  --lns-color-success: var(--lns-themeDark-color-success);
  --lns-color-warning: var(--lns-themeDark-color-warning);
  --lns-color-danger: var(--lns-themeDark-color-danger);
  --lns-color-dangerHover: var(--lns-themeDark-color-dangerHover);
  --lns-color-dangerActive: var(--lns-themeDark-color-dangerActive);
  --lns-color-backdrop: var(--lns-themeDark-color-backdrop);
  --lns-color-backdropDark: var(--lns-themeDark-color-backdropDark);
  --lns-color-backdropTwilight: var(--lns-themeDark-color-backdropTwilight);
  --lns-color-disabledContent: var(--lns-themeDark-color-disabledContent);
  --lns-color-highlight: var(--lns-themeDark-color-highlight);
  --lns-color-disabledBackground: var(--lns-themeDark-color-disabledBackground);
  --lns-color-formFieldBorder: var(--lns-themeDark-color-formFieldBorder);
  --lns-color-formFieldBackground: var(--lns-themeDark-color-formFieldBackground);
  --lns-color-buttonBorder: var(--lns-themeDark-color-buttonBorder);
  --lns-color-upgrade: var(--lns-themeDark-color-upgrade);
  --lns-color-upgradeHover: var(--lns-themeDark-color-upgradeHover);
  --lns-color-upgradeActive: var(--lns-themeDark-color-upgradeActive);
  --lns-color-tabBackground: var(--lns-themeDark-color-tabBackground);
  --lns-color-discoveryBackground: var(--lns-themeDark-color-discoveryBackground);
  --lns-color-discoveryLightBackground: var(--lns-themeDark-color-discoveryLightBackground);
  --lns-color-discoveryTitle: var(--lns-themeDark-color-discoveryTitle);
  --lns-color-discoveryHighlight: var(--lns-themeDark-color-discoveryHighlight);
}



#inner-shadow-companion {
  --lns-fontWeight-book: 400;
  --lns-fontWeight-bold: 700;
  --lns-unit: 0.5rem;
  --lns-fontSize-small: calc(1.5 * var(--lns-unit, 8px));
  --lns-lineHeight-small: 1.5;
  --lns-fontSize-body-sm: calc(1.5 * var(--lns-unit, 8px));
  --lns-lineHeight-body-sm: 1.5;
  --lns-fontSize-medium: calc(1.75 * var(--lns-unit, 8px));
  --lns-lineHeight-medium: 1.6;
  --lns-fontSize-body-md: calc(1.75 * var(--lns-unit, 8px));
  --lns-lineHeight-body-md: 1.6;
  --lns-fontSize-large: calc(2.25 * var(--lns-unit, 8px));
  --lns-lineHeight-large: 1.45;
  --lns-fontSize-body-lg: calc(2.25 * var(--lns-unit, 8px));
  --lns-lineHeight-body-lg: 1.45;
  --lns-fontSize-xlarge: calc(3 * var(--lns-unit, 8px));
  --lns-lineHeight-xlarge: 1.35;
  --lns-fontSize-heading-sm: calc(3 * var(--lns-unit, 8px));
  --lns-lineHeight-heading-sm: 1.35;
  --lns-fontSize-xxlarge: calc(4 * var(--lns-unit, 8px));
  --lns-lineHeight-xxlarge: 1.2;
  --lns-fontSize-heading-md: calc(4 * var(--lns-unit, 8px));
  --lns-lineHeight-heading-md: 1.2;
  --lns-fontSize-xxxlarge: calc(6 * var(--lns-unit, 8px));
  --lns-lineHeight-xxxlarge: 1.15;
  --lns-fontSize-heading-lg: calc(6 * var(--lns-unit, 8px));
  --lns-lineHeight-heading-lg: 1.15;
  --lns-radius-medium: calc(1 * var(--lns-unit, 8px));
  --lns-radius-large: calc(2 * var(--lns-unit, 8px));
  --lns-radius-xlarge: calc(3 * var(--lns-unit, 8px));
  --lns-radius-full: calc(999 * var(--lns-unit, 8px));
  --lns-shadow-small: 0 calc(0.5 * var(--lns-unit, 8px)) calc(1.25 * var(--lns-unit, 8px)) hsla(0, 0%, 0%, 0.05);
  --lns-shadow-medium: 0 calc(0.5 * var(--lns-unit, 8px)) calc(1.25 * var(--lns-unit, 8px)) hsla(0, 0%, 0%, 0.1);
  --lns-shadow-large: 0 calc(0.75 * var(--lns-unit, 8px)) calc(3 * var(--lns-unit, 8px)) hsla(0, 0%, 0%, 0.1);
  --lns-space-xsmall: calc(0.5 * var(--lns-unit, 8px));
  --lns-space-small: calc(1 * var(--lns-unit, 8px));
  --lns-space-medium: calc(2 * var(--lns-unit, 8px));
  --lns-space-large: calc(3 * var(--lns-unit, 8px));
  --lns-space-xlarge: calc(5 * var(--lns-unit, 8px));
  --lns-space-xxlarge: calc(8 * var(--lns-unit, 8px));
  --lns-formFieldBorderWidth: 1px;
  --lns-formFieldBorderWidthFocus: 2px;
  --lns-formFieldHeight: calc(4.5 * var(--lns-unit, 8px));
  --lns-formFieldRadius: calc(2.25 * var(--lns-unit, 8px));
  --lns-formFieldHorizontalPadding: calc(2 * var(--lns-unit, 8px));
  --lns-formFieldBorderShadow:
    inset 0 0 0 var(--lns-formFieldBorderWidth) var(--lns-color-formFieldBorder);
  --lns-formFieldBorderShadowFocus:
    inset 0 0 0 var(--lns-formFieldBorderWidthFocus) var(--lns-color-blurple),
    0 0 0 var(--lns-formFieldBorderWidthFocus) var(--lns-color-focusRing);
  --lns-formFieldBorderShadowError:
    inset 0 0 0 var(--lns-formFieldBorderWidthFocus) var(--lns-color-danger),
    0 0 0 var(--lns-formFieldBorderWidthFocus) var(--lns-color-orangeLight);
  --lns-color-red: hsla(11, 80%, 45%, 1);
  --lns-color-blurpleLight: hsla(240, 83.3%, 95.3%, 1);
  --lns-color-blurpleMedium: hsla(242, 81%, 87.6%, 1);
  --lns-color-blurple: hsla(242, 88.4%, 66.3%, 1);
  --lns-color-blurpleDark: hsla(242, 87.6%, 62%, 1);
  --lns-color-blurpleStrong: hsla(252, 46%, 33%, 1);
  --lns-color-offWhite: hsla(45, 36.4%, 95.7%, 1);
  --lns-color-blueLight: hsla(206, 58.3%, 85.9%, 1);
  --lns-color-blue: hsla(206, 100%, 73.3%, 1);
  --lns-color-blueDark: hsla(206, 29.5%, 33.9%, 1);
  --lns-color-orangeLight: hsla(6, 100%, 89.6%, 1);
  --lns-color-orange: hsla(11, 100%, 62.2%, 1);
  --lns-color-orangeDark: hsla(11, 79.9%, 64.9%, 1);
  --lns-color-tealLight: hsla(180, 20%, 67.6%, 1);
  --lns-color-teal: hsla(180, 51.4%, 51.6%, 1);
  --lns-color-tealDark: hsla(180, 16.2%, 22.9%, 1);
  --lns-color-yellowLight: hsla(39, 100%, 87.8%, 1);
  --lns-color-yellow: hsla(50, 100%, 57.3%, 1);
  --lns-color-yellowDark: hsla(39, 100%, 68%, 1);
  --lns-color-grey8: hsla(0, 0%, 13%, 1);
  --lns-color-grey7: hsla(246, 16%, 26%, 1);
  --lns-color-grey6: hsla(252, 13%, 46%, 1);
  --lns-color-grey5: hsla(240, 7%, 62%, 1);
  --lns-color-grey4: hsla(259, 12%, 75%, 1);
  --lns-color-grey3: hsla(260, 11%, 85%, 1);
  --lns-color-grey2: hsla(260, 11%, 95%, 1);
  --lns-color-grey1: hsla(240, 7%, 97%, 1);
  --lns-color-white: hsla(0, 0%, 100%, 1);
  --lns-themeLight-color-primary: hsla(242, 88.4%, 66.3%, 1);
  --lns-themeLight-color-primaryHover: hsla(242, 88.4%, 56.3%, 1);
  --lns-themeLight-color-primaryActive: hsla(242, 88.4%, 45.3%, 1);
  --lns-themeLight-color-body: hsla(0, 0%, 13%, 1);
  --lns-themeLight-color-bodyDimmed: hsla(252, 13%, 46%, 1);
  --lns-themeLight-color-background: hsla(0, 0%, 100%, 1);
  --lns-themeLight-color-backgroundHover: hsla(246, 16%, 26%, 0.1);
  --lns-themeLight-color-backgroundActive: hsla(246, 16%, 26%, 0.3);
  --lns-themeLight-color-backgroundSecondary: hsla(246, 16%, 26%, 0.04);
  --lns-themeLight-color-backgroundSecondary2: hsla(45, 34%, 78%, 0.2);
  --lns-themeLight-color-overlay: hsla(0, 0%, 100%, 1);
  --lns-themeLight-color-border: hsla(252, 13%, 46%, 0.2);
  --lns-themeLight-color-focusRing: hsla(242, 88.4%, 66.3%, 0.5);
  --lns-themeLight-color-record: hsla(11, 100%, 62.2%, 1);
  --lns-themeLight-color-recordHover: hsla(11, 100%, 52.2%, 1);
  --lns-themeLight-color-recordActive: hsla(11, 100%, 42.2%, 1);
  --lns-themeLight-color-info: hsla(206, 100%, 73.3%, 1);
  --lns-themeLight-color-success: hsla(180, 51.4%, 51.6%, 1);
  --lns-themeLight-color-warning: hsla(39, 100%, 68%, 1);
  --lns-themeLight-color-danger: hsla(11, 80%, 45%, 1);
  --lns-themeLight-color-dangerHover: hsla(11, 80%, 38%, 1);
  --lns-themeLight-color-dangerActive: hsla(11, 80%, 31%, 1);
  --lns-themeLight-color-backdrop: hsla(0, 0%, 13%, 0.5);
  --lns-themeLight-color-backdropDark: hsla(0, 0%, 13%, 0.9);
  --lns-themeLight-color-backdropTwilight: hsla(245, 44.8%, 46.9%, 0.8);
  --lns-themeLight-color-disabledContent: hsla(240, 7%, 62%, 1);
  --lns-themeLight-color-highlight: hsla(240, 83.3%, 66.3%, 0.15);
  --lns-themeLight-color-disabledBackground: hsla(260, 11%, 95%, 1);
  --lns-themeLight-color-formFieldBorder: hsla(260, 11%, 85%, 1);
  --lns-themeLight-color-formFieldBackground: hsla(0, 0%, 100%, 1);
  --lns-themeLight-color-buttonBorder: hsla(252, 13%, 46%, 0.25);
  --lns-themeLight-color-upgrade: hsla(206, 100%, 93%, 1);
  --lns-themeLight-color-upgradeHover: hsla(206, 100%, 85%, 1);
  --lns-themeLight-color-upgradeActive: hsla(206, 100%, 77%, 1);
  --lns-themeLight-color-tabBackground: hsla(252, 13%, 46%, 0.15);
  --lns-themeLight-color-discoveryBackground: hsla(206, 100%, 93%, 1);
  --lns-themeLight-color-discoveryLightBackground: hsla(206, 100%, 97%, 1);
  --lns-themeLight-color-discoveryTitle: hsla(0, 0%, 13%, 1);
  --lns-themeLight-color-discoveryHighlight: hsla(206, 100%, 77%, 0.3);
  --lns-themeDark-color-primary: hsla(242, 87%, 73%, 1);
  --lns-themeDark-color-primaryHover: hsla(242, 88.4%, 56.3%, 1);
  --lns-themeDark-color-primaryActive: hsla(242, 88.4%, 45.3%, 1);
  --lns-themeDark-color-body: hsla(240, 7%, 97%, 1);
  --lns-themeDark-color-bodyDimmed: hsla(240, 7%, 62%, 1);
  --lns-themeDark-color-background: hsla(0, 0%, 13%, 1);
  --lns-themeDark-color-backgroundHover: hsla(0, 0%, 100%, 0.1);
  --lns-themeDark-color-backgroundActive: hsla(0, 0%, 100%, 0.2);
  --lns-themeDark-color-backgroundSecondary: hsla(0, 0%, 100%, 0.04);
  --lns-themeDark-color-backgroundSecondary2: hsla(45, 13%, 44%, 0.2);
  --lns-themeDark-color-overlay: hsla(0, 0%, 20%, 1);
  --lns-themeDark-color-border: hsla(259, 12%, 75%, 0.2);
  --lns-themeDark-color-focusRing: hsla(242, 88.4%, 66.3%, 0.5);
  --lns-themeDark-color-record: hsla(11, 100%, 62.2%, 1);
  --lns-themeDark-color-recordHover: hsla(11, 100%, 52.2%, 1);
  --lns-themeDark-color-recordActive: hsla(11, 100%, 42.2%, 1);
  --lns-themeDark-color-info: hsla(206, 100%, 73.3%, 1);
  --lns-themeDark-color-success: hsla(180, 51.4%, 51.6%, 1);
  --lns-themeDark-color-warning: hsla(39, 100%, 68%, 1);
  --lns-themeDark-color-danger: hsla(11, 80%, 45%, 1);
  --lns-themeDark-color-dangerHover: hsla(11, 80%, 38%, 1);
  --lns-themeDark-color-dangerActive: hsla(11, 80%, 31%, 1);
  --lns-themeDark-color-backdrop: hsla(0, 0%, 13%, 0.5);
  --lns-themeDark-color-backdropDark: hsla(0, 0%, 13%, 0.9);
  --lns-themeDark-color-backdropTwilight: hsla(245, 44.8%, 46.9%, 0.8);
  --lns-themeDark-color-disabledContent: hsla(240, 7%, 62%, 1);
  --lns-themeDark-color-highlight: hsla(240, 83.3%, 66.3%, 0.15);
  --lns-themeDark-color-disabledBackground: hsla(252, 13%, 23%, 1);
  --lns-themeDark-color-formFieldBorder: hsla(252, 13%, 46%, 1);
  --lns-themeDark-color-formFieldBackground: hsla(0, 0%, 13%, 1);
  --lns-themeDark-color-buttonBorder: hsla(0, 0%, 100%, 0.25);
  --lns-themeDark-color-upgrade: hsla(206, 92%, 81%, 1);
  --lns-themeDark-color-upgradeHover: hsla(206, 92%, 74%, 1);
  --lns-themeDark-color-upgradeActive: hsla(206, 92%, 67%, 1);
  --lns-themeDark-color-tabBackground: hsla(0, 0%, 100%, 0.15);
  --lns-themeDark-color-discoveryBackground: hsla(206, 92%, 81%, 1);
  --lns-themeDark-color-discoveryLightBackground: hsla(0, 0%, 13%, 1);
  --lns-themeDark-color-discoveryTitle: hsla(206, 100%, 73.3%, 1);
  --lns-themeDark-color-discoveryHighlight: hsla(206, 100%, 77%, 0.3);
  --lns-gradient-ai-primary: radial-gradient(134.96% 884.49% at 119.29% 112.58%, #DC43BE 0%, #565ADD 70%);
  --lns-gradient-ai-secondary: radial-gradient(100% 138.41% at 100% 100%, #EFF0FF 0%, #FFFFFF 100%);
}


.c\:red {
  color: var(--lns-color-red)
}

.c\:blurpleLight {
  color: var(--lns-color-blurpleLight)
}

.c\:blurpleMedium {
  color: var(--lns-color-blurpleMedium)
}

.c\:blurple {
  color: var(--lns-color-blurple)
}

.c\:blurpleDark {
  color: var(--lns-color-blurpleDark)
}

.c\:blurpleStrong {
  color: var(--lns-color-blurpleStrong)
}

.c\:offWhite {
  color: var(--lns-color-offWhite)
}

.c\:blueLight {
  color: var(--lns-color-blueLight)
}

.c\:blue {
  color: var(--lns-color-blue)
}

.c\:blueDark {
  color: var(--lns-color-blueDark)
}

.c\:orangeLight {
  color: var(--lns-color-orangeLight)
}

.c\:orange {
  color: var(--lns-color-orange)
}

.c\:orangeDark {
  color: var(--lns-color-orangeDark)
}

.c\:tealLight {
  color: var(--lns-color-tealLight)
}

.c\:teal {
  color: var(--lns-color-teal)
}

.c\:tealDark {
  color: var(--lns-color-tealDark)
}

.c\:yellowLight {
  color: var(--lns-color-yellowLight)
}

.c\:yellow {
  color: var(--lns-color-yellow)
}

.c\:yellowDark {
  color: var(--lns-color-yellowDark)
}

.c\:grey8 {
  color: var(--lns-color-grey8)
}

.c\:grey7 {
  color: var(--lns-color-grey7)
}

.c\:grey6 {
  color: var(--lns-color-grey6)
}

.c\:grey5 {
  color: var(--lns-color-grey5)
}

.c\:grey4 {
  color: var(--lns-color-grey4)
}

.c\:grey3 {
  color: var(--lns-color-grey3)
}

.c\:grey2 {
  color: var(--lns-color-grey2)
}

.c\:grey1 {
  color: var(--lns-color-grey1)
}

.c\:white {
  color: var(--lns-color-white)
}

.c\:primary {
  color: var(--lns-color-primary)
}

.c\:primaryHover {
  color: var(--lns-color-primaryHover)
}

.c\:primaryActive {
  color: var(--lns-color-primaryActive)
}

.c\:body {
  color: var(--lns-color-body)
}

.c\:bodyDimmed {
  color: var(--lns-color-bodyDimmed)
}

.c\:background {
  color: var(--lns-color-background)
}

.c\:backgroundHover {
  color: var(--lns-color-backgroundHover)
}

.c\:backgroundActive {
  color: var(--lns-color-backgroundActive)
}

.c\:backgroundSecondary {
  color: var(--lns-color-backgroundSecondary)
}

.c\:backgroundSecondary2 {
  color: var(--lns-color-backgroundSecondary2)
}

.c\:overlay {
  color: var(--lns-color-overlay)
}

.c\:border {
  color: var(--lns-color-border)
}

.c\:focusRing {
  color: var(--lns-color-focusRing)
}

.c\:record {
  color: var(--lns-color-record)
}

.c\:recordHover {
  color: var(--lns-color-recordHover)
}

.c\:recordActive {
  color: var(--lns-color-recordActive)
}

.c\:info {
  color: var(--lns-color-info)
}

.c\:success {
  color: var(--lns-color-success)
}

.c\:warning {
  color: var(--lns-color-warning)
}

.c\:danger {
  color: var(--lns-color-danger)
}

.c\:dangerHover {
  color: var(--lns-color-dangerHover)
}

.c\:dangerActive {
  color: var(--lns-color-dangerActive)
}

.c\:backdrop {
  color: var(--lns-color-backdrop)
}

.c\:backdropDark {
  color: var(--lns-color-backdropDark)
}

.c\:backdropTwilight {
  color: var(--lns-color-backdropTwilight)
}

.c\:disabledContent {
  color: var(--lns-color-disabledContent)
}

.c\:highlight {
  color: var(--lns-color-highlight)
}

.c\:disabledBackground {
  color: var(--lns-color-disabledBackground)
}

.c\:formFieldBorder {
  color: var(--lns-color-formFieldBorder)
}

.c\:formFieldBackground {
  color: var(--lns-color-formFieldBackground)
}

.c\:buttonBorder {
  color: var(--lns-color-buttonBorder)
}

.c\:upgrade {
  color: var(--lns-color-upgrade)
}

.c\:upgradeHover {
  color: var(--lns-color-upgradeHover)
}

.c\:upgradeActive {
  color: var(--lns-color-upgradeActive)
}

.c\:tabBackground {
  color: var(--lns-color-tabBackground)
}

.c\:discoveryBackground {
  color: var(--lns-color-discoveryBackground)
}

.c\:discoveryLightBackground {
  color: var(--lns-color-discoveryLightBackground)
}

.c\:discoveryTitle {
  color: var(--lns-color-discoveryTitle)
}

.c\:discoveryHighlight {
  color: var(--lns-color-discoveryHighlight)
}

.shadow\:small {
  box-shadow: var(--lns-shadow-small)
}

.shadow\:medium {
  box-shadow: var(--lns-shadow-medium)
}

.shadow\:large {
  box-shadow: var(--lns-shadow-large)
}

.radius\:medium {
  border-radius: var(--lns-radius-medium)
}

.radius\:large {
  border-radius: var(--lns-radius-large)
}

.radius\:xlarge {
  border-radius: var(--lns-radius-xlarge)
}

.radius\:full {
  border-radius: var(--lns-radius-full)
}

.bgc\:red {
  background-color: var(--lns-color-red)
}

.bgc\:blurpleLight {
  background-color: var(--lns-color-blurpleLight)
}

.bgc\:blurpleMedium {
  background-color: var(--lns-color-blurpleMedium)
}

.bgc\:blurple {
  background-color: var(--lns-color-blurple)
}

.bgc\:blurpleDark {
  background-color: var(--lns-color-blurpleDark)
}

.bgc\:blurpleStrong {
  background-color: var(--lns-color-blurpleStrong)
}

.bgc\:offWhite {
  background-color: var(--lns-color-offWhite)
}

.bgc\:blueLight {
  background-color: var(--lns-color-blueLight)
}

.bgc\:blue {
  background-color: var(--lns-color-blue)
}

.bgc\:blueDark {
  background-color: var(--lns-color-blueDark)
}

.bgc\:orangeLight {
  background-color: var(--lns-color-orangeLight)
}

.bgc\:orange {
  background-color: var(--lns-color-orange)
}

.bgc\:orangeDark {
  background-color: var(--lns-color-orangeDark)
}

.bgc\:tealLight {
  background-color: var(--lns-color-tealLight)
}

.bgc\:teal {
  background-color: var(--lns-color-teal)
}

.bgc\:tealDark {
  background-color: var(--lns-color-tealDark)
}

.bgc\:yellowLight {
  background-color: var(--lns-color-yellowLight)
}

.bgc\:yellow {
  background-color: var(--lns-color-yellow)
}

.bgc\:yellowDark {
  background-color: var(--lns-color-yellowDark)
}

.bgc\:grey8 {
  background-color: var(--lns-color-grey8)
}

.bgc\:grey7 {
  background-color: var(--lns-color-grey7)
}

.bgc\:grey6 {
  background-color: var(--lns-color-grey6)
}

.bgc\:grey5 {
  background-color: var(--lns-color-grey5)
}

.bgc\:grey4 {
  background-color: var(--lns-color-grey4)
}

.bgc\:grey3 {
  background-color: var(--lns-color-grey3)
}

.bgc\:grey2 {
  background-color: var(--lns-color-grey2)
}

.bgc\:grey1 {
  background-color: var(--lns-color-grey1)
}

.bgc\:white {
  background-color: var(--lns-color-white)
}

.bgc\:primary {
  background-color: var(--lns-color-primary)
}

.bgc\:primaryHover {
  background-color: var(--lns-color-primaryHover)
}

.bgc\:primaryActive {
  background-color: var(--lns-color-primaryActive)
}

.bgc\:body {
  background-color: var(--lns-color-body)
}

.bgc\:bodyDimmed {
  background-color: var(--lns-color-bodyDimmed)
}

.bgc\:background {
  background-color: var(--lns-color-background)
}

.bgc\:backgroundHover {
  background-color: var(--lns-color-backgroundHover)
}

.bgc\:backgroundActive {
  background-color: var(--lns-color-backgroundActive)
}

.bgc\:backgroundSecondary {
  background-color: var(--lns-color-backgroundSecondary)
}

.bgc\:backgroundSecondary2 {
  background-color: var(--lns-color-backgroundSecondary2)
}

.bgc\:overlay {
  background-color: var(--lns-color-overlay)
}

.bgc\:border {
  background-color: var(--lns-color-border)
}

.bgc\:focusRing {
  background-color: var(--lns-color-focusRing)
}

.bgc\:record {
  background-color: var(--lns-color-record)
}

.bgc\:recordHover {
  background-color: var(--lns-color-recordHover)
}

.bgc\:recordActive {
  background-color: var(--lns-color-recordActive)
}

.bgc\:info {
  background-color: var(--lns-color-info)
}

.bgc\:success {
  background-color: var(--lns-color-success)
}

.bgc\:warning {
  background-color: var(--lns-color-warning)
}

.bgc\:danger {
  background-color: var(--lns-color-danger)
}

.bgc\:dangerHover {
  background-color: var(--lns-color-dangerHover)
}

.bgc\:dangerActive {
  background-color: var(--lns-color-dangerActive)
}

.bgc\:backdrop {
  background-color: var(--lns-color-backdrop)
}

.bgc\:backdropDark {
  background-color: var(--lns-color-backdropDark)
}

.bgc\:backdropTwilight {
  background-color: var(--lns-color-backdropTwilight)
}

.bgc\:disabledContent {
  background-color: var(--lns-color-disabledContent)
}

.bgc\:highlight {
  background-color: var(--lns-color-highlight)
}

.bgc\:disabledBackground {
  background-color: var(--lns-color-disabledBackground)
}

.bgc\:formFieldBorder {
  background-color: var(--lns-color-formFieldBorder)
}

.bgc\:formFieldBackground {
  background-color: var(--lns-color-formFieldBackground)
}

.bgc\:buttonBorder {
  background-color: var(--lns-color-buttonBorder)
}

.bgc\:upgrade {
  background-color: var(--lns-color-upgrade)
}

.bgc\:upgradeHover {
  background-color: var(--lns-color-upgradeHover)
}

.bgc\:upgradeActive {
  background-color: var(--lns-color-upgradeActive)
}

.bgc\:tabBackground {
  background-color: var(--lns-color-tabBackground)
}

.bgc\:discoveryBackground {
  background-color: var(--lns-color-discoveryBackground)
}

.bgc\:discoveryLightBackground {
  background-color: var(--lns-color-discoveryLightBackground)
}

.bgc\:discoveryTitle {
  background-color: var(--lns-color-discoveryTitle)
}

.bgc\:discoveryHighlight {
  background-color: var(--lns-color-discoveryHighlight)
}

.m\:0 {
  margin: 0
}

.m\:auto {
  margin: auto
}

.m\:xsmall {
  margin: var(--lns-space-xsmall)
}

.m\:small {
  margin: var(--lns-space-small)
}

.m\:medium {
  margin: var(--lns-space-medium)
}

.m\:large {
  margin: var(--lns-space-large)
}

.m\:xlarge {
  margin: var(--lns-space-xlarge)
}

.m\:xxlarge {
  margin: var(--lns-space-xxlarge)
}

.mt\:0 {
  margin-top: 0
}

.mt\:auto {
  margin-top: auto
}

.mt\:xsmall {
  margin-top: var(--lns-space-xsmall)
}

.mt\:small {
  margin-top: var(--lns-space-small)
}

.mt\:medium {
  margin-top: var(--lns-space-medium)
}

.mt\:large {
  margin-top: var(--lns-space-large)
}

.mt\:xlarge {
  margin-top: var(--lns-space-xlarge)
}

.mt\:xxlarge {
  margin-top: var(--lns-space-xxlarge)
}

.mb\:0 {
  margin-bottom: 0
}

.mb\:auto {
  margin-bottom: auto
}

.mb\:xsmall {
  margin-bottom: var(--lns-space-xsmall)
}

.mb\:small {
  margin-bottom: var(--lns-space-small)
}

.mb\:medium {
  margin-bottom: var(--lns-space-medium)
}

.mb\:large {
  margin-bottom: var(--lns-space-large)
}

.mb\:xlarge {
  margin-bottom: var(--lns-space-xlarge)
}

.mb\:xxlarge {
  margin-bottom: var(--lns-space-xxlarge)
}

.ml\:0 {
  margin-left: 0
}

.ml\:auto {
  margin-left: auto
}

.ml\:xsmall {
  margin-left: var(--lns-space-xsmall)
}

.ml\:small {
  margin-left: var(--lns-space-small)
}

.ml\:medium {
  margin-left: var(--lns-space-medium)
}

.ml\:large {
  margin-left: var(--lns-space-large)
}

.ml\:xlarge {
  margin-left: var(--lns-space-xlarge)
}

.ml\:xxlarge {
  margin-left: var(--lns-space-xxlarge)
}

.mr\:0 {
  margin-right: 0
}

.mr\:auto {
  margin-right: auto
}

.mr\:xsmall {
  margin-right: var(--lns-space-xsmall)
}

.mr\:small {
  margin-right: var(--lns-space-small)
}

.mr\:medium {
  margin-right: var(--lns-space-medium)
}

.mr\:large {
  margin-right: var(--lns-space-large)
}

.mr\:xlarge {
  margin-right: var(--lns-space-xlarge)
}

.mr\:xxlarge {
  margin-right: var(--lns-space-xxlarge)
}

.mx\:0 {
  margin-left: 0;
  margin-right: 0
}

.mx\:auto {
  margin-left: auto;
  margin-right: auto
}

.mx\:xsmall {
  margin-left: var(--lns-space-xsmall);
  margin-right: var(--lns-space-xsmall)
}

.mx\:small {
  margin-left: var(--lns-space-small);
  margin-right: var(--lns-space-small)
}

.mx\:medium {
  margin-left: var(--lns-space-medium);
  margin-right: var(--lns-space-medium)
}

.mx\:large {
  margin-left: var(--lns-space-large);
  margin-right: var(--lns-space-large)
}

.mx\:xlarge {
  margin-left: var(--lns-space-xlarge);
  margin-right: var(--lns-space-xlarge)
}

.mx\:xxlarge {
  margin-left: var(--lns-space-xxlarge);
  margin-right: var(--lns-space-xxlarge)
}

.my\:0 {
  margin-top: 0;
  margin-bottom: 0
}

.my\:auto {
  margin-top: auto;
  margin-bottom: auto
}

.my\:xsmall {
  margin-top: var(--lns-space-xsmall);
  margin-bottom: var(--lns-space-xsmall)
}

.my\:small {
  margin-top: var(--lns-space-small);
  margin-bottom: var(--lns-space-small)
}

.my\:medium {
  margin-top: var(--lns-space-medium);
  margin-bottom: var(--lns-space-medium)
}

.my\:large {
  margin-top: var(--lns-space-large);
  margin-bottom: var(--lns-space-large)
}

.my\:xlarge {
  margin-top: var(--lns-space-xlarge);
  margin-bottom: var(--lns-space-xlarge)
}

.my\:xxlarge {
  margin-top: var(--lns-space-xxlarge);
  margin-bottom: var(--lns-space-xxlarge)
}

.p\:0 {
  padding: 0
}

.p\:xsmall {
  padding: var(--lns-space-xsmall)
}

.p\:small {
  padding: var(--lns-space-small)
}

.p\:medium {
  padding: var(--lns-space-medium)
}

.p\:large {
  padding: var(--lns-space-large)
}

.p\:xlarge {
  padding: var(--lns-space-xlarge)
}

.p\:xxlarge {
  padding: var(--lns-space-xxlarge)
}

.pt\:0 {
  padding-top: 0
}

.pt\:xsmall {
  padding-top: var(--lns-space-xsmall)
}

.pt\:small {
  padding-top: var(--lns-space-small)
}

.pt\:medium {
  padding-top: var(--lns-space-medium)
}

.pt\:large {
  padding-top: var(--lns-space-large)
}

.pt\:xlarge {
  padding-top: var(--lns-space-xlarge)
}

.pt\:xxlarge {
  padding-top: var(--lns-space-xxlarge)
}

.pb\:0 {
  padding-bottom: 0
}

.pb\:xsmall {
  padding-bottom: var(--lns-space-xsmall)
}

.pb\:small {
  padding-bottom: var(--lns-space-small)
}

.pb\:medium {
  padding-bottom: var(--lns-space-medium)
}

.pb\:large {
  padding-bottom: var(--lns-space-large)
}

.pb\:xlarge {
  padding-bottom: var(--lns-space-xlarge)
}

.pb\:xxlarge {
  padding-bottom: var(--lns-space-xxlarge)
}

.pl\:0 {
  padding-left: 0
}

.pl\:xsmall {
  padding-left: var(--lns-space-xsmall)
}

.pl\:small {
  padding-left: var(--lns-space-small)
}

.pl\:medium {
  padding-left: var(--lns-space-medium)
}

.pl\:large {
  padding-left: var(--lns-space-large)
}

.pl\:xlarge {
  padding-left: var(--lns-space-xlarge)
}

.pl\:xxlarge {
  padding-left: var(--lns-space-xxlarge)
}

.pr\:0 {
  padding-right: 0
}

.pr\:xsmall {
  padding-right: var(--lns-space-xsmall)
}

.pr\:small {
  padding-right: var(--lns-space-small)
}

.pr\:medium {
  padding-right: var(--lns-space-medium)
}

.pr\:large {
  padding-right: var(--lns-space-large)
}

.pr\:xlarge {
  padding-right: var(--lns-space-xlarge)
}

.pr\:xxlarge {
  padding-right: var(--lns-space-xxlarge)
}

.px\:0 {
  padding-left: 0;
  padding-right: 0
}

.px\:xsmall {
  padding-left: var(--lns-space-xsmall);
  padding-right: var(--lns-space-xsmall)
}

.px\:small {
  padding-left: var(--lns-space-small);
  padding-right: var(--lns-space-small)
}

.px\:medium {
  padding-left: var(--lns-space-medium);
  padding-right: var(--lns-space-medium)
}

.px\:large {
  padding-left: var(--lns-space-large);
  padding-right: var(--lns-space-large)
}

.px\:xlarge {
  padding-left: var(--lns-space-xlarge);
  padding-right: var(--lns-space-xlarge)
}

.px\:xxlarge {
  padding-left: var(--lns-space-xxlarge);
  padding-right: var(--lns-space-xxlarge)
}

.py\:0 {
  padding-top: 0;
  padding-bottom: 0
}

.py\:xsmall {
  padding-top: var(--lns-space-xsmall);
  padding-bottom: var(--lns-space-xsmall)
}

.py\:small {
  padding-top: var(--lns-space-small);
  padding-bottom: var(--lns-space-small)
}

.py\:medium {
  padding-top: var(--lns-space-medium);
  padding-bottom: var(--lns-space-medium)
}

.py\:large {
  padding-top: var(--lns-space-large);
  padding-bottom: var(--lns-space-large)
}

.py\:xlarge {
  padding-top: var(--lns-space-xlarge);
  padding-bottom: var(--lns-space-xlarge)
}

.py\:xxlarge {
  padding-top: var(--lns-space-xxlarge);
  padding-bottom: var(--lns-space-xxlarge)
}

.text\:small {
  font-size: var(--lns-fontSize-small);
  line-height: var(--lns-lineHeight-small)
}

.text\:body-sm {
  font-size: var(--lns-fontSize-body-sm);
  line-height: var(--lns-lineHeight-body-sm)
}

.text\:medium {
  font-size: var(--lns-fontSize-medium);
  line-height: var(--lns-lineHeight-medium)
}

.text\:body-md {
  font-size: var(--lns-fontSize-body-md);
  line-height: var(--lns-lineHeight-body-md)
}

.text\:large {
  font-size: var(--lns-fontSize-large);
  line-height: var(--lns-lineHeight-large)
}

.text\:body-lg {
  font-size: var(--lns-fontSize-body-lg);
  line-height: var(--lns-lineHeight-body-lg)
}

.text\:xlarge {
  font-size: var(--lns-fontSize-xlarge);
  line-height: var(--lns-lineHeight-xlarge)
}

.text\:heading-sm {
  font-size: var(--lns-fontSize-heading-sm);
  line-height: var(--lns-lineHeight-heading-sm)
}

.text\:xxlarge {
  font-size: var(--lns-fontSize-xxlarge);
  line-height: var(--lns-lineHeight-xxlarge)
}

.text\:heading-md {
  font-size: var(--lns-fontSize-heading-md);
  line-height: var(--lns-lineHeight-heading-md)
}

.text\:xxxlarge {
  font-size: var(--lns-fontSize-xxxlarge);
  line-height: var(--lns-lineHeight-xxxlarge)
}

.text\:heading-lg {
  font-size: var(--lns-fontSize-heading-lg);
  line-height: var(--lns-lineHeight-heading-lg)
}

.weight\:book {
  font-weight: var(--lns-fontWeight-book)
}

.weight\:bold {
  font-weight: var(--lns-fontWeight-bold)
}

.text\:body {
  font-size: var(--lns-fontSize-body-md);
  line-height: var(--lns-lineHeight-body-md);
  font-weight: var(--lns-fontWeight-book)
}

.text\:title {
  font-size: var(--lns-fontSize-body-lg);
  line-height: var(--lns-lineHeight-body-lg);
  font-weight: var(--lns-fontWeight-bold)
}

.text\:mainTitle {
  font-size: var(--lns-fontSize-heading-md);
  line-height: var(--lns-lineHeight-heading-md);
  font-weight: var(--lns-fontWeight-bold)
}

.text\:left {
  text-align: left
}

.text\:right {
  text-align: right
}

.text\:center {
  text-align: center
}

.border {
  border: 1px solid var(--lns-color-border)
}

.borderTop {
  border-top: 1px solid var(--lns-color-border)
}

.borderBottom {
  border-bottom: 1px solid var(--lns-color-border)
}

.borderLeft {
  border-left: 1px solid var(--lns-color-border)
}

.borderRight {
  border-right: 1px solid var(--lns-color-border)
}

.inline {
  display: inline
}

.block {
  display: block
}

.flex {
  display: flex
}

.inlineBlock {
  display: inline-block
}

.inlineFlex {
  display: inline-flex
}

.none {
  display: none
}

.flexWrap {
  flex-wrap: wrap
}

.flexDirection\:column {
  flex-direction: column
}

.flexDirection\:row {
  flex-direction: row
}

.items\:stretch {
  align-items: stretch
}

.items\:center {
  align-items: center
}

.items\:baseline {
  align-items: baseline
}

.items\:flexStart {
  align-items: flex-start
}

.items\:flexEnd {
  align-items: flex-end
}

.items\:selfStart {
  align-items: self-start
}

.items\:selfEnd {
  align-items: self-end
}

.justify\:flexStart {
  justify-content: flex-start
}

.justify\:flexEnd {
  justify-content: flex-end
}

.justify\:center {
  justify-content: center
}

.justify\:spaceBetween {
  justify-content: space-between
}

.justify\:spaceAround {
  justify-content: space-around
}

.justify\:spaceEvenly {
  justify-content: space-evenly
}

.grow\:0 {
  flex-grow: 0
}

.grow\:1 {
  flex-grow: 1
}

.shrink\:0 {
  flex-shrink: 0
}

.shrink\:1 {
  flex-shrink: 1
}

.self\:auto {
  align-self: auto
}

.self\:flexStart {
  align-self: flex-start
}

.self\:flexEnd {
  align-self: flex-end
}

.self\:center {
  align-self: center
}

.self\:baseline {
  align-self: baseline
}

.self\:stretch {
  align-self: stretch
}

.overflow\:hidden {
  overflow: hidden
}

.overflow\:auto {
  overflow: auto
}

.relative {
  position: relative
}

.absolute {
  position: absolute
}

.sticky {
  position: sticky
}

.fixed {
  position: fixed
}

.top\:0 {
  top: 0
}

.top\:auto {
  top: auto
}

.top\:xsmall {
  top: var(--lns-space-xsmall)
}

.top\:small {
  top: var(--lns-space-small)
}

.top\:medium {
  top: var(--lns-space-medium)
}

.top\:large {
  top: var(--lns-space-large)
}

.top\:xlarge {
  top: var(--lns-space-xlarge)
}

.top\:xxlarge {
  top: var(--lns-space-xxlarge)
}

.bottom\:0 {
  bottom: 0
}

.bottom\:auto {
  bottom: auto
}

.bottom\:xsmall {
  bottom: var(--lns-space-xsmall)
}

.bottom\:small {
  bottom: var(--lns-space-small)
}

.bottom\:medium {
  bottom: var(--lns-space-medium)
}

.bottom\:large {
  bottom: var(--lns-space-large)
}

.bottom\:xlarge {
  bottom: var(--lns-space-xlarge)
}

.bottom\:xxlarge {
  bottom: var(--lns-space-xxlarge)
}

.left\:0 {
  left: 0
}

.left\:auto {
  left: auto
}

.left\:xsmall {
  left: var(--lns-space-xsmall)
}

.left\:small {
  left: var(--lns-space-small)
}

.left\:medium {
  left: var(--lns-space-medium)
}

.left\:large {
  left: var(--lns-space-large)
}

.left\:xlarge {
  left: var(--lns-space-xlarge)
}

.left\:xxlarge {
  left: var(--lns-space-xxlarge)
}

.right\:0 {
  right: 0
}

.right\:auto {
  right: auto
}

.right\:xsmall {
  right: var(--lns-space-xsmall)
}

.right\:small {
  right: var(--lns-space-small)
}

.right\:medium {
  right: var(--lns-space-medium)
}

.right\:large {
  right: var(--lns-space-large)
}

.right\:xlarge {
  right: var(--lns-space-xlarge)
}

.right\:xxlarge {
  right: var(--lns-space-xxlarge)
}

.width\:auto {
  width: auto
}

.width\:full {
  width: 100%
}

.width\:0 {
  width: 0
}

.minWidth\:0 {
  min-width: 0
}

.height\:auto {
  height: auto
}

.height\:full {
  height: 100%
}

.height\:0 {
  height: 0
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

@media(min-width:31em) {
  .xs-c\:red {
    color: var(--lns-color-red)
  }

  .xs-c\:blurpleLight {
    color: var(--lns-color-blurpleLight)
  }

  .xs-c\:blurpleMedium {
    color: var(--lns-color-blurpleMedium)
  }

  .xs-c\:blurple {
    color: var(--lns-color-blurple)
  }

  .xs-c\:blurpleDark {
    color: var(--lns-color-blurpleDark)
  }

  .xs-c\:blurpleStrong {
    color: var(--lns-color-blurpleStrong)
  }

  .xs-c\:offWhite {
    color: var(--lns-color-offWhite)
  }

  .xs-c\:blueLight {
    color: var(--lns-color-blueLight)
  }

  .xs-c\:blue {
    color: var(--lns-color-blue)
  }

  .xs-c\:blueDark {
    color: var(--lns-color-blueDark)
  }

  .xs-c\:orangeLight {
    color: var(--lns-color-orangeLight)
  }

  .xs-c\:orange {
    color: var(--lns-color-orange)
  }

  .xs-c\:orangeDark {
    color: var(--lns-color-orangeDark)
  }

  .xs-c\:tealLight {
    color: var(--lns-color-tealLight)
  }

  .xs-c\:teal {
    color: var(--lns-color-teal)
  }

  .xs-c\:tealDark {
    color: var(--lns-color-tealDark)
  }

  .xs-c\:yellowLight {
    color: var(--lns-color-yellowLight)
  }

  .xs-c\:yellow {
    color: var(--lns-color-yellow)
  }

  .xs-c\:yellowDark {
    color: var(--lns-color-yellowDark)
  }

  .xs-c\:grey8 {
    color: var(--lns-color-grey8)
  }

  .xs-c\:grey7 {
    color: var(--lns-color-grey7)
  }

  .xs-c\:grey6 {
    color: var(--lns-color-grey6)
  }

  .xs-c\:grey5 {
    color: var(--lns-color-grey5)
  }

  .xs-c\:grey4 {
    color: var(--lns-color-grey4)
  }

  .xs-c\:grey3 {
    color: var(--lns-color-grey3)
  }

  .xs-c\:grey2 {
    color: var(--lns-color-grey2)
  }

  .xs-c\:grey1 {
    color: var(--lns-color-grey1)
  }

  .xs-c\:white {
    color: var(--lns-color-white)
  }

  .xs-c\:primary {
    color: var(--lns-color-primary)
  }

  .xs-c\:primaryHover {
    color: var(--lns-color-primaryHover)
  }

  .xs-c\:primaryActive {
    color: var(--lns-color-primaryActive)
  }

  .xs-c\:body {
    color: var(--lns-color-body)
  }

  .xs-c\:bodyDimmed {
    color: var(--lns-color-bodyDimmed)
  }

  .xs-c\:background {
    color: var(--lns-color-background)
  }

  .xs-c\:backgroundHover {
    color: var(--lns-color-backgroundHover)
  }

  .xs-c\:backgroundActive {
    color: var(--lns-color-backgroundActive)
  }

  .xs-c\:backgroundSecondary {
    color: var(--lns-color-backgroundSecondary)
  }

  .xs-c\:backgroundSecondary2 {
    color: var(--lns-color-backgroundSecondary2)
  }

  .xs-c\:overlay {
    color: var(--lns-color-overlay)
  }

  .xs-c\:border {
    color: var(--lns-color-border)
  }

  .xs-c\:focusRing {
    color: var(--lns-color-focusRing)
  }

  .xs-c\:record {
    color: var(--lns-color-record)
  }

  .xs-c\:recordHover {
    color: var(--lns-color-recordHover)
  }

  .xs-c\:recordActive {
    color: var(--lns-color-recordActive)
  }

  .xs-c\:info {
    color: var(--lns-color-info)
  }

  .xs-c\:success {
    color: var(--lns-color-success)
  }

  .xs-c\:warning {
    color: var(--lns-color-warning)
  }

  .xs-c\:danger {
    color: var(--lns-color-danger)
  }

  .xs-c\:dangerHover {
    color: var(--lns-color-dangerHover)
  }

  .xs-c\:dangerActive {
    color: var(--lns-color-dangerActive)
  }

  .xs-c\:backdrop {
    color: var(--lns-color-backdrop)
  }

  .xs-c\:backdropDark {
    color: var(--lns-color-backdropDark)
  }

  .xs-c\:backdropTwilight {
    color: var(--lns-color-backdropTwilight)
  }

  .xs-c\:disabledContent {
    color: var(--lns-color-disabledContent)
  }

  .xs-c\:highlight {
    color: var(--lns-color-highlight)
  }

  .xs-c\:disabledBackground {
    color: var(--lns-color-disabledBackground)
  }

  .xs-c\:formFieldBorder {
    color: var(--lns-color-formFieldBorder)
  }

  .xs-c\:formFieldBackground {
    color: var(--lns-color-formFieldBackground)
  }

  .xs-c\:buttonBorder {
    color: var(--lns-color-buttonBorder)
  }

  .xs-c\:upgrade {
    color: var(--lns-color-upgrade)
  }

  .xs-c\:upgradeHover {
    color: var(--lns-color-upgradeHover)
  }

  .xs-c\:upgradeActive {
    color: var(--lns-color-upgradeActive)
  }

  .xs-c\:tabBackground {
    color: var(--lns-color-tabBackground)
  }

  .xs-c\:discoveryBackground {
    color: var(--lns-color-discoveryBackground)
  }

  .xs-c\:discoveryLightBackground {
    color: var(--lns-color-discoveryLightBackground)
  }

  .xs-c\:discoveryTitle {
    color: var(--lns-color-discoveryTitle)
  }

  .xs-c\:discoveryHighlight {
    color: var(--lns-color-discoveryHighlight)
  }

  .xs-shadow\:small {
    box-shadow: var(--lns-shadow-small)
  }

  .xs-shadow\:medium {
    box-shadow: var(--lns-shadow-medium)
  }

  .xs-shadow\:large {
    box-shadow: var(--lns-shadow-large)
  }

  .xs-radius\:medium {
    border-radius: var(--lns-radius-medium)
  }

  .xs-radius\:large {
    border-radius: var(--lns-radius-large)
  }

  .xs-radius\:xlarge {
    border-radius: var(--lns-radius-xlarge)
  }

  .xs-radius\:full {
    border-radius: var(--lns-radius-full)
  }

  .xs-bgc\:red {
    background-color: var(--lns-color-red)
  }

  .xs-bgc\:blurpleLight {
    background-color: var(--lns-color-blurpleLight)
  }

  .xs-bgc\:blurpleMedium {
    background-color: var(--lns-color-blurpleMedium)
  }

  .xs-bgc\:blurple {
    background-color: var(--lns-color-blurple)
  }

  .xs-bgc\:blurpleDark {
    background-color: var(--lns-color-blurpleDark)
  }

  .xs-bgc\:blurpleStrong {
    background-color: var(--lns-color-blurpleStrong)
  }

  .xs-bgc\:offWhite {
    background-color: var(--lns-color-offWhite)
  }

  .xs-bgc\:blueLight {
    background-color: var(--lns-color-blueLight)
  }

  .xs-bgc\:blue {
    background-color: var(--lns-color-blue)
  }

  .xs-bgc\:blueDark {
    background-color: var(--lns-color-blueDark)
  }

  .xs-bgc\:orangeLight {
    background-color: var(--lns-color-orangeLight)
  }

  .xs-bgc\:orange {
    background-color: var(--lns-color-orange)
  }

  .xs-bgc\:orangeDark {
    background-color: var(--lns-color-orangeDark)
  }

  .xs-bgc\:tealLight {
    background-color: var(--lns-color-tealLight)
  }

  .xs-bgc\:teal {
    background-color: var(--lns-color-teal)
  }

  .xs-bgc\:tealDark {
    background-color: var(--lns-color-tealDark)
  }

  .xs-bgc\:yellowLight {
    background-color: var(--lns-color-yellowLight)
  }

  .xs-bgc\:yellow {
    background-color: var(--lns-color-yellow)
  }

  .xs-bgc\:yellowDark {
    background-color: var(--lns-color-yellowDark)
  }

  .xs-bgc\:grey8 {
    background-color: var(--lns-color-grey8)
  }

  .xs-bgc\:grey7 {
    background-color: var(--lns-color-grey7)
  }

  .xs-bgc\:grey6 {
    background-color: var(--lns-color-grey6)
  }

  .xs-bgc\:grey5 {
    background-color: var(--lns-color-grey5)
  }

  .xs-bgc\:grey4 {
    background-color: var(--lns-color-grey4)
  }

  .xs-bgc\:grey3 {
    background-color: var(--lns-color-grey3)
  }

  .xs-bgc\:grey2 {
    background-color: var(--lns-color-grey2)
  }

  .xs-bgc\:grey1 {
    background-color: var(--lns-color-grey1)
  }

  .xs-bgc\:white {
    background-color: var(--lns-color-white)
  }

  .xs-bgc\:primary {
    background-color: var(--lns-color-primary)
  }

  .xs-bgc\:primaryHover {
    background-color: var(--lns-color-primaryHover)
  }

  .xs-bgc\:primaryActive {
    background-color: var(--lns-color-primaryActive)
  }

  .xs-bgc\:body {
    background-color: var(--lns-color-body)
  }

  .xs-bgc\:bodyDimmed {
    background-color: var(--lns-color-bodyDimmed)
  }

  .xs-bgc\:background {
    background-color: var(--lns-color-background)
  }

  .xs-bgc\:backgroundHover {
    background-color: var(--lns-color-backgroundHover)
  }

  .xs-bgc\:backgroundActive {
    background-color: var(--lns-color-backgroundActive)
  }

  .xs-bgc\:backgroundSecondary {
    background-color: var(--lns-color-backgroundSecondary)
  }

  .xs-bgc\:backgroundSecondary2 {
    background-color: var(--lns-color-backgroundSecondary2)
  }

  .xs-bgc\:overlay {
    background-color: var(--lns-color-overlay)
  }

  .xs-bgc\:border {
    background-color: var(--lns-color-border)
  }

  .xs-bgc\:focusRing {
    background-color: var(--lns-color-focusRing)
  }

  .xs-bgc\:record {
    background-color: var(--lns-color-record)
  }

  .xs-bgc\:recordHover {
    background-color: var(--lns-color-recordHover)
  }

  .xs-bgc\:recordActive {
    background-color: var(--lns-color-recordActive)
  }

  .xs-bgc\:info {
    background-color: var(--lns-color-info)
  }

  .xs-bgc\:success {
    background-color: var(--lns-color-success)
  }

  .xs-bgc\:warning {
    background-color: var(--lns-color-warning)
  }

  .xs-bgc\:danger {
    background-color: var(--lns-color-danger)
  }

  .xs-bgc\:dangerHover {
    background-color: var(--lns-color-dangerHover)
  }

  .xs-bgc\:dangerActive {
    background-color: var(--lns-color-dangerActive)
  }

  .xs-bgc\:backdrop {
    background-color: var(--lns-color-backdrop)
  }

  .xs-bgc\:backdropDark {
    background-color: var(--lns-color-backdropDark)
  }

  .xs-bgc\:backdropTwilight {
    background-color: var(--lns-color-backdropTwilight)
  }

  .xs-bgc\:disabledContent {
    background-color: var(--lns-color-disabledContent)
  }

  .xs-bgc\:highlight {
    background-color: var(--lns-color-highlight)
  }

  .xs-bgc\:disabledBackground {
    background-color: var(--lns-color-disabledBackground)
  }

  .xs-bgc\:formFieldBorder {
    background-color: var(--lns-color-formFieldBorder)
  }

  .xs-bgc\:formFieldBackground {
    background-color: var(--lns-color-formFieldBackground)
  }

  .xs-bgc\:buttonBorder {
    background-color: var(--lns-color-buttonBorder)
  }

  .xs-bgc\:upgrade {
    background-color: var(--lns-color-upgrade)
  }

  .xs-bgc\:upgradeHover {
    background-color: var(--lns-color-upgradeHover)
  }

  .xs-bgc\:upgradeActive {
    background-color: var(--lns-color-upgradeActive)
  }

  .xs-bgc\:tabBackground {
    background-color: var(--lns-color-tabBackground)
  }

  .xs-bgc\:discoveryBackground {
    background-color: var(--lns-color-discoveryBackground)
  }

  .xs-bgc\:discoveryLightBackground {
    background-color: var(--lns-color-discoveryLightBackground)
  }

  .xs-bgc\:discoveryTitle {
    background-color: var(--lns-color-discoveryTitle)
  }

  .xs-bgc\:discoveryHighlight {
    background-color: var(--lns-color-discoveryHighlight)
  }

  .xs-m\:0 {
    margin: 0
  }

  .xs-m\:auto {
    margin: auto
  }

  .xs-m\:xsmall {
    margin: var(--lns-space-xsmall)
  }

  .xs-m\:small {
    margin: var(--lns-space-small)
  }

  .xs-m\:medium {
    margin: var(--lns-space-medium)
  }

  .xs-m\:large {
    margin: var(--lns-space-large)
  }

  .xs-m\:xlarge {
    margin: var(--lns-space-xlarge)
  }

  .xs-m\:xxlarge {
    margin: var(--lns-space-xxlarge)
  }

  .xs-mt\:0 {
    margin-top: 0
  }

  .xs-mt\:auto {
    margin-top: auto
  }

  .xs-mt\:xsmall {
    margin-top: var(--lns-space-xsmall)
  }

  .xs-mt\:small {
    margin-top: var(--lns-space-small)
  }

  .xs-mt\:medium {
    margin-top: var(--lns-space-medium)
  }

  .xs-mt\:large {
    margin-top: var(--lns-space-large)
  }

  .xs-mt\:xlarge {
    margin-top: var(--lns-space-xlarge)
  }

  .xs-mt\:xxlarge {
    margin-top: var(--lns-space-xxlarge)
  }

  .xs-mb\:0 {
    margin-bottom: 0
  }

  .xs-mb\:auto {
    margin-bottom: auto
  }

  .xs-mb\:xsmall {
    margin-bottom: var(--lns-space-xsmall)
  }

  .xs-mb\:small {
    margin-bottom: var(--lns-space-small)
  }

  .xs-mb\:medium {
    margin-bottom: var(--lns-space-medium)
  }

  .xs-mb\:large {
    margin-bottom: var(--lns-space-large)
  }

  .xs-mb\:xlarge {
    margin-bottom: var(--lns-space-xlarge)
  }

  .xs-mb\:xxlarge {
    margin-bottom: var(--lns-space-xxlarge)
  }

  .xs-ml\:0 {
    margin-left: 0
  }

  .xs-ml\:auto {
    margin-left: auto
  }

  .xs-ml\:xsmall {
    margin-left: var(--lns-space-xsmall)
  }

  .xs-ml\:small {
    margin-left: var(--lns-space-small)
  }

  .xs-ml\:medium {
    margin-left: var(--lns-space-medium)
  }

  .xs-ml\:large {
    margin-left: var(--lns-space-large)
  }

  .xs-ml\:xlarge {
    margin-left: var(--lns-space-xlarge)
  }

  .xs-ml\:xxlarge {
    margin-left: var(--lns-space-xxlarge)
  }

  .xs-mr\:0 {
    margin-right: 0
  }

  .xs-mr\:auto {
    margin-right: auto
  }

  .xs-mr\:xsmall {
    margin-right: var(--lns-space-xsmall)
  }

  .xs-mr\:small {
    margin-right: var(--lns-space-small)
  }

  .xs-mr\:medium {
    margin-right: var(--lns-space-medium)
  }

  .xs-mr\:large {
    margin-right: var(--lns-space-large)
  }

  .xs-mr\:xlarge {
    margin-right: var(--lns-space-xlarge)
  }

  .xs-mr\:xxlarge {
    margin-right: var(--lns-space-xxlarge)
  }

  .xs-mx\:0 {
    margin-left: 0;
    margin-right: 0
  }

  .xs-mx\:auto {
    margin-left: auto;
    margin-right: auto
  }

  .xs-mx\:xsmall {
    margin-left: var(--lns-space-xsmall);
    margin-right: var(--lns-space-xsmall)
  }

  .xs-mx\:small {
    margin-left: var(--lns-space-small);
    margin-right: var(--lns-space-small)
  }

  .xs-mx\:medium {
    margin-left: var(--lns-space-medium);
    margin-right: var(--lns-space-medium)
  }

  .xs-mx\:large {
    margin-left: var(--lns-space-large);
    margin-right: var(--lns-space-large)
  }

  .xs-mx\:xlarge {
    margin-left: var(--lns-space-xlarge);
    margin-right: var(--lns-space-xlarge)
  }

  .xs-mx\:xxlarge {
    margin-left: var(--lns-space-xxlarge);
    margin-right: var(--lns-space-xxlarge)
  }

  .xs-my\:0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xs-my\:auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .xs-my\:xsmall {
    margin-top: var(--lns-space-xsmall);
    margin-bottom: var(--lns-space-xsmall)
  }

  .xs-my\:small {
    margin-top: var(--lns-space-small);
    margin-bottom: var(--lns-space-small)
  }

  .xs-my\:medium {
    margin-top: var(--lns-space-medium);
    margin-bottom: var(--lns-space-medium)
  }

  .xs-my\:large {
    margin-top: var(--lns-space-large);
    margin-bottom: var(--lns-space-large)
  }

  .xs-my\:xlarge {
    margin-top: var(--lns-space-xlarge);
    margin-bottom: var(--lns-space-xlarge)
  }

  .xs-my\:xxlarge {
    margin-top: var(--lns-space-xxlarge);
    margin-bottom: var(--lns-space-xxlarge)
  }

  .xs-p\:0 {
    padding: 0
  }

  .xs-p\:xsmall {
    padding: var(--lns-space-xsmall)
  }

  .xs-p\:small {
    padding: var(--lns-space-small)
  }

  .xs-p\:medium {
    padding: var(--lns-space-medium)
  }

  .xs-p\:large {
    padding: var(--lns-space-large)
  }

  .xs-p\:xlarge {
    padding: var(--lns-space-xlarge)
  }

  .xs-p\:xxlarge {
    padding: var(--lns-space-xxlarge)
  }

  .xs-pt\:0 {
    padding-top: 0
  }

  .xs-pt\:xsmall {
    padding-top: var(--lns-space-xsmall)
  }

  .xs-pt\:small {
    padding-top: var(--lns-space-small)
  }

  .xs-pt\:medium {
    padding-top: var(--lns-space-medium)
  }

  .xs-pt\:large {
    padding-top: var(--lns-space-large)
  }

  .xs-pt\:xlarge {
    padding-top: var(--lns-space-xlarge)
  }

  .xs-pt\:xxlarge {
    padding-top: var(--lns-space-xxlarge)
  }

  .xs-pb\:0 {
    padding-bottom: 0
  }

  .xs-pb\:xsmall {
    padding-bottom: var(--lns-space-xsmall)
  }

  .xs-pb\:small {
    padding-bottom: var(--lns-space-small)
  }

  .xs-pb\:medium {
    padding-bottom: var(--lns-space-medium)
  }

  .xs-pb\:large {
    padding-bottom: var(--lns-space-large)
  }

  .xs-pb\:xlarge {
    padding-bottom: var(--lns-space-xlarge)
  }

  .xs-pb\:xxlarge {
    padding-bottom: var(--lns-space-xxlarge)
  }

  .xs-pl\:0 {
    padding-left: 0
  }

  .xs-pl\:xsmall {
    padding-left: var(--lns-space-xsmall)
  }

  .xs-pl\:small {
    padding-left: var(--lns-space-small)
  }

  .xs-pl\:medium {
    padding-left: var(--lns-space-medium)
  }

  .xs-pl\:large {
    padding-left: var(--lns-space-large)
  }

  .xs-pl\:xlarge {
    padding-left: var(--lns-space-xlarge)
  }

  .xs-pl\:xxlarge {
    padding-left: var(--lns-space-xxlarge)
  }

  .xs-pr\:0 {
    padding-right: 0
  }

  .xs-pr\:xsmall {
    padding-right: var(--lns-space-xsmall)
  }

  .xs-pr\:small {
    padding-right: var(--lns-space-small)
  }

  .xs-pr\:medium {
    padding-right: var(--lns-space-medium)
  }

  .xs-pr\:large {
    padding-right: var(--lns-space-large)
  }

  .xs-pr\:xlarge {
    padding-right: var(--lns-space-xlarge)
  }

  .xs-pr\:xxlarge {
    padding-right: var(--lns-space-xxlarge)
  }

  .xs-px\:0 {
    padding-left: 0;
    padding-right: 0
  }

  .xs-px\:xsmall {
    padding-left: var(--lns-space-xsmall);
    padding-right: var(--lns-space-xsmall)
  }

  .xs-px\:small {
    padding-left: var(--lns-space-small);
    padding-right: var(--lns-space-small)
  }

  .xs-px\:medium {
    padding-left: var(--lns-space-medium);
    padding-right: var(--lns-space-medium)
  }

  .xs-px\:large {
    padding-left: var(--lns-space-large);
    padding-right: var(--lns-space-large)
  }

  .xs-px\:xlarge {
    padding-left: var(--lns-space-xlarge);
    padding-right: var(--lns-space-xlarge)
  }

  .xs-px\:xxlarge {
    padding-left: var(--lns-space-xxlarge);
    padding-right: var(--lns-space-xxlarge)
  }

  .xs-py\:0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .xs-py\:xsmall {
    padding-top: var(--lns-space-xsmall);
    padding-bottom: var(--lns-space-xsmall)
  }

  .xs-py\:small {
    padding-top: var(--lns-space-small);
    padding-bottom: var(--lns-space-small)
  }

  .xs-py\:medium {
    padding-top: var(--lns-space-medium);
    padding-bottom: var(--lns-space-medium)
  }

  .xs-py\:large {
    padding-top: var(--lns-space-large);
    padding-bottom: var(--lns-space-large)
  }

  .xs-py\:xlarge {
    padding-top: var(--lns-space-xlarge);
    padding-bottom: var(--lns-space-xlarge)
  }

  .xs-py\:xxlarge {
    padding-top: var(--lns-space-xxlarge);
    padding-bottom: var(--lns-space-xxlarge)
  }

  .xs-text\:small {
    font-size: var(--lns-fontSize-small);
    line-height: var(--lns-lineHeight-small)
  }

  .xs-text\:body-sm {
    font-size: var(--lns-fontSize-body-sm);
    line-height: var(--lns-lineHeight-body-sm)
  }

  .xs-text\:medium {
    font-size: var(--lns-fontSize-medium);
    line-height: var(--lns-lineHeight-medium)
  }

  .xs-text\:body-md {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md)
  }

  .xs-text\:large {
    font-size: var(--lns-fontSize-large);
    line-height: var(--lns-lineHeight-large)
  }

  .xs-text\:body-lg {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg)
  }

  .xs-text\:xlarge {
    font-size: var(--lns-fontSize-xlarge);
    line-height: var(--lns-lineHeight-xlarge)
  }

  .xs-text\:heading-sm {
    font-size: var(--lns-fontSize-heading-sm);
    line-height: var(--lns-lineHeight-heading-sm)
  }

  .xs-text\:xxlarge {
    font-size: var(--lns-fontSize-xxlarge);
    line-height: var(--lns-lineHeight-xxlarge)
  }

  .xs-text\:heading-md {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md)
  }

  .xs-text\:xxxlarge {
    font-size: var(--lns-fontSize-xxxlarge);
    line-height: var(--lns-lineHeight-xxxlarge)
  }

  .xs-text\:heading-lg {
    font-size: var(--lns-fontSize-heading-lg);
    line-height: var(--lns-lineHeight-heading-lg)
  }

  .xs-weight\:book {
    font-weight: var(--lns-fontWeight-book)
  }

  .xs-weight\:bold {
    font-weight: var(--lns-fontWeight-bold)
  }

  .xs-text\:body {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md);
    font-weight: var(--lns-fontWeight-book)
  }

  .xs-text\:title {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg);
    font-weight: var(--lns-fontWeight-bold)
  }

  .xs-text\:mainTitle {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md);
    font-weight: var(--lns-fontWeight-bold)
  }

  .xs-text\:left {
    text-align: left
  }

  .xs-text\:right {
    text-align: right
  }

  .xs-text\:center {
    text-align: center
  }

  .xs-border {
    border: 1px solid var(--lns-color-border)
  }

  .xs-borderTop {
    border-top: 1px solid var(--lns-color-border)
  }

  .xs-borderBottom {
    border-bottom: 1px solid var(--lns-color-border)
  }

  .xs-borderLeft {
    border-left: 1px solid var(--lns-color-border)
  }

  .xs-borderRight {
    border-right: 1px solid var(--lns-color-border)
  }

  .xs-inline {
    display: inline
  }

  .xs-block {
    display: block
  }

  .xs-flex {
    display: flex
  }

  .xs-inlineBlock {
    display: inline-block
  }

  .xs-inlineFlex {
    display: inline-flex
  }

  .xs-none {
    display: none
  }

  .xs-flexWrap {
    flex-wrap: wrap
  }

  .xs-flexDirection\:column {
    flex-direction: column
  }

  .xs-flexDirection\:row {
    flex-direction: row
  }

  .xs-items\:stretch {
    align-items: stretch
  }

  .xs-items\:center {
    align-items: center
  }

  .xs-items\:baseline {
    align-items: baseline
  }

  .xs-items\:flexStart {
    align-items: flex-start
  }

  .xs-items\:flexEnd {
    align-items: flex-end
  }

  .xs-items\:selfStart {
    align-items: self-start
  }

  .xs-items\:selfEnd {
    align-items: self-end
  }

  .xs-justify\:flexStart {
    justify-content: flex-start
  }

  .xs-justify\:flexEnd {
    justify-content: flex-end
  }

  .xs-justify\:center {
    justify-content: center
  }

  .xs-justify\:spaceBetween {
    justify-content: space-between
  }

  .xs-justify\:spaceAround {
    justify-content: space-around
  }

  .xs-justify\:spaceEvenly {
    justify-content: space-evenly
  }

  .xs-grow\:0 {
    flex-grow: 0
  }

  .xs-grow\:1 {
    flex-grow: 1
  }

  .xs-shrink\:0 {
    flex-shrink: 0
  }

  .xs-shrink\:1 {
    flex-shrink: 1
  }

  .xs-self\:auto {
    align-self: auto
  }

  .xs-self\:flexStart {
    align-self: flex-start
  }

  .xs-self\:flexEnd {
    align-self: flex-end
  }

  .xs-self\:center {
    align-self: center
  }

  .xs-self\:baseline {
    align-self: baseline
  }

  .xs-self\:stretch {
    align-self: stretch
  }

  .xs-overflow\:hidden {
    overflow: hidden
  }

  .xs-overflow\:auto {
    overflow: auto
  }

  .xs-relative {
    position: relative
  }

  .xs-absolute {
    position: absolute
  }

  .xs-sticky {
    position: sticky
  }

  .xs-fixed {
    position: fixed
  }

  .xs-top\:0 {
    top: 0
  }

  .xs-top\:auto {
    top: auto
  }

  .xs-top\:xsmall {
    top: var(--lns-space-xsmall)
  }

  .xs-top\:small {
    top: var(--lns-space-small)
  }

  .xs-top\:medium {
    top: var(--lns-space-medium)
  }

  .xs-top\:large {
    top: var(--lns-space-large)
  }

  .xs-top\:xlarge {
    top: var(--lns-space-xlarge)
  }

  .xs-top\:xxlarge {
    top: var(--lns-space-xxlarge)
  }

  .xs-bottom\:0 {
    bottom: 0
  }

  .xs-bottom\:auto {
    bottom: auto
  }

  .xs-bottom\:xsmall {
    bottom: var(--lns-space-xsmall)
  }

  .xs-bottom\:small {
    bottom: var(--lns-space-small)
  }

  .xs-bottom\:medium {
    bottom: var(--lns-space-medium)
  }

  .xs-bottom\:large {
    bottom: var(--lns-space-large)
  }

  .xs-bottom\:xlarge {
    bottom: var(--lns-space-xlarge)
  }

  .xs-bottom\:xxlarge {
    bottom: var(--lns-space-xxlarge)
  }

  .xs-left\:0 {
    left: 0
  }

  .xs-left\:auto {
    left: auto
  }

  .xs-left\:xsmall {
    left: var(--lns-space-xsmall)
  }

  .xs-left\:small {
    left: var(--lns-space-small)
  }

  .xs-left\:medium {
    left: var(--lns-space-medium)
  }

  .xs-left\:large {
    left: var(--lns-space-large)
  }

  .xs-left\:xlarge {
    left: var(--lns-space-xlarge)
  }

  .xs-left\:xxlarge {
    left: var(--lns-space-xxlarge)
  }

  .xs-right\:0 {
    right: 0
  }

  .xs-right\:auto {
    right: auto
  }

  .xs-right\:xsmall {
    right: var(--lns-space-xsmall)
  }

  .xs-right\:small {
    right: var(--lns-space-small)
  }

  .xs-right\:medium {
    right: var(--lns-space-medium)
  }

  .xs-right\:large {
    right: var(--lns-space-large)
  }

  .xs-right\:xlarge {
    right: var(--lns-space-xlarge)
  }

  .xs-right\:xxlarge {
    right: var(--lns-space-xxlarge)
  }

  .xs-width\:auto {
    width: auto
  }

  .xs-width\:full {
    width: 100%
  }

  .xs-width\:0 {
    width: 0
  }

  .xs-minWidth\:0 {
    min-width: 0
  }

  .xs-height\:auto {
    height: auto
  }

  .xs-height\:full {
    height: 100%
  }

  .xs-height\:0 {
    height: 0
  }

  .xs-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .xs-srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }
}

@media(min-width:48em) {
  .sm-c\:red {
    color: var(--lns-color-red)
  }

  .sm-c\:blurpleLight {
    color: var(--lns-color-blurpleLight)
  }

  .sm-c\:blurpleMedium {
    color: var(--lns-color-blurpleMedium)
  }

  .sm-c\:blurple {
    color: var(--lns-color-blurple)
  }

  .sm-c\:blurpleDark {
    color: var(--lns-color-blurpleDark)
  }

  .sm-c\:blurpleStrong {
    color: var(--lns-color-blurpleStrong)
  }

  .sm-c\:offWhite {
    color: var(--lns-color-offWhite)
  }

  .sm-c\:blueLight {
    color: var(--lns-color-blueLight)
  }

  .sm-c\:blue {
    color: var(--lns-color-blue)
  }

  .sm-c\:blueDark {
    color: var(--lns-color-blueDark)
  }

  .sm-c\:orangeLight {
    color: var(--lns-color-orangeLight)
  }

  .sm-c\:orange {
    color: var(--lns-color-orange)
  }

  .sm-c\:orangeDark {
    color: var(--lns-color-orangeDark)
  }

  .sm-c\:tealLight {
    color: var(--lns-color-tealLight)
  }

  .sm-c\:teal {
    color: var(--lns-color-teal)
  }

  .sm-c\:tealDark {
    color: var(--lns-color-tealDark)
  }

  .sm-c\:yellowLight {
    color: var(--lns-color-yellowLight)
  }

  .sm-c\:yellow {
    color: var(--lns-color-yellow)
  }

  .sm-c\:yellowDark {
    color: var(--lns-color-yellowDark)
  }

  .sm-c\:grey8 {
    color: var(--lns-color-grey8)
  }

  .sm-c\:grey7 {
    color: var(--lns-color-grey7)
  }

  .sm-c\:grey6 {
    color: var(--lns-color-grey6)
  }

  .sm-c\:grey5 {
    color: var(--lns-color-grey5)
  }

  .sm-c\:grey4 {
    color: var(--lns-color-grey4)
  }

  .sm-c\:grey3 {
    color: var(--lns-color-grey3)
  }

  .sm-c\:grey2 {
    color: var(--lns-color-grey2)
  }

  .sm-c\:grey1 {
    color: var(--lns-color-grey1)
  }

  .sm-c\:white {
    color: var(--lns-color-white)
  }

  .sm-c\:primary {
    color: var(--lns-color-primary)
  }

  .sm-c\:primaryHover {
    color: var(--lns-color-primaryHover)
  }

  .sm-c\:primaryActive {
    color: var(--lns-color-primaryActive)
  }

  .sm-c\:body {
    color: var(--lns-color-body)
  }

  .sm-c\:bodyDimmed {
    color: var(--lns-color-bodyDimmed)
  }

  .sm-c\:background {
    color: var(--lns-color-background)
  }

  .sm-c\:backgroundHover {
    color: var(--lns-color-backgroundHover)
  }

  .sm-c\:backgroundActive {
    color: var(--lns-color-backgroundActive)
  }

  .sm-c\:backgroundSecondary {
    color: var(--lns-color-backgroundSecondary)
  }

  .sm-c\:backgroundSecondary2 {
    color: var(--lns-color-backgroundSecondary2)
  }

  .sm-c\:overlay {
    color: var(--lns-color-overlay)
  }

  .sm-c\:border {
    color: var(--lns-color-border)
  }

  .sm-c\:focusRing {
    color: var(--lns-color-focusRing)
  }

  .sm-c\:record {
    color: var(--lns-color-record)
  }

  .sm-c\:recordHover {
    color: var(--lns-color-recordHover)
  }

  .sm-c\:recordActive {
    color: var(--lns-color-recordActive)
  }

  .sm-c\:info {
    color: var(--lns-color-info)
  }

  .sm-c\:success {
    color: var(--lns-color-success)
  }

  .sm-c\:warning {
    color: var(--lns-color-warning)
  }

  .sm-c\:danger {
    color: var(--lns-color-danger)
  }

  .sm-c\:dangerHover {
    color: var(--lns-color-dangerHover)
  }

  .sm-c\:dangerActive {
    color: var(--lns-color-dangerActive)
  }

  .sm-c\:backdrop {
    color: var(--lns-color-backdrop)
  }

  .sm-c\:backdropDark {
    color: var(--lns-color-backdropDark)
  }

  .sm-c\:backdropTwilight {
    color: var(--lns-color-backdropTwilight)
  }

  .sm-c\:disabledContent {
    color: var(--lns-color-disabledContent)
  }

  .sm-c\:highlight {
    color: var(--lns-color-highlight)
  }

  .sm-c\:disabledBackground {
    color: var(--lns-color-disabledBackground)
  }

  .sm-c\:formFieldBorder {
    color: var(--lns-color-formFieldBorder)
  }

  .sm-c\:formFieldBackground {
    color: var(--lns-color-formFieldBackground)
  }

  .sm-c\:buttonBorder {
    color: var(--lns-color-buttonBorder)
  }

  .sm-c\:upgrade {
    color: var(--lns-color-upgrade)
  }

  .sm-c\:upgradeHover {
    color: var(--lns-color-upgradeHover)
  }

  .sm-c\:upgradeActive {
    color: var(--lns-color-upgradeActive)
  }

  .sm-c\:tabBackground {
    color: var(--lns-color-tabBackground)
  }

  .sm-c\:discoveryBackground {
    color: var(--lns-color-discoveryBackground)
  }

  .sm-c\:discoveryLightBackground {
    color: var(--lns-color-discoveryLightBackground)
  }

  .sm-c\:discoveryTitle {
    color: var(--lns-color-discoveryTitle)
  }

  .sm-c\:discoveryHighlight {
    color: var(--lns-color-discoveryHighlight)
  }

  .sm-shadow\:small {
    box-shadow: var(--lns-shadow-small)
  }

  .sm-shadow\:medium {
    box-shadow: var(--lns-shadow-medium)
  }

  .sm-shadow\:large {
    box-shadow: var(--lns-shadow-large)
  }

  .sm-radius\:medium {
    border-radius: var(--lns-radius-medium)
  }

  .sm-radius\:large {
    border-radius: var(--lns-radius-large)
  }

  .sm-radius\:xlarge {
    border-radius: var(--lns-radius-xlarge)
  }

  .sm-radius\:full {
    border-radius: var(--lns-radius-full)
  }

  .sm-bgc\:red {
    background-color: var(--lns-color-red)
  }

  .sm-bgc\:blurpleLight {
    background-color: var(--lns-color-blurpleLight)
  }

  .sm-bgc\:blurpleMedium {
    background-color: var(--lns-color-blurpleMedium)
  }

  .sm-bgc\:blurple {
    background-color: var(--lns-color-blurple)
  }

  .sm-bgc\:blurpleDark {
    background-color: var(--lns-color-blurpleDark)
  }

  .sm-bgc\:blurpleStrong {
    background-color: var(--lns-color-blurpleStrong)
  }

  .sm-bgc\:offWhite {
    background-color: var(--lns-color-offWhite)
  }

  .sm-bgc\:blueLight {
    background-color: var(--lns-color-blueLight)
  }

  .sm-bgc\:blue {
    background-color: var(--lns-color-blue)
  }

  .sm-bgc\:blueDark {
    background-color: var(--lns-color-blueDark)
  }

  .sm-bgc\:orangeLight {
    background-color: var(--lns-color-orangeLight)
  }

  .sm-bgc\:orange {
    background-color: var(--lns-color-orange)
  }

  .sm-bgc\:orangeDark {
    background-color: var(--lns-color-orangeDark)
  }

  .sm-bgc\:tealLight {
    background-color: var(--lns-color-tealLight)
  }

  .sm-bgc\:teal {
    background-color: var(--lns-color-teal)
  }

  .sm-bgc\:tealDark {
    background-color: var(--lns-color-tealDark)
  }

  .sm-bgc\:yellowLight {
    background-color: var(--lns-color-yellowLight)
  }

  .sm-bgc\:yellow {
    background-color: var(--lns-color-yellow)
  }

  .sm-bgc\:yellowDark {
    background-color: var(--lns-color-yellowDark)
  }

  .sm-bgc\:grey8 {
    background-color: var(--lns-color-grey8)
  }

  .sm-bgc\:grey7 {
    background-color: var(--lns-color-grey7)
  }

  .sm-bgc\:grey6 {
    background-color: var(--lns-color-grey6)
  }

  .sm-bgc\:grey5 {
    background-color: var(--lns-color-grey5)
  }

  .sm-bgc\:grey4 {
    background-color: var(--lns-color-grey4)
  }

  .sm-bgc\:grey3 {
    background-color: var(--lns-color-grey3)
  }

  .sm-bgc\:grey2 {
    background-color: var(--lns-color-grey2)
  }

  .sm-bgc\:grey1 {
    background-color: var(--lns-color-grey1)
  }

  .sm-bgc\:white {
    background-color: var(--lns-color-white)
  }

  .sm-bgc\:primary {
    background-color: var(--lns-color-primary)
  }

  .sm-bgc\:primaryHover {
    background-color: var(--lns-color-primaryHover)
  }

  .sm-bgc\:primaryActive {
    background-color: var(--lns-color-primaryActive)
  }

  .sm-bgc\:body {
    background-color: var(--lns-color-body)
  }

  .sm-bgc\:bodyDimmed {
    background-color: var(--lns-color-bodyDimmed)
  }

  .sm-bgc\:background {
    background-color: var(--lns-color-background)
  }

  .sm-bgc\:backgroundHover {
    background-color: var(--lns-color-backgroundHover)
  }

  .sm-bgc\:backgroundActive {
    background-color: var(--lns-color-backgroundActive)
  }

  .sm-bgc\:backgroundSecondary {
    background-color: var(--lns-color-backgroundSecondary)
  }

  .sm-bgc\:backgroundSecondary2 {
    background-color: var(--lns-color-backgroundSecondary2)
  }

  .sm-bgc\:overlay {
    background-color: var(--lns-color-overlay)
  }

  .sm-bgc\:border {
    background-color: var(--lns-color-border)
  }

  .sm-bgc\:focusRing {
    background-color: var(--lns-color-focusRing)
  }

  .sm-bgc\:record {
    background-color: var(--lns-color-record)
  }

  .sm-bgc\:recordHover {
    background-color: var(--lns-color-recordHover)
  }

  .sm-bgc\:recordActive {
    background-color: var(--lns-color-recordActive)
  }

  .sm-bgc\:info {
    background-color: var(--lns-color-info)
  }

  .sm-bgc\:success {
    background-color: var(--lns-color-success)
  }

  .sm-bgc\:warning {
    background-color: var(--lns-color-warning)
  }

  .sm-bgc\:danger {
    background-color: var(--lns-color-danger)
  }

  .sm-bgc\:dangerHover {
    background-color: var(--lns-color-dangerHover)
  }

  .sm-bgc\:dangerActive {
    background-color: var(--lns-color-dangerActive)
  }

  .sm-bgc\:backdrop {
    background-color: var(--lns-color-backdrop)
  }

  .sm-bgc\:backdropDark {
    background-color: var(--lns-color-backdropDark)
  }

  .sm-bgc\:backdropTwilight {
    background-color: var(--lns-color-backdropTwilight)
  }

  .sm-bgc\:disabledContent {
    background-color: var(--lns-color-disabledContent)
  }

  .sm-bgc\:highlight {
    background-color: var(--lns-color-highlight)
  }

  .sm-bgc\:disabledBackground {
    background-color: var(--lns-color-disabledBackground)
  }

  .sm-bgc\:formFieldBorder {
    background-color: var(--lns-color-formFieldBorder)
  }

  .sm-bgc\:formFieldBackground {
    background-color: var(--lns-color-formFieldBackground)
  }

  .sm-bgc\:buttonBorder {
    background-color: var(--lns-color-buttonBorder)
  }

  .sm-bgc\:upgrade {
    background-color: var(--lns-color-upgrade)
  }

  .sm-bgc\:upgradeHover {
    background-color: var(--lns-color-upgradeHover)
  }

  .sm-bgc\:upgradeActive {
    background-color: var(--lns-color-upgradeActive)
  }

  .sm-bgc\:tabBackground {
    background-color: var(--lns-color-tabBackground)
  }

  .sm-bgc\:discoveryBackground {
    background-color: var(--lns-color-discoveryBackground)
  }

  .sm-bgc\:discoveryLightBackground {
    background-color: var(--lns-color-discoveryLightBackground)
  }

  .sm-bgc\:discoveryTitle {
    background-color: var(--lns-color-discoveryTitle)
  }

  .sm-bgc\:discoveryHighlight {
    background-color: var(--lns-color-discoveryHighlight)
  }

  .sm-m\:0 {
    margin: 0
  }

  .sm-m\:auto {
    margin: auto
  }

  .sm-m\:xsmall {
    margin: var(--lns-space-xsmall)
  }

  .sm-m\:small {
    margin: var(--lns-space-small)
  }

  .sm-m\:medium {
    margin: var(--lns-space-medium)
  }

  .sm-m\:large {
    margin: var(--lns-space-large)
  }

  .sm-m\:xlarge {
    margin: var(--lns-space-xlarge)
  }

  .sm-m\:xxlarge {
    margin: var(--lns-space-xxlarge)
  }

  .sm-mt\:0 {
    margin-top: 0
  }

  .sm-mt\:auto {
    margin-top: auto
  }

  .sm-mt\:xsmall {
    margin-top: var(--lns-space-xsmall)
  }

  .sm-mt\:small {
    margin-top: var(--lns-space-small)
  }

  .sm-mt\:medium {
    margin-top: var(--lns-space-medium)
  }

  .sm-mt\:large {
    margin-top: var(--lns-space-large)
  }

  .sm-mt\:xlarge {
    margin-top: var(--lns-space-xlarge)
  }

  .sm-mt\:xxlarge {
    margin-top: var(--lns-space-xxlarge)
  }

  .sm-mb\:0 {
    margin-bottom: 0
  }

  .sm-mb\:auto {
    margin-bottom: auto
  }

  .sm-mb\:xsmall {
    margin-bottom: var(--lns-space-xsmall)
  }

  .sm-mb\:small {
    margin-bottom: var(--lns-space-small)
  }

  .sm-mb\:medium {
    margin-bottom: var(--lns-space-medium)
  }

  .sm-mb\:large {
    margin-bottom: var(--lns-space-large)
  }

  .sm-mb\:xlarge {
    margin-bottom: var(--lns-space-xlarge)
  }

  .sm-mb\:xxlarge {
    margin-bottom: var(--lns-space-xxlarge)
  }

  .sm-ml\:0 {
    margin-left: 0
  }

  .sm-ml\:auto {
    margin-left: auto
  }

  .sm-ml\:xsmall {
    margin-left: var(--lns-space-xsmall)
  }

  .sm-ml\:small {
    margin-left: var(--lns-space-small)
  }

  .sm-ml\:medium {
    margin-left: var(--lns-space-medium)
  }

  .sm-ml\:large {
    margin-left: var(--lns-space-large)
  }

  .sm-ml\:xlarge {
    margin-left: var(--lns-space-xlarge)
  }

  .sm-ml\:xxlarge {
    margin-left: var(--lns-space-xxlarge)
  }

  .sm-mr\:0 {
    margin-right: 0
  }

  .sm-mr\:auto {
    margin-right: auto
  }

  .sm-mr\:xsmall {
    margin-right: var(--lns-space-xsmall)
  }

  .sm-mr\:small {
    margin-right: var(--lns-space-small)
  }

  .sm-mr\:medium {
    margin-right: var(--lns-space-medium)
  }

  .sm-mr\:large {
    margin-right: var(--lns-space-large)
  }

  .sm-mr\:xlarge {
    margin-right: var(--lns-space-xlarge)
  }

  .sm-mr\:xxlarge {
    margin-right: var(--lns-space-xxlarge)
  }

  .sm-mx\:0 {
    margin-left: 0;
    margin-right: 0
  }

  .sm-mx\:auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm-mx\:xsmall {
    margin-left: var(--lns-space-xsmall);
    margin-right: var(--lns-space-xsmall)
  }

  .sm-mx\:small {
    margin-left: var(--lns-space-small);
    margin-right: var(--lns-space-small)
  }

  .sm-mx\:medium {
    margin-left: var(--lns-space-medium);
    margin-right: var(--lns-space-medium)
  }

  .sm-mx\:large {
    margin-left: var(--lns-space-large);
    margin-right: var(--lns-space-large)
  }

  .sm-mx\:xlarge {
    margin-left: var(--lns-space-xlarge);
    margin-right: var(--lns-space-xlarge)
  }

  .sm-mx\:xxlarge {
    margin-left: var(--lns-space-xxlarge);
    margin-right: var(--lns-space-xxlarge)
  }

  .sm-my\:0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .sm-my\:auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .sm-my\:xsmall {
    margin-top: var(--lns-space-xsmall);
    margin-bottom: var(--lns-space-xsmall)
  }

  .sm-my\:small {
    margin-top: var(--lns-space-small);
    margin-bottom: var(--lns-space-small)
  }

  .sm-my\:medium {
    margin-top: var(--lns-space-medium);
    margin-bottom: var(--lns-space-medium)
  }

  .sm-my\:large {
    margin-top: var(--lns-space-large);
    margin-bottom: var(--lns-space-large)
  }

  .sm-my\:xlarge {
    margin-top: var(--lns-space-xlarge);
    margin-bottom: var(--lns-space-xlarge)
  }

  .sm-my\:xxlarge {
    margin-top: var(--lns-space-xxlarge);
    margin-bottom: var(--lns-space-xxlarge)
  }

  .sm-p\:0 {
    padding: 0
  }

  .sm-p\:xsmall {
    padding: var(--lns-space-xsmall)
  }

  .sm-p\:small {
    padding: var(--lns-space-small)
  }

  .sm-p\:medium {
    padding: var(--lns-space-medium)
  }

  .sm-p\:large {
    padding: var(--lns-space-large)
  }

  .sm-p\:xlarge {
    padding: var(--lns-space-xlarge)
  }

  .sm-p\:xxlarge {
    padding: var(--lns-space-xxlarge)
  }

  .sm-pt\:0 {
    padding-top: 0
  }

  .sm-pt\:xsmall {
    padding-top: var(--lns-space-xsmall)
  }

  .sm-pt\:small {
    padding-top: var(--lns-space-small)
  }

  .sm-pt\:medium {
    padding-top: var(--lns-space-medium)
  }

  .sm-pt\:large {
    padding-top: var(--lns-space-large)
  }

  .sm-pt\:xlarge {
    padding-top: var(--lns-space-xlarge)
  }

  .sm-pt\:xxlarge {
    padding-top: var(--lns-space-xxlarge)
  }

  .sm-pb\:0 {
    padding-bottom: 0
  }

  .sm-pb\:xsmall {
    padding-bottom: var(--lns-space-xsmall)
  }

  .sm-pb\:small {
    padding-bottom: var(--lns-space-small)
  }

  .sm-pb\:medium {
    padding-bottom: var(--lns-space-medium)
  }

  .sm-pb\:large {
    padding-bottom: var(--lns-space-large)
  }

  .sm-pb\:xlarge {
    padding-bottom: var(--lns-space-xlarge)
  }

  .sm-pb\:xxlarge {
    padding-bottom: var(--lns-space-xxlarge)
  }

  .sm-pl\:0 {
    padding-left: 0
  }

  .sm-pl\:xsmall {
    padding-left: var(--lns-space-xsmall)
  }

  .sm-pl\:small {
    padding-left: var(--lns-space-small)
  }

  .sm-pl\:medium {
    padding-left: var(--lns-space-medium)
  }

  .sm-pl\:large {
    padding-left: var(--lns-space-large)
  }

  .sm-pl\:xlarge {
    padding-left: var(--lns-space-xlarge)
  }

  .sm-pl\:xxlarge {
    padding-left: var(--lns-space-xxlarge)
  }

  .sm-pr\:0 {
    padding-right: 0
  }

  .sm-pr\:xsmall {
    padding-right: var(--lns-space-xsmall)
  }

  .sm-pr\:small {
    padding-right: var(--lns-space-small)
  }

  .sm-pr\:medium {
    padding-right: var(--lns-space-medium)
  }

  .sm-pr\:large {
    padding-right: var(--lns-space-large)
  }

  .sm-pr\:xlarge {
    padding-right: var(--lns-space-xlarge)
  }

  .sm-pr\:xxlarge {
    padding-right: var(--lns-space-xxlarge)
  }

  .sm-px\:0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm-px\:xsmall {
    padding-left: var(--lns-space-xsmall);
    padding-right: var(--lns-space-xsmall)
  }

  .sm-px\:small {
    padding-left: var(--lns-space-small);
    padding-right: var(--lns-space-small)
  }

  .sm-px\:medium {
    padding-left: var(--lns-space-medium);
    padding-right: var(--lns-space-medium)
  }

  .sm-px\:large {
    padding-left: var(--lns-space-large);
    padding-right: var(--lns-space-large)
  }

  .sm-px\:xlarge {
    padding-left: var(--lns-space-xlarge);
    padding-right: var(--lns-space-xlarge)
  }

  .sm-px\:xxlarge {
    padding-left: var(--lns-space-xxlarge);
    padding-right: var(--lns-space-xxlarge)
  }

  .sm-py\:0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm-py\:xsmall {
    padding-top: var(--lns-space-xsmall);
    padding-bottom: var(--lns-space-xsmall)
  }

  .sm-py\:small {
    padding-top: var(--lns-space-small);
    padding-bottom: var(--lns-space-small)
  }

  .sm-py\:medium {
    padding-top: var(--lns-space-medium);
    padding-bottom: var(--lns-space-medium)
  }

  .sm-py\:large {
    padding-top: var(--lns-space-large);
    padding-bottom: var(--lns-space-large)
  }

  .sm-py\:xlarge {
    padding-top: var(--lns-space-xlarge);
    padding-bottom: var(--lns-space-xlarge)
  }

  .sm-py\:xxlarge {
    padding-top: var(--lns-space-xxlarge);
    padding-bottom: var(--lns-space-xxlarge)
  }

  .sm-text\:small {
    font-size: var(--lns-fontSize-small);
    line-height: var(--lns-lineHeight-small)
  }

  .sm-text\:body-sm {
    font-size: var(--lns-fontSize-body-sm);
    line-height: var(--lns-lineHeight-body-sm)
  }

  .sm-text\:medium {
    font-size: var(--lns-fontSize-medium);
    line-height: var(--lns-lineHeight-medium)
  }

  .sm-text\:body-md {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md)
  }

  .sm-text\:large {
    font-size: var(--lns-fontSize-large);
    line-height: var(--lns-lineHeight-large)
  }

  .sm-text\:body-lg {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg)
  }

  .sm-text\:xlarge {
    font-size: var(--lns-fontSize-xlarge);
    line-height: var(--lns-lineHeight-xlarge)
  }

  .sm-text\:heading-sm {
    font-size: var(--lns-fontSize-heading-sm);
    line-height: var(--lns-lineHeight-heading-sm)
  }

  .sm-text\:xxlarge {
    font-size: var(--lns-fontSize-xxlarge);
    line-height: var(--lns-lineHeight-xxlarge)
  }

  .sm-text\:heading-md {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md)
  }

  .sm-text\:xxxlarge {
    font-size: var(--lns-fontSize-xxxlarge);
    line-height: var(--lns-lineHeight-xxxlarge)
  }

  .sm-text\:heading-lg {
    font-size: var(--lns-fontSize-heading-lg);
    line-height: var(--lns-lineHeight-heading-lg)
  }

  .sm-weight\:book {
    font-weight: var(--lns-fontWeight-book)
  }

  .sm-weight\:bold {
    font-weight: var(--lns-fontWeight-bold)
  }

  .sm-text\:body {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md);
    font-weight: var(--lns-fontWeight-book)
  }

  .sm-text\:title {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg);
    font-weight: var(--lns-fontWeight-bold)
  }

  .sm-text\:mainTitle {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md);
    font-weight: var(--lns-fontWeight-bold)
  }

  .sm-text\:left {
    text-align: left
  }

  .sm-text\:right {
    text-align: right
  }

  .sm-text\:center {
    text-align: center
  }

  .sm-border {
    border: 1px solid var(--lns-color-border)
  }

  .sm-borderTop {
    border-top: 1px solid var(--lns-color-border)
  }

  .sm-borderBottom {
    border-bottom: 1px solid var(--lns-color-border)
  }

  .sm-borderLeft {
    border-left: 1px solid var(--lns-color-border)
  }

  .sm-borderRight {
    border-right: 1px solid var(--lns-color-border)
  }

  .sm-inline {
    display: inline
  }

  .sm-block {
    display: block
  }

  .sm-flex {
    display: flex
  }

  .sm-inlineBlock {
    display: inline-block
  }

  .sm-inlineFlex {
    display: inline-flex
  }

  .sm-none {
    display: none
  }

  .sm-flexWrap {
    flex-wrap: wrap
  }

  .sm-flexDirection\:column {
    flex-direction: column
  }

  .sm-flexDirection\:row {
    flex-direction: row
  }

  .sm-items\:stretch {
    align-items: stretch
  }

  .sm-items\:center {
    align-items: center
  }

  .sm-items\:baseline {
    align-items: baseline
  }

  .sm-items\:flexStart {
    align-items: flex-start
  }

  .sm-items\:flexEnd {
    align-items: flex-end
  }

  .sm-items\:selfStart {
    align-items: self-start
  }

  .sm-items\:selfEnd {
    align-items: self-end
  }

  .sm-justify\:flexStart {
    justify-content: flex-start
  }

  .sm-justify\:flexEnd {
    justify-content: flex-end
  }

  .sm-justify\:center {
    justify-content: center
  }

  .sm-justify\:spaceBetween {
    justify-content: space-between
  }

  .sm-justify\:spaceAround {
    justify-content: space-around
  }

  .sm-justify\:spaceEvenly {
    justify-content: space-evenly
  }

  .sm-grow\:0 {
    flex-grow: 0
  }

  .sm-grow\:1 {
    flex-grow: 1
  }

  .sm-shrink\:0 {
    flex-shrink: 0
  }

  .sm-shrink\:1 {
    flex-shrink: 1
  }

  .sm-self\:auto {
    align-self: auto
  }

  .sm-self\:flexStart {
    align-self: flex-start
  }

  .sm-self\:flexEnd {
    align-self: flex-end
  }

  .sm-self\:center {
    align-self: center
  }

  .sm-self\:baseline {
    align-self: baseline
  }

  .sm-self\:stretch {
    align-self: stretch
  }

  .sm-overflow\:hidden {
    overflow: hidden
  }

  .sm-overflow\:auto {
    overflow: auto
  }

  .sm-relative {
    position: relative
  }

  .sm-absolute {
    position: absolute
  }

  .sm-sticky {
    position: sticky
  }

  .sm-fixed {
    position: fixed
  }

  .sm-top\:0 {
    top: 0
  }

  .sm-top\:auto {
    top: auto
  }

  .sm-top\:xsmall {
    top: var(--lns-space-xsmall)
  }

  .sm-top\:small {
    top: var(--lns-space-small)
  }

  .sm-top\:medium {
    top: var(--lns-space-medium)
  }

  .sm-top\:large {
    top: var(--lns-space-large)
  }

  .sm-top\:xlarge {
    top: var(--lns-space-xlarge)
  }

  .sm-top\:xxlarge {
    top: var(--lns-space-xxlarge)
  }

  .sm-bottom\:0 {
    bottom: 0
  }

  .sm-bottom\:auto {
    bottom: auto
  }

  .sm-bottom\:xsmall {
    bottom: var(--lns-space-xsmall)
  }

  .sm-bottom\:small {
    bottom: var(--lns-space-small)
  }

  .sm-bottom\:medium {
    bottom: var(--lns-space-medium)
  }

  .sm-bottom\:large {
    bottom: var(--lns-space-large)
  }

  .sm-bottom\:xlarge {
    bottom: var(--lns-space-xlarge)
  }

  .sm-bottom\:xxlarge {
    bottom: var(--lns-space-xxlarge)
  }

  .sm-left\:0 {
    left: 0
  }

  .sm-left\:auto {
    left: auto
  }

  .sm-left\:xsmall {
    left: var(--lns-space-xsmall)
  }

  .sm-left\:small {
    left: var(--lns-space-small)
  }

  .sm-left\:medium {
    left: var(--lns-space-medium)
  }

  .sm-left\:large {
    left: var(--lns-space-large)
  }

  .sm-left\:xlarge {
    left: var(--lns-space-xlarge)
  }

  .sm-left\:xxlarge {
    left: var(--lns-space-xxlarge)
  }

  .sm-right\:0 {
    right: 0
  }

  .sm-right\:auto {
    right: auto
  }

  .sm-right\:xsmall {
    right: var(--lns-space-xsmall)
  }

  .sm-right\:small {
    right: var(--lns-space-small)
  }

  .sm-right\:medium {
    right: var(--lns-space-medium)
  }

  .sm-right\:large {
    right: var(--lns-space-large)
  }

  .sm-right\:xlarge {
    right: var(--lns-space-xlarge)
  }

  .sm-right\:xxlarge {
    right: var(--lns-space-xxlarge)
  }

  .sm-width\:auto {
    width: auto
  }

  .sm-width\:full {
    width: 100%
  }

  .sm-width\:0 {
    width: 0
  }

  .sm-minWidth\:0 {
    min-width: 0
  }

  .sm-height\:auto {
    height: auto
  }

  .sm-height\:full {
    height: 100%
  }

  .sm-height\:0 {
    height: 0
  }

  .sm-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm-srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }
}

@media(min-width:64em) {
  .md-c\:red {
    color: var(--lns-color-red)
  }

  .md-c\:blurpleLight {
    color: var(--lns-color-blurpleLight)
  }

  .md-c\:blurpleMedium {
    color: var(--lns-color-blurpleMedium)
  }

  .md-c\:blurple {
    color: var(--lns-color-blurple)
  }

  .md-c\:blurpleDark {
    color: var(--lns-color-blurpleDark)
  }

  .md-c\:blurpleStrong {
    color: var(--lns-color-blurpleStrong)
  }

  .md-c\:offWhite {
    color: var(--lns-color-offWhite)
  }

  .md-c\:blueLight {
    color: var(--lns-color-blueLight)
  }

  .md-c\:blue {
    color: var(--lns-color-blue)
  }

  .md-c\:blueDark {
    color: var(--lns-color-blueDark)
  }

  .md-c\:orangeLight {
    color: var(--lns-color-orangeLight)
  }

  .md-c\:orange {
    color: var(--lns-color-orange)
  }

  .md-c\:orangeDark {
    color: var(--lns-color-orangeDark)
  }

  .md-c\:tealLight {
    color: var(--lns-color-tealLight)
  }

  .md-c\:teal {
    color: var(--lns-color-teal)
  }

  .md-c\:tealDark {
    color: var(--lns-color-tealDark)
  }

  .md-c\:yellowLight {
    color: var(--lns-color-yellowLight)
  }

  .md-c\:yellow {
    color: var(--lns-color-yellow)
  }

  .md-c\:yellowDark {
    color: var(--lns-color-yellowDark)
  }

  .md-c\:grey8 {
    color: var(--lns-color-grey8)
  }

  .md-c\:grey7 {
    color: var(--lns-color-grey7)
  }

  .md-c\:grey6 {
    color: var(--lns-color-grey6)
  }

  .md-c\:grey5 {
    color: var(--lns-color-grey5)
  }

  .md-c\:grey4 {
    color: var(--lns-color-grey4)
  }

  .md-c\:grey3 {
    color: var(--lns-color-grey3)
  }

  .md-c\:grey2 {
    color: var(--lns-color-grey2)
  }

  .md-c\:grey1 {
    color: var(--lns-color-grey1)
  }

  .md-c\:white {
    color: var(--lns-color-white)
  }

  .md-c\:primary {
    color: var(--lns-color-primary)
  }

  .md-c\:primaryHover {
    color: var(--lns-color-primaryHover)
  }

  .md-c\:primaryActive {
    color: var(--lns-color-primaryActive)
  }

  .md-c\:body {
    color: var(--lns-color-body)
  }

  .md-c\:bodyDimmed {
    color: var(--lns-color-bodyDimmed)
  }

  .md-c\:background {
    color: var(--lns-color-background)
  }

  .md-c\:backgroundHover {
    color: var(--lns-color-backgroundHover)
  }

  .md-c\:backgroundActive {
    color: var(--lns-color-backgroundActive)
  }

  .md-c\:backgroundSecondary {
    color: var(--lns-color-backgroundSecondary)
  }

  .md-c\:backgroundSecondary2 {
    color: var(--lns-color-backgroundSecondary2)
  }

  .md-c\:overlay {
    color: var(--lns-color-overlay)
  }

  .md-c\:border {
    color: var(--lns-color-border)
  }

  .md-c\:focusRing {
    color: var(--lns-color-focusRing)
  }

  .md-c\:record {
    color: var(--lns-color-record)
  }

  .md-c\:recordHover {
    color: var(--lns-color-recordHover)
  }

  .md-c\:recordActive {
    color: var(--lns-color-recordActive)
  }

  .md-c\:info {
    color: var(--lns-color-info)
  }

  .md-c\:success {
    color: var(--lns-color-success)
  }

  .md-c\:warning {
    color: var(--lns-color-warning)
  }

  .md-c\:danger {
    color: var(--lns-color-danger)
  }

  .md-c\:dangerHover {
    color: var(--lns-color-dangerHover)
  }

  .md-c\:dangerActive {
    color: var(--lns-color-dangerActive)
  }

  .md-c\:backdrop {
    color: var(--lns-color-backdrop)
  }

  .md-c\:backdropDark {
    color: var(--lns-color-backdropDark)
  }

  .md-c\:backdropTwilight {
    color: var(--lns-color-backdropTwilight)
  }

  .md-c\:disabledContent {
    color: var(--lns-color-disabledContent)
  }

  .md-c\:highlight {
    color: var(--lns-color-highlight)
  }

  .md-c\:disabledBackground {
    color: var(--lns-color-disabledBackground)
  }

  .md-c\:formFieldBorder {
    color: var(--lns-color-formFieldBorder)
  }

  .md-c\:formFieldBackground {
    color: var(--lns-color-formFieldBackground)
  }

  .md-c\:buttonBorder {
    color: var(--lns-color-buttonBorder)
  }

  .md-c\:upgrade {
    color: var(--lns-color-upgrade)
  }

  .md-c\:upgradeHover {
    color: var(--lns-color-upgradeHover)
  }

  .md-c\:upgradeActive {
    color: var(--lns-color-upgradeActive)
  }

  .md-c\:tabBackground {
    color: var(--lns-color-tabBackground)
  }

  .md-c\:discoveryBackground {
    color: var(--lns-color-discoveryBackground)
  }

  .md-c\:discoveryLightBackground {
    color: var(--lns-color-discoveryLightBackground)
  }

  .md-c\:discoveryTitle {
    color: var(--lns-color-discoveryTitle)
  }

  .md-c\:discoveryHighlight {
    color: var(--lns-color-discoveryHighlight)
  }

  .md-shadow\:small {
    box-shadow: var(--lns-shadow-small)
  }

  .md-shadow\:medium {
    box-shadow: var(--lns-shadow-medium)
  }

  .md-shadow\:large {
    box-shadow: var(--lns-shadow-large)
  }

  .md-radius\:medium {
    border-radius: var(--lns-radius-medium)
  }

  .md-radius\:large {
    border-radius: var(--lns-radius-large)
  }

  .md-radius\:xlarge {
    border-radius: var(--lns-radius-xlarge)
  }

  .md-radius\:full {
    border-radius: var(--lns-radius-full)
  }

  .md-bgc\:red {
    background-color: var(--lns-color-red)
  }

  .md-bgc\:blurpleLight {
    background-color: var(--lns-color-blurpleLight)
  }

  .md-bgc\:blurpleMedium {
    background-color: var(--lns-color-blurpleMedium)
  }

  .md-bgc\:blurple {
    background-color: var(--lns-color-blurple)
  }

  .md-bgc\:blurpleDark {
    background-color: var(--lns-color-blurpleDark)
  }

  .md-bgc\:blurpleStrong {
    background-color: var(--lns-color-blurpleStrong)
  }

  .md-bgc\:offWhite {
    background-color: var(--lns-color-offWhite)
  }

  .md-bgc\:blueLight {
    background-color: var(--lns-color-blueLight)
  }

  .md-bgc\:blue {
    background-color: var(--lns-color-blue)
  }

  .md-bgc\:blueDark {
    background-color: var(--lns-color-blueDark)
  }

  .md-bgc\:orangeLight {
    background-color: var(--lns-color-orangeLight)
  }

  .md-bgc\:orange {
    background-color: var(--lns-color-orange)
  }

  .md-bgc\:orangeDark {
    background-color: var(--lns-color-orangeDark)
  }

  .md-bgc\:tealLight {
    background-color: var(--lns-color-tealLight)
  }

  .md-bgc\:teal {
    background-color: var(--lns-color-teal)
  }

  .md-bgc\:tealDark {
    background-color: var(--lns-color-tealDark)
  }

  .md-bgc\:yellowLight {
    background-color: var(--lns-color-yellowLight)
  }

  .md-bgc\:yellow {
    background-color: var(--lns-color-yellow)
  }

  .md-bgc\:yellowDark {
    background-color: var(--lns-color-yellowDark)
  }

  .md-bgc\:grey8 {
    background-color: var(--lns-color-grey8)
  }

  .md-bgc\:grey7 {
    background-color: var(--lns-color-grey7)
  }

  .md-bgc\:grey6 {
    background-color: var(--lns-color-grey6)
  }

  .md-bgc\:grey5 {
    background-color: var(--lns-color-grey5)
  }

  .md-bgc\:grey4 {
    background-color: var(--lns-color-grey4)
  }

  .md-bgc\:grey3 {
    background-color: var(--lns-color-grey3)
  }

  .md-bgc\:grey2 {
    background-color: var(--lns-color-grey2)
  }

  .md-bgc\:grey1 {
    background-color: var(--lns-color-grey1)
  }

  .md-bgc\:white {
    background-color: var(--lns-color-white)
  }

  .md-bgc\:primary {
    background-color: var(--lns-color-primary)
  }

  .md-bgc\:primaryHover {
    background-color: var(--lns-color-primaryHover)
  }

  .md-bgc\:primaryActive {
    background-color: var(--lns-color-primaryActive)
  }

  .md-bgc\:body {
    background-color: var(--lns-color-body)
  }

  .md-bgc\:bodyDimmed {
    background-color: var(--lns-color-bodyDimmed)
  }

  .md-bgc\:background {
    background-color: var(--lns-color-background)
  }

  .md-bgc\:backgroundHover {
    background-color: var(--lns-color-backgroundHover)
  }

  .md-bgc\:backgroundActive {
    background-color: var(--lns-color-backgroundActive)
  }

  .md-bgc\:backgroundSecondary {
    background-color: var(--lns-color-backgroundSecondary)
  }

  .md-bgc\:backgroundSecondary2 {
    background-color: var(--lns-color-backgroundSecondary2)
  }

  .md-bgc\:overlay {
    background-color: var(--lns-color-overlay)
  }

  .md-bgc\:border {
    background-color: var(--lns-color-border)
  }

  .md-bgc\:focusRing {
    background-color: var(--lns-color-focusRing)
  }

  .md-bgc\:record {
    background-color: var(--lns-color-record)
  }

  .md-bgc\:recordHover {
    background-color: var(--lns-color-recordHover)
  }

  .md-bgc\:recordActive {
    background-color: var(--lns-color-recordActive)
  }

  .md-bgc\:info {
    background-color: var(--lns-color-info)
  }

  .md-bgc\:success {
    background-color: var(--lns-color-success)
  }

  .md-bgc\:warning {
    background-color: var(--lns-color-warning)
  }

  .md-bgc\:danger {
    background-color: var(--lns-color-danger)
  }

  .md-bgc\:dangerHover {
    background-color: var(--lns-color-dangerHover)
  }

  .md-bgc\:dangerActive {
    background-color: var(--lns-color-dangerActive)
  }

  .md-bgc\:backdrop {
    background-color: var(--lns-color-backdrop)
  }

  .md-bgc\:backdropDark {
    background-color: var(--lns-color-backdropDark)
  }

  .md-bgc\:backdropTwilight {
    background-color: var(--lns-color-backdropTwilight)
  }

  .md-bgc\:disabledContent {
    background-color: var(--lns-color-disabledContent)
  }

  .md-bgc\:highlight {
    background-color: var(--lns-color-highlight)
  }

  .md-bgc\:disabledBackground {
    background-color: var(--lns-color-disabledBackground)
  }

  .md-bgc\:formFieldBorder {
    background-color: var(--lns-color-formFieldBorder)
  }

  .md-bgc\:formFieldBackground {
    background-color: var(--lns-color-formFieldBackground)
  }

  .md-bgc\:buttonBorder {
    background-color: var(--lns-color-buttonBorder)
  }

  .md-bgc\:upgrade {
    background-color: var(--lns-color-upgrade)
  }

  .md-bgc\:upgradeHover {
    background-color: var(--lns-color-upgradeHover)
  }

  .md-bgc\:upgradeActive {
    background-color: var(--lns-color-upgradeActive)
  }

  .md-bgc\:tabBackground {
    background-color: var(--lns-color-tabBackground)
  }

  .md-bgc\:discoveryBackground {
    background-color: var(--lns-color-discoveryBackground)
  }

  .md-bgc\:discoveryLightBackground {
    background-color: var(--lns-color-discoveryLightBackground)
  }

  .md-bgc\:discoveryTitle {
    background-color: var(--lns-color-discoveryTitle)
  }

  .md-bgc\:discoveryHighlight {
    background-color: var(--lns-color-discoveryHighlight)
  }

  .md-m\:0 {
    margin: 0
  }

  .md-m\:auto {
    margin: auto
  }

  .md-m\:xsmall {
    margin: var(--lns-space-xsmall)
  }

  .md-m\:small {
    margin: var(--lns-space-small)
  }

  .md-m\:medium {
    margin: var(--lns-space-medium)
  }

  .md-m\:large {
    margin: var(--lns-space-large)
  }

  .md-m\:xlarge {
    margin: var(--lns-space-xlarge)
  }

  .md-m\:xxlarge {
    margin: var(--lns-space-xxlarge)
  }

  .md-mt\:0 {
    margin-top: 0
  }

  .md-mt\:auto {
    margin-top: auto
  }

  .md-mt\:xsmall {
    margin-top: var(--lns-space-xsmall)
  }

  .md-mt\:small {
    margin-top: var(--lns-space-small)
  }

  .md-mt\:medium {
    margin-top: var(--lns-space-medium)
  }

  .md-mt\:large {
    margin-top: var(--lns-space-large)
  }

  .md-mt\:xlarge {
    margin-top: var(--lns-space-xlarge)
  }

  .md-mt\:xxlarge {
    margin-top: var(--lns-space-xxlarge)
  }

  .md-mb\:0 {
    margin-bottom: 0
  }

  .md-mb\:auto {
    margin-bottom: auto
  }

  .md-mb\:xsmall {
    margin-bottom: var(--lns-space-xsmall)
  }

  .md-mb\:small {
    margin-bottom: var(--lns-space-small)
  }

  .md-mb\:medium {
    margin-bottom: var(--lns-space-medium)
  }

  .md-mb\:large {
    margin-bottom: var(--lns-space-large)
  }

  .md-mb\:xlarge {
    margin-bottom: var(--lns-space-xlarge)
  }

  .md-mb\:xxlarge {
    margin-bottom: var(--lns-space-xxlarge)
  }

  .md-ml\:0 {
    margin-left: 0
  }

  .md-ml\:auto {
    margin-left: auto
  }

  .md-ml\:xsmall {
    margin-left: var(--lns-space-xsmall)
  }

  .md-ml\:small {
    margin-left: var(--lns-space-small)
  }

  .md-ml\:medium {
    margin-left: var(--lns-space-medium)
  }

  .md-ml\:large {
    margin-left: var(--lns-space-large)
  }

  .md-ml\:xlarge {
    margin-left: var(--lns-space-xlarge)
  }

  .md-ml\:xxlarge {
    margin-left: var(--lns-space-xxlarge)
  }

  .md-mr\:0 {
    margin-right: 0
  }

  .md-mr\:auto {
    margin-right: auto
  }

  .md-mr\:xsmall {
    margin-right: var(--lns-space-xsmall)
  }

  .md-mr\:small {
    margin-right: var(--lns-space-small)
  }

  .md-mr\:medium {
    margin-right: var(--lns-space-medium)
  }

  .md-mr\:large {
    margin-right: var(--lns-space-large)
  }

  .md-mr\:xlarge {
    margin-right: var(--lns-space-xlarge)
  }

  .md-mr\:xxlarge {
    margin-right: var(--lns-space-xxlarge)
  }

  .md-mx\:0 {
    margin-left: 0;
    margin-right: 0
  }

  .md-mx\:auto {
    margin-left: auto;
    margin-right: auto
  }

  .md-mx\:xsmall {
    margin-left: var(--lns-space-xsmall);
    margin-right: var(--lns-space-xsmall)
  }

  .md-mx\:small {
    margin-left: var(--lns-space-small);
    margin-right: var(--lns-space-small)
  }

  .md-mx\:medium {
    margin-left: var(--lns-space-medium);
    margin-right: var(--lns-space-medium)
  }

  .md-mx\:large {
    margin-left: var(--lns-space-large);
    margin-right: var(--lns-space-large)
  }

  .md-mx\:xlarge {
    margin-left: var(--lns-space-xlarge);
    margin-right: var(--lns-space-xlarge)
  }

  .md-mx\:xxlarge {
    margin-left: var(--lns-space-xxlarge);
    margin-right: var(--lns-space-xxlarge)
  }

  .md-my\:0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md-my\:auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .md-my\:xsmall {
    margin-top: var(--lns-space-xsmall);
    margin-bottom: var(--lns-space-xsmall)
  }

  .md-my\:small {
    margin-top: var(--lns-space-small);
    margin-bottom: var(--lns-space-small)
  }

  .md-my\:medium {
    margin-top: var(--lns-space-medium);
    margin-bottom: var(--lns-space-medium)
  }

  .md-my\:large {
    margin-top: var(--lns-space-large);
    margin-bottom: var(--lns-space-large)
  }

  .md-my\:xlarge {
    margin-top: var(--lns-space-xlarge);
    margin-bottom: var(--lns-space-xlarge)
  }

  .md-my\:xxlarge {
    margin-top: var(--lns-space-xxlarge);
    margin-bottom: var(--lns-space-xxlarge)
  }

  .md-p\:0 {
    padding: 0
  }

  .md-p\:xsmall {
    padding: var(--lns-space-xsmall)
  }

  .md-p\:small {
    padding: var(--lns-space-small)
  }

  .md-p\:medium {
    padding: var(--lns-space-medium)
  }

  .md-p\:large {
    padding: var(--lns-space-large)
  }

  .md-p\:xlarge {
    padding: var(--lns-space-xlarge)
  }

  .md-p\:xxlarge {
    padding: var(--lns-space-xxlarge)
  }

  .md-pt\:0 {
    padding-top: 0
  }

  .md-pt\:xsmall {
    padding-top: var(--lns-space-xsmall)
  }

  .md-pt\:small {
    padding-top: var(--lns-space-small)
  }

  .md-pt\:medium {
    padding-top: var(--lns-space-medium)
  }

  .md-pt\:large {
    padding-top: var(--lns-space-large)
  }

  .md-pt\:xlarge {
    padding-top: var(--lns-space-xlarge)
  }

  .md-pt\:xxlarge {
    padding-top: var(--lns-space-xxlarge)
  }

  .md-pb\:0 {
    padding-bottom: 0
  }

  .md-pb\:xsmall {
    padding-bottom: var(--lns-space-xsmall)
  }

  .md-pb\:small {
    padding-bottom: var(--lns-space-small)
  }

  .md-pb\:medium {
    padding-bottom: var(--lns-space-medium)
  }

  .md-pb\:large {
    padding-bottom: var(--lns-space-large)
  }

  .md-pb\:xlarge {
    padding-bottom: var(--lns-space-xlarge)
  }

  .md-pb\:xxlarge {
    padding-bottom: var(--lns-space-xxlarge)
  }

  .md-pl\:0 {
    padding-left: 0
  }

  .md-pl\:xsmall {
    padding-left: var(--lns-space-xsmall)
  }

  .md-pl\:small {
    padding-left: var(--lns-space-small)
  }

  .md-pl\:medium {
    padding-left: var(--lns-space-medium)
  }

  .md-pl\:large {
    padding-left: var(--lns-space-large)
  }

  .md-pl\:xlarge {
    padding-left: var(--lns-space-xlarge)
  }

  .md-pl\:xxlarge {
    padding-left: var(--lns-space-xxlarge)
  }

  .md-pr\:0 {
    padding-right: 0
  }

  .md-pr\:xsmall {
    padding-right: var(--lns-space-xsmall)
  }

  .md-pr\:small {
    padding-right: var(--lns-space-small)
  }

  .md-pr\:medium {
    padding-right: var(--lns-space-medium)
  }

  .md-pr\:large {
    padding-right: var(--lns-space-large)
  }

  .md-pr\:xlarge {
    padding-right: var(--lns-space-xlarge)
  }

  .md-pr\:xxlarge {
    padding-right: var(--lns-space-xxlarge)
  }

  .md-px\:0 {
    padding-left: 0;
    padding-right: 0
  }

  .md-px\:xsmall {
    padding-left: var(--lns-space-xsmall);
    padding-right: var(--lns-space-xsmall)
  }

  .md-px\:small {
    padding-left: var(--lns-space-small);
    padding-right: var(--lns-space-small)
  }

  .md-px\:medium {
    padding-left: var(--lns-space-medium);
    padding-right: var(--lns-space-medium)
  }

  .md-px\:large {
    padding-left: var(--lns-space-large);
    padding-right: var(--lns-space-large)
  }

  .md-px\:xlarge {
    padding-left: var(--lns-space-xlarge);
    padding-right: var(--lns-space-xlarge)
  }

  .md-px\:xxlarge {
    padding-left: var(--lns-space-xxlarge);
    padding-right: var(--lns-space-xxlarge)
  }

  .md-py\:0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .md-py\:xsmall {
    padding-top: var(--lns-space-xsmall);
    padding-bottom: var(--lns-space-xsmall)
  }

  .md-py\:small {
    padding-top: var(--lns-space-small);
    padding-bottom: var(--lns-space-small)
  }

  .md-py\:medium {
    padding-top: var(--lns-space-medium);
    padding-bottom: var(--lns-space-medium)
  }

  .md-py\:large {
    padding-top: var(--lns-space-large);
    padding-bottom: var(--lns-space-large)
  }

  .md-py\:xlarge {
    padding-top: var(--lns-space-xlarge);
    padding-bottom: var(--lns-space-xlarge)
  }

  .md-py\:xxlarge {
    padding-top: var(--lns-space-xxlarge);
    padding-bottom: var(--lns-space-xxlarge)
  }

  .md-text\:small {
    font-size: var(--lns-fontSize-small);
    line-height: var(--lns-lineHeight-small)
  }

  .md-text\:body-sm {
    font-size: var(--lns-fontSize-body-sm);
    line-height: var(--lns-lineHeight-body-sm)
  }

  .md-text\:medium {
    font-size: var(--lns-fontSize-medium);
    line-height: var(--lns-lineHeight-medium)
  }

  .md-text\:body-md {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md)
  }

  .md-text\:large {
    font-size: var(--lns-fontSize-large);
    line-height: var(--lns-lineHeight-large)
  }

  .md-text\:body-lg {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg)
  }

  .md-text\:xlarge {
    font-size: var(--lns-fontSize-xlarge);
    line-height: var(--lns-lineHeight-xlarge)
  }

  .md-text\:heading-sm {
    font-size: var(--lns-fontSize-heading-sm);
    line-height: var(--lns-lineHeight-heading-sm)
  }

  .md-text\:xxlarge {
    font-size: var(--lns-fontSize-xxlarge);
    line-height: var(--lns-lineHeight-xxlarge)
  }

  .md-text\:heading-md {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md)
  }

  .md-text\:xxxlarge {
    font-size: var(--lns-fontSize-xxxlarge);
    line-height: var(--lns-lineHeight-xxxlarge)
  }

  .md-text\:heading-lg {
    font-size: var(--lns-fontSize-heading-lg);
    line-height: var(--lns-lineHeight-heading-lg)
  }

  .md-weight\:book {
    font-weight: var(--lns-fontWeight-book)
  }

  .md-weight\:bold {
    font-weight: var(--lns-fontWeight-bold)
  }

  .md-text\:body {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md);
    font-weight: var(--lns-fontWeight-book)
  }

  .md-text\:title {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg);
    font-weight: var(--lns-fontWeight-bold)
  }

  .md-text\:mainTitle {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md);
    font-weight: var(--lns-fontWeight-bold)
  }

  .md-text\:left {
    text-align: left
  }

  .md-text\:right {
    text-align: right
  }

  .md-text\:center {
    text-align: center
  }

  .md-border {
    border: 1px solid var(--lns-color-border)
  }

  .md-borderTop {
    border-top: 1px solid var(--lns-color-border)
  }

  .md-borderBottom {
    border-bottom: 1px solid var(--lns-color-border)
  }

  .md-borderLeft {
    border-left: 1px solid var(--lns-color-border)
  }

  .md-borderRight {
    border-right: 1px solid var(--lns-color-border)
  }

  .md-inline {
    display: inline
  }

  .md-block {
    display: block
  }

  .md-flex {
    display: flex
  }

  .md-inlineBlock {
    display: inline-block
  }

  .md-inlineFlex {
    display: inline-flex
  }

  .md-none {
    display: none
  }

  .md-flexWrap {
    flex-wrap: wrap
  }

  .md-flexDirection\:column {
    flex-direction: column
  }

  .md-flexDirection\:row {
    flex-direction: row
  }

  .md-items\:stretch {
    align-items: stretch
  }

  .md-items\:center {
    align-items: center
  }

  .md-items\:baseline {
    align-items: baseline
  }

  .md-items\:flexStart {
    align-items: flex-start
  }

  .md-items\:flexEnd {
    align-items: flex-end
  }

  .md-items\:selfStart {
    align-items: self-start
  }

  .md-items\:selfEnd {
    align-items: self-end
  }

  .md-justify\:flexStart {
    justify-content: flex-start
  }

  .md-justify\:flexEnd {
    justify-content: flex-end
  }

  .md-justify\:center {
    justify-content: center
  }

  .md-justify\:spaceBetween {
    justify-content: space-between
  }

  .md-justify\:spaceAround {
    justify-content: space-around
  }

  .md-justify\:spaceEvenly {
    justify-content: space-evenly
  }

  .md-grow\:0 {
    flex-grow: 0
  }

  .md-grow\:1 {
    flex-grow: 1
  }

  .md-shrink\:0 {
    flex-shrink: 0
  }

  .md-shrink\:1 {
    flex-shrink: 1
  }

  .md-self\:auto {
    align-self: auto
  }

  .md-self\:flexStart {
    align-self: flex-start
  }

  .md-self\:flexEnd {
    align-self: flex-end
  }

  .md-self\:center {
    align-self: center
  }

  .md-self\:baseline {
    align-self: baseline
  }

  .md-self\:stretch {
    align-self: stretch
  }

  .md-overflow\:hidden {
    overflow: hidden
  }

  .md-overflow\:auto {
    overflow: auto
  }

  .md-relative {
    position: relative
  }

  .md-absolute {
    position: absolute
  }

  .md-sticky {
    position: sticky
  }

  .md-fixed {
    position: fixed
  }

  .md-top\:0 {
    top: 0
  }

  .md-top\:auto {
    top: auto
  }

  .md-top\:xsmall {
    top: var(--lns-space-xsmall)
  }

  .md-top\:small {
    top: var(--lns-space-small)
  }

  .md-top\:medium {
    top: var(--lns-space-medium)
  }

  .md-top\:large {
    top: var(--lns-space-large)
  }

  .md-top\:xlarge {
    top: var(--lns-space-xlarge)
  }

  .md-top\:xxlarge {
    top: var(--lns-space-xxlarge)
  }

  .md-bottom\:0 {
    bottom: 0
  }

  .md-bottom\:auto {
    bottom: auto
  }

  .md-bottom\:xsmall {
    bottom: var(--lns-space-xsmall)
  }

  .md-bottom\:small {
    bottom: var(--lns-space-small)
  }

  .md-bottom\:medium {
    bottom: var(--lns-space-medium)
  }

  .md-bottom\:large {
    bottom: var(--lns-space-large)
  }

  .md-bottom\:xlarge {
    bottom: var(--lns-space-xlarge)
  }

  .md-bottom\:xxlarge {
    bottom: var(--lns-space-xxlarge)
  }

  .md-left\:0 {
    left: 0
  }

  .md-left\:auto {
    left: auto
  }

  .md-left\:xsmall {
    left: var(--lns-space-xsmall)
  }

  .md-left\:small {
    left: var(--lns-space-small)
  }

  .md-left\:medium {
    left: var(--lns-space-medium)
  }

  .md-left\:large {
    left: var(--lns-space-large)
  }

  .md-left\:xlarge {
    left: var(--lns-space-xlarge)
  }

  .md-left\:xxlarge {
    left: var(--lns-space-xxlarge)
  }

  .md-right\:0 {
    right: 0
  }

  .md-right\:auto {
    right: auto
  }

  .md-right\:xsmall {
    right: var(--lns-space-xsmall)
  }

  .md-right\:small {
    right: var(--lns-space-small)
  }

  .md-right\:medium {
    right: var(--lns-space-medium)
  }

  .md-right\:large {
    right: var(--lns-space-large)
  }

  .md-right\:xlarge {
    right: var(--lns-space-xlarge)
  }

  .md-right\:xxlarge {
    right: var(--lns-space-xxlarge)
  }

  .md-width\:auto {
    width: auto
  }

  .md-width\:full {
    width: 100%
  }

  .md-width\:0 {
    width: 0
  }

  .md-minWidth\:0 {
    min-width: 0
  }

  .md-height\:auto {
    height: auto
  }

  .md-height\:full {
    height: 100%
  }

  .md-height\:0 {
    height: 0
  }

  .md-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .md-srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }
}

@media(min-width:75em) {
  .lg-c\:red {
    color: var(--lns-color-red)
  }

  .lg-c\:blurpleLight {
    color: var(--lns-color-blurpleLight)
  }

  .lg-c\:blurpleMedium {
    color: var(--lns-color-blurpleMedium)
  }

  .lg-c\:blurple {
    color: var(--lns-color-blurple)
  }

  .lg-c\:blurpleDark {
    color: var(--lns-color-blurpleDark)
  }

  .lg-c\:blurpleStrong {
    color: var(--lns-color-blurpleStrong)
  }

  .lg-c\:offWhite {
    color: var(--lns-color-offWhite)
  }

  .lg-c\:blueLight {
    color: var(--lns-color-blueLight)
  }

  .lg-c\:blue {
    color: var(--lns-color-blue)
  }

  .lg-c\:blueDark {
    color: var(--lns-color-blueDark)
  }

  .lg-c\:orangeLight {
    color: var(--lns-color-orangeLight)
  }

  .lg-c\:orange {
    color: var(--lns-color-orange)
  }

  .lg-c\:orangeDark {
    color: var(--lns-color-orangeDark)
  }

  .lg-c\:tealLight {
    color: var(--lns-color-tealLight)
  }

  .lg-c\:teal {
    color: var(--lns-color-teal)
  }

  .lg-c\:tealDark {
    color: var(--lns-color-tealDark)
  }

  .lg-c\:yellowLight {
    color: var(--lns-color-yellowLight)
  }

  .lg-c\:yellow {
    color: var(--lns-color-yellow)
  }

  .lg-c\:yellowDark {
    color: var(--lns-color-yellowDark)
  }

  .lg-c\:grey8 {
    color: var(--lns-color-grey8)
  }

  .lg-c\:grey7 {
    color: var(--lns-color-grey7)
  }

  .lg-c\:grey6 {
    color: var(--lns-color-grey6)
  }

  .lg-c\:grey5 {
    color: var(--lns-color-grey5)
  }

  .lg-c\:grey4 {
    color: var(--lns-color-grey4)
  }

  .lg-c\:grey3 {
    color: var(--lns-color-grey3)
  }

  .lg-c\:grey2 {
    color: var(--lns-color-grey2)
  }

  .lg-c\:grey1 {
    color: var(--lns-color-grey1)
  }

  .lg-c\:white {
    color: var(--lns-color-white)
  }

  .lg-c\:primary {
    color: var(--lns-color-primary)
  }

  .lg-c\:primaryHover {
    color: var(--lns-color-primaryHover)
  }

  .lg-c\:primaryActive {
    color: var(--lns-color-primaryActive)
  }

  .lg-c\:body {
    color: var(--lns-color-body)
  }

  .lg-c\:bodyDimmed {
    color: var(--lns-color-bodyDimmed)
  }

  .lg-c\:background {
    color: var(--lns-color-background)
  }

  .lg-c\:backgroundHover {
    color: var(--lns-color-backgroundHover)
  }

  .lg-c\:backgroundActive {
    color: var(--lns-color-backgroundActive)
  }

  .lg-c\:backgroundSecondary {
    color: var(--lns-color-backgroundSecondary)
  }

  .lg-c\:backgroundSecondary2 {
    color: var(--lns-color-backgroundSecondary2)
  }

  .lg-c\:overlay {
    color: var(--lns-color-overlay)
  }

  .lg-c\:border {
    color: var(--lns-color-border)
  }

  .lg-c\:focusRing {
    color: var(--lns-color-focusRing)
  }

  .lg-c\:record {
    color: var(--lns-color-record)
  }

  .lg-c\:recordHover {
    color: var(--lns-color-recordHover)
  }

  .lg-c\:recordActive {
    color: var(--lns-color-recordActive)
  }

  .lg-c\:info {
    color: var(--lns-color-info)
  }

  .lg-c\:success {
    color: var(--lns-color-success)
  }

  .lg-c\:warning {
    color: var(--lns-color-warning)
  }

  .lg-c\:danger {
    color: var(--lns-color-danger)
  }

  .lg-c\:dangerHover {
    color: var(--lns-color-dangerHover)
  }

  .lg-c\:dangerActive {
    color: var(--lns-color-dangerActive)
  }

  .lg-c\:backdrop {
    color: var(--lns-color-backdrop)
  }

  .lg-c\:backdropDark {
    color: var(--lns-color-backdropDark)
  }

  .lg-c\:backdropTwilight {
    color: var(--lns-color-backdropTwilight)
  }

  .lg-c\:disabledContent {
    color: var(--lns-color-disabledContent)
  }

  .lg-c\:highlight {
    color: var(--lns-color-highlight)
  }

  .lg-c\:disabledBackground {
    color: var(--lns-color-disabledBackground)
  }

  .lg-c\:formFieldBorder {
    color: var(--lns-color-formFieldBorder)
  }

  .lg-c\:formFieldBackground {
    color: var(--lns-color-formFieldBackground)
  }

  .lg-c\:buttonBorder {
    color: var(--lns-color-buttonBorder)
  }

  .lg-c\:upgrade {
    color: var(--lns-color-upgrade)
  }

  .lg-c\:upgradeHover {
    color: var(--lns-color-upgradeHover)
  }

  .lg-c\:upgradeActive {
    color: var(--lns-color-upgradeActive)
  }

  .lg-c\:tabBackground {
    color: var(--lns-color-tabBackground)
  }

  .lg-c\:discoveryBackground {
    color: var(--lns-color-discoveryBackground)
  }

  .lg-c\:discoveryLightBackground {
    color: var(--lns-color-discoveryLightBackground)
  }

  .lg-c\:discoveryTitle {
    color: var(--lns-color-discoveryTitle)
  }

  .lg-c\:discoveryHighlight {
    color: var(--lns-color-discoveryHighlight)
  }

  .lg-shadow\:small {
    box-shadow: var(--lns-shadow-small)
  }

  .lg-shadow\:medium {
    box-shadow: var(--lns-shadow-medium)
  }

  .lg-shadow\:large {
    box-shadow: var(--lns-shadow-large)
  }

  .lg-radius\:medium {
    border-radius: var(--lns-radius-medium)
  }

  .lg-radius\:large {
    border-radius: var(--lns-radius-large)
  }

  .lg-radius\:xlarge {
    border-radius: var(--lns-radius-xlarge)
  }

  .lg-radius\:full {
    border-radius: var(--lns-radius-full)
  }

  .lg-bgc\:red {
    background-color: var(--lns-color-red)
  }

  .lg-bgc\:blurpleLight {
    background-color: var(--lns-color-blurpleLight)
  }

  .lg-bgc\:blurpleMedium {
    background-color: var(--lns-color-blurpleMedium)
  }

  .lg-bgc\:blurple {
    background-color: var(--lns-color-blurple)
  }

  .lg-bgc\:blurpleDark {
    background-color: var(--lns-color-blurpleDark)
  }

  .lg-bgc\:blurpleStrong {
    background-color: var(--lns-color-blurpleStrong)
  }

  .lg-bgc\:offWhite {
    background-color: var(--lns-color-offWhite)
  }

  .lg-bgc\:blueLight {
    background-color: var(--lns-color-blueLight)
  }

  .lg-bgc\:blue {
    background-color: var(--lns-color-blue)
  }

  .lg-bgc\:blueDark {
    background-color: var(--lns-color-blueDark)
  }

  .lg-bgc\:orangeLight {
    background-color: var(--lns-color-orangeLight)
  }

  .lg-bgc\:orange {
    background-color: var(--lns-color-orange)
  }

  .lg-bgc\:orangeDark {
    background-color: var(--lns-color-orangeDark)
  }

  .lg-bgc\:tealLight {
    background-color: var(--lns-color-tealLight)
  }

  .lg-bgc\:teal {
    background-color: var(--lns-color-teal)
  }

  .lg-bgc\:tealDark {
    background-color: var(--lns-color-tealDark)
  }

  .lg-bgc\:yellowLight {
    background-color: var(--lns-color-yellowLight)
  }

  .lg-bgc\:yellow {
    background-color: var(--lns-color-yellow)
  }

  .lg-bgc\:yellowDark {
    background-color: var(--lns-color-yellowDark)
  }

  .lg-bgc\:grey8 {
    background-color: var(--lns-color-grey8)
  }

  .lg-bgc\:grey7 {
    background-color: var(--lns-color-grey7)
  }

  .lg-bgc\:grey6 {
    background-color: var(--lns-color-grey6)
  }

  .lg-bgc\:grey5 {
    background-color: var(--lns-color-grey5)
  }

  .lg-bgc\:grey4 {
    background-color: var(--lns-color-grey4)
  }

  .lg-bgc\:grey3 {
    background-color: var(--lns-color-grey3)
  }

  .lg-bgc\:grey2 {
    background-color: var(--lns-color-grey2)
  }

  .lg-bgc\:grey1 {
    background-color: var(--lns-color-grey1)
  }

  .lg-bgc\:white {
    background-color: var(--lns-color-white)
  }

  .lg-bgc\:primary {
    background-color: var(--lns-color-primary)
  }

  .lg-bgc\:primaryHover {
    background-color: var(--lns-color-primaryHover)
  }

  .lg-bgc\:primaryActive {
    background-color: var(--lns-color-primaryActive)
  }

  .lg-bgc\:body {
    background-color: var(--lns-color-body)
  }

  .lg-bgc\:bodyDimmed {
    background-color: var(--lns-color-bodyDimmed)
  }

  .lg-bgc\:background {
    background-color: var(--lns-color-background)
  }

  .lg-bgc\:backgroundHover {
    background-color: var(--lns-color-backgroundHover)
  }

  .lg-bgc\:backgroundActive {
    background-color: var(--lns-color-backgroundActive)
  }

  .lg-bgc\:backgroundSecondary {
    background-color: var(--lns-color-backgroundSecondary)
  }

  .lg-bgc\:backgroundSecondary2 {
    background-color: var(--lns-color-backgroundSecondary2)
  }

  .lg-bgc\:overlay {
    background-color: var(--lns-color-overlay)
  }

  .lg-bgc\:border {
    background-color: var(--lns-color-border)
  }

  .lg-bgc\:focusRing {
    background-color: var(--lns-color-focusRing)
  }

  .lg-bgc\:record {
    background-color: var(--lns-color-record)
  }

  .lg-bgc\:recordHover {
    background-color: var(--lns-color-recordHover)
  }

  .lg-bgc\:recordActive {
    background-color: var(--lns-color-recordActive)
  }

  .lg-bgc\:info {
    background-color: var(--lns-color-info)
  }

  .lg-bgc\:success {
    background-color: var(--lns-color-success)
  }

  .lg-bgc\:warning {
    background-color: var(--lns-color-warning)
  }

  .lg-bgc\:danger {
    background-color: var(--lns-color-danger)
  }

  .lg-bgc\:dangerHover {
    background-color: var(--lns-color-dangerHover)
  }

  .lg-bgc\:dangerActive {
    background-color: var(--lns-color-dangerActive)
  }

  .lg-bgc\:backdrop {
    background-color: var(--lns-color-backdrop)
  }

  .lg-bgc\:backdropDark {
    background-color: var(--lns-color-backdropDark)
  }

  .lg-bgc\:backdropTwilight {
    background-color: var(--lns-color-backdropTwilight)
  }

  .lg-bgc\:disabledContent {
    background-color: var(--lns-color-disabledContent)
  }

  .lg-bgc\:highlight {
    background-color: var(--lns-color-highlight)
  }

  .lg-bgc\:disabledBackground {
    background-color: var(--lns-color-disabledBackground)
  }

  .lg-bgc\:formFieldBorder {
    background-color: var(--lns-color-formFieldBorder)
  }

  .lg-bgc\:formFieldBackground {
    background-color: var(--lns-color-formFieldBackground)
  }

  .lg-bgc\:buttonBorder {
    background-color: var(--lns-color-buttonBorder)
  }

  .lg-bgc\:upgrade {
    background-color: var(--lns-color-upgrade)
  }

  .lg-bgc\:upgradeHover {
    background-color: var(--lns-color-upgradeHover)
  }

  .lg-bgc\:upgradeActive {
    background-color: var(--lns-color-upgradeActive)
  }

  .lg-bgc\:tabBackground {
    background-color: var(--lns-color-tabBackground)
  }

  .lg-bgc\:discoveryBackground {
    background-color: var(--lns-color-discoveryBackground)
  }

  .lg-bgc\:discoveryLightBackground {
    background-color: var(--lns-color-discoveryLightBackground)
  }

  .lg-bgc\:discoveryTitle {
    background-color: var(--lns-color-discoveryTitle)
  }

  .lg-bgc\:discoveryHighlight {
    background-color: var(--lns-color-discoveryHighlight)
  }

  .lg-m\:0 {
    margin: 0
  }

  .lg-m\:auto {
    margin: auto
  }

  .lg-m\:xsmall {
    margin: var(--lns-space-xsmall)
  }

  .lg-m\:small {
    margin: var(--lns-space-small)
  }

  .lg-m\:medium {
    margin: var(--lns-space-medium)
  }

  .lg-m\:large {
    margin: var(--lns-space-large)
  }

  .lg-m\:xlarge {
    margin: var(--lns-space-xlarge)
  }

  .lg-m\:xxlarge {
    margin: var(--lns-space-xxlarge)
  }

  .lg-mt\:0 {
    margin-top: 0
  }

  .lg-mt\:auto {
    margin-top: auto
  }

  .lg-mt\:xsmall {
    margin-top: var(--lns-space-xsmall)
  }

  .lg-mt\:small {
    margin-top: var(--lns-space-small)
  }

  .lg-mt\:medium {
    margin-top: var(--lns-space-medium)
  }

  .lg-mt\:large {
    margin-top: var(--lns-space-large)
  }

  .lg-mt\:xlarge {
    margin-top: var(--lns-space-xlarge)
  }

  .lg-mt\:xxlarge {
    margin-top: var(--lns-space-xxlarge)
  }

  .lg-mb\:0 {
    margin-bottom: 0
  }

  .lg-mb\:auto {
    margin-bottom: auto
  }

  .lg-mb\:xsmall {
    margin-bottom: var(--lns-space-xsmall)
  }

  .lg-mb\:small {
    margin-bottom: var(--lns-space-small)
  }

  .lg-mb\:medium {
    margin-bottom: var(--lns-space-medium)
  }

  .lg-mb\:large {
    margin-bottom: var(--lns-space-large)
  }

  .lg-mb\:xlarge {
    margin-bottom: var(--lns-space-xlarge)
  }

  .lg-mb\:xxlarge {
    margin-bottom: var(--lns-space-xxlarge)
  }

  .lg-ml\:0 {
    margin-left: 0
  }

  .lg-ml\:auto {
    margin-left: auto
  }

  .lg-ml\:xsmall {
    margin-left: var(--lns-space-xsmall)
  }

  .lg-ml\:small {
    margin-left: var(--lns-space-small)
  }

  .lg-ml\:medium {
    margin-left: var(--lns-space-medium)
  }

  .lg-ml\:large {
    margin-left: var(--lns-space-large)
  }

  .lg-ml\:xlarge {
    margin-left: var(--lns-space-xlarge)
  }

  .lg-ml\:xxlarge {
    margin-left: var(--lns-space-xxlarge)
  }

  .lg-mr\:0 {
    margin-right: 0
  }

  .lg-mr\:auto {
    margin-right: auto
  }

  .lg-mr\:xsmall {
    margin-right: var(--lns-space-xsmall)
  }

  .lg-mr\:small {
    margin-right: var(--lns-space-small)
  }

  .lg-mr\:medium {
    margin-right: var(--lns-space-medium)
  }

  .lg-mr\:large {
    margin-right: var(--lns-space-large)
  }

  .lg-mr\:xlarge {
    margin-right: var(--lns-space-xlarge)
  }

  .lg-mr\:xxlarge {
    margin-right: var(--lns-space-xxlarge)
  }

  .lg-mx\:0 {
    margin-left: 0;
    margin-right: 0
  }

  .lg-mx\:auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg-mx\:xsmall {
    margin-left: var(--lns-space-xsmall);
    margin-right: var(--lns-space-xsmall)
  }

  .lg-mx\:small {
    margin-left: var(--lns-space-small);
    margin-right: var(--lns-space-small)
  }

  .lg-mx\:medium {
    margin-left: var(--lns-space-medium);
    margin-right: var(--lns-space-medium)
  }

  .lg-mx\:large {
    margin-left: var(--lns-space-large);
    margin-right: var(--lns-space-large)
  }

  .lg-mx\:xlarge {
    margin-left: var(--lns-space-xlarge);
    margin-right: var(--lns-space-xlarge)
  }

  .lg-mx\:xxlarge {
    margin-left: var(--lns-space-xxlarge);
    margin-right: var(--lns-space-xxlarge)
  }

  .lg-my\:0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .lg-my\:auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .lg-my\:xsmall {
    margin-top: var(--lns-space-xsmall);
    margin-bottom: var(--lns-space-xsmall)
  }

  .lg-my\:small {
    margin-top: var(--lns-space-small);
    margin-bottom: var(--lns-space-small)
  }

  .lg-my\:medium {
    margin-top: var(--lns-space-medium);
    margin-bottom: var(--lns-space-medium)
  }

  .lg-my\:large {
    margin-top: var(--lns-space-large);
    margin-bottom: var(--lns-space-large)
  }

  .lg-my\:xlarge {
    margin-top: var(--lns-space-xlarge);
    margin-bottom: var(--lns-space-xlarge)
  }

  .lg-my\:xxlarge {
    margin-top: var(--lns-space-xxlarge);
    margin-bottom: var(--lns-space-xxlarge)
  }

  .lg-p\:0 {
    padding: 0
  }

  .lg-p\:xsmall {
    padding: var(--lns-space-xsmall)
  }

  .lg-p\:small {
    padding: var(--lns-space-small)
  }

  .lg-p\:medium {
    padding: var(--lns-space-medium)
  }

  .lg-p\:large {
    padding: var(--lns-space-large)
  }

  .lg-p\:xlarge {
    padding: var(--lns-space-xlarge)
  }

  .lg-p\:xxlarge {
    padding: var(--lns-space-xxlarge)
  }

  .lg-pt\:0 {
    padding-top: 0
  }

  .lg-pt\:xsmall {
    padding-top: var(--lns-space-xsmall)
  }

  .lg-pt\:small {
    padding-top: var(--lns-space-small)
  }

  .lg-pt\:medium {
    padding-top: var(--lns-space-medium)
  }

  .lg-pt\:large {
    padding-top: var(--lns-space-large)
  }

  .lg-pt\:xlarge {
    padding-top: var(--lns-space-xlarge)
  }

  .lg-pt\:xxlarge {
    padding-top: var(--lns-space-xxlarge)
  }

  .lg-pb\:0 {
    padding-bottom: 0
  }

  .lg-pb\:xsmall {
    padding-bottom: var(--lns-space-xsmall)
  }

  .lg-pb\:small {
    padding-bottom: var(--lns-space-small)
  }

  .lg-pb\:medium {
    padding-bottom: var(--lns-space-medium)
  }

  .lg-pb\:large {
    padding-bottom: var(--lns-space-large)
  }

  .lg-pb\:xlarge {
    padding-bottom: var(--lns-space-xlarge)
  }

  .lg-pb\:xxlarge {
    padding-bottom: var(--lns-space-xxlarge)
  }

  .lg-pl\:0 {
    padding-left: 0
  }

  .lg-pl\:xsmall {
    padding-left: var(--lns-space-xsmall)
  }

  .lg-pl\:small {
    padding-left: var(--lns-space-small)
  }

  .lg-pl\:medium {
    padding-left: var(--lns-space-medium)
  }

  .lg-pl\:large {
    padding-left: var(--lns-space-large)
  }

  .lg-pl\:xlarge {
    padding-left: var(--lns-space-xlarge)
  }

  .lg-pl\:xxlarge {
    padding-left: var(--lns-space-xxlarge)
  }

  .lg-pr\:0 {
    padding-right: 0
  }

  .lg-pr\:xsmall {
    padding-right: var(--lns-space-xsmall)
  }

  .lg-pr\:small {
    padding-right: var(--lns-space-small)
  }

  .lg-pr\:medium {
    padding-right: var(--lns-space-medium)
  }

  .lg-pr\:large {
    padding-right: var(--lns-space-large)
  }

  .lg-pr\:xlarge {
    padding-right: var(--lns-space-xlarge)
  }

  .lg-pr\:xxlarge {
    padding-right: var(--lns-space-xxlarge)
  }

  .lg-px\:0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg-px\:xsmall {
    padding-left: var(--lns-space-xsmall);
    padding-right: var(--lns-space-xsmall)
  }

  .lg-px\:small {
    padding-left: var(--lns-space-small);
    padding-right: var(--lns-space-small)
  }

  .lg-px\:medium {
    padding-left: var(--lns-space-medium);
    padding-right: var(--lns-space-medium)
  }

  .lg-px\:large {
    padding-left: var(--lns-space-large);
    padding-right: var(--lns-space-large)
  }

  .lg-px\:xlarge {
    padding-left: var(--lns-space-xlarge);
    padding-right: var(--lns-space-xlarge)
  }

  .lg-px\:xxlarge {
    padding-left: var(--lns-space-xxlarge);
    padding-right: var(--lns-space-xxlarge)
  }

  .lg-py\:0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg-py\:xsmall {
    padding-top: var(--lns-space-xsmall);
    padding-bottom: var(--lns-space-xsmall)
  }

  .lg-py\:small {
    padding-top: var(--lns-space-small);
    padding-bottom: var(--lns-space-small)
  }

  .lg-py\:medium {
    padding-top: var(--lns-space-medium);
    padding-bottom: var(--lns-space-medium)
  }

  .lg-py\:large {
    padding-top: var(--lns-space-large);
    padding-bottom: var(--lns-space-large)
  }

  .lg-py\:xlarge {
    padding-top: var(--lns-space-xlarge);
    padding-bottom: var(--lns-space-xlarge)
  }

  .lg-py\:xxlarge {
    padding-top: var(--lns-space-xxlarge);
    padding-bottom: var(--lns-space-xxlarge)
  }

  .lg-text\:small {
    font-size: var(--lns-fontSize-small);
    line-height: var(--lns-lineHeight-small)
  }

  .lg-text\:body-sm {
    font-size: var(--lns-fontSize-body-sm);
    line-height: var(--lns-lineHeight-body-sm)
  }

  .lg-text\:medium {
    font-size: var(--lns-fontSize-medium);
    line-height: var(--lns-lineHeight-medium)
  }

  .lg-text\:body-md {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md)
  }

  .lg-text\:large {
    font-size: var(--lns-fontSize-large);
    line-height: var(--lns-lineHeight-large)
  }

  .lg-text\:body-lg {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg)
  }

  .lg-text\:xlarge {
    font-size: var(--lns-fontSize-xlarge);
    line-height: var(--lns-lineHeight-xlarge)
  }

  .lg-text\:heading-sm {
    font-size: var(--lns-fontSize-heading-sm);
    line-height: var(--lns-lineHeight-heading-sm)
  }

  .lg-text\:xxlarge {
    font-size: var(--lns-fontSize-xxlarge);
    line-height: var(--lns-lineHeight-xxlarge)
  }

  .lg-text\:heading-md {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md)
  }

  .lg-text\:xxxlarge {
    font-size: var(--lns-fontSize-xxxlarge);
    line-height: var(--lns-lineHeight-xxxlarge)
  }

  .lg-text\:heading-lg {
    font-size: var(--lns-fontSize-heading-lg);
    line-height: var(--lns-lineHeight-heading-lg)
  }

  .lg-weight\:book {
    font-weight: var(--lns-fontWeight-book)
  }

  .lg-weight\:bold {
    font-weight: var(--lns-fontWeight-bold)
  }

  .lg-text\:body {
    font-size: var(--lns-fontSize-body-md);
    line-height: var(--lns-lineHeight-body-md);
    font-weight: var(--lns-fontWeight-book)
  }

  .lg-text\:title {
    font-size: var(--lns-fontSize-body-lg);
    line-height: var(--lns-lineHeight-body-lg);
    font-weight: var(--lns-fontWeight-bold)
  }

  .lg-text\:mainTitle {
    font-size: var(--lns-fontSize-heading-md);
    line-height: var(--lns-lineHeight-heading-md);
    font-weight: var(--lns-fontWeight-bold)
  }

  .lg-text\:left {
    text-align: left
  }

  .lg-text\:right {
    text-align: right
  }

  .lg-text\:center {
    text-align: center
  }

  .lg-border {
    border: 1px solid var(--lns-color-border)
  }

  .lg-borderTop {
    border-top: 1px solid var(--lns-color-border)
  }

  .lg-borderBottom {
    border-bottom: 1px solid var(--lns-color-border)
  }

  .lg-borderLeft {
    border-left: 1px solid var(--lns-color-border)
  }

  .lg-borderRight {
    border-right: 1px solid var(--lns-color-border)
  }

  .lg-inline {
    display: inline
  }

  .lg-block {
    display: block
  }

  .lg-flex {
    display: flex
  }

  .lg-inlineBlock {
    display: inline-block
  }

  .lg-inlineFlex {
    display: inline-flex
  }

  .lg-none {
    display: none
  }

  .lg-flexWrap {
    flex-wrap: wrap
  }

  .lg-flexDirection\:column {
    flex-direction: column
  }

  .lg-flexDirection\:row {
    flex-direction: row
  }

  .lg-items\:stretch {
    align-items: stretch
  }

  .lg-items\:center {
    align-items: center
  }

  .lg-items\:baseline {
    align-items: baseline
  }

  .lg-items\:flexStart {
    align-items: flex-start
  }

  .lg-items\:flexEnd {
    align-items: flex-end
  }

  .lg-items\:selfStart {
    align-items: self-start
  }

  .lg-items\:selfEnd {
    align-items: self-end
  }

  .lg-justify\:flexStart {
    justify-content: flex-start
  }

  .lg-justify\:flexEnd {
    justify-content: flex-end
  }

  .lg-justify\:center {
    justify-content: center
  }

  .lg-justify\:spaceBetween {
    justify-content: space-between
  }

  .lg-justify\:spaceAround {
    justify-content: space-around
  }

  .lg-justify\:spaceEvenly {
    justify-content: space-evenly
  }

  .lg-grow\:0 {
    flex-grow: 0
  }

  .lg-grow\:1 {
    flex-grow: 1
  }

  .lg-shrink\:0 {
    flex-shrink: 0
  }

  .lg-shrink\:1 {
    flex-shrink: 1
  }

  .lg-self\:auto {
    align-self: auto
  }

  .lg-self\:flexStart {
    align-self: flex-start
  }

  .lg-self\:flexEnd {
    align-self: flex-end
  }

  .lg-self\:center {
    align-self: center
  }

  .lg-self\:baseline {
    align-self: baseline
  }

  .lg-self\:stretch {
    align-self: stretch
  }

  .lg-overflow\:hidden {
    overflow: hidden
  }

  .lg-overflow\:auto {
    overflow: auto
  }

  .lg-relative {
    position: relative
  }

  .lg-absolute {
    position: absolute
  }

  .lg-sticky {
    position: sticky
  }

  .lg-fixed {
    position: fixed
  }

  .lg-top\:0 {
    top: 0
  }

  .lg-top\:auto {
    top: auto
  }

  .lg-top\:xsmall {
    top: var(--lns-space-xsmall)
  }

  .lg-top\:small {
    top: var(--lns-space-small)
  }

  .lg-top\:medium {
    top: var(--lns-space-medium)
  }

  .lg-top\:large {
    top: var(--lns-space-large)
  }

  .lg-top\:xlarge {
    top: var(--lns-space-xlarge)
  }

  .lg-top\:xxlarge {
    top: var(--lns-space-xxlarge)
  }

  .lg-bottom\:0 {
    bottom: 0
  }

  .lg-bottom\:auto {
    bottom: auto
  }

  .lg-bottom\:xsmall {
    bottom: var(--lns-space-xsmall)
  }

  .lg-bottom\:small {
    bottom: var(--lns-space-small)
  }

  .lg-bottom\:medium {
    bottom: var(--lns-space-medium)
  }

  .lg-bottom\:large {
    bottom: var(--lns-space-large)
  }

  .lg-bottom\:xlarge {
    bottom: var(--lns-space-xlarge)
  }

  .lg-bottom\:xxlarge {
    bottom: var(--lns-space-xxlarge)
  }

  .lg-left\:0 {
    left: 0
  }

  .lg-left\:auto {
    left: auto
  }

  .lg-left\:xsmall {
    left: var(--lns-space-xsmall)
  }

  .lg-left\:small {
    left: var(--lns-space-small)
  }

  .lg-left\:medium {
    left: var(--lns-space-medium)
  }

  .lg-left\:large {
    left: var(--lns-space-large)
  }

  .lg-left\:xlarge {
    left: var(--lns-space-xlarge)
  }

  .lg-left\:xxlarge {
    left: var(--lns-space-xxlarge)
  }

  .lg-right\:0 {
    right: 0
  }

  .lg-right\:auto {
    right: auto
  }

  .lg-right\:xsmall {
    right: var(--lns-space-xsmall)
  }

  .lg-right\:small {
    right: var(--lns-space-small)
  }

  .lg-right\:medium {
    right: var(--lns-space-medium)
  }

  .lg-right\:large {
    right: var(--lns-space-large)
  }

  .lg-right\:xlarge {
    right: var(--lns-space-xlarge)
  }

  .lg-right\:xxlarge {
    right: var(--lns-space-xxlarge)
  }

  .lg-width\:auto {
    width: auto
  }

  .lg-width\:full {
    width: 100%
  }

  .lg-width\:0 {
    width: 0
  }

  .lg-minWidth\:0 {
    min-width: 0
  }

  .lg-height\:auto {
    height: auto
  }

  .lg-height\:full {
    height: 100%
  }

  .lg-height\:0 {
    height: 0
  }

  .lg-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .lg-srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }
}

#inner-shadow-companion {
  --lns-unit: 8px;
  all: initial;
  font-family: circular, Helvetica, sans-serif;
  color: var(--lns-color-body);
}

#tooltip-mount-layer-companion {
  z-index: 2147483646;
  position: relative;

  color: var(--lns-color-body);
  pointer-events: auto;
}


#AC_ad,
#AD_160,
#AD_300,
#AD_468x60,
#AD_G,
#AD_L,
#AD_ROW,
#AD_Top,
#AD_text,
#ADbox,
#Ad-3-Slider,
#Ad-4-Slider,
#Ad-Container,
#Ad-Content,
#Ad-Top,
#AdBanner,
#AdBar,
#AdBigBox,
#AdBillboard,
#AdBlock,
#AdBottomLeader,
#AdBottomRight,
#AdBox2,
#AdColumn,
#AdContainerTop,
#AdContent,
#AdDisclaimer,
#AdHeader,
#AdMiddle,
#AdPopUp,
#AdRectangleBanner,
#AdSense1,
#AdSense2,
#AdSense3,
#AdServer,
#AdSkyscraper,
#AdSlot_megabanner,
#AdSpaceLeaderboard,
#AdTop,
#AdTopLeader,
#AdWidgetContainer,
#AdWrapperSuperCA,
#AdZone1,
#AdZone2,
#Ad_BelowContent,
#Ad_Block,
#Ad_TopLeaderboard,
#Adbanner,
#Adlabel,
#AdsBannerTop,
#AdsBillboard,
#AdsBottomContainer,
#AdsContent,
#AdsDiv,
#AdsFrame,
#AdsPubperform,
#AdsRight,
#AdsSky,
#AdsTopContainer,
#AdsWrap,
#Ads_BA_BS,
#Ads_BA_BUT,
#Ads_BA_BUT2,
#Ads_BA_BUT_box,
#Ads_BA_CAD,
#Ads_BA_CAD2,
#Ads_BA_FLB,
#Ads_BA_SKY,
#Ads_BA_VID,
#Ads_TFM_BS,
#Ads_google_bottom_wide,
#Adsense300x250,
#AdsenseBottom,
#AdsenseTop,
#Adsterra,
#Adv10,
#Adv11,
#Adv8,
#Adv9,
#AdvContainer,
#AdvFooter,
#AdvHeader,
#Adv_Footer,
#AdvertMid1,
#AdvertMid2,
#AdvertPanel,
#AdvertText,
#AdvertiseFrame,
#Advertisement1,
#Advertisement2,
#AdvertisementDiv,
#AdvertisementLeaderboard,
#Advertisements,
#AdvertisingDiv_0,
#Advertorial,
#Advertorials,
#AnchorAd,
#ArticleContentAd,
#Banner728x90,
#BannerAd,
#BannerAds,
#BannerAdvert,
#BannerAdvertisement,
#BigBoxAd,
#BigboxAdUnit,
#BodyAd,
#BodyTopAds,
#Body_Ad8_divAdd,
#BotAd,
#BottomAdContainer,
#BottomRightAdWrapper,
#ButtonAd,
#ContentAd,
#Content_CA_AD_0_BC,
#Content_CA_AD_1_BC,
#DFP_top_leaderboard,
#FooterAd,
#FooterAdBlock,
#FooterAdContainer,
#GoogleAd,
#GoogleAd1,
#GoogleAd2,
#GoogleAd3,
#GoogleAdRight,
#GoogleAdTop,
#GoogleAdsense,
#HP1-ad,
#HP2-ad,
#HeadAd,
#HeaderAD,
#HeaderAd,
#HeaderAdBlock,
#HeaderAdsBlock,
#HeroAd,
#HomeAd1,
#IFrameAd,
#IFrameAd1,
#IK-ad-area,
#IK-ad-block,
#IM_AD,
#LargeRectangleAd,
#LayoutBottomAdBox,
#LayoutHomeAdBoxBottom,
#LeaderboardAdvertising,
#LeftAd,
#LeftAd1,
#MPUAdSpace,
#MPUadvertising,
#MPUadvertisingDetail,
#MainAd,
#MediumRectangleAD,
#MidPageAds,
#MiddleRightRadvertisement,
#Mpu_Bottom,
#Mpu_Top,
#MyAdsId3,
#NR-Ads,
#PaneAdvertisingContainer,
#Player_Playoncontent,
#Player_Playoncontent_footer,
#PromotionAdBox,
#RadAdSkyscraper,
#RightAd,
#RightAdBlock,
#RightAdSpace,
#RightAdvertisement,
#SidebarAd,
#SidebarAdContainer,
#SitenavAdslot,
#SkyAd,
#SkyscraperAD,
#SponsoredAd,
#SponsoredAds,
#SponsoredLinks,
#SponsorsAds,
#StickyBannerAd,
#Top-ad,
#Top1AdWrapper,
#TopADs,
#TopAd,
#TopAd0,
#TopAdBox,
#TopAdContainer,
#TopAdPlacement,
#TopAdPos,
#TopAdTable,
#TopAdvert,
#TopBannerAd,
#TopRightRadvertisement,
#VPNAdvert,
#WelcomeAd,
#aad-header-1,
#aad-header-2,
#aad-header-3,
#ab_adblock,
#above-comments-ad,
#above-fold-ad,
#above-footer-ads,
#aboveAd,
#aboveNodeAds,
#above_button_ad,
#aboveplayerad,
#abovepostads,
#acm-ad-tag-lawrence_dfp_mobile_arkadium,
#ad--article--home-mobile-paramount-wrapper,
#ad--article-bottom-wrapper,
#ad--article-top,
#ad--sidebar,
#ad-0,
#ad-1,
#ad-125x125,
#ad-160,
#ad-160x600,
#ad-2,
#ad-2-160x600,
#ad-250,
#ad-250x300,
#ad-3,
#ad-3-300x250,
#ad-300,
#ad-300-250,
#ad-300-additional,
#ad-300-detail,
#ad-300-sidebar,
#ad-300X250-2,
#ad-300a,
#ad-300b,
#ad-300x250,
#ad-300x250-0,
#ad-300x250-2,
#ad-300x250-b,
#ad-300x250-sidebar,
#ad-300x250-wrapper,
#ad-300x250_mid,
#ad-300x250_mobile,
#ad-300x250_top,
#ad-300x600_top,
#ad-4,
#ad-5,
#ad-6,
#ad-7,
#ad-728,
#ad-728-90,
#ad-728x90,
#ad-8,
#ad-9,
#ad-Content_1,
#ad-Content_2,
#ad-Rectangle_1,
#ad-Rectangle_2,
#ad-Superbanner,
#ad-a,
#ad-ads,
#ad-advertorial,
#ad-affiliate,
#ad-after,
#ad-anchor,
#ad-around-the-web,
#ad-article,
#ad-article-in,
#ad-aside-1,
#ad-background,
#ad-ban,
#ad-banner-1,
#ad-banner-atf,
#ad-banner-bottom,
#ad-banner-btf,
#ad-banner-desktop,
#ad-banner-image,
#ad-banner-placement,
#ad-banner-top,
#ad-banner-wrap,
#ad-banner_atf-label,
#ad-bar,
#ad-base,
#ad-bb-content,
#ad-below-content,
#ad-bg,
#ad-big,
#ad-bigbox,
#ad-bigsize,
#ad-billboard,
#ad-billboard-atf,
#ad-billboard-bottom,
#ad-billboard01,
#ad-blade,
#ad-block,
#ad-block-125,
#ad-block-2,
#ad-block-aa,
#ad-block-bottom,
#ad-block-container,
#ad-border,
#ad-bottom,
#ad-bottom-banner,
#ad-bottom-fixed,
#ad-bottom-right-container,
#ad-bottom-wrapper,
#ad-box,
#ad-box-1,
#ad-box-2,
#ad-box-bottom,
#ad-box-halfpage,
#ad-box-leaderboard,
#ad-box-left,
#ad-box-rectangle,
#ad-box-rectangle-2,
#ad-box-right,
#ad-box1,
#ad-box2,
#ad-boxes,
#ad-break,
#ad-bs,
#ad-btm,
#ad-buttons,
#ad-campaign,
#ad-carousel,
#ad-case,
#ad-center,
#ad-chips,
#ad-circfooter,
#ad-code,
#ad-col,
#ad-container-banner,
#ad-container-fullpage,
#ad-container-inner,
#ad-container-leaderboard,
#ad-container-mpu,
#ad-container-outer,
#ad-container-overlay,
#ad-container-top-placeholder,
#ad-container1,
#ad-contentad,
#ad-desktop-bottom,
#ad-desktop-takeover-home,
#ad-desktop-takeover-int,
#ad-desktop-top,
#ad-desktop-wrap,
#ad-discover,
#ad-display-ad,
#ad-display-ad-placeholder,
#ad-div-leaderboard,
#ad-drawer,
#ad-ear,
#ad-extra-flat,
#ad-featured-right,
#ad-fixed-bottom,
#ad-flex-top,
#ad-flyout,
#ad-footer-728x90,
#ad-framework-top,
#ad-front-btf,
#ad-front-footer,
#ad-full-width,
#ad-fullbanner-btf,
#ad-fullbanner-outer,
#ad-fullbanner2,
#ad-fullwidth,
#ad-googleAdSense,
#ad-gutter-left,
#ad-gutter-right,
#ad-halfpage,
#ad-halfpage1,
#ad-halfpage2,
#ad-head,
#ad-header-1,
#ad-header-2,
#ad-header-3,
#ad-header-left,
#ad-header-mad,
#ad-header-mobile,
#ad-header-right,
#ad-holder,
#ad-horizontal,
#ad-horizontal-header,
#ad-horizontal-top,
#ad-incontent,
#ad-index,
#ad-inline-block,
#ad-label2,
#ad-large-banner-top,
#ad-large-header,
#ad-lb-secondary,
#ad-lead,
#ad-leadboard1,
#ad-leadboard2,
#ad-leader,
#ad-leader-atf,
#ad-leader-container,
#ad-leader-wrapper,
#ad-leaderboard,
#ad-leaderboard-atf,
#ad-leaderboard-bottom,
#ad-leaderboard-container,
#ad-leaderboard-footer,
#ad-leaderboard-header,
#ad-leaderboard-spot,
#ad-leaderboard-top,
#ad-leaderboard970x90home,
#ad-leaderboard970x90int,
#ad-leaderboard_bottom,
#ad-leadertop,
#ad-lrec,
#ad-m-rec-content,
#ad-main,
#ad-main-bottom,
#ad-main-top,
#ad-manager,
#ad-masthead,
#ad-medium,
#ad-medium-lower,
#ad-medium-rectangle,
#ad-medrec,
#ad-medrec__first,
#ad-mid,
#ad-mid-rect,
#ad-middle,
#ad-midpage,
#ad-minibar,
#ad-module,
#ad-mpu,
#ad-mrec,
#ad-mrec2,
#ad-new,
#ad-north,
#ad-one,
#ad-other,
#ad-output,
#ad-overlay,
#ad-p3,
#ad-page-1,
#ad-pan3l,
#ad-panel,
#ad-pencil,
#ad-performance,
#ad-performanceFullbanner1,
#ad-performanceRectangle1,
#ad-placeholder,
#ad-placeholder-horizontal,
#ad-placeholder-vertical,
#ad-placement,
#ad-plate,
#ad-player,
#ad-popup,
#ad-popup-home,
#ad-popup-int,
#ad-post,
#ad-promo,
#ad-push,
#ad-pushdown,
#ad-r,
#ad-rec-atf,
#ad-rec-btf,
#ad-rec-btf-top,
#ad-rect,
#ad-rectangle,
#ad-rectangle1,
#ad-rectangle1-outer,
#ad-rectangle2,
#ad-rectangle3,
#ad-results,
#ad-right,
#ad-right-bar-tall,
#ad-right-container,
#ad-right-sidebar,
#ad-right-top,
#ad-right2,
#ad-right3,
#ad-rotator,
#ad-row,
#ad-section,
#ad-separator,
#ad-shop,
#ad-side,
#ad-side-text,
#ad-sidebar,
#ad-sidebar-btf,
#ad-sidebar-container,
#ad-sidebar-mad,
#ad-sidebar-mad-wrapper,
#ad-sidebar1,
#ad-sidebar2,
#ad-site-header,
#ad-skin,
#ad-skm-below-content,
#ad-sky,
#ad-skyscraper,
#ad-slideshow,
#ad-slideshow2,
#ad-slot,
#ad-slot-1,
#ad-slot-2,
#ad-slot-3,
#ad-slot-4,
#ad-slot-5,
#ad-slot-502,
#ad-slot-lb,
#ad-slot-right,
#ad-slot-top,
#ad-slot1,
#ad-slot2,
#ad-slot4,
#ad-slug-wrapper,
#ad-small-banner,
#ad-space,
#ad-space-big,
#ad-splash,
#ad-sponsors,
#ad-spot,
#ad-spot-bottom,
#ad-spot-one,
#ad-standard,
#ad-standard-wrap,
#ad-stickers,
#ad-sticky-footer-container,
#ad-story-right,
#ad-story-top,
#ad-stripe,
#ad-target,
#ad-teaser,
#ad-text,
#ad-three,
#ad-top,
#ad-top-250,
#ad-top-300x250,
#ad-top-728,
#ad-top-banner,
#ad-top-leaderboard,
#ad-top-left,
#ad-top-lock,
#ad-top-low,
#ad-top-right,
#ad-top-right-container,
#ad-top-text-low,
#ad-top-wrap,
#ad-top-wrapper,
#ad-tower,
#ad-two,
#ad-undefined,
#ad-unit-right-bottom-160-600,
#ad-unit-right-middle-300-250,
#ad-unit-top-banner,
#ad-vip-article,
#ad-west,
#ad-wide-leaderboard,
#ad-wrap,
#ad-wrap2,
#ad-wrapper,
#ad-wrapper-728x90,
#ad-wrapper-footer-1,
#ad-wrapper-main-1,
#ad-wrapper-sidebar-1,
#ad-wrapper-top-1,
#ad1-placeholder,
#ad125x125,
#ad160,
#ad160600,
#ad160x600,
#ad250,
#ad300,
#ad300-250,
#ad300_250,
#ad336,
#ad336x280,
#ad468,
#ad468x60,
#ad480x60,
#ad6,
#ad600,
#ad728,
#ad72890,
#ad728Box,
#ad728Header,
#ad728Mid,
#ad728Top,
#ad728Wrapper,
#ad728X90,
#ad728foot,
#ad728h,
#ad728top,
#ad728x90,
#ad728x90_1,
#ad90,
#ad900,
#ad970,
#ad970x90_exp,
#adATF300x250,
#adATF728x90,
#adATFLeaderboard,
#adAside,
#adBTF300x250,
#adBadges,
#adBanner1,
#adBanner336x280,
#adBannerBottom,
#adBannerHeader,
#adBannerSpacer,
#adBannerTable,
#adBannerTop,
#adBar,
#adBelt,
#adBillboard,
#adBlock01,
#adBlockBanner,
#adBlockContainer,
#adBlockContent,
#adBlockOverlay,
#adBlocks,
#adBottom,
#adBox,
#adBrandDev,
#adBrandingStation,
#adBreak,
#adCarousel,
#adChannel,
#adChoiceFooter,
#adChoices,
#adChoicesIcon,
#adChoicesLogo,
#adCol,
#adColumn,
#adColumn3,
#adComponentWrapper,
#adContainer,
#adContainer_1,
#adContainer_2,
#adContainer_3,
#adContent,
#adContentHolder,
#adContext,
#adDiv,
#adDiv0,
#adDiv1,
#adDiv300,
#adDiv4,
#adDiv728,
#adDivContainer,
#adFiller,
#adFlashDiv,
#adFooter,
#adFot,
#adFrame,
#adGallery,
#adGoogleText,
#adHeader,
#adHeaderTop,
#adHeaderWrapper,
#adHeading,
#adHeightstory,
#adHolder,
#adHolder1,
#adHolder2,
#adHolder3,
#adHolder4,
#adHolder5,
#adHolder6,
#adHome,
#adHomeTop,
#adIframe,
#adInhouse,
#adIsland,
#adLB,
#adLabel,
#adLarge,
#adLayer,
#adLayerTop,
#adLayout,
#adLeader,
#adLeaderTop,
#adLeaderboard,
#adLeaderboard-middle,
#adLeft,
#adLink,
#adLink1,
#adLounge,
#adLrec,
#adMOBILETOP,
#adMPU,
#adMPUHolder,
#adMain,
#adMarketplace,
#adMed,
#adMedRect,
#adMediumRectangle,
#adMeld,
#adMessage,
#adMid2,
#adMpu,
#adMpuBottom,
#adOuter,
#adPartnerLinks,
#adPlaceHolder1,
#adPlaceHolder2,
#adPlacement_1,
#adPlacement_2,
#adPlacement_3,
#adPlacement_4,
#adPlacement_7,
#adPlacement_8,
#adPlacement_9,
#adPlacer,
#adPopover,
#adPopup,
#adPosition0,
#adPosition14,
#adPosition5,
#adPosition6,
#adPosition7,
#adPosition9,
#adPush,
#adPushdown1,
#adReady,
#adRight,
#adRight1,
#adRight2,
#adRight3,
#adRight4,
#adRight5,
#adScraper,
#adSection,
#adSenseBox,
#adSenseModule,
#adSenseWrapper,
#adSet,
#adSide,
#adSide1-container,
#adSideButton,
#adSidebar,
#adSite,
#adSkin,
#adSkinBackdrop,
#adSkinLeft,
#adSkinRight,
#adSky,
#adSkyPosition,
#adSkyscraper,
#adSlider,
#adSlot-dmpu,
#adSlot-dontMissLarge,
#adSlot-leader,
#adSlot-leaderBottom,
#adSlot1,
#adSlot2,
#adSlot3,
#adSlot4,
#adSlug,
#adSpace,
#adSpaceBottom,
#adSpaceHeight,
#adSpacer,
#adSpecial,
#adSqb,
#adSquare,
#adStrip,
#adSuperbanner,
#adTag,
#adText,
#adTextLink,
#adTile,
#adTop,
#adTopContent,
#adTopLREC,
#adTopLarge,
#adTopModule,
#adTower,
#adUnderArticle,
#adUnit,
#adWideSkyscraper,
#adWrap,
#adWrapper,
#adWrapperSky,
#ad_1,
#ad_160,
#ad_160_600,
#ad_160_600_2,
#ad_160x160,
#ad_160x600,
#ad_2,
#ad_250,
#ad_250x250,
#ad_3,
#ad_300,
#ad_300_250,
#ad_300_250_1,
#ad_300x250,
#ad_336,
#ad_4,
#ad_468_60,
#ad_468x60,
#ad_5,
#ad_728,
#ad_728_90,
#ad_728x90,
#ad_8,
#ad_9,
#ad_B1,
#ad_Banner,
#ad_Bottom,
#ad_LargeRec01,
#ad_Middle,
#ad_Middle1,
#ad_Pushdown,
#ad_R1,
#ad_Right,
#ad_Top,
#ad_Wrap,
#ad__billboard,
#ad_ad,
#ad_adsense,
#ad_after_header_1,
#ad_anchor,
#ad_area,
#ad_article1_1,
#ad_article1_2,
#ad_article2_1,
#ad_article2_2,
#ad_article3_1,
#ad_article3_2,
#ad_banner,
#ad_banner_1,
#ad_banner_468x60,
#ad_banner_728x90,
#ad_banner_bot,
#ad_banner_top,
#ad_banners,
#ad_bar,
#ad_bar_rect,
#ad_before_header,
#ad_bg,
#ad_bg_image,
#ad_big,
#ad_bigbox,
#ad_bigbox_companion,
#ad_bigrectangle,
#ad_billboard,
#ad_block,
#ad_block_0,
#ad_block_1,
#ad_block_2,
#ad_block_mpu,
#ad_bnr_atf_01,
#ad_bnr_atf_02,
#ad_bnr_atf_03,
#ad_bnr_btf_07,
#ad_bnr_btf_08,
#ad_body,
#ad_bottom,
#ad_box,
#ad_box_top,
#ad_branding,
#ad_bsb,
#ad_bsb_cont,
#ad_btmslot,
#ad_button,
#ad_buttons,
#ad_cell,
#ad_center,
#ad_choices,
#ad_close,
#ad_closebtn,
#ad_comments,
#ad_cont,
#ad_cont_superbanner,
#ad_container,
#ad_container_0,
#ad_container_300x250,
#ad_container_side,
#ad_container_sidebar,
#ad_container_top,
#ad_content,
#ad_content_1,
#ad_content_2,
#ad_content_3,
#ad_content_fullsize,
#ad_content_primary,
#ad_content_right,
#ad_content_top,
#ad_content_wrap,
#ad_contentslot_1,
#ad_contentslot_2,
#ad_creative_2,
#ad_creative_3,
#ad_creative_5,
#ad_dfp_rec1,
#ad_display_300_250,
#ad_display_728_90,
#ad_div,
#ad_div_bottom,
#ad_div_top,
#ad_feedback,
#ad_foot,
#ad_footer,
#ad_footer1,
#ad_footerAd,
#ad_frame,
#ad_frame1,
#ad_from_bottom,
#ad_fullbanner,
#ad_gallery,
#ad_gallery_bot,
#ad_global_300x250,
#ad_global_above_footer,
#ad_global_header,
#ad_global_header1,
#ad_global_header2,
#ad_h3,
#ad_halfpage,
#ad_head,
#ad_header,
#ad_header_1,
#ad_header_container,
#ad_holder,
#ad_home,
#ad_home_middle,
#ad_horizontal,
#ad_houseslot_a,
#ad_houseslot_b,
#ad_hp,
#ad_img,
#ad_interthread,
#ad_island,
#ad_island2,
#ad_label,
#ad_large,
#ad_large_rectangular,
#ad_lateral,
#ad_layer,
#ad_ldb,
#ad_lead1,
#ad_leader,
#ad_leaderBoard,
#ad_leaderboard,
#ad_leaderboard_top,
#ad_left,
#ad_left_1,
#ad_left_2,
#ad_left_3,
#ad_left_skyscraper,
#ad_left_top,
#ad_leftslot,
#ad_link,
#ad_links,
#ad_links_footer,
#ad_lnk,
#ad_lrec,
#ad_lwr_square,
#ad_main,
#ad_main_leader,
#ad_main_top,
#ad_marginal,
#ad_marker,
#ad_mast,
#ad_med_rect,
#ad_medium,
#ad_medium_rectangle,
#ad_medium_rectangular,
#ad_mediumrectangle,
#ad_message,
#ad_middle,
#ad_middle_bottom,
#ad_midstrip,
#ad_mobile,
#ad_module,
#ad_mpu,
#ad_mpu2,
#ad_mpu300x250,
#ad_mrec,
#ad_mrec1,
#ad_mrec2,
#ad_mrec_intext,
#ad_mrec_intext2,
#ad_new,
#ad_news_article,
#ad_newsletter,
#ad_one,
#ad_overlay,
#ad_overlayer,
#ad_panel,
#ad_panorama_top,
#ad_pencil,
#ad_place,
#ad_placeholder,
#ad_player,
#ad_plugs,
#ad_popup_background,
#ad_popup_wrapper,
#ad_post,
#ad_poster,
#ad_primary,
#ad_publicidad,
#ad_rail,
#ad_rec_01,
#ad_rect,
#ad_rect1,
#ad_rect2,
#ad_rect3,
#ad_rect_body,
#ad_rect_bottom,
#ad_rect_btf_01,
#ad_rect_btf_02,
#ad_rect_btf_03,
#ad_rect_btf_04,
#ad_rect_btf_05,
#ad_rectangle,
#ad_region1,
#ad_region2,
#ad_region3,
#ad_region5,
#ad_results,
#ad_right,
#ad_right_box,
#ad_right_top,
#ad_rightslot,
#ad_rotator-2,
#ad_rotator-3,
#ad_row,
#ad_row_home,
#ad_rr_1,
#ad_sec,
#ad_sec_div,
#ad_secondary,
#ad_short,
#ad_sidebar,
#ad_sidebar1,
#ad_sidebar2,
#ad_sidebar3,
#ad_sidebar_1,
#ad_sidebar_left_container,
#ad_sidebar_news,
#ad_sidebar_top,
#ad_sidebody,
#ad_site_header,
#ad_sitebar,
#ad_skin,
#ad_slot,
#ad_slot_bottom,
#ad_slot_leaderboard,
#ad_small,
#ad_space_top,
#ad_sponsored,
#ad_spot_a,
#ad_spot_b,
#ad_spotlight,
#ad_square,
#ad_squares,
#ad_ss,
#ad_stck,
#ad_sticky_wrap,
#ad_strip,
#ad_superbanner,
#ad_table,
#ad_takeover,
#ad_tall,
#ad_tbl,
#ad_top,
#ad_topBanner,
#ad_topScroller,
#ad_top_728x90,
#ad_top_banner,
#ad_top_bar,
#ad_top_holder,
#ad_topbanner,
#ad_topmob,
#ad_topnav,
#ad_topslot,
#ad_two,
#ad_txt,
#ad_under_game,
#ad_unit,
#ad_unit1,
#ad_unit2,
#ad_vertical,
#ad_video_abovePlayer,
#ad_video_belowPlayer,
#ad_video_large,
#ad_video_root,
#ad_wallpaper,
#ad_wide,
#ad_wide_box,
#ad_wideboard,
#ad_widget,
#ad_widget_1,
#ad_window,
#ad_wp,
#ad_wp_base,
#ad_wrap,
#ad_wrapper,
#ad_wrapper1,
#ad_wrapper2,
#ad_xrail_top,
#ad_zone,
#adaptvcompanion,
#adb_bottom,
#adbackground,
#adbanner-container,
#adbanner1,
#adbannerbox,
#adbannerdiv,
#adbannerleft,
#adbannerright,
#adbannerwidget,
#adbar,
#adbig,
#adblade,
#adblade_ad,
#adblock-big,
#adblock-leaderboard,
#adblock-small,
#adblock1,
#adblock2,
#adblock4,
#adblockbottom,
#adbn,
#adbnr,
#adboard,
#adbody,
#adbottom,
#adbottomleft,
#adbottomright,
#adbox,
#adbox--hot_news_ad,
#adbox--page_bottom_ad,
#adbox--page_top_ad,
#adbox-inarticle,
#adbox-topbanner,
#adbox1,
#adbox2,
#adbox_content,
#adbox_right,
#adbutton,
#adbuttons,
#adcell,
#adcenter,
#adcenter2,
#adcenter4,
#adchoices-icon,
#adchoicesBtn,
#adclear,
#adclose,
#adcode,
#adcolContent,
#adcolumn,
#adcontainer,
#adcontainer1,
#adcontainer2,
#adcontainer3,
#adcontainer5,
#adcontainerRight,
#adcontainer_ad_content_top,
#adcontent,
#adcontent1,
#adcontent2,
#adcontextlinks,
#addbottomleft,
#addvert,
#adfactor-label,
#adfloat,
#adfooter,
#adfooter_728x90,
#adframe:not(frameset),
#adframetop,
#adfreeDeskSpace,
#adhalfpage,
#adhead,
#adheader,
#adhesion,
#adhesionAdSlot,
#adhesionUnit,
#adhide,
#adholder,
#adholderContainerHeader,
#adhome,
#adhomepage,
#adjacency,
#adlabel,
#adlabelFooter,
#adlabelfooter,
#adlabelheader,
#adlanding,
#adlayer,
#adlayerContainer,
#adlayerad,
#adleaderboard,
#adleft,
#adlinks,
#adlrec,
#adm-inline-article-ad-1,
#adm-inline-article-ad-2,
#admain,
#admasthead,
#admid,
#admobilefoot,
#admobilefootinside,
#admobilemiddle,
#admobiletop,
#admobiletopinside,
#admod2,
#admpubottom,
#admpubottom2,
#admpufoot,
#admpumiddle,
#admpumiddle2,
#admputop2,
#admsg,
#adnet,
#adnorth,
#ados1,
#ados2,
#ados3,
#ados4,
#adplace,
#adplacement,
#adpos-top,
#adpos2,
#adposition,
#adposition1,
#adposition10,
#adposition1_container,
#adposition2,
#adposition3,
#adposition4,
#adpositionbottom,
#adrect,
#adright,
#adright2,
#adrightbottom,
#adrightrail,
#adriver_middle,
#adriver_top,
#adrotator,
#adrow,
#adrow1,
#adrow3,
#ads-1,
#ads-125,
#ads-200,
#ads-250,
#ads-300,
#ads-300-250,
#ads-336x280,
#ads-468,
#ads-5,
#ads-728x90,
#ads-728x90-I3,
#ads-728x90-I4,
#ads-area,
#ads-article-left,
#ads-banner,
#ads-banner-top,
#ads-bar,
#ads-before-content,
#ads-bg,
#ads-bg-mobile,
#ads-billboard,
#ads-block,
#ads-blog,
#ads-bot,
#ads-bottom,
#ads-col,
#ads-container,
#ads-container-2,
#ads-container-anchor,
#ads-container-single,
#ads-container-top,
#ads-content,
#ads-content-double,
#ads-footer,
#ads-footer-inner,
#ads-footer-wrap,
#ads-google,
#ads-header,
#ads-header-728,
#ads-home-468,
#ads-horizontal,
#ads-inread,
#ads-inside-content,
#ads-leader,
#ads-leaderboard,
#ads-leaderboard1,
#ads-left,
#ads-left-top,
#ads-lrec,
#ads-main,
#ads-menu,
#ads-middle,
#ads-mpu,
#ads-outer,
#ads-pagetop,
#ads-panel,
#ads-pop,
#ads-position-header-desktop,
#ads-right,
#ads-right-bottom,
#ads-right-skyscraper,
#ads-right-top,
#ads-slot,
#ads-space,
#ads-superBanner,
#ads-text,
#ads-top,
#ads-top-728,
#ads-top-wrap,
#ads-under-rotator,
#ads-vertical,
#ads-vertical-wrapper,
#ads-wrap,
#ads-wrapper,
#ads1,
#ads120,
#ads125,
#ads1_box,
#ads2,
#ads2_block,
#ads2_box,
#ads2_container,
#ads3,
#ads300,
#ads300-250,
#ads300x200,
#ads300x250,
#ads300x250_2,
#ads336x280,
#ads4,
#ads468x60,
#ads50,
#ads7,
#ads728,
#ads728bottom,
#ads728top,
#ads728x90,
#ads728x90_2,
#ads728x90top,
#adsBar,
#adsBottom,
#adsContainer,
#adsContent,
#adsDisplay,
#adsHeader,
#adsHeading,
#adsLREC,
#adsLeft,
#adsLinkFooter,
#adsMobileFixed,
#adsMpu,
#adsPanel,
#adsRight,
#adsRightDiv,
#adsSectionLeft,
#adsSectionRight,
#adsSquare,
#adsTG,
#adsTN,
#adsTop,
#adsTopLeft,
#adsTopMobileFixed,
#adsZone,
#adsZone1,
#adsZone2,
#ads[style^="position: absolute; z-index: 30; width: 100%; height"],
#ads_0_container,
#ads_160,
#ads_3,
#ads_300,
#ads_300x250,
#ads_4,
#ads_728,
#ads_728x90,
#ads_728x90_top,
#ads_banner,
#ads_banner1,
#ads_banner_header,
#ads_belownav,
#ads_big,
#ads_block,
#ads_body_1,
#ads_body_2,
#ads_body_3,
#ads_body_4,
#ads_body_5,
#ads_body_6,
#ads_bottom,
#ads_box,
#ads_box1,
#ads_box2,
#ads_box_bottom,
#ads_box_right,
#ads_box_top,
#ads_button,
#ads_campaign,
#ads_catDiv,
#ads_center,
#ads_center_banner,
#ads_central,
#ads_combo2,
#ads_container,
#ads_content,
#ads_desktop_r1,
#ads_desktop_r2,
#ads_expand,
#ads_footer,
#ads_fullsize,
#ads_h,
#ads_h1,
#ads_h2,
#ads_halfsize,
#ads_header,
#ads_horiz,
#ads_horizontal,
#ads_horz,
#ads_in_modal,
#ads_in_video,
#ads_inline_z,
#ads_inner,
#ads_insert_container,
#ads_layout_bottom,
#ads_lb,
#ads_lb_frame,
#ads_leaderbottom,
#ads_left,
#ads_left_top,
#ads_line,
#ads_medrect,
#ads_notice,
#ads_overlay,
#ads_page_top,
#ads_place,
#ads_placeholder,
#ads_player,
#ads_popup,
#ads_right,
#ads_right_sidebar,
#ads_right_top,
#ads_slide_div,
#ads_space,
#ads_space_header,
#ads_superbanner1,
#ads_superbanner2,
#ads_superior,
#ads_td,
#ads_text,
#ads_textlinks,
#ads_title,
#ads_top,
#ads_top2,
#ads_top_banner,
#ads_top_container,
#ads_top_content,
#ads_top_right,
#ads_top_sec,
#ads_topbanner,
#ads_tower1,
#ads_tower_top,
#ads_vert,
#ads_video,
#ads_wide,
#ads_wrapper,
#adsbot,
#adsbottom,
#adsbox,
#adsbox-left,
#adsbox-right,
#adscenter,
#adscolumn,
#adscontainer,
#adscontent,
#adsdiv,
#adsection,
#adsense-2,
#adsense-468x60,
#adsense-area,
#adsense-bottom,
#adsense-container-bottom,
#adsense-header,
#adsense-link,
#adsense-links,
#adsense-middle,
#adsense-post,
#adsense-right,
#adsense-sidebar,
#adsense-tag,
#adsense-text,
#adsense-top,
#adsense-wrap,
#adsense1,
#adsense2,
#adsense468,
#adsense6,
#adsense728,
#adsenseArea,
#adsenseContainer,
#adsenseHeader,
#adsenseLeft,
#adsenseWrap,
#adsense_banner_top,
#adsense_block,
#adsense_bottom_ad,
#adsense_box,
#adsense_box2,
#adsense_center,
#adsense_image,
#adsense_inline,
#adsense_leaderboard,
#adsense_overlay,
#adsense_r_side_sticky_container,
#adsense_sidebar,
#adsense_top,
#adsenseheader,
#adsensehorizontal,
#adsensempu,
#adsenseskyscraper,
#adsensetext,
#adsensetop,
#adsensewide,
#adserv,
#adsframe_2,
#adside,
#adsimage,
#adsitem,
#adskeeper,
#adskinleft,
#adskinlink,
#adskinright,
#adskintop,
#adsky,
#adskyscraper,
#adskyscraper_flex,
#adsleft1,
#adslider,
#adslist,
#adslot-below-updated,
#adslot-download-abovefiles,
#adslot-half-page,
#adslot-homepage-middle,
#adslot-infobox,
#adslot-left-skyscraper,
#adslot-side-mrec,
#adslot-site-footer,
#adslot-site-header,
#adslot-sticky-headerbar,
#adslot-top-rectangle,
#adslot1,
#adslot2,
#adslot3,
#adslot300x250ATF,
#adslot300x250BTF,
#adslot4,
#adslot5,
#adslot6,
#adslot7,
#adslot_1,
#adslot_2,
#adslot_left,
#adslot_rect,
#adslot_top,
#adsmgid,
#adsmiddle,
#adsonar,
#adspace,
#adspace-1,
#adspace-2,
#adspace-300x250,
#adspace-728,
#adspace-728x90,
#adspace-bottom,
#adspace-leaderboard-top,
#adspace-one,
#adspace-top,
#adspace300x250,
#adspaceBox,
#adspaceRow,
#adspace_header,
#adspace_leaderboard,
#adspace_top,
#adspacer,
#adspan,
#adsplace1,
#adsplace2,
#adsplace4,
#adsplash,
#adspot,
#adspot-bottom,
#adspot-top,
#adsquare,
#adsquare2,
#adsright,
#adsside,
#adsspace,
#adstext2,
#adstrip,
#adtab,
#adtext,
#adtop,
#adtxt,
#adunit,
#adunit-article-bottom,
#adunit_video,
#adunitl,
#adv-01,
#adv-300,
#adv-Bottom,
#adv-BoxP,
#adv-Middle,
#adv-Middle1,
#adv-Middle2,
#adv-Scrollable,
#adv-Top,
#adv-TopLeft,
#adv-banner,
#adv-banner-r,
#adv-box,
#adv-companion-iframe,
#adv-container,
#adv-gpt-box-container1,
#adv-gpt-masthead-skin-container1,
#adv-halfpage,
#adv-header,
#adv-leaderblock,
#adv-leaderboard,
#adv-left,
#adv-masthead,
#adv-middle,
#adv-middle1,
#adv-midroll,
#adv-native,
#adv-preroll,
#adv-right,
#adv-right1,
#adv-scrollable,
#adv-sticky-1,
#adv-sticky-2,
#adv-text,
#adv-title,
#adv-top,
#adv-top-skin,
#adv300x250,
#adv300x250container,
#adv468x90,
#adv728,
#adv728x90,
#adv768x90,
#advBoxBottom,
#advCarrousel,
#advHome,
#advHook-Middle1,
#advRectangle,
#advRectangle1,
#advSkin,
#advTop,
#advWrapper,
#adv_300,
#adv_728,
#adv_728x90,
#adv_BoxBottom,
#adv_Inread,
#adv_IntropageOvl,
#adv_LdbMastheadPush,
#adv_Reload,
#adv_Skin,
#adv_bootom,
#adv_border,
#adv_center,
#adv_config,
#adv_contents,
#adv_footer,
#adv_holder,
#adv_leaderboard,
#adv_mob,
#adv_mpu1,
#adv_mpu2,
#adv_network,
#adv_overlay,
#adv_overlay_content,
#adv_r,
#adv_right,
#adv_skin,
#adv_sky,
#adv_textlink,
#adv_top,
#adv_wallpaper,
#adv_wallpaper2,
#advads_ad_widget-18,
#advads_ad_widget-19,
#advads_ad_widget-8,
#adver,
#adver-top,
#adverFrame,
#advert-1,
#advert-120,
#advert-2,
#advert-ahead,
#advert-article,
#advert-article-1,
#advert-article-2,
#advert-article-3,
#advert-banner,
#advert-banner-container,
#advert-banner-wrap,
#advert-banner2,
#advert-block,
#advert-boomer,
#advert-box,
#advert-column,
#advert-container-top,
#advert-display,
#advert-fireplace,
#advert-footer,
#advert-footer-hidden,
#advert-header,
#advert-island,
#advert-leaderboard,
#advert-left,
#advert-mpu,
#advert-posterad,
#advert-rectangle,
#advert-right,
#advert-sky,
#advert-skyscaper,
#advert-skyscraper,
#advert-slider-top,
#advert-text,
#advert-top,
#advert-top-banner,
#advert-wrapper,
#advert1,
#advert2,
#advertBanner,
#advertBox,
#advertBoxRight,
#advertBoxSquare,
#advertColumn,
#advertContainer,
#advertDB,
#advertOverlay,
#advertRight,
#advertSection,
#advertTop,
#advertTopLarge,
#advertTopSmall,
#advertTower,
#advertWrapper,
#advert_1,
#advert_banner,
#advert_belowmenu,
#advert_box,
#advert_container,
#advert_header,
#advert_leaderboard,
#advert_mid,
#advert_mpu,
#advert_right1,
#advert_sky,
#advert_top,
#advertblock,
#advertborder,
#adverticum_r_above,
#adverticum_r_above_container,
#adverticum_r_side_container,
#advertise,
#advertise-block,
#advertise-here,
#advertise-sidebar,
#advertise1,
#advertise2,
#advertiseBanner,
#advertiseLink,
#advertise_top,
#advertisediv,
#advertisement-300x250,
#advertisement-bottom,
#advertisement-content,
#advertisement-large,
#advertisement-placement,
#advertisement-text,
#advertisement1,
#advertisement2,
#advertisement3,
#advertisement728x90,
#advertisementArea,
#advertisementBox,
#advertisementHorizontal,
#advertisementRight,
#advertisementTop,
#advertisement_banner,
#advertisement_belowscreenshots,
#advertisement_block,
#advertisement_box,
#advertisement_container,
#advertisement_label,
#advertisement_notice,
#advertisement_title,
#advertisements_bottom,
#advertisements_sidebar,
#advertisements_top,
#advertisementsarticle,
#advertiser-container,
#advertiserLinks,
#advertisetop,
#advertising-160x600,
#advertising-300x250,
#advertising-728x90,
#advertising-banner,
#advertising-caption,
#advertising-container,
#advertising-right,
#advertising-skyscraper,
#advertising-top,
#advertisingHrefTop,
#advertisingLeftLeft,
#advertisingLink,
#advertisingRightColumn,
#advertisingRightRight,
#advertisingTop,
#advertisingTopWrapper,
#advertising_300,
#advertising_320,
#advertising_728,
#advertising__banner__content,
#advertising_column,
#advertising_container,
#advertising_contentad,
#advertising_div,
#advertising_header,
#advertising_holder,
#advertising_leaderboard,
#advertising_top_container,
#advertising_wrapper,
#advertisment-horizontal,
#advertisment-text,
#advertisment1,
#advertisment_content,
#advertisment_panel,
#advertleft,
#advertorial,
#advertorial-box,
#advertorial-wrap,
#advertorial1,
#advertorial_links,
#adverts,
#adverts--footer,
#adverts-top-container,
#adverts-top-left,
#adverts-top-middle,
#adverts-top-right,
#adverts_base,
#adverts_post_content,
#adverts_right,
#advertscroll,
#advertsingle,
#advertspace,
#advertssection,
#adverttop,
#advframe,
#advr_mobile,
#advsingle,
#advt,
#advt_bottom,
#advtbar,
#advtcell,
#advtext,
#advtop,
#advtopright,
#adwallpaper,
#adwidget,
#adwidget-5,
#adwidget-6,
#adwidget1,
#adwidget2,
#adwrapper,
#adxBigAd,
#adxBigAd2,
#adxLeaderboard,
#adxMiddle,
#adxMiddleRight,
#adxToolSponsor,
#adx_ad,
#adxtop2,
#adzbanner,
#adzone,
#adzone-middle1,
#adzone-middle2,
#adzone-right,
#adzone-top,
#adzone_content,
#adzone_wall,
#adzonebanner,
#adzoneheader,
#afc-container,
#affiliate_2,
#affiliate_ad,
#after-dfp-ad-mid1,
#after-dfp-ad-mid2,
#after-dfp-ad-mid3,
#after-dfp-ad-mid4,
#after-dfp-ad-top,
#after-header-ads,
#after-top-menu-ads,
#after_ad,
#after_bottom_ad,
#after_heading_ad,
#after_title_ad,
#amazon-ads,
#amazon_ad,
#analytics_ad,
#anchor-ad,
#anchorAd,
#aniview--player,
#aniview-ads,
#aom-ad-right_side_1,
#aom-ad-right_side_2,
#aom-ad-top,
#apiBackgroundAd,
#around-the-web,
#article-ad,
#article-ad-container,
#article-ad-content,
#article-ads,
#article-advert,
#article-aside-top-ad,
#article-billboard-ad-1,
#article-bottom-ad,
#article-box-ad,
#article-content-ad,
#article-footer-ad,
#article-footer-sponsors,
#article-island-ad,
#article-sidebar-ad,
#articleAd,
#articleAdReplacement,
#articleBoard-ad,
#articleBottom-ads,
#articleLeftAdColumn,
#articleSideAd,
#articleTop-ads,
#article_ad,
#article_ad_1,
#article_ad_3,
#article_ad_bottom,
#article_ad_container,
#article_ad_top,
#article_ad_w,
#article_adholder,
#article_ads,
#article_advert,
#article_banner_ad,
#article_body_ad1,
#article_box_ad,
#articlead1,
#articlead2,
#articlead300x250r,
#articleadblock,
#articlefootad,
#articletop_ad,
#aside-ad-container,
#asideAd,
#aside_ad,
#asideads,
#asinglead,
#ax-billboard,
#ax-billboard-bottom,
#ax-billboard-sub,
#ax-billboard-top,
#backad,
#background-ad-cover,
#background-adv,
#background_ad_left,
#background_ad_right,
#background_ads,
#backgroundadvert,
#banADbanner,
#banner-300x250,
#banner-468x60,
#banner-728,
#banner-728x90,
#banner-ad,
#banner-ad-container,
#banner-ad-large,
#banner-ads,
#banner-advert,
#banner-lg-ad,
#banner-native-ad,
#banner-skyscraper,
#banner300x250,
#banner468,
#banner468x60,
#banner728,
#banner728x90,
#bannerAd,
#bannerAdFrame,
#bannerAdTop,
#bannerAdWrap,
#bannerAdWrapper,
#bannerAds,
#bannerAdsense,
#bannerAdvert,
#bannerGoogle,
#banner_ad_bottom,
#banner_ad_footer,
#banner_ad_module,
#banner_ad_placeholder,
#banner_ad_top,
#banner_ads,
#banner_adsense,
#banner_adv,
#banner_advertisement,
#banner_adverts,
#banner_content_ad,
#banner_pos1_ddb_0,
#banner_pos2_ddb_0,
#banner_pos3_ddb_0,
#banner_pos4_ddb_0,
#banner_sedo,
#banner_slot,
#banner_spacer,
#banner_topad,
#banner_videoad,
#banner_wrapper_top,
#bannerad-bottom,
#bannerad-top,
#bannerad2,
#banneradrow,
#bannerads,
#banneradspace,
#banneradvert3,
#banneradvertise,
#bannerplayer-wrap,
#baseboard-ad,
#baseboard-ad-wrapper,
#bbContentAds,
#bb_ad_container,
#bb_top_ad,
#bbadwrap,
#before-footer-ad,
#below-listings-ad,
#below-menu-ad-header,
#below-post-ad,
#below-title-ad,
#belowAd,
#belowContactBoxAd,
#belowNodeAds,
#below_content_ad_container,
#belowad,
#belowheaderad,
#bg-custom-ad,
#bgad,
#big-box-ad,
#bigAd,
#bigAd1,
#bigAd2,
#bigAdDiv,
#bigBoxAd,
#bigBoxAdCont,
#big_ad,
#big_ad_label,
#big_ads,
#bigad,
#bigadbox,
#bigads,
#bigadspace,
#bigadspot,
#bigboard_ad,
#bigsidead,
#billboard-ad,
#billboard-atf,
#billboard_ad,
#bingadcontainer2,
#blkAds1,
#blkAds2,
#blkAds3,
#blkAds4,
#blkAds5,
#block-ad-articles,
#block-adsense-0,
#block-adsense-2,
#block-adsense-banner-article-bottom,
#block-adsense-banner-channel-bottom,
#block-adsenseleaderboard,
#block-advertisement,
#block-advertorial,
#block-articlebelowtextad,
#block-articlefrontpagead,
#block-articletopadvert,
#block-boxes-taboola,
#block-dfp-top,
#block-frontpageabovepartnersad,
#block-frontpagead,
#block-frontpagesideadvert1,
#block-google-ads,
#block-googleads3,
#block-googleads3-2,
#block-openads-0,
#block-openads-1,
#block-openads-13,
#block-openads-14,
#block-openads-2,
#block-openads-3,
#block-openads-4,
#block-openads-5,
#block-sponsors,
#block-taboolablock,
#blockAd,
#blockAds,
#block_ad,
#block_ad2,
#block_ad_container,
#block_advert,
#block_advert1,
#block_advert2,
#block_advertisement,
#blog-ad,
#blog-advert,
#blogad,
#blogad-wrapper,
#blogads,
#bm-HeaderAd,
#bn_ad,
#bnr-300x250,
#bnr-468x60,
#bnr-728x90,
#bnrAd,
#body-ads,
#bodyAd1,
#bodyAd2,
#bodyAd3,
#bodyAd4,
#body_ad,
#body_centered_ad,
#bottom-ad,
#bottom-ad-1,
#bottom-ad-area,
#bottom-ad-banner,
#bottom-ad-container,
#bottom-ad-leaderboard,
#bottom-ad-slot,
#bottom-ad-tray,
#bottom-ad-wrapper,
#bottom-add,
#bottom-adhesion,
#bottom-adhesion-container,
#bottom-ads,
#bottom-ads-bar,
#bottom-ads-container,
#bottom-adspot,
#bottom-advertising,
#bottom-boxad,
#bottom-not-ads,
#bottom-side-ad,
#bottom-sponsor-add,
#bottomAd,
#bottomAd300,
#bottomAdBlcok,
#bottomAdContainer,
#bottomAdSection,
#bottomAdSense,
#bottomAdSenseDiv,
#bottomAdWrapper,
#bottomAds,
#bottomAdvBox,
#bottomBannerAd,
#bottomContentAd,
#bottomDDAd,
#bottomLeftAd,
#bottomMPU,
#bottomRightAd,
#bottom_ad,
#bottom_ad_728,
#bottom_ad_area,
#bottom_ad_box,
#bottom_ad_region,
#bottom_ad_unit,
#bottom_ad_wrapper,
#bottom_adbox,
#bottom_ads,
#bottom_adwrapper,
#bottom_banner_ad,
#bottom_fixed_ad_overlay,
#bottom_leader_ad,
#bottom_player_adv,
#bottom_sponsor_ads,
#bottom_sponsored_links,
#bottom_text_ad,
#bottomad,
#bottomad300,
#bottomad_table,
#bottomadbanner,
#bottomadbar,
#bottomadholder,
#bottomads,
#bottomadsdiv,
#bottomadsense,
#bottomadvert,
#bottomadwrapper,
#bottomcontentads,
#bottomleaderboardad,
#bottommpuAdvert,
#bottommpuSlot,
#bottomsponad,
#bottomsponsoredresults,
#box-ad,
#box-ad-section,
#box-ad-sidebar,
#box-content-ad,
#box1ad,
#box2ad,
#boxAD,
#boxAd,
#boxAd300,
#boxAdContainer,
#boxAdvert,
#boxLREC,
#box_ad,
#box_ad_container,
#box_ad_middle,
#box_ads,
#box_advertisement,
#box_advertisment,
#box_articlead,
#box_text_ads,
#boxad,
#boxads,
#bpAd,
#br-ad-header,
#breadcrumb_ad,
#breakbarad,
#bsa_add_holder_g,
#bt-ad,
#bt-ad-header,
#btfAdNew,
#btm_ad,
#btm_ads,
#btmad,
#btnAdDP,
#btnAds,
#btnads,
#btopads,
#button-ads,
#button_ad_container,
#button_ads,
#buy-sell-ads,
#buySellAds,
#buysellads,
#carbon-ads-container-bg,
#carbonadcontainer,
#carbonads,
#carbonads-container,
#card-ads-top,
#category-ad,
#category-sponsor,
#cellAd,
#center-ad,
#center-ad-group,
#centerads,
#ch-ad-outer-right,
#ch-ads,
#channel_ad,
#channel_ads,
#circ_ad,
#circ_ad_holder,
#circad_wrapper,
#classifiedsads,
#clickforad,
#clientAds,
#closeAdsDiv,
#closeable-ad,
#cloudAdTag,
#cmg-video-player-placeholder,
#col-right-ad,
#colAd,
#colombiaAdBox,
#columnAd,
#commentAdWrapper,
#commentTopAd,
#comment_ad_zone,
#comments-ad-container,
#comments-ads,
#comments-standalone-mpu,
#compAdvertisement,
#companion-ad,
#companionAd,
#companionAdDiv,
#companion_Ad,
#companionad,
#component-taboola-below-article-feed,
#component-taboola-below-article-feed-2,
#component-taboola-below-homepage-feed,
#connatix,
#connatix-moveable,
#connatix_placeholder_desktop,
#container-ad,
#container_ad,
#content-ad,
#content-ad-side,
#content-ads,
#content-adver,
#content-contentad,
#content-header-ad,
#content-left-ad,
#content-right-ad,
#contentAd,
#contentAdSense,
#contentAdTwo,
#contentAds,
#contentBoxad,
#content_Ad,
#content_ad,
#content_ad_1,
#content_ad_2,
#content_ad_block,
#content_ad_container,
#content_ad_placeholder,
#content_ads,
#content_ads_top,
#content_adv,
#content_bottom_ad,
#content_bottom_ads,
#content_mpu,
#contentad,
#contentad-adsense-homepage-1,
#contentad-commercial-1,
#contentad-content-box-1,
#contentad-footer-tfm-1,
#contentad-lower-medium-rectangle-1,
#contentad-story-middle-1,
#contentad-superbanner-1,
#contentad-top-adsense-1,
#contentad-topbanner-1,
#contentadcontainer,
#contentads,
#contextad,
#contextual-ads,
#contextual-ads-block,
#contextualad,
#cornerad,
#coverads,
#criteoAd,
#crt-adblock-a,
#crt-adblock-b,
#ctl00_ContentPlaceHolder1_ucAdHomeRightFO_divAdvertisement,
#ctl00_ContentPlaceHolder1_ucAdHomeRight_divAdvertisement,
#ctl00_adFooter,
#ctl00_leaderboardAdvertContainer,
#ctl00_skyscraperAdvertContainer,
#ctl00_topAd,
#ctl00_ucFooter_ucFooterBanner_divAdvertisement,
#cubeAd,
#cube_ad,
#cube_ads,
#customAd,
#customAds,
#customad,
#darazAd,
#ddAdZone2,
#ddb_fluid_native_ddb_0,
#desktop-ad-top,
#desktop-sidebar-ad,
#desktop_middle_ad_fixed,
#desktop_top_ad_fixed,
#dfp-ad-bottom-wrapper,
#dfp-ad-container,
#dfp-ad-floating,
#dfp-ad-leaderboard,
#dfp-ad-leaderboard-wrapper,
#dfp-ad-medium_rectangle,
#dfp-ad-mediumrect-wrapper,
#dfp-ad-mpu1,
#dfp-ad-mpu2,
#dfp-ad-right1,
#dfp-ad-right1-wrapper,
#dfp-ad-right2,
#dfp-ad-right2-wrapper,
#dfp-ad-right3,
#dfp-ad-right4-wrapper,
#dfp-ad-slot2,
#dfp-ad-slot3,
#dfp-ad-slot3-wrapper,
#dfp-ad-slot4-wrapper,
#dfp-ad-slot5,
#dfp-ad-slot5-wrapper,
#dfp-ad-slot6,
#dfp-ad-slot6-wrapper,
#dfp-ad-slot7,
#dfp-ad-slot7-wrapper,
#dfp-ad-top-wrapper,
#dfp-ap-2016-interstitial,
#dfp-article-mpu,
#dfp-atf,
#dfp-atf-desktop,
#dfp-banner,
#dfp-banner-popup,
#dfp-billboard1,
#dfp-billboard2,
#dfp-btf,
#dfp-btf-desktop,
#dfp-footer-desktop,
#dfp-header,
#dfp-header-container,
#dfp-ia01,
#dfp-ia02,
#dfp-interstitial,
#dfp-leaderboard,
#dfp-leaderboard-desktop,
#dfp-masthead,
#dfp-middle,
#dfp-middle1,
#dfp-mtf,
#dfp-mtf-desktop,
#dfp-rectangle,
#dfp-rectangle1,
#dfp-ros-res-header_container,
#dfp-tlb,
#dfp-top-banner,
#dfpAd,
#dfp_ad_mpu,
#dfp_ads_4,
#dfp_ads_5,
#dfp_bigbox_2,
#dfp_bigbox_recipe_top,
#dfp_container,
#dfp_leaderboard,
#dfpad-0,
#dfpslot_tow_2-0,
#dfpslot_tow_2-1,
#dfrads-widget-3,
#dfrads-widget-6,
#dfrads-widget-7,
#dianomiNewsBlock,
#dict-adv,
#direct-ad,
#disable-ads-container,
#display-ads,
#displayAd,
#displayAdSet,
#display_ad,
#displayad_carousel,
#displayad_rectangle,
#div-ad-1x1,
#div-ad-bottom,
#div-ad-flex,
#div-ad-inread,
#div-ad-leaderboard,
#div-ad-r,
#div-ad-r1,
#div-ad-top,
#div-ad-top_banner,
#div-adcenter1,
#div-adcenter2,
#div-advert,
#div-contentad_1,
#div-footer-ad,
#div-gpt-LDB1,
#div-gpt-MPU1,
#div-gpt-MPU2,
#div-gpt-MPU3,
#div-gpt-Skin,
#div-gpt-inline-main,
#div-gpt-mini-leaderboard1,
#div-gpt-mrec,
#div-insticator-ad-1,
#div-insticator-ad-2,
#div-insticator-ad-3,
#div-insticator-ad-4,
#div-insticator-ad-5,
#div-insticator-ad-6,
#div-insticator-ad-9,
#div-leader-ad,
#div-social-ads,
#divAd,
#divAdDetail,
#divAdHere,
#divAdHorizontal,
#divAdLeft,
#divAdMain,
#divAdRight,
#divAdWrapper,
#divAds,
#divAdsTop,
#divAdv300x250,
#divAdvertisement,
#divDoubleAd,
#divFoldersAd,
#divFooterAd,
#divFooterAds,
#divSponAds,
#divSponsoredLinks,
#divStoryBigAd1,
#divThreadAdBox,
#divTopAd,
#divTopAds,
#divWrapper_Ad,
#div_ad_TopRight,
#div_ad_float,
#div_ad_holder,
#div_ad_leaderboard,
#div_advt_right,
#div_belowAd,
#div_bottomad,
#div_bottomad_container,
#div_googlead,
#divadfloat,
#dnn_adSky,
#dnn_adTop {
display: none !important;
}


#dnn_ad_banner,
#dnn_ad_island1,
#dnn_ad_skyscraper,
#dnn_sponsoredLinks,
#downloadAd,
#download_ad,
#download_ads,
#dragads,
#ds-mpu,
#dsStoryAd,
#dsk-banner-ad-a,
#dsk-banner-ad-b,
#dsk-banner-ad-c,
#dsk-banner-ad-d,
#dsk-box-ad-c,
#dsk-box-ad-d,
#dsk-box-ad-f,
#dsk-box-ad-g,
#dv-gpt-ad-bigbox-wrap,
#dynamicAdDiv,
#em_ad_superbanner,
#embedAD,
#embedADS,
#event_ads,
#events-adv-side1,
#events-adv-side2,
#events-adv-side3,
#events-adv-side4,
#events-adv-side5,
#events-adv-side6,
#exoAd,
#externalAd,
#ezmob_footer,
#ezmobfooter,
#featureAd,
#featureAdSpace,
#featureAds,
#feature_ad,
#featuread,
#featured-ads,
#featuredAds,
#first-ads,
#first_ad,
#firstad,
#fixed-ad,
#fixedAd,
#fixedban,
#floatAd,
#floatads,
#floating-ad-wrapper,
#floating-ads,
#floating-advert,
#floatingAd,
#floatingAdContainer,
#floatingAds,
#floating_ad,
#floating_ad_container,
#floating_ads_bottom_textcss_container,
#floorAdWrapper,
#foot-ad-wrap,
#foot-ad2-wrap,
#footAd,
#footAdArea,
#footAds,
#footad,
#footer-ad,
#footer-ad-728,
#footer-ad-block,
#footer-ad-box,
#footer-ad-col,
#footer-ad-google,
#footer-ad-large,
#footer-ad-slot,
#footer-ad-unit,
#footer-ad-wrapper,
#footer-ads,
#footer-adspace,
#footer-adv,
#footer-advert,
#footer-advert-area,
#footer-advertisement,
#footer-adverts,
#footer-adwrapper,
#footer-affl,
#footer-banner-ad,
#footer-leaderboard-ad,
#footer-sponsored,
#footer-sponsors,
#footerAd,
#footerAdBottom,
#footerAdBox,
#footerAdDiv,
#footerAdWrap,
#footerAdd,
#footerAds,
#footerAdsPlacement,
#footerAdvert,
#footerAdvertisement,
#footerAdverts,
#footerGoogleAd,
#footer_AdArea,
#footer_ad,
#footer_ad_block,
#footer_ad_container,
#footer_ad_frame,
#footer_ad_holder,
#footer_ad_modules,
#footer_adcode,
#footer_add,
#footer_addvertise,
#footer_ads,
#footer_ads_holder,
#footer_adspace,
#footer_adv,
#footer_advertising,
#footer_leaderboard_ad,
#footer_text_ad,
#footerad,
#footerad728,
#footerads,
#footeradsbox,
#footeradvert,
#forum-top-ad-bar,
#frameAd,
#frmSponsAds,
#front-ad-cont,
#front-page-ad,
#front-page-advert,
#frontPageAd,
#front_advert,
#front_mpu,
#ft-ad,
#ft-ads,
#full_banner_ad,
#fwAdBox,
#fwdevpDiv0,
#fwdevpDiv1,
#fwdevpDiv2,
#g-outbrain,
#gAds,
#gStickyAd,
#g_ad,
#g_adsense,
#gad300x250,
#gad728x90,
#gads300x250,
#gadsOverlayUnit,
#gads_middle,
#gallery-ad,
#gallery-ad-container,
#gallery-advert,
#gallery-below-line-advert,
#gallery-sidebar-advert,
#gallery_ad,
#gallery_ads,
#gallery_header_ad,
#galleryad1,
#gam-ad-ban1,
#game-ad,
#gamead,
#gameads,
#gasense,
#geoAd,
#gg_ad,
#ggl-ad,
#glamads,
#global-banner-ad,
#globalLeftNavAd,
#globalTopNavAd,
#global_header_ad,
#global_header_ad_area,
#gnt_atomsnc,
#goad1,
#goads,
#gooadtop,
#google-ad,
#google-ads,
#google-ads-bottom,
#google-ads-bottom-container,
#google-ads-container,
#google-ads-detailsRight,
#google-ads-directoryViewRight,
#google-ads-header,
#google-adsense,
#google-adwords,
#google-afc,
#google-dfp-bottom,
#google-dfp-top,
#google-post-ad,
#google-post-adbottom,
#google-top-ads,
#googleAd,
#googleAdArea,
#googleAdBottom,
#googleAdBox,
#googleAdTop,
#googleAds,
#googleAdsense,
#googleAdsenseAdverts,
#googleSearchAds,
#google_ad_1,
#google_ad_2,
#google_ad_3,
#google_ad_container,
#google_ad_slot,
#google_ads,
#google_ads_1,
#google_ads_box,
#google_ads_frame,
#google_ads_frame1_anchor,
#google_ads_frame2_anchor,
#google_ads_frame3_anchor,
#google_ads_frame4_anchor,
#google_ads_frame5_anchor,
#google_ads_frame6_anchor,
#google_adsense,
#google_adsense_ad,
#googlead,
#googlead2,
#googleadleft,
#googleads,
#googleads1,
#googleadsense,
#googleadstop,
#googlebanner,
#googlesponsor,
#googletextads,
#gpt-ad-1,
#gpt-ad-banner,
#gpt-ad-halfpage,
#gpt-ad-outofpage-wp,
#gpt-ad-rectangle1,
#gpt-ad-rectangle2,
#gpt-ad-side-bottom,
#gpt-ad-skyscraper,
#gpt-dynamic_native_article_4,
#gpt-high_impact,
#gpt-instory-ad,
#gpt-leaderboard-ad,
#gpt-mpu,
#gpt-poster,
#gpt-sticky,
#grdAds,
#gridAdSidebar,
#grid_ad,
#half-page-ad,
#halfPageAd,
#half_page_ad_300x600,
#halfpagead,
#head-ad,
#head-ad-text-wrap,
#head-ad-timer,
#head-ads,
#head-advertisement,
#headAd,
#headAds,
#headAdv,
#head_ad,
#head_ads,
#head_advert,
#headad,
#headadvert,
#header-ad,
#header-ad-background,
#header-ad-block,
#header-ad-bottom,
#header-ad-container,
#header-ad-holder,
#header-ad-label,
#header-ad-left,
#header-ad-placeholder,
#header-ad-right,
#header-ad-slot,
#header-ad-wrap,
#header-ad-wrapper,
#header-ad2,
#header-ads,
#header-ads-container,
#header-ads-holder,
#header-ads-wrapper,
#header-adsense,
#header-adserve,
#header-adspace,
#header-adv,
#header-advert,
#header-advert-panel,
#header-advertisement,
#header-advertising,
#header-adverts,
#header-advrt,
#header-banner-728-90,
#header-banner-ad,
#header-banner-ad-wrapper,
#header-block-ads,
#header-box-ads,
#headerAd,
#headerAdBackground,
#headerAdContainer,
#headerAdSpace,
#headerAdUnit,
#headerAdWrap,
#headerAds,
#headerAdsWrapper,
#headerAdv,
#headerAdvert,
#headerTopAd,
#header_ad,
#header_ad_728,
#header_ad_728_90,
#header_ad_banner,
#header_ad_block,
#header_ad_container,
#header_ad_leaderboard,
#header_ad_units,
#header_ad_widget,
#header_ad_wrap,
#header_adbox,
#header_adcode,
#header_ads,
#header_ads2,
#header_adsense,
#header_adv,
#header_advert,
#header_advertisement,
#header_advertisement_top,
#header_advertising,
#header_adverts,
#header_bottom_ad,
#header_publicidad,
#header_right_ad,
#header_sponsors,
#header_top_ad,
#headerad,
#headerad_large,
#headeradbox,
#headeradcontainer,
#headerads,
#headeradsbox,
#headeradsense,
#headeradspace,
#headeradvertholder,
#headeradwrap,
#headergooglead,
#headersponsors,
#headingAd,
#headline_ad,
#hearst-autos-ad-wrapper,
#hgiks-middle,
#hgiks-top,
#home-ad,
#home-ad-block,
#home-ad-slot,
#home-advert-module,
#home-advertise,
#home-banner-ad,
#home-left-ad,
#home-rectangle-ad,
#home-side-ad,
#home-top-ads,
#homeAd,
#homeAdLeft,
#homeAds,
#homeSideAd,
#home_ad,
#home_ads_vert,
#home_advertising_block,
#home_bottom_ad,
#home_contentad,
#home_mpu,
#home_sidebar_ad,
#home_top_right_ad,
#homead,
#homeheaderad,
#homepage-ad,
#homepage-adbar,
#homepage-footer-ad,
#homepage-header-ad,
#homepage-sidebar-ad,
#homepage-sidebar-ads,
#homepage-sponsored,
#homepageAd,
#homepageAdsTop,
#homepageFooterAd,
#homepageGoogleAds,
#homepage_ad,
#homepage_ad_listing,
#homepage_rectangle_ad,
#homepage_right_ad,
#homepage_right_ad_container,
#homepage_top_ad,
#homepage_top_ads,
#homepageadvert,
#hometopads,
#horAd,
#hor_ad,
#horadslot,
#horizad,
#horizads728,
#horizontal-ad,
#horizontal-adspace,
#horizontal-banner-ad,
#horizontalAd,
#horizontalAdvertisement,
#horizontal_ad,
#horizontal_ad2,
#horizontal_ad_top,
#horizontalad,
#horizontalads,
#hottopics-advert,
#hours_ad,
#houseAd,
#hovered_sponsored,
#hp-desk-after-header-ad,
#hp-header-ad,
#hp-right-ad,
#hp-store-ad,
#hpAdVideo,
#humix-vid-ezAutoMatch,
#idDivAd,
#id_SearchAds,
#iframe-ad,
#iframeAd_2,
#iframe_ad_2,
#imPopup,
#im_popupDiv,
#ima_ads-2,
#ima_ads-3,
#ima_ads-4,
#imgAddDirectLink,
#imgad1,
#imu_ad_module,
#in-article-ad,
#in-article-mpu,
#in-content-ad,
#inArticleAdv,
#inarticlead,
#inc-ads-bigbox,
#incontent-ad-2,
#incontent-ad-3,
#incontentAd1,
#incontentAd2,
#incontentAd3,
#index-ad,
#index-bottom-advert,
#indexSquareAd,
#index_ad,
#indexad,
#indexad300x250l,
#indexsmallads,
#indiv_adsense,
#infoBottomAd,
#infoboxadwrapper,
#inhousead,
#initializeAd,
#inline-ad,
#inline-ad-label,
#inline-advert,
#inline-story-ad,
#inline-story-ad2,
#inlineAd,
#inlineAdCont,
#inlineAdtop,
#inlineAdvertisement,
#inlineBottomAd,
#inline_ad,
#inline_ad_section,
#inlinead,
#inlineads,
#inner-ad,
#inner-ad-container,
#inner-advert-row,
#inner-top-ads,
#innerad,
#innerpage-ad,
#inside-page-ad,
#insideCubeAd,
#instant_ad,
#insticator-container,
#instoryad,
#int-ad,
#int_ad,
#interads,
#intermediate-ad,
#internalAdvert,
#internalads,
#interstitial-shade,
#interstitialAd,
#interstitialAdContainer,
#interstitialAdUnit,
#interstitial_ad,
#interstitial_ad_container,
#interstitial_ads,
#intext_ad,
#introAds,
#intro_ad_1,
#invid_ad,
#ipadv,
#iq-AdSkin,
#iqadcontainer,
#iqadoverlay,
#iqadtile1,
#iqadtile11,
#iqadtile14,
#iqadtile15,
#iqadtile16,
#iqadtile2,
#iqadtile3,
#iqadtile4,
#iqadtile41,
#iqadtile6,
#iqadtile8,
#iqadtile9,
#iqadtile99,
#islandAd,
#islandAdPan,
#islandAdPane,
#islandAdPane2,
#island_ad_top,
#islandad,
#jobs-ad,
#js-Taboola-Container-0,
#js-ad-billboard,
#js-ad-leaderboard,
#js-image-ad-mpu,
#js-outbrain-ads-module,
#js-outbrain-module,
#js-outbrain-relateds,
#js-page-ad-top,
#js-wide-ad,
#js_commerceInsetModule,
#jsid-ad-container-post_above_comment,
#jsid-ad-container-post_below_comment,
#jwplayer-container-div,
#jwplayer_contextual_player_div,
#kargo-player,
#kt_player>a[target="_blank"],
#kt_player>div[style$="display: block;"][style*="inset: 0px;"],
#large-ads,
#large-bottom-leaderboard-ad,
#large-leaderboard-ad,
#large-middle-leaderboard-ad,
#large-rectange-ad,
#large-rectange-ad-2,
#large-skyscraper-ad,
#largeAd,
#largeAds,
#large_rec_ad1,
#largead,
#layer_ad,
#layer_ad_content,
#layerad,
#layeradsense,
#layout-header-ad-wrapper,
#layout_topad,
#lb-ad,
#lb-sponsor-left,
#lb-sponsor-right,
#lbAdBar,
#lbAdBarBtm,
#lblAds,
#lead-ads,
#lead_ad,
#leadad_1,
#leadad_2,
#leader-ad,
#leader-board-ad,
#leader-companion>a[href],
#leaderAd,
#leaderAdContainer,
#leaderAdContainerOuter,
#leaderBoardAd,
#leader_ad,
#leader_board_ad,
#leaderad,
#leaderad_section,
#leaderadvert,
#leaderboard-ad,
#leaderboard-advert,
#leaderboard-advertisement,
#leaderboard-atf,
#leaderboard-bottom-ad,
#leaderboard.ad,
#leaderboardAd,
#leaderboardAdTop,
#leaderboardAds,
#leaderboardAdvert,
#leaderboard_728x90,
#leaderboard_Ad,
#leaderboard_ad,
#leaderboard_ads,
#leaderboard_bottom_ad,
#leaderboard_top_ad,
#leaderboardad,
#leatherboardad,
#left-ad,
#left-ad-1,
#left-ad-2,
#left-ad-col,
#left-ad-iframe,
#left-ad-skin,
#left-bottom-ad,
#left-col-ads-1,
#left-content-ad,
#leftAD,
#leftAdAboveSideBar,
#leftAdCol,
#leftAdContainer,
#leftAdMessage,
#leftAdSpace,
#leftAd_fmt,
#leftAd_rdr,
#leftAds,
#leftAdsSmall,
#leftAdvert,
#leftBanner-ad,
#leftColumnAdContainer,
#leftGoogleAds,
#leftTopAdWrapper,
#left_ad,
#left_ads,
#left_adsense,
#left_adspace,
#left_adv,
#left_advertisement,
#left_bg_ad,
#left_block_ads,
#left_float_ad,
#left_global_adspace,
#left_side_ads,
#left_sidebar_ads,
#left_top_ad,
#leftad,
#leftadg,
#leftads,
#leftcolAd,
#leftcolumnad,
#leftforumad,
#leftrail_dynamic_ad_wrapper,
#lg-banner-ad,
#ligatus,
#ligatus_adv,
#ligatusdiv,
#lightboxAd,
#linkAdSingle,
#linkAds,
#link_ads,
#linkads,
#listadholder,
#liste_top_ads_wrapper,
#listing-ad,
#live-ad,
#localAds,
#localpp,
#locked-footer-ad-wrapper,
#logoAd,
#logoAd2,
#logo_ad,
#long-ad,
#long-ad-space,
#long-bottom-ad-wrapper,
#longAdSpace,
#longAdWrap,
#long_advert_header,
#long_advertisement,
#lower-ad-banner,
#lower-ads,
#lower-advertising,
#lower-home-ads,
#lowerAdvertisement,
#lowerAdvertisementImg,
#lower_ad,
#lower_content_ad_box,
#lowerads,
#lowerthirdad,
#lrec_ad,
#lrecad,
#m-banner-bannerAd,
#main-ad,
#main-advert,
#mainAd,
#mainAd1,
#mainAdUnit,
#mainAdvert,
#mainPageAds,
#mainPlaceHolder_coreContentPlaceHolder_rightColumnAdvert_divControl,
#main_AD,
#main_ad,
#main_ads,
#main_content_ad,
#main_rec_ad,
#main_top_ad,
#mainui-ads,
#mapAdsSwiper,
#mapAdvert,
#marketplaceAds,
#marquee-ad,
#marquee_ad,
#mastAd,
#mastAdvert,
#mastad,
#masterad,
#masthead_ad,
#masthead_ads_container,
#masthead_topad,
#med-rect-ad,
#med-rectangle-ad,
#medRecAd,
#medReqAd,
#media-ad,
#medium-ad,
#mediumAd1,
#mediumAdContainer,
#mediumAdvertisement,
#mediumRectangleAd,
#medrec_bottom_ad,
#medrec_middle_ad,
#medrec_top_ad,
#medrectad,
#medrectangle_banner,
#menuad,
#menubarad,
#mgb-container>#mgb,
#mgid-container,
#mgid_iframe,
#mid-ad-slot-1,
#mid-ad-slot-3,
#mid-ad-slot-5,
#mid-ads,
#mid-table-ad,
#midAD,
#midRightAds,
#midRightTextAds,
#mid_ad,
#mid_ad_div,
#mid_ad_title,
#mid_left_ads,
#mid_mpu,
#mid_roll_ad_holder,
#midadspace,
#midadvert,
#midbarad,
#midbnrad,
#midcolumn_ad,
#middle-ad,
#middle-ad-destin,
#middleAd,
#middle_ad,
#middle_ads,
#middle_mpu,
#middlead,
#middleads,
#middleads2,
#midpost_ad,
#midrect_ad,
#midstrip_ad,
#mini-ad,
#mm-player-placeholder-large-screen,
#mobile-adhesion,
#mobile-ads-ad,
#mobile-footer-ad-wrapper,
#mobile-swipe-banner,
#mobileAdContainer,
#mobile_ads_100_pc,
#mobile_ads_block,
#mod_ad,
#mod_ad_top,
#modal-ad,
#module-ads-01,
#module-ads-02,
#moduleTaboolaRightRail,
#module_ad,
#module_box_ad,
#monsterAd,
#mplayer-embed,
#mpu-ad,
#mpu-advert,
#mpu-cont,
#mpu-content,
#mpu-sidebar,
#mpu1_parent,
#mpu2,
#mpu2_container,
#mpu2_parent,
#mpuAd,
#mpuAdvert,
#mpuContainer,
#mpuDiv,
#mpuInContent,
#mpuSecondary,
#mpuSlot,
#mpuWrapper,
#mpuWrapperAd,
#mpuWrapperAd2,
#mpu_ad,
#mpu_ad2,
#mpu_adv,
#mpu_banner,
#mpu_box,
#mpu_container,
#mpu_div,
#mpu_holder,
#mpu_text_ad,
#mpu_top,
#mpuad,
#mpubox,
#mpuholder,
#mvp-foot-ad-wrap,
#mvp-post-bot-ad,
#my-ads,
#narrow-ad,
#narrow_ad_unit,
#native-ads-placeholder,
#native_ad2,
#native_ads,
#nav-ad-container,
#navAdBanner,
#nav_ad,
#nav_ad_728_mid,
#navads-container,
#navbar_ads,
#navigation-ad,
#navlinkad,
#newAd,
#ng-ad,
#ng-ad-lbl,
#ni-ad-row,
#nk_ad_top,
#notify_ad,
#ntvads,
#openx-text-ad,
#openx-widget,
#original_taboola,
#outbrain,
#outbrain-id,
#outbrain-section,
#outbrain-wrapper,
#outbrain1,
#outbrainAdWrapper,
#outbrainWidget,
#outbrain_widget_0,
#ovadsense,
#overlay-ad-bg,
#overlay_ad,
#overlayad,
#overlayadd,
#p-Ad,
#p-advert,
#p-googlead,
#p-googleadsense,
#p2HeaderAd,
#p2squaread,
#page-ad-top,
#page-advertising,
#page-header-ad,
#page-top-ad,
#pageAdDiv,
#pageAdds,
#pageAds,
#pageAdsDiv,
#pageAdvert,
#pageBannerAd,
#pageLeftAd,
#pageMiddleAdWrapper,
#pageRightAd,
#page__outside-advertsing,
#page_ad,
#page_ad_top,
#page_top_ad,
#pageads_top,
#pagebottomAd,
#pagination-advert,
#panel-ad,
#panelAd,
#panel_ad1,
#panoAdBlock,
#partner-ad,
#partnerAd,
#partnerMedRec,
#partner_ads,
#pause-ad,
#pause-ads,
#pauseAd,
#pc-div-gpt-ad_728-3,
#pencil-ad,
#pencil-ad-container,
#perm_ad,
#permads,
#persistentAd,
#personalization_ads,
#pgAdWrapper,
#ph_ad,
#player-ads,
#player-advert,
#player-advertising,
#player-below-advert,
#player-midrollAd,
#playerAd,
#playerAdsRight,
#player_ad,
#player_ads,
#player_middle_ad,
#player_top_ad,
#playerad,
#playerads,
#pop.div_pop,
#pop_ad,
#popadwrap,
#popback-ad,
#popoverAd,
#popupAd,
#popupBottomAd,
#popup_ad_wrapper,
#popupadunit,
#possible_taboola,
#post-ad,
#post-ads,
#post-bottom-ads,
#post-content-ad,
#post-page-ad,
#post-promo-ad,
#postAd,
#postNavigationAd,
#post_ad,
#post_addsense,
#post_adsense,
#post_adspace,
#post_advert,
#postads0,
#ppcAdverts,
#ppvideoadvertisement,
#pr_ad,
#pr_advertising,
#pre-adv,
#pre-footer-ad,
#preAds_ad_mrec_intext,
#preAds_ad_mrec_intext2,
#preminumAD,
#premiumAdTop,
#premium_ad,
#premium_ddb_0,
#premiumad,
#premiumads,
#prerollAd,
#preroll_ads,
#primis-container,
#primis-holder,
#primis_intext,
#primis_player,
#print_ads,
#printads,
#privateads,
#promo-ad,
#promoAds,
#promoFloatAd,
#promo_ads,
#pub468x60,
#pub728x90,
#pubexchange_below_content,
#publicidad,
#publicidadeLREC,
#pushAd,
#pushDownAd,
#pushdownAd,
#pushdownAdWrapper,
#pushdown_ad,
#pusher-ad,
#pvadscontainer,
#quads-ad1_widget,
#quads-ad2_widget,
#quads-admin-ads-js,
#r89-desktop-top-ad,
#radio-ad-container,
#rail-ad-wrap,
#rail-bottom-ad,
#railAd,
#rail_ad,
#rail_ad1,
#rail_ad2,
#rec_spot_ad_1,
#recommendAdBox,
#rect-ad,
#rectAd,
#rect_ad,
#rectad,
#rectangle-ad,
#rectangleAd,
#rectangleAdTeaser1,
#rectangle_ad,
#redirect-ad,
#redirect-ad-modal,
#reference-ad,
#region-node-advert,
#reklam_buton,
#reklam_center,
#reklama,
#reklama_big,
#reklama_left_body,
#reklama_left_up,
#reklama_right_up,
#related-ads,
#related-news-1-bottom-ad,
#related-news-1-top-ad,
#related_ad,
#related_ads,
#related_ads_box,
#removeAdsSidebar,
#removeadlink,
#responsive-ad,
#responsive-ad-sidebar-container,
#responsive_ad,
#responsivead,
#result-list-aside-topadsense,
#resultSponLinks,
#resultsAdsBottom,
#resultsAdsSB,
#resultsAdsTop,
#rh-ad,
#rh-ad-container,
#rh_tower_ad,
#rhc_ads,
#rhs_ads,
#rhs_adverts,
#rhsads,
#rhsadvert,
#richad,
#right-ad,
#right-ad-block,
#right-ad-col,
#right-ad-iframe,
#right-ad-skin,
#right-ad1,
#right-ads,
#right-ads-rail,
#right-advert,
#right-bar-ad,
#right-box-ad,
#right-content-ad,
#right-featured-ad,
#right-rail-ad-slot-content-top,
#right-widget-b-ads_widget-9,
#right-widget-c-ads_widget-7,
#right-widget-d-ads_widget-36,
#right-widget-top-ads_widget-23,
#right1-ad,
#right1ad,
#rightAD,
#rightAd,
#rightAd1,
#rightAdBar,
#rightAdBlock,
#rightAdColumn,
#rightAdContainer,
#rightAdHolder,
#rightAdUnit,
#rightAd_rdr,
#rightAds,
#rightAdsDiv,
#rightBlockAd,
#rightBottomAd,
#rightColAd,
#rightColumnAds,
#rightRailAds,
#rightSideAd,
#rightSideAdvert,
#right_Ads2,
#right_ad,
#right_ad_1,
#right_ad_2,
#right_ad_box,
#right_ad_container,
#right_ad_top,
#right_ad_wrapper,
#right_ads,
#right_ads_box,
#right_adsense,
#right_advert,
#right_advertisement,
#right_advertising,
#right_adverts,
#right_bg_ad,
#right_block_ads,
#right_bottom_ad,
#right_column_ad,
#right_column_ad_container,
#right_column_ads,
#right_column_adverts,
#right_player_ad,
#right_side_ad,
#right_sidebar_ads,
#right_top_ad,
#right_top_gad,
#rightad,
#rightad1,
#rightad2,
#rightadBorder,
#rightadBorder1,
#rightadBorder2,
#rightadContainer,
#rightadcell,
#rightadg,
#rightadhome,
#rightads,
#rightads300x250,
#rightadsarea,
#rightbar-ad,
#rightbar_ad,
#rightcol_sponsorad,
#rightgoogleads,
#rightrail-ad,
#rightrail_bottom_ddb_0,
#rightrail_pos1_ddb_0,
#rightrail_pos2_ddb_0,
#rightrail_pos3_ddb_0,
#rightrail_top_ddb_0,
#rightside-ads,
#rightside_ad,
#rightskyad,
#rm-adslot-bigsizebanner_1,
#rm-adslot-contentad_1,
#rotating_ad,
#rotatingads,
#row-ad,
#rowAdv,
#rtAdvertisement,
#scroll-ad,
#scroll_ad,
#search-ad,
#search-ads1,
#search-google-ads,
#search-sponsor,
#search-sponsored-links,
#searchAd,
#searchAds,
#search_ad,
#search_ads,
#second_ad_div,
#secondad,
#section-ad,
#section-ad-bottom,
#section_ad,
#section_advertisements,
#self-ad,
#sellwild-loader,
#sev1mposterad,
#show-ad,
#show-sticky-ad,
#showAd,
#show_ads,
#showads,
#showcaseAd,
#side-ad,
#side-ad-container,
#side-ads,
#side-ads-box,
#side-banner-ad,
#side-boxad,
#sideABlock,
#sideAD,
#sideAd,
#sideAd1,
#sideAd2,
#sideAd3,
#sideAd4,
#sideAdArea,
#sideAdLarge,
#sideAdSmall,
#sideAdSub,
#sideAds,
#sideBannerAd,
#sideBar-ads,
#sideBarAd,
#sideSponsors,
#side_ad,
#side_ad_module,
#side_ad_wrapper,
#side_ads,
#side_adverts,
#side_longads,
#side_skyscraper_ad,
#side_sponsors,
#sidead,
#sidead1,
#sideads,
#sideads_container,
#sideadscol,
#sideadvert,
#sideadzone,
#sidebar-ad,
#sidebar-ad-1,
#sidebar-ad-2,
#sidebar-ad-block,
#sidebar-ad-boxes,
#sidebar-ad-middle,
#sidebar-ad-wrap,
#sidebar-ad1,
#sidebar-ad2,
#sidebar-ad3,
#sidebar-ads,
#sidebar-ads-content,
#sidebar-ads-narrow,
#sidebar-ads-wide,
#sidebar-ads-wrapper,
#sidebar-adspace,
#sidebar-adv,
#sidebar-advertise-text,
#sidebar-advertisement,
#sidebar-left-ad,
#sidebar-main-ad,
#sidebar-sponsors,
#sidebar-top-ad,
#sidebar-top-ads,
#sidebarAd,
#sidebarAd1,
#sidebarAd2,
#sidebarAdSense,
#sidebarAdSpace,
#sidebarAdUnitWidget,
#sidebarAds,
#sidebarAdvTop,
#sidebarAdvert,
#sidebarSponsors,
#sidebarTextAds,
#sidebarTowerAds,
#sidebar_ad,
#sidebar_ad_1,
#sidebar_ad_2,
#sidebar_ad_3,
#sidebar_ad_big,
#sidebar_ad_container,
#sidebar_ad_top,
#sidebar_ad_widget,
#sidebar_ad_wrapper,
#sidebar_adblock,
#sidebar_ads,
#sidebar_box_add,
#sidebar_topad,
#sidebarad,
#sidebarad0,
#sidebaradpane,
#sidebarads,
#sidebaradsense,
#sidebaradverts,
#sidebard-ads-wrapper,
#sidebargooglead,
#sidebargoogleads,
#sidebarrectad,
#sideline-ad,
#sidepad-ad,
#single-ad,
#single-ad-2,
#single-adblade,
#single-mpu,
#singleAd,
#singleAdsContainer,
#singlead,
#singleads,
#site-ad-container,
#site-ads,
#site-header__ads,
#site-leaderboard-ads,
#site-sponsor-ad,
#site-sponsors,
#siteAdHeader,
#site_bottom_ad_div,
#site_content_ad_div,
#site_top_ad,
#site_wrap_ad,
#sitead,
#skcolAdSky,
#skin-ad,
#skin-ad-left-rail-container,
#skin-ad-right-rail-container,
#skinTopAd,
#skin_adv,
#skinad-left,
#skinad-right,
#skinningads,
#sky-ad,
#sky-ads,
#sky-left,
#sky-right,
#skyAd,
#skyAdContainer,
#skyScraperAd,
#skyScrapperAd,
#skyWrapperAds,
#sky_ad,
#sky_advert,
#skyads,
#skyadwrap,
#skybox-ad,
#skyline_ad,
#skyscrapeAd,
#skyscraper-ad,
#skyscraperAd,
#skyscraperAdContainer,
#skyscraperAdWrap,
#skyscraperAds,
#skyscraperWrapperAd,
#skyscraper_ad,
#skyscraper_advert,
#skyscraperadblock,
#skyscrapper-ad,
#slashboxes>.deals-rail,
#slideAd,
#slide_ad,
#slidead,
#slideboxad,
#slider-ad,
#sliderAdHolder,
#slider_ad,
#sm-banner-ad,
#smallAd,
#small_ad,
#small_ads,
#smallad,
#smallads,
#smallerAd,
#sp-adv-banner-top,
#specialAd,
#special_ads,
#specialadfeatures,
#specials_ads,
#speed_ads,
#speeds_ads,
#splashy-ad-container-top,
#sponBox,
#spon_links,
#sponlink,
#sponlinks,
#sponsAds,
#sponsLinks,
#spons_links,
#sponseredlinks,
#sponsor-box-widget,
#sponsor-flyout,
#sponsor-flyout-wrap,
#sponsor-links,
#sponsor-partners,
#sponsor-sidebar-container,
#sponsorAd,
#sponsorAd1,
#sponsorAd2,
#sponsorAdDiv,
#sponsorBar,
#sponsorBorder,
#sponsorContainer0,
#sponsorFooter,
#sponsorLinkDiv,
#sponsorLinks,
#sponsorResults,
#sponsorSpot,
#sponsorTab,
#sponsorText,
#sponsorTextLink,
#sponsor_300x250,
#sponsor_ad,
#sponsor_ads,
#sponsor_bar,
#sponsor_bottom,
#sponsor_box,
#sponsor_deals,
#sponsor_div,
#sponsor_footer,
#sponsor_header,
#sponsor_link,
#sponsor_no,
#sponsor_posts,
#sponsor_right,
#sponsored-ads,
#sponsored-carousel-nucleus,
#sponsored-footer,
#sponsored-inline,
#sponsored-links,
#sponsored-links-alt,
#sponsored-links-container,
#sponsored-listings,
#sponsored-message,
#sponsored-products,
#sponsored-recommendations,
#sponsored-resources,
#sponsored-search,
#sponsored-text-links,
#sponsored-widget,
#sponsored1,
#sponsoredAd,
#sponsoredAdvertisement,
#sponsoredBottom,
#sponsoredBox1,
#sponsoredBox2,
#sponsoredFeaturedHoz,
#sponsoredHoz,
#sponsoredLinks,
#sponsoredLinksBox,
#sponsoredList,
#sponsoredResults,
#sponsoredResultsWide,
#sponsoredTop,
#sponsored_ads,
#sponsored_container,
#sponsored_content,
#sponsored_head,
#sponsored_label,
#sponsored_link_bottom,
#sponsored_links,
#sponsored_native_ad,
#sponsoredad,
#sponsoredads,
#sponsoredlinks,
#sponsorfeature,
#sponsorlink,
#sponsors-article,
#sponsors-block,
#sponsors-home,
#sponsorsBox,
#sponsorsContainer,
#sponsorship-area-wrapper,
#sponsorship-box,
#sporsored-results,
#spotlight-ads,
#spotlightAds,
#spotlight_ad,
#spotlightad,
#sprint_ad,
#sqAd,
#sq_ads,
#square-ad,
#square-ad-box,
#square-ad-space,
#square-ads,
#square-sponsors,
#squareAd,
#squareAdBottom,
#squareAdSpace,
#squareAdTop,
#squareAdWrap,
#squareAds,
#squareGoogleAd,
#square_ad,
#squaread,
#squareadevertise,
#squareadvert,
#squared_ad,
#staticad,
#stationad,
#sticky-ad,
#sticky-ad-bottom,
#sticky-ad-container,
#sticky-ad-header,
#sticky-add-side-block,
#sticky-ads,
#sticky-ads-top,
#sticky-custom-ads,
#sticky-footer-ad,
#sticky-footer-ads,
#sticky-left-ad,
#sticky-rail-ad,
#stickyAd,
#stickyAdBlock,
#stickyBottomAd,
#stickySidebarAd,
#stickySkyAd,
#sticky_sidebar_ads,
#stickyad,
#stickyads,
#stickyleftad,
#stickyrightad,
#stopAdv,
#stop_ad3,
#story-ad,
#story-bottom-ad,
#storyAd,
#story_ad,
#story_ads,
#story_bottom_ddb_0,
#story_top_ddb_0,
#storyad2,
#stripadv,
#subheaderAd,
#taboola,
#taboola-above-homepage-thumbnails,
#taboola-ad,
#taboola-adverts,
#taboola-below,
#taboola-below-article-1,
#taboola-below-article-thumbnails,
#taboola-below-article-thumbnails,
#taboola-below-article-thumbnails-2,
#taboola-below-article-thumbnails-express,
#taboola-below-article-thumbnails-mg,
#taboola-below-article-thumbnails-v2,
#taboola-below-disco-board,
#taboola-below-forum-thumbnails,
#taboola-below-homepage-thumbnails-2,
#taboola-below-homepage-thumbnails-3,
#taboola-below-main-column,
#taboola-belowarticle,
#taboola-bottom,
#taboola-bottom-main-column,
#taboola-div,
#taboola-homepage-thumbnails,
#taboola-homepage-thumbnails-desktop,
#taboola-horizontal-toolbar,
#taboola-in-feed-thumbnails,
#taboola-mid-article-thumbnails,
#taboola-mid-article-thumbnails-ii,
#taboola-mid-main-column-thumbnails,
#taboola-mobile-article-thumbnails,
#taboola-native-right-rail-thumbnails,
#taboola-placeholder,
#taboola-right-rail,
#taboola-right-rail,
#taboola-right-rail-express,
#taboola-right-rail-text-right,
#taboola-right-rail-thumbnails,
#taboola-right-rail-thumbnails-2nd,
#taboola-text-2-columns-mix,
#taboola-vid-container,
#taboola-widget-wrapper,
#taboola_bottom,
#taboola_responsive_wrapper,
#taboola_side,
#taboola_wrapper,
#takeover-ad,
#takeover_ad,
#takeoverad,
#td-ad-placeholder,
#tdAds,
#td_adunit2,
#td_sponsorAd,
#team_ad,
#teaser1[style^="width:autopx;"],
#teaser2[style^="width:autopx;"],
#teaser3[style="width: 100%;text-align: center;display: scroll;position:fixed;bottom: 0;margin: 0 auto;z-index: 103;"],
#teaser3[style^="width:autopx;"],
#text-ad,
#text-ads,
#text-intext-ads,
#text-link-ads,
#textAd,
#textAd1,
#textAds,
#textAdsTop,
#text_ad,
#text_ads,
#text_advert,
#textad,
#textad3,
#textlink-advertisement,
#textsponsor,
#tfm_admanagerTeaser,
#tile-ad,
#tileAds,
#tmInfiniteAd,
#toaster_ad,
#top-ad,
#top-ad-area,
#top-ad-banner,
#top-ad-container,
#top-ad-content,
#top-ad-desktop,
#top-ad-div,
#top-ad-google,
#top-ad-iframe,
#top-ad-rect,
#top-ad-slot,
#top-ad-slot-0,
#top-ad-slot-1,
#top-ad-unit,
#top-ad-wrapper,
#top-adblock,
#top-adds,
#top-ads,
#top-ads-1,
#top-ads-contain,
#top-ads-container,
#top-adspot,
#top-advert,
#top-advertisement,
#top-advertisements,
#top-advertising-content,
#top-banner-ad,
#top-banner-ad-browser,
#top-buy-sell-ads,
#top-dfp,
#top-head-ad,
#top-leaderboard-ad,
#top-left-ad,
#top-middle-add,
#top-not-ads,
#top-right-ad,
#top-right-ad-slot,
#top-skin-ad,
#top-skin-ad-bg,
#top-sponsor-ad,
#top-story-ad,
#topAD,
#topAd,
#topAd728x90,
#topAdArea,
#topAdBanner,
#topAdBar,
#topAdBox,
#topAdContainer,
#topAdDiv,
#topAdDropdown,
#topAdHolder,
#topAdShow,
#topAdSpace,
#topAdSpace_div,
#topAdWrapper,
#topAdcontainer,
#topAds,
#topAds1,
#topAds2,
#topAdsContainer,
#topAdsDiv,
#topAdsG,
#topAdv,
#topAdvBox,
#topAdvert,
#topBanner-ad,
#topBannerAd,
#topBannerAdContainer,
#topBannerAdv,
#topImgAd,
#topLeaderboardAd,
#topMPU,
#topMpuContainer,
#topSponsorBanner,
#topSponsoredLinks,
#top_AD,
#top_ad,
#top_ad-360,
#top_ad_area,
#top_ad_banner,
#top_ad_block,
#top_ad_box,
#top_ad_container,
#top_ad_td,
#top_ad_unit,
#top_ad_wrapper,
#top_ad_zone,
#top_add,
#top_ads,
#top_ads_box,
#top_ads_container,
#top_ads_region,
#top_ads_wrap,
#top_adsense_cont,
#top_adspace,
#top_adv,
#top_advert,
#top_advert_box,
#top_advertise,
#top_advertising,
#top_banner_ads,
#top_container_ad,
#top_google_ads,
#top_mpu,
#top_mpu_ad,
#top_rectangle_ad,
#top_right_ad,
#top_row_ad,
#top_span_ad,
#top_sponsor_ads,
#top_sponsor_text,
#top_wide_ad,
#topad,
#topad-728x90,
#topad-block,
#topad-wrap,
#topad1,
#topad2,
#topad728,
#topad_holder,
#topad_left,
#topad_right,
#topad_table,
#topadbanner,
#topadbanner2,
#topadbar,
#topadblock,
#topadcell,
#topadcontainer,
#topaddwide,
#topadleft,
#topadone,
#topadplaceholder,
#topadright,
#topads-spacer,
#topads-wrapper,
#topadsblock,
#topadsdiv,
#topadsense,
#topadspace,
#topadvert,
#topadwrap,
#topadz,
#topadzone,
#topbanner_ad,
#topbanner_sponsor,
#topbannerad,
#topbanneradtitle,
#topbar-ad,
#topbarAd,
#topbarad,
#topbarads,
#topcustomad,
#topheader_ads,
#topleaderAd,
#topleaderboardad,
#topnavad,
#toppannonse,
#topright-ad,
#toprightAdvert,
#toprightad,
#toprow-ad,
#topsidebar-ad,
#topsponad,
#topsponsorads,
#topsponsored,
#toptextad,
#tor-footer-ad,
#tower1ad,
#towerAdContainer,
#towerad,
#tpd-post-header-ad,
#tpl_advertising,
#transparentad,
#trc_google_ad,
#txtAdHeader,
#ultimedia_wrapper,
#upper-ads,
#upperMpu,
#upperRightAds,
#upper_adbox,
#upper_advertising,
#upper_small_ad,
#upperad,
#vc-maincontainer-ad,
#vc-maincontainer-midad,
#velsof_wheel_container,
#vert-ads,
#vertAd2,
#vert_ad,
#vert_ad_placeholder,
#vertad1,
#vertical.ad,
#verticalAds,
#vertical_ad,
#vertical_ads,
#verticalads,
#vidazoo-player,
#video-ad,
#video-ad-companion-rectangle,
#video-adv,
#video-adv-wrapper,
#video-advert,
#video-embed-ads,
#video-in-player-ad,
#video-side-adv,
#video-sponsor-links,
#video-under-player-ad,
#videoAd,
#videoAdContainer,
#videoAdvert,
#videoCompanionAd,
#videoOverAd,
#videoOverAd300,
#videoPauseAd,
#video_adv,
#video_advert,
#video_advert_top,
#video_embed_ads,
#video_hor_bot_ads,
#video_overlay_ad,
#videoad,
#videoad-script-cnt,
#videoads,
#viewAd1,
#viewabilityAdContainer,
#visual-ad,
#vuukle-quiz-and-ad,
#vuukle_ads_square2,
#wTopAd,
#wallAd,
#wall_advert,
#wd-sponsored,
#weather-ad,
#weather_sponsor,
#weatherad,
#welcome_ad,
#wg_ads,
#wgtAd,
#whitepaper-ad,
#wide-ad,
#wideAdd,
#wide_ad_unit,
#wide_ad_unit2,
#wide_ad_unit3,
#wide_adv,
#wide_right_ad,
#widget-ads-3,
#widget-ads-4,
#widget-adv-12,
#widget-box-ad-1,
#widget-box-ad-2,
#widget_Adverts,
#widget_ad,
#widget_advertisement,
#widget_thrive_ad_default-2,
#widget_thrive_ad_default-4,
#widgetwidget_adserve,
#widgetwidget_adserve2,
#wl-pencil-ad,
#wow-ads,
#wp-insert-ad-widget-1,
#wp-topAds,
#wp_ad_marker,
#wp_adbn_root,
#wp_ads_gpt_widget-16,
#wp_ads_gpt_widget-17,
#wp_ads_gpt_widget-18,
#wp_ads_gpt_widget-19,
#wp_ads_gpt_widget-21,
#wp_ads_gpt_widget-4,
#wp_ads_gpt_widget-5,
#wpladbox1,
#wpladbox2,
#wrapAd,
#wrapAdRight,
#wrapCommentAd,
#wrapper-AD_G,
#wrapper-AD_L,
#wrapper-AD_L2,
#wrapper-AD_L3,
#wrapper-AD_PUSH,
#wrapper-AD_R,
#wrapper-ad,
#wrapper-ad970,
#wrapperAdsTopLeft,
#wrapperAdsTopRight,
#wrapperRightAds,
#wrapper_ad_Top,
#wrapper_sponsoredlinks,
#wrapper_topad,
#wtopad,
#yahoo-sponsors,
#yahooAdsBottom,
#yahooSponsored,
#yahoo_ads,
#yahoo_text_ad,
#yahooads,
#yandex_ad,
#yatadsky,
#yrail_ads,
#yreSponsoredLinks,
#ysm_ad_iframe,
#zMSplacement1,
#zMSplacement2,
#zMSplacement3,
#zMSplacement4,
#zMSplacement5,
#zMSplacement6,
#zdcFloatingBtn,
#zeus_top-banner,
#zone-adsense,
#zsAdvertisingBanner,
.-advertsSidebar,
.ADBAR,
.ADBox,
.ADFooter,
.ADInfo,
.ADLeader,
.ADMiddle1,
.ADPod,
.ADServer,
.ADStyle,
.ADTop,
.ADVBig,
.ADVFLEX_250,
.ADVParallax,
.ADV_Mobile,
.AD_2,
.AD_area,
.ADbox,
.ADmid,
.ADwidget,
.ATF_wrapper,
.Ad--Align,
.Ad--empty,
.Ad--header,
.Ad--loading,
.Ad--presenter,
.Ad--sidebar,
.Ad-Advert_Container,
.Ad-Container,
.Ad-Header,
.Ad-Inner,
.Ad-adhesive,
.Ad-hor-height,
.Ad-label,
.Ad-leaderboard,
.Ad.Leaderboard,
.Ad300,
.Ad3Tile,
.Ad728x90,
.AdBar,
.AdBody:not(body),
.AdBorder,
.AdBottomPage,
.AdBox,
.AdBox160,
.AdBox7,
.AdBox728,
.AdCenter,
.AdCommercial,
.AdCompactheader,
.AdContainer,
.AdContainer-Sidebar,
.AdHeader,
.AdHere,
.AdHolder,
.AdInline,
.AdInsLink,
.AdLeft1,
.AdLeft2,
.AdMedium,
.AdMessage,
.AdMod,
.AdModule,
.AdOneColumnContainer,
.AdOuterMostContainer,
.AdPanel,
.AdPlaceHolder,
.AdPlaceholder,
.AdPlacementContainer,
.AdProduct,
.AdRight1,
.AdRight2,
.AdSense,
.AdSenseLeft,
.AdSlot,
.AdSpace,
.AdSpeedWP,
.AdTagModule,
.AdTitle,
.AdTop,
.AdUnit,
.Ad_C,
.Ad_D,
.Ad_Label,
.Ad_Right,
.Ad_container,
.Ads--center,
.Ads-768x90,
.Ads-background,
.Ads-leaderboard,
.Ads-slot,
.Ads-sticky,
.AdsBottom,
.AdsBox,
.AdsBoxBottom,
.AdsBoxSection,
.AdsBoxTop,
.AdsLayout__top-container,
.AdsRectangleWrapper,
.AdsSlot,
.Ads__wrapper,
.Ads_header,
.Adsense,
.AdsenseBox,
.Adsterra,
.Adtext,
.Adv468,
.Advert-label,
.Advert300x250,
.AdvertContainer,
.AdvertWrapper,
.AdvertisementAfterHeader,
.AdvertisementAfterPost,
.AdvertisementAsidePost,
.AdvertisementText,
.AdvertisementTextTag,
.AdvertisementTop,
.Advertisment,
.AdvertorialTeaser,
.AdvtSample,
.AdzerkBanner,
.AffiliateAds,
.AppFooter__BannerAd,
.Arpian-ads,
.Article-advert,
.ArticleAd,
.ArticleAdSide,
.ArticleAdWrapper,
.ArticleFooter-outbrain,
.ArticleInlineAd,
.ArticleInnerAD,
.ArticleOutbrainLocal,
.Article__Ad,
.BOX_Ad,
.BOX_LeadAd,
.Banner300x250,
.Banner468X60,
.BeOpWidget,
.BigBoxAd,
.BigBoxAdLabel,
.Billboard-ad,
.Billboard-ad-holder,
.Billboard_2-ad-holder,
.Billboard_3-ad-holder,
.Billboard_4-ad-holder,
.Billboard_5-ad-holder,
.BlockAd,
.BottomAd-container,
.BottomAdContainer,
.BottomAdsPartial,
.BottomAffiliate,
.BoxAd,
.BoxAdWrap,
.BoxRail-ad,
.ButtonAd,
.CitrusBannerWrapper--enollj,
.CommentAd,
.ConnatixAd,
.ContentAd,
.ContentAds,
.ContentBottomAd,
.ContentTextAd,
.ContentTopAd,
.DFPad,
.DisplayAd,
.FirstAd,
.FooterAd,
.FooterAdContainer,
.FooterAds,
.Footer_1-ad-holder,
.GRVAd,
.GRVMpuWrapper,
.GRVMultiVideo,
.GRVPrimisVideo,
.GRVVideo,
.Gallery-Content-BottomAd,
.GeminiAdItem,
.GeminiNativeAd,
.GoogleAdv,
.GoogleDfpAd,
.GoogleDfpAd-Content,
.GoogleDfpAd-Float,
.GoogleDfpAd-container,
.GoogleDfpAd-wrap,
.GoogleDfpAd-wrapper,
.GoogleDfpAdModule,
.GoogleDoubleClick-SponsorText,
.GroupAdSense,
.HeaderAd,
.HeaderAds,
.HeaderBannerAd,
.HeadingAdSpace,
.Hero-Ad,
.HomeAds,
.InArticleAd,
.IndexRightAd,
.InsertedAd,
.LastAd,
.LayoutBottomAds,
.LayoutHomeAds,
.LayoutHomeAdsAd,
.LayoutPromotionAdsNew,
.LazyLoadAd,
.LeaderAd,
.LeaderAdvertisement,
.LeaderBoardAd,
.LearderAd_Border,
.ListicleAdRow,
.MPUHolder,
.MPUad,
.MapLayout_BottomAd,
.MapLayout_BottomMobiAd,
.MarketGid_container,
.MbanAd,
.MiddleAd,
.MiddleAdContainer,
.MiddleAdvert,
.MiddleRightRadvertisement,
.NA_ad,
.NR-Ads,
.NativeAdContainerRegion,
.NavBarAd,
.Normal-add,
.OAS_wrap,
.OUTBRAIN,
.OUTBRAIN[data-widget-id^="FMS_REELD_"],
.OcelotAdModule,
.OcelotAdModule-ad,
.Outbrain,
.PPD_ADS_JS,
.Page-ad,
.PageTopAd,
.PcSideBarAd,
.PencilAd,
.PostAdvertisementBeforePost,
.PostSidebarAd,
.Post__ad,
.PrimisResponsiveStyle,
.PrintAd-Slider,
.ProductAd,
.PushdownAd,
.RC-AD,
.RC-AD-BOX-BOTTOM,
.RC-AD-BOX-MIDDLE,
.RC-AD-BOX-TOP,
.RC-AD-TOP-BANNER,
.RectangleAd,
.Rectangle_1-ad-holder,
.Rectangle_2-ad-holder,
.Rectangle_3-ad-holder,
.RelatedAds,
.ResponsiveAd,
.RightAd,
.RightAd1,
.RightAd2,
.RightAdvertisement,
.RightGoogleAd,
.RightRailAd,
.RightRailAds,
.RightTowerAd,
.STR_AdBlock,
.SecondaryAd,
.SecondaryAdLink,
.Section-ad,
.SectionSponsor,
.SideAd,
.SideAdCol,
.SideAds,
.SideWidget__ad,
.Sidebar-ad,
.Sidebar-ad--300x600,
.SidebarAd,
.SidebarAdvert,
.SidebarRightAdvertisement,
.SimpleAd,
.SkyAdContainer,
.SkyAdContent,
.SkyScraperAd,
.SovrnAd,
.Sponsor-container,
.SponsorHeader,
.SponsorIsland,
.SponsorLink,
.SponsoredAdTitle,
.SponsoredArticleAd,
.SponsoredContent,
.SponsoredContentWidget,
.SponsoredLinks,
.SponsoredLinksModule,
.SponsoredLinksPadding,
.SponsoredLinksPanel,
.SponsoredResults,
.Sponsored_link,
.SponsorshipText,
.SquareAd,
.Squareadspot,
.StandardAdLeft,
.StandardAdRight,
.Sticky-AdContainer,
.StickyAdRail__Inner,
.SummaryPage-HeaderAd,
.TextAd,
.TextAdds,
.Textads,
.ThreeAds,
.TmnAdsense,
.TopAd,
.TopAdBox,
.TopAdContainer,
.TopAdL,
.TopAdR,
.TopAds,
.TopAdsPartial,
.TopBannerAd,
.TopRightRadvertisement,
.Top_Ad,
.TrackedBannerPromo,
.TrackedSidebarPromo,
.TrafficAd,
.U210-adv-column,
.UnderAd,
.VPCarbonAds,
.VerticalAd,
.Video-Ad,
.VideoAd,
.WPBannerizeWidget,
.WP_Widget_Ad_manager,
.WideAdTile,
.WideAdsLeft,
.WidgetAdvertiser,
.WidthAd,
.WikiaTopAds,
.ZERGNET,
.\[\&_\.gdprAdTransparencyCogWheelButton\]\:\!pjra-z-\[5\],
._SummaryPageHeaderAdView,
._SummaryPageSidebarStickyAdView,
.__isboostOverContent,
._ads,
._ads-full,
._ap_adrecover_ad,
._ap_apex_ad,
._articleAdvert,
._bannerAds,
._bottom_ad_wrapper,
._ciw-betterAds,
._fullsquaread,
._has-ads,
._popIn_recommend_article_ad,
._popIn_recommend_article_ad_reserved,
._table_ad_div_wide,
.a-ad,
.a-ad--aside,
.a-ad--leaderboard,
.a-ad--skyscraper,
.a-ad--wide,
.a-d-250,
.a-d-90,
.a-d-container,
.a-d-holder-container,
.a-dserver,
.a-dserver_text,
.a-sponsor,
.a160x600,
.a300x250,
.a468x60,
.a728x90,
.aadsection_b1,
.aadsection_b2,
.aarpe-ad-wrapper,
.ab-ad_placement-article,
.abBoxAd,
.abMessage,
.abPopup,
.ablock300,
.ablock468,
.ablock728,
.above-header-advert,
.aboveCommentAds,
.abovead,
.ac-banner-ad,
.ac-lre-desktop,
.ac-lre-player-ph,
.ac-lre-wrapper,
.ac-widget-placeholder,
.ac_adbox,
.acm-ad-container,
.acm-ad-tag-unit,
.acm_ad_zones,
.ad--300,
.ad--300x250,
.ad--468,
.ad--468-60,
.ad--728x90,
.ad--970-750-336-300,
.ad--970-90,
.ad--article,
.ad--article-top,
.ad--articlemodule,
.ad--b,
.ad--banner,
.ad--banner2,
.ad--banniere_basse,
.ad--banniere_haute,
.ad--billboard,
.ad--bottom,
.ad--bottom-label,
.ad--bottommpu,
.ad--boundries,
.ad--button,
.ad--c,
.ad--center,
.ad--centered,
.ad--container,
.ad--content,
.ad--content-ad,
.ad--dart,
.ad--desktop,
.ad--displayed,
.ad--droite_basse,
.ad--droite_haute,
.ad--droite_middle,
.ad--e,
.ad--fallback,
.ad--footer,
.ad--fullsize,
.ad--google,
.ad--halfpage,
.ad--header,
.ad--homepage-top,
.ad--in-article,
.ad--in-content,
.ad--inArticleBanner,
.ad--inline,
.ad--inner,
.ad--large,
.ad--leaderboard,
.ad--loading,
.ad--medium-rectangle,
.ad--medium_rectangle,
.ad--medium_rectangle_outstream,
.ad--mediumrectangle,
.ad--mid,
.ad--mid-content,
.ad--mobile,
.ad--mpu,
.ad--native,
.ad--nativeFlex,
.ad--no-bg,
.ad--noscroll,
.ad--object,
.ad--outstream,
.ad--overlayer,
.ad--p1,
.ad--p2,
.ad--p3,
.ad--p4,
.ad--p6,
.ad--p7,
.ad--placeholder,
.ad--pubperform,
.ad--pushdown,
.ad--rail,
.ad--rectangle,
.ad--rectangle1,
.ad--rectangle2,
.ad--right,
.ad--rightRail,
.ad--scroll,
.ad--section,
.ad--sidebar,
.ad--sky,
.ad--skyscraper,
.ad--slider,
.ad--slot,
.ad--sponsor-content,
.ad--square-rectangle,
.ad--sticky,
.ad--stripe,
.ad--stroeer,
.ad--subcontainer,
.ad--top,
.ad--top-desktop,
.ad--top-leaderboard,
.ad--top-slot,
.ad--topmobile,
.ad--topmobile2,
.ad--topmobile3,
.ad--wallpaper,
.ad--widget,
.ad--wrapper,
.ad-1,
.ad-120-60,
.ad-120x60,
.ad-120x600,
.ad-120x90,
.ad-125x125,
.ad-13,
.ad-137,
.ad-14,
.ad-160,
.ad-160-160,
.ad-160-600,
.ad-160x600,
.ad-2,
.ad-200,
.ad-200x200,
.ad-250,
.ad-250x300,
.ad-3,
.ad-300,
.ad-300-2,
.ad-300-250-600,
.ad-300-600,
.ad-300-b,
.ad-300-block,
.ad-300-dummy,
.ad-300-flex,
.ad-300-x-250,
.ad-300X250,
.ad-300X250-body,
.ad-300x,
.ad-300x100,
.ad-300x200,
.ad-300x250,
.ad-300x600,
.ad-336,
.ad-336x280,
.ad-336x280B,
.ad-350,
.ad-4,
.ad-468,
.ad-468x120,
.ad-468x60,
.ad-5,
.ad-544x250,
.ad-55,
.ad-560,
.ad-6,
.ad-600,
.ad-600-h,
.ad-635x40,
.ad-7,
.ad-728,
.ad-728-90,
.ad-728-banner,
.ad-728-x-90,
.ad-728x90,
.ad-728x90-1,
.ad-728x90-top,
.ad-728x90-top0,
.ad-728x90-wrapper,
.ad-728x90_forum,
.ad-768,
.ad-8,
.ad-88-60,
.ad-88x31,
.ad-9,
.ad-90,
.ad-90x600,
.ad-970,
.ad-970-250,
.ad-970-90,
.ad-970x250,
.ad-970x90,
.ad-Advert_Placeholder,
.ad-E,
.ad-LREC,
.ad-LREC2,
.ad-Leaderboard,
.ad-MPU,
.ad-MediumRectangle,
.ad-PENCIL,
.ad-S,
.ad-Square,
.ad-SuperBanner,
.ad-TOPPER,
.ad-W,
.ad-a,
.ad-ab,
.ad-abc,
.ad-above-header,
.ad-accordion,
.ad-active,
.ad-adSense,
.ad-adcode,
.ad-adhesion,
.ad-adlink-bottom,
.ad-adlink-side,
.ad-adsense,
.ad-adsense-block-250,
.ad-advertisement-horizontal,
.ad-affiliate,
.ad-after-content,
.ad-after-header,
.ad-align-none,
.ad-aligncenter,
.ad-alignment,
.ad-alsorectangle,
.ad-anchor,
.ad-aps-wide,
.ad-area,
.ad-area--pd,
.ad-area-small,
.ad-article-breaker,
.ad-article-inline,
.ad-article-teaser,
.ad-article-wrapper,
.ad-aside-pc-billboard,
.ad-atf,
.ad-atf-top,
.ad-background,
.ad-background-center,
.ad-background-container,
.ad-ban,
.ad-banner-2,
.ad-banner-250x600,
.ad-banner-300,
.ad-banner-300x250,
.ad-banner-5,
.ad-banner-6,
.ad-banner-728x90,
.ad-banner-bottom-container,
.ad-banner-box,
.ad-banner-btf,
.ad-banner-container,
.ad-banner-content,
.ad-banner-full-wrapper,
.ad-banner-header,
.ad-banner-image,
.ad-banner-inlisting,
.ad-banner-leaderboard {
display: none !important;
}

.ad-banner-placeholder,
.ad-banner-single,
.ad-banner-smaller,
.ad-banner-static,
.ad-banner-top,
.ad-banner-top-wrapper,
.ad-banner-wrapper,
.ad-banners,
.ad-bar,
.ad-bar-header,
.ad-bb,
.ad-before-header,
.ad-below,
.ad-below-images,
.ad-below-player,
.ad-belowarticle,
.ad-bg,
.ad-big,
.ad-big-box,
.ad-bigbanner,
.ad-bigbillboard,
.ad-bigbox,
.ad-bigbox-double-inread,
.ad-bigbox-fixed,
.ad-bigsize,
.ad-billboard,
.ad-bline,
.ad-block,
.ad-block--300,
.ad-block--leader,
.ad-block-300,
.ad-block-banner-container,
.ad-block-big,
.ad-block-bottom,
.ad-block-btf,
.ad-block-container,
.ad-block-header,
.ad-block-holder,
.ad-block-inside,
.ad-block-mod,
.ad-block-section,
.ad-block-square,
.ad-block-sticky-ad,
.ad-block-wide,
.ad-block-wk,
.ad-block-wrapper,
.ad-block-wrapper-dev,
.ad-blogads,
.ad-bnr,
.ad-body,
.ad-boombox,
.ad-border,
.ad-bordered,
.ad-borderless,
.ad-bot,
.ad-bottom,
.ad-bottom-container,
.ad-bottom-right-container,
.ad-bottom728x90,
.ad-bottomLeft,
.ad-bottomleader,
.ad-bottomline,
.ad-box-2,
.ad-box-300x250,
.ad-box-auto,
.ad-box-caption,
.ad-box-container,
.ad-box-title,
.ad-box-up,
.ad-box-video,
.ad-box-wrapper,
.ad-box1,
.ad-box2,
.ad-box3,
.ad-box:not(#ad-banner):not(:empty),
.ad-box_h,
.ad-boxamp-wrapper,
.ad-boxbottom,
.ad-boxes,
.ad-boxsticky,
.ad-boxtop,
.ad-brdr-btm,
.ad-break,
.ad-break-item,
.ad-breaker,
.ad-breakout,
.ad-browse-rectangle,
.ad-bt,
.ad-btn,
.ad-btn-heading,
.ad-bug-300w,
.ad-burnside,
.ad-button,
.ad-buttons,
.ad-c-label,
.ad-cad,
.ad-calendar,
.ad-call-300x250,
.ad-callout,
.ad-callout-wrapper,
.ad-caption,
.ad-card,
.ad-card-container,
.ad-carousel,
.ad-cat,
.ad-catfish,
.ad-cell,
.ad-cen,
.ad-cen2,
.ad-cen3,
.ad-center,
.ad-centered,
.ad-centering,
.ad-chartbeatwidget,
.ad-choices,
.ad-circ,
.ad-click,
.ad-close-button,
.ad-cls,
.ad-cls-fix,
.ad-cnt,
.ad-code,
.ad-codes,
.ad-col,
.ad-col-02,
.ad-colour,
.ad-column,
.ad-comment,
.ad-companion,
.ad-complete,
.ad-component,
.ad-component-fullbanner2,
.ad-component-wrapper,
.ad-contain,
.ad-contain-300x250,
.ad-contain-top,
.ad-container--hot-video,
.ad-container--inline,
.ad-container--leaderboard,
.ad-container--masthead,
.ad-container--mrec,
.ad-container--stripe,
.ad-container--top,
.ad-container-160x600,
.ad-container-300x250,
.ad-container-728,
.ad-container-728x90,
.ad-container-adsense,
.ad-container-banner-top,
.ad-container-bot,
.ad-container-bottom,
.ad-container-box,
.ad-container-embedded,
.ad-container-header,
.ad-container-inner,
.ad-container-inthread,
.ad-container-leaderboard,
.ad-container-left,
.ad-container-m,
.ad-container-medium-rectangle,
.ad-container-middle,
.ad-container-multiple,
.ad-container-pave,
.ad-container-property,
.ad-container-responsive,
.ad-container-right,
.ad-container-side,
.ad-container-single,
.ad-container-tool,
.ad-container-top,
.ad-container-topad,
.ad-container-wrapper,
.ad-container1,
.ad-container3x,
.ad-container__ad-slot,
.ad-container__leaderboard,
.ad-container__sticky-wrapper,
.ad-container_row,
.ad-content,
.ad-content-area,
.ad-content-rectangle,
.ad-content-slot,
.ad-content-wrapper,
.ad-context,
.ad-cover,
.ad-critical,
.ad-cta,
.ad-current,
.ad-curtain,
.ad-custom-size,
.ad-d,
.ad-decoration,
.ad-defer,
.ad-desktop,
.ad-desktop-in-content,
.ad-desktop-legacy,
.ad-desktop-native-1,
.ad-desktop-native-2,
.ad-desktop-only,
.ad-desktop-right,
.ad-detail,
.ad-dfp-column,
.ad-dfp-row,
.ad-disclaimer,
.ad-disclaimer-container,
.ad-disclaimer-text,
.ad-display,
.ad-displayed,
.ad-diver,
.ad-divider,
.ad-dog,
.ad-dog__cnx-container,
.ad-dog__ratio-16x9,
.ad-dt,
.ad-dx_wrp,
.ad-e,
.ad-element,
.ad-enabled,
.ad-engage,
.ad-entity-container,
.ad-entry-wrapper,
.ad-ex,
.ad-exchange,
.ad-expand,
.ad-external,
.ad-fadein,
.ad-fadeup,
.ad-fallback,
.ad-feature-content,
.ad-feature-sponsor,
.ad-feature-text,
.ad-featured-video-caption,
.ad-feedback,
.ad-fi,
.ad-field,
.ad-filler,
.ad-filmstrip,
.ad-first,
.ad-fix,
.ad-fixed,
.ad-flag,
.ad-flex,
.ad-flex-center,
.ad-float,
.ad-floating,
.ad-floor,
.ad-footer,
.ad-footer-empty,
.ad-footer-leaderboard,
.ad-format-300x250,
.ad-format-300x600,
.ad-forum,
.ad-frame,
.ad-frame-container,
.ad-full,
.ad-full-width,
.ad-fullbanner,
.ad-fullbanner-btf-container,
.ad-fullbannernohieght,
.ad-fullwidth,
.ad-gap-sm,
.ad-giga,
.ad-google,
.ad-google-contextual,
.ad-gpt,
.ad-gpt-breaker,
.ad-gpt-container,
.ad-gpt-main,
.ad-gpt-vertical,
.ad-graphic-large,
.ad-gray,
.ad-grey,
.ad-grid,
.ad-grid-125,
.ad-grid-container,
.ad-group,
.ad-halfpage,
.ad-halfpage-placeholder,
.ad-hdr,
.ad-head,
.ad-header,
.ad-header-below,
.ad-header-container,
.ad-header-creative,
.ad-header-inner-wrap,
.ad-header-pencil,
.ad-header-placeholder,
.ad-header-sidebar,
.ad-header-small-square,
.ad-heading,
.ad-height-250,
.ad-height-280,
.ad-height-600,
.ad-here,
.ad-hero,
.ad-hide-mobile,
.ad-hideable,
.ad-hint,
.ad-hldr-tmc,
.ad-ho,
.ad-hold,
.ad-holder,
.ad-holder-center,
.ad-holder-mob-300,
.ad-home-bottom,
.ad-home-leaderboard-placeholder,
.ad-home-right,
.ad-homeleaderboard,
.ad-homepage,
.ad-homepage-1,
.ad-homepage-2,
.ad-homepage-one,
.ad-hor,
.ad-horizontal,
.ad-horizontal-large,
.ad-horizontal-top,
.ad-horizontal-top-wrapper,
.ad-house-btac,
.ad-housepromo-d-wrapper,
.ad-hoverable,
.ad-hpto,
.ad-href1,
.ad-href2,
.ad-iab-txt,
.ad-icon,
.ad-identifier,
.ad-iframe,
.ad-iframe-container,
.ad-in-content,
.ad-in-content-300,
.ad-in-post,
.ad-in-read,
.ad-in-results,
.ad-inStory,
.ad-incontent,
.ad-incontent-wrap,
.ad-index-main,
.ad-indicator-horiz,
.ad-info-wrap,
.ad-inline,
.ad-inline-article,
.ad-inline-block,
.ad-inner,
.ad-inner-container,
.ad-inner-container-background,
.ad-innr,
.ad-insert,
.ad-inserter-widget,
.ad-inside,
.ad-integrated-display,
.ad-internal,
.ad-interruptor,
.ad-interstitial,
.ad-island,
.ad-item,
.ad-item-related,
.ad-label,
.ad-lable,
.ad-landscape,
.ad-large-1,
.ad-large-game,
.ad-last,
.ad-lat,
.ad-lat2,
.ad-layer,
.ad-lazy,
.ad-lb,
.ad-ldrbrd,
.ad-lead,
.ad-lead-bottom,
.ad-leader,
.ad-leader-board,
.ad-leader-bottom,
.ad-leader-plus-top,
.ad-leader-top,
.ad-leader-wrap,
.ad-leader-wrapper,
.ad-leaderboard,
.ad-leaderboard-base,
.ad-leaderboard-companion,
.ad-leaderboard-container,
.ad-leaderboard-flex,
.ad-leaderboard-footer,
.ad-leaderboard-header,
.ad-leaderboard-middle,
.ad-leaderboard-placeholder,
.ad-leaderboard-slot,
.ad-leaderboard-splitter,
.ad-leaderboard-top,
.ad-leaderboard-wrapper,
.ad-leaderbody,
.ad-leaderheader,
.ad-leadtop,
.ad-left-1,
.ad-left-top,
.ad-leftrail,
.ad-lib-div,
.ad-line,
.ad-link,
.ad-link-block,
.ad-link-label,
.ad-link-left,
.ad-link-right,
.ad-links,
.ad-links-text,
.ad-list-desktop,
.ad-list-item,
.ad-loaded,
.ad-loader,
.ad-location,
.ad-location-container,
.ad-lock,
.ad-lock-content,
.ad-lowerboard,
.ad-lrec,
.ad-m-banner,
.ad-m-mrec,
.ad-m-rec,
.ad-mad,
.ad-main,
.ad-manager-ad,
.ad-manager-placeholder,
.ad-manager-wrapper,
.ad-margin,
.ad-marketplace,
.ad-marketswidget,
.ad-marquee,
.ad-masthead,
.ad-masthead-1,
.ad-masthead-left,
.ad-mb,
.ad-med,
.ad-med-rec,
.ad-med-rect,
.ad-med-rect-tmp,
.ad-medium,
.ad-medium-container,
.ad-medium-content,
.ad-medium-rectangle,
.ad-medium-rectangle-base,
.ad-medium-two,
.ad-medium-widget,
.ad-medrect,
.ad-megaboard,
.ad-message,
.ad-messaging,
.ad-microsites,
.ad-midleader,
.ad-mobile,
.ad-mobile--sticky,
.ad-mobile-300x150,
.ad-mobile-300x250,
.ad-mobile-300x50,
.ad-mobile-banner,
.ad-mobile-flex-inc,
.ad-mobile-flex-pos2,
.ad-mobile-incontent-ad-plus,
.ad-mobile-mpu-plus-outstream-inc,
.ad-mobile-nav-ad-plus,
.ad-mod,
.ad-mod-section,
.ad-mod-section-728-90,
.ad-module,
.ad-mount,
.ad-mpl,
.ad-mpu,
.ad-mpu-bottom,
.ad-mpu-container,
.ad-mpu-middle,
.ad-mpu-middle2,
.ad-mpu-placeholder,
.ad-mpu-plus-top,
.ad-mpu-top,
.ad-mpu__aside,
.ad-mpufixed,
.ad-mr-article,
.ad-mrec,
.ad-mrect,
.ad-msg,
.ad-msn,
.ad-native,
.ad-native-top-sidebar,
.ad-nav-ad,
.ad-nav-ad-plus,
.ad-new,
.ad-new-box,
.ad-no-css,
.ad-no-mobile,
.ad-no-notice,
.ad-no-style,
.ad-noBorderAndMargin,
.ad-noline,
.ad-note,
.ad-notice,
.ad-notice-small,
.ad-observer,
.ad-oms,
.ad-on,
.ad-on-top,
.ad-one,
.ad-other,
.ad-outer,
.ad-outlet,
.ad-outline,
.ad-output-middle,
.ad-output-wrapper,
.ad-outside,
.ad-overlay,
.ad-packs,
.ad-padding,
.ad-page-leader,
.ad-page-medium,
.ad-page-setting,
.ad-pagehead,
.ad-panel,
.ad-panel-wrap,
.ad-panel__container,
.ad-panel__container--styled,
.ad-panel__googlead,
.ad-panorama,
.ad-parallax,
.ad-parent-billboard,
.ad-parent-class,
.ad-parent-halfpage,
.ad-pb,
.ad-peg,
.ad-pencil-margin,
.ad-permalink,
.ad-personalise,
.ad-place,
.ad-place-active,
.ad-place-holder,
.ad-placeholder,
.ad-placeholder--mpu,
.ad-placeholder-leaderboard,
.ad-placeholder-wrapper,
.ad-placeholder-wrapper-dynamic,
.ad-placeholder__inner,
.ad-placement-left,
.ad-placement-right,
.ad-places,
.ad-plea,
.ad-poc,
.ad-poc-admin,
.ad-point,
.ad-popup,
.ad-popup-content,
.ad-pos,
.ad-pos-0,
.ad-pos-1,
.ad-pos-2,
.ad-pos-3,
.ad-pos-4,
.ad-pos-5,
.ad-pos-6,
.ad-pos-7,
.ad-pos-8,
.ad-pos-middle,
.ad-pos-top,
.ad-position,
.ad-position-1,
.ad-position-2,
.ad-poss,
.ad-post,
.ad-post-footer,
.ad-post-top,
.ad-postText,
.ad-poster,
.ad-posterad-inlisting,
.ad-preloader-container,
.ad-preparing,
.ad-prevent-jump,
.ad-primary,
.ad-primary-desktop,
.ad-primary-sidebar,
.ad-priority,
.ad-program-list,
.ad-program-top,
.ad-promo,
.ad-pub,
.ad-push,
.ad-pushdown,
.ad-r,
.ad-rac-box,
.ad-rail,
.ad-rail-wrapper,
.ad-ratio,
.ad-rb-hover,
.ad-reader-con-item,
.ad-rect,
.ad-rect-atf-01,
.ad-rect-top-right,
.ad-rectangle,
.ad-rectangle-1,
.ad-rectangle-banner,
.ad-rectangle-container,
.ad-rectangle-long,
.ad-rectangle-long-sky,
.ad-rectangle-text,
.ad-rectangle-wide,
.ad-rectangle-xs,
.ad-rectangle2,
.ad-rectanglemed,
.ad-region,
.ad-region-delay-load,
.ad-related,
.ad-relatedbottom,
.ad-render-space,
.ad-responsive,
.ad-responsive-slot,
.ad-responsive-wide,
.ad-result,
.ad-rev-content,
.ad-rh,
.ad-right,
.ad-right-header,
.ad-right1,
.ad-right2,
.ad-right3,
.ad-risingstar-container,
.ad-roadblock,
.ad-root,
.ad-rotation,
.ad-rotator,
.ad-row,
.ad-row-box,
.ad-row-horizontal,
.ad-row-horizontal-top,
.ad-row-viewport,
.ad-s,
.ad-s-rendered,
.ad-sample,
.ad-script-processed,
.ad-scroll,
.ad-scrollpane,
.ad-search-grid,
.ad-secondary-desktop,
.ad-section,
.ad-section-body,
.ad-section-one,
.ad-section-three,
.ad-section__skyscraper,
.ad-sense,
.ad-sense-ad,
.ad-sep,
.ad-separator,
.ad-shifted,
.ad-show-label,
.ad-showcase,
.ad-side,
.ad-side-one,
.ad-side-top,
.ad-side-wrapper,
.ad-sidebar,
.ad-sidebar-mrec,
.ad-sidebar-skyscraper,
.ad-siderail,
.ad-signup,
.ad-single-bottom,
.ad-sitewide,
.ad-size-300x600,
.ad-size-728x90,
.ad-size-landscape,
.ad-size-leaderboard,
.ad-size-medium-rectangle,
.ad-size-medium-rectangle-flex,
.ad-size-mpu,
.ad-skeleton,
.ad-skin-link,
.ad-sky,
.ad-sky-left,
.ad-sky-right,
.ad-sky-wrap,
.ad-skyscr,
.ad-skyscraper,
.ad-skyscraper1,
.ad-skyscraper2,
.ad-skyscraper3,
.ad-slider,
.ad-slot,
.ad-slot--container,
.ad-slot--inline,
.ad-slot--mostpop,
.ad-slot--mpu-banner-ad,
.ad-slot--rendered,
.ad-slot--right,
.ad-slot--top,
.ad-slot--top-above-nav,
.ad-slot--top-banner-ad,
.ad-slot--wrapper,
.ad-slot-1,
.ad-slot-2,
.ad-slot-234-60,
.ad-slot-300-250,
.ad-slot-728-90,
.ad-slot-a,
.ad-slot-article,
.ad-slot-banner,
.ad-slot-bigbox,
.ad-slot-billboard,
.ad-slot-box,
.ad-slot-container,
.ad-slot-container-1,
.ad-slot-desktop,
.ad-slot-full-width,
.ad-slot-header,
.ad-slot-horizontal,
.ad-slot-inview,
.ad-slot-placeholder,
.ad-slot-rail,
.ad-slot-replies,
.ad-slot-replies-header,
.ad-slot-responsive,
.ad-slot-sidebar,
.ad-slot-sidebar-b,
.ad-slot-tall,
.ad-slot-top,
.ad-slot-top-728,
.ad-slot-widget,
.ad-slot-wrapper,
.ad-slotRg,
.ad-slotRgc,
.ad-slot__ad--top,
.ad-slot__content,
.ad-slot__label,
.ad-slot__oas,
.ad-slots-wrapper,
.ad-slug,
.ad-small,
.ad-small-1,
.ad-small-2,
.ad-smallBP,
.ad-source,
.ad-sp,
.ad-space,
.ad-space-mpu-box,
.ad-space-topbanner,
.ad-spacing,
.ad-span,
.ad-speedbump,
.ad-splash,
.ad-sponsor,
.ad-sponsor-large-container,
.ad-sponsor-text,
.ad-sponsored-feed-top,
.ad-sponsored-links,
.ad-sponsored-post,
.ad-sponsors,
.ad-spot,
.ad-spotlight,
.ad-spteaser,
.ad-sq-super,
.ad-square,
.ad-square-placeholder,
.ad-square2-container,
.ad-square300,
.ad-squares,
.ad-stack,
.ad-standard,
.ad-statement,
.ad-static,
.ad-sticky,
.ad-sticky-banner,
.ad-sticky-bottom,
.ad-sticky-container,
.ad-sticky-slot,
.ad-sticky-wrapper,
.ad-stickyhero,
.ad-stickyhero--standard,
.ad-stickyhero-enable-mobile,
.ad-story-inject,
.ad-story-top,
.ad-strategic,
.ad-strip,
.ad-style2,
.ad-subnav-container,
.ad-subtitle,
.ad-summary,
.ad-superbanner,
.ad-superbanner-node,
.ad-t,
.ad-t-text,
.ad-table,
.ad-tabs,
.ad-tag,
.ad-tag-square,
.ad-tag__inner,
.ad-tag__wrapper,
.ad-takeover,
.ad-takeover-homepage,
.ad-tall,
.ad-tech-widget,
.ad-temp,
.ad-text,
.ad-text-centered,
.ad-text-label,
.ad-text-link,
.ad-text-links,
.ad-textads,
.ad-textlink,
.ad-thanks,
.ad-ticker,
.ad-tile,
.ad-title,
.ad-tl1,
.ad-top,
.ad-top-300x250,
.ad-top-728,
.ad-top-728x90,
.ad-top-banner,
.ad-top-billboard,
.ad-top-billboard-init,
.ad-top-box-right,
.ad-top-container,
.ad-top-desktop,
.ad-top-featured,
.ad-top-in,
.ad-top-lboard,
.ad-top-left,
.ad-top-mobile,
.ad-top-mpu,
.ad-top-padding,
.ad-top-rectangle,
.ad-top-right-container,
.ad-top-side,
.ad-top-slot,
.ad-top-spacing,
.ad-top-wrap-inner,
.ad-top-wrapper,
.ad-topbanner,
.ad-topper,
.ad-topright,
.ad-tower,
.ad-tower-container,
.ad-towers,
.ad-transition,
.ad-trck,
.ad-two,
.ad-twos,
.ad-txt,
.ad-txt-red,
.ad-type,
.ad-type-branding,
.ad-type-cube,
.ad-type-flex-leaderboard,
.ad-unit,
.ad-unit--leaderboard,
.ad-unit-2,
.ad-unit-300,
.ad-unit-300-wrapper,
.ad-unit-container,
.ad-unit-horisontal,
.ad-unit-inline-center,
.ad-unit-label,
.ad-unit-mpu,
.ad-unit-panel,
.ad-unit-secondary,
.ad-unit-sponsored-bar,
.ad-unit-t,
.ad-unit-text,
.ad-unit-top,
.ad-unit-wrapper,
.ad-unit__inner,
.ad-units-single-header-wrapper,
.ad-update,
.ad-vert,
.ad-vertical,
.ad-vertical-container,
.ad-vertical-stack-ad,
.ad-view-zone,
.ad-w-300,
.ad-w-728,
.ad-w-970,
.ad-warning,
.ad-warp,
.ad-watermark,
.ad-wgt,
.ad-wide,
.ad-wide-bottom,
.ad-wide-wrap,
.ad-widget,
.ad-widget-area,
.ad-widget-box,
.ad-widget-list,
.ad-widget-sizes,
.ad-widget-wrapper,
.ad-widgets,
.ad-width-300,
.ad-width-728,
.ad-wireframe,
.ad-wireframe-wrapper,
.ad-with-background,
.ad-with-header-wrapper,
.ad-with-notice,
.ad-wp,
.ad-wp-720,
.ad-wppr,
.ad-wppr-container,
.ad-wrap-leaderboard,
.ad-wrap-transparent,
.ad-wrap:not(#google_ads_iframe_checktag),
.ad-wrap_wallpaper,
.ad-wrapp,
.ad-wrapper,
.ad-wrapper--ad-unit-wrap,
.ad-wrapper--articletop,
.ad-wrapper--lg,
.ad-wrapper--sidebar,
.ad-wrapper-250,
.ad-wrapper-bg,
.ad-wrapper-left,
.ad-wrapper-mobile-atf,
.ad-wrapper-outer,
.ad-wrapper-solid,
.ad-wrapper-sticky,
.ad-wrapper-top,
.ad-wrapper-with-text,
.ad-wrapper__ad-slug,
.ad-xs-title,
.ad-zone,
.ad-zone-ajax,
.ad-zone-container,
.ad.addon,
.ad.bottomrect,
.ad.box,
.ad.brandboard,
.ad.card,
.ad.center,
.ad.contentboard,
.ad.desktop-970x250,
.ad.element,
.ad.floater-link,
.ad.gallery,
.ad.halfpage,
.ad.inner,
.ad.item,
.ad.leaderboard,
.ad.maxiboard,
.ad.maxisky,
.ad.middlerect,
.ad.module,
.ad.monsterboard,
.ad.netboard,
.ad.post-area,
.ad.promotion,
.ad.rectangle,
.ad.rectangle_2,
.ad.rectangle_3,
.ad.rectangle_home_1,
.ad.reform-top,
.ad.section,
.ad.sidebar-module,
.ad.size-300x250,
.ad.skybridgeleft,
.ad.small-mpu,
.ad.small-teaser,
.ad.super,
.ad.wideboard_tablet,
.ad02,
.ad03,
.ad04,
.ad08sky,
.ad1-float,
.ad1-left,
.ad1-right,
.ad10,
.ad100,
.ad1000,
.ad1001,
.ad100x100,
.ad120,
.ad120_600,
.ad120x120,
.ad120x240GrayBorder,
.ad120x60,
.ad120x600,
.ad125,
.ad125x125,
.ad125x125a,
.ad125x125b,
.ad140,
.ad160,
.ad160600,
.ad160_blk,
.ad160_l,
.ad160_r,
.ad160b,
.ad160x160,
.ad160x600,
.ad160x600GrayBorder,
.ad160x600_1,
.ad160x600box,
.ad170x30,
.ad18,
.ad180,
.ad180x80,
.ad185x100,
.ad19,
.ad1Image,
.ad1_bottom,
.ad1_latest,
.ad1_top,
.ad1b,
.ad1left,
.ad1x1,
.ad2-float,
.ad200,
.ad200x60,
.ad220x50,
.ad230,
.ad233x224,
.ad234,
.ad234x60,
.ad236x62,
.ad240,
.ad250,
.ad250wrap,
.ad250x250,
.ad250x300,
.ad260,
.ad260x60,
.ad284x134,
.ad290,
.ad2content_box,
.ad300,
.ad300-hp-top,
.ad3001,
.ad300250,
.ad300Block,
.ad300Wrapper,
.ad300X250,
.ad300_2,
.ad300_250,
.ad300_bg,
.ad300_ver2,
.ad300b,
.ad300banner,
.ad300px,
.ad300shows,
.ad300top,
.ad300w,
.ad300x100,
.ad300x120,
.ad300x150,
.ad300x250,
.ad300x250-1,
.ad300x250-2,
.ad300x250-inline,
.ad300x250Module,
.ad300x250Right,
.ad300x250Top,
.ad300x250_box,
.ad300x250_container,
.ad300x250a,
.ad300x250b,
.ad300x250box,
.ad300x250box2,
.ad300x250flex,
.ad300x250s,
.ad300x250x2,
.ad300x40,
.ad300x50-right,
.ad300x600,
.ad300x600cat,
.ad300x600post,
.ad300x77,
.ad300x90,
.ad310,
.ad315,
.ad320x250,
.ad320x50,
.ad336,
.ad336_b,
.ad336x250,
.ad336x280,
.ad336x362,
.ad343x290,
.ad350,
.ad350r,
.ad360,
.ad366,
.ad3rdParty,
.ad400,
.ad400right,
.ad400x40,
.ad450,
.ad468,
.ad468_60,
.ad468box,
.ad468innerboxadpic,
.ad468x60,
.ad468x60Wrap,
.ad468x60_main,
.ad470x60,
.ad530,
.ad540x90,
.ad590,
.ad590x90,
.ad5_container,
.ad600,
.ad612x80,
.ad620x70,
.ad626X35,
.ad640x480,
.ad644,
.ad650x140,
.ad652,
.ad70,
.ad728,
.ad72890,
.ad728By90,
.ad728_90,
.ad728_blk,
.ad728_cont,
.ad728_wrap,
.ad728b,
.ad728cont,
.ad728h,
.ad728top,
.ad728x90,
.ad728x90-1,
.ad728x90-2,
.ad728x90box,
.ad728x90btf,
.ad970,
.ad970_250,
.adActive,
.adAlert,
.adArea,
.adAreaLC,
.adAreaNative,
.adAreaTopTitle,
.adArticleBanner,
.adArticleBody,
.adArticleSideTop300x250,
.adBan,
.adBanner300x250,
.adBanner728x90,
.adBillboard,
.adBkgd,
.adBlock,
.adBlock728,
.adBlockBottom,
.adBlockSpacer,
.adBlockSpot,
.adBorder,
.adBorders,
.adBox,
.adBox-small,
.adBox1,
.adBox2,
.adBox5,
.adBox6,
.adBox728,
.adBox728X90,
.adBox728X90_header,
.adBoxBody,
.adBoxBorder,
.adBoxContainer,
.adBoxContent,
.adBoxFooter,
.adBoxHeader,
.adBoxSidebar,
.adBoxSingle,
.adBoxTitle,
.adBox_1,
.adBox_3,
.adBtm,
.adCall,
.adCaptionText,
.adCell,
.adCenter,
.adCenterAd,
.adCentertile,
.adChoice,
.adChoiceLogo,
.adChoicesLogo,
.adChrome,
.adClose,
.adCode,
.adColumn,
.adColumnLeft,
.adColumnRight,
.adComponent,
.adCont,
.adContTop,
.adContainer1,
.adContainerSide,
.adContent,
.adContentAd,
.adContour,
.adCopy,
.adCreative,
.adCreator,
.adCube,
.adDefRect,
.adDetails_ad336,
.adDiv,
.adDrawer,
.adDyn,
.adElement,
.adExpanded,
.adFooterLinks,
.adFrame,
.adFrameCnt,
.adFrameContainer,
.adFrames,
.adFuel-label,
.adFull,
.adFullbanner,
.adGlobalHeader,
.adGoogle,
.adGroup,
.adHalfPage,
.adHead,
.adHeader,
.adHeaderAdbanner,
.adHeaderText,
.adHeaderblack,
.adHeading,
.adHeadline,
.adHeadlineSummary,
.adHed,
.adHeight200,
.adHeight270,
.adHeight280,
.adHeight313,
.adHeight600,
.adHolder,
.adHolder2,
.adHolderStory,
.adHoldert,
.adHome300x250,
.adHomeSideTop300x250,
.adHorisontal,
.adHorisontalNoBorder,
.adHorizontalTextAlt,
.adHplaceholder,
.adHz,
.adIDiv,
.adIframe,
.adIframeCount,
.adImg,
.adImgIM,
.adInArticle,
.adInContent,
.adInfo,
.adInitRemove,
.adInner,
.adInnerLeftBottom,
.adInsider,
.adInteractive,
.adIsland,
.adItem,
.adLabel,
.adLabelLine,
.adLabels,
.adLargeRec,
.adLargeRect,
.adLat,
.adLeader,
.adLeaderBoard_container,
.adLeaderForum,
.adLeaderboard,
.adLeaderboardAdContainer,
.adLeft,
.adLine,
.adLink,
.adLinkCnt,
.adListB,
.adLoader,
.adLocal,
.adLocation,
.adMPU,
.adMPUHome,
.adMRECHolder,
.adMarker,
.adMarkerBlock,
.adMastheadLeft,
.adMastheadRight,
.adMed,
.adMedRectBox,
.adMedRectBoxLeft,
.adMediaMiddle,
.adMediumRectangle,
.adMessage,
.adMiddle,
.adMinHeight280,
.adMinHeight313,
.adMiniTower,
.adMod,
.adModule,
.adModule--inner,
.adModule--outer,
.adModule-outer,
.adModule300,
.adModuleAd,
.adMpu,
.adMpuHolder,
.adMrginBottom,
.adNarrow,
.adNoBorder,
.adNoOutline,
.adNone,
.adNote,
.adNotice,
.adNotice90,
.adNoticeOut,
.adNotification,
.adObj,
.adOne,
.adOuterContainer,
.adOverlay,
.adPanel,
.adPanelContent,
.adPanorama,
.adPlaceholder,
.adPlacement,
.adPod,
.adPosition,
.adPremium,
.adRecommend,
.adRecommendRight,
.adRect,
.adRectangle,
.adRectangle-pos-large,
.adRectangle-pos-medium,
.adRectangle-pos-small,
.adRectangleBanner,
.adRectangleUnit,
.adRemove,
.adRenderer,
.adRendererInfinite,
.adResponsive,
.adResult,
.adResults,
.adRight,
.adRightSide,
.adRightSky,
.adRoller,
.adRotator,
.adRow,
.adRowTopWrapper,
.adSKY,
.adSection,
.adSenceImagePush,
.adSense,
.adSense-header,
.adSepDiv,
.adServer,
.adSeven,
.adSide,
.adSideBarMPU,
.adSideBarMPUTop,
.adSidebarButtons,
.adSizer,
.adSkin,
.adSky,
.adSkyscaper,
.adSkyscraper,
.adSlice,
.adSlide,
.adSlot,
.adSlot-container,
.adSlotAdition,
.adSlotCnt,
.adSlotContainer,
.adSlotHeaderContainer,
.adSlug,
.adSpBelow,
.adSpace,
.adSpace300x250,
.adSpace950x90,
.adSpacer,
.adSpec,
.adSplash,
.adSponsor,
.adSponsorText,
.adSponsorhipInfo,
.adSpot,
.adSpot-mrec,
.adSpot-textBox,
.adSpotBlock,
.adSpotFullWidth,
.adSpotIsland,
.adSquare,
.adStatementText,
.adStyle,
.adStyle1,
.adSub,
.adSubColPod,
.adSummary,
.adSuperboard,
.adSupertower,
.adTD,
.adTXTnew,
.adTab,
.adTag,
.adTag-top,
.adTag-wrap,
.adTagThree,
.adTagTwo,
.adText,
.adTextDownload,
.adTextPmpt,
.adTextStreaming,
.adTextWrap,
.adTicker,
.adTile,
.adTileWrap,
.adTiler,
.adTip,
.adTitle,
.adTitleR,
.adTop,
.adTopBk,
.adTopFloat,
.adTopHome,
.adTopLB,
.adTopLeft,
.adTopRight,
.adTopWrapper,
.adTopboxright,
.adTwo,
.adTxt,
.adType2,
.adUnderArticle,
.adUnit,
.adUnitHorz,
.adUnitVert,
.adVar,
.adVertical,
.adVideo,
.adVideo2,
.adVl,
.adVplaceholder,
.adWarning,
.adWebBoard,
.adWideSkyscraper,
.adWideSkyscraperRight,
.adWidget,
.adWidgetBlock,
.adWithTab,
.adWizard-ad,
.adWord,
.adWords-bg,
.adWrap,
.adWrapLg,
.adWrapper,
.adWrapper1,
.adZone,
.adZoneRight,
.ad_0,
.ad_1,
.ad_1000_125,
.ad_120x60,
.ad_120x600,
.ad_120x90,
.ad_125,
.ad_130x90,
.ad_150x150,
.ad_160,
.ad_160_600,
.ad_160x600,
.ad_188_inner,
.ad_1tdq7q5,
.ad_2,
.ad_200,
.ad_240,
.ad_250250,
.ad_250x200,
.ad_250x250,
.ad_290_290,
.ad_3,
.ad_300,
.ad_300250,
.ad_300_250,
.ad_300_250_1,
.ad_300_250_2,
.ad_300_250_wrapper,
.ad_300_600,
.ad_300by250,
.ad_300x100,
.ad_300x250,
.ad_300x250_container,
.ad_300x600,
.ad_320x250_async,
.ad_336,
.ad_336x280,
.ad_350x250,
.ad_4,
.ad_468,
.ad_468x60,
.ad_5,
.ad_600,
.ad_640,
.ad_640x480,
.ad_728,
.ad_72890,
.ad_728Home,
.ad_728_90,
.ad_728_90_1,
.ad_728_90b,
.ad_728_top,
.ad_728x90,
.ad_728x90-1,
.ad_728x90-2,
.ad_728x90_container,
.ad_728x90b,
.ad_90,
.ad_970x250,
.ad_970x250_300x250,
.ad_970x250_container,
.ad_Bumper,
.ad_Flex,
.ad_Left,
.ad_Right,
.ad__300x250,
.ad__300x600,
.ad__970x250,
.ad__align,
.ad__centered,
.ad__container,
.ad__content,
.ad__full--width,
.ad__header,
.ad__holder,
.ad__image,
.ad__in_article,
.ad__inline,
.ad__item,
.ad__label,
.ad__leaderboard,
.ad__mobi,
.ad__mobile-footer,
.ad__mpu,
.ad__placeholder,
.ad__rectangle,
.ad__section-border,
.ad__sidebar,
.ad__space,
.ad__sticky,
.ad__template,
.ad__window,
.ad__wrapper,
.ad_adv,
.ad_after_section,
.ad_amazon,
.ad_area,
.ad_area_two,
.ad_back,
.ad_background,
.ad_background_1,
.ad_background_true,
.ad_banner,
.ad_banner2,
.ad_banner_2,
.ad_banner_250x250,
.ad_banner_468,
.ad_banner_728,
.ad_banner_728x90_inner,
.ad_banner_border,
.ad_banner_div,
.ad_bar,
.ad_below_content,
.ad_belowfirstpost_frame,
.ad_bg,
.ad_bgskin,
.ad_big_banner,
.ad_bigbox,
.ad_billboard,
.ad_blk,
.ad_block,
.ad_block_1,
.ad_block_2,
.ad_block_widget,
.ad_body,
.ad_border,
.ad_botbanner,
.ad_bottom,
.ad_bottom_728,
.ad_bottom_leaderboard,
.ad_bottom_left,
.ad_bottom_mpu,
.ad_bottom_space,
.ad_box,
.ad_box1,
.ad_box2,
.ad_box_2,
.ad_box_6,
.ad_box_9,
.ad_box_ad,
.ad_box_div,
.ad_box_header,
.ad_box_spacer,
.ad_box_top,
.ad_break,
.ad_break2_container,
.ad_break_container,
.ad_btf,
.ad_btn,
.ad_btn-white,
.ad_btn1,
.ad_btn2,
.ad_by,
.ad_callout,
.ad_caption,
.ad_center,
.ad_center_bottom,
.ad_centered,
.ad_choice,
.ad_choices,
.ad_cl,
.ad_claim,
.ad_click,
.ad_cls_fix,
.ad_code,
.ad_col,
.ad_column,
.ad_column_box,
.ad_common,
.ad_con,
.ad_cont,
.ad_cont_footer,
.ad_contain,
.ad_container,
.ad_container_body,
.ad_container_bottom,
.ad_content,
.ad_content_below,
.ad_content_bottom,
.ad_content_wide,
.ad_content_wrapper,
.ad_contents,
.ad_crown,
.ad_custombanner,
.ad_d_big,
.ad_db,
.ad_default,
.ad_description,
.ad_desktop,
.ad_disclaimer,
.ad_div,
.ad_div_banner,
.ad_div_box,
.ad_div_box2,
.ad_element,
.ad_embed,
.ad_feature,
.ad_float,
.ad_floating_box,
.ad_fluid,
.ad_footer,
.ad_footer_super_banner,
.ad_frame,
.ad_frame_around,
.ad_fullwidth,
.ad_gam,
.ad_global_header,
.ad_google,
.ad_gpt,
.ad_grein_botn,
.ad_grid,
.ad_group,
.ad_half_page,
.ad_halfpage,
.ad_hd,
.ad_head,
.ad_head_rectangle,
.ad_header,
.ad_header_top,
.ad_heading,
.ad_headline,
.ad_holder,
.ad_horizontal,
.ad_hover_href,
.ad_iframe2,
.ad_image,
.ad_img,
.ad_imgae_150,
.ad_in_article,
.ad_in_text,
.ad_incontent,
.ad_index02,
.ad_indicator,
.ad_inline,
.ad_inline_wrapper,
.ad_inner,
.ad_inset,
.ad_island,
.ad_item,
.ad_label,
.ad_large,
.ad_lb,
.ad_leader,
.ad_leader_bottom,
.ad_leader_plus_top,
.ad_leaderboard,
.ad_leaderboard_atf,
.ad_leaderboard_master,
.ad_leaderboard_top,
.ad_leaderboard_wrap,
.ad_left,
.ad_left_cell,
.ad_left_column,
.ad_lft,
.ad_line2,
.ad_link,
.ad_links,
.ad_lnks,
.ad_loc,
.ad_long,
.ad_lrec,
.ad_lrgsky,
.ad_lt,
.ad_main,
.ad_maintopad,
.ad_margin,
.ad_marker,
.ad_masthead,
.ad_med,
.ad_medium_rectangle,
.ad_medrec,
.ad_medrect,
.ad_megabanner,
.ad_message,
.ad_mid_post_body,
.ad_middle,
.ad_middle_banner,
.ad_mobile,
.ad_mod,
.ad_module,
.ad_mp,
.ad_mpu,
.ad_mpu_top,
.ad_mr,
.ad_mrec,
.ad_native,
.ad_native_xrail,
.ad_news,
.ad_no_border,
.ad_note,
.ad_notice,
.ad_oms,
.ad_on_article,
.ad_one,
.ad_one_one,
.ad_one_third,
.ad_outer,
.ad_overlays,
.ad_p360,
.ad_pagebody,
.ad_panel,
.ad_paragraphs_desktop_container,
.ad_partner,
.ad_partners,
.ad_pause,
.ad_pic,
.ad_place,
.ad_placeholder,
.ad_placeholder_d_b,
.ad_placeholder_d_s,
.ad_placeholder_d_sticky,
.ad_placement,
.ad_plus,
.ad_position,
.ad_post,
.ad_primary,
.ad_promo,
.ad_promo1,
.ad_promo_spacer,
.ad_push,
.ad_r,
.ad_rec,
.ad_rect,
.ad_rectangle,
.ad_rectangle_300_250,
.ad_rectangle_medium,
.ad_rectangular,
.ad_regular1,
.ad_regular2,
.ad_regular3,
.ad_reminder,
.ad_response,
.ad_rhs,
.ad_right,
.ad_rightSky,
.ad_right_300_250,
.ad_right_cell,
.ad_right_col,
.ad_rightside,
.ad_row,
.ad_scroll,
.ad_secondary,
.ad_segment,
.ad_sense_01,
.ad_sense_footer_container,
.ad_share_box,
.ad_side,
.ad_side_box,
.ad_side_rectangle_banner,
.ad_sidebar,
.ad_sidebar_bigbox,
.ad_sidebar_inner,
.ad_sidebar_left,
.ad_sidebar_right,
.ad_size_160x600,
.ad_skin,
.ad_sky,
.ad_sky2,
.ad_sky2_2,
.ad_skyscpr,
.ad_skyscraper,
.ad_skyscrapper,
.ad_slider_out,
.ad_slot,
.ad_slot_inread,
.ad_slot_right,
.ad_slug,
.ad_small,
.ad_space,
.ad_space_300_250,
.ad_spacer,
.ad_sponsor,
.ad_sponsor_fp,
.ad_sponsoredsection,
.ad_spot,
.ad_spot_b,
.ad_spot_c,
.ad_spotlight,
.ad_square,
.ad_square_r,
.ad_square_r_top,
.ad_square_top,
.ad_start,
.ad_static,
.ad_station,
.ad_story_island,
.ad_stream,
.ad_stream_hd,
.ad_sub,
.ad_supersize,
.ad_table,
.ad_tag,
.ad_tag_middle,
.ad_text,
.ad_text_link,
.ad_text_links,
.ad_text_vertical,
.ad_text_w,
.ad_textlink1,
.ad_textlink_box,
.ad_thumbnail_header,
.ad_title,
.ad_title_small,
.ad_tlb,
.ad_to_list,
.ad_top,
.ad_top1,
.ad_top_1,
.ad_top_2,
.ad_top_3,
.ad_top_banner,
.ad_top_leaderboard,
.ad_top_left,
.ad_top_mpu,
.ad_top_right,
.ad_topic_content,
.ad_topmain,
.ad_topright,
.ad_topshop,
.ad_tower,
.ad_trailer_header,
.ad_trick_header,
.ad_trick_left,
.ad_ttl,
.ad_two,
.ad_two_third,
.ad_txt2,
.ad_type_1,
.ad_type_adsense,
.ad_type_dfp,
.ad_under,
.ad_under_royal_slider,
.ad_unit,
.ad_unit_300,
.ad_unit_300_x_250,
.ad_unit_600,
.ad_unit_rail,
.ad_unit_wrapper,
.ad_unit_wrapper_main,
.ad_url,
.ad_v2,
.ad_v3,
.ad_vertisement,
.ad_w,
.ad_w300h450,
.ad_w300i,
.ad_w_us_a300,
.ad_warn,
.ad_warning,
.ad_watch_now,
.ad_watermark,
.ad_wid300,
.ad_wide,
.ad_wide_vertical,
.ad_widget,
.ad_widget_200_100,
.ad_widget_200_200,
.ad_widget_image,
.ad_widget_title,
.ad_word,
.ad_wrap,
.ad_wrapper,
.ad_wrapper_300,
.ad_wrapper_970x90,
.ad_wrapper_box,
.ad_wrapper_false,
.ad_wrapper_fixed,
.ad_wrapper_top,
.ad_wrp,
.ad_xrail,
.ad_xrail_top,
.ad_zone,
.adace-adi-popup-wrapper,
.adace-slideup-slot-wrap,
.adace-slot,
.adace-slot-wrapper,
.adace-sponsors-box,
.adace-vignette,
.adalert-overlayer,
.adalert-toplayer,
.adamazon,
.adarea,
.adarea-long,
.adarticle,
.adb-top,
.adback,
.adban,
.adband,
.adbanner-300-250,
.adbanner-bottom,
.adbanner1,
.adbannerbox,
.adbannerright,
.adbannertop,
.adbase,
.adbbox,
.adbckgrnd,
.adbetween,
.adbetweenarticles,
.adbkgnd,
.adblade,
.adblade-container,
.adbladeimg,
.adblk,
.adblock-bottom,
.adblock-header,
.adblock-header1,
.adblock-main,
.adblock-popup,
.adblock-top,
.adblock-top-left,
.adblock-wide,
.adblock300,
.adblock300250,
.adblock728x90,
.adblock__banner,
.adblock_noborder,
.adblock_primary,
.adblockdiv,
.adblocks-topright,
.adboard,
.adborder,
.adborderbottom,
.adbordertop,
.adbot,
.adbot_postbit,
.adbot_showthread,
.adbottom,
.adbottomright,
.adbox-300x250,
.adbox-468x60,
.adbox-border-desk,
.adbox-box,
.adbox-header,
.adbox-outer,
.adbox-rectangle,
.adbox-sidebar,
.adbox-slider,
.adbox-style,
.adbox-title,
.adbox-topbanner,
.adbox-wrapper,
.adbox1,
.adbox160,
.adbox2,
.adbox300,
.adbox300x250,
.adbox336,
.adbox600,
.adbox728,
.adboxRightSide,
.adboxTopBanner,
.adboxVert,
.adbox_300x600,
.adbox_310x400,
.adbox_366x280,
.adbox_468X60,
.adbox_border,
.adbox_bottom,
.adbox_br,
.adbox_cont,
.adbox_largerect,
.adbox_left,
.adbox_top,
.adboxbg,
.adboxbot,
.adboxclass,
.adboxcm,
.adboxcontent,
.adboxcontentsum,
.adboxes,
.adboxesrow,
.adboxid,
.adboxlarge,
.adboxlong,
.adboxo,
.adboxtop,
.adbreak,
.adbrite2,
.adbtn,
.adbtns,
.adbttm_right_300,
.adbttm_right_label,
.adbucks,
.adbug,
.adbutler-inline-ad,
.adbutler-top-banner,
.adbutler_top_banner,
.adbutton,
.adbutton-block,
.adbuttons,
.adcard,
.adcasing,
.adcenter,
.adchange,
.adchoices,
.adchoices-link,
.adclass,
.adcode,
.adcode-widget,
.adcode2,
.adcode300x250,
.adcode728x90,
.adcode_container,
.adcodetextwrap300x250,
.adcodetop,
.adcol1,
.adcol2,
.adcolumn,
.adcolumn_wrapper,
.adcomment,
.adcon,
.adcont,
.adcontainer-Leaderboard,
.adcontainer-Rectangle,
.adcontainer2,
.adcontainer300x250l,
.adcontainer300x250r,
.adcontainer_footer,
.adcopy,
.add-box-side,
.add-box-top,
.add-sidebar,
.add300,
.add300top,
.add300x250,
.addAdvertContainer,
.add_topbanner,
.addarea,
.addarearight,
.addbanner,
.addboxRight,
.addisclaimer,
.addiv,
.adds2,
.adds300x250,
.adds620x90,
.addtitle,
.addvert,
.addwide,
.adengageadzone,
.adenquire,
.adex-ad-text,
.adfbox,
.adfeedback,
.adfeeds,
.adfix,
.adflag,
.adflexi,
.adfliction,
.adfoot,
.adfootbox,
.adfooter,
.adform__topbanner,
.adfoxly-overlay,
.adfoxly-place-delay,
.adfoxly-wrapper,
.adframe,
.adframe2,
.adframe_banner,
.adframe_rectangle,
.adfree,
.adfront,
.adfront-head,
.adfrp,
.adfull,
.adgear,
.adgmleaderboard,
.adguru-content-html,
.adguru-modal-popup,
.adhalfhome,
.adhalfpage,
.adhalfpageright,
.adhead,
.adheader,
.adheightpromo,
.adheighttall,
.adherebox,
.adhesion-block,
.adhesion-header,
.adhesion:not(body),
.adhesiveAdWrapper,
.adhesiveWrapper,
.adhesive_holder,
.adhi,
.adhide,
.adhint,
.adholder,
.adholder-300,
.adholder2,
.adholderban,
.adhoriz,
.adiframe,
.adindex,
.adindicator,
.adinfo,
.adinjwidget,
.adinner,
.adinpost,
.adinsert,
.adinsert160,
.adinside,
.adintext,
.adintro,
.adisclaimer,
.adisland,
.adits,
.adjlink,
.adk-slot,
.adkicker,
.adkit,
.adlabel-horz,
.adlabel-vert,
.adlabel1,
.adlabel2,
.adlabel3,
.adlabelleft,
.adlarge,
.adlarger,
.adlateral,
.adlayer,
.adleader,
.adleft1,
.adleftph,
.adlgbox,
.adline,
.adlink,
.adlinkdiv,
.adlinks,
.adlinks-class,
.adlist,
.adlist1,
.adlist2,
.adloaded,
.adlsot,
.admain,
.adman,
.admarker,
.admaster,
.admediumred,
.admedrec,
.admeldBoxAd,
.admessage,
.admiddle,
.admiddlesidebar,
.admngr,
.admngrfr,
.admngrft,
.admods,
.admodule,
.admoduleB,
.admpu,
.admpu-small,
.admputop,
.admz,
.adnSpot,
.adname,
.adnet_area,
.adnotecenter,
.adnotice,
.adnotification,
.adnz-ad-placeholder,
.adocean,
.adocean728x90,
.adocean_desktop_section,
.adops,
.adpacks,
.adpacks_content,
.adpadding,
.adpane,
.adparent,
.adpic,
.adplace,
.adplace_center,
.adplaceholder,
.adplaceholder-top,
.adplacement,
.adplate-background,
.adplugg-tag,
.adpod,
.adpopup,
.adpos-300-mobile,
.adpost,
.adposter_pos,
.adproxy,
.adrec,
.adrechts,
.adrect,
.adrectangle,
.adrectwrapper,
.adrevtising-buttom,
.adright,
.adright300,
.adrightlg,
.adrightsm,
.adrighttop,
.adriverBanner,
.adroot,
.adrotate-sponsor,
.adrotate-widget,
.adrotate_ads_row,
.adrotate_top_banner,
.adrotate_widget,
.adrotate_widgets,
.adrotatediv,
.adrow,
.adrule,
.ads--bottom-spacing,
.ads--desktop,
.ads--full,
.ads--no-preload,
.ads--sidebar,
.ads--single,
.ads--square,
.ads--super,
.ads--top,
.ads-1,
.ads-120x600,
.ads-125,
.ads-160x600,
.ads-160x600-outer,
.ads-2,
.ads-3,
.ads-300,
.ads-300-250,
.ads-300-box,
.ads-300x250,
.ads-300x250-sidebar,
.ads-300x300,
.ads-300x600,
.ads-300x600-wrapper-en,
.ads-320-50,
.ads-320x250,
.ads-336x280,
.ads-468,
.ads-728,
.ads-728-90,
.ads-728by90,
.ads-728x90,
.ads-980x90,
.ads-above-comments,
.ads-ad,
.ads-advertorial,
.ads-article-right,
.ads-articlebottom,
.ads-aside,
.ads-banner,
.ads-banner-bottom,
.ads-banner-js,
.ads-banner-middle,
.ads-banner-spacing,
.ads-banner-top,
.ads-banner-top-right,
.ads-base,
.ads-beforecontent,
.ads-below-content,
.ads-below-home,
.ads-below-view-content,
.ads-between-comments,
.ads-bg,
.ads-bigbox,
.ads-bilboards,
.ads-bing-bottom,
.ads-bing-top,
.ads-block,
.ads-block-bottom-wrap,
.ads-block-link-text,
.ads-block-panel-tipo-1,
.ads-block-rightside,
.ads-block-top,
.ads-block-top-right,
.ads-border,
.ads-bottom,
.ads-bottom-block,
.ads-bottom-center,
.ads-bottom-content,
.ads-bottom-left,
.ads-bottom-right,
.ads-box,
.ads-box-border,
.ads-box-cont,
.ads-bt,
.ads-btm,
.ads-by,
.ads-by-google,
.ads-callback,
.ads-card,
.ads-carousel,
.ads-center,
.ads-centered,
.ads-cnt,
.ads-code,
.ads-col,
.ads-cols,
.ads-cont,
.ads-content,
.ads-core-placer,
.ads-custom,
.ads-decorator,
.ads-desktop,
.ads-div,
.ads-el,
.ads-end-content,
.ads-favicon,
.ads-feed,
.ads-fieldset,
.ads-footer,
.ads-fr,
.ads-global-header,
.ads-global-top,
.ads-google,
.ads-google-bottom,
.ads-google-top,
.ads-grp,
.ads-half,
.ads-header,
.ads-header-desktop,
.ads-header-left,
.ads-header-right,
.ads-here,
.ads-hints,
.ads-holder,
.ads-home,
.ads-homepage-2,
.ads-horizontal,
.ads-horizontal-banner,
.ads-image,
.ads-inarticle,
.ads-inline,
.ads-inner,
.ads-instance,
.ads-internal,
.ads-item,
.ads-label,
.ads-label-inverse,
.ads-large,
.ads-leaderboard,
.ads-leaderboard-border,
.ads-leaderboard-panel,
.ads-leaderbord,
.ads-left,
.ads-line,
.ads-list,
.ads-loaded,
.ads-long,
.ads-main,
.ads-margin,
.ads-marker,
.ads-medium-rect,
.ads-middle,
.ads-middle-top,
.ads-minheight,
.ads-mini,
.ads-mini-3rows,
.ads-mobile,
.ads-module,
.ads-module-alignment,
.ads-movie,
.ads-mpu,
.ads-narrow,
.ads-native-wrapper,
.ads-note,
.ads-one,
.ads-outer,
.ads-panel,
.ads-parent,
.ads-pholder,
.ads-placeholder,
.ads-placeholder-inside,
.ads-placeholder-wrapper,
.ads-placment,
.ads-post,
.ads-post-closing,
.ads-post-footer,
.ads-post-full,
.ads-posting,
.ads-profile,
.ads-rail,
.ads-rect,
.ads-rectangle,
.ads-relatedbottom,
.ads-rendering-fix,
.ads-right,
.ads-right-min,
.ads-rotate,
.ads-row,
.ads-scroller-box,
.ads-section,
.ads-side,
.ads-sidebar,
.ads-sidebar-boxad,
.ads-sidebar-widget,
.ads-sign,
.ads-single,
.ads-site,
.ads-size-small,
.ads-skin,
.ads-skin-mobile,
.ads-sky,
.ads-skyscraper,
.ads-skyscraper-container-left,
.ads-skyscraper-container-right,
.ads-skyscraper-left,
.ads-skyscraper-right,
.ads-small,
.ads-small-horizontal,
.ads-small-squares,
.ads-smartphone,
.ads-social-box,
.ads-sponsored-title,
.ads-sponsors,
.ads-square,
.ads-square-large,
.ads-square-small,
.ads-squares,
.ads-star,
.ads-stick-footer,
.ads-sticky,
.ads-story,
.ads-story-leaderboard-atf,
.ads-stripe,
.ads-styled,
.ads-superbanner,
.ads-system,
.ads-text,
.ads-title,
.ads-to-hide,
.ads-top,
.ads-top-728,
.ads-top-center,
.ads-top-content,
.ads-top-fixed,
.ads-top-home,
.ads-top-left,
.ads-top-main,
.ads-top-right,
.ads-top-spacer,
.ads-topbar,
.ads-two,
.ads-txt,
.ads-ul,
.ads-verticle,
.ads-wall-container,
.ads-wide,
.ads-widget,
.ads-widget-content,
.ads-widget-content-wrap,
.ads-widget-link {
display: none !important;
}


.ads-wrap,
.ads-wrapper,
.ads-wrapper-top,
.ads-x1,
.ads-zone,
.ads.bottom,
.ads.box,
.ads.cell,
.ads.cta,
.ads.grid-layout,
.ads.square,
.ads.top,
.ads.widget,
.ads01,
.ads1,
.ads10,
.ads11,
.ads120,
.ads120_600,
.ads120_600-widget,
.ads120_80,
.ads120x,
.ads123,
.ads125,
.ads125-widget,
.ads160,
.ads160-600,
.ads2,
.ads250,
.ads250-250,
.ads2Block,
.ads3,
.ads300,
.ads300-200,
.ads300-250,
.ads300250,
.ads300_250,
.ads300_600-widget,
.ads300box,
.ads300x600,
.ads336_280,
.ads336x280,
.ads4,
.ads468,
.ads468x60,
.ads600,
.ads720x90,
.ads728,
.ads728_90,
.ads728b,
.ads728x90,
.ads728x90-1,
.ads970,
.adsAdvert,
.adsArea,
.adsBanner,
.adsBannerLink,
.adsBlock,
.adsBlockContainerHorizontal,
.adsBot,
.adsBottom,
.adsBoxTop,
.adsCap,
.adsCell,
.adsColumn,
.adsConfig,
.adsCont,
.adsDef,
.adsDesktop,
.adsDetailsPage,
.adsDisclaimer,
.adsDiv,
.adsFirst,
.adsFixed,
.adsFull,
.adsHeader,
.adsHeading,
.adsHeight300x250,
.adsHeight720x90,
.adsHome-full,
.adsImages,
.adsInner,
.adsLabel,
.adsLibrary,
.adsLine,
.adsList,
.adsMPU,
.adsMag,
.adsMarker,
.adsMiddle,
.adsMvCarousel,
.adsNetwork,
.adsOuter,
.adsOverPrimary,
.adsPlaceHolder,
.adsPostquare,
.adsPushdown,
.adsRectangleMedium,
.adsRight,
.adsRow,
.adsSecond,
.adsSectionRL,
.adsSpacing,
.adsSticky,
.adsTag,
.adsText,
.adsTop,
.adsTopBanner,
.adsTopCont,
.adsTower2,
.adsTowerWrap,
.adsTxt,
.adsWidget,
.adsWrap,
.ads_160,
.ads_180,
.ads_2,
.ads_3,
.ads_300,
.ads_300_250,
.ads_300x250,
.ads_300x600,
.ads_4,
.ads_468,
.ads_468x60,
.ads_720x90,
.ads_728,
.ads_728x90,
.ads_Header,
.ads__article__header,
.ads__aside,
.ads__container,
.ads__header,
.ads__horizontal,
.ads__hyperleaderboard--hyperleaderboard,
.ads__inline,
.ads__interstitial,
.ads__link,
.ads__listing,
.ads__mid,
.ads__middle,
.ads__midpage-fullwidth,
.ads__native,
.ads__right-rail-ad,
.ads__sidebar,
.ads__top,
.ads_ad_box,
.ads_after,
.ads_after_more,
.ads_amazon,
.ads_area,
.ads_article,
.ads_ba_cad,
.ads_banner,
.ads_bar,
.ads_before,
.ads_between_content,
.ads_bg,
.ads_big,
.ads_bigrec,
.ads_block,
.ads_border,
.ads_box,
.ads_box_headline,
.ads_box_type1,
.ads_center,
.ads_code,
.ads_column,
.ads_container,
.ads_container_top,
.ads_content,
.ads_css,
.ads_div,
.ads_div1,
.ads_foot,
.ads_footer,
.ads_footerad,
.ads_full_1,
.ads_google,
.ads_h,
.ads_h1,
.ads_h2,
.ads_header,
.ads_header_bottom,
.ads_holder,
.ads_home,
.ads_horizontal,
.ads_inview,
.ads_item,
.ads_label,
.ads_lb,
.ads_leader,
.ads_leaderboard,
.ads_left,
.ads_main,
.ads_main_hp,
.ads_media,
.ads_medium,
.ads_medium_rectangle,
.ads_medrect,
.ads_middle,
.ads_middle-container,
.ads_middle_container,
.ads_mobile_vert,
.ads_mpu,
.ads_outer,
.ads_outline,
.ads_place,
.ads_place_160,
.ads_place_top,
.ads_placeholder,
.ads_player,
.ads_post,
.ads_prtext,
.ads_rectangle,
.ads_remove,
.ads_right,
.ads_rightbar_top,
.ads_side,
.ads_sideba,
.ads_sidebar,
.ads_single_center,
.ads_single_side,
.ads_single_top,
.ads_singlepost,
.ads_slice,
.ads_slot,
.ads_small,
.ads_small_rectangle,
.ads_space_long,
.ads_spacer,
.ads_square,
.ads_takeover,
.ads_text,
.ads_tit,
.ads_title,
.ads_top,
.ads_top_1,
.ads_top_banner,
.ads_top_both,
.ads_top_middle,
.ads_top_nav,
.ads_topbanner,
.ads_topleft,
.ads_topright,
.ads_tower,
.ads_tr,
.ads_under_data,
.ads_unit,
.ads_up,
.ads_video,
.ads_wide,
.ads_widesky,
.ads_widget,
.ads_wrap,
.ads_wrap-para,
.ads_wrapper,
.adsafp,
.adsanity-alignnone,
.adsanity-group,
.adsanity-single,
.adsarea,
.adsartical,
.adsbanner1,
.adsbanner2,
.adsbantop,
.adsbar,
.adsbg300,
.adsbillboard,
.adsblock,
.adsblockvert,
.adsbnr,
.adsbody,
.adsborder,
.adsboth,
.adsbottom,
.adsbottombox,
.adsbox--masthead,
.adsbox-square,
.adsbox970x90,
.adsbox990x90,
.adsboxBtn,
.adsbox_300x250,
.adsboxitem,
.adsbx728x90,
.adsbyadop,
.adsbyexoclick,
.adsbyexoclick-wrapper,
.adsbygalaksion,
.adsbygoogle-box,
.adsbygoogle-noablate,
.adsbygoogle-wrapper,
.adsbygoogle2,
.adsbypublift,
.adsbypubmax,
.adsbytrafficjunky,
.adsbyvli,
.adsbyxa,
.adscaleTop,
.adscenter,
.adscentertext,
.adsclick,
.adscontainer,
.adscontent250,
.adscontentcenter,
.adscontntad,
.adscreen,
.adsdelivery,
.adsdesktop,
.adsdiv,
.adsection_a2,
.adsection_c2,
.adsection_c3,
.adsenbox,
.adsens,
.adsense-250,
.adsense-300-600,
.adsense-336,
.adsense-336-280,
.adsense-468,
.adsense-728-90,
.adsense-ad-results,
.adsense-ads,
.adsense-afterpost,
.adsense-area,
.adsense-article,
.adsense-block,
.adsense-box,
.adsense-center,
.adsense-code,
.adsense-container,
.adsense-content,
.adsense-div,
.adsense-float,
.adsense-googleAds,
.adsense-header,
.adsense-heading,
.adsense-iframe-container,
.adsense-inline,
.adsense-left,
.adsense-links,
.adsense-loading,
.adsense-module,
.adsense-overlay,
.adsense-post,
.adsense-resposivo-meio,
.adsense-right,
.adsense-slot,
.adsense-square,
.adsense-sticky-slide,
.adsense-title,
.adsense-top,
.adsense-unit,
.adsense-widget,
.adsense-wrapper,
.adsense1,
.adsense160x600,
.adsense250,
.adsense3,
.adsense300,
.adsense300x250,
.adsense728,
.adsense728x90,
.adsenseAds,
.adsenseBannerArea,
.adsenseBlock,
.adsenseContainer,
.adsenseList,
.adsenseRow,
.adsenseSky,
.adsenseWrapper,
.adsense_200,
.adsense_336_280,
.adsense_728x90_container,
.adsense_ad,
.adsense_block,
.adsense_bottom,
.adsense_container,
.adsense_content_300x250,
.adsense_div_wrapper,
.adsense_inner,
.adsense_label,
.adsense_leader,
.adsense_media,
.adsense_menu,
.adsense_mpu,
.adsense_rectangle,
.adsense_results,
.adsense_right,
.adsense_sidebar,
.adsense_sidebar_top,
.adsense_single,
.adsense_top,
.adsense_top_ad,
.adsense_unit,
.adsense_wrapper,
.adsensebig,
.adsensefloat,
.adsenseformat,
.adsenseframe,
.adsenseleaderboard,
.adsensemobile,
.adsenvelope,
.adsep,
.adserve_728,
.adserverBox,
.adserver_zone,
.adserverad,
.adserving,
.adset,
.adsfloat,
.adsfloatpanel,
.adsforums,
.adsghori,
.adsgrd,
.adsgvert,
.adsheight-250,
.adshome,
.adshowbig,
.adshowcase,
.adshp,
.adside,
.adside-box-index,
.adside-box-single,
.adside_box,
.adsidebar,
.adsidebox,
.adsider,
.adsincs2,
.adsinfo,
.adsingle,
.adsingle-r,
.adsingleph,
.adsitem,
.adsize728,
.adsizer,
.adsizewrapper,
.adskeeperWrap,
.adsky,
.adsleaderboard,
.adsleaderboardbox,
.adsleff,
.adsleft,
.adsleftblock,
.adslibraryArticle,
.adslider,
.adslink,
.adslist,
.adslisting,
.adslisting2,
.adslistingz,
.adsload,
.adsloading,
.adslogan,
.adslot,
.adslot--leaderboard,
.adslot-area,
.adslot-banner,
.adslot-billboard,
.adslot-feature,
.adslot-inline-wide,
.adslot-mpu,
.adslot-rectangle,
.adslot-widget,
.adslot970,
.adslotMid,
.adslot_1,
.adslot_1m,
.adslot_2,
.adslot_2m,
.adslot_3,
.adslot_300,
.adslot_3d,
.adslot_3m,
.adslot_4,
.adslot_728,
.adslot__ad-container,
.adslot__ad-wrapper,
.adslot_blurred,
.adslot_bot_300x250,
.adslot_collapse,
.adslot_popup,
.adslot_side1,
.adslothead,
.adslotleft,
.adslotright,
.adslotright_1,
.adslotright_2,
.adslug,
.adsmaintop,
.adsmall,
.adsmaller,
.adsmalltext,
.adsmanag,
.adsmbody,
.adsmedrect,
.adsmedrectright,
.adsmessage,
.adsmobile,
.adsninja-ad-zone,
.adsninja-ad-zone-container-with-set-height,
.adsninja-rail-zone,
.adsnippet_widget,
.adsns,
.adsntl,
.adsonar-after,
.adsonofftrigger,
.adsoptimal-slot,
.adsother,
.adspace,
.adspace-300x600,
.adspace-336x280,
.adspace-728x90,
.adspace-MR,
.adspace-lb,
.adspace-leaderboard,
.adspace-lr,
.adspace-mpu,
.adspace-mtb,
.adspace-top,
.adspace-widget,
.adspace1,
.adspace180,
.adspace2,
.adspace728x90,
.adspace_2,
.adspace_bottom,
.adspace_buysell,
.adspace_right,
.adspace_rotate,
.adspace_skyscraper,
.adspace_top,
.adspacer,
.adspacer2,
.adspan,
.adspanel,
.adspecial390,
.adspeed,
.adsplash-160x600,
.adsplat,
.adsponsor,
.adspop,
.adspost,
.adspot,
.adspot-desk,
.adspot-title,
.adspot1,
.adspot200x90,
.adspot468x60,
.adspot728x90,
.adspotGrey,
.adspot_468x60,
.adspot_728x90,
.adsprefooter,
.adspreview,
.adsrecnode,
.adsresponsive,
.adsright,
.adss,
.adss-rel,
.adssidebar2,
.adsskyscraper,
.adsslotcustom2,
.adsslotcustom4,
.adssmall,
.adssquare,
.adssquare2,
.adsterra,
.adstext,
.adstextpad,
.adstipt,
.adstitle,
.adstop,
.adstory,
.adstrip,
.adstyle,
.adsverting,
.adsvideo,
.adswallpapr,
.adswidget,
.adswiper,
.adswitch,
.adswordatas,
.adsystem_ad,
.adszone,
.adt-300x250,
.adt-300x600,
.adt-728x90,
.adtab,
.adtable,
.adtag,
.adtc,
.adtech,
.adtech-ad-widget,
.adtech-banner,
.adtech-boxad,
.adtech-copy,
.adtech-video-2,
.adtech-wrapper,
.adtechMobile,
.adtech_wrapper,
.adtester-container,
.adtext-bg,
.adtext_gray,
.adtext_horizontal,
.adtext_onwhite,
.adtext_vertical,
.adtext_white,
.adtextleft,
.adtextright,
.adthrive,
.adthrive-ad,
.adthrive-content,
.adthrive-header,
.adthrive-header-container,
.adthrive-placeholder-content,
.adthrive-placeholder-header,
.adthrive-placeholder-static-sidebar,
.adthrive-placeholder-video,
.adthrive-sidebar,
.adthrive-video-player,
.adthrive_custom_ad,
.adtile,
.adtips,
.adtips1,
.adtitle,
.adtoggle,
.adtop,
.adtop-border,
.adtops,
.adtower,
.adtravel,
.adttl,
.adtxt,
.adtxtlinks,
.adult-adv,
.adun,
.adunit,
.adunit-300-250,
.adunit-active,
.adunit-adbridg,
.adunit-container,
.adunit-container_sitebar_1,
.adunit-googleadmanager,
.adunit-lazy,
.adunit-middle,
.adunit-parent,
.adunit-purch,
.adunit-side,
.adunit-title,
.adunit-top,
.adunit-wrap,
.adunit-wrapper,
.adunit125,
.adunit160,
.adunit300x250,
.adunit468,
.adunitContainer,
.adunit_300x250,
.adunit_728x90,
.adunit_content,
.adunit_footer,
.adunit_leaderboard,
.adunit_rectangle,
.adv--h600,
.adv--square,
.adv-120x600,
.adv-160,
.adv-160x600,
.adv-200-200,
.adv-250-250,
.adv-300,
.adv-300-1,
.adv-300-250,
.adv-300-600,
.adv-300x250,
.adv-300x250-generic,
.adv-336-280,
.adv-4,
.adv-468-60,
.adv-468x60,
.adv-700,
.adv-728,
.adv-728-90,
.adv-970,
.adv-970-250,
.adv-970-250-2,
.adv-980x60,
.adv-ad,
.adv-ads-selfstyle,
.adv-aside,
.adv-background,
.adv-banner,
.adv-bar,
.adv-block,
.adv-block-container,
.adv-border,
.adv-bottom,
.adv-box,
.adv-box-holder,
.adv-box-wrapper,
.adv-carousel,
.adv-center,
.adv-click,
.adv-cont,
.adv-cont1,
.adv-conteiner,
.adv-dvb,
.adv-format-1,
.adv-full-width,
.adv-google,
.adv-gpt-desktop-wrapper,
.adv-gpt-wrapper-desktop,
.adv-halfpage,
.adv-header,
.adv-holder,
.adv-in-body,
.adv-inset,
.adv-intext,
.adv-intext-label,
.adv-key,
.adv-label,
.adv-leaderboard,
.adv-leaderboard-banner,
.adv-link--left,
.adv-link--right,
.adv-mobile-wrapper,
.adv-mpu,
.adv-outer,
.adv-p,
.adv-right,
.adv-right-300,
.adv-rotator,
.adv-script-container,
.adv-sidebar,
.adv-skin-spacer,
.adv-slot-container,
.adv-text,
.adv-top,
.adv-top-banner,
.adv-top-container,
.adv-top-page,
.adv-top-skin,
.adv-under-video,
.adv-unit,
.adv-videoad,
.adv-x61,
.adv1,
.adv120,
.adv200,
.adv250,
.adv300,
.adv300-250,
.adv300-250-2,
.adv300-70,
.adv300left,
.adv300x100,
.adv300x250,
.adv300x60,
.adv300x70,
.adv336,
.adv350,
.adv460x60,
.adv468,
.adv468x90,
.adv728,
.adv728x90,
.advBottom,
.advBottomHome,
.advBox,
.advInt,
.advLeaderboard,
.advRightBig,
.advSquare,
.advText,
.advTop,
.adv_120,
.adv_120_600,
.adv_120x240,
.adv_120x600,
.adv_160_600,
.adv_160x600,
.adv_250,
.adv_250_250,
.adv_300,
.adv_300_300,
.adv_300_top,
.adv_300x250,
.adv_336_280,
.adv_468_60,
.adv_728_90,
.adv_728x90,
.adv__box,
.adv__leaderboard,
.adv__wrapper,
.adv_aff,
.adv_banner,
.adv_banner_hor,
.adv_bg,
.adv_box,
.adv_box_narrow,
.adv_here,
.adv_img,
.adv_leaderboard,
.adv_left,
.adv_link,
.adv_main_middle,
.adv_main_middle_wrapper,
.adv_main_right_down,
.adv_main_right_down_wrapper,
.adv_medium_rectangle,
.adv_message,
.adv_msg,
.adv_outbrain,
.adv_panel,
.adv_right,
.adv_side1,
.adv_side2,
.adv_sidebar,
.adv_title,
.adv_top,
.adv_txt,
.adv_under_menu,
.advads-background,
.advads-close-button,
.advads-parallax-container,
.advads-sticky,
.advads-target,
.advads-widget,
.advads_ad_widget-11,
.advads_ad_widget-18,
.advads_ad_widget-2,
.advads_ad_widget-21,
.advads_ad_widget-3,
.advads_ad_widget-4,
.advads_ad_widget-5,
.advads_ad_widget-8,
.advads_ad_widget-9,
.advads_widget,
.advance-ads,
.advart,
.advbig,
.adver,
.adver-block,
.adver-header,
.adver-left,
.adver-text,
.adverTag,
.adverTxt,
.adver_bot,
.adver_cont_below,
.adver_home,
.advert--background,
.advert--banner-wrap,
.advert--fallback,
.advert--header,
.advert--in-sidebar,
.advert--inline,
.advert--leaderboard,
.advert--loading,
.advert--outer,
.advert--placeholder,
.advert--right-rail,
.advert--square,
.advert-100,
.advert-120x90,
.advert-160x600,
.advert-300,
.advert-300-side,
.advert-728,
.advert-728-90,
.advert-728x90,
.advert-article-bottom,
.advert-autosize,
.advert-background,
.advert-banner,
.advert-banner-container,
.advert-banner-holder,
.advert-bannerad,
.advert-bar,
.advert-bg-250,
.advert-block,
.advert-border,
.advert-bot-box,
.advert-bottom,
.advert-box,
.advert-bronze,
.advert-bronze-btm,
.advert-btm,
.advert-card,
.advert-center,
.advert-col,
.advert-col-center,
.advert-competitions,
.advert-container,
.advert-content,
.advert-content-item,
.advert-detail,
.advert-dfp,
.advert-featured,
.advert-footer,
.advert-gold,
.advert-group,
.advert-head,
.advert-header-728,
.advert-horizontal,
.advert-image,
.advert-info,
.advert-inner,
.advert-label,
.advert-leaderboard,
.advert-leaderboard2,
.advert-loader,
.advert-mini,
.advert-mpu,
.advert-mrec,
.advert-note,
.advert-overlay,
.advert-pane,
.advert-panel,
.advert-placeholder,
.advert-placeholder-wrapper,
.advert-preview-wrapper,
.advert-right,
.advert-row,
.advert-section,
.advert-sidebar,
.advert-silver,
.advert-sky,
.advert-skyright,
.advert-skyscraper,
.advert-slider,
.advert-spot-container,
.advert-sticky-wrapper,
.advert-stub,
.advert-text,
.advert-three,
.advert-title,
.advert-top,
.advert-top-footer,
.advert-txt,
.advert-unit,
.advert-wide,
.advert-wingbanner-left,
.advert-wingbanner-right,
.advert-wrap,
.advert-wrap1,
.advert-wrap2,
.advert-wrapper,
.advert-wrapper-exco,
.advert.box,
.advert.desktop,
.advert.mobile,
.advert.mpu,
.advert.skyscraper,
.advert1,
.advert120,
.advert1Banner,
.advert2,
.advert300,
.advert4,
.advert5,
.advert728_90,
.advert728x90,
.advert8,
.advertBanner,
.advertBar,
.advertBlock,
.advertBottom,
.advertBox,
.advertCaption,
.advertColumn,
.advertCont,
.advertContainer,
.advertDownload,
.advertFullBanner,
.advertHeader,
.advertHeadline,
.advertLink,
.advertLink1,
.advertMPU,
.advertMiddle,
.advertMpu,
.advertRight,
.advertSideBar,
.advertSign,
.advertSlider,
.advertSlot,
.advertSuperBanner,
.advertText,
.advertTitleSky,
.advertWrapper,
.advert_300x250,
.advert_336,
.advert_468x60,
.advert__container,
.advert__fullbanner,
.advert__leaderboard,
.advert__mpu,
.advert__sidebar,
.advert__tagline,
.advert_area,
.advert_banner,
.advert_banners,
.advert_block,
.advert_box,
.advert_caption,
.advert_cont,
.advert_container,
.advert_div,
.advert_foot,
.advert_header,
.advert_home_300,
.advert_img,
.advert_label,
.advert_leaderboard,
.advert_line,
.advert_list,
.advert_main,
.advert_main_bottom,
.advert_mpu,
.advert_nav,
.advert_note,
.advert_pos,
.advert_small,
.advert_span,
.advert_text,
.advert_title,
.advert_top,
.advert_txt,
.advert_wrapper,
.advertbar,
.advertbox,
.adverteaser,
.advertembed,
.adverthome,
.adverticum_container,
.adverticum_content,
.advertis,
.advertis-left,
.advertis-right,
.advertise-1,
.advertise-2,
.advertise-box,
.advertise-here,
.advertise-horz,
.advertise-info,
.advertise-leaderboard,
.advertise-link,
.advertise-list,
.advertise-pic,
.advertise-small,
.advertise-square,
.advertise-top,
.advertise-vert,
.advertiseContainer,
.advertiseHere,
.advertiseText,
.advertise_ads,
.advertise_box,
.advertise_brand,
.advertise_carousel,
.advertise_here,
.advertise_link,
.advertise_link_sidebar,
.advertise_links,
.advertise_sec,
.advertise_text,
.advertise_txt,
.advertise_verRight,
.advertisebtn,
.advertisedBy,
.advertisement-1,
.advertisement-2,
.advertisement-250,
.advertisement-300,
.advertisement-300x250,
.advertisement-background,
.advertisement-banner,
.advertisement-block,
.advertisement-bottom,
.advertisement-box,
.advertisement-card,
.advertisement-cell,
.advertisement-container,
.advertisement-content,
.advertisement-copy,
.advertisement-footer,
.advertisement-google,
.advertisement-header,
.advertisement-holder,
.advertisement-image,
.advertisement-label,
.advertisement-layout,
.advertisement-leaderboard,
.advertisement-leaderboard-lg,
.advertisement-left,
.advertisement-link,
.advertisement-nav,
.advertisement-placeholder,
.advertisement-position1,
.advertisement-right,
.advertisement-sidebar,
.advertisement-space,
.advertisement-sponsor,
.advertisement-tag,
.advertisement-text,
.advertisement-title,
.advertisement-top,
.advertisement-txt,
.advertisement-wrapper,
.advertisement.leaderboard,
.advertisement.rectangle,
.advertisement.under-article,
.advertisement1,
.advertisement300x250,
.advertisement468,
.advertisementBackground,
.advertisementBanner,
.advertisementBar,
.advertisementBlock,
.advertisementBox,
.advertisementBoxBan,
.advertisementContainer,
.advertisementFull,
.advertisementHeader,
.advertisementImg,
.advertisementLabel,
.advertisementPanel,
.advertisementRotate,
.advertisementSection,
.advertisementSmall,
.advertisementText,
.advertisementTop,
.advertisement_160x600,
.advertisement_300x250,
.advertisement_728x90,
.advertisement__header,
.advertisement__label,
.advertisement__leaderboard,
.advertisement__wrapper,
.advertisement_box,
.advertisement_container,
.advertisement_footer,
.advertisement_header,
.advertisement_horizontal,
.advertisement_mobile,
.advertisement_part,
.advertisement_post,
.advertisement_section_top,
.advertisement_text,
.advertisement_top,
.advertisement_wrapper,
.advertisements-link,
.advertisements-right,
.advertisements-sidebar,
.advertisements_heading,
.advertisementwrap,
.advertiser,
.advertiser-links,
.advertising--row,
.advertising--top,
.advertising-banner,
.advertising-block,
.advertising-container,
.advertising-container-top,
.advertising-content,
.advertising-disclaimer,
.advertising-fixed,
.advertising-header,
.advertising-iframe,
.advertising-inner,
.advertising-leaderboard,
.advertising-lrec,
.advertising-mediumrectangle,
.advertising-mention,
.advertising-middle,
.advertising-middle-i,
.advertising-notice,
.advertising-right,
.advertising-right-d,
.advertising-right-i,
.advertising-section,
.advertising-side,
.advertising-side-hp,
.advertising-srec,
.advertising-top,
.advertising-top-banner,
.advertising-top-box,
.advertising-top-category,
.advertising-top-desktop,
.advertising-vert,
.advertising-wrapper,
.advertising1,
.advertising160,
.advertising2,
.advertising300_home,
.advertising300x250,
.advertising728,
.advertising728_3,
.advertisingBanner,
.advertisingBlock,
.advertisingLabel,
.advertisingLegend,
.advertisingLrec,
.advertisingMob,
.advertisingRight,
.advertisingSlide,
.advertisingTable,
.advertisingTop,
.advertising_300x250,
.advertising_banner,
.advertising_block,
.advertising_bottom_box,
.advertising_box_bg,
.advertising_header_1,
.advertising_hibu_lef,
.advertising_hibu_mid,
.advertising_hibu_rig,
.advertising_horizontal_title,
.advertising_images,
.advertising_square,
.advertising_top,
.advertising_vertical_title,
.advertising_widget,
.advertising_wrapper,
.advertisingarea,
.advertisingarea-homepage,
.advertisingimage,
.advertisingimage-extended,
.advertisingimageextended,
.advertisment,
.advertisment-banner,
.advertisment-label,
.advertisment-left-panal,
.advertisment-module,
.advertisment-rth,
.advertisment-top,
.advertismentBox,
.advertismentContainer,
.advertismentContent,
.advertismentText,
.advertisment_bar,
.advertisment_caption,
.advertisment_full,
.advertisment_notice,
.advertisment_two,
.advertize,
.advertize_here,
.advertizing-banner,
.advertlabel,
.advertleft,
.advertlink,
.advertnotice,
.advertop,
.advertorial,
.advertorial-2,
.advertorial-block,
.advertorial-image,
.advertorial-promo-box,
.advertorial-teaser,
.advertorial-wrapper,
.advertorial2,
.advertorial_728x90,
.advertorial_red,
.advertorialitem,
.advertorialtitle,
.advertorialview,
.advertorialwidget,
.advertouter,
.advertplay,
.adverts,
.adverts--banner,
.adverts-125,
.adverts-inline,
.adverts2,
.advertsLeaderboard,
.adverts_RHS,
.adverts_footer_advert,
.adverts_footer_scrolling_advert,
.adverts_header_advert,
.adverts_side_advert,
.advertspace,
.adverttext,
.adverttop,
.advfrm,
.advg468,
.advhere,
.adviewDFPBanner,
.advimg160600,
.advimg300250,
.advn_zone,
.advoice,
.advr,
.advr-wrapper,
.advr_top,
.advrectangle,
.advrst,
.advslideshow,
.advspot,
.advt,
.advt-banner-3,
.advt-block,
.advt-right,
.advt-sec,
.advt300,
.advt720,
.advtBlock,
.advtMsg,
.advt_160x600,
.advt_468by60px,
.advt_indieclick,
.advt_single,
.advt_widget,
.advtbox,
.advtcell,
.advtext,
.advtimg,
.advtitle,
.advtop,
.advtop-leaderbord,
.advttopleft,
.advv_box,
.adwblue,
.adwert,
.adwhitespace,
.adwide,
.adwideskyright,
.adwidget,
.adwithspace,
.adwobs,
.adwolf-holder,
.adword-box,
.adword-structure,
.adword-text,
.adword-title,
.adword1,
.adwordListings,
.adwords,
.adwords-container,
.adwordsHeader,
.adwords_in_content,
.adworks,
.adwrap,
.adwrap-mrec,
.adwrap-widget,
.adwrap_MPU,
.adwrapper--desktop,
.adwrapper-lrec,
.adwrapper1,
.adwrapper948,
.adwrappercls,
.adwrappercls1,
.adx-300x250-container,
.adx-300x600-container,
.adx-ads,
.adx-wrapper,
.adx-wrapper-middle,
.adx_center,
.adxli,
.adz-horiz,
.adz-horiz-ext,
.adz2,
.adz728x90,
.adzbanner,
.adzone,
.adzone-footer,
.adzone-preview,
.adzone-sidebar,
.adzone_skyscraper,
.ae-player__itv,
.af-block-ad-wrapper,
.af-label-ads,
.afc-box,
.aff-big-unit,
.aff-iframe,
.afffix-custom-ad,
.affiliate-ad,
.affiliate-footer,
.affiliate-link,
.affiliate-sidebar,
.affiliate-strip,
.affiliateAdvertText,
.affiliate_ad,
.affiliate_header_ads,
.after-content-ad,
.after-intro-ad,
.after-post-ad,
.after-post-ads,
.after-story-ad-wrapper,
.after_ad,
.after_comments_ads,
.after_content_banner_advert,
.after_post_ad,
.afw_ad,
.aggads-ad,
.ahe-ad,
.ai-top-ad-outer,
.aisle-ad,
.ajax_ad,
.ajaxads,
.ajdg_bnnrwidgets,
.ajdg_grpwidgets,
.alice-adslot,
.alice-root-header-ads__ad--top,
.align.Ad,
.alignads,
.alt_ad,
.alt_ad_block,
.altad,
.am-adContainer,
.am-adslot,
.am-bazaar-ad,
.amAdvert,
.am_ads,
.amazon-auto-links,
.amazon_ad,
.amazonads,
.ami-video-wrapper,
.amp-ad,
.amp-ad-container,
.amp-ad__wrapper,
.amp-ads,
.amp-ads-container,
.amp-adv-container,
.amp-adv-wrapper,
.amp-article-ad-element,
.amp-flying-carpet-text-border,
.amp-sticky-ad-custom,
.amp-sticky-ads,
.amp-unresolved,
.ampFlyAdd,
.amp_ad_1,
.amp_ad_header,
.amp_ad_wrapper,
.ampad,
.ampexcoVideoPlayer,
.ampforwp-sticky-custom-ad,
.anchor-ad,
.anchor-ad-wrapper,
.anchorAd,
.anchored-ad-widget,
.aniview-inline-player,
.annonstext,
.anyClipWrapper,
.anyad,
.anzeige_banner,
.aoa_overlay,
.ap-ad-block,
.ape-ads-container,
.apexAd,
.apiAds,
.aplvideo,
.app-ad,
.app_ad_unit,
.app_advertising_skyscraper,
.app_nexus_banners_common,
.ar-header-m-ad,
.arc-ad-wrapper,
.arcAdsBox,
.arcAdsContainer,
.arcad-block-container,
.archive-ad,
.archive-ads,
.archive-radio-ad-container,
.areaAd,
.area_ad,
.area_ad03,
.area_ad07,
.area_ad09,
.area_ad2,
.arena-ad-col,
.art-text-ad,
.artAd,
.artAdInner,
.art_ads,
.artcl_ad_dsk,
.article--ad,
.article--content-ad,
.article-ad,
.article-ad-align-left,
.article-ad-blk,
.article-ad-bottom,
.article-ad-box,
.article-ad-cont,
.article-ad-container,
.article-ad-holder,
.article-ad-horizontal,
.article-ad-left,
.article-ad-legend,
.article-ad-main,
.article-ad-placeholder,
.article-ad-placement,
.article-ad-primary,
.article-ad-row,
.article-ad-row-inner,
.article-ad-section,
.article-ads,
.article-advert,
.article-advert--text,
.article-advert-container,
.article-advert-dfp,
.article-aside-ad,
.article-aside-top-ad,
.article-connatix-wrap,
.article-content-ad,
.article-content-adwrap,
.article-detail-ad,
.article-first-ad,
.article-footer-ad,
.article-footer-ad-container,
.article-footer__ad,
.article-footer__ads,
.article-header-ad,
.article-header__railAd,
.article-inline-ad,
.article-mid-ad,
.article-small-ads,
.article-sponsor,
.article-sponsorship-header,
.article-taboola,
.article-top-ad,
.articleADbox,
.articleAd,
.articleAdHeader,
.articleAdTopRight,
.articleAds,
.articleAdsL,
.articleAdvert,
.articleBottom-ads,
.articleEmbeddedAdBox,
.articleFooterAd,
.articleHeaderAd,
.articleTop-ads,
.articleTopAd,
.article_OutbrainContent,
.article__ad-holder,
.article__adblock,
.article__adhesion,
.article__adv,
.article_ad,
.article_ad_1,
.article_ad_2,
.article_ad_text,
.article_ad_top,
.article_adbox,
.article_ads_banner,
.article_bottom-ads,
.article_bottom_ad,
.article_google-ad,
.article_google_ads,
.article_inline_ad,
.article_inner_ad,
.article_mpu,
.article_tower_ad,
.articlead,
.articleads,
.articles-ad-block,
.artnet-ads-ad,
.aside-ad,
.aside-ad-space,
.aside-ad-wrapper,
.aside-ads,
.aside-ads-top,
.asideAd,
.aside_ad,
.aside_ad_large,
.aspace-300x169,
.aspace-300x250,
.async-ad-container,
.at-header-ad,
.at-sidebar-ad,
.atf-ad,
.atfAds,
.atf_adWrapper,
.atomsAdsCellModel,
.attachment-advert_home,
.attachment-dm-advert-bronze,
.attachment-dm-advert-gold,
.attachment-dm-advert-silver,
.attachment-sidebar-ad,
.attachment-squareAd,
.avadvslot,
.avap-ads-container,
.avert--leaderboard,
.avert--sidebar,
.avert-text,
.avp-p-wrapper,
.azk-adsense,
.b-ad,
.b-ad-main,
.b-adhesion,
.b-adv,
.b-advert,
.b-advertising__down-menu,
.b-aside-ads,
.b-header-ad,
.b-right-rail--ads,
.bAdvertisement,
.b_adLastChild,
.b_ads,
.b_ads_cont,
.b_ads_r,
.b_ads_top,
.background-ad,
.background-ads,
.background-adv,
.backgroundAd,
.bam-ad-slot,
.bank-rate-ad,
.banmanad,
.banner--ad,
.banner-125,
.banner-300,
.banner-300-100,
.banner-300-250,
.banner-300x250,
.banner-300x600,
.banner-320-100,
.banner-468,
.banner-468-60,
.banner-468x60,
.banner-728,
.banner-728x90,
.banner-ad,
.banner-ad-b,
.banner-ad-below,
.banner-ad-block,
.banner-ad-bottom-fixed,
.banner-ad-container,
.banner-ad-contianer,
.banner-ad-footer,
.banner-ad-image,
.banner-ad-inner,
.banner-ad-label,
.banner-ad-large,
.banner-ad-pos,
.banner-ad-row,
.banner-ad-skeleton-box,
.banner-ad-space,
.banner-ad-wrap,
.banner-ad-wrapper,
.banner-ad2,
.banner-ads-right,
.banner-ads-sidebar,
.banner-adsense,
.banner-adv,
.banner-advert,
.banner-advert-wrapper,
.banner-advertisement,
.banner-advertising,
.banner-adverts,
.banner-asd__title,
.banner-buysellads,
.banner-sponsorship,
.banner-top-ads,
.banner120x600,
.banner160,
.banner160x600,
.banner200x200,
.banner300,
.banner300x250,
.banner336,
.banner336x280,
.banner350,
.banner468,
.banner728,
.banner728-ad,
.banner728-container,
.banner728x90,
.bannerADS,
.bannerADV,
.bannerAd,
.bannerAd-module,
.bannerAd3,
.bannerAdContainer,
.bannerAdLeaderboard,
.bannerAdRectangle,
.bannerAdSearch,
.bannerAdSidebar,
.bannerAdTower,
.bannerAdWrap,
.bannerAds,
.bannerAdvert,
.bannerAside,
.bannerGoogle,
.bannerRightAd,
.banner_160x600,
.banner_240x400,
.banner_250x250,
.banner_300_250,
.banner_300x250,
.banner_300x600,
.banner_468_60,
.banner_468x60,
.banner_728_90,
.banner_ad-728x90,
.banner_ad_300x250,
.banner_ad_728x90,
.banner_ad_container,
.banner_ad_footer,
.banner_ad_full,
.banner_ad_leaderboard,
.banner_ad_link,
.banner_ad_wrapper,
.banner_ads1,
.banner_reklam,
.banner_reklam2,
.banner_slot,
.bannerad,
.bannerad3,
.banneradd,
.bannerads,
.banneradv,
.bannerandads,
.bannergroup-ads,
.bannermpu,
.banners_ad,
.bannervcms,
.bar_ad,
.base-ad-mpu,
.base-ad-slot,
.base-ad-top,
.base_ad,
.baseboard-ad,
.bb-ad,
.bb-ad-mrec,
.bbccom-advert,
.bbccom_advert,
.bcom_ad,
.before-header-ad,
.before-injected-ad,
.below-ad-border,
.below-article-ad-sidebar,
.below-nav-ad,
.belowMastheadWrapper,
.belowNavAds,
.below_game_ad,
.below_nav_ad_wrap,
.below_player_ad,
.bg-ad-gray,
.bg-ads,
.bg-ads-space,
.bg-grey-ad,
.bgAdBlue,
.bg_ad,
.bg_ads,
.bgcolor_ad,
.bgr-ad-leaderboard,
.bh-ads,
.bh_ad_container,
.bidbarrel-ad,
.big-ad,
.big-ads,
.big-advertisement,
.big-box-ad,
.big-right-ad,
.bigAd,
.bigAdContainer,
.bigAds,
.bigAdvBanner,
.bigBoxAdArea,
.bigCubeAd,
.big_ad,
.big_ad2,
.big_ads,
.bigad,
.bigad1,
.bigad2,
.bigadleft,
.bigadright,
.bigads,
.bigadtxt1,
.bigbox-ad,
.bigbox.ad,
.bigbox_ad,
.bigboxad,
.bigsponsor,
.billboard-ad,
.billboard-ad-one,
.billboard-ad-space,
.billboard-ads,
.billboard.ad,
.billboardAd,
.billboard__advert,
.billboard_ad,
.billboard_ad_wrap,
.billboard_adwrap,
.bing-ads-wrapper,
.bing-native-ad,
.bl300_ad,
.block--ad,
.block--ads,
.block--dfp,
.block--doubleclick,
.block--simpleads,
.block-ad,
.block-ad-entity,
.block-ad-header,
.block-ad-leaderboard,
.block-ad-wrapper,
.block-admanager,
.block-ads,
.block-ads-bottom,
.block-ads-home,
.block-ads-system,
.block-ads-top,
.block-ads-yahoo,
.block-ads1,
.block-ads2,
.block-ads3,
.block-ads_top,
.block-adsense,
.block-adtech,
.block-adv,
.block-advert,
.block-advertisement,
.block-advertisement-banner-block,
.block-advertising,
.block-adzerk,
.block-bg-advertisement,
.block-boxes-ad,
.block-cdw-google-ads,
.block-dfp,
.block-dfp-ad,
.block-dfp-blocks,
.block-doubleclick_ads,
.block-fusion-ads,
.block-google-admanager,
.block-openads,
.block-openx,
.block-quartz-ads,
.block-reklama,
.block-simpleads,
.block-skyscraper-ad,
.block-sponsor,
.block-sponsored-links,
.block-the-dfp,
.block-wrap-ad,
.block-yt-ads,
.blockAd,
.blockAds,
.blockAdvertise,
.block__ads__ad,
.block_ad,
.block_ad1,
.block_ad303x1000_left,
.block_ad303x1000_right,
.block_ad_middle,
.block_ad_top,
.block_ads,
.block_adslot,
.block_adv,
.block_advert,
.block_article_ad,
.blockad,
.blocked-ads,
.blog-ad,
.blog-ad-image,
.blog-ads,
.blog-advertisement,
.blogAd,
.blogAdvertisement,
.blog_ad,
.blogads,
.bmd_advert,
.bn_ads,
.bnr_ad,
.body-ad,
.body-ads,
.body-top-ads,
.bodyAd,
.body_ad,
.bodyads,
.bodyads2,
.bordered-ad,
.botAd,
.bot_ad,
.bot_ads,
.bottom-ad,
.bottom-ad--bigbox,
.bottom-ad-banner,
.bottom-ad-box,
.bottom-ad-container,
.bottom-ad-desktop,
.bottom-ad-large,
.bottom-ad-placeholder,
.bottom-ad-wrapper,
.bottom-ad-zone,
.bottom-ad2,
.bottom-ads,
.bottom-ads-container,
.bottom-ads-sticky,
.bottom-ads-wrapper,
.bottom-adv,
.bottom-adv-container,
.bottom-banner-ad,
.bottom-fixed-ad,
.bottom-left-ad,
.bottom-main-adsense,
.bottom-mobile-ad,
.bottom-mpu-ad,
.bottom-post-ad-space,
.bottom-post-ads,
.bottom-right-advert,
.bottom-side-advertisement,
.bottomAd,
.bottomAdBlock,
.bottomAdContainer,
.bottomAds,
.bottomAdvert,
.bottomAdvertisement,
.bottom_ad,
.bottom_ad_block,
.bottom_ad_placeholder,
.bottom_ad_responsive,
.bottom_ads,
.bottom_adsense,
.bottom_adspace,
.bottom_banner_ad,
.bottom_banner_advert_text,
.bottom_bar_ads,
.bottom_left_advert,
.bottom_right_ad,
.bottom_rightad,
.bottom_side_ad,
.bottom_sponsor,
.bottom_sticky_ad,
.bottomad,
.bottomads,
.bottomadvert,
.botton_advertisement,
.box-ad,
.box-ad-middle,
.box-ads,
.box-adsense,
.box-adsense-top,
.box-advert,
.box-advertisement,
.box-advertising,
.box-adverts,
.box-entry-ad,
.box-fixed-ads,
.box-footer-ad,
.box-outbrain,
.boxAd,
.boxAdContainer,
.boxAds,
.boxAds2,
.boxAdvertisement,
.boxOverContent__banner,
.boxSponsor,
.box_ad,
.box_ad_container,
.box_ad_content,
.box_ad_horizontal,
.box_ad_spacer,
.box_ad_wrap,
.box_ads,
.box_adv,
.box_adv_728,
.box_advert,
.box_advertising,
.box_content_ad,
.box_content_ads,
.box_layout_ad,
.box_publicidad,
.box_sidebar-ads,
.boxad,
.boxad1,
.boxad2,
.boxadcont,
.boxads,
.boxadv,
.bps-ad-wrapper,
.bps-advertisement,
.bq_adleaderboard,
.bq_rightAd,
.br-ad,
.br-ad-wrapper,
.brandpost_inarticle,
.breadads,
.break-ads,
.breaker-ad,
.breakerAd,
.briefNewsAd,
.brn-ads-box,
.brn-ads-mobile-container,
.brn-ads-sticky-wrapper,
.broker-ad,
.browse-ad-container,
.browsi-ad,
.btm_ad,
.btn_ad,
.bump-ad,
.bunyad-ad,
.buttom_ad,
.buttom_ad_size,
.button-ad,
.button-ads,
.buttonAd,
.buttonAdSpot,
.buttonAds,
.button_ad,
.button_ads,
.button_advert,
.button_left_ad,
.button_right_ad,
.buttonad,
.buttonadbox,
.buttonads,
.buySellAdsContainer,
.buysellAds,
.buzzAd,
.c-Ad,
.c-Adhesion,
.c-ArticleAds,
.c-ad,
.c-ad--adStickyContainer,
.c-ad--bigbox,
.c-ad--header,
.c-ad-flex,
.c-ad-fluid,
.c-ad-placeholder,
.c-ad-size2,
.c-ad-size3,
.c-adDisplay,
.c-adDisplay_container,
.c-adOmnibar,
.c-adSense,
.c-adSkyBox,
.c-adbutler-ad,
.c-adbutler-ad__wrapper,
.c-adcontainer,
.c-ads,
.c-adunit,
.c-adunit--billboard,
.c-adunit--first,
.c-adunit__container,
.c-adv3__inner,
.c-advert,
.c-advert-app,
.c-advert-superbanner,
.c-advertisement,
.c-advertisement--billboard,
.c-advertisement--rectangle,
.c-advertising,
.c-advertising__banner-area,
.c-adverts,
.c-advscrollingzone,
.c-box--advert,
.c-gallery-vertical__advert,
.c-googleadslot,
.c-gpt-ad,
.c-header__ad,
.c-header__advert-container,
.c-pageArticleSingle_bottomAd,
.c-prebid,
.c-sidebar-ad-stream__ad,
.c-sitenav-adslot,
.c-sitenavPlaceholder__ad,
.c2_outbrain,
.c_nt_ad,
.cableads,
.cactus-ads,
.cactus-header-ads,
.caja_ad,
.california-ad,
.california-sidebar-ad,
.calloutAd,
.carbon-ad,
.carbon_ads,
.carbonad,
.carbonad-tag,
.carbonads-widget,
.card--ad,
.card--article-ad,
.card-ad,
.card-ads,
.card-article-ads,
.cardAd,
.catalog_ads,
.category-ad:not(html):not(body):not(.post),
.category-ads:not(html):not(body):not(.post),
.categoryMosaic-advertising,
.categoryMosaic-advertisingText,
.cazAd,
.cb-ad-banner,
.cb-ad-container,
.cbd_ad_manager,
.cbs-ad,
.cc-advert,
.center-ad,
.center-ad-long,
.center-tag-rightad,
.centerAD,
.centerAd,
.centerAds,
.center_ad,
.center_add,
.center_ads,
.center_inline_ad,
.centerad,
.centerads,
.centeradv,
.centered-ad,
.ch-ad-item,
.channel--ad,
.channel-ad,
.channel-adv,
.channel-icon--ad,
.channel-icon__ad-buffer,
.channel-sidebar-big-box-ad,
.channelBoxAds,
.channel_ad_2016,
.chapter-bottom-ads,
.chapter-top-ads,
.chart_ads,
.chitika-ad,
.ck-anyclips,
.ck-anyclips-article,
.cl-ad-billboard,
.clAdPlacementAnchorWrapper,
.clever-core-ads,
.click-track.partner,
.clickforceads,
.clickio-side-ad,
.client-ad,
.clsy-c-advsection,
.cms-ad,
.cn-advertising,
.cnbcHeaderAd,
.cnc-ads,
.cnx-player,
.cnx-player-wrapper,
.coinzilla-ad,
.coinzilla-ad--mobile,
.col-ad,
.col-ad-hidden,
.col-has-ad,
.col-line-ad,
.col2-ads,
.colAd,
.colBoxAdframe,
.colBoxDisplayAd,
.col_ad,
.colads,
.collapsed-ad,
.colombiaAd,
.column-ad,
.columnAd,
.columnAdvert,
.columnBoxAd,
.columnRightAdvert,
.combinationAd,
.comment-ad,
.comment-ad-wrap,
.comment-advertisement,
.comment_ad,
.comment_ad_box,
.commercialAd,
.companion-ad,
.companion-ads,
.companionAd,
.companion_ad,
.complex-ad,
.component-ar-horizontal-bar-ad,
.component-header-sticky-ad,
.component-outbrain,
.components-Ad-___Ad__ad,
.con_ads,
.connatix,
.connatix-container,
.connatix-hodler,
.connatix-holder,
.connatix-main-container,
.connatix-wrapper,
.connatix-wysiwyg-container,
.consoleAd,
.cont-ad,
.container--ad,
.container--ads,
.container--ads-leaderboard-atf,
.container--advert,
.container--bannerAd,
.container-ad-600,
.container-ad-left,
.container-adds,
.container-adrotate,
.container-ads,
.container-adwords,
.container-banner-ads,
.container-bottom-ad,
.container-content__container-relatedlinks,
.container-first-ads,
.container-lower-ad,
.container-rectangle-ad,
.container-top-adv,
.containerAdsense,
.containerSqAd,
.container__ad,
.container__box--ads,
.container_ad,
.container_ad_v,
.container_publicidad,
.containerads,
.contains-ad,
.contains-advertisment,
.content--right-ads,
.content-ad,
.content-ad-article,
.content-ad-box,
.content-ad-container,
.content-ad-left,
.content-ad-right,
.content-ad-side,
.content-ad-widget,
.content-ad-wrapper,
.content-ads,
.content-ads-bottom,
.content-advert,
.content-advertisment,
.content-bottom-ad,
.content-bottom-mpu,
.content-cliff__ad,
.content-cliff__ad-container,
.content-contentad,
.content-footer-ad,
.content-footer-ad-block,
.content-header-ad,
.content-item-ad-top,
.content-kuss-ads,
.content-leaderboard-ad,
.content-leaderboard-ads,
.content-page-ad_wrap,
.content-result-ads,
.content-top-ad-item,
.content1-ad,
.content2-ad,
.contentAd,
.contentAd--sb1,
.contentAdBox,
.contentAdContainer,
.contentAdFoot,
.contentAdIndex,
.contentAds,
.contentAdsCommon,
.contentAdsWrapper,
.contentAdvertisement,
.contentTopAd,
.contentTopAdSmall,
.contentTopAds,
.content__ad,
.content__ad__content,
.content_ad,
.content_ad_728,
.content_ad_head,
.content_ad_side,
.content_ads,
.content_adsense,
.content_adsq,
.content_advert,
.content_advertising,
.content_advt,
.content_bottom_adsense,
.content_gpt_top_ads,
.content_inner_ad,
.content_left_advert,
.contentad,
.contentad-end,
.contentad-home,
.contentad-storyad-1,
.contentad-superbanner-2,
.contentad-top,
.contentad2,
.contentad300x250,
.contentad_right_col,
.contentadarticle,
.contentadfloatl,
.contentadleft,
.contentads1,
.contentads2,
.contentbox_ad,
.contentleftad,
.contents-ads-bottom-left,
.contest_ad,
.context-ads,
.contextualAds,
.contextual_ad_unit,
.coreAdsPlacer,
.cornerad,
.cpmstarHeadline,
.cpmstarText,
.crain-advertisement,
.criteo-ad,
.crm-adcontain,
.crumb-ad,
.cspAd,
.css--ad,
.ct-ads,
.ct-advert,
.ct-advertising-footer,
.ct-bottom-ads,
.ct_ad,
.ct_ampad,
.cta-ad,
.cube-ad,
.cubeAd,
.cube_ad,
.cube_ads,
.custom-ad,
.custom-ad-area,
.custom-ad-container,
.custom-ads,
.custom-advert-banner,
.custom-sticky-ad-container,
.customAd,
.custom_ad,
.custom_ad_responsive,
.custom_ads,
.custom_ads_positions,
.custom_banner_ad,
.custom_footer_ad,
.customadvert,
.customized_ad_module,
.cwAdvert,
.cxAdvertisement,
.d1-top-ad,
.d3-c-adblock,
.d3-o-adv-block,
.da-custom-ad-box,
.dac__banner__wrapper,
.dac__mpu-card,
.daily-adlabel,
.dart-ad,
.dart-ad-content,
.dart-ad-grid,
.dart-ad-title,
.dart-advertisement,
.dart-leaderboard,
.dart-leaderboard-top,
.dartAdImage,
.dart_ad,
.dart_tag,
.dartad,
.dartadbanner,
.dartadvert,
.dartiframe,
.dc-ad,
.dc-banner,
.dc-half-banner,
.dc-widget-adv-125,
.dcmads,
.dd-ad,
.dd-ad-container,
.deckAd,
.deckads,
.default_rc_theme,
.demand-supply,
.desktop-ad,
.desktop-ad-banner,
.desktop-ad-container,
.desktop-ad-inpage,
.desktop-ad-slider,
.desktop-ads,
.desktop-adunit,
.desktop-advert,
.desktop-article-top-ad,
.desktop-aside-ad-hide,
.desktop-lazy-ads,
.desktop-sidebar-ad-wrapper,
.desktop-top-ad-wrapper,
.desktop.ad,
.desktopAd,
.desktop_ad,
.desktop_mpu,
.desktop_only_ad,
.desktopads,
.detail-ad,
.detail-ads,
.detail__ad--small,
.detail_ad,
.detail_article_ad,
.detail_top_advert,
.details-advert,
.dfm-featured-bottom-flex-container,
.dfp-ad,
.dfp-ad-bigbox2-wrap,
.dfp-ad-container,
.dfp-ad-container-box,
.dfp-ad-container-wide,
.dfp-ad-full,
.dfp-ad-hideempty,
.dfp-ad-lazy,
.dfp-ad-lead2-wrap,
.dfp-ad-lead3-wrap,
.dfp-ad-midbreaker-wrap,
.dfp-ad-midbreaker2-wrap,
.dfp-ad-placeholder,
.dfp-ad-rect,
.dfp-ad-region-1,
.dfp-ad-region-2,
.dfp-ad-tags,
.dfp-ad-top-wrapper,
.dfp-ad-unit,
.dfp-ad-widget,
.dfp-ads-ad-article-middle,
.dfp-ads-embedded,
.dfp-adspot,
.dfp-article-ad,
.dfp-banner,
.dfp-banner-slot,
.dfp-billboard-wrapper,
.dfp-block,
.dfp-bottom,
.dfp-button,
.dfp-close-ad,
.dfp-double-mpu,
.dfp-dynamic-tag,
.dfp-fixedbar,
.dfp-here-bottom,
.dfp-here-top,
.dfp-interstitial,
.dfp-leaderboard,
.dfp-leaderboard-container,
.dfp-mrec,
.dfp-panel,
.dfp-plugin-advert,
.dfp-position,
.dfp-slot,
.dfp-slot-wallpaper,
.dfp-space,
.dfp-super-leaderboard,
.dfp-tag-wrapper,
.dfp-top,
.dfp-top1,
.dfp-top1-container,
.dfp-top_leaderboard,
.dfp-wrap,
.dfp-wrapper,
.dfpAd,
.dfpAdUnitContainer,
.dfpAds,
.dfpAdspot,
.dfpAdvert,
.dfp_ATF_wrapper,
.dfp_ad--outbrain,
.dfp_ad_block,
.dfp_ad_caption,
.dfp_ad_content_bottom,
.dfp_ad_content_top,
.dfp_ad_footer,
.dfp_ad_header,
.dfp_ad_pos,
.dfp_ad_unit,
.dfp_ads_block,
.dfp_frame,
.dfp_slot,
.dfp_strip,
.dfp_top-ad,
.dfp_txt,
.dfp_unit,
.dfp_unit--interscroller,
.dfp_unit-ad_container,
.dfpad,
.dfrads,
.dfx-ad,
.dfx-adBlock1Wrapper,
.dg-gpt-ad-container,
.dianomi-ad,
.dianomi-container,
.dianomi-embed,
.dianomiScriptContainer,
.dianomi_context,
.dikr-responsive-ads-slot,
.discourse-adplugin,
.discourse-google-dfp,
.display-ad,
.display-ad-block,
.display-adhorizontal,
.display-ads-block,
.display-advertisement,
.displayAd,
.displayAdCode,
.displayAdSlot,
.displayAdUnit,
.displayAds,
.display_ad,
.display_ads_right,
.div-gpt-ad-adhesion-leaderboard-wrap,
.div-insticator-ad,
.divAd,
.divAdright,
.divAds,
.divAdsBanner,
.divAdsLeft,
.divAdsRight,
.divReklama,
.divRepAd,
.divSponsoredBox {
display: none !important;
}


.divSponsoredLinks,
.divTopADBanner,
.divTopADBannerWapper,
.divTopArticleAd,
.div_advertisement,
.divad1,
.divad2,
.divad3,
.divads,
.divider-ad,
.divider-advert,
.divider-full-width-ad,
.divider-taboola,
.divider_ad,
.dlSponsoredLinks,
.dm-adSlotBillboard,
.dm-adSlotNative1,
.dm-adSlotNative2,
.dm-adSlotNative3,
.dm-adSlotRectangle1,
.dm-adSlotRectangle2,
.dm-adSlotSkyscraper,
.dm-adSlot__sticky,
.dm_ad-billboard,
.dm_ad-container,
.dm_ad-halfpage,
.dm_ad-leaderboard,
.dm_ad-link,
.dm_ad-skyscraper,
.dmpu-ad,
.dn-ad-wide,
.dotcom-ad,
.double-ad,
.double-ads,
.doubleClickAd,
.doubleclickAds,
.download-ad,
.downloadAds,
.download_ad,
.dsk-box-ad-d,
.dsq_ad,
.dt-sponsor,
.dtads-desktop,
.dtads-slot,
.dual-ads,
.dualAds,
.dyn-sidebar-ad,
.dynamic-ads,
.dynamicAdvertContainer,
.dynamicLeadAd,
.dynamic_adslot,
.dynamicad1,
.dynamicad2,
.e-ad,
.e-advertise,
.e3lan,
.e3lan-top,
.e3lan-widget-content,
.e3lan300-100,
.e3lan300-250,
.e3lan300_250-widget,
.eaa-ad,
.eads,
.easy-ads,
.easyAdsBox,
.easyAdsSinglePosition,
.ebayads,
.ebm-ad-target__outer,
.ecommerce-ad,
.ecosia-ads,
.eddy-adunit,
.editor_ad,
.eg-ad,
.eg-custom-ad,
.element--ad,
.element-ad,
.element-adplace,
.element_contentad1,
.element_contentad2,
.element_contentad3,
.element_contentad4,
.element_contentad5,
.elementor-widget-wp-widget-advads_ad_widget,
.embAD,
.embed-ad,
.embedded-article-ad,
.embeddedAd,
.embeddedAds,
.embedded_ad_wrapper,
.empire-unit-prefill-container,
.empty-ad,
.endAHolder,
.endti-adlabel,
.entry-ad,
.entry-ads,
.entry-bottom-ad,
.entry-bottom-ads,
.entry-top-ad,
.entryAd,
.entry_ad,
.entryad,
.etn-ad-text,
.eu-advertisment1,
.evo-ads-widget,
.evolve-ad,
.ex_pu_iframe,
.exco-container,
.exo_wrapper,
.external-ad,
.external-add,
.ez-sidebar-wall-ad,
.ez-video-wrap,
.ezAdsWidget,
.ezmob-footer,
.ezmob-footer-desktop,
.ezo_ad,
.ezoic-ad,
.ezoic-ad-adaptive,
.ezoic-adpicker-ad,
.ezoic-floating-bottom,
.f-ad,
.f-item-ad,
.f-item-ad-inhouse,
.fbs-ad--ntv-home-wrapper,
.fbs-ad--top-wrapper,
.fbs-ad--topx-wrapper,
.fc_clmb_ad,
.fce_ads,
.featureAd,
.feature_ad,
.featured-ad,
.featured-ads,
.featured-sponsors,
.featured-story-ad,
.featuredAdBox,
.featuredAds,
.featuredBoxAD,
.featured_ad,
.featuredadvertising,
.feed-ad,
.feed-ad-wrapper,
.fh_ad_microbuttons,
.field-59-companion-ad,
.fig-ad-content,
.first-article-ad-block,
.first-banner-ad,
.first-leaderbord-adv,
.first-leaderbord-adv-mobile,
.firstAd-container,
.first_ad,
.first_party_ad_wrapper,
.first_post_ad,
.firstad,
.firstpost_advert,
.firstpost_advert_container,
.fix_ad,
.fixadheight,
.fixadheightbottom,
.fixed-ad-aside,
.fixed-ad-bottom,
.fixed-ads,
.fixed-bottom-ad,
.fixed-sidebar-ad,
.fixedAds,
.fixedLeftAd,
.fixedRightAd,
.fixed_ad,
.fixed_adslot,
.fixed_advert_banner,
.fjs-ad-hide-empty,
.fla-ad,
.flashAd,
.flash_ad,
.flash_advert,
.flashad,
.flashadd,
.flex-ad,
.flex-posts-ads,
.flexAd,
.flexAds,
.flexContentAd,
.flexad,
.flexadvert,
.flexiad,
.flm-ad,
.floatad,
.floatads,
.floated-ad,
.floated_right_ad,
.floating-ads,
.floating-advert,
.floatingAds,
.fly-ad,
.fm-badge-ad,
.fnadvert,
.fns_td_wrap,
.fold-ads,
.follower-ad-bottom,
.following-ad,
.following-ad-container,
.foot-ad,
.foot-ads,
.foot-advertisement,
.foot_adsense,
.footad,
.footer-300-ad,
.footer-ad,
.footer-ad-full-wrapper,
.footer-ad-labeling,
.footer-ad-row,
.footer-ad-section,
.footer-ad-squares,
.footer-ad-unit,
.footer-ad-wrap,
.footer-adrow,
.footer-ads,
.footer-ads-slide,
.footer-ads-wrapper,
.footer-ads_unlocked,
.footer-adsbar,
.footer-adsense,
.footer-advert,
.footer-advert-large,
.footer-advertisement,
.footer-advertisements,
.footer-advertising,
.footer-advertising-area,
.footer-banner-ad,
.footer-banner-ads,
.footer-floating-ad,
.footer-im-ad,
.footer-leaderboard-ad,
.footer-post-ad-blk,
.footer-prebid,
.footer-text-ads,
.footerAd,
.footerAdModule,
.footerAdUnit,
.footerAdWrapper,
.footerAds,
.footerAdsWrap,
.footerAdslot,
.footerAdverts,
.footerBottomAdSec,
.footerFullAd,
.footerPageAds,
.footerTextAd,
.footer__ads--content,
.footer__advert,
.footer_ad,
.footer_ad336,
.footer_ad_container,
.footer_ads,
.footer_adv,
.footer_advertisement,
.footer_block_ad,
.footer_bottom_ad,
.footer_bottomad,
.footer_line_ad,
.footer_text_ad,
.footer_text_adblog,
.footerad,
.footeradspace,
.footertextadbox,
.forbes-ad-container,
.forex_ad_links,
.fortune-ad-unit,
.forum-ad,
.forum-ad-2,
.forum-teaser-ad,
.forum-topic--adsense,
.forumAd,
.forum_ad_beneath,
.four-ads,
.fp-ad-nativendo-one-third,
.fp-ad-rectangle,
.fp-ad300,
.fp-ads,
.fp-right-ad,
.fp-right-ad-list,
.fp-right-ad-zone,
.fp_ad_text,
.fp_adv-box,
.frame_adv,
.framead,
.freestar-ad-container,
.freestar-ad-sidebar-container,
.freestar-ad-wide-container,
.freestar-incontent-ad,
.frn_adbox,
.front-ad,
.front_ad,
.frontads,
.frontendAd,
.frontone_ad,
.frontpage__article--ad,
.frontpage_ads,
.fsAdContainer,
.fs_ad,
.fs_ads,
.fsrads,
.ft-ad,
.full-ad,
.full-ad-wrapper,
.full-ads,
.full-adv,
.full-bleed-ad,
.full-bleed-ad-container,
.full-page-ad,
.full-top-ad-area,
.full-width-ad,
.full-width-ad-container,
.full-width-ads,
.fullAdBar,
.fullBleedAd,
.fullSizeAd,
.fullWidthAd,
.full_AD,
.full_ad_box,
.full_ad_row,
.full_width_ad,
.fulladblock,
.fullbanner_ad,
.fullbannerad,
.fullpage-ad,
.fullsize-ad-square,
.fullwidth-advertisement,
.fusion-ads,
.fuv_sidebar_ad_widget,
.fwAdTags,
.fw_ad,
.g-ad,
.g-ad-fix,
.g-ad-leaderboard,
.g-ad-slot,
.g-adver,
.g-advertisement-block,
.g1-ads,
.g1-advertisement,
.g2-adsense,
.g3-adsense,
.gAdMTable,
.gAdMainParent,
.gAdMobileTable,
.gAdOne,
.gAdOneMobile,
.gAdRows,
.gAdSky,
.gAdThreeDesktop,
.gAdThreeMobile,
.gAdTwo,
.gAds,
.gAds1,
.gAdsBlock,
.gAdsContainer,
.gAdvertising,
.g_ad,
.g_adv,
.ga-ads,
.gaTeaserAds,
.gaTeaserAdsBox,
.gabfire_ad,
.gabfire_simplead_widget,
.gad-container,
.gad-right1,
.gad-right2,
.gad300x600,
.gad336x280,
.gadContainer,
.gad_container,
.gads_container,
.gadsense,
.gadsense-ad,
.gallery-ad,
.gallery-ad-container,
.gallery-ad-counter,
.gallery-ad-holder,
.gallery-ad-lazyload-placeholder,
.gallery-ad-overlay,
.gallery-adslot-top,
.gallery-injectedAd,
.gallery-sidebar-ad,
.gallery-slide-ad,
.galleryAds,
.galleryLeftAd,
.galleryRightAd,
.gallery_ad,
.gallery_ad_wrapper,
.gallery_ads_box,
.galleryad,
.galleryads,
.gam-ad,
.gam-ad-hz-bg,
.gam_ad_slot,
.game-ads,
.game-category-ads,
.gameAd,
.gameBottomAd,
.gamepage_boxad,
.games-ad-wrapper,
.gb-ad-top,
.gb_area_ads,
.general-ad,
.genericAds,
.ggl_ads_row,
.ggl_txt_ads,
.giant_pushbar_ads_l,
.glacier-ad,
.globalAd,
.gnm-ad-unit,
.gnm-ad-unit-container,
.gnm-ad-zones,
.gnm-adhesion-ad,
.gnm-banner-ad,
.gnm-bg-ad,
.gnt_em_vp_c[data-g-s="vp_dk"],
.gnt_flp,
.gnt_rr_xpst,
.gnt_rr_xst,
.gnt_tb.gnt_tbb,
.gnt_tbr.gnt_tb,
.gnt_x,
.gnt_x__lbl,
.gnt_xmst,
.go-ad,
.goAdMan,
.goads,
.googads,
.google-2ad-m,
.google-ad,
.google-ad-160-600,
.google-ad-468-60,
.google-ad-728-90,
.google-ad-block,
.google-ad-container,
.google-ad-content,
.google-ad-header2,
.google-ad-image,
.google-ad-manager,
.google-ad-placeholder,
.google-ad-sidebar,
.google-ad-space,
.google-ad-widget,
.google-ads,
.google-ads-billboard,
.google-ads-bottom,
.google-ads-container,
.google-ads-footer-01,
.google-ads-footer-02,
.google-ads-in_article,
.google-ads-leaderboard,
.google-ads-long,
.google-ads-responsive,
.google-ads-right,
.google-ads-sidebar,
.google-ads-widget,
.google-ads-wrapper,
.google-adsense,
.google-advert-sidebar,
.google-afc-wrapper,
.google-bottom-ads,
.google-dfp-ad-caption,
.google-dfp-ad-wrapper,
.google-right-ad,
.google-sponsored,
.google-sponsored-ads,
.google-sponsored-link,
.google-sponsored-links,
.google468,
.googleAd,
.googleAdBox,
.googleAdContainer,
.googleAdSearch,
.googleAdSense,
.googleAdWrapper,
.googleAdd,
.googleAds,
.googleAdsContainer,
.googleAdsense,
.googleAdv,
.google_ad,
.google_ad_container,
.google_ad_label,
.google_ad_wide,
.google_add,
.google_admanager,
.google_ads,
.google_ads_content,
.google_ads_sidebar,
.google_adsense,
.google_adsense1,
.google_adsense_footer,
.google_afc,
.google_afc_ad,
.googlead,
.googleadArea,
.googleadbottom,
.googleadcontainer,
.googleaddiv,
.googleads,
.googleads-container,
.googleads-height,
.googleadsense,
.googleadsrectangle,
.googleadv,
.googleadvertisement,
.googleadwrap,
.googleafc,
.gpAds,
.gpt-ad,
.gpt-ad-container,
.gpt-ad-sidebar-wrap,
.gpt-ad-wrapper,
.gpt-ads,
.gpt-billboard,
.gpt-breaker-container,
.gpt-container,
.gpt-leaderboard-banner,
.gpt-mpu-banner,
.gpt-sticky-sidebar,
.gpt.top-slot,
.gptSlot,
.gptSlot-outerContainer,
.gptSlot__sticky-footer,
.gptslot,
.gradientAd,
.graphic_ad,
.grev-ad,
.grey-ad,
.grey-ad-line,
.grey-ad-notice,
.greyAd,
.greyad,
.grid>.container>#aside-promotion,
.grid-ad,
.grid-ad-col__big,
.grid-advertisement,
.grid-block-ad,
.grid-item-ad,
.gridAd,
.gridAdRow,
.gridSideAd,
.grid__module-sizer_name_taboola,
.grid_ad_container,
.gridad,
.gridlove-ad,
.gridstream_ad,
.ground-ads-shared,
.group-ad-leaderboard,
.group-google-ads,
.group-item-ad,
.group_ad,
.gsAd,
.gtm-ad-slot,
.guide__row--fixed-ad,
.guj-ad--placeholder,
.gujAd,
.gutterads,
.gw-ad,
.h-adholder,
.h-ads,
.h-adver,
.h-large-ad-box,
.h-top-ad,
.h11-ad-top,
.h_Ads,
.h_ad,
.half-ad,
.half-page-ad,
.half-page-ad-1,
.half-page-ad-2,
.halfPageAd,
.half_ad_box,
.halfpage_ad,
.halfpage_ad_1,
.halfpage_ad_container,
.happy-inline-ad,
.happy-under-player,
.has-ad,
.has-adslot,
.has-fixed-bottom-ad,
.hasAD,
.hdr-ad,
.hdr-ads,
.hdrAd,
.hdr_ad,
.head-ad,
.head-ads,
.head-banner468,
.head-top-ads,
.headAd,
.head_ad,
.head_ad_wrapper,
.head_ads,
.head_adv,
.head_advert,
.headad,
.headadcontainer,
.header-ad,
.header-ad-area,
.header-ad-banner,
.header-ad-box,
.header-ad-container,
.header-ad-desktop,
.header-ad-frame,
.header-ad-holder,
.header-ad-region,
.header-ad-row,
.header-ad-space,
.header-ad-top,
.header-ad-widget,
.header-ad-wrap,
.header-ad-wrapper,
.header-ad-zone,
.header-adbanner,
.header-adbox,
.header-adcode,
.header-adplace,
.header-ads,
.header-ads-area,
.header-ads-container,
.header-ads-holder,
.header-ads-wrap,
.header-ads-wrapper,
.header-adsense,
.header-adslot-container,
.header-adspace,
.header-adv,
.header-advert,
.header-advert-wrapper,
.header-advertise,
.header-advertisement,
.header-advertising,
.header-and-footer--banner-ad,
.header-article-ads,
.header-banner-ad,
.header-banner-ads,
.header-banner-advertising,
.header-bannerad,
.header-bottom-adboard-area,
.header-pencil-ad,
.header-sponsor,
.header-top-ad,
.header-top_ads,
.headerAd,
.headerAd1,
.headerAdBanner,
.headerAdContainer,
.headerAdPosition,
.headerAdSpacing,
.headerAdWrapper,
.headerAds,
.headerAds250,
.headerAdspace,
.headerAdvert,
.headerAdvertisement,
.headerTextAd,
.headerTopAd,
.headerTopAds,
.header__ad,
.header__ads,
.header__ads-wrapper,
.header__advertisement,
.header_ad,
.header_ad1,
.header_ad_center,
.header_ad_div,
.header_ad_space,
.header_ads,
.header_ads-container,
.header_ads_box,
.header_adspace,
.header_advert,
.header_advertisement,
.header_advertisment,
.header_leaderboard_ad,
.header_top_ad,
.headerad,
.headeradarea,
.headeradblock,
.headeradright,
.headerads,
.heading-ad-space,
.headline-adblock,
.headline-ads,
.headline_advert,
.hederAd,
.herald-ad,
.hero-ad,
.hero-ad-slot,
.hero-advert,
.heroAd,
.hidden-ad,
.hide-ad,
.hide_ad,
.hidead,
.highlightsAd,
.hm-ad,
.hmad,
.hn-ads,
.holder-ad,
.holder-ads,
.home-ad,
.home-ad-bigbox,
.home-ad-container,
.home-ad-inline,
.home-ad-links,
.home-ad-region-1,
.home-ad-section,
.home-ads,
.home-ads-container,
.home-ads1,
.home-adv-box,
.home-advert,
.home-body-ads,
.home-page-ad,
.home-sidebar-ad,
.home-sponsored-links,
.home-sticky-ad,
.home-top-ad,
.homeAd,
.homeAd1,
.homeAd2,
.homeAdBox,
.homeAdBoxA,
.homeAdSection,
.homeBoxMediumAd,
.homeCentreAd,
.homeMainAd,
.homeMediumAdGroup,
.homePageAdSquare,
.homePageAds,
.homeTopAdContainer,
.home_ad,
.home_ad_bottom,
.home_ad_large,
.home_ad_title,
.home_adblock,
.home_advert,
.home_advertisement,
.home_mrec_ad,
.homeadwrapper,
.homepage--sponsor-content,
.homepage-ad,
.homepage-ad-block,
.homepage-ad-module,
.homepage-advertisement,
.homepage-banner-ad,
.homepage-footer-ad,
.homepage-footer-ads,
.homepage-page__ff-ad-container,
.homepage-page__tag-ad-container,
.homepage-page__video-ad-container,
.homepageAd,
.homepage__native-ad,
.homepage_ads,
.homepage_block_ad,
.hor-ad,
.hor_ad,
.horiAd,
.horiz_adspace,
.horizontal-ad,
.horizontal-ad-container,
.horizontal-ad-holder,
.horizontal-ad-wrapper,
.horizontal-ad2,
.horizontal-ads,
.horizontal-advert-container,
.horizontal-full-ad,
.horizontal.ad,
.horizontalAd,
.horizontalAdText,
.horizontalAdvert,
.horizontal_Fullad,
.horizontal_ad,
.horizontal_adblock,
.horizontal_ads,
.horizontaltextadbox,
.horizsponsoredlinks,
.hortad,
.hotad_bottom,
.hotel-ad,
.house-ad,
.house-ad-small,
.house-ad-unit,
.house-ads,
.houseAd,
.houseAd1,
.houseAdsStyle,
.housead,
.hover_ads,
.hoverad,
.hp-ad-container,
.hp-ad-grp,
.hp-adsection,
.hp-sectionad,
.hpRightAdvt,
.hp_320-250-ad,
.hp_ad_300,
.hp_ad_box,
.hp_ad_cont,
.hp_ad_text,
.hp_adv300x250,
.hp_advP1,
.hp_horizontal_ad,
.hp_textlink_ad,
.htl-ad,
.htl-ad-placeholder,
.htl-inarticle-container,
.html-advertisement,
.html5-ad-progress-list,
.hw-ad--frTop,
.hyad,
.i-amphtml-element.live-updates.render-embed,
.i-amphtml-unresolved,
.iAdserver,
.iab300x250,
.iab728x90,
.ib-adv,
.ico-adv,
.icon-advertise,
.iconAdChoices,
.icon_ad_choices,
.iconads,
.idgGoogleAdTag,
.ie-adtext,
.iframe-ad,
.iframe-ads,
.iframeAd,
.iframeAds,
.ima-ad-container,
.image-advertisement,
.image-viewer-ad,
.image-viewer-mpu,
.imageAd,
.imageAds,
.imagead,
.imageads,
.img-advert,
.img_ad,
.img_ads,
.imgad,
.in-article-ad,
.in-article-ad-placeholder,
.in-article-ad-wrapper,
.in-article-adx,
.in-between-ad,
.in-content-ad,
.in-content-ad-wrapper,
.in-page-ad,
.in-slider-ad,
.in-story-ads,
.in-text-ad,
.in-text__advertising,
.in-thumb-ad,
.in-thumb-video-ad,
.inPageAd,
.in_ad,
.in_article_ad,
.in_article_ad_wrapper,
.in_content_ad_container,
.in_content_advert,
.inarticlead,
.inc-ad,
.incontent-ad1,
.incontentAd,
.incontent_ads,
.index-adv,
.index-module_adBeforeContent__AMXn,
.index-module_adBeforeContent__UYZT,
.index-module_rightrailBottom__IJEl,
.index-module_rightrailTop__mag4,
.index-module_sd_background__Um4w,
.index_728_ad,
.index_ad,
.index_ad_a2,
.index_ad_a4,
.index_ad_a5,
.index_ad_a6,
.index_right_ad,
.inf-onclickvideo-adbox,
.inf-onclickvideo-container,
.infinity-ad,
.inhousead,
.injected-ad,
.injectedAd,
.inline-ad,
.inline-ad-card,
.inline-ad-container,
.inline-ad-desktop,
.inline-ad-placeholder,
.inline-ad-text,
.inline-ad-wrap,
.inline-ad-wrapper,
.inline-adblock,
.inline-advert,
.inline-banner-ad,
.inline-display-ad,
.inline-google-ad-slot,
.inline-mpu,
.inline-story-add,
.inlineAd,
.inlineAdContainer,
.inlineAdImage,
.inlineAdInner,
.inlineAdNotice,
.inlineAdText,
.inlineAdvert,
.inlineAdvertisement,
.inlinePageAds,
.inlineSideAd,
.inline_ad,
.inline_ad_container,
.inline_ad_title,
.inline_ads,
.inlinead,
.inlinead_lazyload,
.inlineadsense,
.inlineadtitle,
.inlist-ad,
.inlistAd,
.inner-ad,
.inner-ad-disclaimer,
.inner-ad-section,
.inner-adv,
.inner-advert,
.inner-post-ad,
.innerAdWrapper,
.innerAds,
.innerContentAd,
.innerWidecontentAd,
.inner_ad,
.inner_ad_advertise,
.inner_big_ad,
.innerad,
.inpostad,
.inr_top_ads,
.ins_adwrap,
.insert-post-ads,
.insert_ad,
.insert_ad_column,
.insert_advertisement,
.insertad,
.inside_ad,
.insideads,
.inslide-ad,
.insticator-ads,
.instream_ad,
.intAdRow,
.intad,
.interAd,
.internal-ad,
.internalAd,
.internal_ad,
.interstitial-ad,
.interstory_first_mobile,
.interstory_second_mobile,
.intext-ads,
.intra-article-ad,
.intro-ad,
.ion-ad,
.ione-widget-dart-ad,
.ipc-advert,
.ipc-advert-class,
.ipsAd,
.ipsAdvertisement,
.iqadlinebottom,
.iqadmarker,
.iqadtile_wrapper,
.is-ad,
.is-carbon-ad,
.is-desktop-ads,
.is-mpu,
.is-preload-ad,
.is-script-ad,
.is-sponsored,
.is-sticky-ad,
.isAd,
.isAdPage,
.isad_box,
.ise-ad,
.island-ad,
.islandAd,
.islandAdvert,
.island_ad,
.islandad,
.item--ad,
.item-ad,
.item-ad-leaderboard,
.item-advertising,
.item-container-ad,
.itemAdvertise,
.item_ads,
.itsanad,
.j-ad,
.jLinkSponsored,
.jannah_ad,
.jg-ad-5,
.jg-ad-970,
.jobbioapp,
.jobs-ad-box,
.jobs-ad-marker,
.jquery-adi,
.jquery-script-ads,
.js-ad,
.js-ad-banner-container,
.js-ad-buttons,
.js-ad-container,
.js-ad-dynamic,
.js-ad-frame,
.js-ad-home,
.js-ad-loader-bottom,
.js-ad-slot,
.js-ad-static,
.js-ad-unit,
.js-ad-unit-bottom,
.js-ad-wrapper,
.js-ad_iframe,
.js-adfliction-iframe,
.js-adfliction-standard,
.js-ads,
.js-ads-carousel,
.js-advert,
.js-advert-container,
.js-adzone,
.js-anchor-ad,
.js-article-advert-injected,
.js-billboard-advert,
.js-dfp-ad,
.js-dfp-ad-bottom,
.js-dfp-ad-top,
.js-gpt-ad,
.js-gptAd,
.js-header-ad,
.js-header-ad-wrapper,
.js-lazy-ad,
.js-mapped-ad,
.js-mpu,
.js-native-ad,
.js-no-sticky-ad,
.js-outbrain-container,
.js-overlay_ad,
.js-react-simple-ad,
.js-results-ads,
.js-right-ad-block,
.js-sidebar-ads,
.js-skyscraper-ad,
.js-slide-right-ad,
.js-slide-top-ad,
.js-sticky-ad,
.js-stream-ad,
.js-taboola,
.js-toggle-ad,
.js-widget-distroscale,
.js-widget-send-to-news,
.jsAdSlot,
.jsMPUSponsor,
.js_adContainer,
.js_ad_wrapper,
.js_deferred-ad,
.js_desktop-horizontal-ad,
.js_midbanner_ad_slot,
.js_preheader-ad-container,
.js_related-stories-inset,
.js_slideshow-full-width-ad,
.js_slideshow-sidebar-ad,
.js_sticky-top-ad,
.jsx-adcontainer,
.jw-ad,
.jw-ad-block,
.jw-ad-label,
.jw-ad-media-container,
.jw-ad-visible,
.jwPlayer--floatingContainer,
.kakao_ad_area,
.keen_ad,
.kumpulads-post,
.kumpulads-side,
.kwizly-psb-ad,
.l-ad,
.l-ad-top,
.l-ads,
.l-adsense,
.l-article__ad,
.l-bottom-ads,
.l-grid--ad-card,
.l-header-advertising,
.l-section--ad,
.l1-ads-wrapper,
.label-ad,
.label_advertising_text,
.labelads,
.large-advert,
.largeAd,
.largeRectangleAd,
.largeUnitAd,
.large_ad,
.lastAdHolder,
.lastads,
.latest-ad,
.layout-ad,
.layout__right-ads,
.layout_h-ad,
.lazy-ad,
.lazy-ad-unit,
.lazy-adv,
.lazyad,
.lazyadsense,
.lazyadslot,
.lazyload-ad,
.lazyload_ad,
.lazyload_ad_article,
.lb-ad,
.lb-adhesion-unit,
.lb-advert-container,
.lb-item-ad,
.ld-ad,
.ld-ad-inner,
.ldm_ad,
.lead-ad,
.lead-ads,
.leader-ad,
.leader-ad-728,
.leaderAd,
.leaderAdTop,
.leaderAdvert,
.leaderBoardAdWrapper,
.leaderBoardAdvert,
.leader_ad,
.leader_aol,
.leaderad,
.leaderboard-ad,
.leaderboard-ad-belt,
.leaderboard-ad-component,
.leaderboard-ad-container,
.leaderboard-ad-dummy,
.leaderboard-ad-fixed,
.leaderboard-ad-grid,
.leaderboard-ad-main,
.leaderboard-ad-module,
.leaderboard-ad-pane,
.leaderboard-ad-placeholder,
.leaderboard-ad-section,
.leaderboard-ad-unit,
.leaderboard-ad-wrapper,
.leaderboard-adblock,
.leaderboard-ads,
.leaderboard-ads-text,
.leaderboard-advert,
.leaderboard-advertisement,
.leaderboard-main-ad,
.leaderboard-top-ad,
.leaderboard-top-ad-wrapper,
.leaderboard.advert,
.leaderboard1AdWrapper,
.leaderboardAd,
.leaderboardAdWrapper,
.leaderboardFooter_ad,
.leaderboardRectAdWrapper,
.leaderboard_ad_container,
.leaderboard_ad_unit,
.leaderboard_ads,
.leaderboard_adsense,
.leaderboard_adv,
.leaderboard_banner_ad,
.leaderboardad,
.leaderboardadmiddle,
.leaderboardadtop,
.leaderboardadwrap,
.lee-track-ilad,
.left-ad,
.left-ads,
.left-advert,
.left-rail-ad,
.left-sponser-ad,
.leftAd,
.leftAdColumn,
.leftAdContainer,
.leftAds,
.leftAdsEnabled,
.leftAdsFix,
.leftAdvDiv,
.leftAdvert,
.leftCol_advert,
.leftColumnAd,
.left_300_ad,
.left_ad,
.left_ad_160,
.left_ad_areas,
.left_ad_box,
.left_ad_container,
.left_add_block,
.left_adlink,
.left_ads,
.left_adsense,
.left_advertisement_block,
.left_col_ad,
.left_google_add,
.leftad,
.leftadd,
.leftadtag,
.leftbar_ad2,
.leftbarads,
.leftbottomads,
.leftnavad,
.leftrighttopad,
.leftsidebar_ad,
.lefttopad1,
.legacy-ads,
.legion_primiswrapper,
.lft_advt_container,
.lg-ads-160x90,
.lg-ads-311x500,
.lg-ads-635x100,
.lg-ads-skin-container,
.ligatus,
.lightad,
.lijit-ad,
.linead,
.linkAD,
.linkAds,
.link_ad,
.linkads,
.list-ad,
.list-adbox,
.list-ads,
.list-feature-ad,
.list-footer-ad,
.listad,
.listicle-instream-ad-holder,
.listing-item-ad,
.listingAd,
.listings_ad,
.lite-page-ad,
.live-ad,
.lng-ad,
.local-ads,
.localad,
.location-ad,
.log_ads,
.logged_out_ad,
.logo-ad,
.logoAds,
.logo_AdChoices,
.logoad,
.logoutAd,
.logoutAdContainer,
.long-ads,
.longAd,
.longAdBox,
.longAds,
.long_ad,
.longform-ad,
.loop-ad,
.lower-ad,
.lower-ads,
.lowerAd,
.lowerAds,
.lower_ad,
.lr-ad,
.lr-pack-ad,
.lr_skyad,
.lrec-container,
.lst_ads,
.lyrics-inner-ad-wrap,
.m-ContentAd,
.m-ad,
.m-ad-brick,
.m-ad-region,
.m-ad-unit,
.m-ad__wrapper,
.m-adaptive-ad-component,
.m-advert,
.m-advertisement,
.m-advertisement--container,
.m-article-taboola,
.m-balloon-header--ad,
.m-block-ad,
.m-content-advert,
.m-content-advert-wrap,
.m-dfp-ad-text,
.m-header-ad,
.m-in-content-ad,
.m-in-content-ad-row,
.m-jac-ad,
.m-sponsored,
.m1-header-ad,
.m2n-ads-slot,
.m_ad,
.m_ad1,
.m_ad300,
.m_banner_ads,
.macAd,
.macad,
.mad_adcontainer,
.magAd,
.magad,
.main-ad,
.main-ad-container,
.main-ad-gallery,
.main-add-sec,
.main-ads,
.main-advert,
.main-advertising,
.main-column-ad,
.main-footer-ad,
.main-header-ad,
.main-header__ad-wrapper,
.main-right-ads,
.mainAd,
.mainAdContainer,
.mainAds,
.mainLeftAd,
.mainLinkAd,
.mainRightAd,
.main_ad,
.main_adbox,
.main_ads,
.main_adv,
.mantis-ad,
.mantisadd,
.manual-ad,
.map-ad,
.mapped-ad,
.mar-block-ad,
.mar-leaderboard--bottom,
.margin-advertisement,
.margin0-ads,
.marginalContentAdvertAddition,
.marketing-ad,
.marketplace-ad,
.marketplaceAd,
.marquee-ad,
.masonry-tile-ad,
.masonry__ad,
.master_post_advert,
.masthead-ad,
.masthead-ads,
.mastheadAds,
.masthead__ad,
.match-ad,
.mb-advert,
.mb-advert__incontent,
.mb-advert__leaderboard--large,
.mb-advert__mpu,
.mb-advert__tweeny,
.mb-block--advert-side,
.mb-list-ad,
.mc-column-Taboola,
.mc_floating_ad,
.mc_text_ads_box,
.md-advertisement,
.medRect,
.media-viewer__ads-container,
.mediaAd,
.mediaAdContainer,
.medium-rectangle-ad,
.medium-top-ad,
.mediumRectAdWrapper,
.mediumRectagleAd,
.mediumRectangleAd,
.mediumRectangleAdvert,
.medium_ad,
.mediumad,
.medrec-ad,
.medrect-ad,
.medrect-ad2,
.medrectAd,
.medrect_ad,
.mega-ad,
.member-ads,
.menu-ad,
.menuAd,
.message_ads,
.meta-ad,
.meta_ad,
.metabet-adtile,
.mf-adsense-leaderboard,
.mf-adsense-rightrail,
.mg_box_ads,
.mgid-wrapper,
.mgid_3x2,
.mid-ad-wrapper,
.mid-ads,
.mid-advert,
.mid-article-banner-ad,
.mid-outbrain,
.mid-post-ad,
.mid-section-ad,
.midAd,
.midAdv-cont,
.midAdv-cont2,
.midAdvert,
.mid_ad,
.mid_banner_ad,
.midad,
.midarticlead,
.middle-ad,
.middle-ads,
.middle-ads728,
.middle-footer-ad,
.middleAd,
.middleAdLeft,
.middleAdMid,
.middleAdRight,
.middleAdWrapper,
.middleAds,
.middleBannerAd,
.middle_AD,
.middle_ad,
.middle_ad_responsive,
.middle_ads,
.middlead,
.middleadouter,
.midpost-ad,
.min-height-ad,
.min_navi_ad,
.mini-ad,
.mini-ads,
.mini_ads,
.miniad,
.miniads,
.misc-ad,
.misc-ad-label,
.miscAd,
.mj-floating-ad-wrapper,
.mks_ads_widget,
.mm-ad-sponsored,
.mm-ads-adhesive-ad,
.mm-ads-gpt-adunit,
.mm-ads-leaderboard-header,
.mm-banner970-ad,
.mm-embed--sendtonews,
.mm-widget--sendtonews,
.mmads,
.mntl-gpt-adunit,
.mntl-leaderboard-header,
.mntl-leaderboard-spacer,
.mntl-sc-block-adslot,
.moads-top-banner,
.moads-widget,
.mob-ad-break-text,
.mob-adspace,
.mob-hero-banner-ad-wrap,
.mob_ads,
.mobads,
.mobile-ad,
.mobile-ad-container,
.mobile-ad-negative-space,
.mobile-ad-placeholder,
.mobile-ad-slider,
.mobile-ads,
.mobile-fixed-ad,
.mobile-instream-ad-holder,
.mobile-instream-ad-holder-single,
.mobileAd,
.mobileAdWrap,
.mobileAppAd,
.mobile_ad_banner,
.mobile_ad_container,
.mobile_featuredad,
.mobile_leaderboard_ad,
.mobileadbig,
.mobileadunit,
.mobilesideadverts,
.mod-ad,
.mod-adblock,
.mod-ads,
.mod-google-ads,
.mod-horizontal-ad,
.mod-sponsored-links,
.mod-vertical-ad,
.mod_ad,
.mod_ad_container,
.mod_ad_text,
.mod_ad_top,
.mod_admodule,
.mod_ads,
.mod_advert,
.mod_index_ad,
.mod_js_ad,
.mod_openads,
.mod_r_ad,
.mod_r_ad1,
.modal-ad,
.module--ad,
.module-ad,
.module-ad-small,
.module-ads,
.module-advert,
.module-advertisement,
.module-box-ads,
.module-image-ad,
.module-rectangleads,
.module-sponsored-ads,
.module-zerg,
.module1colAds,
.moduleAd,
.moduleAdSpot,
.moduleAdvert,
.moduleAdvertContent,
.moduleBannerAd,
.module__ad-wide,
.module_ad,
.module_ad_disclaimer,
.module_box_ad,
.module_header_sponsored,
.module_home_ads,
.module_single_ads,
.modulegad,
.moduletable-adsponsor,
.moduletable-advert,
.moduletable-bannerAd6,
.moduletable-centerad,
.moduletable-googleads,
.moduletable-rectangleads,
.moduletable_ad-right,
.moduletable_ad300x250,
.moduletable_adtop,
.moduletable_advertisement,
.moduletable_top_ad,
.moduletableadvert,
.moduletableexclusive-ads,
.moduletablesquaread,
.moduletabletowerad,
.mom-ad,
.moneyball-ad,
.monsterad,
.mos-ad,
.mosaicAd,
.motherboard-ad,
.movable-ad,
.movv-ad,
.mp-ad,
.mpsponsor,
.mpu-ad,
.mpu-ad-con,
.mpu-ad-river,
.mpu-ad-top,
.mpu-advert,
.mpu-c,
.mpu-footer,
.mpu-fp,
.mpu-holder,
.mpu-leaderboard,
.mpu-left,
.mpu-left-bk,
.mpu-mediatv,
.mpu-right,
.mpu-title,
.mpu-top-left,
.mpu-top-left-banner,
.mpu-top-right,
.mpu-unit,
.mpu-wrap,
.mpu-wrapper,
.mpuAd,
.mpuAdArea,
.mpuAdSlot,
.mpuAdvert,
.mpuArea,
.mpuBlock,
.mpuBox,
.mpuContainer,
.mpu_Ad,
.mpu_ad,
.mpu_advert,
.mpu_container,
.mpu_holder,
.mpu_placeholder,
.mpu_side,
.mpu_wrapper,
.mpuad,
.mpuads,
.mr1_adwrap,
.mr2_adwrap,
.mr3_adwrap,
.mr4_adwrap,
.mrec-ads,
.mrec-banners,
.mrecAds,
.mrec_advert,
.mrf-adv,
.mrf-adv__wrapper,
.msg-ad,
.msgad,
.mt-ad-container,
.mt_ad,
.mt_ads,
.mtop_adfit,
.mu-ad-container,
.mv_atf_ad_holder,
.mvp-ad-label,
.mvp-feat1-list-ad,
.mvp-flex-ad,
.mvp-post-ad-wrap,
.mvp-widget-ad,
.mvp-widget-feat2-side-ad,
.mvp_ad_widget,
.mw-ad,
.my-ads,
.myAds,
.myAdsGroup,
.my__container__ad,
.n1ad-center-300,
.narrow_ad_unit,
.narrow_ads,
.national_ad,
.nationalad,
.native-ad,
.native-ad-article,
.native-ad-container,
.native-ad-item,
.native-ad-mode,
.native-ad-slot,
.native-adv,
.native-advts,
.native-leaderboard-ad,
.native-sidebar-ad,
.native.ad,
.nativeAd,
.native_ad,
.native_ad_inline,
.native_ad_wrap,
.native_ads,
.nativead,
.nav-ad,
.nav-ad-gpt-container,
.nav-ad-plus-leader,
.nav-adWrapper,
.nav_ad,
.navbar-ad-section,
.navbar-ads,
.navbar-header-ad,
.naviad,
.ndmadkit,
.netPost_ad1,
.netPost_ad3,
.netads,
.netshelter-ad,
.newHeaderAd,
.new_ad1,
.new_ad_left,
.new_ad_normal,
.new_ad_wrapper_all,
.new_ads_unit,
.newad,
.newad1,
.news-ad,
.news-ad-square-a,
.news-ad-square-box,
.news-ads-top,
.news-item--ad,
.news_ad_box,
.news_vibrant_ads_banner,
.newsad,
.newsblock-ads,
.newsfeed_adunit,
.newspack_global_ad,
.nfy-ad,
.nfy-ad-teaser,
.nfy-ad-tile,
.nfy-ad-wrapper,
.nfy-cobo-ad,
.nfy-col-ad,
.ng-ad-banner,
.ng-ad-insert,
.nm-ad,
.nn_mobile_mpu_wrapper,
.node-ad,
.node_ad_wrapper,
.normalAds,
.normal_ads,
.normalad,
.northad,
.not-an-ad-header,
.note-advertisement,
.np-ad,
.np-ad-background,
.np-ad-border,
.np-ads-wrapper,
.np-adv-container,
.np-advert_apu,
.np-advert_apu-double,
.np-advert_info,
.np-header-ad,
.np-header-ads-area,
.np-right-ad,
.nrAds,
.nsAdRow,
.nts-ad,
.nts-video-wrapper,
.ntv-ad,
.nuffnangad,
.nuk-ad-placeholder,
.nv-ads-wrapper,
.nw-ad,
.nw-ad-label,
.nw-c-leaderboard-ad,
.nw-top-ad,
.nw_adv_square,
.nx-billboard-ad,
.nx-placeholder-ad,
.nya-slot[style],
.o-ad,
.o-ad-banner-top,
.o-ad-container,
.o-advert,
.o-listing__ad,
.o-site-header__advert,
.oad-ad,
.oas-ad,
.oas-container,
.oas-leaderboard-ads,
.oas_ad,
.oas_add,
.oas_advertisement,
.oasad,
.oasads,
.ob-p.ob-dynamic-rec-container,
.ob-smartfeed-wrapper,
.ob-widget-header,
.ob_ads_header,
.ob_container .item-container-obpd,
.ob_dual_right>.ob_ads_header~.odb_div,
.offads,
.oi-add-block,
.oi-header-ad,
.oio-banner-zone,
.oio-link-sidebar,
.oio-openslots,
.oio-zone-position,
.oko-adhesion,
.on_player_ads,
.oneColumnAd,
.onet-ad,
.online-ad-container,
.oovvuu-embed-player,
.opd_adsticky,
.otd-ad-top,
.outBrainWrapper,
.outbrain,
.outbrain-ad-slot,
.outbrain-ad-units,
.outbrain-ads,
.outbrain-bg,
.outbrain-bloc,
.outbrain-content,
.outbrain-group,
.outbrain-module,
.outbrain-placeholder,
.outbrain-recommended,
.outbrain-reserved-space,
.outbrain-single-bottom,
.outbrain-widget,
.outbrain-widget,
.outbrain-wrap,
.outbrain-wrapper,
.outbrain-wrapper-container,
.outbrain-wrapper-outer,
.outbrainAdHeight,
.outbrainWidget,
.outbrain__main,
.outbrain_container,
.outbrain_skybox,
.outbrainad,
.outbrainbox,
.outer-ad-container,
.outer-ad-unit-wrapper,
.outerAdWrapper,
.outerAds,
.outer_ad_container,
.outside_ad,
.outsider-ad,
.ov-ad-slot,
.overflow-ad,
.overlay-ad,
.overlay-ad-container,
.overlay-ads,
.overlay-box-ad,
.overlay_ad,
.ox-holder,
.p-ad,
.p-ad-block,
.p-ad-dfp-banner,
.p-ad-dfp-middle-rec,
.p-ad-feature-pr,
.p-ad-outbreak,
.p-ad-rectangle,
.p-ad-thumbnail-txt,
.p-ads-billboard,
.p-ads-rec,
.p-post-ad:not(html):not(body),
.p75_sidebar_ads,
.p_adv,
.p_topad,
.package_adBox,
.padAdvx,
.padded-ad,
.paddingBotAd,
.pads2,
.pads_bulk_widget,
.padvertlabel,
.page-ad,
.page-ads,
.page-advert,
.page-advertisement,
.page-bottom-fixed-ads,
.page-box-ad,
.page-break-ad,
.page-content__advert,
.page-footer-ad,
.page-header-ad,
.page-header_ad,
.page-top-ads,
.pageAd,
.pageAdSkin,
.pageAdSkinUrl,
.pageAds,
.pageFooterAd,
.pageGoogleAd,
.pageGoogleAds,
.pageHeaderAd,
.pageHeaderAds,
.pageTopAd,
.page__top-ad-wrapper,
.page_ad,
.pagead,
.pagepusheradATF,
.pages__ad,
.pane-ad-pane,
.pane-ads,
.pane-sasia-ad,
.pane-site-ads,
.pane-sponsored-links,
.pane_ad_wide,
.panel-ad,
.panel-adsense,
.panel-advert,
.panel.ad,
.panel_ad,
.paneladvert,
.par-ad,
.par-adv-slot,
.parade-ad-container,
.parent-ad-desktop,
.partial-ad,
.partner-ad,
.partner-ad-module-wrapper,
.partner-ads-list,
.partner-loading-shown.partner-label,
.partnerAd,
.partner_ads,
.partnerad_container,
.partnersTextLinks,
.pauseAdPlacement,
.pb-slot-container,
.pbs__player,
.pc-ad,
.pcads_widget,
.pd-ads-mpu,
.pdpads_desktop,
.penci-ad-box,
.penci-ad-image,
.penci-ad_box,
.penci-adsense-below-slider,
.penci-google-adsense,
.penci-google-adsense-1,
.penci-promo-link,
.penci_list_bannner_widget,
.pencil-ad,
.pencil-ad-container,
.pencil-ad-section,
.pencil_ad,
.perm_ad,
.pf_content_ad,
.pf_sky_ad,
.pf_top_ad,
.pg-ad-block,
.pg-adnotice,
.pg-adtarget,
.pgevoke-fp-bodyad2,
.pgevoke-story-rightrail-ad1,
.pgevoke-story-topads,
.pgevoke-topads,
.ph-ad,
.photo-ad,
.photo-ad-pad,
.photoAd,
.photoad,
.phpads_container,
.phpbb-ads-center,
.pix_adzone,
.placeholder-ad,
.placeholder-dfp,
.placeholderAd,
.plain-ad,
.plainAd,
.player-ad,
.player-ad-overlay,
.player-ads,
.player-ads2,
.player-section__ads-banners,
.player-under-ad,
.playerAd,
.playerAdv,
.player_ad,
.player_ad2,
.player_ad_box,
.playerad,
.playerdads,
.playwire-article-leaderboard-ad,
.plugin-ad,
.plugin-ad-container,
.pm-ad,
.pm-ad-unit,
.pm-ad-zone,
.pm-ads-banner,
.pm-ads-inplayer,
.pm-banner-ad,
.pmc-adm-boomerang-pub-div,
.pmc-contextual-player,
.polar-ad,
.polaris-ad--wrapper-desktop,
.polarisMarketing,
.polaris__ad,
.polaris__below-header-ad-wrapper,
.pop-out-eplayer-container,
.popup-box-ads,
.position-ads,
.post-ad,
.post-ad-title,
.post-ad-top,
.post-ad-type,
.post-ads,
.post-ads-top,
.post-adsense-bottom,
.post-advert,
.post-advert-row,
.post-advertisement,
.post-load-ad,
.post-news-ad,
.post-sidebar-ad,
.post-sponsored,
.postAd,
.postWideAd,
.post_ad,
.post_ads,
.post_advert,
.post_detail_right_advert,
.post_sponsored,
.postad,
.postads,
.postbit-ad,
.poster_ad,
.posts-ad,
.pp-ad-container,
.pp_ad_code_adtxt,
.ppb_ads,
.ppr_priv_footer_banner_ad_billboard,
.ppr_priv_header_banner_ad,
.ppr_priv_horizon_ad,
.pr_adslot_0,
.pr_adslot_1,
.preheader_advert,
.premium-ad,
.premium-ads,
.premium-adv,
.premium-mpu-container,
.premium_PremiumPlacement__2dEp0,
.priad,
.priad-1,
.primary-ad,
.primary-ad-widget,
.primary-advertisment,
.primis-ad,
.primis-ad-wrap,
.primis-custom,
.primis-player,
.primis-player-container,
.primis-player__container,
.primis-video,
.primis-video-player,
.primis-wrapper,
.primis_1,
.print-ad-wrapper,
.print-adslot,
.printAds,
.product-ad,
.product-ads,
.product-inlist-ad,
.profile-ad-container,
.profile-ads-container,
.profile__ad-wrapper,
.profile_ad_bottom,
.profile_ad_top,
.programtic-ads,
.promo-ad,
.promo-mpu,
.promoAd,
.promoAds,
.promoAdvertising,
.promo_ad,
.promo_ads,
.promo_border,
.promoad,
.promoboxAd,
.promoted-outbrain,
.promoted_content_ad,
.promotionAdContainer,
.promotionTextAd,
.proper-ad-insert,
.proper-ad-unit,
.ps-ad,
.pt-ad--container,
.pt-ad--scroll,
.pt_ad03,
.pt_col_ad02,
.pub_ads,
.pubexchange_module,
.publication-ad,
.publicidad_horizontal,
.publicidade,
.publisher_ad,
.pubtech-adv-slot,
.puff-ad,
.puff-advertorials,
.pull-ad,
.pull_top_ad,
.pullad,
.purchad,
.push--ad,
.push-ad,
.push-adv,
.pushDownAd,
.pushdown-ad,
.pushdownAd,
.pwa-ad,
.pz-ad-box,
.quads-ad-label,
.quads-bg-ad,
.quads-location,
.queue_ad,
.queued-ad,
.quigo,
.quigo-ad,
.quigoads,
.r-ad,
.r-pause-ad-container,
.r89-outstream-video,
.r_ad,
.r_ads,
.rail-ad,
.rail-ads-1,
.rail-article-sponsored,
.rail__ad,
.rail_ad,
.railad,
.railadspace,
.ray-floating-ads-container,
.rc-sponsored,
.rcom-freestar-ads-widget,
.re-AdTop1Container,
.ready-ad,
.rec-sponsored,
.rec_ad,
.rec_article_footer,
.rec_article_right,
.rec_container__right,
.rec_container_footer,
.rec_container_right,
.rec_title_footer,
.recent-ad,
.recentAds,
.recent_ad_holder,
.recipeFeatureAd,
.rect-ad,
.rect-ad-1,
.rectAd300,
.rect_ad,
.rect_ad_module,
.rect_advert,
.rectad,
.rectadv,
.rectangle-ad,
.rectangle-ad-container,
.rectangle-embed-ad,
.rectangleAd,
.rectangleAdContainer,
.rectangle_ad,
.rectanglead,
.rectangleads,
.reform-top-container,
.refreshAds,
.region-ad-bottom-leaderboard,
.region-ad-pan,
.region-ad-right,
.region-ad-top,
.region-ads,
.region-ads-content-top,
.region-banner-ad,
.region-dfp-ad-footer,
.region-dfp-ad-header,
.region-header-ad,
.region-header-ads,
.region-top-ad,
.region-top-ad-block,
.regular-ads,
.regularad,
.rekl-left,
.rekl-right,
.rekl-top,
.rekl_left,
.rekl_right,
.rekl_top,
.rekl_top_wrapper,
.reklam,
.reklam-block,
.reklam-kare,
.reklam-masthead,
.reklam2,
.reklam728,
.reklama,
.reklama-vert,
.reklama1,
.reklame-wrapper,
.reklamka,
.related-ad,
.related-ads,
.relatedAds,
.related_ad,
.remnant_ad,
.remove-ads,
.remove-ads-link,
.res_ad,
.resads-adspot,
.responsive-ad,
.responsive-ad-header-container,
.responsive-ad-outbrain,
.responsive-ad-wrapper,
.responsive-ads,
.responsiveAdsense,
.responsive_ad_top,
.responsive_ads_468x60,
.result-ad,
.result-sponsored,
.resultAd,
.result_ad,
.resultad,
.results-ads,
.revcontent-wrap,
.review-ad,
.reviews-display-ad,
.revive-ad,
.rh-ad,
.rhads,
.rhs-ad,
.rhs-ads-panel,
.rhs-advert-container,
.rhs-mrec-wrapper,
.rhs_ad,
.rhs_ad_title,
.rhs_ads,
.rhsad,
.rhsadvert,
.right-ad,
.right-ad-1,
.right-ad-2,
.right-ad-3,
.right-ad-4,
.right-ad-5,
.right-ad-block,
.right-ad-container,
.right-ad-holder,
.right-ad-wrapper,
.right-ad2,
.right-ad350px250px,
.right-ads,
.right-ads2,
.right-adsense,
.right-adv,
.right-advert,
.right-advertisement,
.right-col-ad,
.right-column-ad,
.right-column-ads,
.right-rail-ad,
.right-rail-ad-container,
.right-rail-box-ad-container,
.right-side-ad,
.right-side-ads,
.right-sidebar-box-ad,
.right-sidebar-box-ads,
.right-sponser-ad,
.right-top-ad,
.right-video-dvertisement,
.rightAD,
.rightAd,
.rightAd1,
.rightAd2,
.rightAdBlock,
.rightAdBox,
.rightAdColumn,
.rightAdContainer,
.rightAds,
.rightAdsFix,
.rightAdvert,
.rightAdverts,
.rightBoxAd,
.rightBoxMidAds,
.rightColAd,
.rightColAdBox,
.rightColumnAd,
.rightColumnAdd,
.rightColumnAdsTop,
.rightColumnRectAd,
.rightHeaderAd,
.rightRailAd,
.rightRailMiddleAd,
.rightSecAds,
.rightSideBarAd,
.rightSideSponsor,
.rightTopAdWrapper,
.right_ad,
.right_ad_1,
.right_ad_2,
.right_ad_box,
.right_ad_box1,
.right_ad_text,
.right_ad_top,
.right_ad_unit,
.right_ad_wrap,
.right_ads,
.right_ads_column,
.right_adsense_box_2,
.right_adskin,
.right_adv,
.right_advert,
.right_advertise_cnt,
.right_advertisement,
.right_block_advert,
.right_box_ad,
.right_col_ad,
.right_column_ads,
.right_content_ad,
.right_image_ad,
.right_long_ad,
.right_outside_ads,
.right_side_ads,
.right_side_box_ad,
.right_sponsor_main,
.rightad,
.rightadHeightBottom,
.rightadblock,
.rightadd,
.rightads,
.rightadunit,
.rightadv,
.rightboxads,
.rightcolads,
.rightcoladvert,
.rightrail-ad-placed,
.rightsideAd,
.river-item-sponsored,
.rj-ads-wrapper,
.rm-adslot,
.rolloverad,
.roof-ad,
.root-ad-anchor,
.rotating-ad,
.rotating-ads,
.row-ad,
.row-ad-leaderboard,
.rowAd,
.rowAds,
.row_header_ads,
.rpd_ads,
.rr-ad,
.rr_ads,
.rs-ad,
.rs-advert,
.rs-advert__container,
.rs_ad_block,
.rs_ad_top,
.rt_ad,
.rwSideAd,
.rwdArticleInnerAdBlock,
.s-ad,
.s-ads,
.s2nContainer,
.s_ads,
.sadvert,
.sagreklam,
.sal-adv-gpt,
.sam_ad,
.sb-ad,
.sb-ads,
.sbAd,
.sbAdUnitContainer,
.sbTopadWrapper,
.sb_ad,
.sb_ad_holder,
.sc-ad,
.scad,
.script-ad,
.scroll-ad-item-container,
.scroll-ads,
.scroll-fixable.rail-right>.deals-rail,
.scroll-track-ad,
.scrolling-ads,
.sda_adbox,
.sdc-advert__top-1,
.se-ligatus,
.search-ad,
.search-advertisement,
.search-result-list-item--sidebar-ad,
.search-result-list-item--topad,
.search-results-ad,
.search-sponsor,
.search-sponsored,
.searchAd,
.searchAdTop,
.searchAds,
.searchad,
.searchads,
.secondary-ad-widget,
.secondary-advertisment,
.secondary_ad,
.section-ad,
.section-ad-unit,
.section-ad-wrapper,
.section-ad2,
.section-ads,
.section-adtag,
.section-adv,
.section-advertisement,
.section-sponsor,
.section-subheader>.section-hotel-prices-header,
.section-widget-ad,
.section_ad,
.section_ad_left,
.section_ads,
.send-to-news,
.seoAdWrapper,
.servedAdlabel,
.serviceAd,
.sexunder_ads,
.sf_ad_box,
.sg-adblock,
.sgAd,
.sh-section-ad,
.shadvertisment,
.sheknows-infuse-ad,
.shift-ad,
.shopee-search-user-brief,
.shortadvertisement,
.show-desk-ad,
.show-sticky-ad,
.showAd,
.showAdContainer,
.showads,
.showcaseAd,
.showcasead,
.shr-ads-container,
.sics-component__outbrain,
.sidbaread,
.side-ad,
.side-ad-300,
.side-ad-blocks,
.side-ad-container,
.side-ad-inner,
.side-ad-top,
.side-ads,
.side-ads-block,
.side-ads-wide,
.side-adv-block,
.side-adv-text,
.side-advert,
.side-advertising,
.side-adverts,
.side-bar-ad,
.sideAd,
.sideAdLeft,
.sideAdWide,
.sideBarAd,
.sideBlockAd,
.sideBoxAd,
.side__ad,
.side__ad-box,
.side_ad,
.side_ad2,
.side_ad_top,
.side_add_wrap,
.side_ads,
.side_adsense,
.side_adv,
.side_col_ad_wrap,
.sidead,
.sideadmid,
.sideads,
.sideads_l,
.sideadsbox,
.sideadtable,
.sideadvert,
.sideadverts,
.sidebar-ad,
.sidebar-ad-area,
.sidebar-ad-b,
.sidebar-ad-box,
.sidebar-ad-c,
.sidebar-ad-component,
.sidebar-ad-cont,
.sidebar-ad-container,
.sidebar-ad-div,
.sidebar-ad-label,
.sidebar-ad-rect,
.sidebar-ad-slot,
.sidebar-ad-top,
.sidebar-ad-wrapper,
.sidebar-adbox,
.sidebar-ads,
.sidebar-ads-block,
.sidebar-ads-wrap,
.sidebar-adsdiv,
.sidebar-adv-container,
.sidebar-advert,
.sidebar-advertisement,
.sidebar-advertisment,
.sidebar-adverts,
.sidebar-adverts-header,
.sidebar-banner-ad,
.sidebar-below-ad-unit,
.sidebar-big-ad,
.sidebar-big-box-ad,
.sidebar-bottom-ad,
.sidebar-box-ad,
.sidebar-box-ads,
.sidebar-content-ad,
.sidebar-header-ads,
.sidebar-outbrain,
.sidebar-skyscraper-ad,
.sidebar-sponsored,
.sidebar-sponsors,
.sidebar-square-ad,
.sidebar-sticky--ad,
.sidebar-text-ad,
.sidebar-top-ad,
.sidebar-tower-ad,
.sidebar-zergnet,
.sidebarAD,
.sidebarAd,
.sidebarAdvert,
.sidebar__ad,
.sidebar_ad,
.sidebar_ad_300,
.sidebar_ad_300_250,
.sidebar_ad_container,
.sidebar_ad_holder,
.sidebar_ad_leaderboard,
.sidebar_ad_module,
.sidebar_ads,
.sidebar_ads_left,
.sidebar_ads_right,
.sidebar_ads_title,
.sidebar_adsense,
.sidebar_advert,
.sidebar_advertising,
.sidebar_box_ad,
.sidebar_right_ad,
.sidebar_skyscraper_ad,
.sidebar_sponsors,
.sidebarad,
.sidebarad_bottom,
.sidebaradbox,
.sidebaradcontent,
.sidebarads,
.sidebaradsense,
.sidebarbox__advertising,
.sidebarboxad,
.sidebox-ad,
.sidebox_ad,
.sideright_ads,
.sideskyad,
.signad,
.simple-ad-placeholder,
.simple_ads_manager_widget,
.simple_adsense_widget,
.simplead-container,
.simpleads-item,
.single-ad,
.single-ad-anchor,
.single-ad-wrap,
.single-ads,
.single-ads-section,
.single-bottom-ads,
.single-mpu,
.single-post-ad,
.single-post-ads,
.single-post-bottom-ads,
.single-top-ad {
display: none !important;
}


.singleAd,
.singleAdBox,
.singleAdsContainer,
.singlePostAd,
.single__outbrain,
.single_ad,
.single_ad_300x250,
.single_advert,
.single_bottom_ad,
.single_top_ad,
.singlead,
.singleads,
.singleadstopcstm2,
.singlepageleftad,
.singlepostad,
.singlepostadsense,
.singpagead,
.sister-ads,
.site-ad-block,
.site-ads,
.site-bottom-ad-slot,
.site-head-ads,
.site-header-ad,
.site-header__ads,
.site-top-ad,
.siteWideAd,
.site_ad,
.site_ad--gray,
.site_ad--label,
.site_ads,
.site_sponsers,
.sitesponsor,
.skinAd,
.sky-ad,
.sky-ad1,
.skyAd,
.skyAdd,
.skyAdvert,
.skyAdvert2,
.sky_ad,
.sky_ad_top,
.skyad,
.skyscraper-ad,
.skyscraper-ad-1,
.skyscraper-ad-container,
.skyscraper.ad,
.skyscraperAd,
.skyscraper_ad,
.skyscrapper-ads-container,
.slate-ad,
.slide-ad,
.slideAd,
.slide_ad,
.slidead,
.slider-ads,
.slider-item-ad,
.slider-right-advertisement-banner,
.sliderad,
.slideshow-ad,
.slideshow-ad-container,
.slideshow-ad-wrapper,
.slideshow-ads,
.slideshowAd,
.slideshowadvert,
.slottaboola,
.sm-ad,
.sm-admgnr-unit,
.sm-ads,
.sm-advertisement,
.sm-widget-ad-holder,
.sm_ad,
.small-ad,
.small-ad-header,
.small-ad-long,
.small-ads,
.smallAd,
.smallAdContainer,
.smallAds,
.smallAdvertisments,
.small_ad,
.small_ad_bg,
.small_ads,
.smallad,
.smalladblock,
.smallads,
.smalladscontainer,
.smallsponsorad,
.smart-ad,
.smartAd,
.smartad,
.smn-new-gpt-ad,
.snhb-ads-en,
.snippet-ad,
.snoadrotatewidgetwrap,
.speakol-widget,
.spinAdvert,
.splashy-ad-container,
.spon_link,
.sponadbox,
.sponlinkbox,
.spons-link,
.spons-wrap,
.sponsBox,
.sponsLinks,
.sponsWrap,
.sponsbox,
.sponser-link,
.sponserLink,
.sponslink,
.sponsor-ads,
.sponsor-area,
.sponsor-block,
.sponsor-bottom,
.sponsor-box,
.sponsor-btns,
.sponsor-inner,
.sponsor-left,
.sponsor-link,
.sponsor-links,
.sponsor-popup,
.sponsor-post,
.sponsor-right,
.sponsor-spot,
.sponsor-text,
.sponsor-text-container,
.sponsor-wrap,
.sponsorAd,
.sponsorArea,
.sponsorBlock,
.sponsorBottom,
.sponsorBox,
.sponsorFooter,
.sponsorFooter-container,
.sponsorLabel,
.sponsorLink,
.sponsorLinks,
.sponsorPanel,
.sponsorPost,
.sponsorPostWrap,
.sponsorStrip,
.sponsorText,
.sponsorTitle,
.sponsorTxt,
.sponsor_ad,
.sponsor_ad1,
.sponsor_ad2,
.sponsor_ad_area,
.sponsor_ad_section,
.sponsor_area,
.sponsor_bar,
.sponsor_block,
.sponsor_columns,
.sponsor_div,
.sponsor_footer,
.sponsor_image,
.sponsor_label,
.sponsor_line,
.sponsor_links,
.sponsor_logo,
.sponsor_placement,
.sponsor_popup,
.sponsor_post,
.sponsor_units,
.sponsorad,
.sponsoradlabel,
.sponsorads,
.sponsoradtitle,
.sponsored-ad,
.sponsored-ad-container,
.sponsored-ad-label,
.sponsored-add,
.sponsored-ads,
.sponsored-article,
.sponsored-article-item,
.sponsored-article-widget,
.sponsored-block,
.sponsored-buttons,
.sponsored-container,
.sponsored-container-bottom,
.sponsored-default,
.sponsored-display-ad,
.sponsored-header,
.sponsored-link,
.sponsored-links,
.sponsored-post,
.sponsored-post-container,
.sponsored-result,
.sponsored-results,
.sponsored-right,
.sponsored-slot,
.sponsored-tag,
.sponsored-text,
.sponsored-top,
.sponsored-widget,
.sponsoredAd,
.sponsoredAds,
.sponsoredBanners,
.sponsoredBar,
.sponsoredBottom,
.sponsoredBox,
.sponsoredContent,
.sponsoredEntry,
.sponsoredFeature,
.sponsoredInfo,
.sponsoredInner,
.sponsoredItem,
.sponsoredLabel,
.sponsoredLeft,
.sponsoredLink,
.sponsoredLinks,
.sponsoredLinks2,
.sponsoredLinksBox,
.sponsoredListing,
.sponsoredProduct,
.sponsoredResults,
.sponsoredSearch,
.sponsoredTop,
.sponsored_ad,
.sponsored_ads,
.sponsored_bar_text,
.sponsored_box,
.sponsored_by,
.sponsored_link,
.sponsored_links,
.sponsored_links2,
.sponsored_links_box,
.sponsored_links_container,
.sponsored_links_section,
.sponsored_post,
.sponsored_result,
.sponsored_results,
.sponsored_sidepanel,
.sponsored_ss,
.sponsored_text,
.sponsored_title,
.sponsored_well,
.sponsoredby,
.sponsoredlink,
.sponsoredlinks,
.sponsoredresults,
.sponsorheader,
.sponsoringbanner,
.sponsorlink,
.sponsorlink2,
.sponsormsg,
.sponsors-advertisment,
.sponsors-box,
.sponsors-footer,
.sponsors-module,
.sponsors-widget,
.sponsorsBanners,
.sponsors_box_container,
.sponsors_links,
.sponsors_spacer,
.sponsorsbanner,
.sponsorsbig,
.sponsorship-banner-bottom,
.sponsorship-box,
.sponsorship-chrome,
.sponsorship-container,
.sponsorship-leaderboard,
.sponsorshipContainer,
.sponsorship_ad,
.sponsorshipbox,
.sponsorwrapper,
.sponstitle,
.sponstop,
.spot-ad,
.spotim-amp-list-ad,
.spotlight-ad,
.spotlightAd,
.spt-footer-ad,
.sq_ad,
.sqrd-ad-manager,
.square-ad,
.square-ad-1,
.square-ad-container,
.square-ad-pane,
.square-ads,
.square-advt,
.square-adwrap,
.square-sidebar-ad,
.square-sponsorship,
.squareAd,
.squareAdWrap,
.squareAdd,
.squareAddtwo,
.squareAds,
.square_ad,
.squaread,
.squaread-container,
.squareadMain,
.squareads,
.squared_ad,
.squirrel_widget,
.sr-adsense,
.sr-advert,
.sraAdvert,
.srp-sidebar-ads,
.ssp-advert,
.standalonead,
.standard-ad-container,
.standard_ad_slot,
.static-ad,
.staticAd,
.static_mpu_wrap,
.staticad,
.sterra-ad,
.stick-ad-container,
.stickad,
.sticky-ad,
.sticky-ad-bottom,
.sticky-ad-container,
.sticky-ad-footer,
.sticky-ad-header,
.sticky-ad-wrapper,
.sticky-ads,
.sticky-ads-container,
.sticky-ads-content,
.sticky-adsense,
.sticky-advert-widget,
.sticky-bottom-ad,
.sticky-footer-ad,
.sticky-footer-ad-container,
.sticky-navbar-ad-container,
.sticky-rail-ad-container,
.sticky-side-ad,
.sticky-sidebar-ad,
.sticky-top-ad-wrap,
.stickyAd,
.stickyAdWrapper,
.stickyAdsGroup,
.stickyContainerMpu,
.stickyRailAd,
.sticky_ad_sidebar,
.sticky_ad_wrapper,
.sticky_ads,
.stickyad,
.stickyads,
.stickyadv,
.stky-ad-footer,
.stm-ad-player,
.stmAdHeightWidget,
.stock_ad,
.stocks-ad-tag,
.store-ads,
.story-ad,
.story-ad-container,
.story-ad-right,
.story-inline-advert,
.storyAd,
.storyAdvert,
.story__top__ad,
.story_ad_div,
.story_body_advert,
.storyad,
.storyad300,
.storyadHolderAfterLoad,
.stpro_ads,
.str-top-ad,
.strack_bnr,
.strawberry-ads,
.strawberry-ads__pretty-container,
.stream-ad,
.streamAd,
.strip-ad,
.stripad,
.style_k8mr7b-o_O-style_uhlm2,
.sub-ad,
.subAdBannerArea,
.subAdBannerHeader,
.subNavAd,
.subad,
.subheader_adsense,
.submenu_ad,
.subnav-ad-layout,
.subnav-ad-wrapper,
.subscribeAd,
.subscriber-ad,
.subscribox-ad,
.sudoku-ad,
.sugarad,
.suggAd,
.super-ad,
.superbanner-adcontent,
.support_ad,
.tabAd,
.tabAds,
.tab_ad,
.tab_ad_area,
.table-ad,
.tableAd1,
.tablet-ad,
.taboola,
.taboola-above-article,
.taboola-above-article-thumbnails,
.taboola-ad,
.taboola-banner,
.taboola-block,
.taboola-bottom-adunit,
.taboola-container,
.taboola-frame,
.taboola-general,
.taboola-in-plug-wrap,
.taboola-inbetweener,
.taboola-item,
.taboola-like-block,
.taboola-module,
.taboola-recommends,
.taboola-sidebar,
.taboola-sidebar-container,
.taboola-skip-wrapper,
.taboola-thumbnails-container,
.taboola-vertical,
.taboola-widget,
.taboola-wrapper,
.taboolaArticle,
.taboolaDiv,
.taboolaHeight,
.taboola__container,
.taboola_blk,
.taboola_body_ad,
.taboola_container,
.taboola_lhs,
.taboola_module,
.taboolaloader,
.tadm_ad_unit,
.takeover-ad,
.tallAdvert,
.tallad,
.tbboxad,
.tc-adbanner,
.tc_ad,
.tc_ad_unit,
.tcf-ad,
.td-a-ad,
.td-a-rec-id-custom_ad_1,
.td-a-rec-id-custom_ad_2,
.td-a-rec-id-custom_ad_3,
.td-a-rec-id-custom_ad_4,
.td-a-rec-id-custom_ad_5,
.td-ad,
.td-ad-m,
.td-ad-p,
.td-ad-tp,
.td-adspot-title,
.td-sponsor-title,
.tdAdHeader,
.td_ad,
.td_footer_ads,
.td_left_widget_ad,
.td_leftads,
.td_reklama_bottom,
.td_reklama_top,
.td_spotlight_ads,
.teaser--advertorial,
.teaser-ad,
.teaser-advertisement,
.teaser-sponsor,
.teaserAd,
.teaserAdContainer,
.teaserAdHeadline,
.teaser_ad,
.templates_ad_placement,
.test-adsense,
.testAd-holder,
.text-ad-sitewide,
.text-ad-top,
.text-advertisement,
.text-panel-ad,
.text-sponsor,
.textAd3,
.textAdBlock,
.textAdBox,
.textAds,
.textLinkAd,
.textSponsor,
.text_ad_title,
.text_ad_website,
.text_ads_2,
.text_ads_wrapper,
.text_adv,
.textad,
.textadContainer,
.textadbox,
.textadlink,
.textadscontainer,
.textadsds,
.textadsfoot,
.textadtext,
.textlinkads,
.th-ad,
.thb_ad_before_header,
.thb_ad_header,
.theAdvert,
.theads,
.theleftad,
.themonic-ad1,
.themonic-ad2,
.themonic-ad3,
.themonic-ad6,
.third-party-ad,
.thumb-ads,
.thumb_ad,
.thumbnailad,
.thumbs-adv,
.thumbs-adv-holder,
.tile--ad,
.tile-ad,
.tile-ad-container,
.tile-advert,
.tileAdContainer,
.tileAdWrap,
.tileAds,
.tile_AdBanner,
.tile_ad,
.tile_ad_container,
.tips_advertisement,
.title-ad,
.tl-ad-container,
.tmiads,
.tmo-ad,
.tmo-ad-ezoic,
.tncls_ad,
.tncls_ad_250,
.tncls_ad_300,
.tnt-ads,
.tnt-ads-container,
.tnt-dmp-reactive,
.tnw-ad,
.toaster-ad,
.toolkit-ad-shell,
.top-300-ad,
.top-ad,
.top-ad-728,
.top-ad-970x90,
.top-ad-anchor,
.top-ad-area,
.top-ad-banner-wrapper,
.top-ad-bloc,
.top-ad-block,
.top-ad-center,
.top-ad-container,
.top-ad-content,
.top-ad-deck,
.top-ad-desktop,
.top-ad-div,
.top-ad-horizontal,
.top-ad-inside,
.top-ad-module,
.top-ad-recirc,
.top-ad-right,
.top-ad-sidebar,
.top-ad-slot,
.top-ad-space,
.top-ad-sticky,
.top-ad-unit,
.top-ad-wrap,
.top-ad-wrapper,
.top-ad-zone,
.top-ad1,
.top-ad__sticky-wrapper,
.top-adbox,
.top-ads,
.top-ads-amp,
.top-ads-block,
.top-ads-bottom-bar,
.top-ads-container,
.top-ads-mobile,
.top-ads-wrapper,
.top-adsense,
.top-adsense-banner,
.top-adspace,
.top-adv,
.top-adv-container,
.top-adverbox,
.top-advert,
.top-advertisement,
.top-banner-468,
.top-banner-ad,
.top-banner-ad-container,
.top-banner-ad-wrapper,
.top-banner-add,
.top-banner-ads,
.top-banner-advert,
.top-bar-ad-related,
.top-box-right-ad,
.top-content-adplace,
.top-dfp-wrapper,
.top-fixed-ad,
.top-half-page-ad,
.top-header-ad,
.top-header-ad1,
.top-horiz-ad,
.top-horizontal-ad,
.top-item-ad,
.top-leaderboard-ad,
.top-left-ad,
.top-menu-ads,
.top-post-ad,
.top-post-ads,
.top-right-ad,
.top-side-advertisement,
.top-sidebar-ad,
.top-sidebar-adbox,
.top-site-ad,
.top-sponsored-header,
.top-story-ad,
.top-topics__ad,
.top-wide-ad-container,
.top.ad,
.top250Ad,
.top300ad,
.topAD,
.topAd,
.topAd728x90,
.topAdBanner,
.topAdBar,
.topAdBlock,
.topAdCenter,
.topAdContainer,
.topAdIn,
.topAdLeft,
.topAdRight,
.topAdSpacer,
.topAdWrap,
.topAdWrapper,
.topAdd,
.topAds,
.topAdsWrappper,
.topAdvBox,
.topAdvert,
.topAdvertisement,
.topAdvertistemt,
.topAdverts,
.topAlertAds,
.topArtAd,
.topArticleAds,
.topBannerAd,
.topBarAd,
.topBoxAdvertisement,
.topLeaderboardAd,
.topRightAd,
.top_Ad,
.top__ad,
.top_ad,
.top_ad1,
.top_ad_728,
.top_ad_728_90,
.top_ad_banner,
.top_ad_big,
.top_ad_disclaimer,
.top_ad_div,
.top_ad_holder,
.top_ad_inner,
.top_ad_label,
.top_ad_list,
.top_ad_long,
.top_ad_post,
.top_ad_responsive,
.top_ad_seperate,
.top_ad_short,
.top_ad_wrap,
.top_ad_wrapper,
.top_adbox1,
.top_adbox2,
.top_adh,
.top_ads,
.top_ads_container,
.top_adsense,
.top_adspace,
.top_adv,
.top_adv_content,
.top_advert,
.top_advertisement,
.top_advertising_lb,
.top_advertizing_cnt,
.top_bar_ad,
.top_big_ads,
.top_container_ad,
.top_corner_ad,
.top_head_ads,
.top_header_ad,
.top_header_ad_inner,
.top_right_ad,
.top_rightad,
.top_side_adv,
.top_sponsor,
.topad-area,
.topad-bar,
.topad-bg,
.topad1,
.topad2,
.topadbar,
.topadblock,
.topadbox,
.topadcont,
.topadrow,
.topads,
.topads-spacer,
.topadsbx,
.topadsection,
.topadspace,
.topadspot,
.topadtara,
.topadtxt,
.topadvert,
.topbannerAd,
.topbar-ad-parent,
.topbar-ad-unit,
.topboardads,
.topright_ad,
.topside_ad,
.topsidebarad,
.tout-ad,
.tout-ad-embed,
.tower-ad,
.tower-ad-abs,
.tower-ad-b,
.tower-ad-wrapper,
.tower-ads-container,
.towerAd,
.towerAdLeft,
.towerAds,
.tower_ad,
.tower_ad_desktop,
.tower_ad_disclaimer,
.towerad,
.tp-ad-label,
.tp_ads,
.tpd-banner-ad-container,
.tpd-banner-desktop,
.tpd-box-ad-d,
.trb_taboola,
.trc-content-sponsored,
.trc-content-sponsoredUB,
.trc-first-recommendation,
.trc-spotlight-first-recommendation,
.trc_excludable,
.trc_excludable,
.trc_rbox,
.trc_rbox_border_elm,
.trc_rbox_div,
.trc_related_container,
.trc_spotlight_item,
.trend-card-advert,
.trend-card-advert__title,
.tsm-ad,
.tt_ads,
.ttb_adv_bg,
.tw-adv-gpt,
.txt_ads,
.txtad_area,
.txtadbox,
.txtadvertise,
.type-ad,
.u-ads,
.u-lazy-ad-wrapper,
.udn-ads,
.ue-c-ad,
.ult_vp_videoPlayerAD,
.under-header-ad,
.under-player-ad,
.under-player-ads,
.under_ads,
.underplayer__ad,
.uniAdBox,
.uniAds,
.unionAd,
.unit-ad,
.unspoken-adplace,
.upper-ad-box,
.upper-ad-space,
.upper_ad,
.upx-ad-placeholder,
.us_ad,
.uvs-ad-full-width,
.vadvert,
.van_taboola,
.van_vid_carousel,
.variable-ad,
.variableHeightAd,
.vce-ad-below-header,
.vce-ad-container,
.vce-header-ads,
.vce_adsense_expand,
.vce_adsense_widget,
.vce_adsense_wrapper,
.vdvwad,
.vert-ad,
.vert-ads,
.vertad,
.vertical-ad,
.vertical-ads,
.vertical-adsense,
.vertical-trending-ads,
.verticalAd,
.verticalAdText,
.vertical_ad,
.vertical_ads,
.verticalad,
.vf-ad-comments,
.vf-conversation-starter__ad,
.vf-promo-gtag,
.vf3-conversations-list__promo,
.vi-sticky-ad,
.video--container--aniview,
.video-ad-bottom,
.video-ad-container,
.video-ad-content,
.video-ads,
.video-ads-container,
.video-ads-grid,
.video-ads-wrapper,
.video-adv,
.video-advert,
.video-archive-ad,
.video-boxad,
.video-inline-ads,
.video-page__adv,
.video-right-ad,
.video-right-ads,
.video-side__adv_title,
.videoAd-wrapper,
.videoAd300,
.videoBoxAd,
.videoOverAd300,
.videoOverAdSmall,
.videoPauseAd,
.videoSideAds,
.video_ad,
.video_ads,
.videoad,
.videoad-base,
.videoad2,
.videos-ad,
.videos-ad-wrap,
.vidible-wrapper,
.view-Advertisment,
.view-ad,
.view-ads,
.view-advertisement,
.view-advertisements,
.view-advertorials,
.view-adverts,
.view-article-inner-ads,
.view-homepage-center-ads,
.view-id-Advertisment,
.view-id-ads,
.view-id-advertisement,
.view-image-ads,
.view-site-ads,
.view_ad,
.views-field-field-ad,
.visibleAd,
.vjs-ad-iframe,
.vjs-ad-overlay,
.vjs-ima3-ad-container,
.vjs-marker-ad,
.vjs-overlay.size-300x250,
.vl-ad-item,
.vl-advertisment,
.vl-header-ads,
.vlog-ad,
.vm-ad-horizontal,
.vmag_medium_ad,
.voc-ob-wrapper,
.vodl-ad__bigsizebanner,
.vpnad,
.vs-advert-300x250,
.vsw-ads,
.vswAdContainer,
.vuukle-ad-block,
.vuukle-ads,
.vw-header__ads,
.w-ad-box,
.w-adsninja-video-player,
.w-content--ad,
.wAdvert,
.w_AdExternal,
.w_ad,
.waf-ad,
.wahAd,
.wahAdRight,
.waldo-display-unit,
.waldo-placeholder,
.waldo-placeholder-bottom,
.wall-ads-control,
.wall-ads-left,
.wall-ads-right,
.wallAd,
.wall_ad,
.wallad,
.wcAd,
.wcfAdLocation,
.wd-adunit,
.wdca_ad_item,
.wdca_custom_ad,
.wdt_ads,
.weatherad,
.web_ads,
.webadvert-container,
.webpart-wrap-advert,
.website-ad-space,
.well-ad,
.werbungAd,
.wfb-ad,
.wg-ad-square,
.wh-advert,
.wh_ad,
.wh_ad_inner,
.when-show-ads,
.wide-ad,
.wide-ad-container,
.wide-ad-new-layout,
.wide-ad-outer,
.wide-ads-container,
.wide-advert,
.wide-footer-ad,
.wide-header-ad,
.wide-skyscraper-ad,
.wideAd,
.wideAdTable,
.widePageAd,
.wide_ad,
.wide_adBox_footer,
.wide_ad_unit,
.wide_ad_unit_top,
.wide_ads,
.wide_google_ads,
.wide_grey_ad_box,
.wide_sponsors,
.widead,
.wideadbox,
.widget--ad,
.widget--ajdg_bnnrwidgets,
.widget--local-ads,
.widget-300x250ad,
.widget-ad,
.widget-ad-codes,
.widget-ad-image,
.widget-ad-script,
.widget-ad-sky,
.widget-ad-zone,
.widget-ad300x250,
.widget-adcode,
.widget-ads,
.widget-adsense,
.widget-adv,
.widget-advads-ad-widget,
.widget-advert-970,
.widget-advertisement,
.widget-dfp,
.widget-group-Ads,
.widget-highlight-ads,
.widget-sponsor,
.widget-sponsor--container,
.widget-text-ad,
.widget1-ad,
.widget10-ad,
.widget4-ad,
.widget6-ad,
.widget7-ad,
.widgetAD,
.widgetAds,
.widgetContentIfrWrapperAd,
.widgetSponsors,
.widget_300x250_advertisement,
.widget_abn_admanager_sidestealer,
.widget_ad,
.widget_ad-widget,
.widget_ad125,
.widget_ad300,
.widget_ad_300,
.widget_ad_boxes_widget,
.widget_ad_layers_ad_widget,
.widget_ad_rotator,
.widget_ad_widget,
.widget_adace_ads_widget,
.widget_admanagerwidget,
.widget_adrotate_widgets,
.widget_ads,
.widget_ads_entries,
.widget_ads_widget,
.widget_adsblock,
.widget_adsensem,
.widget_adsensewidget,
.widget_adsingle,
.widget_adswidget1-quick-adsense,
.widget_adswidget2-quick-adsense,
.widget_adswidget3-quick-adsense,
.widget_adv_location,
.widget_adv_text,
.widget_advads_ad_widget,
.widget_advert,
.widget_advert_content,
.widget_advert_widget,
.widget_advertisement,
.widget_advertisements,
.widget_advertisment,
.widget_advwidget,
.widget_alaya_ad,
.widget_arvins_ad_randomizer,
.widget_awaken_pro_medium_rectangle_ad,
.widget_better-ads,
.widget_com_ad_widget,
.widget_core_ads_desk,
.widget_cpxadvert_widgets,
.widget_customad_widget,
.widget_customadvertising,
.widget_dfp,
.widget_doubleclick_widget,
.widget_ep_rotating_ad_widget,
.widget_epcl_ads_fluid,
.widget_evolve_ad_gpt_widget,
.widget_html_snippet_ad_widget,
.widget_ima_ads,
.widget_ione-dart-ad,
.widget_ipm_sidebar_ad,
.widget_island_ad,
.widget_joblo_complex_ad,
.widget_long_ads_widget,
.widget_newspack-ads-widget,
.widget_njads_single_widget,
.widget_openxwpwidget,
.widget_outbrain,
.widget_outbrain_widget,
.widget_plugrush_widget,
.widget_pmc-ads-widget,
.widget_quads_ads_widget,
.widget_rdc_ad_widget,
.widget_sej_sidebar_ad,
.widget_sidebar_adrotate_tedo_single_widget,
.widget_sidebaradwidget,
.widget_singlead,
.widget_sponsored_content,
.widget_supermag_ad,
.widget_supernews_ad,
.widget_taboola,
.widget_text_adsense,
.widget_themoneytizer_widget,
.widget_thesun_dfp_ad_widget,
.widget_tt_ads_widget,
.widget_viral_advertisement,
.widget_wp-bannerize-widget,
.widget_wp_ads_gpt_widget,
.widget_wp_insert_ad_widget,
.widget_wpex_advertisement,
.widget_wpstealthads_widget,
.widgetads,
.width-ad-slug,
.wikia-ad,
.wio-xbanner,
.worldplus-ad,
.wp-ads-target,
.wp-block-ad-slot,
.wp-block-gamurs-ad,
.wp-block-tpd-block-tpd-ads,
.wp125ad,
.wp125ad_2,
.wp_bannerize,
.wp_bannerize_banner_box,
.wp_bannerize_container,
.wpadcenter-ad-container,
.wpadvert,
.wpd-advertisement,
.wpex-ads-widget,
.wppaszone,
.wps-player-wrap,
.wpvqgr-a-d-s,
.wpx-bannerize,
.wpx_bannerize,
.wpx_bannerize_banner_box,
.wrap-ad,
.wrap-ads,
.wrap_boxad,
.wrapad,
.wrapper-ad,
.wrapper-header-ad-slot,
.wrapper_ad,
.wrapper_advertisement,
.wrapperad,
.ww_ads_banner_wrapper,
.xeiro-ads,
.xmlad,
.xpot-horizontal,
.y-ads,
.y-ads-wide,
.yaAds,
.yad-sponsored,
.yahooAd,
.yahooAds,
.yahoo_ad,
.yahoo_ads,
.yahooad,
.yahooads,
.yan-sponsored,
.zerg-widget,
.zerg-widgets,
.zergnet,
.zergnet-holder,
.zergnet-row,
.zergnet-unit,
.zergnet-widget,
.zergnet-widget-container,
.zergnet-widget__header,
.zergnet-widget__subtitle,
.zergnet__container,
.zeus-ad,
.zeusAdWrapper,
.zeusAd__container,
.zmgad-full-width,
.zmgad-right-rail,
.zone-advertisement,
.zoneAds,
.zox-post-ad-wrap,
.zox-post-bot-ad,
.zox-widget-side-ad,
.zox_ad_widget,
.zox_adv_widget,
AD-SLOT,
AD-TRIPLE-BOX,
AMP-AD,
DFP-AD,
[class^="adDisplay-module"],
[class^="amp-ad-"],
[class^="div-gpt-ad"],
[class^="s2nPlayer"],
[class^="tile-picker__CitrusBannerContainer-sc-"],
[data-ad-cls],
[data-ad-manager-id],
[data-ad-module],
[data-ad-name],
[data-ad-width],
[data-adblockkey],
[data-adbridg-ad-class],
[data-adshim],
[data-advadstrackid],
[data-block-type="ad"],
[data-css-class="dfp-inarticle"],
[data-d-ad-id],
[data-desktop-ad-id],
[data-dynamic-ads],
[data-ez-name],
[data-freestar-ad][id],
[data-id^="div-gpt-ad"],
[data-identity="adhesive-ad"],
[data-m-ad-id],
[data-mobile-ad-id],
[data-name="adaptiveConstructorAd"],
[data-rc-widget="data-rc-widget"],
[data-rc-widget],
[data-revive-zoneid]>iframe,
[data-role="tile-ads-module"],
[data-taboola-options],
[data-template-type="nativead"],
[data-testid="adBanner-wrapper"],
[data-testid="ad_testID"],
[data-testid="prism-ad-wrapper"],
[data-type="ad-vertical"],
[data-wpas-zoneid],
[href="//sexcams.plus/"],
[href="https://jdrucker.com/gold"]>img,
[href="https://masstortfinancing.com"] img,
[href="https://ourgoldguy.com/contact/"] img,
[href="https://www.masstortfinancing.com/"]>img,
[href^="http://clicks.totemcash.com/"],
[href^="http://mypillow.com/"]>img,
[href^="http://www.mypillow.com/"]>img,
[href^="https://ad.admitad.com/"],
[href^="https://ad1.adfarm1.adition.com/"],
[href^="https://affiliate.fastcomet.com/"]>img,
[href^="https://antiagingbed.com/discount/"]>img,
[href^="https://ap.octopuspop.com/click/"]>img,
[href^="https://awbbjmp.com/"],
[href^="https://charmingdatings.life/"],
[href^="https://clicks.affstrack.com/"]>img,
[href^="https://cpa.10kfreesilver.com/"],
[href^="https://glersakr.com/"],
[href^="https://go.xlrdr.com"],
[href^="https://goldcometals.com/clk.trk"],
[href^="https://ilovemyfreedoms.com/landing-"],
[href^="https://istlnkcl.com/"],
[href^="https://join.girlsoutwest.com/"],
[href^="https://join.playboyplus.com/track/"],
[href^="https://join3.bannedsextapes.com"],
[href^="https://mylead.global/stl/"]>img,
[href^="https://mypatriotsupply.com/"]>img,
[href^="https://mypillow.com/"]>img,
[href^="https://mystore.com/"]>img,
[href^="https://noqreport.com/"]>img,
[href^="https://optimizedelite.com/"]>img,
[href^="https://rapidgator.net/article/premium/ref/"],
[href^="https://routewebtk.com/"],
[href^="https://shiftnetwork.infusionsoft.com/go/"]>img,
[href^="https://track.aftrk1.com/"],
[href^="https://track.fiverr.com/visit/"]>img,
[href^="https://turtlebids.irauctions.com/"] img,
[href^="https://v.investologic.co.uk/"],
[href^="https://wct.link/click?"],
[href^="https://www.avantlink.com/click.php"] img,
[href^="https://www.brighteonstore.com/products/"] img,
[href^="https://www.cloudways.com/en/?id"],
[href^="https://www.herbanomic.com/"]>img,
[href^="https://www.hostg.xyz/"]>img,
[href^="https://www.mypatriotsupply.com/"]>img,
[href^="https://www.mypillow.com/"]>img,
[href^="https://www.profitablegatecpm.com/"],
[href^="https://www.restoro.com/"],
[href^="https://www.targetingpartner.com/"],
[href^="https://zone.gotrackier.com/"],
[href^="https://zstacklife.com/"] img,
[id^="ad-wrap-"],
[id^="ad_sky"],
[id^="ad_slider"],
[id^="div-gpt-ad"],
[id^="section-ad-banner"],
[name^="google_ads_iframe"],
[onclick*="content.ad/"],
[onclick^="location.href='https://1337x.vpnonly.site/"],
a-ad,
a[data-href^="http://ads.trafficjunky.net/"],
a[data-oburl^="https://paid.outbrain.com/network/redir?"],
a[data-redirect^="https://paid.outbrain.com/network/redir?"],
a[data-url^="https://vulpix.bet/?ref="],
a[href*="&maxads="],
a[href*=".adsrv.eacdn.com/"],
a[href*=".cfm?domain="][href*="&fp="],
a[href*=".engine.adglare.net/"],
a[href*=".foxqck.com/"],
a[href*=".g2afse.com/"],
a[href*="//daichoho.com/"],
a[href*="//jjgirls.com/sex/Chaturbate"],
a[href*="/jump/next.php?r="],
a[href^=" https://www.friendlyduck.com/AF_"],
a[href^="//ejitsirdosha.net/"],
a[href^="//go.eabids.com/"],
a[href^="//s.st1net.com/splash.php"],
a[href^="//s.zlinkd.com/"],
a[href^="//startgaming.net/tienda/" i],
a[href^="//stighoazon.com/"],
a[href^="http://adultfriendfinder.com/go/"],
a[href^="http://annulmentequitycereals.com/"],
a[href^="http://avthelkp.net/"],
a[href^="http://bongacams.com/track?"],
a[href^="http://cam4com.go2cloud.org/aff_c?"],
a[href^="http://coefficienttolerategravel.com/"],
a[href^="http://com-1.pro/"],
a[href^="http://deskfrontfreely.com/"],
a[href^="http://dragfault.com/"],
a[href^="http://dragnag.com/"],
a[href^="http://eighteenderived.com/"],
a[href^="http://eslp34af.click/"],
a[href^="http://guestblackmail.com/"],
a[href^="http://handgripvegetationhols.com/"],
a[href^="http://li.blogtrottr.com/click?"],
a[href^="http://muzzlematrix.com/"],
a[href^="http://naggingirresponsible.com/"],
a[href^="http://partners.etoro.com/"],
a[href^="http://premonitioninventdisagree.com/"],
a[href^="http://revolvemockerycopper.com/"],
a[href^="http://sarcasmadvisor.com/"],
a[href^="http://stickingrepute.com/"],
a[href^="http://tc.tradetracker.net/"]>img,
a[href^="http://trk.globwo.online/"],
a[href^="http://troopsassistedstupidity.com/"],
a[href^="http://vnte9urn.click/"],
a[href^="http://www.adultempire.com/unlimited/promo?"][href*="&partner_id="],
a[href^="http://www.friendlyduck.com/AF_"],
a[href^="http://www.h4trck.com/"],
a[href^="http://www.iyalc.com/"],
a[href^="https://123-stream.org/"],
a[href^="https://1betandgonow.com/"],
a[href^="https://6-partner.com/"],
a[href^="https://81ac.xyz/"],
a[href^="https://a-ads.com/"],
a[href^="https://a.adtng.com/"],
a[href^="https://a.bestcontentfood.top/"],
a[href^="https://a.bestcontentoperation.top/"],
a[href^="https://a.bestcontentweb.top/"],
a[href^="https://a.candyai.love/"],
a[href^="https://a.medfoodhome.com/"],
a[href^="https://a.medfoodsafety.com/"],
a[href^="https://a2.adform.net/"],
a[href^="https://ab.advertiserurl.com/aff/"],
a[href^="https://activate-game.com/"],
a[href^="https://ad.doubleclick.net/"],
a[href^="https://ad.zanox.com/ppc/"]>img,
a[href^="https://adclick.g.doubleclick.net/"],
a[href^="https://ads.betfair.com/redirect.aspx?"],
a[href^="https://ads.leovegas.com/"],
a[href^="https://ads.planetwin365affiliate.com/"],
a[href^="https://adultfriendfinder.com/go/"],
a[href^="https://ak.hauchiwu.com/"],
a[href^="https://ak.oalsauwy.net/"],
a[href^="https://ak.psaltauw.net/"],
a[href^="https://allhost.shop/aff.php?"],
a[href^="https://auesk.cfd/"],
a[href^="https://ausoafab.net/"],
a[href^="https://aweptjmp.com/"],
a[href^="https://awptjmp.com/"],
a[href^="https://banners.livepartners.com/"],
a[href^="https://bc.game/"],
a[href^="https://billing.purevpn.com/aff.php"]>img,
a[href^="https://black77854.com/"],
a[href^="https://bngprm.com/"],
a[href^="https://bngpt.com/"],
a[href^="https://bodelen.com/"],
a[href^="https://bongacams10.com/track?"],
a[href^="https://bongacams2.com/track?"],
a[href^="https://bs.serving-sys.com"],
a[href^="https://cam4com.go2cloud.org/"],
a[href^="https://camfapr.com/landing/click/"],
a[href^="https://cams.imagetwist.com/in/?track="],
a[href^="https://chaturbate.com/in/?"],
a[href^="https://chaturbate.jjgirls.com/?track="],
a[href^="https://claring-loccelkin.com/"],
a[href^="https://click.candyoffers.com/"],
a[href^="https://click.dtiserv2.com/"],
a[href^="https://click.hoolig.app/"],
a[href^="https://click.linksynergy.com/fs-bin/"]>img,
a[href^="https://clickadilla.com/"],
a[href^="https://clickins.slixa.com/"],
a[href^="https://clicks.pipaffiliates.com/"],
a[href^="https://clixtrac.com/"],
a[href^="https://combodef.com/"],
a[href^="https://ctjdwm.com/"],
a[href^="https://ctosrd.com/"],
a[href^="https://ctrdwm.com/"],
a[href^="https://datewhisper.life/"],
a[href^="https://disobediencecalculatormaiden.com/"],
a[href^="https://dl-protect.net/"],
a[href^="https://drumskilxoa.click/"],
a[href^="https://eergortu.net/"],
a[href^="https://engine.blueistheneworanges.com/"],
a[href^="https://engine.flixtrial.com/"],
a[href^="https://engine.phn.doublepimp.com/"],
a[href^="https://explore-site.com/"],
a[href^="https://fastestvpn.com/lifetime-special-deal?a_aid="],
a[href^="https://fc.lc/ref/"],
a[href^="https://financeads.net/tc.php?"],
a[href^="https://gamingadlt.com/?offer="],
a[href^="https://get-link.xyz/"],
a[href^="https://get.surfshark.net/aff_c?"][href*="&aff_id="]>img,
a[href^="https://getmatchedlocally.com/"],
a[href^="https://getvideoz.click/"],
a[href^="https://gml-grp.com/"],
a[href^="https://go.admjmp.com"],
a[href^="https://go.bushheel.com/"],
a[href^="https://go.cmtaffiliates.com/"],
a[href^="https://go.dmzjmp.com"],
a[href^="https://go.etoro.com/"]>img,
a[href^="https://go.goaserv.com/"],
a[href^="https://go.grinsbest.com/"],
a[href^="https://go.hpyjmp.com"],
a[href^="https://go.hpyrdr.com/"],
a[href^="https://go.markets.com/visit/?bta="],
a[href^="https://go.mnaspm.com/"],
a[href^="https://go.nordvpn.net/aff"]>img,
a[href^="https://go.rmhfrtnd.com/"],
a[href^="https://go.skinstrip.net"][href*="?campaignId="],
a[href^="https://go.strpjmp.com/"],
a[href^="https://go.tmrjmp.com"],
a[href^="https://go.trackitalltheway.com/"],
a[href^="https://go.xlirdr.com"],
a[href^="https://go.xlivrdr.com"],
a[href^="https://go.xlviiirdr.com"],
a[href^="https://go.xlviirdr.com"],
a[href^="https://go.xlvirdr.com"],
a[href^="https://go.xtbaffiliates.com/"],
a[href^="https://go.xxxiijmp.com"],
a[href^="https://go.xxxijmp.com"],
a[href^="https://go.xxxjmp.com"],
a[href^="https://go.xxxvjmp.com/"],
a[href^="https://golinks.work/"],
a[href^="https://hot-growngames.life/"],
a[href^="https://hotplaystime.life/"],
a[href^="https://in.rabbtrk.com/"],
a[href^="https://intenseaffiliates.com/redirect/"],
a[href^="https://iqbroker.com/"][href*="?aff="],
a[href^="https://ismlks.com/"],
a[href^="https://italarizege.xyz/"],
a[href^="https://itubego.com/video-downloader/?affid="],
a[href^="https://jaxofuna.com/"],
a[href^="https://join.dreamsexworld.com/"],
a[href^="https://join.sexworld3d.com/track/"],
a[href^="https://join.virtuallust3d.com/"],
a[href^="https://join.virtualtaboo.com/track/"],
a[href^="https://juicyads.in/"],
a[href^="https://kiksajex.com/"],
a[href^="https://l.hyenadata.com/"],
a[href^="https://land.brazzersnetwork.com/landing/"],
a[href^="https://landing.brazzersnetwork.com/"],
a[href^="https://lead1.pl/"],
a[href^="https://lijavaxa.com/"],
a[href^="https://lnkxt.bannerator.com/"],
a[href^="https://lobimax.com/"],
a[href^="https://loboclick.com/"],
a[href^="https://lone-pack.com/"],
a[href^="https://losingoldfry.com/"],
a[href^="https://m.do.co/c/"]>img,
a[href^="https://maymooth-stopic.com/"],
a[href^="https://mediaserver.entainpartners.com/renderBanner.do?"],
a[href^="https://mediaserver.gvcaffiliates.com/renderBanner.do?"],
a[href^="https://mmwebhandler.aff-online.com/"],
a[href^="https://myclick-2.com/"],
a[href^="https://natour.naughtyamerica.com/track/"],
a[href^="https://ndt5.net/"],
a[href^="https://ngineet.cfd/"],
a[href^="https://offhandpump.com/"],
a[href^="https://osfultrbriolenai.info/"],
a[href^="https://paid.outbrain.com/network/redir?"],
a[href^="https://pb-front.com/"],
a[href^="https://pb-imc.com/"],
a[href^="https://pb-track.com/"],
a[href^="https://play1ad.shop/"],
a[href^="https://playnano.online/offerwalls/?ref="],
a[href^="https://porntubemate.com/"],
a[href^="https://postback1win.com/"],
a[href^="https://prf.hn/click/"][href*="/adref:"]>img,
a[href^="https://prf.hn/click/"][href*="/camref:"]>img,
a[href^="https://prf.hn/click/"][href*="/creativeref:"]>img,
a[href^="https://pubads.g.doubleclick.net/"],
a[href^="https://quotationfirearmrevision.com/"],
a[href^="https://random-affiliate.atimaze.com/"],
a[href^="https://rixofa.com/"],
a[href^="https://s.cant3am.com/"],
a[href^="https://s.deltraff.com/"],
a[href^="https://s.ma3ion.com/"],
a[href^="https://s.optzsrv.com/"],
a[href^="https://s.zlink3.com/"],
a[href^="https://s.zlinkd.com/"],
a[href^="https://safesurfingtoday.com/"][href*="?skip="],
a[href^="https://serve.awmdelivery.com/"],
a[href^="https://service.bv-aff-trx.com/"],
a[href^="https://sexynearme.com/"],
a[href^="https://slkmis.com/"],
a[href^="https://snowdayonline.xyz/"],
a[href^="https://softwa.cfd/"],
a[href^="https://startgaming.net/tienda/" i],
a[href^="https://static.fleshlight.com/images/banners/"],
a[href^="https://streamate.com/landing/click/"],
a[href^="https://svb-analytics.trackerrr.com/"],
a[href^="https://syndicate.contentsserved.com/"],
a[href^="https://syndication.dynsrvtbg.com/"],
a[href^="https://syndication.exoclick.com/"],
a[href^="https://syndication.optimizesrv.com/"],
a[href^="https://t.acam.link/"],
a[href^="https://t.adating.link/"],
a[href^="https://t.ajrkm1.com/"],
a[href^="https://t.ajrkm3.com/"],
a[href^="https://t.ajump1.com/"],
a[href^="https://t.aslnk.link/"],
a[href^="https://t.hrtye.com/"],
a[href^="https://tatrck.com/"],
a[href^="https://tc.tradetracker.net/"]>img,
a[href^="https://tm-offers.gamingadult.com/"],
a[href^="https://torguard.net/aff.php"]>img,
a[href^="https://tour.mrskin.com/"],
a[href^="https://track.1234sd123.com/"],
a[href^="https://track.adform.net/"],
a[href^="https://track.afcpatrk.com/"],
a[href^="https://track.aftrk3.com/"],
a[href^="https://track.totalav.com/"],
a[href^="https://track.ultravpn.com/"],
a[href^="https://track.wg-aff.com"],
a[href^="https://tracker.loropartners.com/"],
a[href^="https://tracking.avapartner.com/"],
a[href^="https://traffdaq.com/"],
a[href^="https://trk.nfl-online-streams.club/"],
a[href^="https://trk.softonixs.xyz/"],
a[href^="https://trk.sportsflix4k.club/"],
a[href^="https://turnstileunavailablesite.com/"],
a[href^="https://twinrdsrv.com/"],
a[href^="https://upsups.click/"],
a[href^="https://vo2.qrlsx.com/"],
a[href^="https://voluum.prom-xcams.com/"],
a[href^="https://witnessjacket.com/"],
a[href^="https://www.adskeeper.com"],
a[href^="https://www.adultempire.com/"][href*="?partner_id="],
a[href^="https://www.adxsrve.com/"],
a[href^="https://www.bang.com/?aff="],
a[href^="https://www.bet365.com/"][href*="affiliate="],
a[href^="https://www.brazzersnetwork.com/landing/"],
a[href^="https://www.dating-finder.com/?ai_d="],
a[href^="https://www.dating-finder.com/signup/?ai_d="],
a[href^="https://www.dql2clk.com/"],
a[href^="https://www.endorico.com/Smartlink/"],
a[href^="https://www.financeads.net/tc.php?"],
a[href^="https://www.friendlyduck.com/AF_"],
a[href^="https://www.geekbuying.com/dynamic-ads/"],
a[href^="https://www.get-express-vpn.com/offer/"],
a[href^="https://www.goldenfrog.com/vyprvpn?offer_id="][href*="&aff_id="],
a[href^="https://www.googleadservices.com/pagead/aclk?"]>img,
a[href^="https://www.highcpmrevenuenetwork.com/"],
a[href^="https://www.highperformancecpmgate.com/"],
a[href^="https://www.infowarsstore.com/"]>img,
a[href^="https://www.liquidfire.mobi/"],
a[href^="https://www.mrskin.com/account/"],
a[href^="https://www.mrskin.com/tour"],
a[href^="https://www.nutaku.net/signup/landing/"],
a[href^="https://www.onlineusershielder.com/"],
a[href^="https://www.privateinternetaccess.com/"]>img,
a[href^="https://www.purevpn.com/"][href*="&utm_source=aff-"],
a[href^="https://www.sheetmusicplus.com/"][href*="?aff_id="],
a[href^="https://www.sugarinstant.com/?partner_id="],
a[href^="https://www.toprevenuegate.com/"],
a[href^="https://www8.smartadserver.com/"],
a[href^="https://xbet-4.com/"],
a[href^="https://zirdough.net/"],
a[onmousedown^="this.href='https://paid.outbrain.com/network/redir?"][target="_blank"],
a[onmousedown^="this.href='https://paid.outbrain.com/network/redir?"][target="_blank"]+.ob_source,
a[style="width:100%;height:100%;z-index:10000000000000000;position:absolute;top:0;left:0;"],
ad-shield-ads,
ad-slot,
amp-ad,
amp-ad-custom,
amp-connatix-player,
amp-embed[type="taboola"],
amp-fx-flying-carpet,
app-ad,
app-advertisement,
app-large-ad,
ark-top-ad,
aside[id^="adrotate_widgets-"],
atf-ad-slot,
bottomadblock,
citrus-ad-wrapper,
display-ad-component,
display-ads,
div[aria-label="Ads"],
div[class^="Adstyled__AdWrapper-"],
div[class^="Display_displayAd"],
div[class^="kiwi-ad-wrapper"],
div[class^="native-ad-"],
div[data-ad-placeholder],
div[data-ad-targeting],
div[data-ad-wrapper],
div[data-adname],
div[data-adunit-path],
div[data-adunit],
div[data-adzone],
div[data-alias="300x250 Ad 1"],
div[data-alias="300x250 Ad 2"],
div[data-contentexchange-widget],
div[data-dfp-id],
div[data-id-advertdfpconf],
div[id*="MarketGid"],
div[id*="ScriptRoot"],
div[id^="ad-div-"],
div[id^="ad-position-"],
div[id^="ad_position_"],
div[id^="adngin-"],
div[id^="adrotate_widgets-"],
div[id^="adspot-"],
div[id^="crt-"][style],
div[id^="dfp-ad-"],
div[id^="div-ads-"],
div[id^="div-gpt-"],
div[id^="ezoic-pub-ad-"],
div[id^="google_dfp_"],
div[id^="gpt_ad_"],
div[id^="lazyad-"],
div[id^="optidigital-adslot"],
div[id^="pa_sticky_ad_box_middle_"],
div[id^="rc-widget-"],
div[id^="st"][style^="z-index: 999999999;"],
div[id^="sticky_ad_"],
div[id^="taboola-stream-"],
div[id^="vuukle-ad-"],
div[id^="yandex_ad"],
div[id^="zergnet-widget"],
gpt-ad,
guj-ad,
hl-adsense,
img[src^="https://images.purevpnaffiliates.com"],
ins.adsbygoogle[data-ad-client],
ins.adsbygoogle[data-ad-slot],
ps-connatix-module,
span[data-ez-ph-id],
span[id^="ezoic-pub-ad-placeholder-"],
topadblock,
zeus-ad {
display: none !important;
}


  @-webkit-keyframes ww-4d25d92c-2c70-4b46-8096-ed64a89b0d6a-launcherOnOpen {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
      transform: translateY(0px) rotate(0deg);
    }

    30% {
      -webkit-transform: translateY(-5px) rotate(2deg);
      transform: translateY(-5px) rotate(2deg);
    }

    60% {
      -webkit-transform: translateY(0px) rotate(0deg);
      transform: translateY(0px) rotate(0deg);
    }


    90% {
      -webkit-transform: translateY(-1px) rotate(0deg);
      transform: translateY(-1px) rotate(0deg);

    }

    100% {
      -webkit-transform: translateY(-0px) rotate(0deg);
      transform: translateY(-0px) rotate(0deg);
    }
  }

  @keyframes ww-4d25d92c-2c70-4b46-8096-ed64a89b0d6a-launcherOnOpen {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
      transform: translateY(0px) rotate(0deg);
    }

    30% {
      -webkit-transform: translateY(-5px) rotate(2deg);
      transform: translateY(-5px) rotate(2deg);
    }

    60% {
      -webkit-transform: translateY(0px) rotate(0deg);
      transform: translateY(0px) rotate(0deg);
    }


    90% {
      -webkit-transform: translateY(-1px) rotate(0deg);
      transform: translateY(-1px) rotate(0deg);

    }

    100% {
      -webkit-transform: translateY(-0px) rotate(0deg);
      transform: translateY(-0px) rotate(0deg);
    }
  }

  @keyframes ww-4d25d92c-2c70-4b46-8096-ed64a89b0d6a-widgetOnLoad {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes ww-4d25d92c-2c70-4b46-8096-ed64a89b0d6a-widgetOnLoad {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }



.singleAd,
.singleAdBox,
.singleAdsContainer,
.singlePostAd,
.single__outbrain,
.single_ad,
.single_ad_300x250,
.single_advert,
.single_bottom_ad,
.single_top_ad,
.singlead,
.singleads,
.singleadstopcstm2,
.singlepageleftad,
.singlepostad,
.singlepostadsense,
.singpagead,
.sister-ads,
.site-ad-block,
.site-ads,
.site-bottom-ad-slot,
.site-head-ads,
.site-header-ad,
.site-header__ads,
.site-top-ad,
.siteWideAd,
.site_ad,
.site_ad--gray,
.site_ad--label,
.site_ads,
.site_sponsers,
.sitesponsor,
.skinAd,
.sky-ad,
.sky-ad1,
.skyAd,
.skyAdd,
.skyAdvert,
.skyAdvert2,
.sky_ad,
.sky_ad_top,
.skyad,
.skyscraper-ad,
.skyscraper-ad-1,
.skyscraper-ad-container,
.skyscraper.ad,
.skyscraperAd,
.skyscraper_ad,
.skyscrapper-ads-container,
.slate-ad,
.slide-ad,
.slideAd,
.slide_ad,
.slidead,
.slider-ads,
.slider-item-ad,
.slider-right-advertisement-banner,
.sliderad,
.slideshow-ad,
.slideshow-ad-container,
.slideshow-ad-wrapper,
.slideshow-ads,
.slideshowAd,
.slideshowadvert,
.slottaboola,
.sm-ad,
.sm-admgnr-unit,
.sm-ads,
.sm-advertisement,
.sm-widget-ad-holder,
.sm_ad,
.small-ad,
.small-ad-header,
.small-ad-long,
.small-ads,
.smallAd,
.smallAdContainer,
.smallAds,
.smallAdvertisments,
.small_ad,
.small_ad_bg,
.small_ads,
.smallad,
.smalladblock,
.smallads,
.smalladscontainer,
.smallsponsorad,
.smart-ad,
.smartAd,
.smartad,
.smn-new-gpt-ad,
.snhb-ads-en,
.snippet-ad,
.snoadrotatewidgetwrap,
.speakol-widget,
.spinAdvert,
.splashy-ad-container,
.spon_link,
.sponadbox,
.sponlinkbox,
.spons-link,
.spons-wrap,
.sponsBox,
.sponsLinks,
.sponsWrap,
.sponsbox,
.sponser-link,
.sponserLink,
.sponslink,
.sponsor-ads,
.sponsor-area,
.sponsor-block,
.sponsor-bottom,
.sponsor-box,
.sponsor-btns,
.sponsor-inner,
.sponsor-left,
.sponsor-link,
.sponsor-links,
.sponsor-popup,
.sponsor-post,
.sponsor-right,
.sponsor-spot,
.sponsor-text,
.sponsor-text-container,
.sponsor-wrap,
.sponsorAd,
.sponsorArea,
.sponsorBlock,
.sponsorBottom,
.sponsorBox,
.sponsorFooter,
.sponsorFooter-container,
.sponsorLabel,
.sponsorLink,
.sponsorLinks,
.sponsorPanel,
.sponsorPost,
.sponsorPostWrap,
.sponsorStrip,
.sponsorText,
.sponsorTitle,
.sponsorTxt,
.sponsor_ad,
.sponsor_ad1,
.sponsor_ad2,
.sponsor_ad_area,
.sponsor_ad_section,
.sponsor_area,
.sponsor_bar,
.sponsor_block,
.sponsor_columns,
.sponsor_div,
.sponsor_footer,
.sponsor_image,
.sponsor_label,
.sponsor_line,
.sponsor_links,
.sponsor_logo,
.sponsor_placement,
.sponsor_popup,
.sponsor_post,
.sponsor_units,
.sponsorad,
.sponsoradlabel,
.sponsorads,
.sponsoradtitle,
.sponsored-ad,
.sponsored-ad-container,
.sponsored-ad-label,
.sponsored-add,
.sponsored-ads,
.sponsored-article,
.sponsored-article-item,
.sponsored-article-widget,
.sponsored-block,
.sponsored-buttons,
.sponsored-container,
.sponsored-container-bottom,
.sponsored-default,
.sponsored-display-ad,
.sponsored-header,
.sponsored-link,
.sponsored-links,
.sponsored-post,
.sponsored-post-container,
.sponsored-result,
.sponsored-results,
.sponsored-right,
.sponsored-slot,
.sponsored-tag,
.sponsored-text,
.sponsored-top,
.sponsored-widget,
.sponsoredAd,
.sponsoredAds,
.sponsoredBanners,
.sponsoredBar,
.sponsoredBottom,
.sponsoredBox,
.sponsoredContent,
.sponsoredEntry,
.sponsoredFeature,
.sponsoredInfo,
.sponsoredInner,
.sponsoredItem,
.sponsoredLabel,
.sponsoredLeft,
.sponsoredLink,
.sponsoredLinks,
.sponsoredLinks2,
.sponsoredLinksBox,
.sponsoredListing,
.sponsoredProduct,
.sponsoredResults,
.sponsoredSearch,
.sponsoredTop,
.sponsored_ad,
.sponsored_ads,
.sponsored_bar_text,
.sponsored_box,
.sponsored_by,
.sponsored_link,
.sponsored_links,
.sponsored_links2,
.sponsored_links_box,
.sponsored_links_container,
.sponsored_links_section,
.sponsored_post,
.sponsored_result,
.sponsored_results,
.sponsored_sidepanel,
.sponsored_ss,
.sponsored_text,
.sponsored_title,
.sponsored_well,
.sponsoredby,
.sponsoredlink,
.sponsoredlinks,
.sponsoredresults,
.sponsorheader,
.sponsoringbanner,
.sponsorlink,
.sponsorlink2,
.sponsormsg,
.sponsors-advertisment,
.sponsors-box,
.sponsors-footer,
.sponsors-module,
.sponsors-widget,
.sponsorsBanners,
.sponsors_box_container,
.sponsors_links,
.sponsors_spacer,
.sponsorsbanner,
.sponsorsbig,
.sponsorship-banner-bottom,
.sponsorship-box,
.sponsorship-chrome,
.sponsorship-container,
.sponsorship-leaderboard,
.sponsorshipContainer,
.sponsorship_ad,
.sponsorshipbox,
.sponsorwrapper,
.sponstitle,
.sponstop,
.spot-ad,
.spotim-amp-list-ad,
.spotlight-ad,
.spotlightAd,
.spt-footer-ad,
.sq_ad,
.sqrd-ad-manager,
.square-ad,
.square-ad-1,
.square-ad-container,
.square-ad-pane,
.square-ads,
.square-advt,
.square-adwrap,
.square-sidebar-ad,
.square-sponsorship,
.squareAd,
.squareAdWrap,
.squareAdd,
.squareAddtwo,
.squareAds,
.square_ad,
.squaread,
.squaread-container,
.squareadMain,
.squareads,
.squared_ad,
.squirrel_widget,
.sr-adsense,
.sr-advert,
.sraAdvert,
.srp-sidebar-ads,
.ssp-advert,
.standalonead,
.standard-ad-container,
.standard_ad_slot,
.static-ad,
.staticAd,
.static_mpu_wrap,
.staticad,
.sterra-ad,
.stick-ad-container,
.stickad,
.sticky-ad,
.sticky-ad-bottom,
.sticky-ad-container,
.sticky-ad-footer,
.sticky-ad-header,
.sticky-ad-wrapper,
.sticky-ads,
.sticky-ads-container,
.sticky-ads-content,
.sticky-adsense,
.sticky-advert-widget,
.sticky-bottom-ad,
.sticky-footer-ad,
.sticky-footer-ad-container,
.sticky-navbar-ad-container,
.sticky-rail-ad-container,
.sticky-side-ad,
.sticky-sidebar-ad,
.sticky-top-ad-wrap,
.stickyAd,
.stickyAdWrapper,
.stickyAdsGroup,
.stickyContainerMpu,
.stickyRailAd,
.sticky_ad_sidebar,
.sticky_ad_wrapper,
.sticky_ads,
.stickyad,
.stickyads,
.stickyadv,
.stky-ad-footer,
.stm-ad-player,
.stmAdHeightWidget,
.stock_ad,
.stocks-ad-tag,
.store-ads,
.story-ad,
.story-ad-container,
.story-ad-right,
.story-inline-advert,
.storyAd,
.storyAdvert,
.story__top__ad,
.story_ad_div,
.story_body_advert,
.storyad,
.storyad300,
.storyadHolderAfterLoad,
.stpro_ads,
.str-top-ad,
.strack_bnr,
.strawberry-ads,
.strawberry-ads__pretty-container,
.stream-ad,
.streamAd,
.strip-ad,
.stripad,
.style_k8mr7b-o_O-style_uhlm2,
.sub-ad,
.subAdBannerArea,
.subAdBannerHeader,
.subNavAd,
.subad,
.subheader_adsense,
.submenu_ad,
.subnav-ad-layout,
.subnav-ad-wrapper,
.subscribeAd,
.subscriber-ad,
.subscribox-ad,
.sudoku-ad,
.sugarad,
.suggAd,
.super-ad,
.superbanner-adcontent,
.support_ad,
.tabAd,
.tabAds,
.tab_ad,
.tab_ad_area,
.table-ad,
.tableAd1,
.tablet-ad,
.taboola,
.taboola-above-article,
.taboola-above-article-thumbnails,
.taboola-ad,
.taboola-banner,
.taboola-block,
.taboola-bottom-adunit,
.taboola-container,
.taboola-frame,
.taboola-general,
.taboola-in-plug-wrap,
.taboola-inbetweener,
.taboola-item,
.taboola-like-block,
.taboola-module,
.taboola-recommends,
.taboola-sidebar,
.taboola-sidebar-container,
.taboola-skip-wrapper,
.taboola-thumbnails-container,
.taboola-vertical,
.taboola-widget,
.taboola-wrapper,
.taboolaArticle,
.taboolaDiv,
.taboolaHeight,
.taboola__container,
.taboola_blk,
.taboola_body_ad,
.taboola_container,
.taboola_lhs,
.taboola_module,
.taboolaloader,
.tadm_ad_unit,
.takeover-ad,
.tallAdvert,
.tallad,
.tbboxad,
.tc-adbanner,
.tc_ad,
.tc_ad_unit,
.tcf-ad,
.td-a-ad,
.td-a-rec-id-custom_ad_1,
.td-a-rec-id-custom_ad_2,
.td-a-rec-id-custom_ad_3,
.td-a-rec-id-custom_ad_4,
.td-a-rec-id-custom_ad_5,
.td-ad,
.td-ad-m,
.td-ad-p,
.td-ad-tp,
.td-adspot-title,
.td-sponsor-title,
.tdAdHeader,
.td_ad,
.td_footer_ads,
.td_left_widget_ad,
.td_leftads,
.td_reklama_bottom,
.td_reklama_top,
.td_spotlight_ads,
.teaser--advertorial,
.teaser-ad,
.teaser-advertisement,
.teaser-sponsor,
.teaserAd,
.teaserAdContainer,
.teaserAdHeadline,
.teaser_ad,
.templates_ad_placement,
.test-adsense,
.testAd-holder,
.text-ad-sitewide,
.text-ad-top,
.text-advertisement,
.text-panel-ad,
.text-sponsor,
.textAd3,
.textAdBlock,
.textAdBox,
.textAds,
.textLinkAd,
.textSponsor,
.text_ad_title,
.text_ad_website,
.text_ads_2,
.text_ads_wrapper,
.text_adv,
.textad,
.textadContainer,
.textadbox,
.textadlink,
.textadscontainer,
.textadsds,
.textadsfoot,
.textadtext,
.textlinkads,
.th-ad,
.thb_ad_before_header,
.thb_ad_header,
.theAdvert,
.theads,
.theleftad,
.themonic-ad1,
.themonic-ad2,
.themonic-ad3,
.themonic-ad6,
.third-party-ad,
.thumb-ads,
.thumb_ad,
.thumbnailad,
.thumbs-adv,
.thumbs-adv-holder,
.tile--ad,
.tile-ad,
.tile-ad-container,
.tile-advert,
.tileAdContainer,
.tileAdWrap,
.tileAds,
.tile_AdBanner,
.tile_ad,
.tile_ad_container,
.tips_advertisement,
.title-ad,
.tl-ad-container,
.tmiads,
.tmo-ad,
.tmo-ad-ezoic,
.tncls_ad,
.tncls_ad_250,
.tncls_ad_300,
.tnt-ads,
.tnt-ads-container,
.tnt-dmp-reactive,
.tnw-ad,
.toaster-ad,
.toolkit-ad-shell,
.top-300-ad,
.top-ad,
.top-ad-728,
.top-ad-970x90,
.top-ad-anchor,
.top-ad-area,
.top-ad-banner-wrapper,
.top-ad-bloc,
.top-ad-block,
.top-ad-center,
.top-ad-container,
.top-ad-content,
.top-ad-deck,
.top-ad-desktop,
.top-ad-div,
.top-ad-horizontal,
.top-ad-inside,
.top-ad-module,
.top-ad-recirc,
.top-ad-right,
.top-ad-sidebar,
.top-ad-slot,
.top-ad-space,
.top-ad-sticky,
.top-ad-unit,
.top-ad-wrap,
.top-ad-wrapper,
.top-ad-zone,
.top-ad1,
.top-ad__sticky-wrapper,
.top-adbox,
.top-ads,
.top-ads-amp,
.top-ads-block,
.top-ads-bottom-bar,
.top-ads-container,
.top-ads-mobile,
.top-ads-wrapper,
.top-adsense,
.top-adsense-banner,
.top-adspace,
.top-adv,
.top-adv-container,
.top-adverbox,
.top-advert,
.top-advertisement,
.top-banner-468,
.top-banner-ad,
.top-banner-ad-container,
.top-banner-ad-wrapper,
.top-banner-add,
.top-banner-ads,
.top-banner-advert,
.top-bar-ad-related,
.top-box-right-ad,
.top-content-adplace,
.top-dfp-wrapper,
.top-fixed-ad,
.top-half-page-ad,
.top-header-ad,
.top-header-ad1,
.top-horiz-ad,
.top-horizontal-ad,
.top-item-ad,
.top-leaderboard-ad,
.top-left-ad,
.top-menu-ads,
.top-post-ad,
.top-post-ads,
.top-right-ad,
.top-side-advertisement,
.top-sidebar-ad,
.top-sidebar-adbox,
.top-site-ad,
.top-sponsored-header,
.top-story-ad,
.top-topics__ad,
.top-wide-ad-container,
.top.ad,
.top250Ad,
.top300ad,
.topAD,
.topAd,
.topAd728x90,
.topAdBanner,
.topAdBar,
.topAdBlock,
.topAdCenter,
.topAdContainer,
.topAdIn,
.topAdLeft,
.topAdRight,
.topAdSpacer,
.topAdWrap,
.topAdWrapper,
.topAdd,
.topAds,
.topAdsWrappper,
.topAdvBox,
.topAdvert,
.topAdvertisement,
.topAdvertistemt,
.topAdverts,
.topAlertAds,
.topArtAd,
.topArticleAds,
.topBannerAd,
.topBarAd,
.topBoxAdvertisement,
.topLeaderboardAd,
.topRightAd,
.top_Ad,
.top__ad,
.top_ad,
.top_ad1,
.top_ad_728,
.top_ad_728_90,
.top_ad_banner,
.top_ad_big,
.top_ad_disclaimer,
.top_ad_div,
.top_ad_holder,
.top_ad_inner,
.top_ad_label,
.top_ad_list,
.top_ad_long,
.top_ad_post,
.top_ad_responsive,
.top_ad_seperate,
.top_ad_short,
.top_ad_wrap,
.top_ad_wrapper,
.top_adbox1,
.top_adbox2,
.top_adh,
.top_ads,
.top_ads_container,
.top_adsense,
.top_adspace,
.top_adv,
.top_adv_content,
.top_advert,
.top_advertisement,
.top_advertising_lb,
.top_advertizing_cnt,
.top_bar_ad,
.top_big_ads,
.top_container_ad,
.top_corner_ad,
.top_head_ads,
.top_header_ad,
.top_header_ad_inner,
.top_right_ad,
.top_rightad,
.top_side_adv,
.top_sponsor,
.topad-area,
.topad-bar,
.topad-bg,
.topad1,
.topad2,
.topadbar,
.topadblock,
.topadbox,
.topadcont,
.topadrow,
.topads,
.topads-spacer,
.topadsbx,
.topadsection,
.topadspace,
.topadspot,
.topadtara,
.topadtxt,
.topadvert,
.topbannerAd,
.topbar-ad-parent,
.topbar-ad-unit,
.topboardads,
.topright_ad,
.topside_ad,
.topsidebarad,
.tout-ad,
.tout-ad-embed,
.tower-ad,
.tower-ad-abs,
.tower-ad-b,
.tower-ad-wrapper,
.tower-ads-container,
.towerAd,
.towerAdLeft,
.towerAds,
.tower_ad,
.tower_ad_desktop,
.tower_ad_disclaimer,
.towerad,
.tp-ad-label,
.tp_ads,
.tpd-banner-ad-container,
.tpd-banner-desktop,
.tpd-box-ad-d,
.trb_taboola,
.trc-content-sponsored,
.trc-content-sponsoredUB,
.trc-first-recommendation,
.trc-spotlight-first-recommendation,
.trc_excludable,
.trc_excludable,
.trc_rbox,
.trc_rbox_border_elm,
.trc_rbox_div,
.trc_related_container,
.trc_spotlight_item,
.trend-card-advert,
.trend-card-advert__title,
.tsm-ad,
.tt_ads,
.ttb_adv_bg,
.tw-adv-gpt,
.txt_ads,
.txtad_area,
.txtadbox,
.txtadvertise,
.type-ad,
.u-ads,
.u-lazy-ad-wrapper,
.udn-ads,
.ue-c-ad,
.ult_vp_videoPlayerAD,
.under-header-ad,
.under-player-ad,
.under-player-ads,
.under_ads,
.underplayer__ad,
.uniAdBox,
.uniAds,
.unionAd,
.unit-ad,
.unspoken-adplace,
.upper-ad-box,
.upper-ad-space,
.upper_ad,
.upx-ad-placeholder,
.us_ad,
.uvs-ad-full-width,
.vadvert,
.van_taboola,
.van_vid_carousel,
.variable-ad,
.variableHeightAd,
.vce-ad-below-header,
.vce-ad-container,
.vce-header-ads,
.vce_adsense_expand,
.vce_adsense_widget,
.vce_adsense_wrapper,
.vdvwad,
.vert-ad,
.vert-ads,
.vertad,
.vertical-ad,
.vertical-ads,
.vertical-adsense,
.vertical-trending-ads,
.verticalAd,
.verticalAdText,
.vertical_ad,
.vertical_ads,
.verticalad,
.vf-ad-comments,
.vf-conversation-starter__ad,
.vf-promo-gtag,
.vf3-conversations-list__promo,
.vi-sticky-ad,
.video--container--aniview,
.video-ad-bottom,
.video-ad-container,
.video-ad-content,
.video-ads,
.video-ads-container,
.video-ads-grid,
.video-ads-wrapper,
.video-adv,
.video-advert,
.video-archive-ad,
.video-boxad,
.video-inline-ads,
.video-page__adv,
.video-right-ad,
.video-right-ads,
.video-side__adv_title,
.videoAd-wrapper,
.videoAd300,
.videoBoxAd,
.videoOverAd300,
.videoOverAdSmall,
.videoPauseAd,
.videoSideAds,
.video_ad,
.video_ads,
.videoad,
.videoad-base,
.videoad2,
.videos-ad,
.videos-ad-wrap,
.vidible-wrapper,
.view-Advertisment,
.view-ad,
.view-ads,
.view-advertisement,
.view-advertisements,
.view-advertorials,
.view-adverts,
.view-article-inner-ads,
.view-homepage-center-ads,
.view-id-Advertisment,
.view-id-ads,
.view-id-advertisement,
.view-image-ads,
.view-site-ads,
.view_ad,
.views-field-field-ad,
.visibleAd,
.vjs-ad-iframe,
.vjs-ad-overlay,
.vjs-ima3-ad-container,
.vjs-marker-ad,
.vjs-overlay.size-300x250,
.vl-ad-item,
.vl-advertisment,
.vl-header-ads,
.vlog-ad,
.vm-ad-horizontal,
.vmag_medium_ad,
.voc-ob-wrapper,
.vodl-ad__bigsizebanner,
.vpnad,
.vs-advert-300x250,
.vsw-ads,
.vswAdContainer,
.vuukle-ad-block,
.vuukle-ads,
.vw-header__ads,
.w-ad-box,
.w-adsninja-video-player,
.w-content--ad,
.wAdvert,
.w_AdExternal,
.w_ad,
.waf-ad,
.wahAd,
.wahAdRight,
.waldo-display-unit,
.waldo-placeholder,
.waldo-placeholder-bottom,
.wall-ads-control,
.wall-ads-left,
.wall-ads-right,
.wallAd,
.wall_ad,
.wallad,
.wcAd,
.wcfAdLocation,
.wd-adunit,
.wdca_ad_item,
.wdca_custom_ad,
.wdt_ads,
.weatherad,
.web_ads,
.webadvert-container,
.webpart-wrap-advert,
.website-ad-space,
.well-ad,
.werbungAd,
.wfb-ad,
.wg-ad-square,
.wh-advert,
.wh_ad,
.wh_ad_inner,
.when-show-ads,
.wide-ad,
.wide-ad-container,
.wide-ad-new-layout,
.wide-ad-outer,
.wide-ads-container,
.wide-advert,
.wide-footer-ad,
.wide-header-ad,
.wide-skyscraper-ad,
.wideAd,
.wideAdTable,
.widePageAd,
.wide_ad,
.wide_adBox_footer,
.wide_ad_unit,
.wide_ad_unit_top,
.wide_ads,
.wide_google_ads,
.wide_grey_ad_box,
.wide_sponsors,
.widead,
.wideadbox,
.widget--ad,
.widget--ajdg_bnnrwidgets,
.widget--local-ads,
.widget-300x250ad,
.widget-ad,
.widget-ad-codes,
.widget-ad-image,
.widget-ad-script,
.widget-ad-sky,
.widget-ad-zone,
.widget-ad300x250,
.widget-adcode,
.widget-ads,
.widget-adsense,
.widget-adv,
.widget-advads-ad-widget,
.widget-advert-970,
.widget-advertisement,
.widget-dfp,
.widget-group-Ads,
.widget-highlight-ads,
.widget-sponsor,
.widget-sponsor--container,
.widget-text-ad,
.widget1-ad,
.widget10-ad,
.widget4-ad,
.widget6-ad,
.widget7-ad,
.widgetAD,
.widgetAds,
.widgetContentIfrWrapperAd,
.widgetSponsors,
.widget_300x250_advertisement,
.widget_abn_admanager_sidestealer,
.widget_ad,
.widget_ad-widget,
.widget_ad125,
.widget_ad300,
.widget_ad_300,
.widget_ad_boxes_widget,
.widget_ad_layers_ad_widget,
.widget_ad_rotator,
.widget_ad_widget,
.widget_adace_ads_widget,
.widget_admanagerwidget,
.widget_adrotate_widgets,
.widget_ads,
.widget_ads_entries,
.widget_ads_widget,
.widget_adsblock,
.widget_adsensem,
.widget_adsensewidget,
.widget_adsingle,
.widget_adswidget1-quick-adsense,
.widget_adswidget2-quick-adsense,
.widget_adswidget3-quick-adsense,
.widget_adv_location,
.widget_adv_text,
.widget_advads_ad_widget,
.widget_advert,
.widget_advert_content,
.widget_advert_widget,
.widget_advertisement,
.widget_advertisements,
.widget_advertisment,
.widget_advwidget,
.widget_alaya_ad,
.widget_arvins_ad_randomizer,
.widget_awaken_pro_medium_rectangle_ad,
.widget_better-ads,
.widget_com_ad_widget,
.widget_core_ads_desk,
.widget_cpxadvert_widgets,
.widget_customad_widget,
.widget_customadvertising,
.widget_dfp,
.widget_doubleclick_widget,
.widget_ep_rotating_ad_widget,
.widget_epcl_ads_fluid,
.widget_evolve_ad_gpt_widget,
.widget_html_snippet_ad_widget,
.widget_ima_ads,
.widget_ione-dart-ad,
.widget_ipm_sidebar_ad,
.widget_island_ad,
.widget_joblo_complex_ad,
.widget_long_ads_widget,
.widget_newspack-ads-widget,
.widget_njads_single_widget,
.widget_openxwpwidget,
.widget_outbrain,
.widget_outbrain_widget,
.widget_plugrush_widget,
.widget_pmc-ads-widget,
.widget_quads_ads_widget,
.widget_rdc_ad_widget,
.widget_sej_sidebar_ad,
.widget_sidebar_adrotate_tedo_single_widget,
.widget_sidebaradwidget,
.widget_singlead,
.widget_sponsored_content,
.widget_supermag_ad,
.widget_supernews_ad,
.widget_taboola,
.widget_text_adsense,
.widget_themoneytizer_widget,
.widget_thesun_dfp_ad_widget,
.widget_tt_ads_widget,
.widget_viral_advertisement,
.widget_wp-bannerize-widget,
.widget_wp_ads_gpt_widget,
.widget_wp_insert_ad_widget,
.widget_wpex_advertisement,
.widget_wpstealthads_widget,
.widgetads,
.width-ad-slug,
.wikia-ad,
.wio-xbanner,
.worldplus-ad,
.wp-ads-target,
.wp-block-ad-slot,
.wp-block-gamurs-ad,
.wp-block-tpd-block-tpd-ads,
.wp125ad,
.wp125ad_2,
.wp_bannerize,
.wp_bannerize_banner_box,
.wp_bannerize_container,
.wpadcenter-ad-container,
.wpadvert,
.wpd-advertisement,
.wpex-ads-widget,
.wppaszone,
.wps-player-wrap,
.wpvqgr-a-d-s,
.wpx-bannerize,
.wpx_bannerize,
.wpx_bannerize_banner_box,
.wrap-ad,
.wrap-ads,
.wrap_boxad,
.wrapad,
.wrapper-ad,
.wrapper-header-ad-slot,
.wrapper_ad,
.wrapper_advertisement,
.wrapperad,
.ww_ads_banner_wrapper,
.xeiro-ads,
.xmlad,
.xpot-horizontal,
.y-ads,
.y-ads-wide,
.yaAds,
.yad-sponsored,
.yahooAd,
.yahooAds,
.yahoo_ad,
.yahoo_ads,
.yahooad,
.yahooads,
.yan-sponsored,
.zerg-widget,
.zerg-widgets,
.zergnet,
.zergnet-holder,
.zergnet-row,
.zergnet-unit,
.zergnet-widget,
.zergnet-widget-container,
.zergnet-widget__header,
.zergnet-widget__subtitle,
.zergnet__container,
.zeus-ad,
.zeusAdWrapper,
.zeusAd__container,
.zmgad-full-width,
.zmgad-right-rail,
.zone-advertisement,
.zoneAds,
.zox-post-ad-wrap,
.zox-post-bot-ad,
.zox-widget-side-ad,
.zox_ad_widget,
.zox_adv_widget,
AD-SLOT,
AD-TRIPLE-BOX,
AMP-AD,
DFP-AD,
[class^="adDisplay-module"],
[class^="amp-ad-"],
[class^="div-gpt-ad"],
[class^="s2nPlayer"],
[class^="tile-picker__CitrusBannerContainer-sc-"],
[data-ad-cls],
[data-ad-manager-id],
[data-ad-module],
[data-ad-name],
[data-ad-width],
[data-adblockkey],
[data-adbridg-ad-class],
[data-adshim],
[data-advadstrackid],
[data-block-type="ad"],
[data-css-class="dfp-inarticle"],
[data-d-ad-id],
[data-desktop-ad-id],
[data-dynamic-ads],
[data-ez-name],
[data-freestar-ad][id],
[data-id^="div-gpt-ad"],
[data-identity="adhesive-ad"],
[data-m-ad-id],
[data-mobile-ad-id],
[data-name="adaptiveConstructorAd"],
[data-rc-widget="data-rc-widget"],
[data-rc-widget],
[data-revive-zoneid]>iframe,
[data-role="tile-ads-module"],
[data-taboola-options],
[data-template-type="nativead"],
[data-testid="adBanner-wrapper"],
[data-testid="ad_testID"],
[data-testid="prism-ad-wrapper"],
[data-type="ad-vertical"],
[data-wpas-zoneid],
[href="//sexcams.plus/"],
[href="https://jdrucker.com/gold"]>img,
[href="https://masstortfinancing.com"] img,
[href="https://ourgoldguy.com/contact/"] img,
[href="https://www.masstortfinancing.com/"]>img,
[href^="http://clicks.totemcash.com/"],
[href^="http://mypillow.com/"]>img,
[href^="http://www.mypillow.com/"]>img,
[href^="https://ad.admitad.com/"],
[href^="https://ad1.adfarm1.adition.com/"],
[href^="https://affiliate.fastcomet.com/"]>img,
[href^="https://antiagingbed.com/discount/"]>img,
[href^="https://ap.octopuspop.com/click/"]>img,
[href^="https://awbbjmp.com/"],
[href^="https://charmingdatings.life/"],
[href^="https://clicks.affstrack.com/"]>img,
[href^="https://cpa.10kfreesilver.com/"],
[href^="https://glersakr.com/"],
[href^="https://go.xlrdr.com"],
[href^="https://goldcometals.com/clk.trk"],
[href^="https://ilovemyfreedoms.com/landing-"],
[href^="https://istlnkcl.com/"],
[href^="https://join.girlsoutwest.com/"],
[href^="https://join.playboyplus.com/track/"],
[href^="https://join3.bannedsextapes.com"],
[href^="https://mylead.global/stl/"]>img,
[href^="https://mypatriotsupply.com/"]>img,
[href^="https://mypillow.com/"]>img,
[href^="https://mystore.com/"]>img,
[href^="https://noqreport.com/"]>img,
[href^="https://optimizedelite.com/"]>img,
[href^="https://rapidgator.net/article/premium/ref/"],
[href^="https://routewebtk.com/"],
[href^="https://shiftnetwork.infusionsoft.com/go/"]>img,
[href^="https://track.aftrk1.com/"],
[href^="https://track.fiverr.com/visit/"]>img,
[href^="https://turtlebids.irauctions.com/"] img,
[href^="https://v.investologic.co.uk/"],
[href^="https://wct.link/click?"],
[href^="https://www.avantlink.com/click.php"] img,
[href^="https://www.brighteonstore.com/products/"] img,
[href^="https://www.cloudways.com/en/?id"],
[href^="https://www.herbanomic.com/"]>img,
[href^="https://www.hostg.xyz/"]>img,
[href^="https://www.mypatriotsupply.com/"]>img,
[href^="https://www.mypillow.com/"]>img,
[href^="https://www.profitablegatecpm.com/"],
[href^="https://www.restoro.com/"],
[href^="https://www.targetingpartner.com/"],
[href^="https://zone.gotrackier.com/"],
[href^="https://zstacklife.com/"] img,
[id^="ad-wrap-"],
[id^="ad_sky"],
[id^="ad_slider"],
[id^="div-gpt-ad"],
[id^="section-ad-banner"],
[name^="google_ads_iframe"],
[onclick*="content.ad/"],
[onclick^="location.href='https://1337x.vpnonly.site/"],
a-ad,
a[data-href^="http://ads.trafficjunky.net/"],
a[data-oburl^="https://paid.outbrain.com/network/redir?"],
a[data-redirect^="https://paid.outbrain.com/network/redir?"],
a[data-url^="https://vulpix.bet/?ref="],
a[href*="&maxads="],
a[href*=".adsrv.eacdn.com/"],
a[href*=".cfm?domain="][href*="&fp="],
a[href*=".engine.adglare.net/"],
a[href*=".foxqck.com/"],
a[href*=".g2afse.com/"],
a[href*="//daichoho.com/"],
a[href*="//jjgirls.com/sex/Chaturbate"],
a[href*="/jump/next.php?r="],
a[href^=" https://www.friendlyduck.com/AF_"],
a[href^="//ejitsirdosha.net/"],
a[href^="//go.eabids.com/"],
a[href^="//s.st1net.com/splash.php"],
a[href^="//s.zlinkd.com/"],
a[href^="//startgaming.net/tienda/" i],
a[href^="//stighoazon.com/"],
a[href^="http://adultfriendfinder.com/go/"],
a[href^="http://annulmentequitycereals.com/"],
a[href^="http://avthelkp.net/"],
a[href^="http://bongacams.com/track?"],
a[href^="http://cam4com.go2cloud.org/aff_c?"],
a[href^="http://coefficienttolerategravel.com/"],
a[href^="http://com-1.pro/"],
a[href^="http://deskfrontfreely.com/"],
a[href^="http://dragfault.com/"],
a[href^="http://dragnag.com/"],
a[href^="http://eighteenderived.com/"],
a[href^="http://eslp34af.click/"],
a[href^="http://guestblackmail.com/"],
a[href^="http://handgripvegetationhols.com/"],
a[href^="http://li.blogtrottr.com/click?"],
a[href^="http://muzzlematrix.com/"],
a[href^="http://naggingirresponsible.com/"],
a[href^="http://partners.etoro.com/"],
a[href^="http://premonitioninventdisagree.com/"],
a[href^="http://revolvemockerycopper.com/"],
a[href^="http://sarcasmadvisor.com/"],
a[href^="http://stickingrepute.com/"],
a[href^="http://tc.tradetracker.net/"]>img,
a[href^="http://trk.globwo.online/"],
a[href^="http://troopsassistedstupidity.com/"],
a[href^="http://vnte9urn.click/"],
a[href^="http://www.adultempire.com/unlimited/promo?"][href*="&partner_id="],
a[href^="http://www.friendlyduck.com/AF_"],
a[href^="http://www.h4trck.com/"],
a[href^="http://www.iyalc.com/"],
a[href^="https://123-stream.org/"],
a[href^="https://1betandgonow.com/"],
a[href^="https://6-partner.com/"],
a[href^="https://81ac.xyz/"],
a[href^="https://a-ads.com/"],
a[href^="https://a.adtng.com/"],
a[href^="https://a.bestcontentfood.top/"],
a[href^="https://a.bestcontentoperation.top/"],
a[href^="https://a.bestcontentweb.top/"],
a[href^="https://a.candyai.love/"],
a[href^="https://a.medfoodhome.com/"],
a[href^="https://a.medfoodsafety.com/"],
a[href^="https://a2.adform.net/"],
a[href^="https://ab.advertiserurl.com/aff/"],
a[href^="https://activate-game.com/"],
a[href^="https://ad.doubleclick.net/"],
a[href^="https://ad.zanox.com/ppc/"]>img,
a[href^="https://adclick.g.doubleclick.net/"],
a[href^="https://ads.betfair.com/redirect.aspx?"],
a[href^="https://ads.leovegas.com/"],
a[href^="https://ads.planetwin365affiliate.com/"],
a[href^="https://adultfriendfinder.com/go/"],
a[href^="https://ak.hauchiwu.com/"],
a[href^="https://ak.oalsauwy.net/"],
a[href^="https://ak.psaltauw.net/"],
a[href^="https://allhost.shop/aff.php?"],
a[href^="https://auesk.cfd/"],
a[href^="https://ausoafab.net/"],
a[href^="https://aweptjmp.com/"],
a[href^="https://awptjmp.com/"],
a[href^="https://banners.livepartners.com/"],
a[href^="https://bc.game/"],
a[href^="https://billing.purevpn.com/aff.php"]>img,
a[href^="https://black77854.com/"],
a[href^="https://bngprm.com/"],
a[href^="https://bngpt.com/"],
a[href^="https://bodelen.com/"],
a[href^="https://bongacams10.com/track?"],
a[href^="https://bongacams2.com/track?"],
a[href^="https://bs.serving-sys.com"],
a[href^="https://cam4com.go2cloud.org/"],
a[href^="https://camfapr.com/landing/click/"],
a[href^="https://cams.imagetwist.com/in/?track="],
a[href^="https://chaturbate.com/in/?"],
a[href^="https://chaturbate.jjgirls.com/?track="],
a[href^="https://claring-loccelkin.com/"],
a[href^="https://click.candyoffers.com/"],
a[href^="https://click.dtiserv2.com/"],
a[href^="https://click.hoolig.app/"],
a[href^="https://click.linksynergy.com/fs-bin/"]>img,
a[href^="https://clickadilla.com/"],
a[href^="https://clickins.slixa.com/"],
a[href^="https://clicks.pipaffiliates.com/"],
a[href^="https://clixtrac.com/"],
a[href^="https://combodef.com/"],
a[href^="https://ctjdwm.com/"],
a[href^="https://ctosrd.com/"],
a[href^="https://ctrdwm.com/"],
a[href^="https://datewhisper.life/"],
a[href^="https://disobediencecalculatormaiden.com/"],
a[href^="https://dl-protect.net/"],
a[href^="https://drumskilxoa.click/"],
a[href^="https://eergortu.net/"],
a[href^="https://engine.blueistheneworanges.com/"],
a[href^="https://engine.flixtrial.com/"],
a[href^="https://engine.phn.doublepimp.com/"],
a[href^="https://explore-site.com/"],
a[href^="https://fastestvpn.com/lifetime-special-deal?a_aid="],
a[href^="https://fc.lc/ref/"],
a[href^="https://financeads.net/tc.php?"],
a[href^="https://gamingadlt.com/?offer="],
a[href^="https://get-link.xyz/"],
a[href^="https://get.surfshark.net/aff_c?"][href*="&aff_id="]>img,
a[href^="https://getmatchedlocally.com/"],
a[href^="https://getvideoz.click/"],
a[href^="https://gml-grp.com/"],
a[href^="https://go.admjmp.com"],
a[href^="https://go.bushheel.com/"],
a[href^="https://go.cmtaffiliates.com/"],
a[href^="https://go.dmzjmp.com"],
a[href^="https://go.etoro.com/"]>img,
a[href^="https://go.goaserv.com/"],
a[href^="https://go.grinsbest.com/"],
a[href^="https://go.hpyjmp.com"],
a[href^="https://go.hpyrdr.com/"],
a[href^="https://go.markets.com/visit/?bta="],
a[href^="https://go.mnaspm.com/"],
a[href^="https://go.nordvpn.net/aff"]>img,
a[href^="https://go.rmhfrtnd.com/"],
a[href^="https://go.skinstrip.net"][href*="?campaignId="],
a[href^="https://go.strpjmp.com/"],
a[href^="https://go.tmrjmp.com"],
a[href^="https://go.trackitalltheway.com/"],
a[href^="https://go.xlirdr.com"],
a[href^="https://go.xlivrdr.com"],
a[href^="https://go.xlviiirdr.com"],
a[href^="https://go.xlviirdr.com"],
a[href^="https://go.xlvirdr.com"],
a[href^="https://go.xtbaffiliates.com/"],
a[href^="https://go.xxxiijmp.com"],
a[href^="https://go.xxxijmp.com"],
a[href^="https://go.xxxjmp.com"],
a[href^="https://go.xxxvjmp.com/"],
a[href^="https://golinks.work/"],
a[href^="https://hot-growngames.life/"],
a[href^="https://hotplaystime.life/"],
a[href^="https://in.rabbtrk.com/"],
a[href^="https://intenseaffiliates.com/redirect/"],
a[href^="https://iqbroker.com/"][href*="?aff="],
a[href^="https://ismlks.com/"],
a[href^="https://italarizege.xyz/"],
a[href^="https://itubego.com/video-downloader/?affid="],
a[href^="https://jaxofuna.com/"],
a[href^="https://join.dreamsexworld.com/"],
a[href^="https://join.sexworld3d.com/track/"],
a[href^="https://join.virtuallust3d.com/"],
a[href^="https://join.virtualtaboo.com/track/"],
a[href^="https://juicyads.in/"],
a[href^="https://kiksajex.com/"],
a[href^="https://l.hyenadata.com/"],
a[href^="https://land.brazzersnetwork.com/landing/"],
a[href^="https://landing.brazzersnetwork.com/"],
a[href^="https://lead1.pl/"],
a[href^="https://lijavaxa.com/"],
a[href^="https://lnkxt.bannerator.com/"],
a[href^="https://lobimax.com/"],
a[href^="https://loboclick.com/"],
a[href^="https://lone-pack.com/"],
a[href^="https://losingoldfry.com/"],
a[href^="https://m.do.co/c/"]>img,
a[href^="https://maymooth-stopic.com/"],
a[href^="https://mediaserver.entainpartners.com/renderBanner.do?"],
a[href^="https://mediaserver.gvcaffiliates.com/renderBanner.do?"],
a[href^="https://mmwebhandler.aff-online.com/"],
a[href^="https://myclick-2.com/"],
a[href^="https://natour.naughtyamerica.com/track/"],
a[href^="https://ndt5.net/"],
a[href^="https://ngineet.cfd/"],
a[href^="https://offhandpump.com/"],
a[href^="https://osfultrbriolenai.info/"],
a[href^="https://paid.outbrain.com/network/redir?"],
a[href^="https://pb-front.com/"],
a[href^="https://pb-imc.com/"],
a[href^="https://pb-track.com/"],
a[href^="https://play1ad.shop/"],
a[href^="https://playnano.online/offerwalls/?ref="],
a[href^="https://porntubemate.com/"],
a[href^="https://postback1win.com/"],
a[href^="https://prf.hn/click/"][href*="/adref:"]>img,
a[href^="https://prf.hn/click/"][href*="/camref:"]>img,
a[href^="https://prf.hn/click/"][href*="/creativeref:"]>img,
a[href^="https://pubads.g.doubleclick.net/"],
a[href^="https://quotationfirearmrevision.com/"],
a[href^="https://random-affiliate.atimaze.com/"],
a[href^="https://rixofa.com/"],
a[href^="https://s.cant3am.com/"],
a[href^="https://s.deltraff.com/"],
a[href^="https://s.ma3ion.com/"],
a[href^="https://s.optzsrv.com/"],
a[href^="https://s.zlink3.com/"],
a[href^="https://s.zlinkd.com/"],
a[href^="https://safesurfingtoday.com/"][href*="?skip="],
a[href^="https://serve.awmdelivery.com/"],
a[href^="https://service.bv-aff-trx.com/"],
a[href^="https://sexynearme.com/"],
a[href^="https://slkmis.com/"],
a[href^="https://snowdayonline.xyz/"],
a[href^="https://softwa.cfd/"],
a[href^="https://startgaming.net/tienda/" i],
a[href^="https://static.fleshlight.com/images/banners/"],
a[href^="https://streamate.com/landing/click/"],
a[href^="https://svb-analytics.trackerrr.com/"],
a[href^="https://syndicate.contentsserved.com/"],
a[href^="https://syndication.dynsrvtbg.com/"],
a[href^="https://syndication.exoclick.com/"],
a[href^="https://syndication.optimizesrv.com/"],
a[href^="https://t.acam.link/"],
a[href^="https://t.adating.link/"],
a[href^="https://t.ajrkm1.com/"],
a[href^="https://t.ajrkm3.com/"],
a[href^="https://t.ajump1.com/"],
a[href^="https://t.aslnk.link/"],
a[href^="https://t.hrtye.com/"],
a[href^="https://tatrck.com/"],
a[href^="https://tc.tradetracker.net/"]>img,
a[href^="https://tm-offers.gamingadult.com/"],
a[href^="https://torguard.net/aff.php"]>img,
a[href^="https://tour.mrskin.com/"],
a[href^="https://track.1234sd123.com/"],
a[href^="https://track.adform.net/"],
a[href^="https://track.afcpatrk.com/"],
a[href^="https://track.aftrk3.com/"],
a[href^="https://track.totalav.com/"],
a[href^="https://track.ultravpn.com/"],
a[href^="https://track.wg-aff.com"],
a[href^="https://tracker.loropartners.com/"],
a[href^="https://tracking.avapartner.com/"],
a[href^="https://traffdaq.com/"],
a[href^="https://trk.nfl-online-streams.club/"],
a[href^="https://trk.softonixs.xyz/"],
a[href^="https://trk.sportsflix4k.club/"],
a[href^="https://turnstileunavailablesite.com/"],
a[href^="https://twinrdsrv.com/"],
a[href^="https://upsups.click/"],
a[href^="https://vo2.qrlsx.com/"],
a[href^="https://voluum.prom-xcams.com/"],
a[href^="https://witnessjacket.com/"],
a[href^="https://www.adskeeper.com"],
a[href^="https://www.adultempire.com/"][href*="?partner_id="],
a[href^="https://www.adxsrve.com/"],
a[href^="https://www.bang.com/?aff="],
a[href^="https://www.bet365.com/"][href*="affiliate="],
a[href^="https://www.brazzersnetwork.com/landing/"],
a[href^="https://www.dating-finder.com/?ai_d="],
a[href^="https://www.dating-finder.com/signup/?ai_d="],
a[href^="https://www.dql2clk.com/"],
a[href^="https://www.endorico.com/Smartlink/"],
a[href^="https://www.financeads.net/tc.php?"],
a[href^="https://www.friendlyduck.com/AF_"],
a[href^="https://www.geekbuying.com/dynamic-ads/"],
a[href^="https://www.get-express-vpn.com/offer/"],
a[href^="https://www.goldenfrog.com/vyprvpn?offer_id="][href*="&aff_id="],
a[href^="https://www.googleadservices.com/pagead/aclk?"]>img,
a[href^="https://www.highcpmrevenuenetwork.com/"],
a[href^="https://www.highperformancecpmgate.com/"],
a[href^="https://www.infowarsstore.com/"]>img,
a[href^="https://www.liquidfire.mobi/"],
a[href^="https://www.mrskin.com/account/"],
a[href^="https://www.mrskin.com/tour"],
a[href^="https://www.nutaku.net/signup/landing/"],
a[href^="https://www.onlineusershielder.com/"],
a[href^="https://www.privateinternetaccess.com/"]>img,
a[href^="https://www.purevpn.com/"][href*="&utm_source=aff-"],
a[href^="https://www.sheetmusicplus.com/"][href*="?aff_id="],
a[href^="https://www.sugarinstant.com/?partner_id="],
a[href^="https://www.toprevenuegate.com/"],
a[href^="https://www8.smartadserver.com/"],
a[href^="https://xbet-4.com/"],
a[href^="https://zirdough.net/"],
a[onmousedown^="this.href='https://paid.outbrain.com/network/redir?"][target="_blank"],
a[onmousedown^="this.href='https://paid.outbrain.com/network/redir?"][target="_blank"]+.ob_source,
a[style="width:100%;height:100%;z-index:10000000000000000;position:absolute;top:0;left:0;"],
ad-shield-ads,
ad-slot,
amp-ad,
amp-ad-custom,
amp-connatix-player,
amp-embed[type="taboola"],
amp-fx-flying-carpet,
app-ad,
app-advertisement,
app-large-ad,
ark-top-ad,
aside[id^="adrotate_widgets-"],
atf-ad-slot,
bottomadblock,
citrus-ad-wrapper,
display-ad-component,
display-ads,
div[aria-label="Ads"],
div[class^="Adstyled__AdWrapper-"],
div[class^="Display_displayAd"],
div[class^="kiwi-ad-wrapper"],
div[class^="native-ad-"],
div[data-ad-placeholder],
div[data-ad-targeting],
div[data-ad-wrapper],
div[data-adname],
div[data-adunit-path],
div[data-adunit],
div[data-adzone],
div[data-alias="300x250 Ad 1"],
div[data-alias="300x250 Ad 2"],
div[data-contentexchange-widget],
div[data-dfp-id],
div[data-id-advertdfpconf],
div[id*="MarketGid"],
div[id*="ScriptRoot"],
div[id^="ad-div-"],
div[id^="ad-position-"],
div[id^="ad_position_"],
div[id^="adngin-"],
div[id^="adrotate_widgets-"],
div[id^="adspot-"],
div[id^="crt-"][style],
div[id^="dfp-ad-"],
div[id^="div-ads-"],
div[id^="div-gpt-"],
div[id^="ezoic-pub-ad-"],
div[id^="google_dfp_"],
div[id^="gpt_ad_"],
div[id^="lazyad-"],
div[id^="optidigital-adslot"],
div[id^="pa_sticky_ad_box_middle_"],
div[id^="rc-widget-"],
div[id^="st"][style^="z-index: 999999999;"],
div[id^="sticky_ad_"],
div[id^="taboola-stream-"],
div[id^="vuukle-ad-"],
div[id^="yandex_ad"],
div[id^="zergnet-widget"],
gpt-ad,
guj-ad,
hl-adsense,
img[src^="https://images.purevpnaffiliates.com"],
ins.adsbygoogle[data-ad-client],
ins.adsbygoogle[data-ad-slot],
ps-connatix-module,
span[data-ez-ph-id],
span[id^="ezoic-pub-ad-placeholder-"],
topadblock,
zeus-ad {
display: none !important;
}


.divSponsoredLinks,
.divTopADBanner,
.divTopADBannerWapper,
.divTopArticleAd,
.div_advertisement,
.divad1,
.divad2,
.divad3,
.divads,
.divider-ad,
.divider-advert,
.divider-full-width-ad,
.divider-taboola,
.divider_ad,
.dlSponsoredLinks,
.dm-adSlotBillboard,
.dm-adSlotNative1,
.dm-adSlotNative2,
.dm-adSlotNative3,
.dm-adSlotRectangle1,
.dm-adSlotRectangle2,
.dm-adSlotSkyscraper,
.dm-adSlot__sticky,
.dm_ad-billboard,
.dm_ad-container,
.dm_ad-halfpage,
.dm_ad-leaderboard,
.dm_ad-link,
.dm_ad-skyscraper,
.dmpu-ad,
.dn-ad-wide,
.dotcom-ad,
.double-ad,
.double-ads,
.doubleClickAd,
.doubleclickAds,
.download-ad,
.downloadAds,
.download_ad,
.dsk-box-ad-d,
.dsq_ad,
.dt-sponsor,
.dtads-desktop,
.dtads-slot,
.dual-ads,
.dualAds,
.dyn-sidebar-ad,
.dynamic-ads,
.dynamicAdvertContainer,
.dynamicLeadAd,
.dynamic_adslot,
.dynamicad1,
.dynamicad2,
.e-ad,
.e-advertise,
.e3lan,
.e3lan-top,
.e3lan-widget-content,
.e3lan300-100,
.e3lan300-250,
.e3lan300_250-widget,
.eaa-ad,
.eads,
.easy-ads,
.easyAdsBox,
.easyAdsSinglePosition,
.ebayads,
.ebm-ad-target__outer,
.ecommerce-ad,
.ecosia-ads,
.eddy-adunit,
.editor_ad,
.eg-ad,
.eg-custom-ad,
.element--ad,
.element-ad,
.element-adplace,
.element_contentad1,
.element_contentad2,
.element_contentad3,
.element_contentad4,
.element_contentad5,
.elementor-widget-wp-widget-advads_ad_widget,
.embAD,
.embed-ad,
.embedded-article-ad,
.embeddedAd,
.embeddedAds,
.embedded_ad_wrapper,
.empire-unit-prefill-container,
.empty-ad,
.endAHolder,
.endti-adlabel,
.entry-ad,
.entry-ads,
.entry-bottom-ad,
.entry-bottom-ads,
.entry-top-ad,
.entryAd,
.entry_ad,
.entryad,
.etn-ad-text,
.eu-advertisment1,
.evo-ads-widget,
.evolve-ad,
.ex_pu_iframe,
.exco-container,
.exo_wrapper,
.external-ad,
.external-add,
.ez-sidebar-wall-ad,
.ez-video-wrap,
.ezAdsWidget,
.ezmob-footer,
.ezmob-footer-desktop,
.ezo_ad,
.ezoic-ad,
.ezoic-ad-adaptive,
.ezoic-adpicker-ad,
.ezoic-floating-bottom,
.f-ad,
.f-item-ad,
.f-item-ad-inhouse,
.fbs-ad--ntv-home-wrapper,
.fbs-ad--top-wrapper,
.fbs-ad--topx-wrapper,
.fc_clmb_ad,
.fce_ads,
.featureAd,
.feature_ad,
.featured-ad,
.featured-ads,
.featured-sponsors,
.featured-story-ad,
.featuredAdBox,
.featuredAds,
.featuredBoxAD,
.featured_ad,
.featuredadvertising,
.feed-ad,
.feed-ad-wrapper,
.fh_ad_microbuttons,
.field-59-companion-ad,
.fig-ad-content,
.first-article-ad-block,
.first-banner-ad,
.first-leaderbord-adv,
.first-leaderbord-adv-mobile,
.firstAd-container,
.first_ad,
.first_party_ad_wrapper,
.first_post_ad,
.firstad,
.firstpost_advert,
.firstpost_advert_container,
.fix_ad,
.fixadheight,
.fixadheightbottom,
.fixed-ad-aside,
.fixed-ad-bottom,
.fixed-ads,
.fixed-bottom-ad,
.fixed-sidebar-ad,
.fixedAds,
.fixedLeftAd,
.fixedRightAd,
.fixed_ad,
.fixed_adslot,
.fixed_advert_banner,
.fjs-ad-hide-empty,
.fla-ad,
.flashAd,
.flash_ad,
.flash_advert,
.flashad,
.flashadd,
.flex-ad,
.flex-posts-ads,
.flexAd,
.flexAds,
.flexContentAd,
.flexad,
.flexadvert,
.flexiad,
.flm-ad,
.floatad,
.floatads,
.floated-ad,
.floated_right_ad,
.floating-ads,
.floating-advert,
.floatingAds,
.fly-ad,
.fm-badge-ad,
.fnadvert,
.fns_td_wrap,
.fold-ads,
.follower-ad-bottom,
.following-ad,
.following-ad-container,
.foot-ad,
.foot-ads,
.foot-advertisement,
.foot_adsense,
.footad,
.footer-300-ad,
.footer-ad,
.footer-ad-full-wrapper,
.footer-ad-labeling,
.footer-ad-row,
.footer-ad-section,
.footer-ad-squares,
.footer-ad-unit,
.footer-ad-wrap,
.footer-adrow,
.footer-ads,
.footer-ads-slide,
.footer-ads-wrapper,
.footer-ads_unlocked,
.footer-adsbar,
.footer-adsense,
.footer-advert,
.footer-advert-large,
.footer-advertisement,
.footer-advertisements,
.footer-advertising,
.footer-advertising-area,
.footer-banner-ad,
.footer-banner-ads,
.footer-floating-ad,
.footer-im-ad,
.footer-leaderboard-ad,
.footer-post-ad-blk,
.footer-prebid,
.footer-text-ads,
.footerAd,
.footerAdModule,
.footerAdUnit,
.footerAdWrapper,
.footerAds,
.footerAdsWrap,
.footerAdslot,
.footerAdverts,
.footerBottomAdSec,
.footerFullAd,
.footerPageAds,
.footerTextAd,
.footer__ads--content,
.footer__advert,
.footer_ad,
.footer_ad336,
.footer_ad_container,
.footer_ads,
.footer_adv,
.footer_advertisement,
.footer_block_ad,
.footer_bottom_ad,
.footer_bottomad,
.footer_line_ad,
.footer_text_ad,
.footer_text_adblog,
.footerad,
.footeradspace,
.footertextadbox,
.forbes-ad-container,
.forex_ad_links,
.fortune-ad-unit,
.forum-ad,
.forum-ad-2,
.forum-teaser-ad,
.forum-topic--adsense,
.forumAd,
.forum_ad_beneath,
.four-ads,
.fp-ad-nativendo-one-third,
.fp-ad-rectangle,
.fp-ad300,
.fp-ads,
.fp-right-ad,
.fp-right-ad-list,
.fp-right-ad-zone,
.fp_ad_text,
.fp_adv-box,
.frame_adv,
.framead,
.freestar-ad-container,
.freestar-ad-sidebar-container,
.freestar-ad-wide-container,
.freestar-incontent-ad,
.frn_adbox,
.front-ad,
.front_ad,
.frontads,
.frontendAd,
.frontone_ad,
.frontpage__article--ad,
.frontpage_ads,
.fsAdContainer,
.fs_ad,
.fs_ads,
.fsrads,
.ft-ad,
.full-ad,
.full-ad-wrapper,
.full-ads,
.full-adv,
.full-bleed-ad,
.full-bleed-ad-container,
.full-page-ad,
.full-top-ad-area,
.full-width-ad,
.full-width-ad-container,
.full-width-ads,
.fullAdBar,
.fullBleedAd,
.fullSizeAd,
.fullWidthAd,
.full_AD,
.full_ad_box,
.full_ad_row,
.full_width_ad,
.fulladblock,
.fullbanner_ad,
.fullbannerad,
.fullpage-ad,
.fullsize-ad-square,
.fullwidth-advertisement,
.fusion-ads,
.fuv_sidebar_ad_widget,
.fwAdTags,
.fw_ad,
.g-ad,
.g-ad-fix,
.g-ad-leaderboard,
.g-ad-slot,
.g-adver,
.g-advertisement-block,
.g1-ads,
.g1-advertisement,
.g2-adsense,
.g3-adsense,
.gAdMTable,
.gAdMainParent,
.gAdMobileTable,
.gAdOne,
.gAdOneMobile,
.gAdRows,
.gAdSky,
.gAdThreeDesktop,
.gAdThreeMobile,
.gAdTwo,
.gAds,
.gAds1,
.gAdsBlock,
.gAdsContainer,
.gAdvertising,
.g_ad,
.g_adv,
.ga-ads,
.gaTeaserAds,
.gaTeaserAdsBox,
.gabfire_ad,
.gabfire_simplead_widget,
.gad-container,
.gad-right1,
.gad-right2,
.gad300x600,
.gad336x280,
.gadContainer,
.gad_container,
.gads_container,
.gadsense,
.gadsense-ad,
.gallery-ad,
.gallery-ad-container,
.gallery-ad-counter,
.gallery-ad-holder,
.gallery-ad-lazyload-placeholder,
.gallery-ad-overlay,
.gallery-adslot-top,
.gallery-injectedAd,
.gallery-sidebar-ad,
.gallery-slide-ad,
.galleryAds,
.galleryLeftAd,
.galleryRightAd,
.gallery_ad,
.gallery_ad_wrapper,
.gallery_ads_box,
.galleryad,
.galleryads,
.gam-ad,
.gam-ad-hz-bg,
.gam_ad_slot,
.game-ads,
.game-category-ads,
.gameAd,
.gameBottomAd,
.gamepage_boxad,
.games-ad-wrapper,
.gb-ad-top,
.gb_area_ads,
.general-ad,
.genericAds,
.ggl_ads_row,
.ggl_txt_ads,
.giant_pushbar_ads_l,
.glacier-ad,
.globalAd,
.gnm-ad-unit,
.gnm-ad-unit-container,
.gnm-ad-zones,
.gnm-adhesion-ad,
.gnm-banner-ad,
.gnm-bg-ad,
.gnt_em_vp_c[data-g-s="vp_dk"],
.gnt_flp,
.gnt_rr_xpst,
.gnt_rr_xst,
.gnt_tb.gnt_tbb,
.gnt_tbr.gnt_tb,
.gnt_x,
.gnt_x__lbl,
.gnt_xmst,
.go-ad,
.goAdMan,
.goads,
.googads,
.google-2ad-m,
.google-ad,
.google-ad-160-600,
.google-ad-468-60,
.google-ad-728-90,
.google-ad-block,
.google-ad-container,
.google-ad-content,
.google-ad-header2,
.google-ad-image,
.google-ad-manager,
.google-ad-placeholder,
.google-ad-sidebar,
.google-ad-space,
.google-ad-widget,
.google-ads,
.google-ads-billboard,
.google-ads-bottom,
.google-ads-container,
.google-ads-footer-01,
.google-ads-footer-02,
.google-ads-in_article,
.google-ads-leaderboard,
.google-ads-long,
.google-ads-responsive,
.google-ads-right,
.google-ads-sidebar,
.google-ads-widget,
.google-ads-wrapper,
.google-adsense,
.google-advert-sidebar,
.google-afc-wrapper,
.google-bottom-ads,
.google-dfp-ad-caption,
.google-dfp-ad-wrapper,
.google-right-ad,
.google-sponsored,
.google-sponsored-ads,
.google-sponsored-link,
.google-sponsored-links,
.google468,
.googleAd,
.googleAdBox,
.googleAdContainer,
.googleAdSearch,
.googleAdSense,
.googleAdWrapper,
.googleAdd,
.googleAds,
.googleAdsContainer,
.googleAdsense,
.googleAdv,
.google_ad,
.google_ad_container,
.google_ad_label,
.google_ad_wide,
.google_add,
.google_admanager,
.google_ads,
.google_ads_content,
.google_ads_sidebar,
.google_adsense,
.google_adsense1,
.google_adsense_footer,
.google_afc,
.google_afc_ad,
.googlead,
.googleadArea,
.googleadbottom,
.googleadcontainer,
.googleaddiv,
.googleads,
.googleads-container,
.googleads-height,
.googleadsense,
.googleadsrectangle,
.googleadv,
.googleadvertisement,
.googleadwrap,
.googleafc,
.gpAds,
.gpt-ad,
.gpt-ad-container,
.gpt-ad-sidebar-wrap,
.gpt-ad-wrapper,
.gpt-ads,
.gpt-billboard,
.gpt-breaker-container,
.gpt-container,
.gpt-leaderboard-banner,
.gpt-mpu-banner,
.gpt-sticky-sidebar,
.gpt.top-slot,
.gptSlot,
.gptSlot-outerContainer,
.gptSlot__sticky-footer,
.gptslot,
.gradientAd,
.graphic_ad,
.grev-ad,
.grey-ad,
.grey-ad-line,
.grey-ad-notice,
.greyAd,
.greyad,
.grid>.container>#aside-promotion,
.grid-ad,
.grid-ad-col__big,
.grid-advertisement,
.grid-block-ad,
.grid-item-ad,
.gridAd,
.gridAdRow,
.gridSideAd,
.grid__module-sizer_name_taboola,
.grid_ad_container,
.gridad,
.gridlove-ad,
.gridstream_ad,
.ground-ads-shared,
.group-ad-leaderboard,
.group-google-ads,
.group-item-ad,
.group_ad,
.gsAd,
.gtm-ad-slot,
.guide__row--fixed-ad,
.guj-ad--placeholder,
.gujAd,
.gutterads,
.gw-ad,
.h-adholder,
.h-ads,
.h-adver,
.h-large-ad-box,
.h-top-ad,
.h11-ad-top,
.h_Ads,
.h_ad,
.half-ad,
.half-page-ad,
.half-page-ad-1,
.half-page-ad-2,
.halfPageAd,
.half_ad_box,
.halfpage_ad,
.halfpage_ad_1,
.halfpage_ad_container,
.happy-inline-ad,
.happy-under-player,
.has-ad,
.has-adslot,
.has-fixed-bottom-ad,
.hasAD,
.hdr-ad,
.hdr-ads,
.hdrAd,
.hdr_ad,
.head-ad,
.head-ads,
.head-banner468,
.head-top-ads,
.headAd,
.head_ad,
.head_ad_wrapper,
.head_ads,
.head_adv,
.head_advert,
.headad,
.headadcontainer,
.header-ad,
.header-ad-area,
.header-ad-banner,
.header-ad-box,
.header-ad-container,
.header-ad-desktop,
.header-ad-frame,
.header-ad-holder,
.header-ad-region,
.header-ad-row,
.header-ad-space,
.header-ad-top,
.header-ad-widget,
.header-ad-wrap,
.header-ad-wrapper,
.header-ad-zone,
.header-adbanner,
.header-adbox,
.header-adcode,
.header-adplace,
.header-ads,
.header-ads-area,
.header-ads-container,
.header-ads-holder,
.header-ads-wrap,
.header-ads-wrapper,
.header-adsense,
.header-adslot-container,
.header-adspace,
.header-adv,
.header-advert,
.header-advert-wrapper,
.header-advertise,
.header-advertisement,
.header-advertising,
.header-and-footer--banner-ad,
.header-article-ads,
.header-banner-ad,
.header-banner-ads,
.header-banner-advertising,
.header-bannerad,
.header-bottom-adboard-area,
.header-pencil-ad,
.header-sponsor,
.header-top-ad,
.header-top_ads,
.headerAd,
.headerAd1,
.headerAdBanner,
.headerAdContainer,
.headerAdPosition,
.headerAdSpacing,
.headerAdWrapper,
.headerAds,
.headerAds250,
.headerAdspace,
.headerAdvert,
.headerAdvertisement,
.headerTextAd,
.headerTopAd,
.headerTopAds,
.header__ad,
.header__ads,
.header__ads-wrapper,
.header__advertisement,
.header_ad,
.header_ad1,
.header_ad_center,
.header_ad_div,
.header_ad_space,
.header_ads,
.header_ads-container,
.header_ads_box,
.header_adspace,
.header_advert,
.header_advertisement,
.header_advertisment,
.header_leaderboard_ad,
.header_top_ad,
.headerad,
.headeradarea,
.headeradblock,
.headeradright,
.headerads,
.heading-ad-space,
.headline-adblock,
.headline-ads,
.headline_advert,
.hederAd,
.herald-ad,
.hero-ad,
.hero-ad-slot,
.hero-advert,
.heroAd,
.hidden-ad,
.hide-ad,
.hide_ad,
.hidead,
.highlightsAd,
.hm-ad,
.hmad,
.hn-ads,
.holder-ad,
.holder-ads,
.home-ad,
.home-ad-bigbox,
.home-ad-container,
.home-ad-inline,
.home-ad-links,
.home-ad-region-1,
.home-ad-section,
.home-ads,
.home-ads-container,
.home-ads1,
.home-adv-box,
.home-advert,
.home-body-ads,
.home-page-ad,
.home-sidebar-ad,
.home-sponsored-links,
.home-sticky-ad,
.home-top-ad,
.homeAd,
.homeAd1,
.homeAd2,
.homeAdBox,
.homeAdBoxA,
.homeAdSection,
.homeBoxMediumAd,
.homeCentreAd,
.homeMainAd,
.homeMediumAdGroup,
.homePageAdSquare,
.homePageAds,
.homeTopAdContainer,
.home_ad,
.home_ad_bottom,
.home_ad_large,
.home_ad_title,
.home_adblock,
.home_advert,
.home_advertisement,
.home_mrec_ad,
.homeadwrapper,
.homepage--sponsor-content,
.homepage-ad,
.homepage-ad-block,
.homepage-ad-module,
.homepage-advertisement,
.homepage-banner-ad,
.homepage-footer-ad,
.homepage-footer-ads,
.homepage-page__ff-ad-container,
.homepage-page__tag-ad-container,
.homepage-page__video-ad-container,
.homepageAd,
.homepage__native-ad,
.homepage_ads,
.homepage_block_ad,
.hor-ad,
.hor_ad,
.horiAd,
.horiz_adspace,
.horizontal-ad,
.horizontal-ad-container,
.horizontal-ad-holder,
.horizontal-ad-wrapper,
.horizontal-ad2,
.horizontal-ads,
.horizontal-advert-container,
.horizontal-full-ad,
.horizontal.ad,
.horizontalAd,
.horizontalAdText,
.horizontalAdvert,
.horizontal_Fullad,
.horizontal_ad,
.horizontal_adblock,
.horizontal_ads,
.horizontaltextadbox,
.horizsponsoredlinks,
.hortad,
.hotad_bottom,
.hotel-ad,
.house-ad,
.house-ad-small,
.house-ad-unit,
.house-ads,
.houseAd,
.houseAd1,
.houseAdsStyle,
.housead,
.hover_ads,
.hoverad,
.hp-ad-container,
.hp-ad-grp,
.hp-adsection,
.hp-sectionad,
.hpRightAdvt,
.hp_320-250-ad,
.hp_ad_300,
.hp_ad_box,
.hp_ad_cont,
.hp_ad_text,
.hp_adv300x250,
.hp_advP1,
.hp_horizontal_ad,
.hp_textlink_ad,
.htl-ad,
.htl-ad-placeholder,
.htl-inarticle-container,
.html-advertisement,
.html5-ad-progress-list,
.hw-ad--frTop,
.hyad,
.i-amphtml-element.live-updates.render-embed,
.i-amphtml-unresolved,
.iAdserver,
.iab300x250,
.iab728x90,
.ib-adv,
.ico-adv,
.icon-advertise,
.iconAdChoices,
.icon_ad_choices,
.iconads,
.idgGoogleAdTag,
.ie-adtext,
.iframe-ad,
.iframe-ads,
.iframeAd,
.iframeAds,
.ima-ad-container,
.image-advertisement,
.image-viewer-ad,
.image-viewer-mpu,
.imageAd,
.imageAds,
.imagead,
.imageads,
.img-advert,
.img_ad,
.img_ads,
.imgad,
.in-article-ad,
.in-article-ad-placeholder,
.in-article-ad-wrapper,
.in-article-adx,
.in-between-ad,
.in-content-ad,
.in-content-ad-wrapper,
.in-page-ad,
.in-slider-ad,
.in-story-ads,
.in-text-ad,
.in-text__advertising,
.in-thumb-ad,
.in-thumb-video-ad,
.inPageAd,
.in_ad,
.in_article_ad,
.in_article_ad_wrapper,
.in_content_ad_container,
.in_content_advert,
.inarticlead,
.inc-ad,
.incontent-ad1,
.incontentAd,
.incontent_ads,
.index-adv,
.index-module_adBeforeContent__AMXn,
.index-module_adBeforeContent__UYZT,
.index-module_rightrailBottom__IJEl,
.index-module_rightrailTop__mag4,
.index-module_sd_background__Um4w,
.index_728_ad,
.index_ad,
.index_ad_a2,
.index_ad_a4,
.index_ad_a5,
.index_ad_a6,
.index_right_ad,
.inf-onclickvideo-adbox,
.inf-onclickvideo-container,
.infinity-ad,
.inhousead,
.injected-ad,
.injectedAd,
.inline-ad,
.inline-ad-card,
.inline-ad-container,
.inline-ad-desktop,
.inline-ad-placeholder,
.inline-ad-text,
.inline-ad-wrap,
.inline-ad-wrapper,
.inline-adblock,
.inline-advert,
.inline-banner-ad,
.inline-display-ad,
.inline-google-ad-slot,
.inline-mpu,
.inline-story-add,
.inlineAd,
.inlineAdContainer,
.inlineAdImage,
.inlineAdInner,
.inlineAdNotice,
.inlineAdText,
.inlineAdvert,
.inlineAdvertisement,
.inlinePageAds,
.inlineSideAd,
.inline_ad,
.inline_ad_container,
.inline_ad_title,
.inline_ads,
.inlinead,
.inlinead_lazyload,
.inlineadsense,
.inlineadtitle,
.inlist-ad,
.inlistAd,
.inner-ad,
.inner-ad-disclaimer,
.inner-ad-section,
.inner-adv,
.inner-advert,
.inner-post-ad,
.innerAdWrapper,
.innerAds,
.innerContentAd,
.innerWidecontentAd,
.inner_ad,
.inner_ad_advertise,
.inner_big_ad,
.innerad,
.inpostad,
.inr_top_ads,
.ins_adwrap,
.insert-post-ads,
.insert_ad,
.insert_ad_column,
.insert_advertisement,
.insertad,
.inside_ad,
.insideads,
.inslide-ad,
.insticator-ads,
.instream_ad,
.intAdRow,
.intad,
.interAd,
.internal-ad,
.internalAd,
.internal_ad,
.interstitial-ad,
.interstory_first_mobile,
.interstory_second_mobile,
.intext-ads,
.intra-article-ad,
.intro-ad,
.ion-ad,
.ione-widget-dart-ad,
.ipc-advert,
.ipc-advert-class,
.ipsAd,
.ipsAdvertisement,
.iqadlinebottom,
.iqadmarker,
.iqadtile_wrapper,
.is-ad,
.is-carbon-ad,
.is-desktop-ads,
.is-mpu,
.is-preload-ad,
.is-script-ad,
.is-sponsored,
.is-sticky-ad,
.isAd,
.isAdPage,
.isad_box,
.ise-ad,
.island-ad,
.islandAd,
.islandAdvert,
.island_ad,
.islandad,
.item--ad,
.item-ad,
.item-ad-leaderboard,
.item-advertising,
.item-container-ad,
.itemAdvertise,
.item_ads,
.itsanad,
.j-ad,
.jLinkSponsored,
.jannah_ad,
.jg-ad-5,
.jg-ad-970,
.jobbioapp,
.jobs-ad-box,
.jobs-ad-marker,
.jquery-adi,
.jquery-script-ads,
.js-ad,
.js-ad-banner-container,
.js-ad-buttons,
.js-ad-container,
.js-ad-dynamic,
.js-ad-frame,
.js-ad-home,
.js-ad-loader-bottom,
.js-ad-slot,
.js-ad-static,
.js-ad-unit,
.js-ad-unit-bottom,
.js-ad-wrapper,
.js-ad_iframe,
.js-adfliction-iframe,
.js-adfliction-standard,
.js-ads,
.js-ads-carousel,
.js-advert,
.js-advert-container,
.js-adzone,
.js-anchor-ad,
.js-article-advert-injected,
.js-billboard-advert,
.js-dfp-ad,
.js-dfp-ad-bottom,
.js-dfp-ad-top,
.js-gpt-ad,
.js-gptAd,
.js-header-ad,
.js-header-ad-wrapper,
.js-lazy-ad,
.js-mapped-ad,
.js-mpu,
.js-native-ad,
.js-no-sticky-ad,
.js-outbrain-container,
.js-overlay_ad,
.js-react-simple-ad,
.js-results-ads,
.js-right-ad-block,
.js-sidebar-ads,
.js-skyscraper-ad,
.js-slide-right-ad,
.js-slide-top-ad,
.js-sticky-ad,
.js-stream-ad,
.js-taboola,
.js-toggle-ad,
.js-widget-distroscale,
.js-widget-send-to-news,
.jsAdSlot,
.jsMPUSponsor,
.js_adContainer,
.js_ad_wrapper,
.js_deferred-ad,
.js_desktop-horizontal-ad,
.js_midbanner_ad_slot,
.js_preheader-ad-container,
.js_related-stories-inset,
.js_slideshow-full-width-ad,
.js_slideshow-sidebar-ad,
.js_sticky-top-ad,
.jsx-adcontainer,
.jw-ad,
.jw-ad-block,
.jw-ad-label,
.jw-ad-media-container,
.jw-ad-visible,
.jwPlayer--floatingContainer,
.kakao_ad_area,
.keen_ad,
.kumpulads-post,
.kumpulads-side,
.kwizly-psb-ad,
.l-ad,
.l-ad-top,
.l-ads,
.l-adsense,
.l-article__ad,
.l-bottom-ads,
.l-grid--ad-card,
.l-header-advertising,
.l-section--ad,
.l1-ads-wrapper,
.label-ad,
.label_advertising_text,
.labelads,
.large-advert,
.largeAd,
.largeRectangleAd,
.largeUnitAd,
.large_ad,
.lastAdHolder,
.lastads,
.latest-ad,
.layout-ad,
.layout__right-ads,
.layout_h-ad,
.lazy-ad,
.lazy-ad-unit,
.lazy-adv,
.lazyad,
.lazyadsense,
.lazyadslot,
.lazyload-ad,
.lazyload_ad,
.lazyload_ad_article,
.lb-ad,
.lb-adhesion-unit,
.lb-advert-container,
.lb-item-ad,
.ld-ad,
.ld-ad-inner,
.ldm_ad,
.lead-ad,
.lead-ads,
.leader-ad,
.leader-ad-728,
.leaderAd,
.leaderAdTop,
.leaderAdvert,
.leaderBoardAdWrapper,
.leaderBoardAdvert,
.leader_ad,
.leader_aol,
.leaderad,
.leaderboard-ad,
.leaderboard-ad-belt,
.leaderboard-ad-component,
.leaderboard-ad-container,
.leaderboard-ad-dummy,
.leaderboard-ad-fixed,
.leaderboard-ad-grid,
.leaderboard-ad-main,
.leaderboard-ad-module,
.leaderboard-ad-pane,
.leaderboard-ad-placeholder,
.leaderboard-ad-section,
.leaderboard-ad-unit,
.leaderboard-ad-wrapper,
.leaderboard-adblock,
.leaderboard-ads,
.leaderboard-ads-text,
.leaderboard-advert,
.leaderboard-advertisement,
.leaderboard-main-ad,
.leaderboard-top-ad,
.leaderboard-top-ad-wrapper,
.leaderboard.advert,
.leaderboard1AdWrapper,
.leaderboardAd,
.leaderboardAdWrapper,
.leaderboardFooter_ad,
.leaderboardRectAdWrapper,
.leaderboard_ad_container,
.leaderboard_ad_unit,
.leaderboard_ads,
.leaderboard_adsense,
.leaderboard_adv,
.leaderboard_banner_ad,
.leaderboardad,
.leaderboardadmiddle,
.leaderboardadtop,
.leaderboardadwrap,
.lee-track-ilad,
.left-ad,
.left-ads,
.left-advert,
.left-rail-ad,
.left-sponser-ad,
.leftAd,
.leftAdColumn,
.leftAdContainer,
.leftAds,
.leftAdsEnabled,
.leftAdsFix,
.leftAdvDiv,
.leftAdvert,
.leftCol_advert,
.leftColumnAd,
.left_300_ad,
.left_ad,
.left_ad_160,
.left_ad_areas,
.left_ad_box,
.left_ad_container,
.left_add_block,
.left_adlink,
.left_ads,
.left_adsense,
.left_advertisement_block,
.left_col_ad,
.left_google_add,
.leftad,
.leftadd,
.leftadtag,
.leftbar_ad2,
.leftbarads,
.leftbottomads,
.leftnavad,
.leftrighttopad,
.leftsidebar_ad,
.lefttopad1,
.legacy-ads,
.legion_primiswrapper,
.lft_advt_container,
.lg-ads-160x90,
.lg-ads-311x500,
.lg-ads-635x100,
.lg-ads-skin-container,
.ligatus,
.lightad,
.lijit-ad,
.linead,
.linkAD,
.linkAds,
.link_ad,
.linkads,
.list-ad,
.list-adbox,
.list-ads,
.list-feature-ad,
.list-footer-ad,
.listad,
.listicle-instream-ad-holder,
.listing-item-ad,
.listingAd,
.listings_ad,
.lite-page-ad,
.live-ad,
.lng-ad,
.local-ads,
.localad,
.location-ad,
.log_ads,
.logged_out_ad,
.logo-ad,
.logoAds,
.logo_AdChoices,
.logoad,
.logoutAd,
.logoutAdContainer,
.long-ads,
.longAd,
.longAdBox,
.longAds,
.long_ad,
.longform-ad,
.loop-ad,
.lower-ad,
.lower-ads,
.lowerAd,
.lowerAds,
.lower_ad,
.lr-ad,
.lr-pack-ad,
.lr_skyad,
.lrec-container,
.lst_ads,
.lyrics-inner-ad-wrap,
.m-ContentAd,
.m-ad,
.m-ad-brick,
.m-ad-region,
.m-ad-unit,
.m-ad__wrapper,
.m-adaptive-ad-component,
.m-advert,
.m-advertisement,
.m-advertisement--container,
.m-article-taboola,
.m-balloon-header--ad,
.m-block-ad,
.m-content-advert,
.m-content-advert-wrap,
.m-dfp-ad-text,
.m-header-ad,
.m-in-content-ad,
.m-in-content-ad-row,
.m-jac-ad,
.m-sponsored,
.m1-header-ad,
.m2n-ads-slot,
.m_ad,
.m_ad1,
.m_ad300,
.m_banner_ads,
.macAd,
.macad,
.mad_adcontainer,
.magAd,
.magad,
.main-ad,
.main-ad-container,
.main-ad-gallery,
.main-add-sec,
.main-ads,
.main-advert,
.main-advertising,
.main-column-ad,
.main-footer-ad,
.main-header-ad,
.main-header__ad-wrapper,
.main-right-ads,
.mainAd,
.mainAdContainer,
.mainAds,
.mainLeftAd,
.mainLinkAd,
.mainRightAd,
.main_ad,
.main_adbox,
.main_ads,
.main_adv,
.mantis-ad,
.mantisadd,
.manual-ad,
.map-ad,
.mapped-ad,
.mar-block-ad,
.mar-leaderboard--bottom,
.margin-advertisement,
.margin0-ads,
.marginalContentAdvertAddition,
.marketing-ad,
.marketplace-ad,
.marketplaceAd,
.marquee-ad,
.masonry-tile-ad,
.masonry__ad,
.master_post_advert,
.masthead-ad,
.masthead-ads,
.mastheadAds,
.masthead__ad,
.match-ad,
.mb-advert,
.mb-advert__incontent,
.mb-advert__leaderboard--large,
.mb-advert__mpu,
.mb-advert__tweeny,
.mb-block--advert-side,
.mb-list-ad,
.mc-column-Taboola,
.mc_floating_ad,
.mc_text_ads_box,
.md-advertisement,
.medRect,
.media-viewer__ads-container,
.mediaAd,
.mediaAdContainer,
.medium-rectangle-ad,
.medium-top-ad,
.mediumRectAdWrapper,
.mediumRectagleAd,
.mediumRectangleAd,
.mediumRectangleAdvert,
.medium_ad,
.mediumad,
.medrec-ad,
.medrect-ad,
.medrect-ad2,
.medrectAd,
.medrect_ad,
.mega-ad,
.member-ads,
.menu-ad,
.menuAd,
.message_ads,
.meta-ad,
.meta_ad,
.metabet-adtile,
.mf-adsense-leaderboard,
.mf-adsense-rightrail,
.mg_box_ads,
.mgid-wrapper,
.mgid_3x2,
.mid-ad-wrapper,
.mid-ads,
.mid-advert,
.mid-article-banner-ad,
.mid-outbrain,
.mid-post-ad,
.mid-section-ad,
.midAd,
.midAdv-cont,
.midAdv-cont2,
.midAdvert,
.mid_ad,
.mid_banner_ad,
.midad,
.midarticlead,
.middle-ad,
.middle-ads,
.middle-ads728,
.middle-footer-ad,
.middleAd,
.middleAdLeft,
.middleAdMid,
.middleAdRight,
.middleAdWrapper,
.middleAds,
.middleBannerAd,
.middle_AD,
.middle_ad,
.middle_ad_responsive,
.middle_ads,
.middlead,
.middleadouter,
.midpost-ad,
.min-height-ad,
.min_navi_ad,
.mini-ad,
.mini-ads,
.mini_ads,
.miniad,
.miniads,
.misc-ad,
.misc-ad-label,
.miscAd,
.mj-floating-ad-wrapper,
.mks_ads_widget,
.mm-ad-sponsored,
.mm-ads-adhesive-ad,
.mm-ads-gpt-adunit,
.mm-ads-leaderboard-header,
.mm-banner970-ad,
.mm-embed--sendtonews,
.mm-widget--sendtonews,
.mmads,
.mntl-gpt-adunit,
.mntl-leaderboard-header,
.mntl-leaderboard-spacer,
.mntl-sc-block-adslot,
.moads-top-banner,
.moads-widget,
.mob-ad-break-text,
.mob-adspace,
.mob-hero-banner-ad-wrap,
.mob_ads,
.mobads,
.mobile-ad,
.mobile-ad-container,
.mobile-ad-negative-space,
.mobile-ad-placeholder,
.mobile-ad-slider,
.mobile-ads,
.mobile-fixed-ad,
.mobile-instream-ad-holder,
.mobile-instream-ad-holder-single,
.mobileAd,
.mobileAdWrap,
.mobileAppAd,
.mobile_ad_banner,
.mobile_ad_container,
.mobile_featuredad,
.mobile_leaderboard_ad,
.mobileadbig,
.mobileadunit,
.mobilesideadverts,
.mod-ad,
.mod-adblock,
.mod-ads,
.mod-google-ads,
.mod-horizontal-ad,
.mod-sponsored-links,
.mod-vertical-ad,
.mod_ad,
.mod_ad_container,
.mod_ad_text,
.mod_ad_top,
.mod_admodule,
.mod_ads,
.mod_advert,
.mod_index_ad,
.mod_js_ad,
.mod_openads,
.mod_r_ad,
.mod_r_ad1,
.modal-ad,
.module--ad,
.module-ad,
.module-ad-small,
.module-ads,
.module-advert,
.module-advertisement,
.module-box-ads,
.module-image-ad,
.module-rectangleads,
.module-sponsored-ads,
.module-zerg,
.module1colAds,
.moduleAd,
.moduleAdSpot,
.moduleAdvert,
.moduleAdvertContent,
.moduleBannerAd,
.module__ad-wide,
.module_ad,
.module_ad_disclaimer,
.module_box_ad,
.module_header_sponsored,
.module_home_ads,
.module_single_ads,
.modulegad,
.moduletable-adsponsor,
.moduletable-advert,
.moduletable-bannerAd6,
.moduletable-centerad,
.moduletable-googleads,
.moduletable-rectangleads,
.moduletable_ad-right,
.moduletable_ad300x250,
.moduletable_adtop,
.moduletable_advertisement,
.moduletable_top_ad,
.moduletableadvert,
.moduletableexclusive-ads,
.moduletablesquaread,
.moduletabletowerad,
.mom-ad,
.moneyball-ad,
.monsterad,
.mos-ad,
.mosaicAd,
.motherboard-ad,
.movable-ad,
.movv-ad,
.mp-ad,
.mpsponsor,
.mpu-ad,
.mpu-ad-con,
.mpu-ad-river,
.mpu-ad-top,
.mpu-advert,
.mpu-c,
.mpu-footer,
.mpu-fp,
.mpu-holder,
.mpu-leaderboard,
.mpu-left,
.mpu-left-bk,
.mpu-mediatv,
.mpu-right,
.mpu-title,
.mpu-top-left,
.mpu-top-left-banner,
.mpu-top-right,
.mpu-unit,
.mpu-wrap,
.mpu-wrapper,
.mpuAd,
.mpuAdArea,
.mpuAdSlot,
.mpuAdvert,
.mpuArea,
.mpuBlock,
.mpuBox,
.mpuContainer,
.mpu_Ad,
.mpu_ad,
.mpu_advert,
.mpu_container,
.mpu_holder,
.mpu_placeholder,
.mpu_side,
.mpu_wrapper,
.mpuad,
.mpuads,
.mr1_adwrap,
.mr2_adwrap,
.mr3_adwrap,
.mr4_adwrap,
.mrec-ads,
.mrec-banners,
.mrecAds,
.mrec_advert,
.mrf-adv,
.mrf-adv__wrapper,
.msg-ad,
.msgad,
.mt-ad-container,
.mt_ad,
.mt_ads,
.mtop_adfit,
.mu-ad-container,
.mv_atf_ad_holder,
.mvp-ad-label,
.mvp-feat1-list-ad,
.mvp-flex-ad,
.mvp-post-ad-wrap,
.mvp-widget-ad,
.mvp-widget-feat2-side-ad,
.mvp_ad_widget,
.mw-ad,
.my-ads,
.myAds,
.myAdsGroup,
.my__container__ad,
.n1ad-center-300,
.narrow_ad_unit,
.narrow_ads,
.national_ad,
.nationalad,
.native-ad,
.native-ad-article,
.native-ad-container,
.native-ad-item,
.native-ad-mode,
.native-ad-slot,
.native-adv,
.native-advts,
.native-leaderboard-ad,
.native-sidebar-ad,
.native.ad,
.nativeAd,
.native_ad,
.native_ad_inline,
.native_ad_wrap,
.native_ads,
.nativead,
.nav-ad,
.nav-ad-gpt-container,
.nav-ad-plus-leader,
.nav-adWrapper,
.nav_ad,
.navbar-ad-section,
.navbar-ads,
.navbar-header-ad,
.naviad,
.ndmadkit,
.netPost_ad1,
.netPost_ad3,
.netads,
.netshelter-ad,
.newHeaderAd,
.new_ad1,
.new_ad_left,
.new_ad_normal,
.new_ad_wrapper_all,
.new_ads_unit,
.newad,
.newad1,
.news-ad,
.news-ad-square-a,
.news-ad-square-box,
.news-ads-top,
.news-item--ad,
.news_ad_box,
.news_vibrant_ads_banner,
.newsad,
.newsblock-ads,
.newsfeed_adunit,
.newspack_global_ad,
.nfy-ad,
.nfy-ad-teaser,
.nfy-ad-tile,
.nfy-ad-wrapper,
.nfy-cobo-ad,
.nfy-col-ad,
.ng-ad-banner,
.ng-ad-insert,
.nm-ad,
.nn_mobile_mpu_wrapper,
.node-ad,
.node_ad_wrapper,
.normalAds,
.normal_ads,
.normalad,
.northad,
.not-an-ad-header,
.note-advertisement,
.np-ad,
.np-ad-background,
.np-ad-border,
.np-ads-wrapper,
.np-adv-container,
.np-advert_apu,
.np-advert_apu-double,
.np-advert_info,
.np-header-ad,
.np-header-ads-area,
.np-right-ad,
.nrAds,
.nsAdRow,
.nts-ad,
.nts-video-wrapper,
.ntv-ad,
.nuffnangad,
.nuk-ad-placeholder,
.nv-ads-wrapper,
.nw-ad,
.nw-ad-label,
.nw-c-leaderboard-ad,
.nw-top-ad,
.nw_adv_square,
.nx-billboard-ad,
.nx-placeholder-ad,
.nya-slot[style],
.o-ad,
.o-ad-banner-top,
.o-ad-container,
.o-advert,
.o-listing__ad,
.o-site-header__advert,
.oad-ad,
.oas-ad,
.oas-container,
.oas-leaderboard-ads,
.oas_ad,
.oas_add,
.oas_advertisement,
.oasad,
.oasads,
.ob-p.ob-dynamic-rec-container,
.ob-smartfeed-wrapper,
.ob-widget-header,
.ob_ads_header,
.ob_container .item-container-obpd,
.ob_dual_right>.ob_ads_header~.odb_div,
.offads,
.oi-add-block,
.oi-header-ad,
.oio-banner-zone,
.oio-link-sidebar,
.oio-openslots,
.oio-zone-position,
.oko-adhesion,
.on_player_ads,
.oneColumnAd,
.onet-ad,
.online-ad-container,
.oovvuu-embed-player,
.opd_adsticky,
.otd-ad-top,
.outBrainWrapper,
.outbrain,
.outbrain-ad-slot,
.outbrain-ad-units,
.outbrain-ads,
.outbrain-bg,
.outbrain-bloc,
.outbrain-content,
.outbrain-group,
.outbrain-module,
.outbrain-placeholder,
.outbrain-recommended,
.outbrain-reserved-space,
.outbrain-single-bottom,
.outbrain-widget,
.outbrain-widget,
.outbrain-wrap,
.outbrain-wrapper,
.outbrain-wrapper-container,
.outbrain-wrapper-outer,
.outbrainAdHeight,
.outbrainWidget,
.outbrain__main,
.outbrain_container,
.outbrain_skybox,
.outbrainad,
.outbrainbox,
.outer-ad-container,
.outer-ad-unit-wrapper,
.outerAdWrapper,
.outerAds,
.outer_ad_container,
.outside_ad,
.outsider-ad,
.ov-ad-slot,
.overflow-ad,
.overlay-ad,
.overlay-ad-container,
.overlay-ads,
.overlay-box-ad,
.overlay_ad,
.ox-holder,
.p-ad,
.p-ad-block,
.p-ad-dfp-banner,
.p-ad-dfp-middle-rec,
.p-ad-feature-pr,
.p-ad-outbreak,
.p-ad-rectangle,
.p-ad-thumbnail-txt,
.p-ads-billboard,
.p-ads-rec,
.p-post-ad:not(html):not(body),
.p75_sidebar_ads,
.p_adv,
.p_topad,
.package_adBox,
.padAdvx,
.padded-ad,
.paddingBotAd,
.pads2,
.pads_bulk_widget,
.padvertlabel,
.page-ad,
.page-ads,
.page-advert,
.page-advertisement,
.page-bottom-fixed-ads,
.page-box-ad,
.page-break-ad,
.page-content__advert,
.page-footer-ad,
.page-header-ad,
.page-header_ad,
.page-top-ads,
.pageAd,
.pageAdSkin,
.pageAdSkinUrl,
.pageAds,
.pageFooterAd,
.pageGoogleAd,
.pageGoogleAds,
.pageHeaderAd,
.pageHeaderAds,
.pageTopAd,
.page__top-ad-wrapper,
.page_ad,
.pagead,
.pagepusheradATF,
.pages__ad,
.pane-ad-pane,
.pane-ads,
.pane-sasia-ad,
.pane-site-ads,
.pane-sponsored-links,
.pane_ad_wide,
.panel-ad,
.panel-adsense,
.panel-advert,
.panel.ad,
.panel_ad,
.paneladvert,
.par-ad,
.par-adv-slot,
.parade-ad-container,
.parent-ad-desktop,
.partial-ad,
.partner-ad,
.partner-ad-module-wrapper,
.partner-ads-list,
.partner-loading-shown.partner-label,
.partnerAd,
.partner_ads,
.partnerad_container,
.partnersTextLinks,
.pauseAdPlacement,
.pb-slot-container,
.pbs__player,
.pc-ad,
.pcads_widget,
.pd-ads-mpu,
.pdpads_desktop,
.penci-ad-box,
.penci-ad-image,
.penci-ad_box,
.penci-adsense-below-slider,
.penci-google-adsense,
.penci-google-adsense-1,
.penci-promo-link,
.penci_list_bannner_widget,
.pencil-ad,
.pencil-ad-container,
.pencil-ad-section,
.pencil_ad,
.perm_ad,
.pf_content_ad,
.pf_sky_ad,
.pf_top_ad,
.pg-ad-block,
.pg-adnotice,
.pg-adtarget,
.pgevoke-fp-bodyad2,
.pgevoke-story-rightrail-ad1,
.pgevoke-story-topads,
.pgevoke-topads,
.ph-ad,
.photo-ad,
.photo-ad-pad,
.photoAd,
.photoad,
.phpads_container,
.phpbb-ads-center,
.pix_adzone,
.placeholder-ad,
.placeholder-dfp,
.placeholderAd,
.plain-ad,
.plainAd,
.player-ad,
.player-ad-overlay,
.player-ads,
.player-ads2,
.player-section__ads-banners,
.player-under-ad,
.playerAd,
.playerAdv,
.player_ad,
.player_ad2,
.player_ad_box,
.playerad,
.playerdads,
.playwire-article-leaderboard-ad,
.plugin-ad,
.plugin-ad-container,
.pm-ad,
.pm-ad-unit,
.pm-ad-zone,
.pm-ads-banner,
.pm-ads-inplayer,
.pm-banner-ad,
.pmc-adm-boomerang-pub-div,
.pmc-contextual-player,
.polar-ad,
.polaris-ad--wrapper-desktop,
.polarisMarketing,
.polaris__ad,
.polaris__below-header-ad-wrapper,
.pop-out-eplayer-container,
.popup-box-ads,
.position-ads,
.post-ad,
.post-ad-title,
.post-ad-top,
.post-ad-type,
.post-ads,
.post-ads-top,
.post-adsense-bottom,
.post-advert,
.post-advert-row,
.post-advertisement,
.post-load-ad,
.post-news-ad,
.post-sidebar-ad,
.post-sponsored,
.postAd,
.postWideAd,
.post_ad,
.post_ads,
.post_advert,
.post_detail_right_advert,
.post_sponsored,
.postad,
.postads,
.postbit-ad,
.poster_ad,
.posts-ad,
.pp-ad-container,
.pp_ad_code_adtxt,
.ppb_ads,
.ppr_priv_footer_banner_ad_billboard,
.ppr_priv_header_banner_ad,
.ppr_priv_horizon_ad,
.pr_adslot_0,
.pr_adslot_1,
.preheader_advert,
.premium-ad,
.premium-ads,
.premium-adv,
.premium-mpu-container,
.premium_PremiumPlacement__2dEp0,
.priad,
.priad-1,
.primary-ad,
.primary-ad-widget,
.primary-advertisment,
.primis-ad,
.primis-ad-wrap,
.primis-custom,
.primis-player,
.primis-player-container,
.primis-player__container,
.primis-video,
.primis-video-player,
.primis-wrapper,
.primis_1,
.print-ad-wrapper,
.print-adslot,
.printAds,
.product-ad,
.product-ads,
.product-inlist-ad,
.profile-ad-container,
.profile-ads-container,
.profile__ad-wrapper,
.profile_ad_bottom,
.profile_ad_top,
.programtic-ads,
.promo-ad,
.promo-mpu,
.promoAd,
.promoAds,
.promoAdvertising,
.promo_ad,
.promo_ads,
.promo_border,
.promoad,
.promoboxAd,
.promoted-outbrain,
.promoted_content_ad,
.promotionAdContainer,
.promotionTextAd,
.proper-ad-insert,
.proper-ad-unit,
.ps-ad,
.pt-ad--container,
.pt-ad--scroll,
.pt_ad03,
.pt_col_ad02,
.pub_ads,
.pubexchange_module,
.publication-ad,
.publicidad_horizontal,
.publicidade,
.publisher_ad,
.pubtech-adv-slot,
.puff-ad,
.puff-advertorials,
.pull-ad,
.pull_top_ad,
.pullad,
.purchad,
.push--ad,
.push-ad,
.push-adv,
.pushDownAd,
.pushdown-ad,
.pushdownAd,
.pwa-ad,
.pz-ad-box,
.quads-ad-label,
.quads-bg-ad,
.quads-location,
.queue_ad,
.queued-ad,
.quigo,
.quigo-ad,
.quigoads,
.r-ad,
.r-pause-ad-container,
.r89-outstream-video,
.r_ad,
.r_ads,
.rail-ad,
.rail-ads-1,
.rail-article-sponsored,
.rail__ad,
.rail_ad,
.railad,
.railadspace,
.ray-floating-ads-container,
.rc-sponsored,
.rcom-freestar-ads-widget,
.re-AdTop1Container,
.ready-ad,
.rec-sponsored,
.rec_ad,
.rec_article_footer,
.rec_article_right,
.rec_container__right,
.rec_container_footer,
.rec_container_right,
.rec_title_footer,
.recent-ad,
.recentAds,
.recent_ad_holder,
.recipeFeatureAd,
.rect-ad,
.rect-ad-1,
.rectAd300,
.rect_ad,
.rect_ad_module,
.rect_advert,
.rectad,
.rectadv,
.rectangle-ad,
.rectangle-ad-container,
.rectangle-embed-ad,
.rectangleAd,
.rectangleAdContainer,
.rectangle_ad,
.rectanglead,
.rectangleads,
.reform-top-container,
.refreshAds,
.region-ad-bottom-leaderboard,
.region-ad-pan,
.region-ad-right,
.region-ad-top,
.region-ads,
.region-ads-content-top,
.region-banner-ad,
.region-dfp-ad-footer,
.region-dfp-ad-header,
.region-header-ad,
.region-header-ads,
.region-top-ad,
.region-top-ad-block,
.regular-ads,
.regularad,
.rekl-left,
.rekl-right,
.rekl-top,
.rekl_left,
.rekl_right,
.rekl_top,
.rekl_top_wrapper,
.reklam,
.reklam-block,
.reklam-kare,
.reklam-masthead,
.reklam2,
.reklam728,
.reklama,
.reklama-vert,
.reklama1,
.reklame-wrapper,
.reklamka,
.related-ad,
.related-ads,
.relatedAds,
.related_ad,
.remnant_ad,
.remove-ads,
.remove-ads-link,
.res_ad,
.resads-adspot,
.responsive-ad,
.responsive-ad-header-container,
.responsive-ad-outbrain,
.responsive-ad-wrapper,
.responsive-ads,
.responsiveAdsense,
.responsive_ad_top,
.responsive_ads_468x60,
.result-ad,
.result-sponsored,
.resultAd,
.result_ad,
.resultad,
.results-ads,
.revcontent-wrap,
.review-ad,
.reviews-display-ad,
.revive-ad,
.rh-ad,
.rhads,
.rhs-ad,
.rhs-ads-panel,
.rhs-advert-container,
.rhs-mrec-wrapper,
.rhs_ad,
.rhs_ad_title,
.rhs_ads,
.rhsad,
.rhsadvert,
.right-ad,
.right-ad-1,
.right-ad-2,
.right-ad-3,
.right-ad-4,
.right-ad-5,
.right-ad-block,
.right-ad-container,
.right-ad-holder,
.right-ad-wrapper,
.right-ad2,
.right-ad350px250px,
.right-ads,
.right-ads2,
.right-adsense,
.right-adv,
.right-advert,
.right-advertisement,
.right-col-ad,
.right-column-ad,
.right-column-ads,
.right-rail-ad,
.right-rail-ad-container,
.right-rail-box-ad-container,
.right-side-ad,
.right-side-ads,
.right-sidebar-box-ad,
.right-sidebar-box-ads,
.right-sponser-ad,
.right-top-ad,
.right-video-dvertisement,
.rightAD,
.rightAd,
.rightAd1,
.rightAd2,
.rightAdBlock,
.rightAdBox,
.rightAdColumn,
.rightAdContainer,
.rightAds,
.rightAdsFix,
.rightAdvert,
.rightAdverts,
.rightBoxAd,
.rightBoxMidAds,
.rightColAd,
.rightColAdBox,
.rightColumnAd,
.rightColumnAdd,
.rightColumnAdsTop,
.rightColumnRectAd,
.rightHeaderAd,
.rightRailAd,
.rightRailMiddleAd,
.rightSecAds,
.rightSideBarAd,
.rightSideSponsor,
.rightTopAdWrapper,
.right_ad,
.right_ad_1,
.right_ad_2,
.right_ad_box,
.right_ad_box1,
.right_ad_text,
.right_ad_top,
.right_ad_unit,
.right_ad_wrap,
.right_ads,
.right_ads_column,
.right_adsense_box_2,
.right_adskin,
.right_adv,
.right_advert,
.right_advertise_cnt,
.right_advertisement,
.right_block_advert,
.right_box_ad,
.right_col_ad,
.right_column_ads,
.right_content_ad,
.right_image_ad,
.right_long_ad,
.right_outside_ads,
.right_side_ads,
.right_side_box_ad,
.right_sponsor_main,
.rightad,
.rightadHeightBottom,
.rightadblock,
.rightadd,
.rightads,
.rightadunit,
.rightadv,
.rightboxads,
.rightcolads,
.rightcoladvert,
.rightrail-ad-placed,
.rightsideAd,
.river-item-sponsored,
.rj-ads-wrapper,
.rm-adslot,
.rolloverad,
.roof-ad,
.root-ad-anchor,
.rotating-ad,
.rotating-ads,
.row-ad,
.row-ad-leaderboard,
.rowAd,
.rowAds,
.row_header_ads,
.rpd_ads,
.rr-ad,
.rr_ads,
.rs-ad,
.rs-advert,
.rs-advert__container,
.rs_ad_block,
.rs_ad_top,
.rt_ad,
.rwSideAd,
.rwdArticleInnerAdBlock,
.s-ad,
.s-ads,
.s2nContainer,
.s_ads,
.sadvert,
.sagreklam,
.sal-adv-gpt,
.sam_ad,
.sb-ad,
.sb-ads,
.sbAd,
.sbAdUnitContainer,
.sbTopadWrapper,
.sb_ad,
.sb_ad_holder,
.sc-ad,
.scad,
.script-ad,
.scroll-ad-item-container,
.scroll-ads,
.scroll-fixable.rail-right>.deals-rail,
.scroll-track-ad,
.scrolling-ads,
.sda_adbox,
.sdc-advert__top-1,
.se-ligatus,
.search-ad,
.search-advertisement,
.search-result-list-item--sidebar-ad,
.search-result-list-item--topad,
.search-results-ad,
.search-sponsor,
.search-sponsored,
.searchAd,
.searchAdTop,
.searchAds,
.searchad,
.searchads,
.secondary-ad-widget,
.secondary-advertisment,
.secondary_ad,
.section-ad,
.section-ad-unit,
.section-ad-wrapper,
.section-ad2,
.section-ads,
.section-adtag,
.section-adv,
.section-advertisement,
.section-sponsor,
.section-subheader>.section-hotel-prices-header,
.section-widget-ad,
.section_ad,
.section_ad_left,
.section_ads,
.send-to-news,
.seoAdWrapper,
.servedAdlabel,
.serviceAd,
.sexunder_ads,
.sf_ad_box,
.sg-adblock,
.sgAd,
.sh-section-ad,
.shadvertisment,
.sheknows-infuse-ad,
.shift-ad,
.shopee-search-user-brief,
.shortadvertisement,
.show-desk-ad,
.show-sticky-ad,
.showAd,
.showAdContainer,
.showads,
.showcaseAd,
.showcasead,
.shr-ads-container,
.sics-component__outbrain,
.sidbaread,
.side-ad,
.side-ad-300,
.side-ad-blocks,
.side-ad-container,
.side-ad-inner,
.side-ad-top,
.side-ads,
.side-ads-block,
.side-ads-wide,
.side-adv-block,
.side-adv-text,
.side-advert,
.side-advertising,
.side-adverts,
.side-bar-ad,
.sideAd,
.sideAdLeft,
.sideAdWide,
.sideBarAd,
.sideBlockAd,
.sideBoxAd,
.side__ad,
.side__ad-box,
.side_ad,
.side_ad2,
.side_ad_top,
.side_add_wrap,
.side_ads,
.side_adsense,
.side_adv,
.side_col_ad_wrap,
.sidead,
.sideadmid,
.sideads,
.sideads_l,
.sideadsbox,
.sideadtable,
.sideadvert,
.sideadverts,
.sidebar-ad,
.sidebar-ad-area,
.sidebar-ad-b,
.sidebar-ad-box,
.sidebar-ad-c,
.sidebar-ad-component,
.sidebar-ad-cont,
.sidebar-ad-container,
.sidebar-ad-div,
.sidebar-ad-label,
.sidebar-ad-rect,
.sidebar-ad-slot,
.sidebar-ad-top,
.sidebar-ad-wrapper,
.sidebar-adbox,
.sidebar-ads,
.sidebar-ads-block,
.sidebar-ads-wrap,
.sidebar-adsdiv,
.sidebar-adv-container,
.sidebar-advert,
.sidebar-advertisement,
.sidebar-advertisment,
.sidebar-adverts,
.sidebar-adverts-header,
.sidebar-banner-ad,
.sidebar-below-ad-unit,
.sidebar-big-ad,
.sidebar-big-box-ad,
.sidebar-bottom-ad,
.sidebar-box-ad,
.sidebar-box-ads,
.sidebar-content-ad,
.sidebar-header-ads,
.sidebar-outbrain,
.sidebar-skyscraper-ad,
.sidebar-sponsored,
.sidebar-sponsors,
.sidebar-square-ad,
.sidebar-sticky--ad,
.sidebar-text-ad,
.sidebar-top-ad,
.sidebar-tower-ad,
.sidebar-zergnet,
.sidebarAD,
.sidebarAd,
.sidebarAdvert,
.sidebar__ad,
.sidebar_ad,
.sidebar_ad_300,
.sidebar_ad_300_250,
.sidebar_ad_container,
.sidebar_ad_holder,
.sidebar_ad_leaderboard,
.sidebar_ad_module,
.sidebar_ads,
.sidebar_ads_left,
.sidebar_ads_right,
.sidebar_ads_title,
.sidebar_adsense,
.sidebar_advert,
.sidebar_advertising,
.sidebar_box_ad,
.sidebar_right_ad,
.sidebar_skyscraper_ad,
.sidebar_sponsors,
.sidebarad,
.sidebarad_bottom,
.sidebaradbox,
.sidebaradcontent,
.sidebarads,
.sidebaradsense,
.sidebarbox__advertising,
.sidebarboxad,
.sidebox-ad,
.sidebox_ad,
.sideright_ads,
.sideskyad,
.signad,
.simple-ad-placeholder,
.simple_ads_manager_widget,
.simple_adsense_widget,
.simplead-container,
.simpleads-item,
.single-ad,
.single-ad-anchor,
.single-ad-wrap,
.single-ads,
.single-ads-section,
.single-bottom-ads,
.single-mpu,
.single-post-ad,
.single-post-ads,
.single-post-bottom-ads,
.single-top-ad {
display: none !important;
}


.ads-wrap,
.ads-wrapper,
.ads-wrapper-top,
.ads-x1,
.ads-zone,
.ads.bottom,
.ads.box,
.ads.cell,
.ads.cta,
.ads.grid-layout,
.ads.square,
.ads.top,
.ads.widget,
.ads01,
.ads1,
.ads10,
.ads11,
.ads120,
.ads120_600,
.ads120_600-widget,
.ads120_80,
.ads120x,
.ads123,
.ads125,
.ads125-widget,
.ads160,
.ads160-600,
.ads2,
.ads250,
.ads250-250,
.ads2Block,
.ads3,
.ads300,
.ads300-200,
.ads300-250,
.ads300250,
.ads300_250,
.ads300_600-widget,
.ads300box,
.ads300x600,
.ads336_280,
.ads336x280,
.ads4,
.ads468,
.ads468x60,
.ads600,
.ads720x90,
.ads728,
.ads728_90,
.ads728b,
.ads728x90,
.ads728x90-1,
.ads970,
.adsAdvert,
.adsArea,
.adsBanner,
.adsBannerLink,
.adsBlock,
.adsBlockContainerHorizontal,
.adsBot,
.adsBottom,
.adsBoxTop,
.adsCap,
.adsCell,
.adsColumn,
.adsConfig,
.adsCont,
.adsDef,
.adsDesktop,
.adsDetailsPage,
.adsDisclaimer,
.adsDiv,
.adsFirst,
.adsFixed,
.adsFull,
.adsHeader,
.adsHeading,
.adsHeight300x250,
.adsHeight720x90,
.adsHome-full,
.adsImages,
.adsInner,
.adsLabel,
.adsLibrary,
.adsLine,
.adsList,
.adsMPU,
.adsMag,
.adsMarker,
.adsMiddle,
.adsMvCarousel,
.adsNetwork,
.adsOuter,
.adsOverPrimary,
.adsPlaceHolder,
.adsPostquare,
.adsPushdown,
.adsRectangleMedium,
.adsRight,
.adsRow,
.adsSecond,
.adsSectionRL,
.adsSpacing,
.adsSticky,
.adsTag,
.adsText,
.adsTop,
.adsTopBanner,
.adsTopCont,
.adsTower2,
.adsTowerWrap,
.adsTxt,
.adsWidget,
.adsWrap,
.ads_160,
.ads_180,
.ads_2,
.ads_3,
.ads_300,
.ads_300_250,
.ads_300x250,
.ads_300x600,
.ads_4,
.ads_468,
.ads_468x60,
.ads_720x90,
.ads_728,
.ads_728x90,
.ads_Header,
.ads__article__header,
.ads__aside,
.ads__container,
.ads__header,
.ads__horizontal,
.ads__hyperleaderboard--hyperleaderboard,
.ads__inline,
.ads__interstitial,
.ads__link,
.ads__listing,
.ads__mid,
.ads__middle,
.ads__midpage-fullwidth,
.ads__native,
.ads__right-rail-ad,
.ads__sidebar,
.ads__top,
.ads_ad_box,
.ads_after,
.ads_after_more,
.ads_amazon,
.ads_area,
.ads_article,
.ads_ba_cad,
.ads_banner,
.ads_bar,
.ads_before,
.ads_between_content,
.ads_bg,
.ads_big,
.ads_bigrec,
.ads_block,
.ads_border,
.ads_box,
.ads_box_headline,
.ads_box_type1,
.ads_center,
.ads_code,
.ads_column,
.ads_container,
.ads_container_top,
.ads_content,
.ads_css,
.ads_div,
.ads_div1,
.ads_foot,
.ads_footer,
.ads_footerad,
.ads_full_1,
.ads_google,
.ads_h,
.ads_h1,
.ads_h2,
.ads_header,
.ads_header_bottom,
.ads_holder,
.ads_home,
.ads_horizontal,
.ads_inview,
.ads_item,
.ads_label,
.ads_lb,
.ads_leader,
.ads_leaderboard,
.ads_left,
.ads_main,
.ads_main_hp,
.ads_media,
.ads_medium,
.ads_medium_rectangle,
.ads_medrect,
.ads_middle,
.ads_middle-container,
.ads_middle_container,
.ads_mobile_vert,
.ads_mpu,
.ads_outer,
.ads_outline,
.ads_place,
.ads_place_160,
.ads_place_top,
.ads_placeholder,
.ads_player,
.ads_post,
.ads_prtext,
.ads_rectangle,
.ads_remove,
.ads_right,
.ads_rightbar_top,
.ads_side,
.ads_sideba,
.ads_sidebar,
.ads_single_center,
.ads_single_side,
.ads_single_top,
.ads_singlepost,
.ads_slice,
.ads_slot,
.ads_small,
.ads_small_rectangle,
.ads_space_long,
.ads_spacer,
.ads_square,
.ads_takeover,
.ads_text,
.ads_tit,
.ads_title,
.ads_top,
.ads_top_1,
.ads_top_banner,
.ads_top_both,
.ads_top_middle,
.ads_top_nav,
.ads_topbanner,
.ads_topleft,
.ads_topright,
.ads_tower,
.ads_tr,
.ads_under_data,
.ads_unit,
.ads_up,
.ads_video,
.ads_wide,
.ads_widesky,
.ads_widget,
.ads_wrap,
.ads_wrap-para,
.ads_wrapper,
.adsafp,
.adsanity-alignnone,
.adsanity-group,
.adsanity-single,
.adsarea,
.adsartical,
.adsbanner1,
.adsbanner2,
.adsbantop,
.adsbar,
.adsbg300,
.adsbillboard,
.adsblock,
.adsblockvert,
.adsbnr,
.adsbody,
.adsborder,
.adsboth,
.adsbottom,
.adsbottombox,
.adsbox--masthead,
.adsbox-square,
.adsbox970x90,
.adsbox990x90,
.adsboxBtn,
.adsbox_300x250,
.adsboxitem,
.adsbx728x90,
.adsbyadop,
.adsbyexoclick,
.adsbyexoclick-wrapper,
.adsbygalaksion,
.adsbygoogle-box,
.adsbygoogle-noablate,
.adsbygoogle-wrapper,
.adsbygoogle2,
.adsbypublift,
.adsbypubmax,
.adsbytrafficjunky,
.adsbyvli,
.adsbyxa,
.adscaleTop,
.adscenter,
.adscentertext,
.adsclick,
.adscontainer,
.adscontent250,
.adscontentcenter,
.adscontntad,
.adscreen,
.adsdelivery,
.adsdesktop,
.adsdiv,
.adsection_a2,
.adsection_c2,
.adsection_c3,
.adsenbox,
.adsens,
.adsense-250,
.adsense-300-600,
.adsense-336,
.adsense-336-280,
.adsense-468,
.adsense-728-90,
.adsense-ad-results,
.adsense-ads,
.adsense-afterpost,
.adsense-area,
.adsense-article,
.adsense-block,
.adsense-box,
.adsense-center,
.adsense-code,
.adsense-container,
.adsense-content,
.adsense-div,
.adsense-float,
.adsense-googleAds,
.adsense-header,
.adsense-heading,
.adsense-iframe-container,
.adsense-inline,
.adsense-left,
.adsense-links,
.adsense-loading,
.adsense-module,
.adsense-overlay,
.adsense-post,
.adsense-resposivo-meio,
.adsense-right,
.adsense-slot,
.adsense-square,
.adsense-sticky-slide,
.adsense-title,
.adsense-top,
.adsense-unit,
.adsense-widget,
.adsense-wrapper,
.adsense1,
.adsense160x600,
.adsense250,
.adsense3,
.adsense300,
.adsense300x250,
.adsense728,
.adsense728x90,
.adsenseAds,
.adsenseBannerArea,
.adsenseBlock,
.adsenseContainer,
.adsenseList,
.adsenseRow,
.adsenseSky,
.adsenseWrapper,
.adsense_200,
.adsense_336_280,
.adsense_728x90_container,
.adsense_ad,
.adsense_block,
.adsense_bottom,
.adsense_container,
.adsense_content_300x250,
.adsense_div_wrapper,
.adsense_inner,
.adsense_label,
.adsense_leader,
.adsense_media,
.adsense_menu,
.adsense_mpu,
.adsense_rectangle,
.adsense_results,
.adsense_right,
.adsense_sidebar,
.adsense_sidebar_top,
.adsense_single,
.adsense_top,
.adsense_top_ad,
.adsense_unit,
.adsense_wrapper,
.adsensebig,
.adsensefloat,
.adsenseformat,
.adsenseframe,
.adsenseleaderboard,
.adsensemobile,
.adsenvelope,
.adsep,
.adserve_728,
.adserverBox,
.adserver_zone,
.adserverad,
.adserving,
.adset,
.adsfloat,
.adsfloatpanel,
.adsforums,
.adsghori,
.adsgrd,
.adsgvert,
.adsheight-250,
.adshome,
.adshowbig,
.adshowcase,
.adshp,
.adside,
.adside-box-index,
.adside-box-single,
.adside_box,
.adsidebar,
.adsidebox,
.adsider,
.adsincs2,
.adsinfo,
.adsingle,
.adsingle-r,
.adsingleph,
.adsitem,
.adsize728,
.adsizer,
.adsizewrapper,
.adskeeperWrap,
.adsky,
.adsleaderboard,
.adsleaderboardbox,
.adsleff,
.adsleft,
.adsleftblock,
.adslibraryArticle,
.adslider,
.adslink,
.adslist,
.adslisting,
.adslisting2,
.adslistingz,
.adsload,
.adsloading,
.adslogan,
.adslot,
.adslot--leaderboard,
.adslot-area,
.adslot-banner,
.adslot-billboard,
.adslot-feature,
.adslot-inline-wide,
.adslot-mpu,
.adslot-rectangle,
.adslot-widget,
.adslot970,
.adslotMid,
.adslot_1,
.adslot_1m,
.adslot_2,
.adslot_2m,
.adslot_3,
.adslot_300,
.adslot_3d,
.adslot_3m,
.adslot_4,
.adslot_728,
.adslot__ad-container,
.adslot__ad-wrapper,
.adslot_blurred,
.adslot_bot_300x250,
.adslot_collapse,
.adslot_popup,
.adslot_side1,
.adslothead,
.adslotleft,
.adslotright,
.adslotright_1,
.adslotright_2,
.adslug,
.adsmaintop,
.adsmall,
.adsmaller,
.adsmalltext,
.adsmanag,
.adsmbody,
.adsmedrect,
.adsmedrectright,
.adsmessage,
.adsmobile,
.adsninja-ad-zone,
.adsninja-ad-zone-container-with-set-height,
.adsninja-rail-zone,
.adsnippet_widget,
.adsns,
.adsntl,
.adsonar-after,
.adsonofftrigger,
.adsoptimal-slot,
.adsother,
.adspace,
.adspace-300x600,
.adspace-336x280,
.adspace-728x90,
.adspace-MR,
.adspace-lb,
.adspace-leaderboard,
.adspace-lr,
.adspace-mpu,
.adspace-mtb,
.adspace-top,
.adspace-widget,
.adspace1,
.adspace180,
.adspace2,
.adspace728x90,
.adspace_2,
.adspace_bottom,
.adspace_buysell,
.adspace_right,
.adspace_rotate,
.adspace_skyscraper,
.adspace_top,
.adspacer,
.adspacer2,
.adspan,
.adspanel,
.adspecial390,
.adspeed,
.adsplash-160x600,
.adsplat,
.adsponsor,
.adspop,
.adspost,
.adspot,
.adspot-desk,
.adspot-title,
.adspot1,
.adspot200x90,
.adspot468x60,
.adspot728x90,
.adspotGrey,
.adspot_468x60,
.adspot_728x90,
.adsprefooter,
.adspreview,
.adsrecnode,
.adsresponsive,
.adsright,
.adss,
.adss-rel,
.adssidebar2,
.adsskyscraper,
.adsslotcustom2,
.adsslotcustom4,
.adssmall,
.adssquare,
.adssquare2,
.adsterra,
.adstext,
.adstextpad,
.adstipt,
.adstitle,
.adstop,
.adstory,
.adstrip,
.adstyle,
.adsverting,
.adsvideo,
.adswallpapr,
.adswidget,
.adswiper,
.adswitch,
.adswordatas,
.adsystem_ad,
.adszone,
.adt-300x250,
.adt-300x600,
.adt-728x90,
.adtab,
.adtable,
.adtag,
.adtc,
.adtech,
.adtech-ad-widget,
.adtech-banner,
.adtech-boxad,
.adtech-copy,
.adtech-video-2,
.adtech-wrapper,
.adtechMobile,
.adtech_wrapper,
.adtester-container,
.adtext-bg,
.adtext_gray,
.adtext_horizontal,
.adtext_onwhite,
.adtext_vertical,
.adtext_white,
.adtextleft,
.adtextright,
.adthrive,
.adthrive-ad,
.adthrive-content,
.adthrive-header,
.adthrive-header-container,
.adthrive-placeholder-content,
.adthrive-placeholder-header,
.adthrive-placeholder-static-sidebar,
.adthrive-placeholder-video,
.adthrive-sidebar,
.adthrive-video-player,
.adthrive_custom_ad,
.adtile,
.adtips,
.adtips1,
.adtitle,
.adtoggle,
.adtop,
.adtop-border,
.adtops,
.adtower,
.adtravel,
.adttl,
.adtxt,
.adtxtlinks,
.adult-adv,
.adun,
.adunit,
.adunit-300-250,
.adunit-active,
.adunit-adbridg,
.adunit-container,
.adunit-container_sitebar_1,
.adunit-googleadmanager,
.adunit-lazy,
.adunit-middle,
.adunit-parent,
.adunit-purch,
.adunit-side,
.adunit-title,
.adunit-top,
.adunit-wrap,
.adunit-wrapper,
.adunit125,
.adunit160,
.adunit300x250,
.adunit468,
.adunitContainer,
.adunit_300x250,
.adunit_728x90,
.adunit_content,
.adunit_footer,
.adunit_leaderboard,
.adunit_rectangle,
.adv--h600,
.adv--square,
.adv-120x600,
.adv-160,
.adv-160x600,
.adv-200-200,
.adv-250-250,
.adv-300,
.adv-300-1,
.adv-300-250,
.adv-300-600,
.adv-300x250,
.adv-300x250-generic,
.adv-336-280,
.adv-4,
.adv-468-60,
.adv-468x60,
.adv-700,
.adv-728,
.adv-728-90,
.adv-970,
.adv-970-250,
.adv-970-250-2,
.adv-980x60,
.adv-ad,
.adv-ads-selfstyle,
.adv-aside,
.adv-background,
.adv-banner,
.adv-bar,
.adv-block,
.adv-block-container,
.adv-border,
.adv-bottom,
.adv-box,
.adv-box-holder,
.adv-box-wrapper,
.adv-carousel,
.adv-center,
.adv-click,
.adv-cont,
.adv-cont1,
.adv-conteiner,
.adv-dvb,
.adv-format-1,
.adv-full-width,
.adv-google,
.adv-gpt-desktop-wrapper,
.adv-gpt-wrapper-desktop,
.adv-halfpage,
.adv-header,
.adv-holder,
.adv-in-body,
.adv-inset,
.adv-intext,
.adv-intext-label,
.adv-key,
.adv-label,
.adv-leaderboard,
.adv-leaderboard-banner,
.adv-link--left,
.adv-link--right,
.adv-mobile-wrapper,
.adv-mpu,
.adv-outer,
.adv-p,
.adv-right,
.adv-right-300,
.adv-rotator,
.adv-script-container,
.adv-sidebar,
.adv-skin-spacer,
.adv-slot-container,
.adv-text,
.adv-top,
.adv-top-banner,
.adv-top-container,
.adv-top-page,
.adv-top-skin,
.adv-under-video,
.adv-unit,
.adv-videoad,
.adv-x61,
.adv1,
.adv120,
.adv200,
.adv250,
.adv300,
.adv300-250,
.adv300-250-2,
.adv300-70,
.adv300left,
.adv300x100,
.adv300x250,
.adv300x60,
.adv300x70,
.adv336,
.adv350,
.adv460x60,
.adv468,
.adv468x90,
.adv728,
.adv728x90,
.advBottom,
.advBottomHome,
.advBox,
.advInt,
.advLeaderboard,
.advRightBig,
.advSquare,
.advText,
.advTop,
.adv_120,
.adv_120_600,
.adv_120x240,
.adv_120x600,
.adv_160_600,
.adv_160x600,
.adv_250,
.adv_250_250,
.adv_300,
.adv_300_300,
.adv_300_top,
.adv_300x250,
.adv_336_280,
.adv_468_60,
.adv_728_90,
.adv_728x90,
.adv__box,
.adv__leaderboard,
.adv__wrapper,
.adv_aff,
.adv_banner,
.adv_banner_hor,
.adv_bg,
.adv_box,
.adv_box_narrow,
.adv_here,
.adv_img,
.adv_leaderboard,
.adv_left,
.adv_link,
.adv_main_middle,
.adv_main_middle_wrapper,
.adv_main_right_down,
.adv_main_right_down_wrapper,
.adv_medium_rectangle,
.adv_message,
.adv_msg,
.adv_outbrain,
.adv_panel,
.adv_right,
.adv_side1,
.adv_side2,
.adv_sidebar,
.adv_title,
.adv_top,
.adv_txt,
.adv_under_menu,
.advads-background,
.advads-close-button,
.advads-parallax-container,
.advads-sticky,
.advads-target,
.advads-widget,
.advads_ad_widget-11,
.advads_ad_widget-18,
.advads_ad_widget-2,
.advads_ad_widget-21,
.advads_ad_widget-3,
.advads_ad_widget-4,
.advads_ad_widget-5,
.advads_ad_widget-8,
.advads_ad_widget-9,
.advads_widget,
.advance-ads,
.advart,
.advbig,
.adver,
.adver-block,
.adver-header,
.adver-left,
.adver-text,
.adverTag,
.adverTxt,
.adver_bot,
.adver_cont_below,
.adver_home,
.advert--background,
.advert--banner-wrap,
.advert--fallback,
.advert--header,
.advert--in-sidebar,
.advert--inline,
.advert--leaderboard,
.advert--loading,
.advert--outer,
.advert--placeholder,
.advert--right-rail,
.advert--square,
.advert-100,
.advert-120x90,
.advert-160x600,
.advert-300,
.advert-300-side,
.advert-728,
.advert-728-90,
.advert-728x90,
.advert-article-bottom,
.advert-autosize,
.advert-background,
.advert-banner,
.advert-banner-container,
.advert-banner-holder,
.advert-bannerad,
.advert-bar,
.advert-bg-250,
.advert-block,
.advert-border,
.advert-bot-box,
.advert-bottom,
.advert-box,
.advert-bronze,
.advert-bronze-btm,
.advert-btm,
.advert-card,
.advert-center,
.advert-col,
.advert-col-center,
.advert-competitions,
.advert-container,
.advert-content,
.advert-content-item,
.advert-detail,
.advert-dfp,
.advert-featured,
.advert-footer,
.advert-gold,
.advert-group,
.advert-head,
.advert-header-728,
.advert-horizontal,
.advert-image,
.advert-info,
.advert-inner,
.advert-label,
.advert-leaderboard,
.advert-leaderboard2,
.advert-loader,
.advert-mini,
.advert-mpu,
.advert-mrec,
.advert-note,
.advert-overlay,
.advert-pane,
.advert-panel,
.advert-placeholder,
.advert-placeholder-wrapper,
.advert-preview-wrapper,
.advert-right,
.advert-row,
.advert-section,
.advert-sidebar,
.advert-silver,
.advert-sky,
.advert-skyright,
.advert-skyscraper,
.advert-slider,
.advert-spot-container,
.advert-sticky-wrapper,
.advert-stub,
.advert-text,
.advert-three,
.advert-title,
.advert-top,
.advert-top-footer,
.advert-txt,
.advert-unit,
.advert-wide,
.advert-wingbanner-left,
.advert-wingbanner-right,
.advert-wrap,
.advert-wrap1,
.advert-wrap2,
.advert-wrapper,
.advert-wrapper-exco,
.advert.box,
.advert.desktop,
.advert.mobile,
.advert.mpu,
.advert.skyscraper,
.advert1,
.advert120,
.advert1Banner,
.advert2,
.advert300,
.advert4,
.advert5,
.advert728_90,
.advert728x90,
.advert8,
.advertBanner,
.advertBar,
.advertBlock,
.advertBottom,
.advertBox,
.advertCaption,
.advertColumn,
.advertCont,
.advertContainer,
.advertDownload,
.advertFullBanner,
.advertHeader,
.advertHeadline,
.advertLink,
.advertLink1,
.advertMPU,
.advertMiddle,
.advertMpu,
.advertRight,
.advertSideBar,
.advertSign,
.advertSlider,
.advertSlot,
.advertSuperBanner,
.advertText,
.advertTitleSky,
.advertWrapper,
.advert_300x250,
.advert_336,
.advert_468x60,
.advert__container,
.advert__fullbanner,
.advert__leaderboard,
.advert__mpu,
.advert__sidebar,
.advert__tagline,
.advert_area,
.advert_banner,
.advert_banners,
.advert_block,
.advert_box,
.advert_caption,
.advert_cont,
.advert_container,
.advert_div,
.advert_foot,
.advert_header,
.advert_home_300,
.advert_img,
.advert_label,
.advert_leaderboard,
.advert_line,
.advert_list,
.advert_main,
.advert_main_bottom,
.advert_mpu,
.advert_nav,
.advert_note,
.advert_pos,
.advert_small,
.advert_span,
.advert_text,
.advert_title,
.advert_top,
.advert_txt,
.advert_wrapper,
.advertbar,
.advertbox,
.adverteaser,
.advertembed,
.adverthome,
.adverticum_container,
.adverticum_content,
.advertis,
.advertis-left,
.advertis-right,
.advertise-1,
.advertise-2,
.advertise-box,
.advertise-here,
.advertise-horz,
.advertise-info,
.advertise-leaderboard,
.advertise-link,
.advertise-list,
.advertise-pic,
.advertise-small,
.advertise-square,
.advertise-top,
.advertise-vert,
.advertiseContainer,
.advertiseHere,
.advertiseText,
.advertise_ads,
.advertise_box,
.advertise_brand,
.advertise_carousel,
.advertise_here,
.advertise_link,
.advertise_link_sidebar,
.advertise_links,
.advertise_sec,
.advertise_text,
.advertise_txt,
.advertise_verRight,
.advertisebtn,
.advertisedBy,
.advertisement-1,
.advertisement-2,
.advertisement-250,
.advertisement-300,
.advertisement-300x250,
.advertisement-background,
.advertisement-banner,
.advertisement-block,
.advertisement-bottom,
.advertisement-box,
.advertisement-card,
.advertisement-cell,
.advertisement-container,
.advertisement-content,
.advertisement-copy,
.advertisement-footer,
.advertisement-google,
.advertisement-header,
.advertisement-holder,
.advertisement-image,
.advertisement-label,
.advertisement-layout,
.advertisement-leaderboard,
.advertisement-leaderboard-lg,
.advertisement-left,
.advertisement-link,
.advertisement-nav,
.advertisement-placeholder,
.advertisement-position1,
.advertisement-right,
.advertisement-sidebar,
.advertisement-space,
.advertisement-sponsor,
.advertisement-tag,
.advertisement-text,
.advertisement-title,
.advertisement-top,
.advertisement-txt,
.advertisement-wrapper,
.advertisement.leaderboard,
.advertisement.rectangle,
.advertisement.under-article,
.advertisement1,
.advertisement300x250,
.advertisement468,
.advertisementBackground,
.advertisementBanner,
.advertisementBar,
.advertisementBlock,
.advertisementBox,
.advertisementBoxBan,
.advertisementContainer,
.advertisementFull,
.advertisementHeader,
.advertisementImg,
.advertisementLabel,
.advertisementPanel,
.advertisementRotate,
.advertisementSection,
.advertisementSmall,
.advertisementText,
.advertisementTop,
.advertisement_160x600,
.advertisement_300x250,
.advertisement_728x90,
.advertisement__header,
.advertisement__label,
.advertisement__leaderboard,
.advertisement__wrapper,
.advertisement_box,
.advertisement_container,
.advertisement_footer,
.advertisement_header,
.advertisement_horizontal,
.advertisement_mobile,
.advertisement_part,
.advertisement_post,
.advertisement_section_top,
.advertisement_text,
.advertisement_top,
.advertisement_wrapper,
.advertisements-link,
.advertisements-right,
.advertisements-sidebar,
.advertisements_heading,
.advertisementwrap,
.advertiser,
.advertiser-links,
.advertising--row,
.advertising--top,
.advertising-banner,
.advertising-block,
.advertising-container,
.advertising-container-top,
.advertising-content,
.advertising-disclaimer,
.advertising-fixed,
.advertising-header,
.advertising-iframe,
.advertising-inner,
.advertising-leaderboard,
.advertising-lrec,
.advertising-mediumrectangle,
.advertising-mention,
.advertising-middle,
.advertising-middle-i,
.advertising-notice,
.advertising-right,
.advertising-right-d,
.advertising-right-i,
.advertising-section,
.advertising-side,
.advertising-side-hp,
.advertising-srec,
.advertising-top,
.advertising-top-banner,
.advertising-top-box,
.advertising-top-category,
.advertising-top-desktop,
.advertising-vert,
.advertising-wrapper,
.advertising1,
.advertising160,
.advertising2,
.advertising300_home,
.advertising300x250,
.advertising728,
.advertising728_3,
.advertisingBanner,
.advertisingBlock,
.advertisingLabel,
.advertisingLegend,
.advertisingLrec,
.advertisingMob,
.advertisingRight,
.advertisingSlide,
.advertisingTable,
.advertisingTop,
.advertising_300x250,
.advertising_banner,
.advertising_block,
.advertising_bottom_box,
.advertising_box_bg,
.advertising_header_1,
.advertising_hibu_lef,
.advertising_hibu_mid,
.advertising_hibu_rig,
.advertising_horizontal_title,
.advertising_images,
.advertising_square,
.advertising_top,
.advertising_vertical_title,
.advertising_widget,
.advertising_wrapper,
.advertisingarea,
.advertisingarea-homepage,
.advertisingimage,
.advertisingimage-extended,
.advertisingimageextended,
.advertisment,
.advertisment-banner,
.advertisment-label,
.advertisment-left-panal,
.advertisment-module,
.advertisment-rth,
.advertisment-top,
.advertismentBox,
.advertismentContainer,
.advertismentContent,
.advertismentText,
.advertisment_bar,
.advertisment_caption,
.advertisment_full,
.advertisment_notice,
.advertisment_two,
.advertize,
.advertize_here,
.advertizing-banner,
.advertlabel,
.advertleft,
.advertlink,
.advertnotice,
.advertop,
.advertorial,
.advertorial-2,
.advertorial-block,
.advertorial-image,
.advertorial-promo-box,
.advertorial-teaser,
.advertorial-wrapper,
.advertorial2,
.advertorial_728x90,
.advertorial_red,
.advertorialitem,
.advertorialtitle,
.advertorialview,
.advertorialwidget,
.advertouter,
.advertplay,
.adverts,
.adverts--banner,
.adverts-125,
.adverts-inline,
.adverts2,
.advertsLeaderboard,
.adverts_RHS,
.adverts_footer_advert,
.adverts_footer_scrolling_advert,
.adverts_header_advert,
.adverts_side_advert,
.advertspace,
.adverttext,
.adverttop,
.advfrm,
.advg468,
.advhere,
.adviewDFPBanner,
.advimg160600,
.advimg300250,
.advn_zone,
.advoice,
.advr,
.advr-wrapper,
.advr_top,
.advrectangle,
.advrst,
.advslideshow,
.advspot,
.advt,
.advt-banner-3,
.advt-block,
.advt-right,
.advt-sec,
.advt300,
.advt720,
.advtBlock,
.advtMsg,
.advt_160x600,
.advt_468by60px,
.advt_indieclick,
.advt_single,
.advt_widget,
.advtbox,
.advtcell,
.advtext,
.advtimg,
.advtitle,
.advtop,
.advtop-leaderbord,
.advttopleft,
.advv_box,
.adwblue,
.adwert,
.adwhitespace,
.adwide,
.adwideskyright,
.adwidget,
.adwithspace,
.adwobs,
.adwolf-holder,
.adword-box,
.adword-structure,
.adword-text,
.adword-title,
.adword1,
.adwordListings,
.adwords,
.adwords-container,
.adwordsHeader,
.adwords_in_content,
.adworks,
.adwrap,
.adwrap-mrec,
.adwrap-widget,
.adwrap_MPU,
.adwrapper--desktop,
.adwrapper-lrec,
.adwrapper1,
.adwrapper948,
.adwrappercls,
.adwrappercls1,
.adx-300x250-container,
.adx-300x600-container,
.adx-ads,
.adx-wrapper,
.adx-wrapper-middle,
.adx_center,
.adxli,
.adz-horiz,
.adz-horiz-ext,
.adz2,
.adz728x90,
.adzbanner,
.adzone,
.adzone-footer,
.adzone-preview,
.adzone-sidebar,
.adzone_skyscraper,
.ae-player__itv,
.af-block-ad-wrapper,
.af-label-ads,
.afc-box,
.aff-big-unit,
.aff-iframe,
.afffix-custom-ad,
.affiliate-ad,
.affiliate-footer,
.affiliate-link,
.affiliate-sidebar,
.affiliate-strip,
.affiliateAdvertText,
.affiliate_ad,
.affiliate_header_ads,
.after-content-ad,
.after-intro-ad,
.after-post-ad,
.after-post-ads,
.after-story-ad-wrapper,
.after_ad,
.after_comments_ads,
.after_content_banner_advert,
.after_post_ad,
.afw_ad,
.aggads-ad,
.ahe-ad,
.ai-top-ad-outer,
.aisle-ad,
.ajax_ad,
.ajaxads,
.ajdg_bnnrwidgets,
.ajdg_grpwidgets,
.alice-adslot,
.alice-root-header-ads__ad--top,
.align.Ad,
.alignads,
.alt_ad,
.alt_ad_block,
.altad,
.am-adContainer,
.am-adslot,
.am-bazaar-ad,
.amAdvert,
.am_ads,
.amazon-auto-links,
.amazon_ad,
.amazonads,
.ami-video-wrapper,
.amp-ad,
.amp-ad-container,
.amp-ad__wrapper,
.amp-ads,
.amp-ads-container,
.amp-adv-container,
.amp-adv-wrapper,
.amp-article-ad-element,
.amp-flying-carpet-text-border,
.amp-sticky-ad-custom,
.amp-sticky-ads,
.amp-unresolved,
.ampFlyAdd,
.amp_ad_1,
.amp_ad_header,
.amp_ad_wrapper,
.ampad,
.ampexcoVideoPlayer,
.ampforwp-sticky-custom-ad,
.anchor-ad,
.anchor-ad-wrapper,
.anchorAd,
.anchored-ad-widget,
.aniview-inline-player,
.annonstext,
.anyClipWrapper,
.anyad,
.anzeige_banner,
.aoa_overlay,
.ap-ad-block,
.ape-ads-container,
.apexAd,
.apiAds,
.aplvideo,
.app-ad,
.app_ad_unit,
.app_advertising_skyscraper,
.app_nexus_banners_common,
.ar-header-m-ad,
.arc-ad-wrapper,
.arcAdsBox,
.arcAdsContainer,
.arcad-block-container,
.archive-ad,
.archive-ads,
.archive-radio-ad-container,
.areaAd,
.area_ad,
.area_ad03,
.area_ad07,
.area_ad09,
.area_ad2,
.arena-ad-col,
.art-text-ad,
.artAd,
.artAdInner,
.art_ads,
.artcl_ad_dsk,
.article--ad,
.article--content-ad,
.article-ad,
.article-ad-align-left,
.article-ad-blk,
.article-ad-bottom,
.article-ad-box,
.article-ad-cont,
.article-ad-container,
.article-ad-holder,
.article-ad-horizontal,
.article-ad-left,
.article-ad-legend,
.article-ad-main,
.article-ad-placeholder,
.article-ad-placement,
.article-ad-primary,
.article-ad-row,
.article-ad-row-inner,
.article-ad-section,
.article-ads,
.article-advert,
.article-advert--text,
.article-advert-container,
.article-advert-dfp,
.article-aside-ad,
.article-aside-top-ad,
.article-connatix-wrap,
.article-content-ad,
.article-content-adwrap,
.article-detail-ad,
.article-first-ad,
.article-footer-ad,
.article-footer-ad-container,
.article-footer__ad,
.article-footer__ads,
.article-header-ad,
.article-header__railAd,
.article-inline-ad,
.article-mid-ad,
.article-small-ads,
.article-sponsor,
.article-sponsorship-header,
.article-taboola,
.article-top-ad,
.articleADbox,
.articleAd,
.articleAdHeader,
.articleAdTopRight,
.articleAds,
.articleAdsL,
.articleAdvert,
.articleBottom-ads,
.articleEmbeddedAdBox,
.articleFooterAd,
.articleHeaderAd,
.articleTop-ads,
.articleTopAd,
.article_OutbrainContent,
.article__ad-holder,
.article__adblock,
.article__adhesion,
.article__adv,
.article_ad,
.article_ad_1,
.article_ad_2,
.article_ad_text,
.article_ad_top,
.article_adbox,
.article_ads_banner,
.article_bottom-ads,
.article_bottom_ad,
.article_google-ad,
.article_google_ads,
.article_inline_ad,
.article_inner_ad,
.article_mpu,
.article_tower_ad,
.articlead,
.articleads,
.articles-ad-block,
.artnet-ads-ad,
.aside-ad,
.aside-ad-space,
.aside-ad-wrapper,
.aside-ads,
.aside-ads-top,
.asideAd,
.aside_ad,
.aside_ad_large,
.aspace-300x169,
.aspace-300x250,
.async-ad-container,
.at-header-ad,
.at-sidebar-ad,
.atf-ad,
.atfAds,
.atf_adWrapper,
.atomsAdsCellModel,
.attachment-advert_home,
.attachment-dm-advert-bronze,
.attachment-dm-advert-gold,
.attachment-dm-advert-silver,
.attachment-sidebar-ad,
.attachment-squareAd,
.avadvslot,
.avap-ads-container,
.avert--leaderboard,
.avert--sidebar,
.avert-text,
.avp-p-wrapper,
.azk-adsense,
.b-ad,
.b-ad-main,
.b-adhesion,
.b-adv,
.b-advert,
.b-advertising__down-menu,
.b-aside-ads,
.b-header-ad,
.b-right-rail--ads,
.bAdvertisement,
.b_adLastChild,
.b_ads,
.b_ads_cont,
.b_ads_r,
.b_ads_top,
.background-ad,
.background-ads,
.background-adv,
.backgroundAd,
.bam-ad-slot,
.bank-rate-ad,
.banmanad,
.banner--ad,
.banner-125,
.banner-300,
.banner-300-100,
.banner-300-250,
.banner-300x250,
.banner-300x600,
.banner-320-100,
.banner-468,
.banner-468-60,
.banner-468x60,
.banner-728,
.banner-728x90,
.banner-ad,
.banner-ad-b,
.banner-ad-below,
.banner-ad-block,
.banner-ad-bottom-fixed,
.banner-ad-container,
.banner-ad-contianer,
.banner-ad-footer,
.banner-ad-image,
.banner-ad-inner,
.banner-ad-label,
.banner-ad-large,
.banner-ad-pos,
.banner-ad-row,
.banner-ad-skeleton-box,
.banner-ad-space,
.banner-ad-wrap,
.banner-ad-wrapper,
.banner-ad2,
.banner-ads-right,
.banner-ads-sidebar,
.banner-adsense,
.banner-adv,
.banner-advert,
.banner-advert-wrapper,
.banner-advertisement,
.banner-advertising,
.banner-adverts,
.banner-asd__title,
.banner-buysellads,
.banner-sponsorship,
.banner-top-ads,
.banner120x600,
.banner160,
.banner160x600,
.banner200x200,
.banner300,
.banner300x250,
.banner336,
.banner336x280,
.banner350,
.banner468,
.banner728,
.banner728-ad,
.banner728-container,
.banner728x90,
.bannerADS,
.bannerADV,
.bannerAd,
.bannerAd-module,
.bannerAd3,
.bannerAdContainer,
.bannerAdLeaderboard,
.bannerAdRectangle,
.bannerAdSearch,
.bannerAdSidebar,
.bannerAdTower,
.bannerAdWrap,
.bannerAds,
.bannerAdvert,
.bannerAside,
.bannerGoogle,
.bannerRightAd,
.banner_160x600,
.banner_240x400,
.banner_250x250,
.banner_300_250,
.banner_300x250,
.banner_300x600,
.banner_468_60,
.banner_468x60,
.banner_728_90,
.banner_ad-728x90,
.banner_ad_300x250,
.banner_ad_728x90,
.banner_ad_container,
.banner_ad_footer,
.banner_ad_full,
.banner_ad_leaderboard,
.banner_ad_link,
.banner_ad_wrapper,
.banner_ads1,
.banner_reklam,
.banner_reklam2,
.banner_slot,
.bannerad,
.bannerad3,
.banneradd,
.bannerads,
.banneradv,
.bannerandads,
.bannergroup-ads,
.bannermpu,
.banners_ad,
.bannervcms,
.bar_ad,
.base-ad-mpu,
.base-ad-slot,
.base-ad-top,
.base_ad,
.baseboard-ad,
.bb-ad,
.bb-ad-mrec,
.bbccom-advert,
.bbccom_advert,
.bcom_ad,
.before-header-ad,
.before-injected-ad,
.below-ad-border,
.below-article-ad-sidebar,
.below-nav-ad,
.belowMastheadWrapper,
.belowNavAds,
.below_game_ad,
.below_nav_ad_wrap,
.below_player_ad,
.bg-ad-gray,
.bg-ads,
.bg-ads-space,
.bg-grey-ad,
.bgAdBlue,
.bg_ad,
.bg_ads,
.bgcolor_ad,
.bgr-ad-leaderboard,
.bh-ads,
.bh_ad_container,
.bidbarrel-ad,
.big-ad,
.big-ads,
.big-advertisement,
.big-box-ad,
.big-right-ad,
.bigAd,
.bigAdContainer,
.bigAds,
.bigAdvBanner,
.bigBoxAdArea,
.bigCubeAd,
.big_ad,
.big_ad2,
.big_ads,
.bigad,
.bigad1,
.bigad2,
.bigadleft,
.bigadright,
.bigads,
.bigadtxt1,
.bigbox-ad,
.bigbox.ad,
.bigbox_ad,
.bigboxad,
.bigsponsor,
.billboard-ad,
.billboard-ad-one,
.billboard-ad-space,
.billboard-ads,
.billboard.ad,
.billboardAd,
.billboard__advert,
.billboard_ad,
.billboard_ad_wrap,
.billboard_adwrap,
.bing-ads-wrapper,
.bing-native-ad,
.bl300_ad,
.block--ad,
.block--ads,
.block--dfp,
.block--doubleclick,
.block--simpleads,
.block-ad,
.block-ad-entity,
.block-ad-header,
.block-ad-leaderboard,
.block-ad-wrapper,
.block-admanager,
.block-ads,
.block-ads-bottom,
.block-ads-home,
.block-ads-system,
.block-ads-top,
.block-ads-yahoo,
.block-ads1,
.block-ads2,
.block-ads3,
.block-ads_top,
.block-adsense,
.block-adtech,
.block-adv,
.block-advert,
.block-advertisement,
.block-advertisement-banner-block,
.block-advertising,
.block-adzerk,
.block-bg-advertisement,
.block-boxes-ad,
.block-cdw-google-ads,
.block-dfp,
.block-dfp-ad,
.block-dfp-blocks,
.block-doubleclick_ads,
.block-fusion-ads,
.block-google-admanager,
.block-openads,
.block-openx,
.block-quartz-ads,
.block-reklama,
.block-simpleads,
.block-skyscraper-ad,
.block-sponsor,
.block-sponsored-links,
.block-the-dfp,
.block-wrap-ad,
.block-yt-ads,
.blockAd,
.blockAds,
.blockAdvertise,
.block__ads__ad,
.block_ad,
.block_ad1,
.block_ad303x1000_left,
.block_ad303x1000_right,
.block_ad_middle,
.block_ad_top,
.block_ads,
.block_adslot,
.block_adv,
.block_advert,
.block_article_ad,
.blockad,
.blocked-ads,
.blog-ad,
.blog-ad-image,
.blog-ads,
.blog-advertisement,
.blogAd,
.blogAdvertisement,
.blog_ad,
.blogads,
.bmd_advert,
.bn_ads,
.bnr_ad,
.body-ad,
.body-ads,
.body-top-ads,
.bodyAd,
.body_ad,
.bodyads,
.bodyads2,
.bordered-ad,
.botAd,
.bot_ad,
.bot_ads,
.bottom-ad,
.bottom-ad--bigbox,
.bottom-ad-banner,
.bottom-ad-box,
.bottom-ad-container,
.bottom-ad-desktop,
.bottom-ad-large,
.bottom-ad-placeholder,
.bottom-ad-wrapper,
.bottom-ad-zone,
.bottom-ad2,
.bottom-ads,
.bottom-ads-container,
.bottom-ads-sticky,
.bottom-ads-wrapper,
.bottom-adv,
.bottom-adv-container,
.bottom-banner-ad,
.bottom-fixed-ad,
.bottom-left-ad,
.bottom-main-adsense,
.bottom-mobile-ad,
.bottom-mpu-ad,
.bottom-post-ad-space,
.bottom-post-ads,
.bottom-right-advert,
.bottom-side-advertisement,
.bottomAd,
.bottomAdBlock,
.bottomAdContainer,
.bottomAds,
.bottomAdvert,
.bottomAdvertisement,
.bottom_ad,
.bottom_ad_block,
.bottom_ad_placeholder,
.bottom_ad_responsive,
.bottom_ads,
.bottom_adsense,
.bottom_adspace,
.bottom_banner_ad,
.bottom_banner_advert_text,
.bottom_bar_ads,
.bottom_left_advert,
.bottom_right_ad,
.bottom_rightad,
.bottom_side_ad,
.bottom_sponsor,
.bottom_sticky_ad,
.bottomad,
.bottomads,
.bottomadvert,
.botton_advertisement,
.box-ad,
.box-ad-middle,
.box-ads,
.box-adsense,
.box-adsense-top,
.box-advert,
.box-advertisement,
.box-advertising,
.box-adverts,
.box-entry-ad,
.box-fixed-ads,
.box-footer-ad,
.box-outbrain,
.boxAd,
.boxAdContainer,
.boxAds,
.boxAds2,
.boxAdvertisement,
.boxOverContent__banner,
.boxSponsor,
.box_ad,
.box_ad_container,
.box_ad_content,
.box_ad_horizontal,
.box_ad_spacer,
.box_ad_wrap,
.box_ads,
.box_adv,
.box_adv_728,
.box_advert,
.box_advertising,
.box_content_ad,
.box_content_ads,
.box_layout_ad,
.box_publicidad,
.box_sidebar-ads,
.boxad,
.boxad1,
.boxad2,
.boxadcont,
.boxads,
.boxadv,
.bps-ad-wrapper,
.bps-advertisement,
.bq_adleaderboard,
.bq_rightAd,
.br-ad,
.br-ad-wrapper,
.brandpost_inarticle,
.breadads,
.break-ads,
.breaker-ad,
.breakerAd,
.briefNewsAd,
.brn-ads-box,
.brn-ads-mobile-container,
.brn-ads-sticky-wrapper,
.broker-ad,
.browse-ad-container,
.browsi-ad,
.btm_ad,
.btn_ad,
.bump-ad,
.bunyad-ad,
.buttom_ad,
.buttom_ad_size,
.button-ad,
.button-ads,
.buttonAd,
.buttonAdSpot,
.buttonAds,
.button_ad,
.button_ads,
.button_advert,
.button_left_ad,
.button_right_ad,
.buttonad,
.buttonadbox,
.buttonads,
.buySellAdsContainer,
.buysellAds,
.buzzAd,
.c-Ad,
.c-Adhesion,
.c-ArticleAds,
.c-ad,
.c-ad--adStickyContainer,
.c-ad--bigbox,
.c-ad--header,
.c-ad-flex,
.c-ad-fluid,
.c-ad-placeholder,
.c-ad-size2,
.c-ad-size3,
.c-adDisplay,
.c-adDisplay_container,
.c-adOmnibar,
.c-adSense,
.c-adSkyBox,
.c-adbutler-ad,
.c-adbutler-ad__wrapper,
.c-adcontainer,
.c-ads,
.c-adunit,
.c-adunit--billboard,
.c-adunit--first,
.c-adunit__container,
.c-adv3__inner,
.c-advert,
.c-advert-app,
.c-advert-superbanner,
.c-advertisement,
.c-advertisement--billboard,
.c-advertisement--rectangle,
.c-advertising,
.c-advertising__banner-area,
.c-adverts,
.c-advscrollingzone,
.c-box--advert,
.c-gallery-vertical__advert,
.c-googleadslot,
.c-gpt-ad,
.c-header__ad,
.c-header__advert-container,
.c-pageArticleSingle_bottomAd,
.c-prebid,
.c-sidebar-ad-stream__ad,
.c-sitenav-adslot,
.c-sitenavPlaceholder__ad,
.c2_outbrain,
.c_nt_ad,
.cableads,
.cactus-ads,
.cactus-header-ads,
.caja_ad,
.california-ad,
.california-sidebar-ad,
.calloutAd,
.carbon-ad,
.carbon_ads,
.carbonad,
.carbonad-tag,
.carbonads-widget,
.card--ad,
.card--article-ad,
.card-ad,
.card-ads,
.card-article-ads,
.cardAd,
.catalog_ads,
.category-ad:not(html):not(body):not(.post),
.category-ads:not(html):not(body):not(.post),
.categoryMosaic-advertising,
.categoryMosaic-advertisingText,
.cazAd,
.cb-ad-banner,
.cb-ad-container,
.cbd_ad_manager,
.cbs-ad,
.cc-advert,
.center-ad,
.center-ad-long,
.center-tag-rightad,
.centerAD,
.centerAd,
.centerAds,
.center_ad,
.center_add,
.center_ads,
.center_inline_ad,
.centerad,
.centerads,
.centeradv,
.centered-ad,
.ch-ad-item,
.channel--ad,
.channel-ad,
.channel-adv,
.channel-icon--ad,
.channel-icon__ad-buffer,
.channel-sidebar-big-box-ad,
.channelBoxAds,
.channel_ad_2016,
.chapter-bottom-ads,
.chapter-top-ads,
.chart_ads,
.chitika-ad,
.ck-anyclips,
.ck-anyclips-article,
.cl-ad-billboard,
.clAdPlacementAnchorWrapper,
.clever-core-ads,
.click-track.partner,
.clickforceads,
.clickio-side-ad,
.client-ad,
.clsy-c-advsection,
.cms-ad,
.cn-advertising,
.cnbcHeaderAd,
.cnc-ads,
.cnx-player,
.cnx-player-wrapper,
.coinzilla-ad,
.coinzilla-ad--mobile,
.col-ad,
.col-ad-hidden,
.col-has-ad,
.col-line-ad,
.col2-ads,
.colAd,
.colBoxAdframe,
.colBoxDisplayAd,
.col_ad,
.colads,
.collapsed-ad,
.colombiaAd,
.column-ad,
.columnAd,
.columnAdvert,
.columnBoxAd,
.columnRightAdvert,
.combinationAd,
.comment-ad,
.comment-ad-wrap,
.comment-advertisement,
.comment_ad,
.comment_ad_box,
.commercialAd,
.companion-ad,
.companion-ads,
.companionAd,
.companion_ad,
.complex-ad,
.component-ar-horizontal-bar-ad,
.component-header-sticky-ad,
.component-outbrain,
.components-Ad-___Ad__ad,
.con_ads,
.connatix,
.connatix-container,
.connatix-hodler,
.connatix-holder,
.connatix-main-container,
.connatix-wrapper,
.connatix-wysiwyg-container,
.consoleAd,
.cont-ad,
.container--ad,
.container--ads,
.container--ads-leaderboard-atf,
.container--advert,
.container--bannerAd,
.container-ad-600,
.container-ad-left,
.container-adds,
.container-adrotate,
.container-ads,
.container-adwords,
.container-banner-ads,
.container-bottom-ad,
.container-content__container-relatedlinks,
.container-first-ads,
.container-lower-ad,
.container-rectangle-ad,
.container-top-adv,
.containerAdsense,
.containerSqAd,
.container__ad,
.container__box--ads,
.container_ad,
.container_ad_v,
.container_publicidad,
.containerads,
.contains-ad,
.contains-advertisment,
.content--right-ads,
.content-ad,
.content-ad-article,
.content-ad-box,
.content-ad-container,
.content-ad-left,
.content-ad-right,
.content-ad-side,
.content-ad-widget,
.content-ad-wrapper,
.content-ads,
.content-ads-bottom,
.content-advert,
.content-advertisment,
.content-bottom-ad,
.content-bottom-mpu,
.content-cliff__ad,
.content-cliff__ad-container,
.content-contentad,
.content-footer-ad,
.content-footer-ad-block,
.content-header-ad,
.content-item-ad-top,
.content-kuss-ads,
.content-leaderboard-ad,
.content-leaderboard-ads,
.content-page-ad_wrap,
.content-result-ads,
.content-top-ad-item,
.content1-ad,
.content2-ad,
.contentAd,
.contentAd--sb1,
.contentAdBox,
.contentAdContainer,
.contentAdFoot,
.contentAdIndex,
.contentAds,
.contentAdsCommon,
.contentAdsWrapper,
.contentAdvertisement,
.contentTopAd,
.contentTopAdSmall,
.contentTopAds,
.content__ad,
.content__ad__content,
.content_ad,
.content_ad_728,
.content_ad_head,
.content_ad_side,
.content_ads,
.content_adsense,
.content_adsq,
.content_advert,
.content_advertising,
.content_advt,
.content_bottom_adsense,
.content_gpt_top_ads,
.content_inner_ad,
.content_left_advert,
.contentad,
.contentad-end,
.contentad-home,
.contentad-storyad-1,
.contentad-superbanner-2,
.contentad-top,
.contentad2,
.contentad300x250,
.contentad_right_col,
.contentadarticle,
.contentadfloatl,
.contentadleft,
.contentads1,
.contentads2,
.contentbox_ad,
.contentleftad,
.contents-ads-bottom-left,
.contest_ad,
.context-ads,
.contextualAds,
.contextual_ad_unit,
.coreAdsPlacer,
.cornerad,
.cpmstarHeadline,
.cpmstarText,
.crain-advertisement,
.criteo-ad,
.crm-adcontain,
.crumb-ad,
.cspAd,
.css--ad,
.ct-ads,
.ct-advert,
.ct-advertising-footer,
.ct-bottom-ads,
.ct_ad,
.ct_ampad,
.cta-ad,
.cube-ad,
.cubeAd,
.cube_ad,
.cube_ads,
.custom-ad,
.custom-ad-area,
.custom-ad-container,
.custom-ads,
.custom-advert-banner,
.custom-sticky-ad-container,
.customAd,
.custom_ad,
.custom_ad_responsive,
.custom_ads,
.custom_ads_positions,
.custom_banner_ad,
.custom_footer_ad,
.customadvert,
.customized_ad_module,
.cwAdvert,
.cxAdvertisement,
.d1-top-ad,
.d3-c-adblock,
.d3-o-adv-block,
.da-custom-ad-box,
.dac__banner__wrapper,
.dac__mpu-card,
.daily-adlabel,
.dart-ad,
.dart-ad-content,
.dart-ad-grid,
.dart-ad-title,
.dart-advertisement,
.dart-leaderboard,
.dart-leaderboard-top,
.dartAdImage,
.dart_ad,
.dart_tag,
.dartad,
.dartadbanner,
.dartadvert,
.dartiframe,
.dc-ad,
.dc-banner,
.dc-half-banner,
.dc-widget-adv-125,
.dcmads,
.dd-ad,
.dd-ad-container,
.deckAd,
.deckads,
.default_rc_theme,
.demand-supply,
.desktop-ad,
.desktop-ad-banner,
.desktop-ad-container,
.desktop-ad-inpage,
.desktop-ad-slider,
.desktop-ads,
.desktop-adunit,
.desktop-advert,
.desktop-article-top-ad,
.desktop-aside-ad-hide,
.desktop-lazy-ads,
.desktop-sidebar-ad-wrapper,
.desktop-top-ad-wrapper,
.desktop.ad,
.desktopAd,
.desktop_ad,
.desktop_mpu,
.desktop_only_ad,
.desktopads,
.detail-ad,
.detail-ads,
.detail__ad--small,
.detail_ad,
.detail_article_ad,
.detail_top_advert,
.details-advert,
.dfm-featured-bottom-flex-container,
.dfp-ad,
.dfp-ad-bigbox2-wrap,
.dfp-ad-container,
.dfp-ad-container-box,
.dfp-ad-container-wide,
.dfp-ad-full,
.dfp-ad-hideempty,
.dfp-ad-lazy,
.dfp-ad-lead2-wrap,
.dfp-ad-lead3-wrap,
.dfp-ad-midbreaker-wrap,
.dfp-ad-midbreaker2-wrap,
.dfp-ad-placeholder,
.dfp-ad-rect,
.dfp-ad-region-1,
.dfp-ad-region-2,
.dfp-ad-tags,
.dfp-ad-top-wrapper,
.dfp-ad-unit,
.dfp-ad-widget,
.dfp-ads-ad-article-middle,
.dfp-ads-embedded,
.dfp-adspot,
.dfp-article-ad,
.dfp-banner,
.dfp-banner-slot,
.dfp-billboard-wrapper,
.dfp-block,
.dfp-bottom,
.dfp-button,
.dfp-close-ad,
.dfp-double-mpu,
.dfp-dynamic-tag,
.dfp-fixedbar,
.dfp-here-bottom,
.dfp-here-top,
.dfp-interstitial,
.dfp-leaderboard,
.dfp-leaderboard-container,
.dfp-mrec,
.dfp-panel,
.dfp-plugin-advert,
.dfp-position,
.dfp-slot,
.dfp-slot-wallpaper,
.dfp-space,
.dfp-super-leaderboard,
.dfp-tag-wrapper,
.dfp-top,
.dfp-top1,
.dfp-top1-container,
.dfp-top_leaderboard,
.dfp-wrap,
.dfp-wrapper,
.dfpAd,
.dfpAdUnitContainer,
.dfpAds,
.dfpAdspot,
.dfpAdvert,
.dfp_ATF_wrapper,
.dfp_ad--outbrain,
.dfp_ad_block,
.dfp_ad_caption,
.dfp_ad_content_bottom,
.dfp_ad_content_top,
.dfp_ad_footer,
.dfp_ad_header,
.dfp_ad_pos,
.dfp_ad_unit,
.dfp_ads_block,
.dfp_frame,
.dfp_slot,
.dfp_strip,
.dfp_top-ad,
.dfp_txt,
.dfp_unit,
.dfp_unit--interscroller,
.dfp_unit-ad_container,
.dfpad,
.dfrads,
.dfx-ad,
.dfx-adBlock1Wrapper,
.dg-gpt-ad-container,
.dianomi-ad,
.dianomi-container,
.dianomi-embed,
.dianomiScriptContainer,
.dianomi_context,
.dikr-responsive-ads-slot,
.discourse-adplugin,
.discourse-google-dfp,
.display-ad,
.display-ad-block,
.display-adhorizontal,
.display-ads-block,
.display-advertisement,
.displayAd,
.displayAdCode,
.displayAdSlot,
.displayAdUnit,
.displayAds,
.display_ad,
.display_ads_right,
.div-gpt-ad-adhesion-leaderboard-wrap,
.div-insticator-ad,
.divAd,
.divAdright,
.divAds,
.divAdsBanner,
.divAdsLeft,
.divAdsRight,
.divReklama,
.divRepAd,
.divSponsoredBox {
display: none !important;
}

.ad-banner-placeholder,
.ad-banner-single,
.ad-banner-smaller,
.ad-banner-static,
.ad-banner-top,
.ad-banner-top-wrapper,
.ad-banner-wrapper,
.ad-banners,
.ad-bar,
.ad-bar-header,
.ad-bb,
.ad-before-header,
.ad-below,
.ad-below-images,
.ad-below-player,
.ad-belowarticle,
.ad-bg,
.ad-big,
.ad-big-box,
.ad-bigbanner,
.ad-bigbillboard,
.ad-bigbox,
.ad-bigbox-double-inread,
.ad-bigbox-fixed,
.ad-bigsize,
.ad-billboard,
.ad-bline,
.ad-block,
.ad-block--300,
.ad-block--leader,
.ad-block-300,
.ad-block-banner-container,
.ad-block-big,
.ad-block-bottom,
.ad-block-btf,
.ad-block-container,
.ad-block-header,
.ad-block-holder,
.ad-block-inside,
.ad-block-mod,
.ad-block-section,
.ad-block-square,
.ad-block-sticky-ad,
.ad-block-wide,
.ad-block-wk,
.ad-block-wrapper,
.ad-block-wrapper-dev,
.ad-blogads,
.ad-bnr,
.ad-body,
.ad-boombox,
.ad-border,
.ad-bordered,
.ad-borderless,
.ad-bot,
.ad-bottom,
.ad-bottom-container,
.ad-bottom-right-container,
.ad-bottom728x90,
.ad-bottomLeft,
.ad-bottomleader,
.ad-bottomline,
.ad-box-2,
.ad-box-300x250,
.ad-box-auto,
.ad-box-caption,
.ad-box-container,
.ad-box-title,
.ad-box-up,
.ad-box-video,
.ad-box-wrapper,
.ad-box1,
.ad-box2,
.ad-box3,
.ad-box:not(#ad-banner):not(:empty),
.ad-box_h,
.ad-boxamp-wrapper,
.ad-boxbottom,
.ad-boxes,
.ad-boxsticky,
.ad-boxtop,
.ad-brdr-btm,
.ad-break,
.ad-break-item,
.ad-breaker,
.ad-breakout,
.ad-browse-rectangle,
.ad-bt,
.ad-btn,
.ad-btn-heading,
.ad-bug-300w,
.ad-burnside,
.ad-button,
.ad-buttons,
.ad-c-label,
.ad-cad,
.ad-calendar,
.ad-call-300x250,
.ad-callout,
.ad-callout-wrapper,
.ad-caption,
.ad-card,
.ad-card-container,
.ad-carousel,
.ad-cat,
.ad-catfish,
.ad-cell,
.ad-cen,
.ad-cen2,
.ad-cen3,
.ad-center,
.ad-centered,
.ad-centering,
.ad-chartbeatwidget,
.ad-choices,
.ad-circ,
.ad-click,
.ad-close-button,
.ad-cls,
.ad-cls-fix,
.ad-cnt,
.ad-code,
.ad-codes,
.ad-col,
.ad-col-02,
.ad-colour,
.ad-column,
.ad-comment,
.ad-companion,
.ad-complete,
.ad-component,
.ad-component-fullbanner2,
.ad-component-wrapper,
.ad-contain,
.ad-contain-300x250,
.ad-contain-top,
.ad-container--hot-video,
.ad-container--inline,
.ad-container--leaderboard,
.ad-container--masthead,
.ad-container--mrec,
.ad-container--stripe,
.ad-container--top,
.ad-container-160x600,
.ad-container-300x250,
.ad-container-728,
.ad-container-728x90,
.ad-container-adsense,
.ad-container-banner-top,
.ad-container-bot,
.ad-container-bottom,
.ad-container-box,
.ad-container-embedded,
.ad-container-header,
.ad-container-inner,
.ad-container-inthread,
.ad-container-leaderboard,
.ad-container-left,
.ad-container-m,
.ad-container-medium-rectangle,
.ad-container-middle,
.ad-container-multiple,
.ad-container-pave,
.ad-container-property,
.ad-container-responsive,
.ad-container-right,
.ad-container-side,
.ad-container-single,
.ad-container-tool,
.ad-container-top,
.ad-container-topad,
.ad-container-wrapper,
.ad-container1,
.ad-container3x,
.ad-container__ad-slot,
.ad-container__leaderboard,
.ad-container__sticky-wrapper,
.ad-container_row,
.ad-content,
.ad-content-area,
.ad-content-rectangle,
.ad-content-slot,
.ad-content-wrapper,
.ad-context,
.ad-cover,
.ad-critical,
.ad-cta,
.ad-current,
.ad-curtain,
.ad-custom-size,
.ad-d,
.ad-decoration,
.ad-defer,
.ad-desktop,
.ad-desktop-in-content,
.ad-desktop-legacy,
.ad-desktop-native-1,
.ad-desktop-native-2,
.ad-desktop-only,
.ad-desktop-right,
.ad-detail,
.ad-dfp-column,
.ad-dfp-row,
.ad-disclaimer,
.ad-disclaimer-container,
.ad-disclaimer-text,
.ad-display,
.ad-displayed,
.ad-diver,
.ad-divider,
.ad-dog,
.ad-dog__cnx-container,
.ad-dog__ratio-16x9,
.ad-dt,
.ad-dx_wrp,
.ad-e,
.ad-element,
.ad-enabled,
.ad-engage,
.ad-entity-container,
.ad-entry-wrapper,
.ad-ex,
.ad-exchange,
.ad-expand,
.ad-external,
.ad-fadein,
.ad-fadeup,
.ad-fallback,
.ad-feature-content,
.ad-feature-sponsor,
.ad-feature-text,
.ad-featured-video-caption,
.ad-feedback,
.ad-fi,
.ad-field,
.ad-filler,
.ad-filmstrip,
.ad-first,
.ad-fix,
.ad-fixed,
.ad-flag,
.ad-flex,
.ad-flex-center,
.ad-float,
.ad-floating,
.ad-floor,
.ad-footer,
.ad-footer-empty,
.ad-footer-leaderboard,
.ad-format-300x250,
.ad-format-300x600,
.ad-forum,
.ad-frame,
.ad-frame-container,
.ad-full,
.ad-full-width,
.ad-fullbanner,
.ad-fullbanner-btf-container,
.ad-fullbannernohieght,
.ad-fullwidth,
.ad-gap-sm,
.ad-giga,
.ad-google,
.ad-google-contextual,
.ad-gpt,
.ad-gpt-breaker,
.ad-gpt-container,
.ad-gpt-main,
.ad-gpt-vertical,
.ad-graphic-large,
.ad-gray,
.ad-grey,
.ad-grid,
.ad-grid-125,
.ad-grid-container,
.ad-group,
.ad-halfpage,
.ad-halfpage-placeholder,
.ad-hdr,
.ad-head,
.ad-header,
.ad-header-below,
.ad-header-container,
.ad-header-creative,
.ad-header-inner-wrap,
.ad-header-pencil,
.ad-header-placeholder,
.ad-header-sidebar,
.ad-header-small-square,
.ad-heading,
.ad-height-250,
.ad-height-280,
.ad-height-600,
.ad-here,
.ad-hero,
.ad-hide-mobile,
.ad-hideable,
.ad-hint,
.ad-hldr-tmc,
.ad-ho,
.ad-hold,
.ad-holder,
.ad-holder-center,
.ad-holder-mob-300,
.ad-home-bottom,
.ad-home-leaderboard-placeholder,
.ad-home-right,
.ad-homeleaderboard,
.ad-homepage,
.ad-homepage-1,
.ad-homepage-2,
.ad-homepage-one,
.ad-hor,
.ad-horizontal,
.ad-horizontal-large,
.ad-horizontal-top,
.ad-horizontal-top-wrapper,
.ad-house-btac,
.ad-housepromo-d-wrapper,
.ad-hoverable,
.ad-hpto,
.ad-href1,
.ad-href2,
.ad-iab-txt,
.ad-icon,
.ad-identifier,
.ad-iframe,
.ad-iframe-container,
.ad-in-content,
.ad-in-content-300,
.ad-in-post,
.ad-in-read,
.ad-in-results,
.ad-inStory,
.ad-incontent,
.ad-incontent-wrap,
.ad-index-main,
.ad-indicator-horiz,
.ad-info-wrap,
.ad-inline,
.ad-inline-article,
.ad-inline-block,
.ad-inner,
.ad-inner-container,
.ad-inner-container-background,
.ad-innr,
.ad-insert,
.ad-inserter-widget,
.ad-inside,
.ad-integrated-display,
.ad-internal,
.ad-interruptor,
.ad-interstitial,
.ad-island,
.ad-item,
.ad-item-related,
.ad-label,
.ad-lable,
.ad-landscape,
.ad-large-1,
.ad-large-game,
.ad-last,
.ad-lat,
.ad-lat2,
.ad-layer,
.ad-lazy,
.ad-lb,
.ad-ldrbrd,
.ad-lead,
.ad-lead-bottom,
.ad-leader,
.ad-leader-board,
.ad-leader-bottom,
.ad-leader-plus-top,
.ad-leader-top,
.ad-leader-wrap,
.ad-leader-wrapper,
.ad-leaderboard,
.ad-leaderboard-base,
.ad-leaderboard-companion,
.ad-leaderboard-container,
.ad-leaderboard-flex,
.ad-leaderboard-footer,
.ad-leaderboard-header,
.ad-leaderboard-middle,
.ad-leaderboard-placeholder,
.ad-leaderboard-slot,
.ad-leaderboard-splitter,
.ad-leaderboard-top,
.ad-leaderboard-wrapper,
.ad-leaderbody,
.ad-leaderheader,
.ad-leadtop,
.ad-left-1,
.ad-left-top,
.ad-leftrail,
.ad-lib-div,
.ad-line,
.ad-link,
.ad-link-block,
.ad-link-label,
.ad-link-left,
.ad-link-right,
.ad-links,
.ad-links-text,
.ad-list-desktop,
.ad-list-item,
.ad-loaded,
.ad-loader,
.ad-location,
.ad-location-container,
.ad-lock,
.ad-lock-content,
.ad-lowerboard,
.ad-lrec,
.ad-m-banner,
.ad-m-mrec,
.ad-m-rec,
.ad-mad,
.ad-main,
.ad-manager-ad,
.ad-manager-placeholder,
.ad-manager-wrapper,
.ad-margin,
.ad-marketplace,
.ad-marketswidget,
.ad-marquee,
.ad-masthead,
.ad-masthead-1,
.ad-masthead-left,
.ad-mb,
.ad-med,
.ad-med-rec,
.ad-med-rect,
.ad-med-rect-tmp,
.ad-medium,
.ad-medium-container,
.ad-medium-content,
.ad-medium-rectangle,
.ad-medium-rectangle-base,
.ad-medium-two,
.ad-medium-widget,
.ad-medrect,
.ad-megaboard,
.ad-message,
.ad-messaging,
.ad-microsites,
.ad-midleader,
.ad-mobile,
.ad-mobile--sticky,
.ad-mobile-300x150,
.ad-mobile-300x250,
.ad-mobile-300x50,
.ad-mobile-banner,
.ad-mobile-flex-inc,
.ad-mobile-flex-pos2,
.ad-mobile-incontent-ad-plus,
.ad-mobile-mpu-plus-outstream-inc,
.ad-mobile-nav-ad-plus,
.ad-mod,
.ad-mod-section,
.ad-mod-section-728-90,
.ad-module,
.ad-mount,
.ad-mpl,
.ad-mpu,
.ad-mpu-bottom,
.ad-mpu-container,
.ad-mpu-middle,
.ad-mpu-middle2,
.ad-mpu-placeholder,
.ad-mpu-plus-top,
.ad-mpu-top,
.ad-mpu__aside,
.ad-mpufixed,
.ad-mr-article,
.ad-mrec,
.ad-mrect,
.ad-msg,
.ad-msn,
.ad-native,
.ad-native-top-sidebar,
.ad-nav-ad,
.ad-nav-ad-plus,
.ad-new,
.ad-new-box,
.ad-no-css,
.ad-no-mobile,
.ad-no-notice,
.ad-no-style,
.ad-noBorderAndMargin,
.ad-noline,
.ad-note,
.ad-notice,
.ad-notice-small,
.ad-observer,
.ad-oms,
.ad-on,
.ad-on-top,
.ad-one,
.ad-other,
.ad-outer,
.ad-outlet,
.ad-outline,
.ad-output-middle,
.ad-output-wrapper,
.ad-outside,
.ad-overlay,
.ad-packs,
.ad-padding,
.ad-page-leader,
.ad-page-medium,
.ad-page-setting,
.ad-pagehead,
.ad-panel,
.ad-panel-wrap,
.ad-panel__container,
.ad-panel__container--styled,
.ad-panel__googlead,
.ad-panorama,
.ad-parallax,
.ad-parent-billboard,
.ad-parent-class,
.ad-parent-halfpage,
.ad-pb,
.ad-peg,
.ad-pencil-margin,
.ad-permalink,
.ad-personalise,
.ad-place,
.ad-place-active,
.ad-place-holder,
.ad-placeholder,
.ad-placeholder--mpu,
.ad-placeholder-leaderboard,
.ad-placeholder-wrapper,
.ad-placeholder-wrapper-dynamic,
.ad-placeholder__inner,
.ad-placement-left,
.ad-placement-right,
.ad-places,
.ad-plea,
.ad-poc,
.ad-poc-admin,
.ad-point,
.ad-popup,
.ad-popup-content,
.ad-pos,
.ad-pos-0,
.ad-pos-1,
.ad-pos-2,
.ad-pos-3,
.ad-pos-4,
.ad-pos-5,
.ad-pos-6,
.ad-pos-7,
.ad-pos-8,
.ad-pos-middle,
.ad-pos-top,
.ad-position,
.ad-position-1,
.ad-position-2,
.ad-poss,
.ad-post,
.ad-post-footer,
.ad-post-top,
.ad-postText,
.ad-poster,
.ad-posterad-inlisting,
.ad-preloader-container,
.ad-preparing,
.ad-prevent-jump,
.ad-primary,
.ad-primary-desktop,
.ad-primary-sidebar,
.ad-priority,
.ad-program-list,
.ad-program-top,
.ad-promo,
.ad-pub,
.ad-push,
.ad-pushdown,
.ad-r,
.ad-rac-box,
.ad-rail,
.ad-rail-wrapper,
.ad-ratio,
.ad-rb-hover,
.ad-reader-con-item,
.ad-rect,
.ad-rect-atf-01,
.ad-rect-top-right,
.ad-rectangle,
.ad-rectangle-1,
.ad-rectangle-banner,
.ad-rectangle-container,
.ad-rectangle-long,
.ad-rectangle-long-sky,
.ad-rectangle-text,
.ad-rectangle-wide,
.ad-rectangle-xs,
.ad-rectangle2,
.ad-rectanglemed,
.ad-region,
.ad-region-delay-load,
.ad-related,
.ad-relatedbottom,
.ad-render-space,
.ad-responsive,
.ad-responsive-slot,
.ad-responsive-wide,
.ad-result,
.ad-rev-content,
.ad-rh,
.ad-right,
.ad-right-header,
.ad-right1,
.ad-right2,
.ad-right3,
.ad-risingstar-container,
.ad-roadblock,
.ad-root,
.ad-rotation,
.ad-rotator,
.ad-row,
.ad-row-box,
.ad-row-horizontal,
.ad-row-horizontal-top,
.ad-row-viewport,
.ad-s,
.ad-s-rendered,
.ad-sample,
.ad-script-processed,
.ad-scroll,
.ad-scrollpane,
.ad-search-grid,
.ad-secondary-desktop,
.ad-section,
.ad-section-body,
.ad-section-one,
.ad-section-three,
.ad-section__skyscraper,
.ad-sense,
.ad-sense-ad,
.ad-sep,
.ad-separator,
.ad-shifted,
.ad-show-label,
.ad-showcase,
.ad-side,
.ad-side-one,
.ad-side-top,
.ad-side-wrapper,
.ad-sidebar,
.ad-sidebar-mrec,
.ad-sidebar-skyscraper,
.ad-siderail,
.ad-signup,
.ad-single-bottom,
.ad-sitewide,
.ad-size-300x600,
.ad-size-728x90,
.ad-size-landscape,
.ad-size-leaderboard,
.ad-size-medium-rectangle,
.ad-size-medium-rectangle-flex,
.ad-size-mpu,
.ad-skeleton,
.ad-skin-link,
.ad-sky,
.ad-sky-left,
.ad-sky-right,
.ad-sky-wrap,
.ad-skyscr,
.ad-skyscraper,
.ad-skyscraper1,
.ad-skyscraper2,
.ad-skyscraper3,
.ad-slider,
.ad-slot,
.ad-slot--container,
.ad-slot--inline,
.ad-slot--mostpop,
.ad-slot--mpu-banner-ad,
.ad-slot--rendered,
.ad-slot--right,
.ad-slot--top,
.ad-slot--top-above-nav,
.ad-slot--top-banner-ad,
.ad-slot--wrapper,
.ad-slot-1,
.ad-slot-2,
.ad-slot-234-60,
.ad-slot-300-250,
.ad-slot-728-90,
.ad-slot-a,
.ad-slot-article,
.ad-slot-banner,
.ad-slot-bigbox,
.ad-slot-billboard,
.ad-slot-box,
.ad-slot-container,
.ad-slot-container-1,
.ad-slot-desktop,
.ad-slot-full-width,
.ad-slot-header,
.ad-slot-horizontal,
.ad-slot-inview,
.ad-slot-placeholder,
.ad-slot-rail,
.ad-slot-replies,
.ad-slot-replies-header,
.ad-slot-responsive,
.ad-slot-sidebar,
.ad-slot-sidebar-b,
.ad-slot-tall,
.ad-slot-top,
.ad-slot-top-728,
.ad-slot-widget,
.ad-slot-wrapper,
.ad-slotRg,
.ad-slotRgc,
.ad-slot__ad--top,
.ad-slot__content,
.ad-slot__label,
.ad-slot__oas,
.ad-slots-wrapper,
.ad-slug,
.ad-small,
.ad-small-1,
.ad-small-2,
.ad-smallBP,
.ad-source,
.ad-sp,
.ad-space,
.ad-space-mpu-box,
.ad-space-topbanner,
.ad-spacing,
.ad-span,
.ad-speedbump,
.ad-splash,
.ad-sponsor,
.ad-sponsor-large-container,
.ad-sponsor-text,
.ad-sponsored-feed-top,
.ad-sponsored-links,
.ad-sponsored-post,
.ad-sponsors,
.ad-spot,
.ad-spotlight,
.ad-spteaser,
.ad-sq-super,
.ad-square,
.ad-square-placeholder,
.ad-square2-container,
.ad-square300,
.ad-squares,
.ad-stack,
.ad-standard,
.ad-statement,
.ad-static,
.ad-sticky,
.ad-sticky-banner,
.ad-sticky-bottom,
.ad-sticky-container,
.ad-sticky-slot,
.ad-sticky-wrapper,
.ad-stickyhero,
.ad-stickyhero--standard,
.ad-stickyhero-enable-mobile,
.ad-story-inject,
.ad-story-top,
.ad-strategic,
.ad-strip,
.ad-style2,
.ad-subnav-container,
.ad-subtitle,
.ad-summary,
.ad-superbanner,
.ad-superbanner-node,
.ad-t,
.ad-t-text,
.ad-table,
.ad-tabs,
.ad-tag,
.ad-tag-square,
.ad-tag__inner,
.ad-tag__wrapper,
.ad-takeover,
.ad-takeover-homepage,
.ad-tall,
.ad-tech-widget,
.ad-temp,
.ad-text,
.ad-text-centered,
.ad-text-label,
.ad-text-link,
.ad-text-links,
.ad-textads,
.ad-textlink,
.ad-thanks,
.ad-ticker,
.ad-tile,
.ad-title,
.ad-tl1,
.ad-top,
.ad-top-300x250,
.ad-top-728,
.ad-top-728x90,
.ad-top-banner,
.ad-top-billboard,
.ad-top-billboard-init,
.ad-top-box-right,
.ad-top-container,
.ad-top-desktop,
.ad-top-featured,
.ad-top-in,
.ad-top-lboard,
.ad-top-left,
.ad-top-mobile,
.ad-top-mpu,
.ad-top-padding,
.ad-top-rectangle,
.ad-top-right-container,
.ad-top-side,
.ad-top-slot,
.ad-top-spacing,
.ad-top-wrap-inner,
.ad-top-wrapper,
.ad-topbanner,
.ad-topper,
.ad-topright,
.ad-tower,
.ad-tower-container,
.ad-towers,
.ad-transition,
.ad-trck,
.ad-two,
.ad-twos,
.ad-txt,
.ad-txt-red,
.ad-type,
.ad-type-branding,
.ad-type-cube,
.ad-type-flex-leaderboard,
.ad-unit,
.ad-unit--leaderboard,
.ad-unit-2,
.ad-unit-300,
.ad-unit-300-wrapper,
.ad-unit-container,
.ad-unit-horisontal,
.ad-unit-inline-center,
.ad-unit-label,
.ad-unit-mpu,
.ad-unit-panel,
.ad-unit-secondary,
.ad-unit-sponsored-bar,
.ad-unit-t,
.ad-unit-text,
.ad-unit-top,
.ad-unit-wrapper,
.ad-unit__inner,
.ad-units-single-header-wrapper,
.ad-update,
.ad-vert,
.ad-vertical,
.ad-vertical-container,
.ad-vertical-stack-ad,
.ad-view-zone,
.ad-w-300,
.ad-w-728,
.ad-w-970,
.ad-warning,
.ad-warp,
.ad-watermark,
.ad-wgt,
.ad-wide,
.ad-wide-bottom,
.ad-wide-wrap,
.ad-widget,
.ad-widget-area,
.ad-widget-box,
.ad-widget-list,
.ad-widget-sizes,
.ad-widget-wrapper,
.ad-widgets,
.ad-width-300,
.ad-width-728,
.ad-wireframe,
.ad-wireframe-wrapper,
.ad-with-background,
.ad-with-header-wrapper,
.ad-with-notice,
.ad-wp,
.ad-wp-720,
.ad-wppr,
.ad-wppr-container,
.ad-wrap-leaderboard,
.ad-wrap-transparent,
.ad-wrap:not(#google_ads_iframe_checktag),
.ad-wrap_wallpaper,
.ad-wrapp,
.ad-wrapper,
.ad-wrapper--ad-unit-wrap,
.ad-wrapper--articletop,
.ad-wrapper--lg,
.ad-wrapper--sidebar,
.ad-wrapper-250,
.ad-wrapper-bg,
.ad-wrapper-left,
.ad-wrapper-mobile-atf,
.ad-wrapper-outer,
.ad-wrapper-solid,
.ad-wrapper-sticky,
.ad-wrapper-top,
.ad-wrapper-with-text,
.ad-wrapper__ad-slug,
.ad-xs-title,
.ad-zone,
.ad-zone-ajax,
.ad-zone-container,
.ad.addon,
.ad.bottomrect,
.ad.box,
.ad.brandboard,
.ad.card,
.ad.center,
.ad.contentboard,
.ad.desktop-970x250,
.ad.element,
.ad.floater-link,
.ad.gallery,
.ad.halfpage,
.ad.inner,
.ad.item,
.ad.leaderboard,
.ad.maxiboard,
.ad.maxisky,
.ad.middlerect,
.ad.module,
.ad.monsterboard,
.ad.netboard,
.ad.post-area,
.ad.promotion,
.ad.rectangle,
.ad.rectangle_2,
.ad.rectangle_3,
.ad.rectangle_home_1,
.ad.reform-top,
.ad.section,
.ad.sidebar-module,
.ad.size-300x250,
.ad.skybridgeleft,
.ad.small-mpu,
.ad.small-teaser,
.ad.super,
.ad.wideboard_tablet,
.ad02,
.ad03,
.ad04,
.ad08sky,
.ad1-float,
.ad1-left,
.ad1-right,
.ad10,
.ad100,
.ad1000,
.ad1001,
.ad100x100,
.ad120,
.ad120_600,
.ad120x120,
.ad120x240GrayBorder,
.ad120x60,
.ad120x600,
.ad125,
.ad125x125,
.ad125x125a,
.ad125x125b,
.ad140,
.ad160,
.ad160600,
.ad160_blk,
.ad160_l,
.ad160_r,
.ad160b,
.ad160x160,
.ad160x600,
.ad160x600GrayBorder,
.ad160x600_1,
.ad160x600box,
.ad170x30,
.ad18,
.ad180,
.ad180x80,
.ad185x100,
.ad19,
.ad1Image,
.ad1_bottom,
.ad1_latest,
.ad1_top,
.ad1b,
.ad1left,
.ad1x1,
.ad2-float,
.ad200,
.ad200x60,
.ad220x50,
.ad230,
.ad233x224,
.ad234,
.ad234x60,
.ad236x62,
.ad240,
.ad250,
.ad250wrap,
.ad250x250,
.ad250x300,
.ad260,
.ad260x60,
.ad284x134,
.ad290,
.ad2content_box,
.ad300,
.ad300-hp-top,
.ad3001,
.ad300250,
.ad300Block,
.ad300Wrapper,
.ad300X250,
.ad300_2,
.ad300_250,
.ad300_bg,
.ad300_ver2,
.ad300b,
.ad300banner,
.ad300px,
.ad300shows,
.ad300top,
.ad300w,
.ad300x100,
.ad300x120,
.ad300x150,
.ad300x250,
.ad300x250-1,
.ad300x250-2,
.ad300x250-inline,
.ad300x250Module,
.ad300x250Right,
.ad300x250Top,
.ad300x250_box,
.ad300x250_container,
.ad300x250a,
.ad300x250b,
.ad300x250box,
.ad300x250box2,
.ad300x250flex,
.ad300x250s,
.ad300x250x2,
.ad300x40,
.ad300x50-right,
.ad300x600,
.ad300x600cat,
.ad300x600post,
.ad300x77,
.ad300x90,
.ad310,
.ad315,
.ad320x250,
.ad320x50,
.ad336,
.ad336_b,
.ad336x250,
.ad336x280,
.ad336x362,
.ad343x290,
.ad350,
.ad350r,
.ad360,
.ad366,
.ad3rdParty,
.ad400,
.ad400right,
.ad400x40,
.ad450,
.ad468,
.ad468_60,
.ad468box,
.ad468innerboxadpic,
.ad468x60,
.ad468x60Wrap,
.ad468x60_main,
.ad470x60,
.ad530,
.ad540x90,
.ad590,
.ad590x90,
.ad5_container,
.ad600,
.ad612x80,
.ad620x70,
.ad626X35,
.ad640x480,
.ad644,
.ad650x140,
.ad652,
.ad70,
.ad728,
.ad72890,
.ad728By90,
.ad728_90,
.ad728_blk,
.ad728_cont,
.ad728_wrap,
.ad728b,
.ad728cont,
.ad728h,
.ad728top,
.ad728x90,
.ad728x90-1,
.ad728x90-2,
.ad728x90box,
.ad728x90btf,
.ad970,
.ad970_250,
.adActive,
.adAlert,
.adArea,
.adAreaLC,
.adAreaNative,
.adAreaTopTitle,
.adArticleBanner,
.adArticleBody,
.adArticleSideTop300x250,
.adBan,
.adBanner300x250,
.adBanner728x90,
.adBillboard,
.adBkgd,
.adBlock,
.adBlock728,
.adBlockBottom,
.adBlockSpacer,
.adBlockSpot,
.adBorder,
.adBorders,
.adBox,
.adBox-small,
.adBox1,
.adBox2,
.adBox5,
.adBox6,
.adBox728,
.adBox728X90,
.adBox728X90_header,
.adBoxBody,
.adBoxBorder,
.adBoxContainer,
.adBoxContent,
.adBoxFooter,
.adBoxHeader,
.adBoxSidebar,
.adBoxSingle,
.adBoxTitle,
.adBox_1,
.adBox_3,
.adBtm,
.adCall,
.adCaptionText,
.adCell,
.adCenter,
.adCenterAd,
.adCentertile,
.adChoice,
.adChoiceLogo,
.adChoicesLogo,
.adChrome,
.adClose,
.adCode,
.adColumn,
.adColumnLeft,
.adColumnRight,
.adComponent,
.adCont,
.adContTop,
.adContainer1,
.adContainerSide,
.adContent,
.adContentAd,
.adContour,
.adCopy,
.adCreative,
.adCreator,
.adCube,
.adDefRect,
.adDetails_ad336,
.adDiv,
.adDrawer,
.adDyn,
.adElement,
.adExpanded,
.adFooterLinks,
.adFrame,
.adFrameCnt,
.adFrameContainer,
.adFrames,
.adFuel-label,
.adFull,
.adFullbanner,
.adGlobalHeader,
.adGoogle,
.adGroup,
.adHalfPage,
.adHead,
.adHeader,
.adHeaderAdbanner,
.adHeaderText,
.adHeaderblack,
.adHeading,
.adHeadline,
.adHeadlineSummary,
.adHed,
.adHeight200,
.adHeight270,
.adHeight280,
.adHeight313,
.adHeight600,
.adHolder,
.adHolder2,
.adHolderStory,
.adHoldert,
.adHome300x250,
.adHomeSideTop300x250,
.adHorisontal,
.adHorisontalNoBorder,
.adHorizontalTextAlt,
.adHplaceholder,
.adHz,
.adIDiv,
.adIframe,
.adIframeCount,
.adImg,
.adImgIM,
.adInArticle,
.adInContent,
.adInfo,
.adInitRemove,
.adInner,
.adInnerLeftBottom,
.adInsider,
.adInteractive,
.adIsland,
.adItem,
.adLabel,
.adLabelLine,
.adLabels,
.adLargeRec,
.adLargeRect,
.adLat,
.adLeader,
.adLeaderBoard_container,
.adLeaderForum,
.adLeaderboard,
.adLeaderboardAdContainer,
.adLeft,
.adLine,
.adLink,
.adLinkCnt,
.adListB,
.adLoader,
.adLocal,
.adLocation,
.adMPU,
.adMPUHome,
.adMRECHolder,
.adMarker,
.adMarkerBlock,
.adMastheadLeft,
.adMastheadRight,
.adMed,
.adMedRectBox,
.adMedRectBoxLeft,
.adMediaMiddle,
.adMediumRectangle,
.adMessage,
.adMiddle,
.adMinHeight280,
.adMinHeight313,
.adMiniTower,
.adMod,
.adModule,
.adModule--inner,
.adModule--outer,
.adModule-outer,
.adModule300,
.adModuleAd,
.adMpu,
.adMpuHolder,
.adMrginBottom,
.adNarrow,
.adNoBorder,
.adNoOutline,
.adNone,
.adNote,
.adNotice,
.adNotice90,
.adNoticeOut,
.adNotification,
.adObj,
.adOne,
.adOuterContainer,
.adOverlay,
.adPanel,
.adPanelContent,
.adPanorama,
.adPlaceholder,
.adPlacement,
.adPod,
.adPosition,
.adPremium,
.adRecommend,
.adRecommendRight,
.adRect,
.adRectangle,
.adRectangle-pos-large,
.adRectangle-pos-medium,
.adRectangle-pos-small,
.adRectangleBanner,
.adRectangleUnit,
.adRemove,
.adRenderer,
.adRendererInfinite,
.adResponsive,
.adResult,
.adResults,
.adRight,
.adRightSide,
.adRightSky,
.adRoller,
.adRotator,
.adRow,
.adRowTopWrapper,
.adSKY,
.adSection,
.adSenceImagePush,
.adSense,
.adSense-header,
.adSepDiv,
.adServer,
.adSeven,
.adSide,
.adSideBarMPU,
.adSideBarMPUTop,
.adSidebarButtons,
.adSizer,
.adSkin,
.adSky,
.adSkyscaper,
.adSkyscraper,
.adSlice,
.adSlide,
.adSlot,
.adSlot-container,
.adSlotAdition,
.adSlotCnt,
.adSlotContainer,
.adSlotHeaderContainer,
.adSlug,
.adSpBelow,
.adSpace,
.adSpace300x250,
.adSpace950x90,
.adSpacer,
.adSpec,
.adSplash,
.adSponsor,
.adSponsorText,
.adSponsorhipInfo,
.adSpot,
.adSpot-mrec,
.adSpot-textBox,
.adSpotBlock,
.adSpotFullWidth,
.adSpotIsland,
.adSquare,
.adStatementText,
.adStyle,
.adStyle1,
.adSub,
.adSubColPod,
.adSummary,
.adSuperboard,
.adSupertower,
.adTD,
.adTXTnew,
.adTab,
.adTag,
.adTag-top,
.adTag-wrap,
.adTagThree,
.adTagTwo,
.adText,
.adTextDownload,
.adTextPmpt,
.adTextStreaming,
.adTextWrap,
.adTicker,
.adTile,
.adTileWrap,
.adTiler,
.adTip,
.adTitle,
.adTitleR,
.adTop,
.adTopBk,
.adTopFloat,
.adTopHome,
.adTopLB,
.adTopLeft,
.adTopRight,
.adTopWrapper,
.adTopboxright,
.adTwo,
.adTxt,
.adType2,
.adUnderArticle,
.adUnit,
.adUnitHorz,
.adUnitVert,
.adVar,
.adVertical,
.adVideo,
.adVideo2,
.adVl,
.adVplaceholder,
.adWarning,
.adWebBoard,
.adWideSkyscraper,
.adWideSkyscraperRight,
.adWidget,
.adWidgetBlock,
.adWithTab,
.adWizard-ad,
.adWord,
.adWords-bg,
.adWrap,
.adWrapLg,
.adWrapper,
.adWrapper1,
.adZone,
.adZoneRight,
.ad_0,
.ad_1,
.ad_1000_125,
.ad_120x60,
.ad_120x600,
.ad_120x90,
.ad_125,
.ad_130x90,
.ad_150x150,
.ad_160,
.ad_160_600,
.ad_160x600,
.ad_188_inner,
.ad_1tdq7q5,
.ad_2,
.ad_200,
.ad_240,
.ad_250250,
.ad_250x200,
.ad_250x250,
.ad_290_290,
.ad_3,
.ad_300,
.ad_300250,
.ad_300_250,
.ad_300_250_1,
.ad_300_250_2,
.ad_300_250_wrapper,
.ad_300_600,
.ad_300by250,
.ad_300x100,
.ad_300x250,
.ad_300x250_container,
.ad_300x600,
.ad_320x250_async,
.ad_336,
.ad_336x280,
.ad_350x250,
.ad_4,
.ad_468,
.ad_468x60,
.ad_5,
.ad_600,
.ad_640,
.ad_640x480,
.ad_728,
.ad_72890,
.ad_728Home,
.ad_728_90,
.ad_728_90_1,
.ad_728_90b,
.ad_728_top,
.ad_728x90,
.ad_728x90-1,
.ad_728x90-2,
.ad_728x90_container,
.ad_728x90b,
.ad_90,
.ad_970x250,
.ad_970x250_300x250,
.ad_970x250_container,
.ad_Bumper,
.ad_Flex,
.ad_Left,
.ad_Right,
.ad__300x250,
.ad__300x600,
.ad__970x250,
.ad__align,
.ad__centered,
.ad__container,
.ad__content,
.ad__full--width,
.ad__header,
.ad__holder,
.ad__image,
.ad__in_article,
.ad__inline,
.ad__item,
.ad__label,
.ad__leaderboard,
.ad__mobi,
.ad__mobile-footer,
.ad__mpu,
.ad__placeholder,
.ad__rectangle,
.ad__section-border,
.ad__sidebar,
.ad__space,
.ad__sticky,
.ad__template,
.ad__window,
.ad__wrapper,
.ad_adv,
.ad_after_section,
.ad_amazon,
.ad_area,
.ad_area_two,
.ad_back,
.ad_background,
.ad_background_1,
.ad_background_true,
.ad_banner,
.ad_banner2,
.ad_banner_2,
.ad_banner_250x250,
.ad_banner_468,
.ad_banner_728,
.ad_banner_728x90_inner,
.ad_banner_border,
.ad_banner_div,
.ad_bar,
.ad_below_content,
.ad_belowfirstpost_frame,
.ad_bg,
.ad_bgskin,
.ad_big_banner,
.ad_bigbox,
.ad_billboard,
.ad_blk,
.ad_block,
.ad_block_1,
.ad_block_2,
.ad_block_widget,
.ad_body,
.ad_border,
.ad_botbanner,
.ad_bottom,
.ad_bottom_728,
.ad_bottom_leaderboard,
.ad_bottom_left,
.ad_bottom_mpu,
.ad_bottom_space,
.ad_box,
.ad_box1,
.ad_box2,
.ad_box_2,
.ad_box_6,
.ad_box_9,
.ad_box_ad,
.ad_box_div,
.ad_box_header,
.ad_box_spacer,
.ad_box_top,
.ad_break,
.ad_break2_container,
.ad_break_container,
.ad_btf,
.ad_btn,
.ad_btn-white,
.ad_btn1,
.ad_btn2,
.ad_by,
.ad_callout,
.ad_caption,
.ad_center,
.ad_center_bottom,
.ad_centered,
.ad_choice,
.ad_choices,
.ad_cl,
.ad_claim,
.ad_click,
.ad_cls_fix,
.ad_code,
.ad_col,
.ad_column,
.ad_column_box,
.ad_common,
.ad_con,
.ad_cont,
.ad_cont_footer,
.ad_contain,
.ad_container,
.ad_container_body,
.ad_container_bottom,
.ad_content,
.ad_content_below,
.ad_content_bottom,
.ad_content_wide,
.ad_content_wrapper,
.ad_contents,
.ad_crown,
.ad_custombanner,
.ad_d_big,
.ad_db,
.ad_default,
.ad_description,
.ad_desktop,
.ad_disclaimer,
.ad_div,
.ad_div_banner,
.ad_div_box,
.ad_div_box2,
.ad_element,
.ad_embed,
.ad_feature,
.ad_float,
.ad_floating_box,
.ad_fluid,
.ad_footer,
.ad_footer_super_banner,
.ad_frame,
.ad_frame_around,
.ad_fullwidth,
.ad_gam,
.ad_global_header,
.ad_google,
.ad_gpt,
.ad_grein_botn,
.ad_grid,
.ad_group,
.ad_half_page,
.ad_halfpage,
.ad_hd,
.ad_head,
.ad_head_rectangle,
.ad_header,
.ad_header_top,
.ad_heading,
.ad_headline,
.ad_holder,
.ad_horizontal,
.ad_hover_href,
.ad_iframe2,
.ad_image,
.ad_img,
.ad_imgae_150,
.ad_in_article,
.ad_in_text,
.ad_incontent,
.ad_index02,
.ad_indicator,
.ad_inline,
.ad_inline_wrapper,
.ad_inner,
.ad_inset,
.ad_island,
.ad_item,
.ad_label,
.ad_large,
.ad_lb,
.ad_leader,
.ad_leader_bottom,
.ad_leader_plus_top,
.ad_leaderboard,
.ad_leaderboard_atf,
.ad_leaderboard_master,
.ad_leaderboard_top,
.ad_leaderboard_wrap,
.ad_left,
.ad_left_cell,
.ad_left_column,
.ad_lft,
.ad_line2,
.ad_link,
.ad_links,
.ad_lnks,
.ad_loc,
.ad_long,
.ad_lrec,
.ad_lrgsky,
.ad_lt,
.ad_main,
.ad_maintopad,
.ad_margin,
.ad_marker,
.ad_masthead,
.ad_med,
.ad_medium_rectangle,
.ad_medrec,
.ad_medrect,
.ad_megabanner,
.ad_message,
.ad_mid_post_body,
.ad_middle,
.ad_middle_banner,
.ad_mobile,
.ad_mod,
.ad_module,
.ad_mp,
.ad_mpu,
.ad_mpu_top,
.ad_mr,
.ad_mrec,
.ad_native,
.ad_native_xrail,
.ad_news,
.ad_no_border,
.ad_note,
.ad_notice,
.ad_oms,
.ad_on_article,
.ad_one,
.ad_one_one,
.ad_one_third,
.ad_outer,
.ad_overlays,
.ad_p360,
.ad_pagebody,
.ad_panel,
.ad_paragraphs_desktop_container,
.ad_partner,
.ad_partners,
.ad_pause,
.ad_pic,
.ad_place,
.ad_placeholder,
.ad_placeholder_d_b,
.ad_placeholder_d_s,
.ad_placeholder_d_sticky,
.ad_placement,
.ad_plus,
.ad_position,
.ad_post,
.ad_primary,
.ad_promo,
.ad_promo1,
.ad_promo_spacer,
.ad_push,
.ad_r,
.ad_rec,
.ad_rect,
.ad_rectangle,
.ad_rectangle_300_250,
.ad_rectangle_medium,
.ad_rectangular,
.ad_regular1,
.ad_regular2,
.ad_regular3,
.ad_reminder,
.ad_response,
.ad_rhs,
.ad_right,
.ad_rightSky,
.ad_right_300_250,
.ad_right_cell,
.ad_right_col,
.ad_rightside,
.ad_row,
.ad_scroll,
.ad_secondary,
.ad_segment,
.ad_sense_01,
.ad_sense_footer_container,
.ad_share_box,
.ad_side,
.ad_side_box,
.ad_side_rectangle_banner,
.ad_sidebar,
.ad_sidebar_bigbox,
.ad_sidebar_inner,
.ad_sidebar_left,
.ad_sidebar_right,
.ad_size_160x600,
.ad_skin,
.ad_sky,
.ad_sky2,
.ad_sky2_2,
.ad_skyscpr,
.ad_skyscraper,
.ad_skyscrapper,
.ad_slider_out,
.ad_slot,
.ad_slot_inread,
.ad_slot_right,
.ad_slug,
.ad_small,
.ad_space,
.ad_space_300_250,
.ad_spacer,
.ad_sponsor,
.ad_sponsor_fp,
.ad_sponsoredsection,
.ad_spot,
.ad_spot_b,
.ad_spot_c,
.ad_spotlight,
.ad_square,
.ad_square_r,
.ad_square_r_top,
.ad_square_top,
.ad_start,
.ad_static,
.ad_station,
.ad_story_island,
.ad_stream,
.ad_stream_hd,
.ad_sub,
.ad_supersize,
.ad_table,
.ad_tag,
.ad_tag_middle,
.ad_text,
.ad_text_link,
.ad_text_links,
.ad_text_vertical,
.ad_text_w,
.ad_textlink1,
.ad_textlink_box,
.ad_thumbnail_header,
.ad_title,
.ad_title_small,
.ad_tlb,
.ad_to_list,
.ad_top,
.ad_top1,
.ad_top_1,
.ad_top_2,
.ad_top_3,
.ad_top_banner,
.ad_top_leaderboard,
.ad_top_left,
.ad_top_mpu,
.ad_top_right,
.ad_topic_content,
.ad_topmain,
.ad_topright,
.ad_topshop,
.ad_tower,
.ad_trailer_header,
.ad_trick_header,
.ad_trick_left,
.ad_ttl,
.ad_two,
.ad_two_third,
.ad_txt2,
.ad_type_1,
.ad_type_adsense,
.ad_type_dfp,
.ad_under,
.ad_under_royal_slider,
.ad_unit,
.ad_unit_300,
.ad_unit_300_x_250,
.ad_unit_600,
.ad_unit_rail,
.ad_unit_wrapper,
.ad_unit_wrapper_main,
.ad_url,
.ad_v2,
.ad_v3,
.ad_vertisement,
.ad_w,
.ad_w300h450,
.ad_w300i,
.ad_w_us_a300,
.ad_warn,
.ad_warning,
.ad_watch_now,
.ad_watermark,
.ad_wid300,
.ad_wide,
.ad_wide_vertical,
.ad_widget,
.ad_widget_200_100,
.ad_widget_200_200,
.ad_widget_image,
.ad_widget_title,
.ad_word,
.ad_wrap,
.ad_wrapper,
.ad_wrapper_300,
.ad_wrapper_970x90,
.ad_wrapper_box,
.ad_wrapper_false,
.ad_wrapper_fixed,
.ad_wrapper_top,
.ad_wrp,
.ad_xrail,
.ad_xrail_top,
.ad_zone,
.adace-adi-popup-wrapper,
.adace-slideup-slot-wrap,
.adace-slot,
.adace-slot-wrapper,
.adace-sponsors-box,
.adace-vignette,
.adalert-overlayer,
.adalert-toplayer,
.adamazon,
.adarea,
.adarea-long,
.adarticle,
.adb-top,
.adback,
.adban,
.adband,
.adbanner-300-250,
.adbanner-bottom,
.adbanner1,
.adbannerbox,
.adbannerright,
.adbannertop,
.adbase,
.adbbox,
.adbckgrnd,
.adbetween,
.adbetweenarticles,
.adbkgnd,
.adblade,
.adblade-container,
.adbladeimg,
.adblk,
.adblock-bottom,
.adblock-header,
.adblock-header1,
.adblock-main,
.adblock-popup,
.adblock-top,
.adblock-top-left,
.adblock-wide,
.adblock300,
.adblock300250,
.adblock728x90,
.adblock__banner,
.adblock_noborder,
.adblock_primary,
.adblockdiv,
.adblocks-topright,
.adboard,
.adborder,
.adborderbottom,
.adbordertop,
.adbot,
.adbot_postbit,
.adbot_showthread,
.adbottom,
.adbottomright,
.adbox-300x250,
.adbox-468x60,
.adbox-border-desk,
.adbox-box,
.adbox-header,
.adbox-outer,
.adbox-rectangle,
.adbox-sidebar,
.adbox-slider,
.adbox-style,
.adbox-title,
.adbox-topbanner,
.adbox-wrapper,
.adbox1,
.adbox160,
.adbox2,
.adbox300,
.adbox300x250,
.adbox336,
.adbox600,
.adbox728,
.adboxRightSide,
.adboxTopBanner,
.adboxVert,
.adbox_300x600,
.adbox_310x400,
.adbox_366x280,
.adbox_468X60,
.adbox_border,
.adbox_bottom,
.adbox_br,
.adbox_cont,
.adbox_largerect,
.adbox_left,
.adbox_top,
.adboxbg,
.adboxbot,
.adboxclass,
.adboxcm,
.adboxcontent,
.adboxcontentsum,
.adboxes,
.adboxesrow,
.adboxid,
.adboxlarge,
.adboxlong,
.adboxo,
.adboxtop,
.adbreak,
.adbrite2,
.adbtn,
.adbtns,
.adbttm_right_300,
.adbttm_right_label,
.adbucks,
.adbug,
.adbutler-inline-ad,
.adbutler-top-banner,
.adbutler_top_banner,
.adbutton,
.adbutton-block,
.adbuttons,
.adcard,
.adcasing,
.adcenter,
.adchange,
.adchoices,
.adchoices-link,
.adclass,
.adcode,
.adcode-widget,
.adcode2,
.adcode300x250,
.adcode728x90,
.adcode_container,
.adcodetextwrap300x250,
.adcodetop,
.adcol1,
.adcol2,
.adcolumn,
.adcolumn_wrapper,
.adcomment,
.adcon,
.adcont,
.adcontainer-Leaderboard,
.adcontainer-Rectangle,
.adcontainer2,
.adcontainer300x250l,
.adcontainer300x250r,
.adcontainer_footer,
.adcopy,
.add-box-side,
.add-box-top,
.add-sidebar,
.add300,
.add300top,
.add300x250,
.addAdvertContainer,
.add_topbanner,
.addarea,
.addarearight,
.addbanner,
.addboxRight,
.addisclaimer,
.addiv,
.adds2,
.adds300x250,
.adds620x90,
.addtitle,
.addvert,
.addwide,
.adengageadzone,
.adenquire,
.adex-ad-text,
.adfbox,
.adfeedback,
.adfeeds,
.adfix,
.adflag,
.adflexi,
.adfliction,
.adfoot,
.adfootbox,
.adfooter,
.adform__topbanner,
.adfoxly-overlay,
.adfoxly-place-delay,
.adfoxly-wrapper,
.adframe,
.adframe2,
.adframe_banner,
.adframe_rectangle,
.adfree,
.adfront,
.adfront-head,
.adfrp,
.adfull,
.adgear,
.adgmleaderboard,
.adguru-content-html,
.adguru-modal-popup,
.adhalfhome,
.adhalfpage,
.adhalfpageright,
.adhead,
.adheader,
.adheightpromo,
.adheighttall,
.adherebox,
.adhesion-block,
.adhesion-header,
.adhesion:not(body),
.adhesiveAdWrapper,
.adhesiveWrapper,
.adhesive_holder,
.adhi,
.adhide,
.adhint,
.adholder,
.adholder-300,
.adholder2,
.adholderban,
.adhoriz,
.adiframe,
.adindex,
.adindicator,
.adinfo,
.adinjwidget,
.adinner,
.adinpost,
.adinsert,
.adinsert160,
.adinside,
.adintext,
.adintro,
.adisclaimer,
.adisland,
.adits,
.adjlink,
.adk-slot,
.adkicker,
.adkit,
.adlabel-horz,
.adlabel-vert,
.adlabel1,
.adlabel2,
.adlabel3,
.adlabelleft,
.adlarge,
.adlarger,
.adlateral,
.adlayer,
.adleader,
.adleft1,
.adleftph,
.adlgbox,
.adline,
.adlink,
.adlinkdiv,
.adlinks,
.adlinks-class,
.adlist,
.adlist1,
.adlist2,
.adloaded,
.adlsot,
.admain,
.adman,
.admarker,
.admaster,
.admediumred,
.admedrec,
.admeldBoxAd,
.admessage,
.admiddle,
.admiddlesidebar,
.admngr,
.admngrfr,
.admngrft,
.admods,
.admodule,
.admoduleB,
.admpu,
.admpu-small,
.admputop,
.admz,
.adnSpot,
.adname,
.adnet_area,
.adnotecenter,
.adnotice,
.adnotification,
.adnz-ad-placeholder,
.adocean,
.adocean728x90,
.adocean_desktop_section,
.adops,
.adpacks,
.adpacks_content,
.adpadding,
.adpane,
.adparent,
.adpic,
.adplace,
.adplace_center,
.adplaceholder,
.adplaceholder-top,
.adplacement,
.adplate-background,
.adplugg-tag,
.adpod,
.adpopup,
.adpos-300-mobile,
.adpost,
.adposter_pos,
.adproxy,
.adrec,
.adrechts,
.adrect,
.adrectangle,
.adrectwrapper,
.adrevtising-buttom,
.adright,
.adright300,
.adrightlg,
.adrightsm,
.adrighttop,
.adriverBanner,
.adroot,
.adrotate-sponsor,
.adrotate-widget,
.adrotate_ads_row,
.adrotate_top_banner,
.adrotate_widget,
.adrotate_widgets,
.adrotatediv,
.adrow,
.adrule,
.ads--bottom-spacing,
.ads--desktop,
.ads--full,
.ads--no-preload,
.ads--sidebar,
.ads--single,
.ads--square,
.ads--super,
.ads--top,
.ads-1,
.ads-120x600,
.ads-125,
.ads-160x600,
.ads-160x600-outer,
.ads-2,
.ads-3,
.ads-300,
.ads-300-250,
.ads-300-box,
.ads-300x250,
.ads-300x250-sidebar,
.ads-300x300,
.ads-300x600,
.ads-300x600-wrapper-en,
.ads-320-50,
.ads-320x250,
.ads-336x280,
.ads-468,
.ads-728,
.ads-728-90,
.ads-728by90,
.ads-728x90,
.ads-980x90,
.ads-above-comments,
.ads-ad,
.ads-advertorial,
.ads-article-right,
.ads-articlebottom,
.ads-aside,
.ads-banner,
.ads-banner-bottom,
.ads-banner-js,
.ads-banner-middle,
.ads-banner-spacing,
.ads-banner-top,
.ads-banner-top-right,
.ads-base,
.ads-beforecontent,
.ads-below-content,
.ads-below-home,
.ads-below-view-content,
.ads-between-comments,
.ads-bg,
.ads-bigbox,
.ads-bilboards,
.ads-bing-bottom,
.ads-bing-top,
.ads-block,
.ads-block-bottom-wrap,
.ads-block-link-text,
.ads-block-panel-tipo-1,
.ads-block-rightside,
.ads-block-top,
.ads-block-top-right,
.ads-border,
.ads-bottom,
.ads-bottom-block,
.ads-bottom-center,
.ads-bottom-content,
.ads-bottom-left,
.ads-bottom-right,
.ads-box,
.ads-box-border,
.ads-box-cont,
.ads-bt,
.ads-btm,
.ads-by,
.ads-by-google,
.ads-callback,
.ads-card,
.ads-carousel,
.ads-center,
.ads-centered,
.ads-cnt,
.ads-code,
.ads-col,
.ads-cols,
.ads-cont,
.ads-content,
.ads-core-placer,
.ads-custom,
.ads-decorator,
.ads-desktop,
.ads-div,
.ads-el,
.ads-end-content,
.ads-favicon,
.ads-feed,
.ads-fieldset,
.ads-footer,
.ads-fr,
.ads-global-header,
.ads-global-top,
.ads-google,
.ads-google-bottom,
.ads-google-top,
.ads-grp,
.ads-half,
.ads-header,
.ads-header-desktop,
.ads-header-left,
.ads-header-right,
.ads-here,
.ads-hints,
.ads-holder,
.ads-home,
.ads-homepage-2,
.ads-horizontal,
.ads-horizontal-banner,
.ads-image,
.ads-inarticle,
.ads-inline,
.ads-inner,
.ads-instance,
.ads-internal,
.ads-item,
.ads-label,
.ads-label-inverse,
.ads-large,
.ads-leaderboard,
.ads-leaderboard-border,
.ads-leaderboard-panel,
.ads-leaderbord,
.ads-left,
.ads-line,
.ads-list,
.ads-loaded,
.ads-long,
.ads-main,
.ads-margin,
.ads-marker,
.ads-medium-rect,
.ads-middle,
.ads-middle-top,
.ads-minheight,
.ads-mini,
.ads-mini-3rows,
.ads-mobile,
.ads-module,
.ads-module-alignment,
.ads-movie,
.ads-mpu,
.ads-narrow,
.ads-native-wrapper,
.ads-note,
.ads-one,
.ads-outer,
.ads-panel,
.ads-parent,
.ads-pholder,
.ads-placeholder,
.ads-placeholder-inside,
.ads-placeholder-wrapper,
.ads-placment,
.ads-post,
.ads-post-closing,
.ads-post-footer,
.ads-post-full,
.ads-posting,
.ads-profile,
.ads-rail,
.ads-rect,
.ads-rectangle,
.ads-relatedbottom,
.ads-rendering-fix,
.ads-right,
.ads-right-min,
.ads-rotate,
.ads-row,
.ads-scroller-box,
.ads-section,
.ads-side,
.ads-sidebar,
.ads-sidebar-boxad,
.ads-sidebar-widget,
.ads-sign,
.ads-single,
.ads-site,
.ads-size-small,
.ads-skin,
.ads-skin-mobile,
.ads-sky,
.ads-skyscraper,
.ads-skyscraper-container-left,
.ads-skyscraper-container-right,
.ads-skyscraper-left,
.ads-skyscraper-right,
.ads-small,
.ads-small-horizontal,
.ads-small-squares,
.ads-smartphone,
.ads-social-box,
.ads-sponsored-title,
.ads-sponsors,
.ads-square,
.ads-square-large,
.ads-square-small,
.ads-squares,
.ads-star,
.ads-stick-footer,
.ads-sticky,
.ads-story,
.ads-story-leaderboard-atf,
.ads-stripe,
.ads-styled,
.ads-superbanner,
.ads-system,
.ads-text,
.ads-title,
.ads-to-hide,
.ads-top,
.ads-top-728,
.ads-top-center,
.ads-top-content,
.ads-top-fixed,
.ads-top-home,
.ads-top-left,
.ads-top-main,
.ads-top-right,
.ads-top-spacer,
.ads-topbar,
.ads-two,
.ads-txt,
.ads-ul,
.ads-verticle,
.ads-wall-container,
.ads-wide,
.ads-widget,
.ads-widget-content,
.ads-widget-content-wrap,
.ads-widget-link {
display: none !important;
}


#dnn_ad_banner,
#dnn_ad_island1,
#dnn_ad_skyscraper,
#dnn_sponsoredLinks,
#downloadAd,
#download_ad,
#download_ads,
#dragads,
#ds-mpu,
#dsStoryAd,
#dsk-banner-ad-a,
#dsk-banner-ad-b,
#dsk-banner-ad-c,
#dsk-banner-ad-d,
#dsk-box-ad-c,
#dsk-box-ad-d,
#dsk-box-ad-f,
#dsk-box-ad-g,
#dv-gpt-ad-bigbox-wrap,
#dynamicAdDiv,
#em_ad_superbanner,
#embedAD,
#embedADS,
#event_ads,
#events-adv-side1,
#events-adv-side2,
#events-adv-side3,
#events-adv-side4,
#events-adv-side5,
#events-adv-side6,
#exoAd,
#externalAd,
#ezmob_footer,
#ezmobfooter,
#featureAd,
#featureAdSpace,
#featureAds,
#feature_ad,
#featuread,
#featured-ads,
#featuredAds,
#first-ads,
#first_ad,
#firstad,
#fixed-ad,
#fixedAd,
#fixedban,
#floatAd,
#floatads,
#floating-ad-wrapper,
#floating-ads,
#floating-advert,
#floatingAd,
#floatingAdContainer,
#floatingAds,
#floating_ad,
#floating_ad_container,
#floating_ads_bottom_textcss_container,
#floorAdWrapper,
#foot-ad-wrap,
#foot-ad2-wrap,
#footAd,
#footAdArea,
#footAds,
#footad,
#footer-ad,
#footer-ad-728,
#footer-ad-block,
#footer-ad-box,
#footer-ad-col,
#footer-ad-google,
#footer-ad-large,
#footer-ad-slot,
#footer-ad-unit,
#footer-ad-wrapper,
#footer-ads,
#footer-adspace,
#footer-adv,
#footer-advert,
#footer-advert-area,
#footer-advertisement,
#footer-adverts,
#footer-adwrapper,
#footer-affl,
#footer-banner-ad,
#footer-leaderboard-ad,
#footer-sponsored,
#footer-sponsors,
#footerAd,
#footerAdBottom,
#footerAdBox,
#footerAdDiv,
#footerAdWrap,
#footerAdd,
#footerAds,
#footerAdsPlacement,
#footerAdvert,
#footerAdvertisement,
#footerAdverts,
#footerGoogleAd,
#footer_AdArea,
#footer_ad,
#footer_ad_block,
#footer_ad_container,
#footer_ad_frame,
#footer_ad_holder,
#footer_ad_modules,
#footer_adcode,
#footer_add,
#footer_addvertise,
#footer_ads,
#footer_ads_holder,
#footer_adspace,
#footer_adv,
#footer_advertising,
#footer_leaderboard_ad,
#footer_text_ad,
#footerad,
#footerad728,
#footerads,
#footeradsbox,
#footeradvert,
#forum-top-ad-bar,
#frameAd,
#frmSponsAds,
#front-ad-cont,
#front-page-ad,
#front-page-advert,
#frontPageAd,
#front_advert,
#front_mpu,
#ft-ad,
#ft-ads,
#full_banner_ad,
#fwAdBox,
#fwdevpDiv0,
#fwdevpDiv1,
#fwdevpDiv2,
#g-outbrain,
#gAds,
#gStickyAd,
#g_ad,
#g_adsense,
#gad300x250,
#gad728x90,
#gads300x250,
#gadsOverlayUnit,
#gads_middle,
#gallery-ad,
#gallery-ad-container,
#gallery-advert,
#gallery-below-line-advert,
#gallery-sidebar-advert,
#gallery_ad,
#gallery_ads,
#gallery_header_ad,
#galleryad1,
#gam-ad-ban1,
#game-ad,
#gamead,
#gameads,
#gasense,
#geoAd,
#gg_ad,
#ggl-ad,
#glamads,
#global-banner-ad,
#globalLeftNavAd,
#globalTopNavAd,
#global_header_ad,
#global_header_ad_area,
#gnt_atomsnc,
#goad1,
#goads,
#gooadtop,
#google-ad,
#google-ads,
#google-ads-bottom,
#google-ads-bottom-container,
#google-ads-container,
#google-ads-detailsRight,
#google-ads-directoryViewRight,
#google-ads-header,
#google-adsense,
#google-adwords,
#google-afc,
#google-dfp-bottom,
#google-dfp-top,
#google-post-ad,
#google-post-adbottom,
#google-top-ads,
#googleAd,
#googleAdArea,
#googleAdBottom,
#googleAdBox,
#googleAdTop,
#googleAds,
#googleAdsense,
#googleAdsenseAdverts,
#googleSearchAds,
#google_ad_1,
#google_ad_2,
#google_ad_3,
#google_ad_container,
#google_ad_slot,
#google_ads,
#google_ads_1,
#google_ads_box,
#google_ads_frame,
#google_ads_frame1_anchor,
#google_ads_frame2_anchor,
#google_ads_frame3_anchor,
#google_ads_frame4_anchor,
#google_ads_frame5_anchor,
#google_ads_frame6_anchor,
#google_adsense,
#google_adsense_ad,
#googlead,
#googlead2,
#googleadleft,
#googleads,
#googleads1,
#googleadsense,
#googleadstop,
#googlebanner,
#googlesponsor,
#googletextads,
#gpt-ad-1,
#gpt-ad-banner,
#gpt-ad-halfpage,
#gpt-ad-outofpage-wp,
#gpt-ad-rectangle1,
#gpt-ad-rectangle2,
#gpt-ad-side-bottom,
#gpt-ad-skyscraper,
#gpt-dynamic_native_article_4,
#gpt-high_impact,
#gpt-instory-ad,
#gpt-leaderboard-ad,
#gpt-mpu,
#gpt-poster,
#gpt-sticky,
#grdAds,
#gridAdSidebar,
#grid_ad,
#half-page-ad,
#halfPageAd,
#half_page_ad_300x600,
#halfpagead,
#head-ad,
#head-ad-text-wrap,
#head-ad-timer,
#head-ads,
#head-advertisement,
#headAd,
#headAds,
#headAdv,
#head_ad,
#head_ads,
#head_advert,
#headad,
#headadvert,
#header-ad,
#header-ad-background,
#header-ad-block,
#header-ad-bottom,
#header-ad-container,
#header-ad-holder,
#header-ad-label,
#header-ad-left,
#header-ad-placeholder,
#header-ad-right,
#header-ad-slot,
#header-ad-wrap,
#header-ad-wrapper,
#header-ad2,
#header-ads,
#header-ads-container,
#header-ads-holder,
#header-ads-wrapper,
#header-adsense,
#header-adserve,
#header-adspace,
#header-adv,
#header-advert,
#header-advert-panel,
#header-advertisement,
#header-advertising,
#header-adverts,
#header-advrt,
#header-banner-728-90,
#header-banner-ad,
#header-banner-ad-wrapper,
#header-block-ads,
#header-box-ads,
#headerAd,
#headerAdBackground,
#headerAdContainer,
#headerAdSpace,
#headerAdUnit,
#headerAdWrap,
#headerAds,
#headerAdsWrapper,
#headerAdv,
#headerAdvert,
#headerTopAd,
#header_ad,
#header_ad_728,
#header_ad_728_90,
#header_ad_banner,
#header_ad_block,
#header_ad_container,
#header_ad_leaderboard,
#header_ad_units,
#header_ad_widget,
#header_ad_wrap,
#header_adbox,
#header_adcode,
#header_ads,
#header_ads2,
#header_adsense,
#header_adv,
#header_advert,
#header_advertisement,
#header_advertisement_top,
#header_advertising,
#header_adverts,
#header_bottom_ad,
#header_publicidad,
#header_right_ad,
#header_sponsors,
#header_top_ad,
#headerad,
#headerad_large,
#headeradbox,
#headeradcontainer,
#headerads,
#headeradsbox,
#headeradsense,
#headeradspace,
#headeradvertholder,
#headeradwrap,
#headergooglead,
#headersponsors,
#headingAd,
#headline_ad,
#hearst-autos-ad-wrapper,
#hgiks-middle,
#hgiks-top,
#home-ad,
#home-ad-block,
#home-ad-slot,
#home-advert-module,
#home-advertise,
#home-banner-ad,
#home-left-ad,
#home-rectangle-ad,
#home-side-ad,
#home-top-ads,
#homeAd,
#homeAdLeft,
#homeAds,
#homeSideAd,
#home_ad,
#home_ads_vert,
#home_advertising_block,
#home_bottom_ad,
#home_contentad,
#home_mpu,
#home_sidebar_ad,
#home_top_right_ad,
#homead,
#homeheaderad,
#homepage-ad,
#homepage-adbar,
#homepage-footer-ad,
#homepage-header-ad,
#homepage-sidebar-ad,
#homepage-sidebar-ads,
#homepage-sponsored,
#homepageAd,
#homepageAdsTop,
#homepageFooterAd,
#homepageGoogleAds,
#homepage_ad,
#homepage_ad_listing,
#homepage_rectangle_ad,
#homepage_right_ad,
#homepage_right_ad_container,
#homepage_top_ad,
#homepage_top_ads,
#homepageadvert,
#hometopads,
#horAd,
#hor_ad,
#horadslot,
#horizad,
#horizads728,
#horizontal-ad,
#horizontal-adspace,
#horizontal-banner-ad,
#horizontalAd,
#horizontalAdvertisement,
#horizontal_ad,
#horizontal_ad2,
#horizontal_ad_top,
#horizontalad,
#horizontalads,
#hottopics-advert,
#hours_ad,
#houseAd,
#hovered_sponsored,
#hp-desk-after-header-ad,
#hp-header-ad,
#hp-right-ad,
#hp-store-ad,
#hpAdVideo,
#humix-vid-ezAutoMatch,
#idDivAd,
#id_SearchAds,
#iframe-ad,
#iframeAd_2,
#iframe_ad_2,
#imPopup,
#im_popupDiv,
#ima_ads-2,
#ima_ads-3,
#ima_ads-4,
#imgAddDirectLink,
#imgad1,
#imu_ad_module,
#in-article-ad,
#in-article-mpu,
#in-content-ad,
#inArticleAdv,
#inarticlead,
#inc-ads-bigbox,
#incontent-ad-2,
#incontent-ad-3,
#incontentAd1,
#incontentAd2,
#incontentAd3,
#index-ad,
#index-bottom-advert,
#indexSquareAd,
#index_ad,
#indexad,
#indexad300x250l,
#indexsmallads,
#indiv_adsense,
#infoBottomAd,
#infoboxadwrapper,
#inhousead,
#initializeAd,
#inline-ad,
#inline-ad-label,
#inline-advert,
#inline-story-ad,
#inline-story-ad2,
#inlineAd,
#inlineAdCont,
#inlineAdtop,
#inlineAdvertisement,
#inlineBottomAd,
#inline_ad,
#inline_ad_section,
#inlinead,
#inlineads,
#inner-ad,
#inner-ad-container,
#inner-advert-row,
#inner-top-ads,
#innerad,
#innerpage-ad,
#inside-page-ad,
#insideCubeAd,
#instant_ad,
#insticator-container,
#instoryad,
#int-ad,
#int_ad,
#interads,
#intermediate-ad,
#internalAdvert,
#internalads,
#interstitial-shade,
#interstitialAd,
#interstitialAdContainer,
#interstitialAdUnit,
#interstitial_ad,
#interstitial_ad_container,
#interstitial_ads,
#intext_ad,
#introAds,
#intro_ad_1,
#invid_ad,
#ipadv,
#iq-AdSkin,
#iqadcontainer,
#iqadoverlay,
#iqadtile1,
#iqadtile11,
#iqadtile14,
#iqadtile15,
#iqadtile16,
#iqadtile2,
#iqadtile3,
#iqadtile4,
#iqadtile41,
#iqadtile6,
#iqadtile8,
#iqadtile9,
#iqadtile99,
#islandAd,
#islandAdPan,
#islandAdPane,
#islandAdPane2,
#island_ad_top,
#islandad,
#jobs-ad,
#js-Taboola-Container-0,
#js-ad-billboard,
#js-ad-leaderboard,
#js-image-ad-mpu,
#js-outbrain-ads-module,
#js-outbrain-module,
#js-outbrain-relateds,
#js-page-ad-top,
#js-wide-ad,
#js_commerceInsetModule,
#jsid-ad-container-post_above_comment,
#jsid-ad-container-post_below_comment,
#jwplayer-container-div,
#jwplayer_contextual_player_div,
#kargo-player,
#kt_player>a[target="_blank"],
#kt_player>div[style$="display: block;"][style*="inset: 0px;"],
#large-ads,
#large-bottom-leaderboard-ad,
#large-leaderboard-ad,
#large-middle-leaderboard-ad,
#large-rectange-ad,
#large-rectange-ad-2,
#large-skyscraper-ad,
#largeAd,
#largeAds,
#large_rec_ad1,
#largead,
#layer_ad,
#layer_ad_content,
#layerad,
#layeradsense,
#layout-header-ad-wrapper,
#layout_topad,
#lb-ad,
#lb-sponsor-left,
#lb-sponsor-right,
#lbAdBar,
#lbAdBarBtm,
#lblAds,
#lead-ads,
#lead_ad,
#leadad_1,
#leadad_2,
#leader-ad,
#leader-board-ad,
#leader-companion>a[href],
#leaderAd,
#leaderAdContainer,
#leaderAdContainerOuter,
#leaderBoardAd,
#leader_ad,
#leader_board_ad,
#leaderad,
#leaderad_section,
#leaderadvert,
#leaderboard-ad,
#leaderboard-advert,
#leaderboard-advertisement,
#leaderboard-atf,
#leaderboard-bottom-ad,
#leaderboard.ad,
#leaderboardAd,
#leaderboardAdTop,
#leaderboardAds,
#leaderboardAdvert,
#leaderboard_728x90,
#leaderboard_Ad,
#leaderboard_ad,
#leaderboard_ads,
#leaderboard_bottom_ad,
#leaderboard_top_ad,
#leaderboardad,
#leatherboardad,
#left-ad,
#left-ad-1,
#left-ad-2,
#left-ad-col,
#left-ad-iframe,
#left-ad-skin,
#left-bottom-ad,
#left-col-ads-1,
#left-content-ad,
#leftAD,
#leftAdAboveSideBar,
#leftAdCol,
#leftAdContainer,
#leftAdMessage,
#leftAdSpace,
#leftAd_fmt,
#leftAd_rdr,
#leftAds,
#leftAdsSmall,
#leftAdvert,
#leftBanner-ad,
#leftColumnAdContainer,
#leftGoogleAds,
#leftTopAdWrapper,
#left_ad,
#left_ads,
#left_adsense,
#left_adspace,
#left_adv,
#left_advertisement,
#left_bg_ad,
#left_block_ads,
#left_float_ad,
#left_global_adspace,
#left_side_ads,
#left_sidebar_ads,
#left_top_ad,
#leftad,
#leftadg,
#leftads,
#leftcolAd,
#leftcolumnad,
#leftforumad,
#leftrail_dynamic_ad_wrapper,
#lg-banner-ad,
#ligatus,
#ligatus_adv,
#ligatusdiv,
#lightboxAd,
#linkAdSingle,
#linkAds,
#link_ads,
#linkads,
#listadholder,
#liste_top_ads_wrapper,
#listing-ad,
#live-ad,
#localAds,
#localpp,
#locked-footer-ad-wrapper,
#logoAd,
#logoAd2,
#logo_ad,
#long-ad,
#long-ad-space,
#long-bottom-ad-wrapper,
#longAdSpace,
#longAdWrap,
#long_advert_header,
#long_advertisement,
#lower-ad-banner,
#lower-ads,
#lower-advertising,
#lower-home-ads,
#lowerAdvertisement,
#lowerAdvertisementImg,
#lower_ad,
#lower_content_ad_box,
#lowerads,
#lowerthirdad,
#lrec_ad,
#lrecad,
#m-banner-bannerAd,
#main-ad,
#main-advert,
#mainAd,
#mainAd1,
#mainAdUnit,
#mainAdvert,
#mainPageAds,
#mainPlaceHolder_coreContentPlaceHolder_rightColumnAdvert_divControl,
#main_AD,
#main_ad,
#main_ads,
#main_content_ad,
#main_rec_ad,
#main_top_ad,
#mainui-ads,
#mapAdsSwiper,
#mapAdvert,
#marketplaceAds,
#marquee-ad,
#marquee_ad,
#mastAd,
#mastAdvert,
#mastad,
#masterad,
#masthead_ad,
#masthead_ads_container,
#masthead_topad,
#med-rect-ad,
#med-rectangle-ad,
#medRecAd,
#medReqAd,
#media-ad,
#medium-ad,
#mediumAd1,
#mediumAdContainer,
#mediumAdvertisement,
#mediumRectangleAd,
#medrec_bottom_ad,
#medrec_middle_ad,
#medrec_top_ad,
#medrectad,
#medrectangle_banner,
#menuad,
#menubarad,
#mgb-container>#mgb,
#mgid-container,
#mgid_iframe,
#mid-ad-slot-1,
#mid-ad-slot-3,
#mid-ad-slot-5,
#mid-ads,
#mid-table-ad,
#midAD,
#midRightAds,
#midRightTextAds,
#mid_ad,
#mid_ad_div,
#mid_ad_title,
#mid_left_ads,
#mid_mpu,
#mid_roll_ad_holder,
#midadspace,
#midadvert,
#midbarad,
#midbnrad,
#midcolumn_ad,
#middle-ad,
#middle-ad-destin,
#middleAd,
#middle_ad,
#middle_ads,
#middle_mpu,
#middlead,
#middleads,
#middleads2,
#midpost_ad,
#midrect_ad,
#midstrip_ad,
#mini-ad,
#mm-player-placeholder-large-screen,
#mobile-adhesion,
#mobile-ads-ad,
#mobile-footer-ad-wrapper,
#mobile-swipe-banner,
#mobileAdContainer,
#mobile_ads_100_pc,
#mobile_ads_block,
#mod_ad,
#mod_ad_top,
#modal-ad,
#module-ads-01,
#module-ads-02,
#moduleTaboolaRightRail,
#module_ad,
#module_box_ad,
#monsterAd,
#mplayer-embed,
#mpu-ad,
#mpu-advert,
#mpu-cont,
#mpu-content,
#mpu-sidebar,
#mpu1_parent,
#mpu2,
#mpu2_container,
#mpu2_parent,
#mpuAd,
#mpuAdvert,
#mpuContainer,
#mpuDiv,
#mpuInContent,
#mpuSecondary,
#mpuSlot,
#mpuWrapper,
#mpuWrapperAd,
#mpuWrapperAd2,
#mpu_ad,
#mpu_ad2,
#mpu_adv,
#mpu_banner,
#mpu_box,
#mpu_container,
#mpu_div,
#mpu_holder,
#mpu_text_ad,
#mpu_top,
#mpuad,
#mpubox,
#mpuholder,
#mvp-foot-ad-wrap,
#mvp-post-bot-ad,
#my-ads,
#narrow-ad,
#narrow_ad_unit,
#native-ads-placeholder,
#native_ad2,
#native_ads,
#nav-ad-container,
#navAdBanner,
#nav_ad,
#nav_ad_728_mid,
#navads-container,
#navbar_ads,
#navigation-ad,
#navlinkad,
#newAd,
#ng-ad,
#ng-ad-lbl,
#ni-ad-row,
#nk_ad_top,
#notify_ad,
#ntvads,
#openx-text-ad,
#openx-widget,
#original_taboola,
#outbrain,
#outbrain-id,
#outbrain-section,
#outbrain-wrapper,
#outbrain1,
#outbrainAdWrapper,
#outbrainWidget,
#outbrain_widget_0,
#ovadsense,
#overlay-ad-bg,
#overlay_ad,
#overlayad,
#overlayadd,
#p-Ad,
#p-advert,
#p-googlead,
#p-googleadsense,
#p2HeaderAd,
#p2squaread,
#page-ad-top,
#page-advertising,
#page-header-ad,
#page-top-ad,
#pageAdDiv,
#pageAdds,
#pageAds,
#pageAdsDiv,
#pageAdvert,
#pageBannerAd,
#pageLeftAd,
#pageMiddleAdWrapper,
#pageRightAd,
#page__outside-advertsing,
#page_ad,
#page_ad_top,
#page_top_ad,
#pageads_top,
#pagebottomAd,
#pagination-advert,
#panel-ad,
#panelAd,
#panel_ad1,
#panoAdBlock,
#partner-ad,
#partnerAd,
#partnerMedRec,
#partner_ads,
#pause-ad,
#pause-ads,
#pauseAd,
#pc-div-gpt-ad_728-3,
#pencil-ad,
#pencil-ad-container,
#perm_ad,
#permads,
#persistentAd,
#personalization_ads,
#pgAdWrapper,
#ph_ad,
#player-ads,
#player-advert,
#player-advertising,
#player-below-advert,
#player-midrollAd,
#playerAd,
#playerAdsRight,
#player_ad,
#player_ads,
#player_middle_ad,
#player_top_ad,
#playerad,
#playerads,
#pop.div_pop,
#pop_ad,
#popadwrap,
#popback-ad,
#popoverAd,
#popupAd,
#popupBottomAd,
#popup_ad_wrapper,
#popupadunit,
#possible_taboola,
#post-ad,
#post-ads,
#post-bottom-ads,
#post-content-ad,
#post-page-ad,
#post-promo-ad,
#postAd,
#postNavigationAd,
#post_ad,
#post_addsense,
#post_adsense,
#post_adspace,
#post_advert,
#postads0,
#ppcAdverts,
#ppvideoadvertisement,
#pr_ad,
#pr_advertising,
#pre-adv,
#pre-footer-ad,
#preAds_ad_mrec_intext,
#preAds_ad_mrec_intext2,
#preminumAD,
#premiumAdTop,
#premium_ad,
#premium_ddb_0,
#premiumad,
#premiumads,
#prerollAd,
#preroll_ads,
#primis-container,
#primis-holder,
#primis_intext,
#primis_player,
#print_ads,
#printads,
#privateads,
#promo-ad,
#promoAds,
#promoFloatAd,
#promo_ads,
#pub468x60,
#pub728x90,
#pubexchange_below_content,
#publicidad,
#publicidadeLREC,
#pushAd,
#pushDownAd,
#pushdownAd,
#pushdownAdWrapper,
#pushdown_ad,
#pusher-ad,
#pvadscontainer,
#quads-ad1_widget,
#quads-ad2_widget,
#quads-admin-ads-js,
#r89-desktop-top-ad,
#radio-ad-container,
#rail-ad-wrap,
#rail-bottom-ad,
#railAd,
#rail_ad,
#rail_ad1,
#rail_ad2,
#rec_spot_ad_1,
#recommendAdBox,
#rect-ad,
#rectAd,
#rect_ad,
#rectad,
#rectangle-ad,
#rectangleAd,
#rectangleAdTeaser1,
#rectangle_ad,
#redirect-ad,
#redirect-ad-modal,
#reference-ad,
#region-node-advert,
#reklam_buton,
#reklam_center,
#reklama,
#reklama_big,
#reklama_left_body,
#reklama_left_up,
#reklama_right_up,
#related-ads,
#related-news-1-bottom-ad,
#related-news-1-top-ad,
#related_ad,
#related_ads,
#related_ads_box,
#removeAdsSidebar,
#removeadlink,
#responsive-ad,
#responsive-ad-sidebar-container,
#responsive_ad,
#responsivead,
#result-list-aside-topadsense,
#resultSponLinks,
#resultsAdsBottom,
#resultsAdsSB,
#resultsAdsTop,
#rh-ad,
#rh-ad-container,
#rh_tower_ad,
#rhc_ads,
#rhs_ads,
#rhs_adverts,
#rhsads,
#rhsadvert,
#richad,
#right-ad,
#right-ad-block,
#right-ad-col,
#right-ad-iframe,
#right-ad-skin,
#right-ad1,
#right-ads,
#right-ads-rail,
#right-advert,
#right-bar-ad,
#right-box-ad,
#right-content-ad,
#right-featured-ad,
#right-rail-ad-slot-content-top,
#right-widget-b-ads_widget-9,
#right-widget-c-ads_widget-7,
#right-widget-d-ads_widget-36,
#right-widget-top-ads_widget-23,
#right1-ad,
#right1ad,
#rightAD,
#rightAd,
#rightAd1,
#rightAdBar,
#rightAdBlock,
#rightAdColumn,
#rightAdContainer,
#rightAdHolder,
#rightAdUnit,
#rightAd_rdr,
#rightAds,
#rightAdsDiv,
#rightBlockAd,
#rightBottomAd,
#rightColAd,
#rightColumnAds,
#rightRailAds,
#rightSideAd,
#rightSideAdvert,
#right_Ads2,
#right_ad,
#right_ad_1,
#right_ad_2,
#right_ad_box,
#right_ad_container,
#right_ad_top,
#right_ad_wrapper,
#right_ads,
#right_ads_box,
#right_adsense,
#right_advert,
#right_advertisement,
#right_advertising,
#right_adverts,
#right_bg_ad,
#right_block_ads,
#right_bottom_ad,
#right_column_ad,
#right_column_ad_container,
#right_column_ads,
#right_column_adverts,
#right_player_ad,
#right_side_ad,
#right_sidebar_ads,
#right_top_ad,
#right_top_gad,
#rightad,
#rightad1,
#rightad2,
#rightadBorder,
#rightadBorder1,
#rightadBorder2,
#rightadContainer,
#rightadcell,
#rightadg,
#rightadhome,
#rightads,
#rightads300x250,
#rightadsarea,
#rightbar-ad,
#rightbar_ad,
#rightcol_sponsorad,
#rightgoogleads,
#rightrail-ad,
#rightrail_bottom_ddb_0,
#rightrail_pos1_ddb_0,
#rightrail_pos2_ddb_0,
#rightrail_pos3_ddb_0,
#rightrail_top_ddb_0,
#rightside-ads,
#rightside_ad,
#rightskyad,
#rm-adslot-bigsizebanner_1,
#rm-adslot-contentad_1,
#rotating_ad,
#rotatingads,
#row-ad,
#rowAdv,
#rtAdvertisement,
#scroll-ad,
#scroll_ad,
#search-ad,
#search-ads1,
#search-google-ads,
#search-sponsor,
#search-sponsored-links,
#searchAd,
#searchAds,
#search_ad,
#search_ads,
#second_ad_div,
#secondad,
#section-ad,
#section-ad-bottom,
#section_ad,
#section_advertisements,
#self-ad,
#sellwild-loader,
#sev1mposterad,
#show-ad,
#show-sticky-ad,
#showAd,
#show_ads,
#showads,
#showcaseAd,
#side-ad,
#side-ad-container,
#side-ads,
#side-ads-box,
#side-banner-ad,
#side-boxad,
#sideABlock,
#sideAD,
#sideAd,
#sideAd1,
#sideAd2,
#sideAd3,
#sideAd4,
#sideAdArea,
#sideAdLarge,
#sideAdSmall,
#sideAdSub,
#sideAds,
#sideBannerAd,
#sideBar-ads,
#sideBarAd,
#sideSponsors,
#side_ad,
#side_ad_module,
#side_ad_wrapper,
#side_ads,
#side_adverts,
#side_longads,
#side_skyscraper_ad,
#side_sponsors,
#sidead,
#sidead1,
#sideads,
#sideads_container,
#sideadscol,
#sideadvert,
#sideadzone,
#sidebar-ad,
#sidebar-ad-1,
#sidebar-ad-2,
#sidebar-ad-block,
#sidebar-ad-boxes,
#sidebar-ad-middle,
#sidebar-ad-wrap,
#sidebar-ad1,
#sidebar-ad2,
#sidebar-ad3,
#sidebar-ads,
#sidebar-ads-content,
#sidebar-ads-narrow,
#sidebar-ads-wide,
#sidebar-ads-wrapper,
#sidebar-adspace,
#sidebar-adv,
#sidebar-advertise-text,
#sidebar-advertisement,
#sidebar-left-ad,
#sidebar-main-ad,
#sidebar-sponsors,
#sidebar-top-ad,
#sidebar-top-ads,
#sidebarAd,
#sidebarAd1,
#sidebarAd2,
#sidebarAdSense,
#sidebarAdSpace,
#sidebarAdUnitWidget,
#sidebarAds,
#sidebarAdvTop,
#sidebarAdvert,
#sidebarSponsors,
#sidebarTextAds,
#sidebarTowerAds,
#sidebar_ad,
#sidebar_ad_1,
#sidebar_ad_2,
#sidebar_ad_3,
#sidebar_ad_big,
#sidebar_ad_container,
#sidebar_ad_top,
#sidebar_ad_widget,
#sidebar_ad_wrapper,
#sidebar_adblock,
#sidebar_ads,
#sidebar_box_add,
#sidebar_topad,
#sidebarad,
#sidebarad0,
#sidebaradpane,
#sidebarads,
#sidebaradsense,
#sidebaradverts,
#sidebard-ads-wrapper,
#sidebargooglead,
#sidebargoogleads,
#sidebarrectad,
#sideline-ad,
#sidepad-ad,
#single-ad,
#single-ad-2,
#single-adblade,
#single-mpu,
#singleAd,
#singleAdsContainer,
#singlead,
#singleads,
#site-ad-container,
#site-ads,
#site-header__ads,
#site-leaderboard-ads,
#site-sponsor-ad,
#site-sponsors,
#siteAdHeader,
#site_bottom_ad_div,
#site_content_ad_div,
#site_top_ad,
#site_wrap_ad,
#sitead,
#skcolAdSky,
#skin-ad,
#skin-ad-left-rail-container,
#skin-ad-right-rail-container,
#skinTopAd,
#skin_adv,
#skinad-left,
#skinad-right,
#skinningads,
#sky-ad,
#sky-ads,
#sky-left,
#sky-right,
#skyAd,
#skyAdContainer,
#skyScraperAd,
#skyScrapperAd,
#skyWrapperAds,
#sky_ad,
#sky_advert,
#skyads,
#skyadwrap,
#skybox-ad,
#skyline_ad,
#skyscrapeAd,
#skyscraper-ad,
#skyscraperAd,
#skyscraperAdContainer,
#skyscraperAdWrap,
#skyscraperAds,
#skyscraperWrapperAd,
#skyscraper_ad,
#skyscraper_advert,
#skyscraperadblock,
#skyscrapper-ad,
#slashboxes>.deals-rail,
#slideAd,
#slide_ad,
#slidead,
#slideboxad,
#slider-ad,
#sliderAdHolder,
#slider_ad,
#sm-banner-ad,
#smallAd,
#small_ad,
#small_ads,
#smallad,
#smallads,
#smallerAd,
#sp-adv-banner-top,
#specialAd,
#special_ads,
#specialadfeatures,
#specials_ads,
#speed_ads,
#speeds_ads,
#splashy-ad-container-top,
#sponBox,
#spon_links,
#sponlink,
#sponlinks,
#sponsAds,
#sponsLinks,
#spons_links,
#sponseredlinks,
#sponsor-box-widget,
#sponsor-flyout,
#sponsor-flyout-wrap,
#sponsor-links,
#sponsor-partners,
#sponsor-sidebar-container,
#sponsorAd,
#sponsorAd1,
#sponsorAd2,
#sponsorAdDiv,
#sponsorBar,
#sponsorBorder,
#sponsorContainer0,
#sponsorFooter,
#sponsorLinkDiv,
#sponsorLinks,
#sponsorResults,
#sponsorSpot,
#sponsorTab,
#sponsorText,
#sponsorTextLink,
#sponsor_300x250,
#sponsor_ad,
#sponsor_ads,
#sponsor_bar,
#sponsor_bottom,
#sponsor_box,
#sponsor_deals,
#sponsor_div,
#sponsor_footer,
#sponsor_header,
#sponsor_link,
#sponsor_no,
#sponsor_posts,
#sponsor_right,
#sponsored-ads,
#sponsored-carousel-nucleus,
#sponsored-footer,
#sponsored-inline,
#sponsored-links,
#sponsored-links-alt,
#sponsored-links-container,
#sponsored-listings,
#sponsored-message,
#sponsored-products,
#sponsored-recommendations,
#sponsored-resources,
#sponsored-search,
#sponsored-text-links,
#sponsored-widget,
#sponsored1,
#sponsoredAd,
#sponsoredAdvertisement,
#sponsoredBottom,
#sponsoredBox1,
#sponsoredBox2,
#sponsoredFeaturedHoz,
#sponsoredHoz,
#sponsoredLinks,
#sponsoredLinksBox,
#sponsoredList,
#sponsoredResults,
#sponsoredResultsWide,
#sponsoredTop,
#sponsored_ads,
#sponsored_container,
#sponsored_content,
#sponsored_head,
#sponsored_label,
#sponsored_link_bottom,
#sponsored_links,
#sponsored_native_ad,
#sponsoredad,
#sponsoredads,
#sponsoredlinks,
#sponsorfeature,
#sponsorlink,
#sponsors-article,
#sponsors-block,
#sponsors-home,
#sponsorsBox,
#sponsorsContainer,
#sponsorship-area-wrapper,
#sponsorship-box,
#sporsored-results,
#spotlight-ads,
#spotlightAds,
#spotlight_ad,
#spotlightad,
#sprint_ad,
#sqAd,
#sq_ads,
#square-ad,
#square-ad-box,
#square-ad-space,
#square-ads,
#square-sponsors,
#squareAd,
#squareAdBottom,
#squareAdSpace,
#squareAdTop,
#squareAdWrap,
#squareAds,
#squareGoogleAd,
#square_ad,
#squaread,
#squareadevertise,
#squareadvert,
#squared_ad,
#staticad,
#stationad,
#sticky-ad,
#sticky-ad-bottom,
#sticky-ad-container,
#sticky-ad-header,
#sticky-add-side-block,
#sticky-ads,
#sticky-ads-top,
#sticky-custom-ads,
#sticky-footer-ad,
#sticky-footer-ads,
#sticky-left-ad,
#sticky-rail-ad,
#stickyAd,
#stickyAdBlock,
#stickyBottomAd,
#stickySidebarAd,
#stickySkyAd,
#sticky_sidebar_ads,
#stickyad,
#stickyads,
#stickyleftad,
#stickyrightad,
#stopAdv,
#stop_ad3,
#story-ad,
#story-bottom-ad,
#storyAd,
#story_ad,
#story_ads,
#story_bottom_ddb_0,
#story_top_ddb_0,
#storyad2,
#stripadv,
#subheaderAd,
#taboola,
#taboola-above-homepage-thumbnails,
#taboola-ad,
#taboola-adverts,
#taboola-below,
#taboola-below-article-1,
#taboola-below-article-thumbnails,
#taboola-below-article-thumbnails,
#taboola-below-article-thumbnails-2,
#taboola-below-article-thumbnails-express,
#taboola-below-article-thumbnails-mg,
#taboola-below-article-thumbnails-v2,
#taboola-below-disco-board,
#taboola-below-forum-thumbnails,
#taboola-below-homepage-thumbnails-2,
#taboola-below-homepage-thumbnails-3,
#taboola-below-main-column,
#taboola-belowarticle,
#taboola-bottom,
#taboola-bottom-main-column,
#taboola-div,
#taboola-homepage-thumbnails,
#taboola-homepage-thumbnails-desktop,
#taboola-horizontal-toolbar,
#taboola-in-feed-thumbnails,
#taboola-mid-article-thumbnails,
#taboola-mid-article-thumbnails-ii,
#taboola-mid-main-column-thumbnails,
#taboola-mobile-article-thumbnails,
#taboola-native-right-rail-thumbnails,
#taboola-placeholder,
#taboola-right-rail,
#taboola-right-rail,
#taboola-right-rail-express,
#taboola-right-rail-text-right,
#taboola-right-rail-thumbnails,
#taboola-right-rail-thumbnails-2nd,
#taboola-text-2-columns-mix,
#taboola-vid-container,
#taboola-widget-wrapper,
#taboola_bottom,
#taboola_responsive_wrapper,
#taboola_side,
#taboola_wrapper,
#takeover-ad,
#takeover_ad,
#takeoverad,
#td-ad-placeholder,
#tdAds,
#td_adunit2,
#td_sponsorAd,
#team_ad,
#teaser1[style^="width:autopx;"],
#teaser2[style^="width:autopx;"],
#teaser3[style="width: 100%;text-align: center;display: scroll;position:fixed;bottom: 0;margin: 0 auto;z-index: 103;"],
#teaser3[style^="width:autopx;"],
#text-ad,
#text-ads,
#text-intext-ads,
#text-link-ads,
#textAd,
#textAd1,
#textAds,
#textAdsTop,
#text_ad,
#text_ads,
#text_advert,
#textad,
#textad3,
#textlink-advertisement,
#textsponsor,
#tfm_admanagerTeaser,
#tile-ad,
#tileAds,
#tmInfiniteAd,
#toaster_ad,
#top-ad,
#top-ad-area,
#top-ad-banner,
#top-ad-container,
#top-ad-content,
#top-ad-desktop,
#top-ad-div,
#top-ad-google,
#top-ad-iframe,
#top-ad-rect,
#top-ad-slot,
#top-ad-slot-0,
#top-ad-slot-1,
#top-ad-unit,
#top-ad-wrapper,
#top-adblock,
#top-adds,
#top-ads,
#top-ads-1,
#top-ads-contain,
#top-ads-container,
#top-adspot,
#top-advert,
#top-advertisement,
#top-advertisements,
#top-advertising-content,
#top-banner-ad,
#top-banner-ad-browser,
#top-buy-sell-ads,
#top-dfp,
#top-head-ad,
#top-leaderboard-ad,
#top-left-ad,
#top-middle-add,
#top-not-ads,
#top-right-ad,
#top-right-ad-slot,
#top-skin-ad,
#top-skin-ad-bg,
#top-sponsor-ad,
#top-story-ad,
#topAD,
#topAd,
#topAd728x90,
#topAdArea,
#topAdBanner,
#topAdBar,
#topAdBox,
#topAdContainer,
#topAdDiv,
#topAdDropdown,
#topAdHolder,
#topAdShow,
#topAdSpace,
#topAdSpace_div,
#topAdWrapper,
#topAdcontainer,
#topAds,
#topAds1,
#topAds2,
#topAdsContainer,
#topAdsDiv,
#topAdsG,
#topAdv,
#topAdvBox,
#topAdvert,
#topBanner-ad,
#topBannerAd,
#topBannerAdContainer,
#topBannerAdv,
#topImgAd,
#topLeaderboardAd,
#topMPU,
#topMpuContainer,
#topSponsorBanner,
#topSponsoredLinks,
#top_AD,
#top_ad,
#top_ad-360,
#top_ad_area,
#top_ad_banner,
#top_ad_block,
#top_ad_box,
#top_ad_container,
#top_ad_td,
#top_ad_unit,
#top_ad_wrapper,
#top_ad_zone,
#top_add,
#top_ads,
#top_ads_box,
#top_ads_container,
#top_ads_region,
#top_ads_wrap,
#top_adsense_cont,
#top_adspace,
#top_adv,
#top_advert,
#top_advert_box,
#top_advertise,
#top_advertising,
#top_banner_ads,
#top_container_ad,
#top_google_ads,
#top_mpu,
#top_mpu_ad,
#top_rectangle_ad,
#top_right_ad,
#top_row_ad,
#top_span_ad,
#top_sponsor_ads,
#top_sponsor_text,
#top_wide_ad,
#topad,
#topad-728x90,
#topad-block,
#topad-wrap,
#topad1,
#topad2,
#topad728,
#topad_holder,
#topad_left,
#topad_right,
#topad_table,
#topadbanner,
#topadbanner2,
#topadbar,
#topadblock,
#topadcell,
#topadcontainer,
#topaddwide,
#topadleft,
#topadone,
#topadplaceholder,
#topadright,
#topads-spacer,
#topads-wrapper,
#topadsblock,
#topadsdiv,
#topadsense,
#topadspace,
#topadvert,
#topadwrap,
#topadz,
#topadzone,
#topbanner_ad,
#topbanner_sponsor,
#topbannerad,
#topbanneradtitle,
#topbar-ad,
#topbarAd,
#topbarad,
#topbarads,
#topcustomad,
#topheader_ads,
#topleaderAd,
#topleaderboardad,
#topnavad,
#toppannonse,
#topright-ad,
#toprightAdvert,
#toprightad,
#toprow-ad,
#topsidebar-ad,
#topsponad,
#topsponsorads,
#topsponsored,
#toptextad,
#tor-footer-ad,
#tower1ad,
#towerAdContainer,
#towerad,
#tpd-post-header-ad,
#tpl_advertising,
#transparentad,
#trc_google_ad,
#txtAdHeader,
#ultimedia_wrapper,
#upper-ads,
#upperMpu,
#upperRightAds,
#upper_adbox,
#upper_advertising,
#upper_small_ad,
#upperad,
#vc-maincontainer-ad,
#vc-maincontainer-midad,
#velsof_wheel_container,
#vert-ads,
#vertAd2,
#vert_ad,
#vert_ad_placeholder,
#vertad1,
#vertical.ad,
#verticalAds,
#vertical_ad,
#vertical_ads,
#verticalads,
#vidazoo-player,
#video-ad,
#video-ad-companion-rectangle,
#video-adv,
#video-adv-wrapper,
#video-advert,
#video-embed-ads,
#video-in-player-ad,
#video-side-adv,
#video-sponsor-links,
#video-under-player-ad,
#videoAd,
#videoAdContainer,
#videoAdvert,
#videoCompanionAd,
#videoOverAd,
#videoOverAd300,
#videoPauseAd,
#video_adv,
#video_advert,
#video_advert_top,
#video_embed_ads,
#video_hor_bot_ads,
#video_overlay_ad,
#videoad,
#videoad-script-cnt,
#videoads,
#viewAd1,
#viewabilityAdContainer,
#visual-ad,
#vuukle-quiz-and-ad,
#vuukle_ads_square2,
#wTopAd,
#wallAd,
#wall_advert,
#wd-sponsored,
#weather-ad,
#weather_sponsor,
#weatherad,
#welcome_ad,
#wg_ads,
#wgtAd,
#whitepaper-ad,
#wide-ad,
#wideAdd,
#wide_ad_unit,
#wide_ad_unit2,
#wide_ad_unit3,
#wide_adv,
#wide_right_ad,
#widget-ads-3,
#widget-ads-4,
#widget-adv-12,
#widget-box-ad-1,
#widget-box-ad-2,
#widget_Adverts,
#widget_ad,
#widget_advertisement,
#widget_thrive_ad_default-2,
#widget_thrive_ad_default-4,
#widgetwidget_adserve,
#widgetwidget_adserve2,
#wl-pencil-ad,
#wow-ads,
#wp-insert-ad-widget-1,
#wp-topAds,
#wp_ad_marker,
#wp_adbn_root,
#wp_ads_gpt_widget-16,
#wp_ads_gpt_widget-17,
#wp_ads_gpt_widget-18,
#wp_ads_gpt_widget-19,
#wp_ads_gpt_widget-21,
#wp_ads_gpt_widget-4,
#wp_ads_gpt_widget-5,
#wpladbox1,
#wpladbox2,
#wrapAd,
#wrapAdRight,
#wrapCommentAd,
#wrapper-AD_G,
#wrapper-AD_L,
#wrapper-AD_L2,
#wrapper-AD_L3,
#wrapper-AD_PUSH,
#wrapper-AD_R,
#wrapper-ad,
#wrapper-ad970,
#wrapperAdsTopLeft,
#wrapperAdsTopRight,
#wrapperRightAds,
#wrapper_ad_Top,
#wrapper_sponsoredlinks,
#wrapper_topad,
#wtopad,
#yahoo-sponsors,
#yahooAdsBottom,
#yahooSponsored,
#yahoo_ads,
#yahoo_text_ad,
#yahooads,
#yandex_ad,
#yatadsky,
#yrail_ads,
#yreSponsoredLinks,
#ysm_ad_iframe,
#zMSplacement1,
#zMSplacement2,
#zMSplacement3,
#zMSplacement4,
#zMSplacement5,
#zMSplacement6,
#zdcFloatingBtn,
#zeus_top-banner,
#zone-adsense,
#zsAdvertisingBanner,
.-advertsSidebar,
.ADBAR,
.ADBox,
.ADFooter,
.ADInfo,
.ADLeader,
.ADMiddle1,
.ADPod,
.ADServer,
.ADStyle,
.ADTop,
.ADVBig,
.ADVFLEX_250,
.ADVParallax,
.ADV_Mobile,
.AD_2,
.AD_area,
.ADbox,
.ADmid,
.ADwidget,
.ATF_wrapper,
.Ad--Align,
.Ad--empty,
.Ad--header,
.Ad--loading,
.Ad--presenter,
.Ad--sidebar,
.Ad-Advert_Container,
.Ad-Container,
.Ad-Header,
.Ad-Inner,
.Ad-adhesive,
.Ad-hor-height,
.Ad-label,
.Ad-leaderboard,
.Ad.Leaderboard,
.Ad300,
.Ad3Tile,
.Ad728x90,
.AdBar,
.AdBody:not(body),
.AdBorder,
.AdBottomPage,
.AdBox,
.AdBox160,
.AdBox7,
.AdBox728,
.AdCenter,
.AdCommercial,
.AdCompactheader,
.AdContainer,
.AdContainer-Sidebar,
.AdHeader,
.AdHere,
.AdHolder,
.AdInline,
.AdInsLink,
.AdLeft1,
.AdLeft2,
.AdMedium,
.AdMessage,
.AdMod,
.AdModule,
.AdOneColumnContainer,
.AdOuterMostContainer,
.AdPanel,
.AdPlaceHolder,
.AdPlaceholder,
.AdPlacementContainer,
.AdProduct,
.AdRight1,
.AdRight2,
.AdSense,
.AdSenseLeft,
.AdSlot,
.AdSpace,
.AdSpeedWP,
.AdTagModule,
.AdTitle,
.AdTop,
.AdUnit,
.Ad_C,
.Ad_D,
.Ad_Label,
.Ad_Right,
.Ad_container,
.Ads--center,
.Ads-768x90,
.Ads-background,
.Ads-leaderboard,
.Ads-slot,
.Ads-sticky,
.AdsBottom,
.AdsBox,
.AdsBoxBottom,
.AdsBoxSection,
.AdsBoxTop,
.AdsLayout__top-container,
.AdsRectangleWrapper,
.AdsSlot,
.Ads__wrapper,
.Ads_header,
.Adsense,
.AdsenseBox,
.Adsterra,
.Adtext,
.Adv468,
.Advert-label,
.Advert300x250,
.AdvertContainer,
.AdvertWrapper,
.AdvertisementAfterHeader,
.AdvertisementAfterPost,
.AdvertisementAsidePost,
.AdvertisementText,
.AdvertisementTextTag,
.AdvertisementTop,
.Advertisment,
.AdvertorialTeaser,
.AdvtSample,
.AdzerkBanner,
.AffiliateAds,
.AppFooter__BannerAd,
.Arpian-ads,
.Article-advert,
.ArticleAd,
.ArticleAdSide,
.ArticleAdWrapper,
.ArticleFooter-outbrain,
.ArticleInlineAd,
.ArticleInnerAD,
.ArticleOutbrainLocal,
.Article__Ad,
.BOX_Ad,
.BOX_LeadAd,
.Banner300x250,
.Banner468X60,
.BeOpWidget,
.BigBoxAd,
.BigBoxAdLabel,
.Billboard-ad,
.Billboard-ad-holder,
.Billboard_2-ad-holder,
.Billboard_3-ad-holder,
.Billboard_4-ad-holder,
.Billboard_5-ad-holder,
.BlockAd,
.BottomAd-container,
.BottomAdContainer,
.BottomAdsPartial,
.BottomAffiliate,
.BoxAd,
.BoxAdWrap,
.BoxRail-ad,
.ButtonAd,
.CitrusBannerWrapper--enollj,
.CommentAd,
.ConnatixAd,
.ContentAd,
.ContentAds,
.ContentBottomAd,
.ContentTextAd,
.ContentTopAd,
.DFPad,
.DisplayAd,
.FirstAd,
.FooterAd,
.FooterAdContainer,
.FooterAds,
.Footer_1-ad-holder,
.GRVAd,
.GRVMpuWrapper,
.GRVMultiVideo,
.GRVPrimisVideo,
.GRVVideo,
.Gallery-Content-BottomAd,
.GeminiAdItem,
.GeminiNativeAd,
.GoogleAdv,
.GoogleDfpAd,
.GoogleDfpAd-Content,
.GoogleDfpAd-Float,
.GoogleDfpAd-container,
.GoogleDfpAd-wrap,
.GoogleDfpAd-wrapper,
.GoogleDfpAdModule,
.GoogleDoubleClick-SponsorText,
.GroupAdSense,
.HeaderAd,
.HeaderAds,
.HeaderBannerAd,
.HeadingAdSpace,
.Hero-Ad,
.HomeAds,
.InArticleAd,
.IndexRightAd,
.InsertedAd,
.LastAd,
.LayoutBottomAds,
.LayoutHomeAds,
.LayoutHomeAdsAd,
.LayoutPromotionAdsNew,
.LazyLoadAd,
.LeaderAd,
.LeaderAdvertisement,
.LeaderBoardAd,
.LearderAd_Border,
.ListicleAdRow,
.MPUHolder,
.MPUad,
.MapLayout_BottomAd,
.MapLayout_BottomMobiAd,
.MarketGid_container,
.MbanAd,
.MiddleAd,
.MiddleAdContainer,
.MiddleAdvert,
.MiddleRightRadvertisement,
.NA_ad,
.NR-Ads,
.NativeAdContainerRegion,
.NavBarAd,
.Normal-add,
.OAS_wrap,
.OUTBRAIN,
.OUTBRAIN[data-widget-id^="FMS_REELD_"],
.OcelotAdModule,
.OcelotAdModule-ad,
.Outbrain,
.PPD_ADS_JS,
.Page-ad,
.PageTopAd,
.PcSideBarAd,
.PencilAd,
.PostAdvertisementBeforePost,
.PostSidebarAd,
.Post__ad,
.PrimisResponsiveStyle,
.PrintAd-Slider,
.ProductAd,
.PushdownAd,
.RC-AD,
.RC-AD-BOX-BOTTOM,
.RC-AD-BOX-MIDDLE,
.RC-AD-BOX-TOP,
.RC-AD-TOP-BANNER,
.RectangleAd,
.Rectangle_1-ad-holder,
.Rectangle_2-ad-holder,
.Rectangle_3-ad-holder,
.RelatedAds,
.ResponsiveAd,
.RightAd,
.RightAd1,
.RightAd2,
.RightAdvertisement,
.RightGoogleAd,
.RightRailAd,
.RightRailAds,
.RightTowerAd,
.STR_AdBlock,
.SecondaryAd,
.SecondaryAdLink,
.Section-ad,
.SectionSponsor,
.SideAd,
.SideAdCol,
.SideAds,
.SideWidget__ad,
.Sidebar-ad,
.Sidebar-ad--300x600,
.SidebarAd,
.SidebarAdvert,
.SidebarRightAdvertisement,
.SimpleAd,
.SkyAdContainer,
.SkyAdContent,
.SkyScraperAd,
.SovrnAd,
.Sponsor-container,
.SponsorHeader,
.SponsorIsland,
.SponsorLink,
.SponsoredAdTitle,
.SponsoredArticleAd,
.SponsoredContent,
.SponsoredContentWidget,
.SponsoredLinks,
.SponsoredLinksModule,
.SponsoredLinksPadding,
.SponsoredLinksPanel,
.SponsoredResults,
.Sponsored_link,
.SponsorshipText,
.SquareAd,
.Squareadspot,
.StandardAdLeft,
.StandardAdRight,
.Sticky-AdContainer,
.StickyAdRail__Inner,
.SummaryPage-HeaderAd,
.TextAd,
.TextAdds,
.Textads,
.ThreeAds,
.TmnAdsense,
.TopAd,
.TopAdBox,
.TopAdContainer,
.TopAdL,
.TopAdR,
.TopAds,
.TopAdsPartial,
.TopBannerAd,
.TopRightRadvertisement,
.Top_Ad,
.TrackedBannerPromo,
.TrackedSidebarPromo,
.TrafficAd,
.U210-adv-column,
.UnderAd,
.VPCarbonAds,
.VerticalAd,
.Video-Ad,
.VideoAd,
.WPBannerizeWidget,
.WP_Widget_Ad_manager,
.WideAdTile,
.WideAdsLeft,
.WidgetAdvertiser,
.WidthAd,
.WikiaTopAds,
.ZERGNET,
.\[\&_\.gdprAdTransparencyCogWheelButton\]\:\!pjra-z-\[5\],
._SummaryPageHeaderAdView,
._SummaryPageSidebarStickyAdView,
.__isboostOverContent,
._ads,
._ads-full,
._ap_adrecover_ad,
._ap_apex_ad,
._articleAdvert,
._bannerAds,
._bottom_ad_wrapper,
._ciw-betterAds,
._fullsquaread,
._has-ads,
._popIn_recommend_article_ad,
._popIn_recommend_article_ad_reserved,
._table_ad_div_wide,
.a-ad,
.a-ad--aside,
.a-ad--leaderboard,
.a-ad--skyscraper,
.a-ad--wide,
.a-d-250,
.a-d-90,
.a-d-container,
.a-d-holder-container,
.a-dserver,
.a-dserver_text,
.a-sponsor,
.a160x600,
.a300x250,
.a468x60,
.a728x90,
.aadsection_b1,
.aadsection_b2,
.aarpe-ad-wrapper,
.ab-ad_placement-article,
.abBoxAd,
.abMessage,
.abPopup,
.ablock300,
.ablock468,
.ablock728,
.above-header-advert,
.aboveCommentAds,
.abovead,
.ac-banner-ad,
.ac-lre-desktop,
.ac-lre-player-ph,
.ac-lre-wrapper,
.ac-widget-placeholder,
.ac_adbox,
.acm-ad-container,
.acm-ad-tag-unit,
.acm_ad_zones,
.ad--300,
.ad--300x250,
.ad--468,
.ad--468-60,
.ad--728x90,
.ad--970-750-336-300,
.ad--970-90,
.ad--article,
.ad--article-top,
.ad--articlemodule,
.ad--b,
.ad--banner,
.ad--banner2,
.ad--banniere_basse,
.ad--banniere_haute,
.ad--billboard,
.ad--bottom,
.ad--bottom-label,
.ad--bottommpu,
.ad--boundries,
.ad--button,
.ad--c,
.ad--center,
.ad--centered,
.ad--container,
.ad--content,
.ad--content-ad,
.ad--dart,
.ad--desktop,
.ad--displayed,
.ad--droite_basse,
.ad--droite_haute,
.ad--droite_middle,
.ad--e,
.ad--fallback,
.ad--footer,
.ad--fullsize,
.ad--google,
.ad--halfpage,
.ad--header,
.ad--homepage-top,
.ad--in-article,
.ad--in-content,
.ad--inArticleBanner,
.ad--inline,
.ad--inner,
.ad--large,
.ad--leaderboard,
.ad--loading,
.ad--medium-rectangle,
.ad--medium_rectangle,
.ad--medium_rectangle_outstream,
.ad--mediumrectangle,
.ad--mid,
.ad--mid-content,
.ad--mobile,
.ad--mpu,
.ad--native,
.ad--nativeFlex,
.ad--no-bg,
.ad--noscroll,
.ad--object,
.ad--outstream,
.ad--overlayer,
.ad--p1,
.ad--p2,
.ad--p3,
.ad--p4,
.ad--p6,
.ad--p7,
.ad--placeholder,
.ad--pubperform,
.ad--pushdown,
.ad--rail,
.ad--rectangle,
.ad--rectangle1,
.ad--rectangle2,
.ad--right,
.ad--rightRail,
.ad--scroll,
.ad--section,
.ad--sidebar,
.ad--sky,
.ad--skyscraper,
.ad--slider,
.ad--slot,
.ad--sponsor-content,
.ad--square-rectangle,
.ad--sticky,
.ad--stripe,
.ad--stroeer,
.ad--subcontainer,
.ad--top,
.ad--top-desktop,
.ad--top-leaderboard,
.ad--top-slot,
.ad--topmobile,
.ad--topmobile2,
.ad--topmobile3,
.ad--wallpaper,
.ad--widget,
.ad--wrapper,
.ad-1,
.ad-120-60,
.ad-120x60,
.ad-120x600,
.ad-120x90,
.ad-125x125,
.ad-13,
.ad-137,
.ad-14,
.ad-160,
.ad-160-160,
.ad-160-600,
.ad-160x600,
.ad-2,
.ad-200,
.ad-200x200,
.ad-250,
.ad-250x300,
.ad-3,
.ad-300,
.ad-300-2,
.ad-300-250-600,
.ad-300-600,
.ad-300-b,
.ad-300-block,
.ad-300-dummy,
.ad-300-flex,
.ad-300-x-250,
.ad-300X250,
.ad-300X250-body,
.ad-300x,
.ad-300x100,
.ad-300x200,
.ad-300x250,
.ad-300x600,
.ad-336,
.ad-336x280,
.ad-336x280B,
.ad-350,
.ad-4,
.ad-468,
.ad-468x120,
.ad-468x60,
.ad-5,
.ad-544x250,
.ad-55,
.ad-560,
.ad-6,
.ad-600,
.ad-600-h,
.ad-635x40,
.ad-7,
.ad-728,
.ad-728-90,
.ad-728-banner,
.ad-728-x-90,
.ad-728x90,
.ad-728x90-1,
.ad-728x90-top,
.ad-728x90-top0,
.ad-728x90-wrapper,
.ad-728x90_forum,
.ad-768,
.ad-8,
.ad-88-60,
.ad-88x31,
.ad-9,
.ad-90,
.ad-90x600,
.ad-970,
.ad-970-250,
.ad-970-90,
.ad-970x250,
.ad-970x90,
.ad-Advert_Placeholder,
.ad-E,
.ad-LREC,
.ad-LREC2,
.ad-Leaderboard,
.ad-MPU,
.ad-MediumRectangle,
.ad-PENCIL,
.ad-S,
.ad-Square,
.ad-SuperBanner,
.ad-TOPPER,
.ad-W,
.ad-a,
.ad-ab,
.ad-abc,
.ad-above-header,
.ad-accordion,
.ad-active,
.ad-adSense,
.ad-adcode,
.ad-adhesion,
.ad-adlink-bottom,
.ad-adlink-side,
.ad-adsense,
.ad-adsense-block-250,
.ad-advertisement-horizontal,
.ad-affiliate,
.ad-after-content,
.ad-after-header,
.ad-align-none,
.ad-aligncenter,
.ad-alignment,
.ad-alsorectangle,
.ad-anchor,
.ad-aps-wide,
.ad-area,
.ad-area--pd,
.ad-area-small,
.ad-article-breaker,
.ad-article-inline,
.ad-article-teaser,
.ad-article-wrapper,
.ad-aside-pc-billboard,
.ad-atf,
.ad-atf-top,
.ad-background,
.ad-background-center,
.ad-background-container,
.ad-ban,
.ad-banner-2,
.ad-banner-250x600,
.ad-banner-300,
.ad-banner-300x250,
.ad-banner-5,
.ad-banner-6,
.ad-banner-728x90,
.ad-banner-bottom-container,
.ad-banner-box,
.ad-banner-btf,
.ad-banner-container,
.ad-banner-content,
.ad-banner-full-wrapper,
.ad-banner-header,
.ad-banner-image,
.ad-banner-inlisting,
.ad-banner-leaderboard {
display: none !important;
}


#AC_ad,
#AD_160,
#AD_300,
#AD_468x60,
#AD_G,
#AD_L,
#AD_ROW,
#AD_Top,
#AD_text,
#ADbox,
#Ad-3-Slider,
#Ad-4-Slider,
#Ad-Container,
#Ad-Content,
#Ad-Top,
#AdBanner,
#AdBar,
#AdBigBox,
#AdBillboard,
#AdBlock,
#AdBottomLeader,
#AdBottomRight,
#AdBox2,
#AdColumn,
#AdContainerTop,
#AdContent,
#AdDisclaimer,
#AdHeader,
#AdMiddle,
#AdPopUp,
#AdRectangleBanner,
#AdSense1,
#AdSense2,
#AdSense3,
#AdServer,
#AdSkyscraper,
#AdSlot_megabanner,
#AdSpaceLeaderboard,
#AdTop,
#AdTopLeader,
#AdWidgetContainer,
#AdWrapperSuperCA,
#AdZone1,
#AdZone2,
#Ad_BelowContent,
#Ad_Block,
#Ad_TopLeaderboard,
#Adbanner,
#Adlabel,
#AdsBannerTop,
#AdsBillboard,
#AdsBottomContainer,
#AdsContent,
#AdsDiv,
#AdsFrame,
#AdsPubperform,
#AdsRight,
#AdsSky,
#AdsTopContainer,
#AdsWrap,
#Ads_BA_BS,
#Ads_BA_BUT,
#Ads_BA_BUT2,
#Ads_BA_BUT_box,
#Ads_BA_CAD,
#Ads_BA_CAD2,
#Ads_BA_FLB,
#Ads_BA_SKY,
#Ads_BA_VID,
#Ads_TFM_BS,
#Ads_google_bottom_wide,
#Adsense300x250,
#AdsenseBottom,
#AdsenseTop,
#Adsterra,
#Adv10,
#Adv11,
#Adv8,
#Adv9,
#AdvContainer,
#AdvFooter,
#AdvHeader,
#Adv_Footer,
#AdvertMid1,
#AdvertMid2,
#AdvertPanel,
#AdvertText,
#AdvertiseFrame,
#Advertisement1,
#Advertisement2,
#AdvertisementDiv,
#AdvertisementLeaderboard,
#Advertisements,
#AdvertisingDiv_0,
#Advertorial,
#Advertorials,
#AnchorAd,
#ArticleContentAd,
#Banner728x90,
#BannerAd,
#BannerAds,
#BannerAdvert,
#BannerAdvertisement,
#BigBoxAd,
#BigboxAdUnit,
#BodyAd,
#BodyTopAds,
#Body_Ad8_divAdd,
#BotAd,
#BottomAdContainer,
#BottomRightAdWrapper,
#ButtonAd,
#ContentAd,
#Content_CA_AD_0_BC,
#Content_CA_AD_1_BC,
#DFP_top_leaderboard,
#FooterAd,
#FooterAdBlock,
#FooterAdContainer,
#GoogleAd,
#GoogleAd1,
#GoogleAd2,
#GoogleAd3,
#GoogleAdRight,
#GoogleAdTop,
#GoogleAdsense,
#HP1-ad,
#HP2-ad,
#HeadAd,
#HeaderAD,
#HeaderAd,
#HeaderAdBlock,
#HeaderAdsBlock,
#HeroAd,
#HomeAd1,
#IFrameAd,
#IFrameAd1,
#IK-ad-area,
#IK-ad-block,
#IM_AD,
#LargeRectangleAd,
#LayoutBottomAdBox,
#LayoutHomeAdBoxBottom,
#LeaderboardAdvertising,
#LeftAd,
#LeftAd1,
#MPUAdSpace,
#MPUadvertising,
#MPUadvertisingDetail,
#MainAd,
#MediumRectangleAD,
#MidPageAds,
#MiddleRightRadvertisement,
#Mpu_Bottom,
#Mpu_Top,
#MyAdsId3,
#NR-Ads,
#PaneAdvertisingContainer,
#Player_Playoncontent,
#Player_Playoncontent_footer,
#PromotionAdBox,
#RadAdSkyscraper,
#RightAd,
#RightAdBlock,
#RightAdSpace,
#RightAdvertisement,
#SidebarAd,
#SidebarAdContainer,
#SitenavAdslot,
#SkyAd,
#SkyscraperAD,
#SponsoredAd,
#SponsoredAds,
#SponsoredLinks,
#SponsorsAds,
#StickyBannerAd,
#Top-ad,
#Top1AdWrapper,
#TopADs,
#TopAd,
#TopAd0,
#TopAdBox,
#TopAdContainer,
#TopAdPlacement,
#TopAdPos,
#TopAdTable,
#TopAdvert,
#TopBannerAd,
#TopRightRadvertisement,
#VPNAdvert,
#WelcomeAd,
#aad-header-1,
#aad-header-2,
#aad-header-3,
#ab_adblock,
#above-comments-ad,
#above-fold-ad,
#above-footer-ads,
#aboveAd,
#aboveNodeAds,
#above_button_ad,
#aboveplayerad,
#abovepostads,
#acm-ad-tag-lawrence_dfp_mobile_arkadium,
#ad--article--home-mobile-paramount-wrapper,
#ad--article-bottom-wrapper,
#ad--article-top,
#ad--sidebar,
#ad-0,
#ad-1,
#ad-125x125,
#ad-160,
#ad-160x600,
#ad-2,
#ad-2-160x600,
#ad-250,
#ad-250x300,
#ad-3,
#ad-3-300x250,
#ad-300,
#ad-300-250,
#ad-300-additional,
#ad-300-detail,
#ad-300-sidebar,
#ad-300X250-2,
#ad-300a,
#ad-300b,
#ad-300x250,
#ad-300x250-0,
#ad-300x250-2,
#ad-300x250-b,
#ad-300x250-sidebar,
#ad-300x250-wrapper,
#ad-300x250_mid,
#ad-300x250_mobile,
#ad-300x250_top,
#ad-300x600_top,
#ad-4,
#ad-5,
#ad-6,
#ad-7,
#ad-728,
#ad-728-90,
#ad-728x90,
#ad-8,
#ad-9,
#ad-Content_1,
#ad-Content_2,
#ad-Rectangle_1,
#ad-Rectangle_2,
#ad-Superbanner,
#ad-a,
#ad-ads,
#ad-advertorial,
#ad-affiliate,
#ad-after,
#ad-anchor,
#ad-around-the-web,
#ad-article,
#ad-article-in,
#ad-aside-1,
#ad-background,
#ad-ban,
#ad-banner-1,
#ad-banner-atf,
#ad-banner-bottom,
#ad-banner-btf,
#ad-banner-desktop,
#ad-banner-image,
#ad-banner-placement,
#ad-banner-top,
#ad-banner-wrap,
#ad-banner_atf-label,
#ad-bar,
#ad-base,
#ad-bb-content,
#ad-below-content,
#ad-bg,
#ad-big,
#ad-bigbox,
#ad-bigsize,
#ad-billboard,
#ad-billboard-atf,
#ad-billboard-bottom,
#ad-billboard01,
#ad-blade,
#ad-block,
#ad-block-125,
#ad-block-2,
#ad-block-aa,
#ad-block-bottom,
#ad-block-container,
#ad-border,
#ad-bottom,
#ad-bottom-banner,
#ad-bottom-fixed,
#ad-bottom-right-container,
#ad-bottom-wrapper,
#ad-box,
#ad-box-1,
#ad-box-2,
#ad-box-bottom,
#ad-box-halfpage,
#ad-box-leaderboard,
#ad-box-left,
#ad-box-rectangle,
#ad-box-rectangle-2,
#ad-box-right,
#ad-box1,
#ad-box2,
#ad-boxes,
#ad-break,
#ad-bs,
#ad-btm,
#ad-buttons,
#ad-campaign,
#ad-carousel,
#ad-case,
#ad-center,
#ad-chips,
#ad-circfooter,
#ad-code,
#ad-col,
#ad-container-banner,
#ad-container-fullpage,
#ad-container-inner,
#ad-container-leaderboard,
#ad-container-mpu,
#ad-container-outer,
#ad-container-overlay,
#ad-container-top-placeholder,
#ad-container1,
#ad-contentad,
#ad-desktop-bottom,
#ad-desktop-takeover-home,
#ad-desktop-takeover-int,
#ad-desktop-top,
#ad-desktop-wrap,
#ad-discover,
#ad-display-ad,
#ad-display-ad-placeholder,
#ad-div-leaderboard,
#ad-drawer,
#ad-ear,
#ad-extra-flat,
#ad-featured-right,
#ad-fixed-bottom,
#ad-flex-top,
#ad-flyout,
#ad-footer-728x90,
#ad-framework-top,
#ad-front-btf,
#ad-front-footer,
#ad-full-width,
#ad-fullbanner-btf,
#ad-fullbanner-outer,
#ad-fullbanner2,
#ad-fullwidth,
#ad-googleAdSense,
#ad-gutter-left,
#ad-gutter-right,
#ad-halfpage,
#ad-halfpage1,
#ad-halfpage2,
#ad-head,
#ad-header-1,
#ad-header-2,
#ad-header-3,
#ad-header-left,
#ad-header-mad,
#ad-header-mobile,
#ad-header-right,
#ad-holder,
#ad-horizontal,
#ad-horizontal-header,
#ad-horizontal-top,
#ad-incontent,
#ad-index,
#ad-inline-block,
#ad-label2,
#ad-large-banner-top,
#ad-large-header,
#ad-lb-secondary,
#ad-lead,
#ad-leadboard1,
#ad-leadboard2,
#ad-leader,
#ad-leader-atf,
#ad-leader-container,
#ad-leader-wrapper,
#ad-leaderboard,
#ad-leaderboard-atf,
#ad-leaderboard-bottom,
#ad-leaderboard-container,
#ad-leaderboard-footer,
#ad-leaderboard-header,
#ad-leaderboard-spot,
#ad-leaderboard-top,
#ad-leaderboard970x90home,
#ad-leaderboard970x90int,
#ad-leaderboard_bottom,
#ad-leadertop,
#ad-lrec,
#ad-m-rec-content,
#ad-main,
#ad-main-bottom,
#ad-main-top,
#ad-manager,
#ad-masthead,
#ad-medium,
#ad-medium-lower,
#ad-medium-rectangle,
#ad-medrec,
#ad-medrec__first,
#ad-mid,
#ad-mid-rect,
#ad-middle,
#ad-midpage,
#ad-minibar,
#ad-module,
#ad-mpu,
#ad-mrec,
#ad-mrec2,
#ad-new,
#ad-north,
#ad-one,
#ad-other,
#ad-output,
#ad-overlay,
#ad-p3,
#ad-page-1,
#ad-pan3l,
#ad-panel,
#ad-pencil,
#ad-performance,
#ad-performanceFullbanner1,
#ad-performanceRectangle1,
#ad-placeholder,
#ad-placeholder-horizontal,
#ad-placeholder-vertical,
#ad-placement,
#ad-plate,
#ad-player,
#ad-popup,
#ad-popup-home,
#ad-popup-int,
#ad-post,
#ad-promo,
#ad-push,
#ad-pushdown,
#ad-r,
#ad-rec-atf,
#ad-rec-btf,
#ad-rec-btf-top,
#ad-rect,
#ad-rectangle,
#ad-rectangle1,
#ad-rectangle1-outer,
#ad-rectangle2,
#ad-rectangle3,
#ad-results,
#ad-right,
#ad-right-bar-tall,
#ad-right-container,
#ad-right-sidebar,
#ad-right-top,
#ad-right2,
#ad-right3,
#ad-rotator,
#ad-row,
#ad-section,
#ad-separator,
#ad-shop,
#ad-side,
#ad-side-text,
#ad-sidebar,
#ad-sidebar-btf,
#ad-sidebar-container,
#ad-sidebar-mad,
#ad-sidebar-mad-wrapper,
#ad-sidebar1,
#ad-sidebar2,
#ad-site-header,
#ad-skin,
#ad-skm-below-content,
#ad-sky,
#ad-skyscraper,
#ad-slideshow,
#ad-slideshow2,
#ad-slot,
#ad-slot-1,
#ad-slot-2,
#ad-slot-3,
#ad-slot-4,
#ad-slot-5,
#ad-slot-502,
#ad-slot-lb,
#ad-slot-right,
#ad-slot-top,
#ad-slot1,
#ad-slot2,
#ad-slot4,
#ad-slug-wrapper,
#ad-small-banner,
#ad-space,
#ad-space-big,
#ad-splash,
#ad-sponsors,
#ad-spot,
#ad-spot-bottom,
#ad-spot-one,
#ad-standard,
#ad-standard-wrap,
#ad-stickers,
#ad-sticky-footer-container,
#ad-story-right,
#ad-story-top,
#ad-stripe,
#ad-target,
#ad-teaser,
#ad-text,
#ad-three,
#ad-top,
#ad-top-250,
#ad-top-300x250,
#ad-top-728,
#ad-top-banner,
#ad-top-leaderboard,
#ad-top-left,
#ad-top-lock,
#ad-top-low,
#ad-top-right,
#ad-top-right-container,
#ad-top-text-low,
#ad-top-wrap,
#ad-top-wrapper,
#ad-tower,
#ad-two,
#ad-undefined,
#ad-unit-right-bottom-160-600,
#ad-unit-right-middle-300-250,
#ad-unit-top-banner,
#ad-vip-article,
#ad-west,
#ad-wide-leaderboard,
#ad-wrap,
#ad-wrap2,
#ad-wrapper,
#ad-wrapper-728x90,
#ad-wrapper-footer-1,
#ad-wrapper-main-1,
#ad-wrapper-sidebar-1,
#ad-wrapper-top-1,
#ad1-placeholder,
#ad125x125,
#ad160,
#ad160600,
#ad160x600,
#ad250,
#ad300,
#ad300-250,
#ad300_250,
#ad336,
#ad336x280,
#ad468,
#ad468x60,
#ad480x60,
#ad6,
#ad600,
#ad728,
#ad72890,
#ad728Box,
#ad728Header,
#ad728Mid,
#ad728Top,
#ad728Wrapper,
#ad728X90,
#ad728foot,
#ad728h,
#ad728top,
#ad728x90,
#ad728x90_1,
#ad90,
#ad900,
#ad970,
#ad970x90_exp,
#adATF300x250,
#adATF728x90,
#adATFLeaderboard,
#adAside,
#adBTF300x250,
#adBadges,
#adBanner1,
#adBanner336x280,
#adBannerBottom,
#adBannerHeader,
#adBannerSpacer,
#adBannerTable,
#adBannerTop,
#adBar,
#adBelt,
#adBillboard,
#adBlock01,
#adBlockBanner,
#adBlockContainer,
#adBlockContent,
#adBlockOverlay,
#adBlocks,
#adBottom,
#adBox,
#adBrandDev,
#adBrandingStation,
#adBreak,
#adCarousel,
#adChannel,
#adChoiceFooter,
#adChoices,
#adChoicesIcon,
#adChoicesLogo,
#adCol,
#adColumn,
#adColumn3,
#adComponentWrapper,
#adContainer,
#adContainer_1,
#adContainer_2,
#adContainer_3,
#adContent,
#adContentHolder,
#adContext,
#adDiv,
#adDiv0,
#adDiv1,
#adDiv300,
#adDiv4,
#adDiv728,
#adDivContainer,
#adFiller,
#adFlashDiv,
#adFooter,
#adFot,
#adFrame,
#adGallery,
#adGoogleText,
#adHeader,
#adHeaderTop,
#adHeaderWrapper,
#adHeading,
#adHeightstory,
#adHolder,
#adHolder1,
#adHolder2,
#adHolder3,
#adHolder4,
#adHolder5,
#adHolder6,
#adHome,
#adHomeTop,
#adIframe,
#adInhouse,
#adIsland,
#adLB,
#adLabel,
#adLarge,
#adLayer,
#adLayerTop,
#adLayout,
#adLeader,
#adLeaderTop,
#adLeaderboard,
#adLeaderboard-middle,
#adLeft,
#adLink,
#adLink1,
#adLounge,
#adLrec,
#adMOBILETOP,
#adMPU,
#adMPUHolder,
#adMain,
#adMarketplace,
#adMed,
#adMedRect,
#adMediumRectangle,
#adMeld,
#adMessage,
#adMid2,
#adMpu,
#adMpuBottom,
#adOuter,
#adPartnerLinks,
#adPlaceHolder1,
#adPlaceHolder2,
#adPlacement_1,
#adPlacement_2,
#adPlacement_3,
#adPlacement_4,
#adPlacement_7,
#adPlacement_8,
#adPlacement_9,
#adPlacer,
#adPopover,
#adPopup,
#adPosition0,
#adPosition14,
#adPosition5,
#adPosition6,
#adPosition7,
#adPosition9,
#adPush,
#adPushdown1,
#adReady,
#adRight,
#adRight1,
#adRight2,
#adRight3,
#adRight4,
#adRight5,
#adScraper,
#adSection,
#adSenseBox,
#adSenseModule,
#adSenseWrapper,
#adSet,
#adSide,
#adSide1-container,
#adSideButton,
#adSidebar,
#adSite,
#adSkin,
#adSkinBackdrop,
#adSkinLeft,
#adSkinRight,
#adSky,
#adSkyPosition,
#adSkyscraper,
#adSlider,
#adSlot-dmpu,
#adSlot-dontMissLarge,
#adSlot-leader,
#adSlot-leaderBottom,
#adSlot1,
#adSlot2,
#adSlot3,
#adSlot4,
#adSlug,
#adSpace,
#adSpaceBottom,
#adSpaceHeight,
#adSpacer,
#adSpecial,
#adSqb,
#adSquare,
#adStrip,
#adSuperbanner,
#adTag,
#adText,
#adTextLink,
#adTile,
#adTop,
#adTopContent,
#adTopLREC,
#adTopLarge,
#adTopModule,
#adTower,
#adUnderArticle,
#adUnit,
#adWideSkyscraper,
#adWrap,
#adWrapper,
#adWrapperSky,
#ad_1,
#ad_160,
#ad_160_600,
#ad_160_600_2,
#ad_160x160,
#ad_160x600,
#ad_2,
#ad_250,
#ad_250x250,
#ad_3,
#ad_300,
#ad_300_250,
#ad_300_250_1,
#ad_300x250,
#ad_336,
#ad_4,
#ad_468_60,
#ad_468x60,
#ad_5,
#ad_728,
#ad_728_90,
#ad_728x90,
#ad_8,
#ad_9,
#ad_B1,
#ad_Banner,
#ad_Bottom,
#ad_LargeRec01,
#ad_Middle,
#ad_Middle1,
#ad_Pushdown,
#ad_R1,
#ad_Right,
#ad_Top,
#ad_Wrap,
#ad__billboard,
#ad_ad,
#ad_adsense,
#ad_after_header_1,
#ad_anchor,
#ad_area,
#ad_article1_1,
#ad_article1_2,
#ad_article2_1,
#ad_article2_2,
#ad_article3_1,
#ad_article3_2,
#ad_banner,
#ad_banner_1,
#ad_banner_468x60,
#ad_banner_728x90,
#ad_banner_bot,
#ad_banner_top,
#ad_banners,
#ad_bar,
#ad_bar_rect,
#ad_before_header,
#ad_bg,
#ad_bg_image,
#ad_big,
#ad_bigbox,
#ad_bigbox_companion,
#ad_bigrectangle,
#ad_billboard,
#ad_block,
#ad_block_0,
#ad_block_1,
#ad_block_2,
#ad_block_mpu,
#ad_bnr_atf_01,
#ad_bnr_atf_02,
#ad_bnr_atf_03,
#ad_bnr_btf_07,
#ad_bnr_btf_08,
#ad_body,
#ad_bottom,
#ad_box,
#ad_box_top,
#ad_branding,
#ad_bsb,
#ad_bsb_cont,
#ad_btmslot,
#ad_button,
#ad_buttons,
#ad_cell,
#ad_center,
#ad_choices,
#ad_close,
#ad_closebtn,
#ad_comments,
#ad_cont,
#ad_cont_superbanner,
#ad_container,
#ad_container_0,
#ad_container_300x250,
#ad_container_side,
#ad_container_sidebar,
#ad_container_top,
#ad_content,
#ad_content_1,
#ad_content_2,
#ad_content_3,
#ad_content_fullsize,
#ad_content_primary,
#ad_content_right,
#ad_content_top,
#ad_content_wrap,
#ad_contentslot_1,
#ad_contentslot_2,
#ad_creative_2,
#ad_creative_3,
#ad_creative_5,
#ad_dfp_rec1,
#ad_display_300_250,
#ad_display_728_90,
#ad_div,
#ad_div_bottom,
#ad_div_top,
#ad_feedback,
#ad_foot,
#ad_footer,
#ad_footer1,
#ad_footerAd,
#ad_frame,
#ad_frame1,
#ad_from_bottom,
#ad_fullbanner,
#ad_gallery,
#ad_gallery_bot,
#ad_global_300x250,
#ad_global_above_footer,
#ad_global_header,
#ad_global_header1,
#ad_global_header2,
#ad_h3,
#ad_halfpage,
#ad_head,
#ad_header,
#ad_header_1,
#ad_header_container,
#ad_holder,
#ad_home,
#ad_home_middle,
#ad_horizontal,
#ad_houseslot_a,
#ad_houseslot_b,
#ad_hp,
#ad_img,
#ad_interthread,
#ad_island,
#ad_island2,
#ad_label,
#ad_large,
#ad_large_rectangular,
#ad_lateral,
#ad_layer,
#ad_ldb,
#ad_lead1,
#ad_leader,
#ad_leaderBoard,
#ad_leaderboard,
#ad_leaderboard_top,
#ad_left,
#ad_left_1,
#ad_left_2,
#ad_left_3,
#ad_left_skyscraper,
#ad_left_top,
#ad_leftslot,
#ad_link,
#ad_links,
#ad_links_footer,
#ad_lnk,
#ad_lrec,
#ad_lwr_square,
#ad_main,
#ad_main_leader,
#ad_main_top,
#ad_marginal,
#ad_marker,
#ad_mast,
#ad_med_rect,
#ad_medium,
#ad_medium_rectangle,
#ad_medium_rectangular,
#ad_mediumrectangle,
#ad_message,
#ad_middle,
#ad_middle_bottom,
#ad_midstrip,
#ad_mobile,
#ad_module,
#ad_mpu,
#ad_mpu2,
#ad_mpu300x250,
#ad_mrec,
#ad_mrec1,
#ad_mrec2,
#ad_mrec_intext,
#ad_mrec_intext2,
#ad_new,
#ad_news_article,
#ad_newsletter,
#ad_one,
#ad_overlay,
#ad_overlayer,
#ad_panel,
#ad_panorama_top,
#ad_pencil,
#ad_place,
#ad_placeholder,
#ad_player,
#ad_plugs,
#ad_popup_background,
#ad_popup_wrapper,
#ad_post,
#ad_poster,
#ad_primary,
#ad_publicidad,
#ad_rail,
#ad_rec_01,
#ad_rect,
#ad_rect1,
#ad_rect2,
#ad_rect3,
#ad_rect_body,
#ad_rect_bottom,
#ad_rect_btf_01,
#ad_rect_btf_02,
#ad_rect_btf_03,
#ad_rect_btf_04,
#ad_rect_btf_05,
#ad_rectangle,
#ad_region1,
#ad_region2,
#ad_region3,
#ad_region5,
#ad_results,
#ad_right,
#ad_right_box,
#ad_right_top,
#ad_rightslot,
#ad_rotator-2,
#ad_rotator-3,
#ad_row,
#ad_row_home,
#ad_rr_1,
#ad_sec,
#ad_sec_div,
#ad_secondary,
#ad_short,
#ad_sidebar,
#ad_sidebar1,
#ad_sidebar2,
#ad_sidebar3,
#ad_sidebar_1,
#ad_sidebar_left_container,
#ad_sidebar_news,
#ad_sidebar_top,
#ad_sidebody,
#ad_site_header,
#ad_sitebar,
#ad_skin,
#ad_slot,
#ad_slot_bottom,
#ad_slot_leaderboard,
#ad_small,
#ad_space_top,
#ad_sponsored,
#ad_spot_a,
#ad_spot_b,
#ad_spotlight,
#ad_square,
#ad_squares,
#ad_ss,
#ad_stck,
#ad_sticky_wrap,
#ad_strip,
#ad_superbanner,
#ad_table,
#ad_takeover,
#ad_tall,
#ad_tbl,
#ad_top,
#ad_topBanner,
#ad_topScroller,
#ad_top_728x90,
#ad_top_banner,
#ad_top_bar,
#ad_top_holder,
#ad_topbanner,
#ad_topmob,
#ad_topnav,
#ad_topslot,
#ad_two,
#ad_txt,
#ad_under_game,
#ad_unit,
#ad_unit1,
#ad_unit2,
#ad_vertical,
#ad_video_abovePlayer,
#ad_video_belowPlayer,
#ad_video_large,
#ad_video_root,
#ad_wallpaper,
#ad_wide,
#ad_wide_box,
#ad_wideboard,
#ad_widget,
#ad_widget_1,
#ad_window,
#ad_wp,
#ad_wp_base,
#ad_wrap,
#ad_wrapper,
#ad_wrapper1,
#ad_wrapper2,
#ad_xrail_top,
#ad_zone,
#adaptvcompanion,
#adb_bottom,
#adbackground,
#adbanner-container,
#adbanner1,
#adbannerbox,
#adbannerdiv,
#adbannerleft,
#adbannerright,
#adbannerwidget,
#adbar,
#adbig,
#adblade,
#adblade_ad,
#adblock-big,
#adblock-leaderboard,
#adblock-small,
#adblock1,
#adblock2,
#adblock4,
#adblockbottom,
#adbn,
#adbnr,
#adboard,
#adbody,
#adbottom,
#adbottomleft,
#adbottomright,
#adbox,
#adbox--hot_news_ad,
#adbox--page_bottom_ad,
#adbox--page_top_ad,
#adbox-inarticle,
#adbox-topbanner,
#adbox1,
#adbox2,
#adbox_content,
#adbox_right,
#adbutton,
#adbuttons,
#adcell,
#adcenter,
#adcenter2,
#adcenter4,
#adchoices-icon,
#adchoicesBtn,
#adclear,
#adclose,
#adcode,
#adcolContent,
#adcolumn,
#adcontainer,
#adcontainer1,
#adcontainer2,
#adcontainer3,
#adcontainer5,
#adcontainerRight,
#adcontainer_ad_content_top,
#adcontent,
#adcontent1,
#adcontent2,
#adcontextlinks,
#addbottomleft,
#addvert,
#adfactor-label,
#adfloat,
#adfooter,
#adfooter_728x90,
#adframe:not(frameset),
#adframetop,
#adfreeDeskSpace,
#adhalfpage,
#adhead,
#adheader,
#adhesion,
#adhesionAdSlot,
#adhesionUnit,
#adhide,
#adholder,
#adholderContainerHeader,
#adhome,
#adhomepage,
#adjacency,
#adlabel,
#adlabelFooter,
#adlabelfooter,
#adlabelheader,
#adlanding,
#adlayer,
#adlayerContainer,
#adlayerad,
#adleaderboard,
#adleft,
#adlinks,
#adlrec,
#adm-inline-article-ad-1,
#adm-inline-article-ad-2,
#admain,
#admasthead,
#admid,
#admobilefoot,
#admobilefootinside,
#admobilemiddle,
#admobiletop,
#admobiletopinside,
#admod2,
#admpubottom,
#admpubottom2,
#admpufoot,
#admpumiddle,
#admpumiddle2,
#admputop2,
#admsg,
#adnet,
#adnorth,
#ados1,
#ados2,
#ados3,
#ados4,
#adplace,
#adplacement,
#adpos-top,
#adpos2,
#adposition,
#adposition1,
#adposition10,
#adposition1_container,
#adposition2,
#adposition3,
#adposition4,
#adpositionbottom,
#adrect,
#adright,
#adright2,
#adrightbottom,
#adrightrail,
#adriver_middle,
#adriver_top,
#adrotator,
#adrow,
#adrow1,
#adrow3,
#ads-1,
#ads-125,
#ads-200,
#ads-250,
#ads-300,
#ads-300-250,
#ads-336x280,
#ads-468,
#ads-5,
#ads-728x90,
#ads-728x90-I3,
#ads-728x90-I4,
#ads-area,
#ads-article-left,
#ads-banner,
#ads-banner-top,
#ads-bar,
#ads-before-content,
#ads-bg,
#ads-bg-mobile,
#ads-billboard,
#ads-block,
#ads-blog,
#ads-bot,
#ads-bottom,
#ads-col,
#ads-container,
#ads-container-2,
#ads-container-anchor,
#ads-container-single,
#ads-container-top,
#ads-content,
#ads-content-double,
#ads-footer,
#ads-footer-inner,
#ads-footer-wrap,
#ads-google,
#ads-header,
#ads-header-728,
#ads-home-468,
#ads-horizontal,
#ads-inread,
#ads-inside-content,
#ads-leader,
#ads-leaderboard,
#ads-leaderboard1,
#ads-left,
#ads-left-top,
#ads-lrec,
#ads-main,
#ads-menu,
#ads-middle,
#ads-mpu,
#ads-outer,
#ads-pagetop,
#ads-panel,
#ads-pop,
#ads-position-header-desktop,
#ads-right,
#ads-right-bottom,
#ads-right-skyscraper,
#ads-right-top,
#ads-slot,
#ads-space,
#ads-superBanner,
#ads-text,
#ads-top,
#ads-top-728,
#ads-top-wrap,
#ads-under-rotator,
#ads-vertical,
#ads-vertical-wrapper,
#ads-wrap,
#ads-wrapper,
#ads1,
#ads120,
#ads125,
#ads1_box,
#ads2,
#ads2_block,
#ads2_box,
#ads2_container,
#ads3,
#ads300,
#ads300-250,
#ads300x200,
#ads300x250,
#ads300x250_2,
#ads336x280,
#ads4,
#ads468x60,
#ads50,
#ads7,
#ads728,
#ads728bottom,
#ads728top,
#ads728x90,
#ads728x90_2,
#ads728x90top,
#adsBar,
#adsBottom,
#adsContainer,
#adsContent,
#adsDisplay,
#adsHeader,
#adsHeading,
#adsLREC,
#adsLeft,
#adsLinkFooter,
#adsMobileFixed,
#adsMpu,
#adsPanel,
#adsRight,
#adsRightDiv,
#adsSectionLeft,
#adsSectionRight,
#adsSquare,
#adsTG,
#adsTN,
#adsTop,
#adsTopLeft,
#adsTopMobileFixed,
#adsZone,
#adsZone1,
#adsZone2,
#ads[style^="position: absolute; z-index: 30; width: 100%; height"],
#ads_0_container,
#ads_160,
#ads_3,
#ads_300,
#ads_300x250,
#ads_4,
#ads_728,
#ads_728x90,
#ads_728x90_top,
#ads_banner,
#ads_banner1,
#ads_banner_header,
#ads_belownav,
#ads_big,
#ads_block,
#ads_body_1,
#ads_body_2,
#ads_body_3,
#ads_body_4,
#ads_body_5,
#ads_body_6,
#ads_bottom,
#ads_box,
#ads_box1,
#ads_box2,
#ads_box_bottom,
#ads_box_right,
#ads_box_top,
#ads_button,
#ads_campaign,
#ads_catDiv,
#ads_center,
#ads_center_banner,
#ads_central,
#ads_combo2,
#ads_container,
#ads_content,
#ads_desktop_r1,
#ads_desktop_r2,
#ads_expand,
#ads_footer,
#ads_fullsize,
#ads_h,
#ads_h1,
#ads_h2,
#ads_halfsize,
#ads_header,
#ads_horiz,
#ads_horizontal,
#ads_horz,
#ads_in_modal,
#ads_in_video,
#ads_inline_z,
#ads_inner,
#ads_insert_container,
#ads_layout_bottom,
#ads_lb,
#ads_lb_frame,
#ads_leaderbottom,
#ads_left,
#ads_left_top,
#ads_line,
#ads_medrect,
#ads_notice,
#ads_overlay,
#ads_page_top,
#ads_place,
#ads_placeholder,
#ads_player,
#ads_popup,
#ads_right,
#ads_right_sidebar,
#ads_right_top,
#ads_slide_div,
#ads_space,
#ads_space_header,
#ads_superbanner1,
#ads_superbanner2,
#ads_superior,
#ads_td,
#ads_text,
#ads_textlinks,
#ads_title,
#ads_top,
#ads_top2,
#ads_top_banner,
#ads_top_container,
#ads_top_content,
#ads_top_right,
#ads_top_sec,
#ads_topbanner,
#ads_tower1,
#ads_tower_top,
#ads_vert,
#ads_video,
#ads_wide,
#ads_wrapper,
#adsbot,
#adsbottom,
#adsbox,
#adsbox-left,
#adsbox-right,
#adscenter,
#adscolumn,
#adscontainer,
#adscontent,
#adsdiv,
#adsection,
#adsense-2,
#adsense-468x60,
#adsense-area,
#adsense-bottom,
#adsense-container-bottom,
#adsense-header,
#adsense-link,
#adsense-links,
#adsense-middle,
#adsense-post,
#adsense-right,
#adsense-sidebar,
#adsense-tag,
#adsense-text,
#adsense-top,
#adsense-wrap,
#adsense1,
#adsense2,
#adsense468,
#adsense6,
#adsense728,
#adsenseArea,
#adsenseContainer,
#adsenseHeader,
#adsenseLeft,
#adsenseWrap,
#adsense_banner_top,
#adsense_block,
#adsense_bottom_ad,
#adsense_box,
#adsense_box2,
#adsense_center,
#adsense_image,
#adsense_inline,
#adsense_leaderboard,
#adsense_overlay,
#adsense_r_side_sticky_container,
#adsense_sidebar,
#adsense_top,
#adsenseheader,
#adsensehorizontal,
#adsensempu,
#adsenseskyscraper,
#adsensetext,
#adsensetop,
#adsensewide,
#adserv,
#adsframe_2,
#adside,
#adsimage,
#adsitem,
#adskeeper,
#adskinleft,
#adskinlink,
#adskinright,
#adskintop,
#adsky,
#adskyscraper,
#adskyscraper_flex,
#adsleft1,
#adslider,
#adslist,
#adslot-below-updated,
#adslot-download-abovefiles,
#adslot-half-page,
#adslot-homepage-middle,
#adslot-infobox,
#adslot-left-skyscraper,
#adslot-side-mrec,
#adslot-site-footer,
#adslot-site-header,
#adslot-sticky-headerbar,
#adslot-top-rectangle,
#adslot1,
#adslot2,
#adslot3,
#adslot300x250ATF,
#adslot300x250BTF,
#adslot4,
#adslot5,
#adslot6,
#adslot7,
#adslot_1,
#adslot_2,
#adslot_left,
#adslot_rect,
#adslot_top,
#adsmgid,
#adsmiddle,
#adsonar,
#adspace,
#adspace-1,
#adspace-2,
#adspace-300x250,
#adspace-728,
#adspace-728x90,
#adspace-bottom,
#adspace-leaderboard-top,
#adspace-one,
#adspace-top,
#adspace300x250,
#adspaceBox,
#adspaceRow,
#adspace_header,
#adspace_leaderboard,
#adspace_top,
#adspacer,
#adspan,
#adsplace1,
#adsplace2,
#adsplace4,
#adsplash,
#adspot,
#adspot-bottom,
#adspot-top,
#adsquare,
#adsquare2,
#adsright,
#adsside,
#adsspace,
#adstext2,
#adstrip,
#adtab,
#adtext,
#adtop,
#adtxt,
#adunit,
#adunit-article-bottom,
#adunit_video,
#adunitl,
#adv-01,
#adv-300,
#adv-Bottom,
#adv-BoxP,
#adv-Middle,
#adv-Middle1,
#adv-Middle2,
#adv-Scrollable,
#adv-Top,
#adv-TopLeft,
#adv-banner,
#adv-banner-r,
#adv-box,
#adv-companion-iframe,
#adv-container,
#adv-gpt-box-container1,
#adv-gpt-masthead-skin-container1,
#adv-halfpage,
#adv-header,
#adv-leaderblock,
#adv-leaderboard,
#adv-left,
#adv-masthead,
#adv-middle,
#adv-middle1,
#adv-midroll,
#adv-native,
#adv-preroll,
#adv-right,
#adv-right1,
#adv-scrollable,
#adv-sticky-1,
#adv-sticky-2,
#adv-text,
#adv-title,
#adv-top,
#adv-top-skin,
#adv300x250,
#adv300x250container,
#adv468x90,
#adv728,
#adv728x90,
#adv768x90,
#advBoxBottom,
#advCarrousel,
#advHome,
#advHook-Middle1,
#advRectangle,
#advRectangle1,
#advSkin,
#advTop,
#advWrapper,
#adv_300,
#adv_728,
#adv_728x90,
#adv_BoxBottom,
#adv_Inread,
#adv_IntropageOvl,
#adv_LdbMastheadPush,
#adv_Reload,
#adv_Skin,
#adv_bootom,
#adv_border,
#adv_center,
#adv_config,
#adv_contents,
#adv_footer,
#adv_holder,
#adv_leaderboard,
#adv_mob,
#adv_mpu1,
#adv_mpu2,
#adv_network,
#adv_overlay,
#adv_overlay_content,
#adv_r,
#adv_right,
#adv_skin,
#adv_sky,
#adv_textlink,
#adv_top,
#adv_wallpaper,
#adv_wallpaper2,
#advads_ad_widget-18,
#advads_ad_widget-19,
#advads_ad_widget-8,
#adver,
#adver-top,
#adverFrame,
#advert-1,
#advert-120,
#advert-2,
#advert-ahead,
#advert-article,
#advert-article-1,
#advert-article-2,
#advert-article-3,
#advert-banner,
#advert-banner-container,
#advert-banner-wrap,
#advert-banner2,
#advert-block,
#advert-boomer,
#advert-box,
#advert-column,
#advert-container-top,
#advert-display,
#advert-fireplace,
#advert-footer,
#advert-footer-hidden,
#advert-header,
#advert-island,
#advert-leaderboard,
#advert-left,
#advert-mpu,
#advert-posterad,
#advert-rectangle,
#advert-right,
#advert-sky,
#advert-skyscaper,
#advert-skyscraper,
#advert-slider-top,
#advert-text,
#advert-top,
#advert-top-banner,
#advert-wrapper,
#advert1,
#advert2,
#advertBanner,
#advertBox,
#advertBoxRight,
#advertBoxSquare,
#advertColumn,
#advertContainer,
#advertDB,
#advertOverlay,
#advertRight,
#advertSection,
#advertTop,
#advertTopLarge,
#advertTopSmall,
#advertTower,
#advertWrapper,
#advert_1,
#advert_banner,
#advert_belowmenu,
#advert_box,
#advert_container,
#advert_header,
#advert_leaderboard,
#advert_mid,
#advert_mpu,
#advert_right1,
#advert_sky,
#advert_top,
#advertblock,
#advertborder,
#adverticum_r_above,
#adverticum_r_above_container,
#adverticum_r_side_container,
#advertise,
#advertise-block,
#advertise-here,
#advertise-sidebar,
#advertise1,
#advertise2,
#advertiseBanner,
#advertiseLink,
#advertise_top,
#advertisediv,
#advertisement-300x250,
#advertisement-bottom,
#advertisement-content,
#advertisement-large,
#advertisement-placement,
#advertisement-text,
#advertisement1,
#advertisement2,
#advertisement3,
#advertisement728x90,
#advertisementArea,
#advertisementBox,
#advertisementHorizontal,
#advertisementRight,
#advertisementTop,
#advertisement_banner,
#advertisement_belowscreenshots,
#advertisement_block,
#advertisement_box,
#advertisement_container,
#advertisement_label,
#advertisement_notice,
#advertisement_title,
#advertisements_bottom,
#advertisements_sidebar,
#advertisements_top,
#advertisementsarticle,
#advertiser-container,
#advertiserLinks,
#advertisetop,
#advertising-160x600,
#advertising-300x250,
#advertising-728x90,
#advertising-banner,
#advertising-caption,
#advertising-container,
#advertising-right,
#advertising-skyscraper,
#advertising-top,
#advertisingHrefTop,
#advertisingLeftLeft,
#advertisingLink,
#advertisingRightColumn,
#advertisingRightRight,
#advertisingTop,
#advertisingTopWrapper,
#advertising_300,
#advertising_320,
#advertising_728,
#advertising__banner__content,
#advertising_column,
#advertising_container,
#advertising_contentad,
#advertising_div,
#advertising_header,
#advertising_holder,
#advertising_leaderboard,
#advertising_top_container,
#advertising_wrapper,
#advertisment-horizontal,
#advertisment-text,
#advertisment1,
#advertisment_content,
#advertisment_panel,
#advertleft,
#advertorial,
#advertorial-box,
#advertorial-wrap,
#advertorial1,
#advertorial_links,
#adverts,
#adverts--footer,
#adverts-top-container,
#adverts-top-left,
#adverts-top-middle,
#adverts-top-right,
#adverts_base,
#adverts_post_content,
#adverts_right,
#advertscroll,
#advertsingle,
#advertspace,
#advertssection,
#adverttop,
#advframe,
#advr_mobile,
#advsingle,
#advt,
#advt_bottom,
#advtbar,
#advtcell,
#advtext,
#advtop,
#advtopright,
#adwallpaper,
#adwidget,
#adwidget-5,
#adwidget-6,
#adwidget1,
#adwidget2,
#adwrapper,
#adxBigAd,
#adxBigAd2,
#adxLeaderboard,
#adxMiddle,
#adxMiddleRight,
#adxToolSponsor,
#adx_ad,
#adxtop2,
#adzbanner,
#adzone,
#adzone-middle1,
#adzone-middle2,
#adzone-right,
#adzone-top,
#adzone_content,
#adzone_wall,
#adzonebanner,
#adzoneheader,
#afc-container,
#affiliate_2,
#affiliate_ad,
#after-dfp-ad-mid1,
#after-dfp-ad-mid2,
#after-dfp-ad-mid3,
#after-dfp-ad-mid4,
#after-dfp-ad-top,
#after-header-ads,
#after-top-menu-ads,
#after_ad,
#after_bottom_ad,
#after_heading_ad,
#after_title_ad,
#amazon-ads,
#amazon_ad,
#analytics_ad,
#anchor-ad,
#anchorAd,
#aniview--player,
#aniview-ads,
#aom-ad-right_side_1,
#aom-ad-right_side_2,
#aom-ad-top,
#apiBackgroundAd,
#around-the-web,
#article-ad,
#article-ad-container,
#article-ad-content,
#article-ads,
#article-advert,
#article-aside-top-ad,
#article-billboard-ad-1,
#article-bottom-ad,
#article-box-ad,
#article-content-ad,
#article-footer-ad,
#article-footer-sponsors,
#article-island-ad,
#article-sidebar-ad,
#articleAd,
#articleAdReplacement,
#articleBoard-ad,
#articleBottom-ads,
#articleLeftAdColumn,
#articleSideAd,
#articleTop-ads,
#article_ad,
#article_ad_1,
#article_ad_3,
#article_ad_bottom,
#article_ad_container,
#article_ad_top,
#article_ad_w,
#article_adholder,
#article_ads,
#article_advert,
#article_banner_ad,
#article_body_ad1,
#article_box_ad,
#articlead1,
#articlead2,
#articlead300x250r,
#articleadblock,
#articlefootad,
#articletop_ad,
#aside-ad-container,
#asideAd,
#aside_ad,
#asideads,
#asinglead,
#ax-billboard,
#ax-billboard-bottom,
#ax-billboard-sub,
#ax-billboard-top,
#backad,
#background-ad-cover,
#background-adv,
#background_ad_left,
#background_ad_right,
#background_ads,
#backgroundadvert,
#banADbanner,
#banner-300x250,
#banner-468x60,
#banner-728,
#banner-728x90,
#banner-ad,
#banner-ad-container,
#banner-ad-large,
#banner-ads,
#banner-advert,
#banner-lg-ad,
#banner-native-ad,
#banner-skyscraper,
#banner300x250,
#banner468,
#banner468x60,
#banner728,
#banner728x90,
#bannerAd,
#bannerAdFrame,
#bannerAdTop,
#bannerAdWrap,
#bannerAdWrapper,
#bannerAds,
#bannerAdsense,
#bannerAdvert,
#bannerGoogle,
#banner_ad_bottom,
#banner_ad_footer,
#banner_ad_module,
#banner_ad_placeholder,
#banner_ad_top,
#banner_ads,
#banner_adsense,
#banner_adv,
#banner_advertisement,
#banner_adverts,
#banner_content_ad,
#banner_pos1_ddb_0,
#banner_pos2_ddb_0,
#banner_pos3_ddb_0,
#banner_pos4_ddb_0,
#banner_sedo,
#banner_slot,
#banner_spacer,
#banner_topad,
#banner_videoad,
#banner_wrapper_top,
#bannerad-bottom,
#bannerad-top,
#bannerad2,
#banneradrow,
#bannerads,
#banneradspace,
#banneradvert3,
#banneradvertise,
#bannerplayer-wrap,
#baseboard-ad,
#baseboard-ad-wrapper,
#bbContentAds,
#bb_ad_container,
#bb_top_ad,
#bbadwrap,
#before-footer-ad,
#below-listings-ad,
#below-menu-ad-header,
#below-post-ad,
#below-title-ad,
#belowAd,
#belowContactBoxAd,
#belowNodeAds,
#below_content_ad_container,
#belowad,
#belowheaderad,
#bg-custom-ad,
#bgad,
#big-box-ad,
#bigAd,
#bigAd1,
#bigAd2,
#bigAdDiv,
#bigBoxAd,
#bigBoxAdCont,
#big_ad,
#big_ad_label,
#big_ads,
#bigad,
#bigadbox,
#bigads,
#bigadspace,
#bigadspot,
#bigboard_ad,
#bigsidead,
#billboard-ad,
#billboard-atf,
#billboard_ad,
#bingadcontainer2,
#blkAds1,
#blkAds2,
#blkAds3,
#blkAds4,
#blkAds5,
#block-ad-articles,
#block-adsense-0,
#block-adsense-2,
#block-adsense-banner-article-bottom,
#block-adsense-banner-channel-bottom,
#block-adsenseleaderboard,
#block-advertisement,
#block-advertorial,
#block-articlebelowtextad,
#block-articlefrontpagead,
#block-articletopadvert,
#block-boxes-taboola,
#block-dfp-top,
#block-frontpageabovepartnersad,
#block-frontpagead,
#block-frontpagesideadvert1,
#block-google-ads,
#block-googleads3,
#block-googleads3-2,
#block-openads-0,
#block-openads-1,
#block-openads-13,
#block-openads-14,
#block-openads-2,
#block-openads-3,
#block-openads-4,
#block-openads-5,
#block-sponsors,
#block-taboolablock,
#blockAd,
#blockAds,
#block_ad,
#block_ad2,
#block_ad_container,
#block_advert,
#block_advert1,
#block_advert2,
#block_advertisement,
#blog-ad,
#blog-advert,
#blogad,
#blogad-wrapper,
#blogads,
#bm-HeaderAd,
#bn_ad,
#bnr-300x250,
#bnr-468x60,
#bnr-728x90,
#bnrAd,
#body-ads,
#bodyAd1,
#bodyAd2,
#bodyAd3,
#bodyAd4,
#body_ad,
#body_centered_ad,
#bottom-ad,
#bottom-ad-1,
#bottom-ad-area,
#bottom-ad-banner,
#bottom-ad-container,
#bottom-ad-leaderboard,
#bottom-ad-slot,
#bottom-ad-tray,
#bottom-ad-wrapper,
#bottom-add,
#bottom-adhesion,
#bottom-adhesion-container,
#bottom-ads,
#bottom-ads-bar,
#bottom-ads-container,
#bottom-adspot,
#bottom-advertising,
#bottom-boxad,
#bottom-not-ads,
#bottom-side-ad,
#bottom-sponsor-add,
#bottomAd,
#bottomAd300,
#bottomAdBlcok,
#bottomAdContainer,
#bottomAdSection,
#bottomAdSense,
#bottomAdSenseDiv,
#bottomAdWrapper,
#bottomAds,
#bottomAdvBox,
#bottomBannerAd,
#bottomContentAd,
#bottomDDAd,
#bottomLeftAd,
#bottomMPU,
#bottomRightAd,
#bottom_ad,
#bottom_ad_728,
#bottom_ad_area,
#bottom_ad_box,
#bottom_ad_region,
#bottom_ad_unit,
#bottom_ad_wrapper,
#bottom_adbox,
#bottom_ads,
#bottom_adwrapper,
#bottom_banner_ad,
#bottom_fixed_ad_overlay,
#bottom_leader_ad,
#bottom_player_adv,
#bottom_sponsor_ads,
#bottom_sponsored_links,
#bottom_text_ad,
#bottomad,
#bottomad300,
#bottomad_table,
#bottomadbanner,
#bottomadbar,
#bottomadholder,
#bottomads,
#bottomadsdiv,
#bottomadsense,
#bottomadvert,
#bottomadwrapper,
#bottomcontentads,
#bottomleaderboardad,
#bottommpuAdvert,
#bottommpuSlot,
#bottomsponad,
#bottomsponsoredresults,
#box-ad,
#box-ad-section,
#box-ad-sidebar,
#box-content-ad,
#box1ad,
#box2ad,
#boxAD,
#boxAd,
#boxAd300,
#boxAdContainer,
#boxAdvert,
#boxLREC,
#box_ad,
#box_ad_container,
#box_ad_middle,
#box_ads,
#box_advertisement,
#box_advertisment,
#box_articlead,
#box_text_ads,
#boxad,
#boxads,
#bpAd,
#br-ad-header,
#breadcrumb_ad,
#breakbarad,
#bsa_add_holder_g,
#bt-ad,
#bt-ad-header,
#btfAdNew,
#btm_ad,
#btm_ads,
#btmad,
#btnAdDP,
#btnAds,
#btnads,
#btopads,
#button-ads,
#button_ad_container,
#button_ads,
#buy-sell-ads,
#buySellAds,
#buysellads,
#carbon-ads-container-bg,
#carbonadcontainer,
#carbonads,
#carbonads-container,
#card-ads-top,
#category-ad,
#category-sponsor,
#cellAd,
#center-ad,
#center-ad-group,
#centerads,
#ch-ad-outer-right,
#ch-ads,
#channel_ad,
#channel_ads,
#circ_ad,
#circ_ad_holder,
#circad_wrapper,
#classifiedsads,
#clickforad,
#clientAds,
#closeAdsDiv,
#closeable-ad,
#cloudAdTag,
#cmg-video-player-placeholder,
#col-right-ad,
#colAd,
#colombiaAdBox,
#columnAd,
#commentAdWrapper,
#commentTopAd,
#comment_ad_zone,
#comments-ad-container,
#comments-ads,
#comments-standalone-mpu,
#compAdvertisement,
#companion-ad,
#companionAd,
#companionAdDiv,
#companion_Ad,
#companionad,
#component-taboola-below-article-feed,
#component-taboola-below-article-feed-2,
#component-taboola-below-homepage-feed,
#connatix,
#connatix-moveable,
#connatix_placeholder_desktop,
#container-ad,
#container_ad,
#content-ad,
#content-ad-side,
#content-ads,
#content-adver,
#content-contentad,
#content-header-ad,
#content-left-ad,
#content-right-ad,
#contentAd,
#contentAdSense,
#contentAdTwo,
#contentAds,
#contentBoxad,
#content_Ad,
#content_ad,
#content_ad_1,
#content_ad_2,
#content_ad_block,
#content_ad_container,
#content_ad_placeholder,
#content_ads,
#content_ads_top,
#content_adv,
#content_bottom_ad,
#content_bottom_ads,
#content_mpu,
#contentad,
#contentad-adsense-homepage-1,
#contentad-commercial-1,
#contentad-content-box-1,
#contentad-footer-tfm-1,
#contentad-lower-medium-rectangle-1,
#contentad-story-middle-1,
#contentad-superbanner-1,
#contentad-top-adsense-1,
#contentad-topbanner-1,
#contentadcontainer,
#contentads,
#contextad,
#contextual-ads,
#contextual-ads-block,
#contextualad,
#cornerad,
#coverads,
#criteoAd,
#crt-adblock-a,
#crt-adblock-b,
#ctl00_ContentPlaceHolder1_ucAdHomeRightFO_divAdvertisement,
#ctl00_ContentPlaceHolder1_ucAdHomeRight_divAdvertisement,
#ctl00_adFooter,
#ctl00_leaderboardAdvertContainer,
#ctl00_skyscraperAdvertContainer,
#ctl00_topAd,
#ctl00_ucFooter_ucFooterBanner_divAdvertisement,
#cubeAd,
#cube_ad,
#cube_ads,
#customAd,
#customAds,
#customad,
#darazAd,
#ddAdZone2,
#ddb_fluid_native_ddb_0,
#desktop-ad-top,
#desktop-sidebar-ad,
#desktop_middle_ad_fixed,
#desktop_top_ad_fixed,
#dfp-ad-bottom-wrapper,
#dfp-ad-container,
#dfp-ad-floating,
#dfp-ad-leaderboard,
#dfp-ad-leaderboard-wrapper,
#dfp-ad-medium_rectangle,
#dfp-ad-mediumrect-wrapper,
#dfp-ad-mpu1,
#dfp-ad-mpu2,
#dfp-ad-right1,
#dfp-ad-right1-wrapper,
#dfp-ad-right2,
#dfp-ad-right2-wrapper,
#dfp-ad-right3,
#dfp-ad-right4-wrapper,
#dfp-ad-slot2,
#dfp-ad-slot3,
#dfp-ad-slot3-wrapper,
#dfp-ad-slot4-wrapper,
#dfp-ad-slot5,
#dfp-ad-slot5-wrapper,
#dfp-ad-slot6,
#dfp-ad-slot6-wrapper,
#dfp-ad-slot7,
#dfp-ad-slot7-wrapper,
#dfp-ad-top-wrapper,
#dfp-ap-2016-interstitial,
#dfp-article-mpu,
#dfp-atf,
#dfp-atf-desktop,
#dfp-banner,
#dfp-banner-popup,
#dfp-billboard1,
#dfp-billboard2,
#dfp-btf,
#dfp-btf-desktop,
#dfp-footer-desktop,
#dfp-header,
#dfp-header-container,
#dfp-ia01,
#dfp-ia02,
#dfp-interstitial,
#dfp-leaderboard,
#dfp-leaderboard-desktop,
#dfp-masthead,
#dfp-middle,
#dfp-middle1,
#dfp-mtf,
#dfp-mtf-desktop,
#dfp-rectangle,
#dfp-rectangle1,
#dfp-ros-res-header_container,
#dfp-tlb,
#dfp-top-banner,
#dfpAd,
#dfp_ad_mpu,
#dfp_ads_4,
#dfp_ads_5,
#dfp_bigbox_2,
#dfp_bigbox_recipe_top,
#dfp_container,
#dfp_leaderboard,
#dfpad-0,
#dfpslot_tow_2-0,
#dfpslot_tow_2-1,
#dfrads-widget-3,
#dfrads-widget-6,
#dfrads-widget-7,
#dianomiNewsBlock,
#dict-adv,
#direct-ad,
#disable-ads-container,
#display-ads,
#displayAd,
#displayAdSet,
#display_ad,
#displayad_carousel,
#displayad_rectangle,
#div-ad-1x1,
#div-ad-bottom,
#div-ad-flex,
#div-ad-inread,
#div-ad-leaderboard,
#div-ad-r,
#div-ad-r1,
#div-ad-top,
#div-ad-top_banner,
#div-adcenter1,
#div-adcenter2,
#div-advert,
#div-contentad_1,
#div-footer-ad,
#div-gpt-LDB1,
#div-gpt-MPU1,
#div-gpt-MPU2,
#div-gpt-MPU3,
#div-gpt-Skin,
#div-gpt-inline-main,
#div-gpt-mini-leaderboard1,
#div-gpt-mrec,
#div-insticator-ad-1,
#div-insticator-ad-2,
#div-insticator-ad-3,
#div-insticator-ad-4,
#div-insticator-ad-5,
#div-insticator-ad-6,
#div-insticator-ad-9,
#div-leader-ad,
#div-social-ads,
#divAd,
#divAdDetail,
#divAdHere,
#divAdHorizontal,
#divAdLeft,
#divAdMain,
#divAdRight,
#divAdWrapper,
#divAds,
#divAdsTop,
#divAdv300x250,
#divAdvertisement,
#divDoubleAd,
#divFoldersAd,
#divFooterAd,
#divFooterAds,
#divSponAds,
#divSponsoredLinks,
#divStoryBigAd1,
#divThreadAdBox,
#divTopAd,
#divTopAds,
#divWrapper_Ad,
#div_ad_TopRight,
#div_ad_float,
#div_ad_holder,
#div_ad_leaderboard,
#div_advt_right,
#div_belowAd,
#div_bottomad,
#div_bottomad_container,
#div_googlead,
#divadfloat,
#dnn_adSky,
#dnn_adTop {
display: none !important;
}
/* Container for the accordion */
.faq-module__item {
  border: 1px solid #ccc; /* Border for the accordion item */
  margin-bottom: 10px; /* Space between accordion items */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Ensure content stays within the item */
  transition: all 0.3s ease; /* Smooth transition for accordion opening/closing */
}

.accordion-header {
  display: flex; /* Aligns header content (title and chevron) horizontally */
  justify-content: space-between; /* Space between title and chevron */
  padding: 10px 15px; /* Spacing inside the header */
  cursor: pointer; /* Pointer cursor on hover */
  background-color: #f9f9f9; /* Light background color */
  border-bottom: 1px solid #ddd; /* Divider between header and content */
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
}

.accordion-header:hover {
  background-color: #e6e6e6; /* Darker background color on hover */
}


.accordion-content.faq-module__hidden {
  display: none; /* Hides the content when the accordion is collapsed */
}

.faq-module__item.expanded .accordion-content {
  display: block; /* Show the content when expanded */
  animation: slideDown 0.3s ease; /* Slide down animation */
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-module__item.expanded .accordion-content {
  display: block; /* Display content when the item is expanded */
  transition: display 0.3s ease-in;
}

/* Chevron rotation */
.chevron svg {
  transition: transform 0.3s ease; /* Smooth transition for the chevron rotation */
}

.chevron svg.rotate {
  transform: rotate(180deg); /* Rotate chevron when expanded */
}

/* Chevron icon */
.chevron svg {
  transition: transform 0.3s ease; /* Smooth transition for the chevron rotation */
}

.chevron svg.rotate {
  transform: rotate(180deg); /* Rotate the chevron when expanded */
}

/* Show More button */
.Button-module__btn {
  background-color: #007bff; /* Primary button color */
  color: #fff; /* White text */
  border: none; /* No border */
  padding: 10px 15px; /* Button padding */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */ 
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
}

.Button-module__btn:hover {
  background-color: #f75656; /* Darker blue on hover */
  color: #fff;
}

.Button-module__btn:focus {
  outline: none; /* Remove focus outline */
}

.Button-module__btn svg {
  vertical-align: middle; /* Align the icon vertically with the text */
  margin-left: 5px; /* Space between text and icon */
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popupContainer {
  background: #fff;
  padding: 20px;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.popupHeader {
  display: flex;
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.listItemStyles-module__listItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

/* Container for the icon and text */
.listItemStyles-module__iconContainer {
  margin-right: 10px;
}

.listItemStyles-module__textContainer {
  text-align: left;
}

/* Heading for policy */
.listItemStyles-module__listHeading {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left; /* Align heading to the left */
}

/* Subheading for policy details */
.listItemStyles-module__listSubHeading {
  text-align: left; /* Align subheading to the left */
  font-size: 0.9em;
  color: #666;
}

/* Grow div for spacing */
.listItemStyles-module__growDiv {
  flex-grow: 1;
}

/* View button at the right */
.listItemStyles-module__listHeadingAnchor {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}



.popupContent {
  padding: 20px 0;
}

.popupFooter {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.gotItButton {
  background-color: #f50057;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Chevron icon */
.chevron svg {
  transition: transform 0.3s ease;
}

/* Rotate chevron when accordion is expanded */
.chevron svg.rotate {
  transform: rotate(180deg);
}

.StepCard-module__stepCardInner,
.StepCard-module__stepCardOuter {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding: 7px;

}