.apollo-container {
    max-width: 800px;
    margin: 0 auto;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.apollo-heading {
    text-align: center;
    color: #333;
    font-family: 'NASA', sans-serif;
    margin-bottom: 20px;
}

.apollo-form {
    display: flex;
    flex-direction: column;
}

.apollo-input, .apollo-select, .apollo-file-input {
    padding: 12px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    transition: all 0.3s ease;
}

.apollo-input:focus, .apollo-select:focus {
    border-color: #ff5a5f;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}

.apollo-button {
    padding: 12px;
    margin-top: 20px;
    background-color: #ff5a5f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.apollo-button:hover {
    background-color: #fd3b41;
}

.apollo-label {
    margin-top: 15px;
    font-weight: bold;
    color: #555;
}

.apollo-label-center {
    text-align: center;
    margin-top: 20px;
}

/* recently added 30 nov 2024 */
.apollo-variation-group {
    display: flex;
    flex-direction: row; /* Display variations in a row */
    gap: 15px; /* Add space between each variation */
    flex-wrap: wrap; /* Allow wrapping to a new line if needed */
    margin-top: 8px; /* Space from the main room type */

    
}


.apollo-variation-group .apollo-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.apollo-variation-group .apollo-checkbox.selected {
    background-color: #ff5a5f; /* Highlight selected variations */
    font-weight: bold; /* Emphasize selected items */
    color: #f9f9f9; /* Darken text color for selected */
}

.apollo-checkbox.deselected {
    background-color: #f9f9f9;
    color: #333;
    border-color: #333;
}

.apollo-checkbox.selected {
    background-color: #ff5a5f; /* Green background */
    color: white;
    border-color: #ff5a5f;
}


.apollo-trash-icon {
    color: #ff5a5f; /* Customize color as needed */
    margin-left: 10px;
    font-size: 14px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s ease;
}

.apollo-trash-icon:hover {
    color: #d9534f; /* Change color on hover for effect */
}
/* recently added 30 nov 2024 */
.apollo-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.apollo-checkbox {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 1px solid transparent;
}

.apollo-checkbox-input {
    display: none; /* Hide the default checkbox */
    
}

.apollo-checkbox.selected {
    background-color: #ff5a5f; /* Blue for selected */
    color: #fff;
    border-color: #fd3b41;
}

.apollo-checkbox:hover {
    background-color: #d1d1d1;
}

.apollo-form-group {
    display: flex;
    align-items: center;
    margin: 10px 0;
    text-align: left;
}

.apollo-label {
    width: auto; /* Adjust as needed */
    text-align: left;
    font-weight: bold;
}

.apollo-image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.apollo-image-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.apollo-image {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
