@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap');

*, *::after, *::before {
    margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font: inherit;


}
/* 
body {
  height:100vh;
  background-color: hsl(240, 19%, 12%);
  font-family: 'Quicksand', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center
} */

/* variables */
:root {
  /* colors */
  --ri5-color-primary-hsl: 242, 69%, 52%;
  --ri5-color-bg-hsl: 0, 0%, 100%;
  --ri5-color-contrast-high-hsl: 230, 7%, 23%;
  --ri5-color-contrast-higher-hsl: 230, 13%, 9%;
  --ri5-color-bg-darker-hsl: 240, 4%, 90%;
  --ri5-color-white-hsl: 0, 0%, 100%;

  /* typography */
  --ri5-text-sm: 0.833rem;
  
  --radio-switch-width: 186px;
  --radio-switch-height: 46px;
  --radio-switch-padding: 1px;
  --radio-switch-radius: 100vw;
  --radio-switch-animation-duration: 0.3s;
}

.radio-switch4 {
  position: relative;
  display: inline-flex;
  height: 45px;
  padding: var(--radio-switch-padding);
  border-radius: calc(var(--radio-switch-radius) * 1.4);
  background-color: hsl(var(--ri5-color-bg-darker-hsl));
}
.radio-switch4:focus-within, .radio-switch4:active {
  box-shadow: 0 0 0 2px hsla(var(--ri5-color-contrast-higher-hsl), 0.15);
}

.radio-switch__item4 {
  position: relative;
  display: inline-block;
  height: calc(var(--radio-switch-height) - 2*var(--radio-switch-padding));
  width: calc(var(--radio-switch-width)*0.5 - var(--radio-switch-padding));
}

.radio-switch__label4 {
  position: relative;
  z-index: 2;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight:700;
  border-radius: var(--radio-switch-radius);
  cursor: pointer;
  font-size: var(--ri5-text-sm);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all var(--radio-switch-animation-duration);
}
.radio-switch__input4:checked ~ .radio-switch__label4 {
  color: hsl(var(--ri5-color-white-hsl));
}
.radio-switch__input4:focus ~ .radio-switch__label4 {
  background-color: hsla(var(--ri5-color-primary-hsl), 0.6));
}
.radio-switch__label4 :not(*):focus-within4, .radio-switch__input4:focus ~ .radio-switch__label4 {
  background-color: transparent;
}

.radio-switch__marker4 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -100%;
  border-radius: var(--radio-switch-radius);
  background-color: #ff5a5f;
  height: calc(var(--radio-switch-height) - 2*var(--radio-switch-padding));
  width: calc(var(--radio-switch-width)*0.5 - var(--radio-switch-padding));
  box-shadow: 0 0.9px 1.5px rgba(0, 0, 0, 0.03),0 3.1px 5.5px rgba(0, 0, 0, 0.08),0 14px 25px rgba(0, 0, 0, 0.12);
  transition: -webkit-transform var(--radio-switch-animation-duration);
  transition: transform var(--radio-switch-animation-duration);
  transition: transform var(--radio-switch-animation-duration), -webkit-transform var(--radio-switch-animation-duration);
}
.radio-switch__input4:checked ~ .radio-switch__marker4 {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

/* utility classes */
.ri5-sr-only4 {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}