.ocean-slideshow-container {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .spring-large-image-container {
    position: relative;
    flex: 3;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .spring-large-image-container img {
    width: 100%;
    display: none;
  }
  
  .spring-large-image-container img.spring-active {
    display: block;
  }
  
  .spring-arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .spring-arrow.left {
    left: 10px;
  }
  
  .spring-arrow.right {
    right: 10px;
  }
  
  .pond-thumbnail-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
  }
  
  .pond-thumbnail {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .pond-thumbnail:hover {
    transform: scale(1.05);
  }
  
  .lake-modal {
    display: flex;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
  }
  
  .lake-modal-content {
    position: relative;
    max-width: 90%;
    max-height: 80%;
  }
  
  .lake-modal-image {
    width: 60%;
    border-radius: 10px;
  }
  
  .lake-modal-close {
    position: absolute;
    top: 10px;
    width: 40px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
  
  .lake-modal-arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .lake-modal-arrow.left {
    left: 130px;
  }
  
  .lake-modal-arrow.right {
    right: 130px;
  }
  
  @media (max-width: 768px) {
    .spring-large-image-container {
      max-height: 250px; /* Limits height for smaller screens */
      width: 60%; /* Ensures container fits within mobile width */
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for mobile */
    }
  
    .spring-large-image-container img {
      width: 100%;
      height: auto; /* Allows image to scale proportionally */
      object-fit: cover; /* Ensures the image fills the container without distortion */
    }
  }