.mack-popup-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 180px; /* Increase the width from '100%' to a fixed or larger size */
  display: flex;
  flex-direction: column;
}
.leaflet-popup-content-wrapper{
  width: 220px; /* Increase the width from '100%' to a fixed or larger size */

}

.mack-image-wrapper {
  position: relative;
}

.mack-popup-image {
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.mack-rating-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.mack-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
}

.mack-star-icon {
  height: 16px;
  width: 16px;
}

.volvo-popup-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.volvo-popup-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.peterbilt-property-distance {
  display: flex;
  align-items: center;
  gap: 8px;
}

.kenworth-pin-image {
  width: 24px;
  height: 24px;
}

.scania-distance-from-center {
  font-size: 12px;
  color: #555;
}

.freightliner-distance-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.freightliner-distance-item {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #777;
}

.mack-dot-separator {
  font-size: 14px;
  color: #ccc;
}

.volvo-popup-price {
  font-size: 16px;
  color: #222;
}

.volvo-popup-price strong {
  color: #27ae60; /* Adjust this color to match your brand */
}
