
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Default flex layout for larger screens */
.transport-container {
    display: flex;
    justify-content: space-between;
    padding-left: 350px;
    padding-right: 350px;
    width: 100%;
    gap: 10px;
}

/* Transport form adjustments */
.transport-container > div {
    width: 60%;
}

.transport-tc {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc; /* Add a subtle border */
    border-radius: 5px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background for better visibility */
    font-family: Arial, sans-serif; /* Consistent font */
    line-height: 1.5; /* Improved readability */
    text-align: left; /* Align text to the left */
    width: 150%; /* Take up full width of the container */
    max-width: none; /* Remove any maximum width constraint */
    font-size: 16px; /* Base font size */
}

/* Center the T&Cs if it overflows */
.transport-container .transport-tc {
    margin: 0 auto;
}


.transport-tc h3 {
    margin-bottom: 15px;
    color: #333; /* Heading color */
    font-size: 16px; /* Base font size */
}
.transport-tc ul {
    list-style-type: disc; /* Use bullet points */
    margin-left: 20px;
    padding-left: 0; /* Align bullets properly */
    font-size: 16px; /* Base font size */
}

.transport-tc li {
    margin-bottom: 10px;
    color: #555; /* Slightly lighter color for text */
    font-size: 16px; /* Base font size */
    display: block; /* Ensure each <li> is a block element */
    width: auto; /* Let it naturally expand */
}
/* Style for the direction buttons */
.direction-btn label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.direction-btn span {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.direction-btn input[type="radio"]:checked + span {
    background-color: #ff5a5f;
    color: #fff;
}

/* Styles for the date selection container */
.date-selection-container {
    margin-top: 20px;
}

.date-selection-buttons {
    display: grid;
    gap: 10px;
    margin-top: 10px;
    grid-template-columns: repeat(3, 1fr); /* Default: 3 buttons per row */
}

/* Style for each button */
.date-button {
    background-color: #f0f0f0;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 30px;
    cursor: pointer;
    font-size: 10px;
    padding: 10px 0;
    text-align: center;
    transition: background-color 0.3s;
}

/* Style for the selected date button */
.date-button.selected {
    background-color: #ff5a5f;
    color: #fff;
}

/* Container styles */
.container {
    width: 100%; /* Full width of the screen */
    max-width: 100%; /* Ensure no overflow */
    margin: 0 auto; /* Center the content */
    padding: 10px; /* Add some padding for spacing */
}

/* For mobile screens (max width of 600px) */
@media (max-width: 600px) {
    .container {
        padding: 5px; /* Reduce padding for mobile */
    }

    .date-selection-buttons {
        grid-template-columns: repeat(3, 1fr); /* 3 buttons per row on mobile */
    }

    .date-button {
        font-size: 12px; /* Increase font size for readability */
        padding: 10px 0; /* Adjust padding for touch-friendly buttons */
    }
}

/* For very small screens (max width of 400px) */
@media (max-width: 400px) {
    .date-selection-buttons {
        grid-template-columns: repeat(2, 1fr); /* 2 buttons per row for very small screens */
    }
    
    .container {
        padding: 5px; /* Ensure the container fits the screen */
    }
}


/* Time and date selection buttons */
button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

button.selected {
    background-color: #ff5a5f;
    color: #fff;
}



/* Table styles for mobile */
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

th {
    background-color: #f8f8f8;
    text-align: left;
}
/* For mobile screens, stack the T&Cs below the form */
@media (max-width: 768px) {
    .transport-container {
        flex-direction: column;
        width: 100%;
    }

    .transport-tc {
        margin-top: 10px;
        padding-left: 0;
        border-left: none;
    }

    /* Adjust form width for mobile */
    .transport-container > div {
        width: 100%;
    }

    /* Buttons full-width on mobile */
    button {
        width: 100%;
        margin-bottom: 10px;
    }

    /* Stack the direction buttons vertically */
    .direction-btn {
        flex-direction: column;
        align-items: flex-start;
    }

    /* Make inputs longer (full width) on mobile */
    
}

/* Table styles for mobile */
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

th {
    background-color: #f8f8f8;
    text-align: left;
}

@media (max-width: 800px) {
    .transport-container {
        padding: 10px;
    }

    table {
        font-size: 12px;
    }

    th, td {
        padding: 8px;
    }

    /* Add space between selects */
    .mainslect {
        display: flex;
        gap: 60px; /* Add gap between select elements */
        flex-wrap: nowrap; /* Ensure the selects stay on the same row */
    }

    /* Ensure select elements are 150% width */
    .selectpro {
        flex: 1 ;
        text-align: center;
        border-radius: 30px;
        width: 150%; /* Increase the width to 150% */
        font-size: 12px;
    }
}

