.event-ticket {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 10px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group-inline {
    display: flex;
    justify-content: space-between;
  }
  
  .full-name {
    width: 100%;
  }
  
  .form-group-inline .form-group {
    width: 48%;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #ff5a5f;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #ff3037;
  }
  