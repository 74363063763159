/* General Styles specific to this page */
.dashboard-page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dashboard-page {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Contain the pseudo-element */
    background-color: #fff; /* Fallback color */
    z-index: 0; /* Ensure it's below content */
}

.dashboard-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./doddles.jpg);
    background-position: center;
    background-size: contain;
    opacity: 0.1; /* Set the opacity for the background image */
    z-index: -1; /* Place it behind the content */
}

/* Group Transport Schedules */
.transport-group {
    display: flex;
    flex-direction: column; /* Stack the cards vertically */
    gap: 10px; /* Add spacing between cards */
    height: 40%;
}

/* Desktop view - side by side */
@media (min-width: 769px) {
    .transport-group {
        flex-direction: column; /* Ensure stacking on smaller screens */
    }
}

/* Styling for the transport cards */
.transport-group .card {
    width: 100%; /* Ensure the cards take the full container width */
}

/* Styles for the header */
.dashboard-header {
    position: absolute;
    top: 20px; /* Fixed distance from the top of the page */
    left: 20px; /* Fixed distance from the left side */
    margin-bottom: 20px; /* Adds space below the welcome message */
    width: auto; /* Adjust width automatically based on content */
}
.welcome-message {
    font-weight: 600;
    text-align: left;
    font-size: 32px;
    color: #333;
    position: relative;
    right: 0%;
    text-align: left; /* Aligns the text to the left */
    margin-bottom: 20px; /* Adds space between the welcome message and first-card */
    padding: 0 20px; /* Adds some padding for mobile or desktop */
}

@media (max-width: 768px) {
    .welcome-message {
        text-align: left;
        position: relative;
        right: 0%;
        font-weight: 600;/* Centers the text on mobile devices */
        font-size: 34px; /* Adjusts font size for mobile */
    }
}




/* Dashboard styling remains the same */
.dashboard-page .dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
    gap: 10px; /* Spacing between cards */
    padding: 20px;
    width: 100%; /* Full width */
    max-width: 1000px; /* Limit max width */
    margin-top: 0px; /* Adds space below the fixed header */
}

/* The rest of the dashboard styling remains the same */
.dashboard-page .card {
    background-color: #ff5a609c;
    position: relative;
    padding: 10px;
    height: 250px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(255, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}


.dashboard-page .card h2,
.dashboard-page .card small {
    position: relative;
    z-index: 2;
}

.dashboard-page .card h2 {
    font-size: 17px;
    color: red;
}

.dashboard-page small {
    position: relative;
    z-index: 2;
    color: rgb(255, 0, 0);
    margin-top: auto;
}


.dashboard-page small .smally {
    position: relative;
    z-index: 2;
    color: rgb(255, 255, 255);
    margin-top: auto;
}

.dashboard-page .card-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* Adding black gradient overlay on the image */
.dashboard-page .first-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
    z-index: 2; /* Place the gradient above the image but below the text */
    border-radius: 10px; /* Keep the border-radius consistent with the card */
}

/* First card spans two columns */
.dashboard-page .first-card {
    grid-column: span 2; /* Make the first card span two columns */
    width: 100%;
    position: relative;
    overflow: hidden;
}

/* Buttons in the first card */
.dashboard-page .card-buttons {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px; /* Space between buttons */
    z-index: 5; /* Ensure buttons are above the gradient */
}

.dashboard-page .button-primary,
.dashboard-page .button-secondary {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    z-index: 6;
}

.dashboard-page .button-primary {
    background-color: #ff5a5f;
    color: white;
}

.dashboard-page .button-secondary {
    background-color: #f1f1f12f;
    color: black;
    border: 1px solid #ccc;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .dashboard-page .dashboard {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }
    .dashboard-page .card-buttons {
        display: flex;
        flex-direction: column; /* Stacks buttons on top of each other */
        bottom: 10px;
        right: 10px;
    }

    .dashboard-page .button-primary,
    .dashboard-page .button-secondary {
        width: 100%; /* Full width buttons */
        margin-bottom: 10px; /* Add space between buttons */
    }
    .dashboard-page .card {
        padding: 15px;
        width: auto;
    }

    .dashboard-page .first-card {
        grid-column: span 2; /* First card still spans two columns */
    }

    /* Styles for the header */
/* Styles for the header */

}

@media (max-width: 480px) {
    .dashboard-page .dashboard {
        grid-template-columns: 1fr; /* 1 column on mobile screens */
    }

    .dashboard-page .card {
        width: auto;
    }

    .dashboard-page .first-card {
        grid-column: span 1; /* First card spans 1 column on small screens */
    }
}
