/* Container and Form */
.ducati-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .ducati-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 2em;
    font-weight: bold;
  }
  
  .ducati-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Input Fields and Labels */
  .yamaha-field,
  .kawasaki-field,
  .harley-field,
  .triumph-field,
  .suzuki-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .yamaha-label,
  .harley-label,
  .triumph-label,
  .suzuki-label {
    font-size: 1em;
    font-weight: bold;
    color: #555;
  }
  
  .yamaha-select,
  .harley-select,
  .triumph-select,
  .kawasaki-input,
  .suzuki-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    background-color: #fff;
    transition: border-color 0.3s;
  }
  
  .yamaha-select:focus,
  .harley-select:focus,
  .triumph-select:focus,
  .kawasaki-input:focus,
  .suzuki-input:focus {
    border-color: #ff5a5f;
    outline: none;
  }
  
  /* Button */
  .ducati-button {
    background-color: #ff5a5f;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ducati-button:hover {
    background-color: #ff272e;
  }
  
  /* Image Previews */
  .bmw-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .bmw-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .bmw-label {
    font-size: 0.9em;
    color: #555;
  }
  
  .bmw-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* General Styling */
  select,
  input {
    width: 100%;
  }
  
  @media (max-width: 600px) {
    .ducati-container {
      padding: 10px;
    }
  
    .bmw-image {
      width: 100px;
      height: 100px;
    }
  }
  