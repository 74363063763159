At the end of amber rooms add this with the faw module item


/* Container for the accordion */
.faq-module__item {
  border: 1px solid #ccc; /* Border for the accordion item */
  margin-bottom: 10px; /* Space between accordion items */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Ensure content stays within the item */
  transition: all 0.3s ease; /* Smooth transition for accordion opening/closing */
}

.accordion-header {
  display: flex; /* Aligns header content (title and chevron) horizontally */
  justify-content: space-between; /* Space between title and chevron */
  padding: 10px 15px; /* Spacing inside the header */
  cursor: pointer; /* Pointer cursor on hover */
  background-color: #f9f9f9; /* Light background color */
  border-bottom: 1px solid #ddd; /* Divider between header and content */
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
}

.accordion-header:hover {
  background-color: #e6e6e6; /* Darker background color on hover */
}


.accordion-content.faq-module__hidden {
  display: none; /* Hides the content when the accordion is collapsed */
}

.faq-module__item.expanded .accordion-content {
  display: block; /* Show the content when expanded */
  animation: slideDown 0.3s ease; /* Slide down animation */
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-module__item.expanded .accordion-content {
  display: block; /* Display content when the item is expanded */
  transition: display 0.3s ease-in;
}

/* Chevron rotation */
.chevron svg {
  transition: transform 0.3s ease; /* Smooth transition for the chevron rotation */
}

.chevron svg.rotate {
  transform: rotate(180deg); /* Rotate chevron when expanded */
}

/* Chevron icon */
.chevron svg {
  transition: transform 0.3s ease; /* Smooth transition for the chevron rotation */
}

.chevron svg.rotate {
  transform: rotate(180deg); /* Rotate the chevron when expanded */
}

/* Show More button */
.Button-module__btn {
  background-color: #007bff; /* Primary button color */
  color: #fff; /* White text */
  border: none; /* No border */
  padding: 10px 15px; /* Button padding */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */ 
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
}

.Button-module__btn:hover {
  background-color: #f75656; /* Darker blue on hover */
  color: #fff;
}

.Button-module__btn:focus {
  outline: none; /* Remove focus outline */
}

.Button-module__btn svg {
  vertical-align: middle; /* Align the icon vertically with the text */
  margin-left: 5px; /* Space between text and icon */
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popupContainer {
  background: #fff;
  padding: 20px;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.popupHeader {
  display: flex;
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.listItemStyles-module__listItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

/* Container for the icon and text */
.listItemStyles-module__iconContainer {
  margin-right: 10px;
}

.listItemStyles-module__textContainer {
  text-align: left;
}

/* Heading for policy */
.listItemStyles-module__listHeading {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left; /* Align heading to the left */
}

/* Subheading for policy details */
.listItemStyles-module__listSubHeading {
  text-align: left; /* Align subheading to the left */
  font-size: 0.9em;
  color: #666;
}

/* Grow div for spacing */
.listItemStyles-module__growDiv {
  flex-grow: 1;
}

/* View button at the right */
.listItemStyles-module__listHeadingAnchor {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}



.popupContent {
  padding: 20px 0;
}

.popupFooter {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.gotItButton {
  background-color: #f50057;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Chevron icon */
.chevron svg {
  transition: transform 0.3s ease;
}

/* Rotate chevron when accordion is expanded */
.chevron svg.rotate {
  transform: rotate(180deg);
}

.StepCard-module__stepCardInner,
.StepCard-module__stepCardOuter {
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding: 7px;

}


 .amber-1td2ujr {
      height: 32px;
      margin-bottom: 16px;
    }

    .amber-26ugy1 {
      width: 100%;
    }

    .amber-1u7mdpe {
      background: #F9FAFB;
    }

    .amber-ww49a2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-1inun54 {
      overflow: hidden;
    }

    .amber-1gnwfpi {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-3xbgk5 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      height: 100%;
      overflow: visible;
    }

    .amber-qo1k2 {
      white-space: nowrap;
      height: 100%;
      overflow: hidden;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .amber-1rky12q {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-right: 10px;
      margin-right: 8px !important;
    }

    .amber-1xvd4ht {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: 10px;
      margin-left: 8px !important;
    }

    .amber-1g4orza {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-right: 10px;
    }

    .amber-xlwgkm {
      width: 100%;
      height: 100%;
    }

    .amber-1gsulff {
      overflow: hidden;
      height: 100dvh;
    }

    .amber-1fr50if {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-15c5iu2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 24px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-b96ju {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 12px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-18l7ils {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 8px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-19rrogg {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 10px;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-aqcqna {
      border: 0;
      border-top-width: 1px;
      border-top-color: #FFFFFF1A;
      border-top-style: solid;
      margin: 0;
    }

    .amber-1oyt3lp {
      align-self: center;
      font-size: 10px;
      margin-right: 40px;
    }

    .amber-1jpq36m {
      padding-top: 34px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-qqmv3w {
      position: relative;
    }

    .amber-qyfg00 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-right: -12px;
    }

    @media (max-width: 767px) {
      .amber-qyfg00 {
        margin-right: -12px;
      }
    }

    @media (max-width: 479px) {
      .amber-qyfg00 {
        margin-right: 0;
      }
    }

    .amber-iaiq1l {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: calc(50% - 13px);
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
      pointer-events: none;
    }

    .amber-12sbrde {
      position: relative;
    }

    .amber-1xfrfq6 {
      position: relative;
      -webkit-flex: 0 0 40%;
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      padding-right: 12px;
    }

    @media (max-width: 767px) {
      .amber-1xfrfq6 {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        padding-right: 12px;
      }
    }

    @media (max-width: 479px) {
      .amber-1xfrfq6 {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding-right: 0;
      }
    }

    .amber-v4lv9f {
      color: #000;
      border-bottom: 1px solid #dee2e6;
    }

    .amber-1y791cp {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-transition: -webkit-transform 200ms ease;
      transition: transform 200ms ease;
      margin-right: 0;
      margin-left: 16px;
      width: 24px;
      min-width: 24px;
    }

    .amber-1y791cp[data-rotate] {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .amber-pildck {
      color: inherit;
      font-weight: 400;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .amber-7qituf {
      padding: 12px;
      padding-top: calc(4px / 2);
    }

    .amber-1xlvxro {
      background-color: white;
    }

    .amber-1ej9d2m {
      margin-left: 0px;
      min-width: 86px;
      width: 86px;
    }

    .amber-y8iz9w {
      margin: 0px 16px 72px 16px;
      padding-top: 56px;
    }

    @media (min-width: 768px) {
      .amber-y8iz9w {
        margin: 0px 80px;
        padding-bottom: 56px;
      }
    }

    @media (min-width: 1441px) {
      .amber-y8iz9w {
        margin: 0px auto;
        padding-bottom: 56px;
        max-width: 1440px;
      }
    }

    @media (min-width: 1601px) {
      .amber-y8iz9w {
        max-width: 1640px;
      }
    }

    .amber-bh7re6 {
      padding-bottom: 33px;
    }

    @media (min-width: 768px) {
      .amber-bh7re6 {
        padding-bottom: 0;
      }
    }

    .amber-e7eabn {
      margin: -6px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: stretch;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    .amber-1afic7u {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 6px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
    }

    @media (min-width: 390px) {
      .amber-1afic7u {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-1afic7u {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1afic7u {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-1afic7u {
        -webkit-flex-basis: 35%;
        -ms-flex-preferred-size: 35%;
        flex-basis: 35%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 35%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-1afic7u {
        -webkit-flex-basis: 30%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 30%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1afic7u {
        width: 68%;
        padding-right: 4%;
      }
    }

    .amber-4lvtkj {
      width: 2px;
      height: 28px;
      background-color: #D1D5DB;
    }

    .amber-10291i3 {
      color: #111928;
      font-weight: 500;
    }

    .amber-56j2tg {
      background: #F9FAFB;
      padding: 16px 20px;
      border-radius: 8px;
      margin-top: 16px;
      display: block;
      cursor: pointer;
    }

    @media (min-width: 1024px) {
      .amber-56j2tg {
        display: block;
        width: auto;
        height: auto;
      }
    }

    .amber-75zui2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 12px;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-flex-direction: col;
      -ms-flex-direction: col;
      flex-direction: col;
    }

    .amber-1lfkgv5 {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 6px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
    }

    @media (min-width: 390px) {
      .amber-1lfkgv5 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-1lfkgv5 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1lfkgv5 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 50%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-1lfkgv5 {
        -webkit-flex-basis: 30%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 30%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-1lfkgv5 {
        -webkit-flex-basis: 30%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 30%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    .amber-1duln8m {
      margin: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: stretch;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    .amber-176pmgx {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 50%;
      margin-bottom: 12px;
    }

    @media (min-width: 390px) {
      .amber-176pmgx {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-176pmgx {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-176pmgx {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-176pmgx {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-176pmgx {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-1yiiosv {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 50%;
    }

    @media (min-width: 390px) {
      .amber-1yiiosv {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-1yiiosv {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1yiiosv {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-1yiiosv {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-1yiiosv {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-137asuq {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
      font-size: 12px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #6B7280;
      margin-bottom: 17px;
      padding-right: 10px;
    }

    @media (min-width: 390px) {
      .amber-137asuq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-137asuq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-137asuq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-137asuq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-137asuq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-137asuq span {
      color: #ED3A56;
      margin-left: 8px;
      padding: 2px 6px;
      border-radius: 999px;
      background: #FEE9EF;
    }

    .amber-137asuq a {
      color: inherit;
      font-weight: inherit;
      min-width: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .amber-137asuq a:hover,
    .amber-137asuq a:focus {
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #ED3A56;
    }

    @media (min-width: 768px) {
      .amber-137asuq {
        font-size: 14px;
      }

      .amber-137asuq span {
        font-size: 12px;
      }
    }

    .amber-w3hxsd {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 6px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
    }

    @media (min-width: 390px) {
      .amber-w3hxsd {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-w3hxsd {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-w3hxsd {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 25%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-w3hxsd {
        -webkit-flex-basis: 15%;
        -ms-flex-preferred-size: 15%;
        flex-basis: 15%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 15%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-w3hxsd {
        -webkit-flex-basis: 15%;
        -ms-flex-preferred-size: 15%;
        flex-basis: 15%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 15%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    .amber-14tp5i1 {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
      margin-bottom: 12px;
    }

    @media (min-width: 390px) {
      .amber-14tp5i1 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-14tp5i1 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-14tp5i1 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-14tp5i1 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-14tp5i1 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-1kgchr5 {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 6px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
    }

    @media (min-width: 390px) {
      .amber-1kgchr5 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-1kgchr5 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1kgchr5 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 25%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-1kgchr5 {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 20%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-1kgchr5 {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 25%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    .amber-1k23fku {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 6px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      margin-top: 8px;
    }

    @media (min-width: 390px) {
      .amber-1k23fku {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-1k23fku {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1k23fku {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 75%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-1k23fku {
        -webkit-flex-basis: 80%;
        -ms-flex-preferred-size: 80%;
        flex-basis: 80%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 80%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-1k23fku {
        -webkit-flex-basis: 75%;
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 75%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-1k23fku {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        margin-top: 0;
      }
    }

    .amber-1qzpfti {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 31px;
      margin-top: 26px;
    }

    .amber-rq3l2r {
      width: auto;
      height: 100%;
    }

    @media (min-width: 390px) {
      .amber-rq3l2r {
        width: auto;
      }
    }

    @media (min-width: 768px) {
      .amber-rq3l2r {
        width: auto;
      }
    }

    .amber-8s797z {
      width: 28px;
      height: 28px;
    }

    .amber-1hyyw29 {
      height: 24px;
      width: auto;
      margin-top: 0;
    }

    .amber-chtak4 {
      width: 2px;
      height: 68px;
      background-color: #D1D5DB;
    }

    .amber-1y6fnqw {
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      gap: 12px;
      margin-top: 8px;
    }

    .amber-1y6fnqw>* {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
    }

    .amber-13sz875 {
      min-width: 22px;
      min-height: 22px;
    }

    .amber-13sz875:hover {
      cursor: pointer;
    }

    .amber-163rbbh {
      margin-top: 32px;
    }

    .amber-15rxfmm {
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-top: 12px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 20px;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-15rxfmm i {
      font-size: 20px;
      cursor: pointer;
    }

    .amber-c602rd {
      height: 23px;
      width: 106px;
      -webkit-filter: invert(1) brightness(1);
      filter: invert(1) brightness(1);
    }

    .amber-1xppgb4 {
      width: 48px;
      height: 40px;
    }

    .amber-y7nbrc {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-align-items: start !important;
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: start !important;
    }

    .amber-1pche7w {
      font-size: 15px;
      font-weight: 600;
      color: #1F2A37;
    }

    @media (min-width: 768px) {
      .amber-1pche7w {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .amber-1jzsuhn {
      font-size: 11px;
      font-weight: 600;
      color: #6B7280;
    }

    @media (min-width: 768px) {
      .amber-1jzsuhn {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .amber-k21imb {
      width: 100vw;
      z-index: 2;
      position: fixed;
      height: 120px;
      background: none;
    }

    @media (min-width: 768px) {
      .amber-k21imb {
        height: 104px;
      }
    }

    .amber-13qrgm7 {
      font-size: 200%;
    }

    @media (min-width: 768px) {
      .amber-13qrgm7 {
        font-size: 250%;
      }
    }

    .amber-g4fh53 {
      font-size: 22px;
    }

    @media (min-width: 768px) {
      .amber-g4fh53 {
        font-size: 36px;
      }
    }

    @media (min-width: 1024px) {
      .amber-cism1j {
        width: 666px;
        height: 304px;
      }
    }

    .amber-hpsswz {
      outline: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #000;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 0;
      box-shadow: none;
      color: #233876;
      margin-right: auto;
      margin-left: auto;
      padding: 0;
      width: 100%;
      max-width: 1440px;
    }

    .amber-1gtkia0 {
      box-shadow: 0px 2px 4px 0px #0000000D;
      background: #E1EFFE;
    }

    .amber-9vl3ds {
      padding: 8px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 12px;
      -webkit-align-items: start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: start;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-aoakmu {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: black;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-weight: 400;
    }

    .amber-aoakmu:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-aoakmu:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-j6ljlb {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #1A56DB;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      border: 0;
    }

    .amber-j6ljlb:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-j6ljlb:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-j6ljlb:hover {
        -webkit-text-decoration: underline;
        text-decoration: underline;
      }
    }

    @media (hover: none) {
      .amber-j6ljlb:active {
        -webkit-text-decoration: underline;
        text-decoration: underline;
      }
    }

    .amber-1a1ymvq {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #3F83F8;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: underline;
      text-decoration: underline;
      font-weight: 500;
    }

    .amber-1a1ymvq:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1a1ymvq:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-15ac6w1 {
      outline: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #000;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 0;
      box-shadow: none;
      padding: 12px 16px;
      background: none;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      max-width: 1440px;
    }

    @media (min-width: 768px) {
      .amber-15ac6w1 {
        padding: 12px 40px;
      }
    }

    .amber-lzyai0 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: baseline;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-6vpm4g {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      height: 36px;
      padding-left: 12px;
      padding-right: 12px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      display: inline-block;
      width: auto;
      border-radius: 6px;
      font-weight: 600;
      position: relative;
      line-height: 1;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      border: 1px solid transparent;
      background-color: transparent;
      color: #0E9F6E;
      font-weight: 500;
      font-size: 16px;
      background: transparent;
      color: white;
      margin-right: 4px;
      margin-left: 4px;
      padding-right: 16px;
      padding-left: 16px;
    }

    .amber-6vpm4g:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-6vpm4g:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-6vpm4g:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-6vpm4g:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-6vpm4g:hover {
        background-color: rgba(243, 250, 247, 1);
      }
    }

    @media (hover: none) {
      .amber-6vpm4g:active {
        background-color: rgba(243, 250, 247, 1);
      }
    }

    .amber-6vpm4g:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-6vpm4g:disabled,
    .amber-6vpm4g[data-disabled] {
      border-color: transparent;
      background-color: #e9ecef;
      color: #adb5bd;
      cursor: not-allowed;
      background-image: none;
      pointer-events: none;
    }

    .amber-6vpm4g:disabled:active,
    .amber-6vpm4g[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-6vpm4g[data-loading] {
      pointer-events: none;
    }

    .amber-6vpm4g[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 6px;
      cursor: not-allowed;
    }

    .amber-6vpm4g:hover {
      color: white;
      background: rgba(255, 255, 255, 0.1);
    }

    .amber-6vpm4g:active,
    .amber-6vpm4g:visited,
    .amber-6vpm4g:focus {
      color: white;
    }

    @media (max-width: 767px) {
      .amber-6vpm4g {
        border: 1px solid #D1D5DB;
      }
    }

    .amber-skxhn0 {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      height: 36px;
      padding-left: 12px;
      padding-right: 18px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      display: inline-block;
      width: auto;
      border-radius: 6px;
      font-weight: 600;
      position: relative;
      line-height: 1;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      border: 1px solid #F9FAFB;
      background-color: transparent;
      color: #F9FAFB;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: none;
      margin-left: 8px;
      padding-right: 16px;
      padding-left: 16px;
    }

    .amber-skxhn0:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-skxhn0:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-skxhn0:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-skxhn0:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-skxhn0:hover {
        background-color: rgba(249, 250, 251, 0.35);
      }
    }

    @media (hover: none) {
      .amber-skxhn0:active {
        background-color: rgba(249, 250, 251, 0.35);
      }
    }

    .amber-skxhn0:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-skxhn0:disabled,
    .amber-skxhn0[data-disabled] {
      border-color: transparent;
      background-color: #e9ecef;
      color: #adb5bd;
      cursor: not-allowed;
      background-image: none;
      pointer-events: none;
    }

    .amber-skxhn0:disabled:active,
    .amber-skxhn0[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-skxhn0[data-loading] {
      pointer-events: none;
    }

    .amber-skxhn0[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 6px;
      cursor: not-allowed;
    }

    @media (min-width: 768px) {
      .amber-skxhn0 {
        display: block;
      }
    }

    .amber-1gna992 {
      -webkit-background-size: cover;
      background-size: cover;
      -webkit-background-position: center;
      background-position: center;
      background:
      
      url("https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg")
        center / cover no-repeat;
      padding: 28px 16px 40px 16px;
      max-width: 1440px;
      width: 100vw;
      height: 120%;
      min-height: 1030px;
      margin: 0 auto;
      color: #FFFFFF;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      .amber-1gna992 {
        height: 708px;
        padding: 18px 80px 52px 80px;
      }
    }

    .amber-hyuc05 {
      outline: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #000;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 12px;
      box-shadow: none;
      border: 1px solid #dee2e6;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      border: 1px solid rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      min-width: 343px;
      padding: 20px 16px;
      background: rgba(0, 0, 0, 0.25);
      gap: 16px;
      min-width: 340px;
    }

    @media (min-width: 768px) {
      .amber-hyuc05 {
        padding: 32px 36px;
        width: 555px;
        height: 497px;
        background: rgba(0, 0, 0, 0.25);
        gap: 24px;
      }
    }

    .amber-48lk48 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #F9FAFB;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 600;
      font-size: 56px;
      line-height: 150%;
      margin: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.1;
    }

    .amber-48lk48:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-48lk48:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-48lk48 {
        font-size: 56px;
        font-weight: 600;
      }
    }

    .amber-lnnabv {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #F9FAFB;
      font-size: 16px;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
    }

    .amber-lnnabv:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-lnnabv:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-lnnabv {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .amber-1ox43b0 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #31C48D;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 16px;
    }

    .amber-1ox43b0:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1ox43b0:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-lb3fob {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #F9FAFB;
      font-size: 16px;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-lb3fob:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-lb3fob:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-5kt18n {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      height: 36px;
      padding-left: 18px;
      padding-right: 18px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      display: inline-block;
      width: auto;
      border-radius: 6px;
      font-weight: 600;
      position: relative;
      line-height: 1;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      border: 1px solid transparent;
      background-color: #3F83F8;
      color: #fff;
      height: 36px;
      width: 100%;
      min-width: 308px;
    }

    .amber-5kt18n:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-5kt18n:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-5kt18n:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-5kt18n:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-5kt18n:hover {
        background-color: #1C64F2;
      }
    }

    @media (hover: none) {
      .amber-5kt18n:active {
        background-color: #1C64F2;
      }
    }

    .amber-5kt18n:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-5kt18n:disabled,
    .amber-5kt18n[data-disabled] {
      border-color: transparent;
      background-color: #e9ecef;
      color: #adb5bd;
      cursor: not-allowed;
      background-image: none;
      pointer-events: none;
    }

    .amber-5kt18n:disabled:active,
    .amber-5kt18n[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-5kt18n[data-loading] {
      pointer-events: none;
    }

    .amber-5kt18n[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 6px;
      cursor: not-allowed;
    }

    @media (min-width: 768px) {
      .amber-5kt18n {
        height: 48px;
      }
    }

    .amber-13u37x8 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #F9FAFB;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-13u37x8:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-13u37x8:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-lemcyi {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      height: 36px;
      padding-left: 18px;
      padding-right: 18px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      display: inline-block;
      width: auto;
      border-radius: 6px;
      font-weight: 600;
      position: relative;
      line-height: 1;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      border: 1px solid transparent;
      background-color: #fff;
      color: #3F83F8;
      height: 36px;
      width: 100%;
      min-width: 308px;
    }

    .amber-lemcyi:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-lemcyi:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-lemcyi:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-lemcyi:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-lemcyi:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-lemcyi:disabled,
    .amber-lemcyi[data-disabled] {
      border-color: transparent;
      background-color: #e9ecef;
      color: #adb5bd;
      cursor: not-allowed;
      background-image: none;
      pointer-events: none;
    }

    .amber-lemcyi:disabled:active,
    .amber-lemcyi[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-lemcyi[data-loading] {
      pointer-events: none;
    }

    .amber-lemcyi[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 6px;
      cursor: not-allowed;
    }

    @media (min-width: 768px) {
      .amber-lemcyi {
        height: 48px;
      }
    }

    .amber-1d9vc5z {
      margin: 0 auto;
      width: 100vw;
      max-width: 1440px;
      padding: 56px 80px;
      overflow: hidden;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 28px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    @media (max-width: 767px) {
      .amber-1d9vc5z {
        padding: 32px 16px;
      }
    }

    @media (min-width: 768px) {
      .amber-1d9vc5z {
        gap: 48px;
      }
    }

    .amber-1f1rhpv {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 300;
      font-size: 32px;
      line-height: 150%;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: #1F2A37;
    }

    .amber-1f1rhpv:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1f1rhpv:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-1f1rhpv {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .amber-nduyv1 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      margin-top: 4px;
      color: #6B7280;
    }

    .amber-nduyv1:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-nduyv1:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-nduyv1 {
        font-size: 18px;
        font-weight: 400;
        margin-top: 8px;
      }
    }

    .amber-rujx3b {
      outline: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #000;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 20px;
      box-shadow: none;
      min-width: 292px;
      max-width: 380px;
    }

    .amber-tthdtw {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #374151;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: #374151;
      margin-bottom: 4px;
    }

    .amber-tthdtw:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-tthdtw:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-tthdtw {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }

    .amber-27zshj {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      white-space: normal;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      height: 65px;
    }

    .amber-27zshj:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-27zshj:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-zbrtlh {
      margin: 0 auto;
      width: 100vw;
      max-width: 1440px;
      padding: 56px 80px;
      overflow: hidden;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    @media (max-width: 767px) {
      .amber-zbrtlh {
        padding: 32px 16px;
      }
    }

    .amber-1irhk22 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: #1F2A37;
    }

    .amber-1irhk22:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1irhk22:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-1irhk22 {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .amber-o1n9j9 {
      margin: -24px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: stretch;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    .amber-tm7vxk {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2;
      padding: 24px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
    }

    @media (min-width: 390px) {
      .amber-tm7vxk {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-tm7vxk {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-tm7vxk {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-tm7vxk {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 50%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-tm7vxk {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-8oh7lq {
      padding-top: 16px;
      padding-bottom: 16px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 24px;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-1k05507 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-c1a6li {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
    }

    .amber-c1a6li:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-c1a6li:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-14olkzq {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      padding: 24px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    @media (min-width: 390px) {
      .amber-14olkzq {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-14olkzq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-14olkzq {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-14olkzq {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 50%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-14olkzq {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-epequm {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #1F2A37;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      margin: 0;
    }

    .amber-epequm:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-epequm:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1f9zaai {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      margin-bottom: 48px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .amber-1f9zaai:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1f9zaai:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1xa1v {
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      gap: 12px;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      gap: 12px;
      margin-right: 12px;
    }

    .amber-1xa1v>* {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
    }

    .amber-7s7zs3 {
      height: 100%;
      width: 38px;
      object-fit: contain;
    }

    .amber-1ow0vyp {
      padding: 28px 40px;
      background-color: #ffffff;
      border-radius: 8px;
      position: relative;
      width: 278px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      .amber-1ow0vyp {
        width: 345px;
      }
    }

    .amber-1ow0vyp:first-of-type {
      margin-left: 5px;
    }

    .amber-k84zhg {
      position: absolute;
      left: -24px;
      top: calc(50% - 24px);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-1u4x2qp {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #4B5563;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }

    .amber-1u4x2qp:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1u4x2qp:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1i083ef {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #111928;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 300;
      font-size: 32px;
      line-height: 150%;
      margin: 0;
      margin-top: 16px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
    }

    .amber-1i083ef:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1i083ef:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-19siiuw {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 14px;
      line-height: 21px;
      white-space: normal;
      font-weight: 400;
      padding-top: 8px;
    }

    .amber-19siiuw:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-19siiuw:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-cd3crb {
      padding-top: 20px;
      padding-bottom: 20px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 12px;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-1962v11 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-1962v11:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1962v11:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1q91zvd {
      margin: 0 auto;
      width: 100vw;
      max-width: 1440px;
      padding: 56px 80px;
      overflow: hidden;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    @media (max-width: 767px) {
      .amber-1q91zvd {
        padding: 32px 16px;
      }
    }

    .amber-wcrvkw {
      margin: -24px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .amber-eze8u2 {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 24px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
    }

    @media (min-width: 390px) {
      .amber-eze8u2 {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-eze8u2 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-eze8u2 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-eze8u2 {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 50%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-eze8u2 {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-1l2v7mo {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #1F2A37;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      margin: 0;
      margin-bottom: 12px;
    }

    .amber-1l2v7mo:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1l2v7mo:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-15nnrgj {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .amber-15nnrgj:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-15nnrgj:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-evg6j {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 24px;
      -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      max-width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    @media (min-width: 390px) {
      .amber-evg6j {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 480px) {
      .amber-evg6j {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 768px) {
      .amber-evg6j {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    @media (min-width: 1024px) {
      .amber-evg6j {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 50%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
    }

    @media (min-width: 1440px) {
      .amber-evg6j {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }

    .amber-2hktju {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-bottom: 24px;
      padding-right: 24px;
      padding-left: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      background: #F3F4F6;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }

    .amber-2hktju:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-2hktju:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-2hktju {
        margin-bottom: 44px;
      }
    }

    .amber-cq8ly {
      width: 100vw;
      overflow: hidden !important;
    }

    @media (max-width: 767px) {
      .amber-cq8ly {
        margin-left: -16px;
      }
    }

    @media (min-width: 768px) {
      .amber-cq8ly {
        width: inherit;
      }
    }

    .amber-1c0zqz2 {
      height: 42px;
      width: auto;
      max-width: 130px;
    }

    .amber-1g1ewwa {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 595vw;
      position: relative;
      white-space: nowrap;
      -webkit-animation: marquee 20s linear infinite;
      animation: marquee 20s linear infinite;
    }

    @-webkit-keyframes marquee {
      0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
      }

      100% {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }

    @keyframes marquee {
      0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
      }

      100% {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }

    @media (min-width: 768px) {
      .amber-1g1ewwa {
        width: 295vw;
        -webkit-animation: marquee 24s linear infinite;
        animation: marquee 24s linear infinite;
      }

      @-webkit-keyframes marquee {
        0% {
          -webkit-transform: translateX(0%);
          -moz-transform: translateX(0%);
          -ms-transform: translateX(0%);
          transform: translateX(0%);
        }

        100% {
          -webkit-transform: translateX(-80%);
          -moz-transform: translateX(-80%);
          -ms-transform: translateX(-80%);
          transform: translateX(-80%);
        }
      }

      @keyframes marquee {
        0% {
          -webkit-transform: translateX(0%);
          -moz-transform: translateX(0%);
          -ms-transform: translateX(0%);
          transform: translateX(0%);
        }

        100% {
          -webkit-transform: translateX(-80%);
          -moz-transform: translateX(-80%);
          -ms-transform: translateX(-80%);
          transform: translateX(-80%);
        }
      }
    }

    @media (min-width: 1441px) {
      .amber-1g1ewwa {
        width: 150vw;
        -webkit-animation: marquee 24s linear infinite;
        animation: marquee 24s linear infinite;
      }

      @-webkit-keyframes marquee {
        0% {
          -webkit-transform: translateX(0%);
          -moz-transform: translateX(0%);
          -ms-transform: translateX(0%);
          transform: translateX(0%);
        }

        100% {
          -webkit-transform: translateX(-80%);
          -moz-transform: translateX(-80%);
          -ms-transform: translateX(-80%);
          transform: translateX(-80%);
        }
      }

      @keyframes marquee {
        0% {
          -webkit-transform: translateX(0%);
          -moz-transform: translateX(0%);
          -ms-transform: translateX(0%);
          transform: translateX(0%);
        }

        100% {
          -webkit-transform: translateX(-80%);
          -moz-transform: translateX(-80%);
          -ms-transform: translateX(-80%);
          transform: translateX(-80%);
        }
      }
    }

    .amber-bj8gla {
      padding: 24px;
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      background: white;
      height: 90px;
      width: 178px;
      display: inline-block;
      float: left;
      margin-right: 16px;
    }

    .amber-2sg1a8 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      margin: 0;
    }

    .amber-2sg1a8:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-2sg1a8:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1r3cov4 {
      width: 100%;
      max-width: calc(100vw - 40px);
      margin-right: auto;
      margin-left: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 34px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      .amber-1r3cov4 {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
      }
    }

    .amber-d9nda2 {
      cursor: pointer;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 10px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      .amber-d9nda2 {
        max-width: 432px;
      }
    }

    .amber-11nhzn5 {
      margin: 0;
    }

    .amber-1r37y8b {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 12px;
    }

    .amber-1rqfarx {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      margin: 0;
      font-weight: 500;
      font-size: 16px;
    }

    .amber-1rqfarx:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1rqfarx:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-1rqfarx {
        font-weight: 700;
        font-size: 24px;
      }
    }

    .amber-n31p3t {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
    }

    .amber-n31p3t:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-n31p3t:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-n31p3t {
        font-size: 16px;
      }
    }

    .amber-10o9cp8 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #9CA3AF;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-10o9cp8:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-10o9cp8:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-klm5g7 {
      width: 100%;
      max-width: calc(100vw - 40px);
      margin-right: auto;
      margin-left: auto;
      padding-top: 42px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-a02wxd {
      outline: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #000;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: none;
      border: 1px solid #dee2e6;
      padding: 12px;
      width: 100%;
      min-width: 350px;
      max-height: 250px;
    }

    .amber-1fd33yh {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      margin: 0;
    }

    .amber-1fd33yh:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1fd33yh:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-11w3m33 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-11w3m33:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-11w3m33:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-jghxib {
      overflow: hidden;
    }

    .amber-uavcdn {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: 14px;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      margin-top: 16px;
    }

    .amber-uavcdn:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-uavcdn:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1lkegg2 {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-width: 26px;
      min-height: 26px;
      border-radius: 26px;
      pointer-events: all;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
      opacity: 0.85;
      border: 1px solid #dee2e6;
      -webkit-transition: opacity 150ms ease;
      transition: opacity 150ms ease;
    }

    .amber-1lkegg2:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1lkegg2:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-1lkegg2:hover {
        opacity: 1;
      }
    }

    @media (hover: none) {
      .amber-1lkegg2:active {
        opacity: 1;
      }
    }

    .amber-1lkegg2:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-1lkegg2[data-inactive] {
      opacity: 0;
      cursor: default;
    }

    .amber-109opwg {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      padding: 12px 6px;
      padding-left: calc(8px + 4px);
      text-align: left;
      color: #000;
    }

    .amber-109opwg:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-109opwg:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-109opwg:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-109opwg:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-109opwg:hover {
        background-color: #f8f9fa;
      }
    }

    @media (hover: none) {
      .amber-109opwg:active {
        background-color: #f8f9fa;
      }
    }

    .amber-109opwg:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    @media (hover: hover) {
      .amber-109opwg:disabled:hover {
        background-color: transparent;
      }
    }

    @media (hover: none) {
      .amber-109opwg:disabled:active {
        background-color: transparent;
      }
    }

    .amber-tz4v6p {
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }

    .amber-tz4v6p>* {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
    }

    .amber-165ozc9 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #111928;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-165ozc9:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-165ozc9:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-18x58uc {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      word-break: break-word;
      line-height: 1.55;
    }

    .amber-k17756 {
      margin: 0 auto;
      width: 100vw;
      max-width: 1440px;
      padding: 56px 80px;
      overflow: hidden;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 100px;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    @media (max-width: 767px) {
      .amber-k17756 {
        padding: 32px 16px;
      }
    }

    @media (max-width: 767px) {
      .amber-k17756 {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 24px;
      }
    }

    .amber-1ne1g4d {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #6B7280;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
    }

    .amber-1ne1g4d:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1ne1g4d:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-1ne1g4d {
        line-height: 30px;
        font-size: 18px;
      }
    }

    .amber-xrwpek {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 16px;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .amber-1bxbwg7 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #1A56DB;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      border: 0;
      background: #F9FAFB;
    }

    .amber-1bxbwg7:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1bxbwg7:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-1bxbwg7:hover {
        -webkit-text-decoration: underline;
        text-decoration: underline;
      }
    }

    @media (hover: none) {
      .amber-1bxbwg7:active {
        -webkit-text-decoration: underline;
        text-decoration: underline;
      }
    }

    .amber-1bxbwg7:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    .amber-16s8nq5 {
      border: 1px solid #F3F4F6;
      background: #ffffff;
      border-radius: 12px;
      height: 116px;
      width: 160px;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      gap: 12px;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .amber-1kgimr9 {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      border: 1px solid transparent;
      background-color: transparent;
      color: #ED3A56;
      position: relative;
      height: 28px;
      min-height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 24px;
      padding: 0;
      line-height: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-1kgimr9:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1kgimr9:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-1kgimr9:hover {
        background-color: rgba(254, 233, 239, 1);
      }
    }

    @media (hover: none) {
      .amber-1kgimr9:active {
        background-color: rgba(254, 233, 239, 1);
      }
    }

    .amber-1kgimr9:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-1kgimr9:disabled,
    .amber-1kgimr9[data-disabled] {
      color: #ced4da;
      cursor: not-allowed;
      background-color: #f1f3f5;
      border-color: #f1f3f5;
      background-image: none;
      pointer-events: none;
    }

    .amber-1kgimr9:disabled:active,
    .amber-1kgimr9[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-1kgimr9[data-loading] {
      pointer-events: none;
    }

    .amber-1kgimr9[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 24px;
      cursor: not-allowed;
    }

    .amber-9xs2zs {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #1F2A37;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 14px;
    }

    .amber-9xs2zs:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-9xs2zs:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-sce3b9 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #fff;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 12px;
      border: 1px solid;
      width: 104px;
      padding: 4px 8px;
      border-radius: 4px;
      background: #ED3A56;
    }

    .amber-sce3b9:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-sce3b9:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-9gjnds {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: inline-block;
      border-radius: 50%;
      height: 7px;
      width: 7px;
      background: #fff;
    }

    .amber-9gjnds:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-9gjnds:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-uvsj11 {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      border: 1px solid transparent;
      background-color: transparent;
      color: #31C48D;
      position: relative;
      height: 28px;
      min-height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 24px;
      padding: 0;
      line-height: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-uvsj11:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-uvsj11:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-uvsj11:hover {
        background-color: rgba(243, 250, 247, 1);
      }
    }

    @media (hover: none) {
      .amber-uvsj11:active {
        background-color: rgba(243, 250, 247, 1);
      }
    }

    .amber-uvsj11:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-uvsj11:disabled,
    .amber-uvsj11[data-disabled] {
      color: #ced4da;
      cursor: not-allowed;
      background-color: #f1f3f5;
      border-color: #f1f3f5;
      background-image: none;
      pointer-events: none;
    }

    .amber-uvsj11:disabled:active,
    .amber-uvsj11[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-uvsj11[data-loading] {
      pointer-events: none;
    }

    .amber-uvsj11[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 24px;
      cursor: not-allowed;
    }

    .amber-urq1lp {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #fff;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 12px;
      border: 1px solid;
      width: 104px;
      padding: 4px 8px;
      border-radius: 4px;
      background: #31C48D;
    }

    .amber-urq1lp:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-urq1lp:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1uzen3c {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      border: 1px solid transparent;
      background-color: transparent;
      color: #E3A008;
      position: relative;
      height: 28px;
      min-height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 24px;
      padding: 0;
      line-height: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-1uzen3c:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1uzen3c:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-1uzen3c:hover {
        background-color: rgba(253, 253, 234, 1);
      }
    }

    @media (hover: none) {
      .amber-1uzen3c:active {
        background-color: rgba(253, 253, 234, 1);
      }
    }

    .amber-1uzen3c:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-1uzen3c:disabled,
    .amber-1uzen3c[data-disabled] {
      color: #ced4da;
      cursor: not-allowed;
      background-color: #f1f3f5;
      border-color: #f1f3f5;
      background-image: none;
      pointer-events: none;
    }

    .amber-1uzen3c:disabled:active,
    .amber-1uzen3c[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-1uzen3c[data-loading] {
      pointer-events: none;
    }

    .amber-1uzen3c[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 24px;
      cursor: not-allowed;
    }

    .amber-5068r6 {
      -webkit-tap-highlight-color: transparent;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      cursor: pointer;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      font-size: 16px;
      background-color: transparent;
      text-align: left;
      color: #000;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-sizing: border-box;
      border: 1px solid transparent;
      background-color: transparent;
      color: #76A9FA;
      position: relative;
      height: 28px;
      min-height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 24px;
      padding: 0;
      line-height: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-5068r6:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-5068r6:focus:not(:focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      .amber-5068r6:hover {
        background-color: rgba(235, 245, 255, 1);
      }
    }

    @media (hover: none) {
      .amber-5068r6:active {
        background-color: rgba(235, 245, 255, 1);
      }
    }

    .amber-5068r6:active {
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
      -ms-transform: translateY(1px);
      transform: translateY(1px);
    }

    .amber-5068r6:disabled,
    .amber-5068r6[data-disabled] {
      color: #ced4da;
      cursor: not-allowed;
      background-color: #f1f3f5;
      border-color: #f1f3f5;
      background-image: none;
      pointer-events: none;
    }

    .amber-5068r6:disabled:active,
    .amber-5068r6[data-disabled]:active {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }

    .amber-5068r6[data-loading] {
      pointer-events: none;
    }

    .amber-5068r6[data-loading]::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(255, 255, 255, .5);
      border-radius: 24px;
      cursor: not-allowed;
    }

    .amber-12ubxmb {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #6B7280;
    }

    .amber-12ubxmb:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-12ubxmb:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1r61w4m {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 16px;
      text-decoration-line: underline;
      line-height: 20px;
      color: #1F2A37;
      font-weight: 700;
    }

    .amber-1r61w4m:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1r61w4m:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1xeqpja {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #4B5563;
      font-size: 12px;
      font-weight: 400;
      line-height: 21px;
      margin-top: 12px;
    }

    .amber-1xeqpja:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1xeqpja:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-1xeqpja {
        font-size: 14px;
      }
    }

    .amber-o8ubyo {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-size: 14px;
      color: #111928;
      font-weight: 700;
      cursor: default;
    }

    .amber-o8ubyo:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-o8ubyo:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-o8ubyo {
        font-size: 16px;
      }
    }

    .amber-1x4d67w {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #4B5563;
      font-weight: 500;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
    }

    .amber-1x4d67w:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1x4d67w:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-17e6d0i {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: #1A56DB;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      border: 0;
    }

    .amber-17e6d0i:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-17e6d0i:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1ucb8z7 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      color: #374151;
    }

    .amber-1ucb8z7:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1ucb8z7:focus:not(:focus-visible) {
      outline: none;
    }

    @media (min-width: 768px) {
      .amber-1ucb8z7 {
        font-size: 14px;
      }
    }

    .amber-f2koq0 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-f2koq0:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-f2koq0:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-f2koq0 img {
      width: 22px;
    }

    .amber-1g0knt6 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-1g0knt6:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1g0knt6:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1g0knt6 i {
      color: #1877F2;
      font-size: 22px;
    }

    .amber-uahb0l {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-uahb0l:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-uahb0l:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1v6w9hg {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-1v6w9hg:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1v6w9hg:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1v6w9hg i {
      color: #FF0000;
      font-size: 22px;
    }

    .amber-1x63qp5 {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      -webkit-tap-highlight-color: transparent;
      color: inherit;
      font-size: inherit;
      line-height: 1.55;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
    }

    .amber-1x63qp5:focus {
      outline-offset: 2px;
      outline: 2px solid #3F83F8;
    }

    .amber-1x63qp5:focus:not(:focus-visible) {
      outline: none;
    }

    .amber-1x63qp5 i {
      color: #3FA9F5;
      font-size: 22px;
    }

@media (max-width: 768px) {
  .amber-form-section {
    width: 900px;
    padding: 10px;
    height: auto;
  }
}