.mountain-card {
    width: 30%;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .mountain-image {
    height: 150px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
  }
  
  .mountain-content {
    background-color: rgba(255, 255, 255, 0.85);
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    color: #333;
  }
  
  .mountain-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .mountain-price {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .mountain-button {
    padding: 10px 15px;
    background-color: #ff5a5f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .mountain-button.delete {
    background-color: #dc3545;
  }
  