.ferferfds544 {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* Hide radio buttons */
input[type="radio"] {
    display: none;
}

.main-image-ferferfds544 {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    position: relative;
    border-radius: 15px;
}

.image-gallery-ferferfds544 img {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 15px;
    margin-top: 10px;
}

.carousel-ferferfds544 {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 40px;
}

.carousel-ferferfds544 img {
    display: none;
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}

/* Show the first image by default */
#image1-ferferfds544:checked~.carousel-ferferfds544 img:nth-child(1),
#image2-ferferfds544:checked~.carousel-ferferfds544 img:nth-child(2),
#image3-ferferfds544:checked~.carousel-ferferfds544 img:nth-child(3),
#image4-ferferfds544:checked~.carousel-ferferfds544 img:nth-child(4),
#image5-ferferfds544:checked~.carousel-ferferfds544 img:nth-child(5) {
    display: block;
}

.buttons-ferferfds544 {
    text-align: center;
    margin-top: 10px;
}

.next-btn-ferferfds544 {
    font-size: 18px;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #ff5a5f;
    color: white;
    border: none;
    border-radius: 5px;
    margin: 10px;
    text-decoration: none;
}

.image-gallery-ferferfds544 {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    /* Enable horizontal scrolling */
}
