

/* CSS for step 3 in mobile */
/* CSS for step 3 in mobile */
@media screen and (max-width: 768px) {
  .step3-container {
      width: 155%; /* Set width to expand beyond the viewport width */
      max-width: 700px; /* Restrict maximum width for larger mobile devices */
      margin-left: -27.5%; /* Shift container left for centered appearance */
      padding: 25px; /* Add padding for better spacing */
      box-sizing: border-box; /* Include padding and borders in width */
      position: relative; /* Ensure proper positioning within the parent */
  }

  .step3-container label {
      font-size: 16px; /* Enhance readability */
      text-align: left; /* Center align text labels */
  }

  .step3-container ul {
      padding-left: 20px; /* Improve list alignment */
  }

  .step {
    font-size: 14px;
    margin-top: 40px;
    text-align: left;
  }
  
}



.containerinvoice {
    display: flex;
    gap: 10px; /* Space between the two sections */
    align-items: flex-start; /* Align items to the top */
  }
  .scrollable {
    max-height: 300px; /* Adjust height as needed */
    overflow-y: auto;
    padding-right: 10px; /* To ensure padding inside the scroll area */
  }
  .parent-booking-form{
    display: flex;
    justify-content: flex-start; /* Aligns items to the left */
    align-items: flex-start;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .svg{
    width: 40px;
    height: 40px;
    margin-top: 5px; 
  }
  .modal-content {
    background: #fff;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    overflow-y: auto;
    max-height: 80vh;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #333;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .booking-form {
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    color: #333333;
  
  }
  
  .booking-summary {
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 10px;
    width: 45%;
    height: max-content;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    color: #333333;
  }
  
  .step, .step2, .step3 {
    font-weight: bold;
    color: #d9534f;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .step {
    font-size: 20px;
    margin-top: 40px;
    text-align: left;
  }
  
  .step2 {
    font-size: 20px;
    margin-top: 40px;
  }
  
  .step3 {
    font-weight: normal;
    font-size: 15px;
    margin-top: 40px;
  }
  
  .emerg-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .emergp {
    font-size: 16px;
    color: #5905f5;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 10px;
    flex: 1;
  }
  
  @media screen and (max-width: 768px) {
    .emergp {
        font-size: 10px;
    }
  }
  
  h4 {
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .containerinvoice {
        flex-direction: column;
    }
  
    .booking-form,
    .booking-summary {
        width: 95%;
        margin: 0 auto 20px auto;
    }
  
    .header {
        flex-direction: row;
    }
  
    .header .image {
        width: 80px;
        height: 80px;
        margin-right: 10px;
    }
  
    .title {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
  
    .title h2 {
        font-size: 20px;
    }
  
    .title h2 span {
        font-size: 18px;
        color: #555;
    }
  
    .title p {
        font-size: 16px;
    }
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    width: 95%;
  }
  
  .header .image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 20px;
  }
  
  .title h2 {
    margin: 0;
    font-size: 28px;
    color: #222222;
  }
  
  .title p {
    margin: 5px 0 0 0;
    color: #555555;
    font-size: 18px;
  }
  
  select {
    width: 100%;
    padding: 12px;
    margin: 5px 0;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    background-color: #ffffff;
    color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  select:focus {
    outline: none;
    border-color: #ff5a5f;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .badge {
    background-color: #ff5a5f00;
    color: #000000;
    padding: 8px 12px;
    font-size: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
    border: 1px solid #000;
    display: inline-block;
  }
  
  .booking-form h3 {
    margin-bottom: 15px;
    color: #333333;
    font-size: 24px;
  }
  
  .booking-form p {
    margin-bottom: 25px;
    color: #555555;
    font-size: 18px;
  }
  
  .booking-form a {
    color: #ff5a5f;
    text-decoration: none;
  }
  
  .booking-form a:hover {
    text-decoration: underline;
  }
  
  .booking-form form {
    display: flex;
    flex-direction: column;
  }
  
  .booking-form input {
    padding: 14px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    background-color: #ffffff;
    color: #333333;
    font-size: 18px;
  }
  
  .booking-form input::placeholder {
    color: #aaaaaa;
    font-size: 16px;
  }
  
  .booking-form button {
    padding: 14px;
    background-color: #ff5a5f;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .booking-form button:hover {
    background-color: #0056b3;
  }
  
  .check-in-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
  }
  
  .check-arrow p {
    margin: 0 15px;
    font-size: 20px;
    color: #555555;
  }
  
  .booking-summary .check p {
    margin: 0;
    font-size: 18px;
    color: #555555;
  }
  
  .booking-summary .check strong {
    font-size: 20px;
    color: #333333;
  }
  
  .details {
    margin-bottom: 20px;
  }
  
  .detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .detail-row .label {
    font-weight: bold;
    font-size: 18px;
    color: #555555;
  }
  
  .detail-row .value {
    font-size: 18px;
    color: #333333;
  }
  
  .cost {
    margin-bottom: 20px;
  }
  
  .cost-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .cost-row .label {
    font-weight: bold;
    font-size: 18px;
    color: #555555;
  }
  
  .cost-row .value {
    font-size: 18px;
    color: #333333;
  }
  
  ul.bullet-list, ol.bullet-list {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 20px;
    margin-left: 0;
    text-align: left;
  }
  
  ul.bullet-list li, ol.bullet-list li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333333;
    line-height: 1.6;
    text-align: left;
  }
  
  .due-today {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    margin-top: 25px;
    color: #ffffff;
  }
  
  .due-today .amount {
    font-size: 26px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .checkbox-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .checkbox-item input {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    visibility: visible;
    appearance: auto;
  }
  
  .checkbox-item label {
    font-size: 16px;
    color: #000;
  }
  .fine-warning {
    color: #d9534f; /* Red color */
    font-weight: bold;
    padding: 10px 0; /* Adjust the padding as needed */
  }

  @media screen and (max-width: 768px) {
    .step4-container {
        width: 155%; /* Set width to expand beyond the viewport width */
        max-width: 700px; /* Restrict the maximum width for larger devices */
        margin-left: -27.5%; /* Shift container left for centered appearance */
        padding: 25px; /* Add padding for better spacing */
        box-sizing: border-box; /* Ensure padding is included in the width */
        position: relative; /* Ensure proper positioning within the parent */
    }
  
    .step4-container label {
        font-size: 18px; /* Enhance readability */
        text-align: left; /* Align text labels to the left */
    }
  
    .step4-container .content {
        font-size: 16px; /* Improve font size for better readability */
        line-height: 1.6; /* Add better line spacing */
        text-align: left; /* Align content to the left */
    }
  
    .step4-container .table {
        font-size: 14px; /* Adjust table font size for mobile */
        border-spacing: 0;
    }
  
    .step4-container .table th,
    .step4-container .table td {
        padding: 10px; /* Add padding to table cells */
        text-align: left; /* Align table text to the left */
    }
  }
  