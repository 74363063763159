.whatsapp-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-button {
    background-color: #25d366;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
  }
  
  .whatsapp-icon {
    width: 30px;
    height: 30px;
  }
  
  .whatsapp-popup {
    position: absolute;
    bottom: 80px;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .whatsapp-popup h3 {
    margin: 0 0 10px 0;
    color: #25d366;
    font-size: 18px;
    text-align: center;
  }
  
  .whatsapp-popup p {
    margin: 0 0 15px 0;
    font-size: 14px;
    color: #555;
    text-align: center;
  }
  
  .whatsapp-popup ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .whatsapp-popup ul li {
    margin: 10px 0;
  }
  
  .whatsapp-popup ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #25d366;
    font-size: 14px;
  }
  
  .whatsapp-popup ul li a img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  