.delete-button {
    background-color: gray; /* Default disabled style */
    color: white;
    cursor: not-allowed;
    font-size: 10px;
}

.delete-button.active {
    background-color: red; /* Active style */
    cursor: pointer;
}

.banana-customer-table input[type='checkbox'] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    accent-color: #000000; /* Default color */
}

.banana-customer-table input[type='checkbox']:checked {
    background-color: #92584a; /* Highlighted background when checked */
    border-color: #ff5a5f; /* Change border to match background */
}


.banana-search-bar {
    width: 91%;
    border-collapse: collapse;
    margin-top: 20px;
}
.banana-table-container {
    padding: 0 60px; /* Keeps the padding */
    overflow-x: auto; /* Allows horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
}

.banana-customer-table {
    width: 100%; /* Ensures the table takes up full width */
    min-width: 800px; /* Adjust this to the minimum width you want the table to have */
    border-collapse: collapse; /* Keep table neat */
}


.banana-customer-table th,
.banana-customer-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.banana-customer-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.banana-customer-table td,
.banana-customer-table th {
    width: 10%;
}

.moon-red {
    color: red;
    font-weight: bold;
}

.moon-green {
    color: green;
    font-weight: bold;
}
.checkbox-wrapper {
    display: inline-block;
    padding: 5px;
    border-radius: 4px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.checkbox-wrapper.selected {
    background-color: #ff5a5f; /* Highlighted background */
    color: #fff; /* Optional: Change text color if needed */
}

.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0; /* Hide the original checkbox */
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0;
}

.custom-checkbox .checkbox-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.custom-checkbox input[type="checkbox"]:checked + .checkbox-icon {
    background-color: #ff5a5f; /* Highlighted background when selected */
    border-color: #ff5a5f;
    color: #fff; /* White icon color */
}

.custom-checkbox .checkbox-icon i {
    font-size: 11px; /* Correct icon size */
}

