.full-page-container {
  position: relative;
  width: 100vw;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9; /* Light background color for full-page */
}



.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.progress-container {
  display: flex;
  gap: 5px;
  margin: 20px 0;
  width: 50%;
}

.progress-bar {
  height: 5px;
  flex: 1;
  background-color: #e0e0e0;
}

.progress-bar.filled {
  background-color: #FC005A;
}

.btn {
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #FC005A;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
   max-height: 100px;
}

.amber-form-group {
  flex: 1;
  min-width: 150px;
}
/* Enhanced radio button styles */
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d9534f; /* Border color to match your theme */
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

input[type="radio"]:checked {
  background-color: #FC005A; /* Checked state with filled color */
  border-color: #FC005A; 
}
/* reserveNew.css */
.full-page-container {
  width: 100%;
  height: 150vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.contentinvoice {
  display: flex;
  justify-content: space-between;
  height: 42vh;
  gap: 20px; /* Adjust the gap as needed */
}
.full-page-content {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); height: 530px;
  padding: 30px;
  width: 90%;
  max-width: 900px;

  border-radius: 8px;

  position: relative;
}

.full-page-content2 {
  background: url('https://i.pinimg.com/originals/84/d8/7e/84d87eb7e536135161c55887d878d44b.gif') no-repeat center center;
  background-size: cover; /* Ensures the image covers the entire area */
  padding: 30px;
  width: 100%;
  height: 89vh;
  position: relative;
  color: #fff; /* Text color for readability */
}
.full-page-content3 {
  background: url('https://i.pinimg.com/originals/84/d8/7e/84d87eb7e536135161c55887d878d44b.gif') no-repeat center center;
  background-size: cover; /* Ensures the image covers the entire area */
  padding: 30px;
  width: 100%;
  height: 89vh;
  position: relative;
  color: #fff; /* Text color for readability */
}
@media (max-width: 768px) {
  .full-page-content2 {

    width: 100%;
    height: 50%;
  }
  .full-page-content3 {

    width: 100%;
    height: 50%;
  }
 
  .full-page-content {
    width: 100%;
    padding: 40px;
    background: #ffffff;
    border-radius: 10px;
    justify-content: center;
  }
}
.btn456{
  padding: 10px 20px;
  background-color:  #FC005A;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.form-grid2 {
  display: flex;
  gap: 10px; /* Adds space between buttons */
  justify-content: flex-start; /* Aligns buttons to the left (use 'center' or 'flex-end' if needed) */
  margin-top: 20px; /* Adjusts spacing above the buttons */
}



.radio-button {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.transport-option-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 2px solid #d9534f;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.amber-form-group2 label {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Highlight the selected option */
.amber-form-group2 label.selected {
  background-color: #d9534f; /* Replace with desired color */
  border-color: #d9534f;
  color: #fff; /* Optional: Adjust text color for contrast */
}

/* Optional: Add hover effect for unselected options */




.transport-option-wrapper.selected,
.transport-option-wrapper:hover {
  background-color: #fbe9e7;
  border-color: #c9302c;
}

.transport-option {
  flex-grow: 1;
}

.transport-title {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

.transport-description {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}


.discount-highlight {
  color: #d9534f;
  font-weight: bold;
}

.strikethrough {
  text-decoration: line-through;
  color: #aaa;
}

.front-page {
  display: flex;
  align-items: center;
  padding: 20px;
}

.gif-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-section {
  flex: 1;
  padding-left: 20px;
}

.register-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.register-section p {
  font-size: 16px;
  margin-bottom: 20px;
}


/* Container */
.containerinvoice {
  padding: 20px;
}

/* Main Card Container */
.rounded-lg {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
  max-width: 350px;
}

/* Header Section */
.content-font {
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.uppercase {
  text-transform: uppercase;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-500 {
  color: #6b7280; /* Tailwind's gray-500 */
}

.text-gray-700 {
  color: #4b5563; /* Tailwind's gray-700 */
}

/* Image Styling */
.w-full {
  width: 100%;
}

.h-40 {
  height: 160px; /* Equivalent to Tailwind's h-40 */
}

.object-cover {
  object-fit: cover;
}

.rounded-md {
  border-radius: 8px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* Text and Title */
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.lg\\:line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* List Styling */
.pb-0\\5 {
  padding-bottom: 2px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-1 {
  margin-bottom: 4px;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.lg\\:text-right {
  text-align: right;
}

/* Divider */
.divider {
  height: 1px;
  background-color: #e5e7eb; /* Tailwind's gray-200 */
  margin-top: 12px;
  margin-bottom: 12px;
}

/* SVG Arrow */
.svg-arrow {
  width: 16px;
  height: 16px;
  margin: 0 4px;
}

/* Price and Total */
.text-theme-orange {
  color: #FC005A; /* Custom theme color */
}

/* Footer Section */
.due-today {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

/* Responsive */
@media (min-width: 1024px) {
  .lg\\:shadow-lg {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .lg\\:p-3 {
      padding: 12px;
  }

  .lg\\:border {
      border: 1px solid #f0f0f0;
  }

  .lg\\:line-clamp-2 {
      -webkit-line-clamp: 2;
  }

  .lg\\:text-right {
      text-align: right;
  }
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
body {
  margin: 0;
  padding: 0;
 
}

section {
  width: 100%;
  box-sizing: border-box;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center; /* Centers the row horizontally */
  align-items: flex-start; /* Aligns cards at the top */
  box-sizing: border-box;
}

.pricingTable {
  display: flex; /* Align the cards horizontally */
  gap: 10px; /* Adds space between the cards */
  justify-content: flex-start; /* Aligns cards to the left */
  flex-wrap: nowrap; /* Prevent wrapping */
}

.pricingTable-firstTable_table {
  width: 255px; /* Fixed width for uniformity */
  background-color: #ffffff;
  display: flex;
  flex-direction: column; /* Stack content inside cards */
  padding: 10px;
  text-align: center;
  height: max-content; /* Adjust height based on content */
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 0.9em; /* Slightly adjusted text size */
}

.pricingTable-firstTable_table:hover {
  transform: scale(1);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.pricingTable-firstTable_table__header {
  font-size: 1.4em;
  padding: 5px 0;
  border-bottom: 1px solid #ebedec;
  color: #333;
}

.pricingTable-firstTable_table__pricing {
  font-size: 1.8em;
  color: #FC005A;
  margin: 5px 0;
  border-bottom: 1px solid #ebedec;
  padding-bottom: 10px;
}

.pricingTable-firstTable_table__options {
  list-style: none;
  padding: 10px;
  font-size: 0.9em;
  margin: 0;
  flex-grow: 1; /* Ensures options list grows to fill available space */
}

.pricingTable-firstTable_table__options li {
  padding: 8px 0;
  border-bottom: 1px solid #f4f4f4;
}

.pricingTable-firstTable_table__options li:before {
  content: "✓";
  display: inline-block;
  margin-right: 10px;
  background-color: #FC005A;
  color: #ffffff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  font-size: 0.75em;
}

.pricingTable-firstTable_table__getstart {
  margin-top: 1px;
  padding: 12px 20px;
  background-color: #8e8e8e;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.pricingTable-firstTable_table__getstart:hover {
  background-color: #d9004e;
  transform: translateY(-2px);
}

.title .fa {
  color: #fff;
  font-size: 25px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.title h2 {
  margin: 6px 0 0;
  color: #fff;
  font-size: 14px;
}

.price h4 {
  margin: 0;
  padding: 8px 0;
  color: #fff;
  font-size: 22px;
}

.card a {
  background: #fff;
  color: black;
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  display: block;
  text-align: center;
  margin: 15px auto 0;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.card a:hover {
  background-color: #f4f4f4;
  text-decoration: none;
}
