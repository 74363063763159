/* SignupTransport.css */

/* Style for MapContainer */
.leaflet-container {
    width: 100%;
    height: 300px;
    position: relative;
    z-index: 1; /* Make sure the map appears above other elements */
}

/* Hide extra input fields if they appear */
.geocoder-control {
    z-index: 1000; /* Ensure the geocoder is above the map */
}

/* Ensure only one input is displayed */
.leaflet-control-geocoder input {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
.full-page-container {
    position: relative;
    z-index: 1; /* Ensure content is above the pseudo-element */
}

.full-page-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://static.vecteezy.com/system/resources/previews/023/126/554/non_2x/set-of-funny-transport-in-doodle-sketch-cartoon-childish-line-art-hand-drawn-illustration-isolated-on-white-vector.jpg');
    background-size:contain;
    background-position: center;
    opacity: 0.1; /* Apply opacity to the doodle only */
    z-index: -1; /* Place it behind the container content */
}

.full-page-content {
    height: auto; /* Change this to the desired height, or set it dynamically */
    min-height: 85%; /* Ensure it at least spans the full viewport height */
    padding: 20px; /* Optional: Add some padding for better aesthetics */
    box-sizing: border-box; /* Ensure padding is included in height calculations */
}
