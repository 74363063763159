.container{
    padding:20px;
    font-family:Arial, sans-serif;
   
}
.payment-table {
    width:100%;
    margin: top 20px;
    border-collapse:collapse;
    
}

.payment-table th, .payment-table td{
    padding:10px 15px;
    border:1px solid #ddd;
    text-align:left;
}



.client-info {
    display:flex;
    align-items:center;
}

.client-image {
    width:40px;
    height:40px;
    border-radius: 50%;
    margin-right:10px;
}

.status {
  padding:5px 10px;
  border-radius:4px;
  font-weight:bold;
  text-align: center;
  display:inline-block;
}

.status.paid {
    background-color:#d4edda;
    color:#155724;
}
.status.pending {
    background-color: #f8d7da;
    color:#721c24;
}
.status.failed {
    background-color: #f8d7da;
    color:#721c24;
}
.status.last {
    background-color: #f8d7da;
    color:#f49506;
}

.status.admin{
 color:#155724;
}

.status.super{
    color:#721c24;
}

.view-button,.edit-button{
    margin-right:5px;
    padding:5px 10px;
    border:none;
    border-radius:4px;
    cursor:pointer;
}

.view-button{
    background-color:#ff5a5f;
    color:white;
}

.edit-button{
    background-color:#ff5a5f;
    color:white;
}

