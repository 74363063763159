/* Flex container for layout */
.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  padding: 20px;
}

.about-section, .form-section {
  flex: 1;
  padding: 100px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%; /* Ensure the form doesn't exceed the container */
}

.input-group {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Wrap inputs if necessary */
}

.field-group {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.input-field {
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 7px;
  font-size: 1.1rem;
  width: 100%;
  max-width: 600px; /* Max width of input fields */
  box-sizing: border-box;
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.submit-button {
  padding: 15px 25px;
  border: none;
  background-color: #bb1544;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 600px; /* Same width as inputs */
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Contact Info */
.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}

.contact-info-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-info-item img {
  width: 20px;
  height: 20px;
}

.who-we-are {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.who-we-are img {
  max-width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.who-we-are p {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

/* Responsive layout for smaller screens */
@media (max-width: 850px) {
  .flex-container {
    flex-direction: column;
    padding: 20px;
  }

  .about-section, .form-section {
    padding: 20px; /* Adjust padding for mobile */
  }

  .input-group {
    flex-direction: column; /* Stack inputs */
    gap: 15px;
    width: 100%; /* Full width on mobile */
  }

  .input-field, .submit-button {
    max-width: 100%; /* Full width on mobile */
  }

  .contact-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Vertical spacing on mobile */
  }

  .contact-info-item {
    justify-content: flex-start;
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .about-section, .form-section {
    padding: 15px; /* Reduced padding for smaller screens */
  }

  .contact-info-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px; /* Smaller gap on very small screens */
  }

  .who-we-are p {
    font-size: 1rem; /* Slightly smaller text on small devices */
  }

  .submit-button {
    font-size: 1rem;
    padding: 12px 20px; /* Adjusted button size */
  }
}
/* Flex container for layout */
.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  padding: 20px;
}

.about-section, .form-section {
  flex: 1;
  padding: 100px;
}

/* Styling the contact form */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%; /* Ensure the form doesn't exceed the container */
}

.input-group {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Wrap inputs if necessary */
}

.field-group {
  flex: 2; /* Make fields wider */
  display: flex;
  flex-direction: column;
}

.input-field {
  padding: 15px; /* Larger padding for better appearance */
  border: 2px solid #ccc;
  border-radius: 7px;
  font-size: 1.1rem; /* Increased font size */
  width: 100%; /* Set width to 100% of the container */
  max-width: 600px; /* Double the length of inputs by setting a max width */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Submit button styling */
.submit-button {
  padding: 15px 25px; /* Larger button */
  border: none;
  background-color: #bb1544;
  color: white;
  font-size: 1.2rem; /* Bigger font */
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%; /* Ensure the button is full width */
  max-width: 600px; /* Same width as inputs */
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Contact Info */
.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.contact-info-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-info-item img {
  width: 20px;
  height: 20px;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }
  
  .input-group {
    flex-direction: column; /* Stack inputs on small screens */
    width: 100%;
  }
  
  .input-field, .submit-button {
    max-width: 100%; /* Ensure full width on small screens */
  }
}
.who-we-are {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the content to the left */
  gap: 10px; /* Reduces the gap between the image and text */
  margin-bottom: 20px; /* Optional: Add some space after the section */
}

.who-we-are img {
  max-width: 100%;
  height: auto;
  margin-bottom: 5px; /* Reduces space below the image */
}

.who-we-are p {
  margin: 0; /* Removes default paragraph margin */
  font-size: 1.1rem; /* Adjusts text size */
  color: #333; /* Optional: Change text color */
}
