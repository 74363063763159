/* CSS for selected option */
.selected-option {
    background-color: #00BFFF; /* Light blue background when selected */
    color: white;              /* White text when selected */
    border: 2px solid #00BFFF; /* Border matching the background color */
    display: inline-block;     /* Keep elements inline */
}

/* CSS for unselected option */
.unselected-option {
    background-color: #f0f0f0; /* Light gray background when not selected */
    color: black;              /* Black text when not selected */
    border: 2px solid #f0f0f0; /* Border matching the background color */
    display: inline-block;     /* Keep elements inline */
}

input[type="checkbox"] {
    cursor: pointer; /* Pointer cursor for checkboxes */
}
