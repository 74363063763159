/* Wrapper */
.cosmicFormWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    min-height: 100vh;
    background: #f7f9fc;
  }
  
  /* Form styling */
  .nebulaForm {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
  }
  .copy-footer-234 {
    font-size: 15px; /* Adjust font size for visibility */
    color: #fff; /* Set text color to white */
    text-align: center; /* Center align the text */
    margin-top: 20px; /* Add spacing from the content above */
  }
  
  /* Input groups */
  .galaxy-group {
    margin-bottom: 20px;
  }
  .logoFooterItem{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .starlightInput {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: all 0.3s ease;
    background-color: #f9f9f9;
    font-family: 'Roboto', sans-serif;
  }
  
  .starlightInput:focus {
    border-color: #3498db;
    background-color: #fff;
    outline: none;
  }
  
  /* Error handling */
  .meteorErrorInput {
    border-color: #e74c3c;
    background-color: #fce4e4;
  }
  .echo-of-the-past {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: left;
    color: white;
    font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

    /* Subtitle shadow */
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Stronger shadow for subtitle */
}/* Text overlay styling */
.whispering-dreams {
    position: absolute;
    left: 50px;
    bottom: 120px;
    color: white;
    z-index: 2;
    text-align: left;
    max-width: 500px; /* Prevent text from getting too wide */
    font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

    /* Added shadow for the text */
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Stronger shadow for text */
}
  .cometErrorMessage {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 5px;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Submit button styling */
  .galaxySubmitButton {
    width: 100%;
    padding: 15px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Roboto', sans-serif;
  }
  
  .galaxySubmitButton:hover {
    background-color: #2980b9;
  }
  
  .galaxySubmitButton:disabled {
    background-color: #b0c4de;
    cursor: not-allowed;
  }
  
  /* Alert message styling */
  .nebulaAlert {
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
  }
  
  .nebula-success {
    background-color: #2ecc71;
    color: white;
  }
  .celestial-symphony {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    color: whitesmoke;
    font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

    /* Heading shadow */
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Stronger shadow for heading */
}
  .nebula-danger {
    background-color: #e74c3c;
    color: white;
  }
  /* Button container */
.twilight-portal {
    position: absolute;
    left: 50px; /* Aligned to the left with the text */
    bottom: 50px; /* Placed under the text */
    z-index: 5;
}/* Button styling */
.stardust-voyage {
    padding: 8px 16px; /* Button padding */
    background-color: white;
    color: red;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

    /* Added stronger shadow for button to match text */
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Match shadow with text */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
}

/* Button hover effect */
.stardust-voyage:hover {
    background-color: #fff;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.9); /* Match hover shadow with text */
    transform: translateY(-3px); /* Slight lift effect on hover */
}
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 768px) {
    .nebulaForm {
      padding: 20px;
    }
    .twilight-portal {
        position: relative;
    }
    .starlightInput {
      padding: 10px;
      font-size: 14px;
    }
    .whispering-dreams {
        left: 20px;
        bottom: 100px;
        max-width: 90%; /* Make text block more flexible for smaller screens */
        font-family: 'Helvetica', sans-serif; /* Ensure typography remains consistent */
    }
    .galaxySubmitButton {
      padding: 10px;
      font-size: 14px;
    }
    .celestial-symphony {
        font-size: 2.5rem; /* Adjust heading size for mobile */
        font-family: 'Helvetica', sans-serif; /* Ensure typography remains consistent */
    }
    .logoFooterItem{
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 30%;
        display: block; /* Ensures the image behaves like a block element */
        margin: 0 auto; 
      }

      .echo-of-the-past {
        font-size: 1rem; /* Adjust subtitle size for mobile */
        font-family: 'Helvetica', sans-serif; /* Ensure typography remains consistent */
    }
      
      .btn-group-vertical {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .stardust-voyage {
        padding: 8px 16px; /* Button padding */
    background-color: white;
    color: red;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    width: 50% !important;
    cursor: pointer;
    
    position: relative;
    left: -120px;
    bottom: 30px;
    font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

    /* Added stronger shadow for button to match text */
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Match shadow with text */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
    }
  }
  