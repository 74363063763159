/* Base styles for the container */
.container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
}

/* Alignment for desktop and tablet screens */
.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Style for dropdowns and buttons */
.me-3 {
    margin-right: 1rem;
}

/* Style for the filter button */
.btn-primary {
    width: 200px;
    height: 48px;
    border-radius: 39px;
    background-color: #ff5a5f;
    color: white;
    border: none;
}

/* Make the filter button text color white */
.btn-primary:hover {
    background-color: #e04e4f;
}

/* Style for select elements */
select.form-control {
    width: 200px;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
    .container {
        padding-top: 1rem;
        padding-bottom: 0.4rem;
    }

    .d-flex {
        flex-direction: column;
    }

    .me-3 {
        margin-right: 0;
        margin-bottom: 0.1rem; /* Space between the stacked elements */
    }

    select.form-control {
        width: 100%;
    }

    .btn-primary {
        width: 100%;
    }
}
