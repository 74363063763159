.login-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.login-modal-content {
    background: #fff;
    padding: 15px; /* Reduced padding */
    border-radius: 8px;
    width: 350px; /* Reduced width */
    font-size: 14px; /* Reduced font size */
}

.form-group {
    margin-bottom: 10px; /* Reduced margin between form groups */
}

.form-group label {
    display: block;
    margin-bottom: 4px;
    font-size: 13px; /* Reduced label font size */
}
.form-group.full-width {
    width: 100%; /* Ensures the date input takes the full width */
    display: block;
    margin-top: 10px;
}

.form-group.full-width input[type="date"] {
    width: 86%; /* Make sure the date input fits properly */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    display: block;
    margin-top: 5px;
    margin-left: 11px;
}

.form-group input {
    width: 100%;
    padding: 8px; /* Reduced input padding */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px; /* Reduced input font size */
}



.radio-group {
    display: flex;
    gap: 20px; /* Adds spacing between the radio buttons */
    margin-top: 5px;
}

button {
    width: 100%;
    padding: 8px; /* Reduced button padding */
    background-color: #ff5a5f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Reduced button font size */
}

button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
}

button.close-btn {
    background-color: transparent;
    color: #ff5a5f;
    border: none;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
}
