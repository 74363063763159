.transport-history-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .transport-history-container h2 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .transport-history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .transport-history-table th,
  .transport-history-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .transport-history-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .transport-history-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .transport-history-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  
  .scratched {
    text-decoration: line-through;
    color: grey;
}

.month-status {
  display: inline-block;
  text-align: center;
  margin-right: 8px; /* Adjust spacing between months */
}

.month-name {
  display: block; /* Makes the name appear above the symbol */
}

.paid {
  color: green; /* Style for paid months */
}

.unpaid {
  color: grey;
  text-decoration: line-through; /* Style for unpaid months */
}

.status-symbol {
  display: block; /* Ensures the symbol is on a new line under the month name */
}