/* Wrapper */
.cosmicFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  min-height: 100vh;
}

/* Form styling */
.nebulaForm {
  padding: 30px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}
.copy-footer-234 {
  font-size: 15px; /* Adjust font size for visibility */
  color: #fff; /* Set text color to white */
  text-align: center; /* Center align the text */
  margin-top: 20px; /* Add spacing from the content above */
}

/* Input groups */
.galaxy-group {
  margin-bottom: 20px;
}
.logoFooterItem{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.starlightInput {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.starlightInput:focus {
  border-color: #3498db;
  background-color: #fff;
  outline: none;
}

/* Error handling */
.meteorErrorInput {
  border-color: #e74c3c;
  background-color: #fce4e4;
}
.echo-of-the-past {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: left;
  color: white;
  font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

  /* Subtitle shadow */
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Stronger shadow for subtitle */
}/* Text overlay styling */
.whispering-dreams {
  position: absolute;
  left: 50px;
  bottom: 120px;
  color: white;
  z-index: 2;
  text-align: left;
  max-width: 500px; /* Prevent text from getting too wide */
  font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

  /* Added shadow for the text */
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Stronger shadow for text */
}
.cometErrorMessage {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Roboto', sans-serif;
}

/* Submit button styling */
.galaxySubmitButton {
  width: 100%;
  padding: 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Roboto', sans-serif;
}

.galaxySubmitButton:hover {
  background-color: #2980b9;
}

.galaxySubmitButton:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}

/* Alert message styling */
.nebulaAlert {
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.nebula-success {
  background-color: #2ecc71;
  color: white;
}
.celestial-symphony {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  color: whitesmoke;
  font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

  /* Heading shadow */
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Stronger shadow for heading */
}
.nebula-danger {
  background-color: #e74c3c;
  color: white;
}
/* Button container */
.twilight-portal {
  position: absolute;
  left: 50px; /* Aligned to the left with the text */
  bottom: 50px; /* Placed under the text */
  z-index: 5;
}/* Button styling */
.stardust-voyage {
  padding: 8px 16px; /* Button padding */
  background-color: white;
  color: red;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

  /* Added stronger shadow for button to match text */
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Match shadow with text */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
}
body {
margin: 20px;
padding: 0;
font-family: Arial, sans-serif;
background-color: #f0f0f0;
}

.astral-viewport {
display: flex;
flex-direction: column;
gap: 20px;
}

.nebula-construct {
display: flex;
height: calc(100vh - 40px);
gap: 10px;
border-radius: 15px;
overflow: hidden;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quasar-zone, .lunar-rise, .solar-dive, .starfal-spark,.starfall-spark, .cosmic-surge, .galactic-glow {
border-radius: 15px;
margin: 5px;
overflow: hidden;
}

.quasar-zone {
flex: 1;
background-image: 
    linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
    url('https://images.pexels.com/photos/10099124/pexels-photo-10099124.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
justify-content: center;
align-items: center;
filter: grayscale(20%);
}

.quasar-zone h2 {
position: relative;
z-index: 2;
color: white;
}

.lunar-chamber {
display: flex;
flex-direction: column;
flex: 1;
gap: 10px;
}

.lunar-rise {
flex: 1;
background-image: 
    linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
    url('https://images.pexels.com/photos/3769021/pexels-photo-3769021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
justify-content: center;
align-items: center;
}

.solar-dive {
flex: 1;
background-image: 
    linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
    url('https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
justify-content: center;
align-items: center;
}

.lunar-rise h2, .solar-dive h2 {
position: relative;
z-index: 2;
color: white;
}

.comet-ribbon {
display: flex;
width: 100%;
height: 25vh;
gap: 10px;
}

.starfal-spark, .starfall-spark, .cosmic-surge, .galactic-glow {
flex: 1;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
justify-content: center;
align-items: center;
position: relative;
}

.starfal-spark {
background-image: url('https://images.unsplash.com/photo-1550547660-d9450f859349?q=80&w=1530&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}

.starfall-spark {
background-image: url('https://img.freepik.com/premium-photo/black-guy-works-call-center-with-people_85574-987.jpg');
}

.cosmic-surge {
background-image: url('https://images.pexels.com/photos/4606346/pexels-photo-4606346.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
}

.galactic-glow {
background-image: url('https://plus.unsplash.com/premium_photo-1713127337315-a077e31d3f98?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}

.starfal-spark h2,.starfall-spark h2, .cosmic-surge h2, .galactic-glow h2 {
position: relative;
z-index: 2;
color: white;
}

.starfal-spark::before,.starfall-spark::before, .cosmic-surge::before, .galactic-glow::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
border-radius: 15px;
z-index: 1;
}

/* Mobile View */
@media (max-width: 768px) {
.nebula-construct {
    flex-direction: column;
    height: auto;
    padding: 10px; /* Added padding */
}
.quasar-zone {
    flex: none;
    width: 95%;
    height: 50vh;
    padding: 10px; /* Added padding */
}
.lunar-chamber {
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    gap: 10px;
    padding: 10px; /* Added padding */
}
.lunar-rise, .solar-dive {
    flex: none;
    width: 100%;
    height: 30vh;
    padding: 10px; /* Added padding */
}
.comet-ribbon {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 40vh;

}
.starfall-spark, .cosmic-surge, .galactic-glow {
    flex: none;
    width: calc(45% - 0px);
    height: 20vh;
 
}
}


/* Button hover effect */
.stardust-voyage:hover {
  background-color: #f0f0f0;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.9); /* Match hover shadow with text */
  transform: translateY(-3px); /* Slight lift effect on hover */
}
/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .nebulaForm {
    padding: 20px;
  }
  .twilight-portal {
      position: relative;
  }
  .starlightInput {
    padding: 10px;
    font-size: 14px;
  }
  .whispering-dreams {
      left: 20px;
      bottom: 100px;
      max-width: 90%; /* Make text block more flexible for smaller screens */
      font-family: 'Helvetica', sans-serif; /* Ensure typography remains consistent */
  }
  .galaxySubmitButton {
    padding: 10px;
    font-size: 14px;
  }
  .celestial-symphony {
      font-size: 2.5rem; /* Adjust heading size for mobile */
      font-family: 'Helvetica', sans-serif; /* Ensure typography remains consistent */
  }
  .logoFooterItem{
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 30%;
      display: block; /* Ensures the image behaves like a block element */
      margin: 0 auto; 
    }

    .echo-of-the-past {
      font-size: 1rem; /* Adjust subtitle size for mobile */
      font-family: 'Helvetica', sans-serif; /* Ensure typography remains consistent */
  }
    
    .btn-group-vertical {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .stardust-voyage {
      padding: 8px 16px; /* Button padding */
  background-color: white;
  color: red;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  width: 50% !important;
  cursor: pointer;
  
  position: relative;
  left: -120px;
  bottom: 30px;
  font-family: 'Helvetica', sans-serif; /* Set typography to Helvetica */

  /* Added stronger shadow for button to match text */
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Match shadow with text */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Add transition for hover effect */
  }
}