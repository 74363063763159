.container {
    display: flex;
    flex-direction: column; /* This will make the map appear below the apartments list */
    width: 120%;
    gap: 20px;
}

/* Apartments list will be on top, taking up more space */
.apartments-list {
    flex: 1;
    overflow-y: auto;
    overflow: hidden;
    height: 500px;
    width: 100%; /* Full width on desktop */
}

/* Map will be below and take full width on desktop */
.map-container {
    flex: 1;
    width: 100%;
    height: 430px;
    border: 1px solid #ddd;
    border-radius: 30px;
}






.dsdssAllApartment {
    position: absolute;
    top: 660px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    font-size: 24px;
    border-radius: 50%;
    z-index: 3000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Adjust spacing for building items on mobile */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .map-container {
        display: none;
    }

    .apartments-list {
        width: 120%;
    }

    .dsasdssa-item {
        flex: 0 0 10%;
        margin: 5px 0; /* Reduce vertical margin */
        padding: 10px; /* Adjust padding as needed */
    }

    .room-card {
        display: flex;
        flex-direction: column;
        gap: 10px; /* Reduce gap between elements inside the card */
    }
}




