/* Make the CSS exclusive to the Airbnb header */
#airbnb-header * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#airbnb-header {
  font-family: Arial, sans-serif;
}

#airbnb-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 70px;
  border-bottom: 1px solid #fff;
}

#airbnb-header .logo {
  display: flex;
  align-items: center;
}

#airbnb-header .logo img {
  width: 115px; /* Increased size of the logo */
  margin-right: 68px;
}

#airbnb-header .logo span {
  font-size: 128px; /* Increased font size */
  font-weight: bold;
  color: #ff5a5f;
}

#airbnb-header nav ul {
  list-style: none;
  display: flex;
  gap: 15px; /* Decreased gap between navigation links */
}

#airbnb-header nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 18px; /* Increased font size */
}

#airbnb-header nav ul li a:hover {
  text-decoration: underline;
}

#airbnb-header .right-menu {
  display: flex;
  align-items: center;
  gap: 15px; /* Decreased gap */
}

#airbnb-header .right-menu a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px; /* Increased font size */
}

#airbnb-header .language-selector {
  font-size: 18px; /* Adjusted size for the globe icon */
  cursor: pointer;
}

#airbnb-header .menu-profile {
  display: flex;
  width: 50%;
  align-items: center;
  padding: 15px 15px 15px 25px;
  border: 1px solid #ddd;
  border-radius: 50px;
}

#airbnb-header .hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px; /* Adjusted size */
  margin-right: 10px;
  cursor: pointer;
}

#airbnb-header .hamburger-menu .bar {
  width: 25px; /* Increased width of bars */
  height: 3px; /* Increased height of bars */
  background-color: #8d7f7f;
  margin: 2px 0;
}

#airbnb-header .profile-icon img {
  width: 30px; 
  height: 30px;/* Increased size of profile icon */
  border-radius: 50%;
  margin-left: 20px;
}

/* Add this to your CSS file */

/* Add shadow and rounded corners to the dropdown */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 90px;
  right: 20px;
  background-color: white;
  padding: 15px 30px;
  padding-left: 20px;
  border-radius: 10px;
  z-index: 1000;
  width: 200px;
  height: auto; /* Auto height for flexibility */
  min-height: 150px; /* Minimum height for small screens */
  max-height: 50vh; /* Maximum height for larger screens, responsive to viewport */
  overflow-y: auto;
  left: auto; /* Allow scrolling if content exceeds max height */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .dropdown-menu {
    top: 70px; /* Adjust position for smaller screens */
    right: 10px; /* Adjust right positioning */
    width: 180px; /* Slightly smaller width on small screens */
    padding: 10px 20px;
    left: auto;
  }
}

/* Media query for very small screens */
@media (max-width: 480px) {
  .dropdown-menu {
    top: 60px; /* Further adjust position for very small screens */
    width: 160px; /* Reduce width */
    padding: 8px 16px;
    right: 5px;
    left: auto;
  }
}


/* Style for individual dropdown items */
.dropdown-menu a {
  display: block;
  padding: 20px 40px; 
  padding-left: 20px;/* Increased padding for more space from the top and right */
  text-decoration: none;
  color: #333;
  font-size: 16px; /* Adjust font size for readability */
  font-weight: normal;
}



/* Hover effect */
.dropdown-menu a:hover {
  background-color: #f9f9f9; /* Subtle hover effect */
  border-radius: 5px; /* Rounding for hover */
}




/* Media query for mobile devices */
@media (max-width: 768px) {
  #airbnb-header nav ul, #airbnb-header .right-menu a, #airbnb-header .language-selector {
      display: none; /* Hide navigation links and "Airbnb your home" text */
  }

  #airbnb-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5% 6%;
      border-bottom: 1px solid #fff;
  }

  #airbnb-header .logo img {
      width: 100px; /* Adjust logo size for mobile */
  }

  #airbnb-header .logo span {
      font-size: 24px; /* Adjust logo text size for mobile */
  }

  #airbnb-header .menu-profile {
      display: flex;
      width: 150%;
      align-items: center;
      padding: 15px 15px 15px 25px;
      border: 1px solid #ddd;
      border-radius: 50px;
  }
}
