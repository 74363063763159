/* General Styles specific to this page */
.dashboard-page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dashboard-page {
    font-family: Arial, sans-serif;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* min-height: 100vh; */
    background-color: #fff;
}

.dashboard-page .dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
    gap: 10px; /* Spacing between cards */
    padding: 20px;
    width: 100%; /* Full width */
    max-width: 1000px; /* Limit max width */
}

.dashboard-page .card {
  background-color: #4c4b4be9;
    position: relative;
    padding: 10px;
    height: 250px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(255, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}

.dashboard-page .card h2,
.dashboard-page .card small {
    position: relative;
    z-index: 2;
}

.dashboard-page .card h2 {
    font-size: 17px;
    color: red;
}

.dashboard-page small {
    position: relative;
    z-index: 2;
    color: rgb(255, 0, 0);
    margin-top: auto;
}


.dashboard-page small .smally {
    position: relative;
    z-index: 2;
    color: rgb(255, 255, 255);
    margin-top: auto;
}

.dashboard-page .card-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* Adding black gradient overlay on the image */
.dashboard-page .first-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
    z-index: 2; /* Place the gradient above the image but below the text */
    border-radius: 10px; /* Keep the border-radius consistent with the card */
}

/* First card spans two columns */
.dashboard-page .first-card {
    grid-column: span 2; /* Make the first card span two columns */
    width: 100%;
    position: relative;
    overflow: hidden;
}

/* Buttons in the first card */
.dashboard-page .card-buttons {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px; /* Space between buttons */
    z-index: 5; /* Ensure buttons are above the gradient */
}

.dashboard-page .button-primary,
.dashboard-page .button-secondary {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    z-index: 6;
}

.dashboard-page .button-primary {
    background-color: #ff5a5f;
    color: white;
}

.dashboard-page .button-secondary {
    background-color: #f1f1f12f;
    color: black;
    border: 1px solid #ccc;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .dashboard-page .dashboard {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }

    .dashboard-page .card {
        padding: 15px;
        width: auto;
    }

    .dashboard-page .first-card {
        grid-column: span 2; /* First card still spans two columns */
    }
}

@media (max-width: 480px) {
    .dashboard-page .dashboard {
        grid-template-columns: 1fr; /* 1 column on mobile screens */
    }

    .dashboard-page .card {
        width: auto;
    }

    .dashboard-page .first-card {
        grid-column: span 1; /* First card spans 1 column on small screens */
    }
}
