
/* Unique Slider container */
.dsasdssa {
    position: relative;
    display: flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    width: 100%; /* Full width of the slider */
    gap: 10px;
}

/* Slider items */
.dsasdssa-item {
    flex: 0 0 calc(25% - 10px); /* Show four items in the viewport */
    box-sizing: border-box;
    height: 400px; /* Adjust height as needed */
}

/* Images inside slider */
.dsasdssa-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Slider buttons */
.fdsdsfdsdsv, .fdsdsfdsdsvApartment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto;
}

.dsdss,  .dsdssAllApartment {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    font-size: 24px;
    border-radius: 50%;
    z-index: 2000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dsdss {
    top: 430px;
}

.dsdssApartment {
    top: 1160px;
}

.dsdssAllApartment {
    top: 710px;
}

/* Hide buttons on mobile */
@media only screen and (max-width: 768px) {
    .fdsdsfdsdsv, .fdsdsfdsdsvApartment {
        display: none;
    }

    .dsasdssa-item {
        flex: 0 0 4%; /* Show 1 item in the viewport for mobile */
        height: 390px; /* Optional: Adjust height for mobile if needed */
    }

    .dsasdssa {
        position: relative;
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        width: 100%;
        margin: auto;
        height: 460px;
        left: 0px;
        gap: 10px;
    }

    .room-card {
        width: 290px;
        height: 350px;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        position: relative;
        color: #ffffff;
        background-color: #142c44;
    }
}

/* Building and room card design */
body {
    font-family: Arial, sans-serif;
    text-align: center;
}

.room-card {
    width: 290px;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    color: #ffffff;
    background-color: #142c44;
}

.room-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.9;
}

.room-card-content {
    padding: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(51, 51, 51, 0.9) 3%, rgba(51, 51, 51, 0.7) 20%, rgba(51, 51, 51, 0.1) 120%);
    z-index: 1;
}

.room-title {
    margin: 0;
    font-size: 24px;
    text-align: left;
    color: #ffffff;
}

.room-type, .b-type {
    color: #cce7ff;
    font-size: 15px;
    text-align: left;
    margin: 5px 0 15px 0;
}

.plan-options {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
}

.plan-option {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;
    font-weight: 700;
    padding: 8px 7px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    width: auto;
    display: inline-block;
}

.plan-option:hover {
    background-color: rgba(51, 51, 51, 0.2);
}

.view-link {
    color: #cce7ff;
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin-bottom: 15px;
    text-align: left;
}

.room-details {
    display: flex;
    justify-content: space-between;
    background-color: #4d4d4d00;
    padding: 10px;
    color: #ffffff;
    border-radius: 5px;
}

.detail-item {
    text-align: left;
}

.detail-item p {
    margin: 0;
    color: #d3d3d3;
}

.detail-item span {
    display: block;
    font-weight: bold;
    margin-top: 5px;
    color: #a6a6a6;
}

/* Disabled button styles */
.disabled-button {
    background-color: #d3d3d3;
    color: #a6a6a6;
    cursor: not-allowed;
    border: 2px solid #a6a6a6;
}

button:disabled {
    opacity: 0.6;
}
.nextAll {
    right: 40px;
  }